import { useEffect, useState } from "react";
import { useForm, Controller} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  InputLabel,
  TextField,
  Drawer,
  Button,
  CircularProgress,
  Select,
  MenuItem,
  Avatar,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Switch,
  Autocomplete,
  Tooltip
} from "@mui/material";
import { useMutation, useQuery, useLazyQuery, useReactiveVar } from "@apollo/client";
import { toggleSnackbar, schoolDtls, registerEvent, userDeatils } from "../../RactiveVariables/index";
import { LIST_ALL_SCHOOL, IMAGE_UPLOAD, GET_ALL_TOPICS } from "../../Graphql/queries";
import { ADD_SCHOOL_USER, EDIT_SCHOOL_USER } from "../../Graphql/mutations";
import axios from "axios";
import {sclUserGrp,sclUserType} from '../School/SchoolOptions'
import InfoIcon from "@mui/icons-material/Info";
import { useParams } from "react-router-dom";
import {position} from '../Common/Options';
import { CropImage } from "../Common";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import imageCompression from 'browser-image-compression';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export default function AddUser(props) {
  const [selectedImage, setSelectedImage] = useState(null);
  const [website, setWebsite] = useState(null);
  const [addUser, { loading }] = useMutation(ADD_SCHOOL_USER);
  const [editUser, { loading: editLoading }] = useMutation(EDIT_SCHOOL_USER,{
    fetchPolicy: "network-only",
  onCompleted:(res)=>{
    props?.setSelectedItem&&props?.setSelectedItem(null);
    setSelectedImage(null);
  }});
  const schema = yup.object().shape({
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required("Last Name is required"),
    title: yup.string().required("Title is required"),
    // schoolId: yup.string().required("School Name is required"),
    email: yup.string().required("Email is required").email()
    .test("email", "Your email should match with your school domain. Please enter your school email.", (value) => {
      if (value.split("@")[1]===website || props?.nonMember || emailVal) return true;
      else return false;
    }),
    // phone: yup
    //   .string()
    //   .required("Phone Number is required")
    //   .test("phone", "Must be a number", (value) => {
    //     if (value && isNaN(value.replace(/[-,(,), ]+/g, ""))) return false;
    //     else return true;
    //   })
    //   .test("phone", "Must be a valid number", (value) => {
    //     if (value && value.length < 8) return false;
    //     else return true;
    //   }),
    userType: !props?.nonMember ? yup.string().required("Please select user group") : null,
    schUserType: !props?.nonMember ? yup.string().required("Please select user type") : null,
    pronoun: yup.string().required("Please enter Pronoun"),
    // position: !props?.nonMember ? yup.string().required("Please select Position") : null,
    // positionOther: yup
    // .string()
    // .when("position", {
    //   is: (position) => position == "Other",
    //   then: yup.string().required("Please Enter Other"),
    // }),
  });
  const { register, handleSubmit, formState, reset, getValue, trigger, setValue,control } = useForm({
    defaultValues: props.defaultValue,
    resolver: yupResolver(schema),
  });
  const { data: listSchool } = useQuery(LIST_ALL_SCHOOL,{variables:{src:'src',allSch:true},
  onCompleted:res=>{  
    getWebiste(props.selectedItem.schoolId,res);
    setValue("schoolId",props.selectedItem.schoolId)
}});

  const schoolDetails = useReactiveVar(schoolDtls);
  const user = useReactiveVar(userDeatils);
  const [cropImage, setCropImage] = useState(null);
  const [cropOpen, setCropOpen] = useState(false);
  const [phone, setPhone] = useState(null);
  const [emailVal, setEmailVal] = useState(props.selectedItem?.emailVisFlg??true);
  const [positions, setPositions] =useState(props?.selectedItem?.position ? props?.selectedItem?.position : "");
  const [imageUploadLoading, setImageUploadLoading] = useState(false);
  const [groupId, setGroupId] = useState(props?.selectedItem?.userType ? props?.selectedItem?.userType : "Member");
  
  const getUserVariables = {
    skip: 0,
    limit: 500,
    isArchived: false,
  };
  const [ListTopics, { data: topicLists ,loading:topicLoading}] = useLazyQuery(GET_ALL_TOPICS, {
    fetchPolicy: "network-only",
    variables: getUserVariables,
    onCompleted:res=>{
      var arr=[]
      props?.selectedItem?.massTopicsIds?.forEach(element1 => {
        let position = res?.listTopics?.data?.findIndex((element) => element._id === element1);
        if(position > -1) {
          arr.push(res?.listTopics?.data?.[position])
        }
      });
      setValue("massTopicsIds",arr);
  }
  });

  useEffect(()=>{
    ListTopics()
  },[props.open])

  let params = useParams();
  const regEvent = useReactiveVar(registerEvent);
  const id = params.id ? params.id : "$";
  const uplaodImageVariables = {
    originalFilename: selectedImage?.fileName,
    path: "/userprofile",
  };
  const [upload, { data: uploadImageData }] = useLazyQuery(IMAGE_UPLOAD, {
    fetchPolicy: "network-only",
    variables: uplaodImageVariables,
  });
  useEffect(()=>{
    if(listSchool){
      getWebiste(props.selectedItem.schoolId);
      setValue("schoolId",props.selectedItem.schoolId)
    }
  },[props.selectedItem])
  // let website = getWebiste();



  const phoneNumberFormat = (num) => {
    let newNum = num.replace(/[-,(,), ]+/g, "");
    let x;
    if (newNum.length <= 3) {
      x = newNum;
    } else if (newNum.length > 3 && newNum.length <= 6) {
      x = "("+newNum.slice(0, 3) + ") " + newNum.slice(3, 6);
    } else {
      x =
        "("+newNum.slice(0, 3) +
        ") " +
        newNum.slice(3, 6) +
        "-" +
        newNum.slice(6, 10);
    }
    return x;
  };
  const { errors } = formState;

  async function uploadImage(params) {
    setImageUploadLoading(true);
    if (selectedImage) {
      let url = uploadImageData?.imageUpload?.signedUrl;
      const response = await axios.put(url, selectedImage.file, {
        headers: {
          "Content-Type": selectedImage?.file?.type,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "X-Requested-With",
        },
      });
    }
    setImageUploadLoading(false);
    onSubmit(params);
  }

  const getTopicId = (array)=>{
    if(array){
      let id = []
      array.forEach(element => {
        id.push(element._id);
      });
      return id
    }
  }

  const onSubmit = (params) => {
    if(props?.nonMember)
      params.schUserType = "Non-Member"
    if(id!=="$")
      params["schoolId"] = schoolDetails?.schoolId ? schoolDetails?.schoolId : id;
    if(params?.gpModules){
      let moduleAcs= []
      Object.keys(params?.gpModules).map((key)=>{
        moduleAcs.push({title:key,status:groupId ==="Admin" ? true : params?.gpModules[key]})
      })
      params["moduleAcs"] = moduleAcs;
      delete params["gpModules"];
    }
    else if(groupId ==="Admin"){
      let moduleAcs= [];
      Checkboxlists.forEach(element => {
        moduleAcs.push({title:element.name,status:true})
      });
    }
    params["phone"] = params?.phone;
    if (selectedImage?.imageFile !== null)
      params["profileImage"] = uploadImageData?.imageUpload?.publicUrl;
    else params["profileImage"] = "";
    if (props.selectedItem && Object.keys(props.selectedItem).length > 0) {
      try {
        if(params.massTopicsIds){
          params['massTopicsIds']= getTopicId(params.massTopicsIds)
          }
        params._id = props?.selectedItem?._id;
        editUser({ variables: { _id: props?.selectedItem?._id, data: params } })
          .then((res) => {
            toggleSnackbar({
              status: true,
              message: "User updated successfully",
              variant: "success",
            });
            props.handlePagination&&props.handlePagination();
            props.setOpen(false);
            props.handleUserUpdate && props.handleUserUpdate(params);
          })
          .catch((error) => {
            const message = error && error.message;
            toggleSnackbar({
              status: true,
              message: message,
              variant: "error",
            });
          });
      } catch (error) {
        const message = error && error.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      }
    } else {
      if(props?.uuid && props.handleAdd && id==="$")
        params["schUdId"] = props?.uuid
      else if(props?.schoolId || schoolDetails?.schoolId)
        params["schoolId"] = props?.schoolId || schoolDetails?.schoolId;
      if(props.nonMember)
        params.noMailFlg = true;
      try {
        addUser({ variables: { data: params } })
          .then((res) => {
            toggleSnackbar({
              status: true,
              message: "User added successfully",
              variant: "success",
            });
            params["id"] = res?.data?.addSchUser?._id;
            if(params?.schVisFlg === true)
              props.handleAdd && props.handleAdd(params);
            props?.getMoreUserList && props?.getMoreUserList();
            props.handlePagination && props.handlePagination();
            props.setOpen && props.setOpen(false);
            props.handleMemberAdd && !props?.nonMember && props?.handleMemberAdd(props?.nonMember);
            props.nonMember && props?.handleNonMemberAdd && props?.handleNonMemberAdd(res?.data?.addSchUser?._id);
            if(regEvent){
              let objct = {...regEvent};
              objct["orderCreatedFor"] = res?.data?.addSchUser?._id;
              registerEvent(objct);
            }
          })
          .catch((error) => {
            const message = error && error.message;
            toggleSnackbar({
              status: true,
              message: message,
              variant: "error",
            });
          });
      } catch (error) {
        const message = error && error.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      }
    }
  };

  function closeDrawer() {
    props.setOpen(false);
    props?.setSelectedItem && props?.setSelectedItem(null);
    setSelectedImage(null);
    setPhone(null);
  }


  function handleUploadClick(imagefile) {
    let obj = {};
    obj["fileName"] = imagefile.name;
    obj["url"] = URL.createObjectURL(imagefile);
    obj["file"] = imagefile;
    setSelectedImage(obj);
    upload();
  }
  const phoneNumberRegister = register("phone", { required: true });
  const Checkboxlists = [
    { label: "School Profile", name: "SchoolProfile" },
    { label: "Events", name: "Events" },
    { label: "Careers", name: "Careers" },
    { label: "Users", name: "Users" },
    { label: "Orders", name: "Orders" },
  ];
  function getAcsDefaultValue(arr,name) {
    if(arr&&name){
      let position = arr.findIndex((element) => element.title === name);
      if(position>-1)
        return arr?.[position]?.status;
      else
        return false
      }
  }
  function getPermi(type) {
    if(user?.data?.moduleAcs){
    let pos = user?.data?.moduleAcs.findIndex(e => e.title===type);
    if(pos>-1 && user?.data?.moduleAcs[pos].status)
      return true;
    else
      return false;
    }
    return false;
  }
  function checkImage(e){
    let file = e.target.files[0];
    if (
      !(
        file?.type == "image/jpeg" ||
        file?.type == "image/png" ||
        file?.type == "image/jpg"
      )
    ) {
      toggleSnackbar({
        status: true,
        message: "File does not support. You must use .png or .jpeg",
        variant: "error",
      });
      return false;
    }
    else if (file.size > 5242880) {
      toggleSnackbar({
        status: true,
        message: "Please upload a file smaller than 5 MB",
        variant: "error",
      });
      return false;
    }
    else{
      var options = {
        maxSizeMB: 1,
        // maxWidthOrHeight: 300,
        useWebWorker: true,
      };
      imageCompression(file, options)
      .then((compressedFile) => {
        setCropImage(compressedFile);
        setCropOpen(true);
      })
      .catch((err) => {
        //console.log("err: ",err)
      })
    }
  }
  function handelCropDone(result,fileUrl) {
    setCropImage(null);
    handleUploadClick(result, fileUrl);
  }
  function getWebiste(id,data){
    let arr = data? data : listSchool
    if(id && arr){
      let pos = arr.listSchool.data.findIndex((element)=>element._id===id);
      if(pos>-1){
        let websitePos = arr?.listSchool?.data[pos].socialMediaWeb.findIndex((element)=>element.title === "Website");
        if(websitePos > -1){
          setWebsite(arr?.listSchool?.data[pos].socialMediaWeb[websitePos].url);
        }
      }
    }
  }
  function sortLabels(a, b) {
    var nameA = a.toUpperCase();
    var nameB = b.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  }
  return (
    <div>
      {props.open && <input
        id="add-user-input"
        type="file"
        hidden
        // onChange={handleUploadClick}
        onChange={checkImage}
        onClick={(event) => {
          event.target.value = null;
        }}
      />}
      <CropImage
        open={cropOpen}
        setOpen={setCropOpen}
        image={cropImage}
        handelCropDone={handelCropDone}
        aspectRatio={1}
      />
      <Drawer anchor={"right"} open={props.open} onClose={closeDrawer}>
        <div className="sidebar-toggler" role="presentation">
          <div className="title-outer mb-3">
            {
              !props.nonMember ? (
                <h1>
              {props.selectedItem && props.handleUserUpdate&&
                    Object.keys(props.selectedItem).length > 0 ?"Edit Profile":
                    props.selectedItem && Object.keys(props.selectedItem).length > 0
                ? "Edit School Member"
                : "Add School Member"}
            </h1>
              ) : (
                <h1>
                {props.selectedItem && Object.keys(props.selectedItem).length > 0
                  ? "Edit School Member"
                  : "Add Guest"}
              </h1>
              )
            }
            
            <div onClick={closeDrawer} className="close-icon">
              X
            </div>
          </div>
          <div className="userform-scroller">
            {!props.nonMember && <div className="d-flex justify-content-center user-outer">
              <div className="avatar-outer">
                <Avatar
                  src={
                    selectedImage
                      ? selectedImage.url
                      : props?.selectedItem?.profileImage
                      ? props?.selectedItem?.profileImage
                      : "/broken-image.jpg"
                  }
                  onClick={(e)=>document.getElementById("add-user-input").click()}
                  sx={{ width: 110, height: 110 }}
                />
                <span
                  onClick={() =>
                    document.getElementById("add-user-input").click()
                  }
                >
                  +
                </span>
              </div>
            </div>}
            <form
              className="adduser-form"
              onSubmit={handleSubmit(uploadImage)}
              noValidate
            >
              <div className="row">
                <div className="col-md-6">
                  <InputLabel className="form-label">
                    First name<sup className="text-danger">*</sup>
                  </InputLabel>
                  <TextField
                    variant="outlined"
                    {...register("firstName")}
                    error={errors.firstName}
                    autoFocus
                    defaultValue={props?.selectedItem?.firstName}
                  />
                  {errors && errors?.["firstName"] && (
                    <p className="errorMessage text-danger">
                      {errors?.firstName?.message}
                    </p>
                  )}
                </div>
                <div className="col-md-6">
                  <InputLabel className="form-label">
                    Last name<sup className="text-danger">*</sup>
                  </InputLabel>
                  <TextField
                    variant="outlined"
                    {...register("lastName")}
                    error={errors.lastName}
                    defaultValue={props?.selectedItem?.lastName}
                  />
                  {errors && errors?.["lastName"] && (
                    <p className="errorMessage text-danger">
                      {errors?.lastName?.message}
                    </p>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <InputLabel className="form-label">
                    Title<sup className="text-danger">*</sup>
                  </InputLabel>
                  <TextField
                    {...register("title")}
                    variant="outlined"
                    error={errors.title}
                    defaultValue={props?.selectedItem?.title}
                  />
                  {/* {errors && errors?.["title"] && (
                    <p className="errorMessage text-danger">
                      {errors?.title?.message}
                    </p>
                  )} */}
                  {/* <Select
                    variant="outlined"
                    {...register("title")}
                    error={errors.title}
                    defaultValue={props?.selectedItem?.title}
                  >
                    {sclUserType?.map((item) => (
                        <MenuItem value={item}>{item}</MenuItem>
                      ))}
                  </Select> */}
                  {errors && errors?.["title"] && (
                    <p className="errorMessage text-danger">
                      {errors?.title?.message}
                    </p>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <InputLabel className="form-label">
                    School Name
                  </InputLabel>
                  <Select
                    variant="outlined"
                    // error={errors.schoolId}
                    // {...register("schoolId")}
                    defaultValue={props.selectedItem.schoolId}
                    onChange={(e)=>{register("schoolId").onChange({
                      target: { value: e.target.value, name: "schoolId" },
                    });
                    getWebiste(e.target.value);
                  }}
                  >
                    {listSchool?.listSchool?.data.map((item) => (
                        <MenuItem value={item?._id} 
                          >{item?.schNameThe}</MenuItem>
                      ))}
                  </Select>
                  {/* {errors && errors?.["schoolId"] && (
                    <p className="errorMessage text-danger">
                      {errors?.schoolId?.message}
                    </p>
                  )} */}
                </div>
                {!props.nonMember && <div className="col-md-6 mt-3">
                  <InputLabel className="form-label">
                    User Type<sup className="text-danger">*</sup>
                  </InputLabel>
                  <Select
                    variant="outlined"
                    {...register("schUserType")}
                    error={errors.schUserType}
                    defaultValue={props?.selectedItem?.schUserType}
                    // onChange={(e)=>{register("schUserType").onChange({
                    //   target: { value: e.target.value, name: "schUserType" },
                    // });
                    // setGroupId(e.target.value)}}
                  >
                    
                        <MenuItem value={"Member"}>{"Member"}</MenuItem>
                        <MenuItem value={"Non-Member"}>{"Guest"}</MenuItem>
                    
                  </Select>
                  {errors && errors?.["schUserType"] && (
                    <p className="errorMessage text-danger">
                      {errors?.schUserType?.message}
                    </p>
                  )}
                </div>}
                {!props.nonMember && <div className="col-md-6 mt-3">
                  <InputLabel className="form-label">
                    User Group<sup className="text-danger">*</sup>
                  </InputLabel>
                  <Select
                    variant="outlined"
                    {...register("userType")}
                    error={errors.userType}
                    defaultValue={props?.selectedItem?.userType ? props?.selectedItem?.userType : "Member"}
                    onChange={(e)=>{register("userType").onChange({
                      target: { value: e.target.value, name: "userType" },
                    });
                    setGroupId(e.target.value)}}
                  >
                    {sclUserGrp?.map((item) => (
                        <MenuItem value={item} 
                          disabled = {user?.data?.loginType==="SchoolUser" 
                            && user?.data?.loginType!=="Admin" 
                            && item==="Admin" ? true : false}
                          >{item}</MenuItem>
                      ))}
                  </Select>
                  {errors && errors?.["userType"] && (
                    <p className="errorMessage text-danger">
                      {errors?.userType?.message}
                    </p>
                  )}
                </div>}
                {!props.nonMember && <div className={positions === "Other" ? "col-md-6 mt-3" : "col-md-12 mt-3" }>
                  <InputLabel className="form-label">
                    Position
                  </InputLabel>
                  <Select
                    variant="outlined"
                    {...register("position")}
                    // error={errors.position}
                    defaultValue={props?.selectedItem?.position}
                    onChange={(e)=>{register("position").onChange({
                      target: { value: e.target.value, name: "position" },
                    });
                    setPositions(e.target.value)}}
                  >
                    {position?.sort(sortLabels).map((item) => (
                        <MenuItem value={item}>{item}</MenuItem>
                      ))}
                  </Select>
                  {/* {errors && errors?.["position"] && (
                    <p className="errorMessage text-danger">
                      {errors?.position?.message}
                    </p>
                  )} */}
                </div>}
                {positions === "Other" &&
                   <div className="col-md-6 mt-3">
                   <InputLabel className="form-label">
                     Other
                   </InputLabel>
                   <TextField
                     {...register("positionOther")}
                     variant="outlined"
                    //  error={errors.positionOther}
                     defaultValue={props?.selectedItem?.positionOther}
                   />
                 </div>
                }
                   {/* {errors && errors?.["positionOther"] && (
                     <p className="errorMessage text-danger">
                       {errors?.positionOther?.message}
                     </p>
                   )} */}
                   <div className="col-md-12 mt-3 topics-tags">
                  <InputLabel className="form-label">
                    Topic Notifications 
                  </InputLabel>
                  <Controller
                  name="massTopicsIds"
                  control={control}
                  // defaultValue={newArray}
                  render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    multiple
                    id="checkboxes-tags-demo"
                    options={topicLists?.listTopics?.data||[]}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.topic}
                    value={value||[]}
                    onChange={(event, value) => {onChange(value)}}
                    // loading={topicLoading}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.topic}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField {...params}
                      // InputProps={{
                      //   ...params.InputProps,
                      //   endAdornment: (
                      //     <Fragment>
                      //       {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      //       {params.InputProps.endAdornment}
                      //     </Fragment>
                      //   ),
                      // }}
                      />
                    )}
                  />
                  )}
                    />
                  {/* {errors && errors?.["massTopicsIds"] && (
                    <p className="errorMessage text-danger">
                      {errors?.massTopicsIds?.message}
                    </p>)} */}
                </div>
                <div className="col-md-6 mt-3">
                  <InputLabel className="form-label">
                    Phone
                  </InputLabel>
                  <TextField
                    {...register("phone")}
                    variant="outlined"
                    // error={errors.phone}
                    value={phone}
                    defaultValue={props?.selectedItem?.phone}
                    onChange={(e) => {
                      phoneNumberRegister.onChange(e);
                      setPhone(phoneNumberFormat(e.target.value));
                    }}
                  />
                  {/* {errors && errors?.["phone"] && (
                    <p className="errorMessage text-danger">
                      {errors?.phone?.message}
                    </p>
                  )} */}
                </div>
                <div className="col-sm-12">
                  <div className="row">
                  {/* {!props.nonMember && <div className="col-sm-6 mt-3"> */}
                  {user.data?.loginType==="Admin" && <div className="col-sm-6 mt-3">
                  <div className="switch-outer mt-4 pt-1">
                    <div className="d-flex align-items-center">
                      <p className="switch-label normal">
                        Skip Website Domain Validation
                      </p>
                      <Checkbox {...register("emailVisFlg")} defaultChecked={props?.selectedItem?.emailVisFlg}
                      checked={emailVal}
                      onChange={(e)=>{
                        trigger("email");
                        setEmailVal(e.target.checked)
                        register("emailVisFlg").onChange({target:{name:"emailVisFlg",value:e.target.checked}})
                      }}/> 
                    </div>
                  </div>
                </div>}
                <div className={user.data?.loginType==="Admin" ? "col-md-6 mt-3" : "col-md-12 mt-3"}>
                  <InputLabel className="form-label">
                    Email<sup className="text-danger">*</sup>
                  </InputLabel>
                  <TextField
                    {...register("email")}
                    variant="outlined"
                    error={errors.email}
                    defaultValue={props?.selectedItem?.email}
                  />
                  {errors && errors?.["email"] && (
                    <p className="errorMessage text-danger">
                      {errors?.email?.message}
                    </p>
                  )}
                </div>
                  </div>
                </div>
                {!props.nonMember && <div className="col-md-12 mt-3">
                  <InputLabel className="form-label">Bio</InputLabel>
                  <div className="textarea-outer">
                    <TextField
                      {...register("bio")}
                      variant="outlined"
                      multiline
                      rows={8}
                      // error={errors.bio}
                      defaultValue={props?.selectedItem?.bio}
                    />
                    {/* {errors && errors?.["bio"] && (
                      <p className="text-danger">{errors?.bio?.message}</p>
                    )} */}
                  </div>
                </div>}
                <div className={!props.nonMember ? "col-md-12 mt-4" : "col-md-6 mt-4"}>
                  <InputLabel className="form-label">
                    Pronoun<sup className="text-danger">*</sup><span><Tooltip title="If this profile is for you, please add your preferred pronouns. Eg/ she/her/hers.
                  If this profile is for someone else, please leave the default text unless you know their preferred pronouns." 
                  placement="right-start">
                    <InfoIcon
                      style={{ width: "17px", color: "#0d6efd" }}
                    />
                  </Tooltip>
                  </span>
                  </InputLabel>
                  {/* <Select
                    variant="outlined"
                    {...register("pronoun")}
                    error={errors.pronoun}
                    defaultValue={props?.selectedItem?.pronoun}
                  >
                    {Pronoun?.map((item) => (
                        <MenuItem value={item}>{item}</MenuItem>
                      ))}
                  </Select>
                  {errors && errors?.["pronoun"] && (
                    <p className="errorMessage text-danger">
                      {errors?.pronoun?.message}
                    </p>
                  )} */}
                  <TextField
                    {...register("pronoun")}
                    variant="outlined"
                    error={errors.pronoun}
                    defaultValue={props?.selectedItem?.pronoun??"To be updated by user"}
                    placeholder="To be updated by user"
                  />
                  {errors && errors?.["pronoun"] && (
                    <p className="errorMessage text-danger">
                      {errors?.pronoun?.message}
                    </p>
                  )}
                </div>
                {!props.nonMember && groupId === "Member" && (user?.data?.loginType ==="Admin" || user?.data?.userType ==="Admin" || getPermi("Users") ) && <div className="col-md-12 mt-3">
                  <h3 className="module-title">Additional Permissions</h3>
                  <FormGroup className="module-checkbox row">
                    {Checkboxlists.map((item) => {
                      return (
                        <FormControlLabel
                          className="col-sm-3"
                          control={
                            <Checkbox
                              defaultChecked={
                                getAcsDefaultValue(props?.selectedItem?.moduleAcs,item.name)
                              }
                              {...register(`gpModules.${item.name}`)}
                              // icon={<RadioButtonUncheckedIcon />}
                              // checkedIcon={<CheckCircleIcon />}
                            />
                          }
                          label={item.label}
                        />
                      );
                    })}
                  </FormGroup>
                </div>}
                {!props.nonMember && <div className="col-sm-12 mt-5 mb-3">
                  <div className="switch-outer">
                    <div className="d-flex align-items-center">
                      <p className="switch-label">
                        Display On NYSAIS Public Website
                      </p>
                      <Switch
                      defaultChecked={props?.selectedItem?.schVisFlg===true ? true : false}
                      {...register("schVisFlg")}
                      />
                    </div>
                  </div>
                </div>}
              </div>
              <div className="d-flex justify-content-between align-items-center btn-section">
                <Button className="outline-btn" onClick={() => closeDrawer()}>
                  Cancel
                </Button>
                <div className="d-flex align-items-center">
                  {
                    !props.nonMember ? (
                       <Button type="submit" className="highlight-btn">
                    {loading || imageUploadLoading || editLoading ? (
                      <CircularProgress size="20px" color="inherit" />
                    ) : props.selectedItem && props.handleUserUpdate&&
                    Object.keys(props.selectedItem).length > 0 ?"Update Profile":
                    props.selectedItem &&
                      Object.keys(props.selectedItem).length > 0 ? (
                      "Update School User"
                    ) : (
                      "Add School Member"
                    )}
                  </Button>
                    ) : (
                      <Button type="submit" className="highlight-btn">
                      {loading || imageUploadLoading || editLoading ? (
                        <CircularProgress size="20px" color="inherit" />
                      ) : props.selectedItem &&
                        Object.keys(props.selectedItem).length > 0 ? (
                        "Update School User"
                      ) : (
                        "Add School User"
                      )}
                    </Button>
                    )
                  }
                 
                </div>
              </div>
            </form>
          </div>
        </div>
      </Drawer>
    </div>
  );
}
