import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";
import { userDeatils, authVar, schoolDtls,mailGroupTitle } from "../RactiveVariables";
import { SideMenu, Header, RouteNames } from "../Modules/Common";

export default function RouteWrapper({
  component: component,
  isPrivate,
  isAuthPage,
  isPublic,
  admin,
  type,
  header,
  sidemenu,
  path,
  fullbody,
  userAccess,
  ...rest
}) {
  const user = useReactiveVar(userDeatils);
  const auth = useReactiveVar(authVar);
  const schoolDetails = useReactiveVar(schoolDtls);
  const mailTitle = useReactiveVar(mailGroupTitle);
  const isLoggedin = auth?.auth;
  const userType = user?.data?.Role;
  const loginType = user?.data?.loginType;
  if (isPrivate && !isLoggedin) {
    return <Redirect to="/login" />;
  } else if (isAuthPage && isLoggedin && loginType==="Admin") {
    return <Redirect to="/users" />;
  } else if (isAuthPage && isLoggedin && loginType==="SchoolUser" &&!isPublic) {
    return <Redirect to="/myevents" />;
  } else if (userAccess && !isAuthPage && !userAccess.includes(loginType)) {
    return <Redirect to="/404" />
  }
  else if (checkForAccess())
    return <Redirect to = "/accessdenied" />
  function mapUrlToLabel(params) {
    if(path.includes("userlisting")){
      return "Mail Groups : " +mailTitle
    }
    else if(path.includes("groups-details")){
      if(typeof(mailTitle)==="string")
      return mailTitle
    }
    else if(path.includes("userlists")){
      // if(typeof(mailTitle)==="string")
      return 'E-Notice recipients'
    }
    else if(path.includes("group")){
      return "Groups"
    }
    else if(path.includes("conversation")){
      return mailTitle
    }
    else{
      let position = RouteNames.findIndex((element) => element.url === path);
      if (position > -1) {
        return RouteNames[position].label;
      }
    }

  }

  function checkForAccess() {
    let urlMap = {
      "/schoolprofile" : "schoolprofile",
      "/schoolcareer" : "careers",
      "/schoolevent" : "events",
      "/events" : "Events",
      "/schools" : "Member Schools",
      "/users" : "Users Access",
      "/usergroup" : "Users Group",
    }
    if(loginType==="SchoolUser"){
      if(path!=="/events" && urlMap[path] && user?.data?.moduleAcs.find(element => element.title.toLowerCase() === urlMap[path].toLowerCase() && element.status===false))
        return true;
    }
    if(loginType==="Admin"){
      if(urlMap[path] && user?.data?.userGpDtls?.moduleAcs.find(element => element.title.toLowerCase() === urlMap[path].toLowerCase() && element.status===false))
        return true;
    }
    else
      return false;
  }

  return (
    <div className={fullbody ? "content fullwidth-body" : "content"}>
      <div className="inner-contents">
        {header && <Header title={schoolDetails?.schoolId ? schoolDetails?.data?.schNameThe : mapUrlToLabel()} />}
        {sidemenu && <SideMenu pathname = {path}/>}
        <Route {...rest} component={component} />
      </div>
    </div>
  );
}
