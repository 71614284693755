import React, { useState,useEffect } from "react";
import { useHistory, useParams ,useLocation} from "react-router-dom";
import {
  InputLabel,
  TextField,
  Drawer,
  Button,
  FormControlLabel,
  CircularProgress,
  FormGroup,
  Checkbox,
  Autocomplete,
  Box,
  FormControl,
  Select,
  MenuItem,
  Chip,
  Stack,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import PreviewAndCompose from "./previewAndCompose"
import {useQuery,useMutation,useLazyQuery} from "@apollo/client";
import {LIST_EVENTS,LIST_ALL_MAIL_GROUPS,LIST_ENOTICELIST, GET_ALL_TOPICS} from "../../Graphql/queries";
import {CREATE_ENOTICE,UPDATE_ENOTICE} from "../../Graphql/mutations"
import { useForm,Controller  } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toggleSnackbar } from "../../RactiveVariables/index";
import * as yup from "yup";
import SendMail from "./sendMail";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export default function Overview(props){
  const location = useLocation();
  const history = useHistory();
  const clone = location?.state?.detail;

  const { id } = useParams();
  const [defaultValue,setDefaultValue] = useState(null)

  const eventlist= props?.eventlist?.data
  const [selectEvent,setSelectEvent] =useState('')
  const [newArray,setNewArray] = useState(null)

  const getMailGroupVariables = {
    skip: 0,
    limit: 200,
    search: "",
    category: "",
    startDate: "",
  };

  const {data,loading, fetchMore} = useQuery(LIST_ALL_MAIL_GROUPS,{variables:getMailGroupVariables, fetchPolicy: "network-only",
onCompleted:res=>{
    var arr=[]
    defaultValue?.createENotice?.toGpList||props?.enoticedetais?.eNoticeDetails?.toGpList?.forEach(element1 => {
      let position = res?.listAllMailGroups?.data.findIndex((element) => element._id === element1);
      if(position > -1) {
        arr.push(res?.listAllMailGroups?.data[position])
      }
    });
    setValue("mailList",arr);
}})

console.log("test1",defaultValue);
console.log("test2",props?.enoticedetais);
const getUserVariables = {
  skip: 0,
  limit: 500,
  isArchived: false,
};

const {
  data: topicList,
} = useQuery(GET_ALL_TOPICS, {
  fetchPolicy: "network-only",
  variables: getUserVariables,
  onCompleted:res=>{
    var arr=[]
    defaultValue?.createENotice?.massTopicsIds||props?.enoticedetais?.eNoticeDetails?.massTopicsIds?.forEach(element1 => {
      let position = res?.listTopics?.data?.findIndex((element) => element._id === element1);
      if(position > -1) {
        arr.push(res?.listTopics?.data?.[position])
      }
    });
    setValue("topicList",arr);
}
});

  const [createEnotice] = useMutation(CREATE_ENOTICE,{ refetchQueries:['listAllENotice'],
    onCompleted:res=>{
      console.log("res",res);
      props.setOverviewRes(res)
      props.setCreateRes(res)

      setDefaultValue(res)
      // setSelectEvent(res)
      props.setTabValue("Preview & Compose");
      props.setCreate(true)
  }})

  const [updateENotice] = useMutation(UPDATE_ENOTICE,{ refetchQueries:['listAllENotice'],
  onCompleted:res=>{
    props.setOverviewRes(res)
    setDefaultValue(res)

    // setSelectEvent(res)
    props.setTabValue("Preview & Compose");
}})



  const schema = yup.object().shape({
    allEventList: props?.checkedList==="Event"?yup.object().required("Event List is Required").nullable():null,
    // .of(
    //   yup.object().shape({
    //     value: yup.string(),
    //     label: yup.string()
    //   })
    // )
    // .min(1, "Event List is Required").,

    // .of(
    //   yup.object().shape({
    //     value: yup.string(),
    //     label: yup.string()
    //   })
    // )
    // mailList: yup.array()
    // .min(1, "Either Mailing Group or Topic is required").required(" Mail List is Required"),
    // topicList: yup.array()
    // .min(1, "Either Mailing Group or Topic is Required").required("Topic is Required"),
    mailList: yup.array().test({
      test: function (mailList) {
        const topicList = this.parent.topicList;
  
        if ((!mailList || mailList.length === 0) && (!topicList || topicList.length === 0)) {
          // If neither mailList nor topicList is selected, show an error
          throw this.createError({
            message: 'Either Mail List or Topic Notification is required',
            path: 'mailList',
          });
        }
  
        return true;
      },
    }),
    topicList: yup.array(),
    // allEventList:yup.string().required("Subject is Required"),
    // mailList:yup.string().required("Subject is Required"),
    subject: yup.string().required("Subject is Required"),
    from: yup.string().required("From is Required"),
    // fromLabel : yup.string().required("From Label is Required"),
    replyTo: yup.string().required("Reply To is Required"),
    // bcc: yup.string().required("Bcc is Required"),
  });
  const { register, handleSubmit, formState, reset, control ,setValue} = useForm({
    resolver: yupResolver(schema),
  });
  const { errors } = formState;
  const getIdValue = (array)=>{
    if(array){
      let id = []
      array.forEach(element => {
        id.push(element._id);
      });
      return id
    }
  }

  const getTopicId = (array)=>{
    if(array){
      let id = []
      array.forEach(element => {
        id.push(element._id);
      });
      return id
    }
  }
  const onSubmit = (params)=>{
    let data= {
      toGpList:getIdValue(params.mailList),
      massTopicsIds: getTopicId(params.topicList),
      ccList:[params.bcc],
      fromLabel:params.fromLabel,
      from:params.from,
      replyTo:params.replyTo,
      eventId:params.allEventList?._id||"",
      eNoticeType:props.checkedList,
      subject:params.subject,
      content:props?.enoticedetais?.eNoticeDetails?.content??'',
      status:'Draft',
    }

    if(props.create===false&&(id==="$"|| clone==="clone")){
      try {
        createEnotice({variables:{data:data}})
           .then((res) => {
                toggleSnackbar({
               status: true,
               message: clone==="clone"?"E-Notice cloned successfully":"E-Notice updated successfully",
               variant: "success",
             });
           })
           .catch((error) => {
             const message = error && error.message;
             toggleSnackbar({
               status: true,
               message: message,
               variant: "error",
             });
           });
       } catch (error) {
         const message = error && error.message;
         toggleSnackbar({
           status: true,
           message: message,
           variant: "error",
         });
       }
    }else {
      try {
        updateENotice({variables:{_id:props?.enoticedetais?.eNoticeDetails._id??id,data:data}})
           .then((res) => {
                toggleSnackbar({
               status: true,
               message: "E-Notice updated successfully",
               variant: "success",
             });
           })
           .catch((error) => {
             const message = error && error.message;
             toggleSnackbar({
               status: true,
               message: message,
               variant: "error",
             });
           });
       } catch (error) {
         const message = error && error.message;
         toggleSnackbar({
           status: true,
           message: message,
           variant: "error",
         });
       }
    }
    

  }

// const regesterEventList = register("eventList");
// const regesterMailingList = register("mailingList");
return(
    <div className="container">
    <div className="row">
    <div className="col-sm-12">
          <div className="card border-0">
          <form noValidate className="card-body"onSubmit={handleSubmit(onSubmit)}>
            <div className="border-btm">
              <FormControlLabel
              disabled={props?.enoticedetais?.eNoticeDetails?.eNoticeType==="Other"}
                control={
                  <Checkbox
                    checked={ props.checkedList === "Event" ? true : false}
                    // onChange={handleChangeIndividual}
                    onChange={() => props.setCheckedList("Event")}
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<CheckCircleIcon />}
                  />
                }
                label="Event "
              />
              <FormControlLabel
              disabled={props?.enoticedetais?.eNoticeDetails?.eNoticeType==="Event"}
                control={
                  <Checkbox
                    checked={props.checkedList === "Other" ? true : false}
                    // onChange={handleChangeUpload}
                    onChange={() => props.setCheckedList("Other")}
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<CheckCircleIcon />}
                  />
                }
                label="Other"
              />
            </div>
            {
              <div className="col-sm-12">
                {(props.checkedList === "Event"||props?.enoticedetais?.eNoticeDetails?.eNoticeType==="Event") && (
                  <Box className="mb-3" sx={{ minWidth: 120 }}>
                    {/* <FormControl fullWidth> */}
                      <InputLabel className="form-label">Event List<sup className="text-danger">*</sup></InputLabel>
                      <Controller
                        name="allEventList"
                        control={control}
                        defaultValue={props?.enoticedetais?.eNoticeDetails?.eventDtls}
                        render={({ field: { onChange, value } }) => (
                      <Autocomplete className="autocomplete-field"
                        disablePortal
                        variant="outlined"
                        options={eventlist||[]}
                        value={value||null}
                        getOptionLabel={(option) => option?.title}
                        onChange={(event, val) => onChange(val)}
                        renderInput={(params) => (
                          <TextField
                            {...params} />
                        )}
                        renderOption={(props, option) => (
                          <li {...props} key={option?._id}>
                            {option?.title}
                          </li>
                        )}
                      />
                      )}
                    />
                    {/* </FormControl> */}
                    {errors && errors?.["allEventList"] && (
                   <p className="errorMessage text-danger">
                     {errors?.allEventList?.message}
                   </p>
                 )}
                  </Box>
                )}
                <div className="col-md-12 mb-3">
                  <InputLabel className="form-label">Subject<sup className="text-danger">*</sup></InputLabel>
                  <TextField
                    className="w-100"
                    variant="outlined"
                    defaultValue={props?.enoticedetais?.eNoticeDetails?.subject}
                      {...register("subject")}
                      error={errors.subject}
                  />
                  {errors && errors?.["subject"] && (
                  <p className="errorMessage text-danger">
                    {errors?.subject?.message}
                  </p>
                )}
                </div>
                <div className="col-md-12 mb-3 topics-tags">
                <InputLabel className="form-label">Topic Notifications</InputLabel>
                <Controller
                  name="topicList"
                  control={control}
                  // defaultValue={newArray}
                  render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    className="loc-autocomplete"
                    multiple
                    variant="outlined"
                    // id="tags-outlined"
                    value={value||[]}
                    options={topicList?.listTopics?.data||[]}
                    getOptionLabel={(option) => option.topic}
                    onChange={(event, value) => {onChange(value)}}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        // required
                        // error={!!error}
                        // helperText={error?.message}
                        // inputRef={ref}
                        {...params}
                        // label="filterSelectedOptions"
                        // placeholder="Favorites"
                      />
                    )}
                  />
                  )}
                    />
                    {errors && errors?.["topicList"] && (
                    <p className="errorMessage text-danger">
                      {errors?.topicList?.message}
                    </p>)}
                </div>

                <div className="col-md-12 mb-3">
                <InputLabel className="form-label">Select Mailing Groups</InputLabel>
                <Controller
                        name="mailList"
                        control={control}
                        // defaultValue={newArray}
                        render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    className="loc-autocomplete"
                    multiple
                    variant="outlined"
                    // id="tags-outlined"
                    value={value||[]}
                    options={data?.listAllMailGroups?.data||[]}
                    getOptionLabel={(option) => option.groupName}
                    onChange={(event, value) => {onChange(value)}}
                    // defaultValue={[top100Films[13]]}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        // required
                        // error={!!error}
                        // helperText={error?.message}
                        // inputRef={ref}
                        {...params}
                        // label="filterSelectedOptions"
                        // placeholder="Favorites"
                      />
                    )}
                  />
                  )}
                    />
                    {errors && errors?.["mailList"] && (
                    <p className="errorMessage text-danger">
                      {errors?.mailList?.message}
                    </p>)}
                </div>
                <div className="col-md-12 mb-3">
                  <div className="row ">
                    <Box className="col-md-6 mb-3" sx={{ minWidth: 120 }}>
                    <InputLabel className="form-label">From<sup className="text-danger">*</sup></InputLabel>
                  <TextField
                    className="w-100"
                    variant="outlined"
                    defaultValue={props?.enoticedetais?.eNoticeDetails?.from||null}
                      {...register("from")}
                      error={errors.from}
                  />
                  {errors && errors?.["from"] && (
                  <p className="errorMessage text-danger">
                    {errors?.from?.message}
                  </p>
                )}
                      {/* <FormControl fullWidth>
                        <InputLabel className="form-label">From</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          variant="outlined"
                          className="w-100"
                          id="demo-simple-select"
                          value={chooseFile}
                          onChange={handleChooseFile}
                          label="Choose File"
                        >
                          <MenuItem value={"Choose File"}>
                            {"Choose File"}
                          </MenuItem>
                          <MenuItem value={10}>Member 1</MenuItem>
                          <MenuItem value={20}>Member 2</MenuItem>
                          <MenuItem value={30}>Member 3</MenuItem>
                        </Select>
                      </FormControl> */}
                    </Box>
                    <Box className="col-md-6 mb-3" sx={{ minWidth: 120 }}>
                    <InputLabel className="form-label">From Label</InputLabel>
                  <TextField
                    className="w-100"
                    variant="outlined"
                    defaultValue={props?.enoticedetais?.eNoticeDetails?.fromLabel||null}
                      {...register("fromLabel")}
                      error={errors.fromLabel}
                  />
                  {errors && errors?.["fromLabel"] && (
                  <p className="errorMessage text-danger">
                    {errors?.fromLabel?.message}
                  </p>
                )}
                      {/* <FormControl fullWidth>
                        <InputLabel className="form-label">From Label</InputLabel>
                        <Select
                        variant="outlined"
                        className="w-100"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={chooseFile}
                          onChange={handleChooseFile}
                          label="Choose File"
                        >
                          <MenuItem value={"Choose File"}>
                            {"Choose File"}
                          </MenuItem>
                          <MenuItem value={10}>Member 1</MenuItem>
                          <MenuItem value={20}>Member 2</MenuItem>
                          <MenuItem value={30}>Member 3</MenuItem>
                        </Select>
                      </FormControl> */}
                    </Box>
                    <Box className="col-md-6" sx={{ minWidth: 120 }}>
                    <InputLabel className="form-label">Reply To<sup className="text-danger">*</sup></InputLabel>
                  <TextField
                    className="w-100"
                    variant="outlined"
                    defaultValue={props?.enoticedetais?.eNoticeDetails?.replyTo||null}
                      {...register("replyTo")}
                      error={errors.replyTo}
                  />
                  {errors && errors?.["replyTo"] && (
                  <p className="errorMessage text-danger">
                    {errors?.replyTo?.message}
                  </p>
                )}
                      {/* <FormControl fullWidth>
                        <InputLabel className="form-label">Replay To</InputLabel>
                        <Select
                          variant="outlined"
                          className="w-100"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={chooseFile}
                          onChange={handleChooseFile}
                          label="Choose File"
                        >
                          <MenuItem value={"Choose File"}>
                            {"Choose File"}
                          </MenuItem>
                          <MenuItem value={10}>Member 1</MenuItem>
                          <MenuItem value={20}>Member 2</MenuItem>
                          <MenuItem value={30}>Member 3</MenuItem>
                        </Select>
                      </FormControl> */}
                    </Box>
                    <Box className="col-md-6" sx={{ minWidth: 120 }}>
                    <InputLabel className="form-label">BCC</InputLabel>
                  <TextField
                    className="w-100"
                    variant="outlined"
                    defaultValue={props?.enoticedetais?.eNoticeDetails?.ccList[0]||null}
                      {...register("bcc")}
                      error={errors.bcc}
                  />
                  {errors && errors?.["bcc"] && (
                  <p className="errorMessage text-danger">
                    {errors?.bcc?.message}
                  </p>
                )}
                      {/* <FormControl fullWidth>
                        <InputLabel className="form-label">BCC</InputLabel>
                        <Select
                          variant="outlined"
                          className="w-100"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={chooseFile}
                          onChange={handleChooseFile}
                          label="Choose File"
                        >
                          <MenuItem value={"Choose File"}>
                            {"Choose File"}
                          </MenuItem>
                          <MenuItem value={10}>Member 1</MenuItem>
                          <MenuItem value={20}>Member 2</MenuItem>
                          <MenuItem value={30}>Member 3</MenuItem>
                        </Select>
                      </FormControl> */}
                    </Box>
                  </div>
                </div>
                {/* <Box className="col-md-12" sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel className="form-label">Templete</InputLabel>
                    <Select
                      variant="outlined"
                      className="w-100"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={chooseFile}
                      onChange={handleChooseFile}
                      label="Choose File"
                    >
                      <MenuItem value={"Choose File"}>{"Choose File"}</MenuItem>
                      <MenuItem value={10}>Member 1</MenuItem>
                      <MenuItem value={20}>Member 2</MenuItem>
                      <MenuItem value={30}>Member 3</MenuItem>
                    </Select>
                  </FormControl>
                </Box> */}
              </div>
            }
            <div className="d-flex justify-content-between mt-3 align-items-center btn-section">
              <Button className="btn outline-btn"onClick={() => history.goBack()}>Cancel</Button>
              <div className="d-flex align-items-center">
                <Button type="submit" className="btn btn-orange">
                  Next
                 {/* {clone==="clone"?"Clone List":id==="$"? "Create List":"Update List"} */}
                </Button>
              </div>
            </div>
          </form>
        </div>
        </div>
        </div>
        </div>
)
}
