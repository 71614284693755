const gender = ["All", "Boys", "Girls", "Coordinate","Co-Ed"];
const Pronoun = ["They/Them/Theirs", "She/Her/Hers", "He/Him/His","My pronoun isn't listed", "Prefer not to say"];
const lowestgrade = [
  "Nursery",
  "Pre-Kindergarten",
  "Kindergarten",
  "Middle",
  "Elementary",
  "Preschool",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "Post-Graduate",
  "N/A"
];
const highestgrade = [
  "Nursery",
  "Pre-Kindergarten",
  "Kindergarten",
  "Middle",
  "Elementary",
  "Preschool",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "Post-Graduate",
  "N/A"
];
const sclUserGrp = ["Admin", "Member"];
const sclUserStatus = [
  "Full Member",
  "Provisional Member",
  "Associate Member",
  "Association Member",
  "Non-Member",
];
const sclUserType = [
  "Head of School",
  "Executive Assistant",
  "Board of Trustees",
  "Board Chair",
  "Board Member",
  "Business Office",
  "Business Manager",
  "Controller",
  "General Business Office",
  "Administrative Assistant",
  "Associate Head",
  "Assistant Head",
  "Division Head",
  "Early Childhood",
  "Lower School",
  "Middle School",
  "Upper School",
  "Dean of Faculty",
  "Human Resources Director",
  "Administrator",
  "Academic Dean",
  "Admissions Director",
  "Advancement Director",
  "Technology Director",
  "Technologist",
  "After School Director",
  "Athletic Director",
  "Athletic Office",
  "Communications Director",
  "Communications Office",
  "Diversity Director",
  "Department Chair",
  "Librarian",
  "Teacher",
  "Alumni/ae Office",
  "Guidance",
  "Nurse",
  "Other",
];
const positionType = [
  "Administrator",
  "Coach",
  "Special Education Support",
  "Special Education Teacher",
  "Staff",
  "Substitute Teacher",
  "Teacher",
  "School Psychologist",
  "Speech Therapist",
  "Physical Therapist",
  "Occupational Therapist",
  "Social Worker",
  // "Head of School",
  // "Associate Head of School",
  // "Assistant Head of School",
  // "Board Chair",
  // "Board Member",
  // "Executive Assistant",
  // "Business Manager",
  // "Controller",
  // "Business Office",
  // "Early Childhood Head",
  // "Lower School Head",
  // "Middle School Head",
  // "Upper School Head",
  // "Dean of Faculty",
  // "Human Resources Director",
  // "Admissions Director",
  // "Admissions Office",
  // "Advancement Director",
  // "Advancement Office",
  // "Technology Director",
  // "Technologist",
  // "Athletic Director",
  // "Administrative Assistant",
  // "After School Director",
  // "Communications Director",
  // "Communications Office",
  // "Diversity Director",
  // "Librarian",
  // "Teacher",
  // "Alumni/ae Office",
  // "Guidance",
  // "Department Chair",
  // "Athletic Office",
  // "Academic Dean",
  // "Nurse",
  // "Other",
];
const regionOptions = [
  "Capital District",
  "Central & Western NY",
  "Hudson Valley",
  "Lake Placid",
  "Long Island",
  "New York City",
  "Westchester",
  "N/A"
]

const subRegionOptions = {
  "Capital District":["Capital District"],
  "Central & Western NY":["Buffalo","Rochester","Southern Tier","Syracuse"],
  "Hudson Valley":["Hudson Valley"],
  "Lake Placid":["Lake Placid"],
  "Long Island":["Nassau","Suffolk"],
  "New York City":["Bronx","Manhattan","Brooklyn","Queens","Staten Island"],
  "Westchester":["Westchester"],
  "N/A":["N/A"]
}
const careerTypeOption = ["Full-time", "Part-time", "Temporary"];

const roleArray = [
  "Administrator",
  "Coach",
  "Special Education Support",
  "Special Education Teacher",
  "Staff",
  "Substitute Teacher",
  "Teacher",
  "School Psychologist",
  "Speech Therapist",
  "Physical Therapist",
  "Occupational Therapist",
  "Social Worker",
  // "Head of School",
  // "Executive Assistant",
  // "Board of Trustees",
	// "Board Chair",
	// "Board Member",
  // "Business Office",
	// "Business Manager",
	// "Controller",
	// "General Business Office",
  // "Administrative Assistant",
  // "Associate Head",
  // "Assistant Head",
  // "Division Head",
	// "Early Childhood",
	// "Lower School",
	// "Middle School",
	// "Upper School",
  // "Dean of Faculty",
  // "Human Resources Director",
  // "Administrator",
  // "Academic Dean",
  // "Admissions Director",
  // "Advancement Director",
  // "Technology Director",
  // "Technologist",
  // "After School Director",
  // "Athletic Director",
  // "Athletic Office",
  // "Communications Director",
  // "Communications Office",
  // "Diversity Director",
  // "Department Chair",
  // "Librarian",
  // "Teacher",
  // "Alumni/ae Office",
  // "Guidance",
  // "Nurse",
  // "Other",
]
const SchMembership = [
  "Member",
  "Non member"
]

export {
  gender,
  lowestgrade,
  highestgrade,
  sclUserGrp,
  sclUserType,
  sclUserStatus,
  Pronoun,
  positionType,
  regionOptions,
  subRegionOptions,
  careerTypeOption,
  roleArray,
  SchMembership
};
