import React,{useState} from "react";
import {
  InputLabel,
  TextField,
  Drawer,
  Button,
  FormControlLabel,
  CircularProgress,
  FormGroup,
  Checkbox,
  Select,
  FormControl,
  Box,
  MenuItem,
  Typography,
  Modal,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog ,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toggleSnackbar, userDeatils } from "../../../RactiveVariables/index";
import moment from "moment";
import { useQuery, useMutation,useLazyQuery, useReactiveVar } from "@apollo/client";
import { LIST_ALL_UPDATES,LIST_ALL_POSITIONS,IMAGE_UPLOAD } from "../../../Graphql/queries";
import {CREATE_EVENT_UPDATES} from "../../../Graphql/mutations"
import {position} from "../../Common/Options"
import axios from "axios";
import imageCompression from 'browser-image-compression';
export default function EventUpdates(props) {
  const user = useReactiveVar(userDeatils)
  const [open, setOpen] = useState(false);
  const [subscriber,setSubscriber] = useState("All")
  const [subject, setSubject] = useState('');
  const [message,setMessage] = useState('')
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [imageUploadLoading, setImageUploadLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [publicUrl,setPublicUrl] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [filename,setFilename] = useState('');
  const [errorMessage,setErrorMessage] = useState('');
  const [errorMessageContent,setErrorMessageContent] = useState('');
  const listallupdatedvariables = {
    skip: 0,
    limit: 200,
    eventId: props.schoolId,
  };
  const { data } = useQuery(LIST_ALL_UPDATES, {
    variables: listallupdatedvariables,
  });

  const{data:positions} =useQuery(LIST_ALL_POSITIONS,{variables:{eventId: props.schoolId}})

  const [eventUpdates,{loading}]= useMutation(CREATE_EVENT_UPDATES,{refetchQueries:['listAllEventUpdates'],
    onCompleted:res=>{
    handleClose()
    setSubject('')
    setMessage('')
    setSelectedImage(null);
    setSubscriber('All')
  }})
  const uplaodImageVariables = {
    originalFilename: selectedImage?.fileName,
    path: "/eventupdates",
  };
  const [upload, { data: uploadImageData }] = useLazyQuery(IMAGE_UPLOAD, {
    fetchPolicy: "network-only",
    variables: uplaodImageVariables,
  });
  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  async function uploadImage(params) {
    if(subject.length===0){
      setErrorMessage("Add a subject")
    }
    if(message.length===0){
      setErrorMessageContent("Add a message")
    }
    else{
    setImageUploadLoading(true);
    if (selectedImage) {
      setPublicUrl(uploadImageData?.imageUpload?.publicUrl)
      setFilename(uploadImageData?.imageUpload?.filename)
      let url = uploadImageData?.imageUpload?.signedUrl;
      const response = await axios.put(url, selectedImage.file, {
        headers: {
          "Content-Type": selectedImage?.file?.type,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "X-Requested-With",
        },
      })
      .then(res=>{
         toggleSnackbar({
          status: true,
          message: "File uploaded successfully",
          variant: "success",
        })
      })
    }
    // else{
    //   toggleSnackbar({
    //     status: true,
    //     message: "Please choose a file",
    //     variant: "error",
    //   })
    // }
    setImageUploadLoading(false);
    handleCloseDialog()
    // onSubmit(params);
  }
  }
  function handleUploadClick(event) {
    let obj = {};
    var file = event.target.files[0];
    if (
      !(
        file?.type == "image/jpeg" ||
        file?.type == "image/png" ||
        file?.type == "image/jpg"||
        file?.type == "application/pdf"
      )
    ) {
      toggleSnackbar({
        status: true,
        message: "File does not support. You must use .png, .jpeg, .jpg, .pdf",
        variant: "error",
      });
      return false;
    }
    if (file.size > 5242880) {
      toggleSnackbar({
        status: true,
        message: "Please upload a file smaller than 5 MB",
        variant: "error",
      });
      return false;
    }
    obj["fileName"] = file.name;
    var options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 300,
      useWebWorker: true,
    };
    imageCompression(file, options)
    .then((compressedFile) => {
      obj["url"] = URL.createObjectURL(compressedFile);
    obj["file"] = compressedFile;
    setSelectedImage(obj);
    upload();
    })
    .catch((err) => {
      //console.log("err: ",err)
    })
    
  }
  function createUpdateEvent(){
      let data= {
        eventId:props.schoolId,
        schoolId: [],
        subscribers:  subscriber,
        files:  publicUrl,
        filesName: filename,
        schUsers: [],
        eventUpdateType: "",
        subject: subject,
        status: "",
        content:  message,

      }
      try {
        eventUpdates({variables:{data:data}})
         .then((res) => {
              toggleSnackbar({
             status: true,
             message: "Update sent successfully",
             variant: "success",
           });
         })
         .catch((error) => {
           const message = error && error.message;
           toggleSnackbar({
             status: true,
             message: message,
             variant: "error",
           });
         });
     } catch (error) {
       const message = error && error.message;
       toggleSnackbar({
         status: true,
         message: message,
         variant: "error",
       });
     }
      
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };
  const date = new Date();

  return (
    <div className="container">
                <input type={"file"}
                  className="d-none" 
                    id="fileToEventUpload" 
                    // accept='.csv'
                    onChange={handleUploadClick}
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                    />
          {/* <div>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Choose a jpg/png image file"}
        </DialogTitle>
        <DialogContent>
          
          <DialogContentText id="alert-dialog-description">

          </DialogContentText>
        </DialogContent>
        <DialogActions>

          <Button className="btn btn-orange" onClick={()=>uploadImage()} autoFocus>
          upload
          </Button>
        </DialogActions>
      </Dialog>
    </div> */}
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={style}
            className="eventpreview-modal"
            style={{ padding: "0px" }}
          >
            <div className="event-mail-preview-header">
              <div>
                <img
                  src="../assets/images/login-logo.svg"
                  className="modal-head-imge"
                  alt="logo"
                />
              </div>
              <div>
                <h3 className="modal-head-disc">
                  {subject}
                </h3>
              </div>
              <div>
                <ul className="sub-icons">
                  <li>
                    <img src="../assets/images/calendarWhite.svg" />
                    <span>
                          {
                             moment(date)
                             .utc()
                             .format("MMM D, YYYY")
                          }
                        </span>
                  </li>
                  <li>
                    <img src="../assets/images/clock.svg" />
                    <span>
                          {moment(date, "HH.mm").format(
                            "h.mm A"
                          )}
                         
                        </span>
                  </li>
                  <li>
                    {props?.eventDetails?.eventType==="Remote"?<img src="../assets/images/remote1.svg" />:<img src="../assets/images/profileWhite.svg" />}
                    <span>{props?.eventDetails?.eventType}</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="disc-content">
              <p>{message}</p>
            </div>
            <div className="modal-btn">
              <div className="row">
                <div className="col-md-6">
                  {<Button className=" btn-snd" onClick={createUpdateEvent}>{loading?<CircularProgress  size="20px" color="inherit" />:"Send"}</Button>}
                </div>
                <div className="col-md-6">
                  <Button className=" btn-cancel" onClick={handleClose}>
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
      {user.data.loginType==="Admin" && <div
        className="card preview-card mb-4 my-events-update"
        style={{ marginLeft: "90px", marginRight: "160px" }}
      >
        <div className="card-body ">
          <h2 className="main-title">Post an Update</h2>
          <div className="row">
            <div className="col-md-6">
              <InputLabel className="form-label">
                Subject<sup className="text-danger">*</sup>
                </InputLabel>
              <TextField
                style={{ width: "100%" }}
                variant="outlined"
                value={subject}
                onChange={(e)=>{setSubject(e.target.value);setErrorMessage(null)}}
                // {...register("Address1")}
                // error={errors.Address1}
                placeholder="Type in your subject text here"
              />
              {errorMessage && (
                  <p className="errorMessage text-danger">{errorMessage}</p>
                )}
              {/* {errors && errors?.["Address1"] && (
                  <p className="errorMessage text-danger">
                    {errors?.Address1?.message}
                  </p>
                )} */}
            </div>
            <div className="col-md-6">
              <FormControl fullWidth>
                <label
                  id="demo-simple-select-label"
                  style={{
                    fontSize: "12px",
                    color: "#4165ac",
                    marginBottom: "3px",
                  }}
                >
                  Attendees
                </label>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={subscriber}
                  label="Age"
                  onChange={(e)=>setSubscriber(e.target.value)}
                >
                  <MenuItem value={"All"}>{"All"}</MenuItem>
                  {positions?.listAllTicketPositions?.map((item) =>(
                   item?.position&& <MenuItem value={item?.position}>{item?.position}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="col-md-12 mt-3">
              <InputLabel className="form-label">
                Message<sup className="text-danger">*</sup>
                </InputLabel>
              <TextField
                style={{ width: "100%" }}
                variant="outlined"
                multiline
                value={message}
                // {...register("Address1")}
                // error={errors.Address1}
                onChange={(e)=>{setMessage(e.target.value);setErrorMessageContent(null)}}
                placeholder="Type in your message"
              />
              {errorMessageContent && (
                  <p className="errorMessage text-danger">{errorMessageContent}</p>
                )}
            </div>
            <div className="col-md-6 mt-3 d-flex justify-content-begining">

            <Button className="btn btn-orange" onClick={() =>
                    document.getElementById("fileToEventUpload").click()
                  } >
                  Add Files
            </Button><span style={{marginLeft:"5px"}}>{selectedImage?.file?.name || null}</span>
              {/* <Button className="btn btn-orange" onClick={handleOpen}>
                Add Files
              </Button> */}
            </div>
            {/* <div className="col-md-6 mt-3 d-flex justify-content-begining">
              <Button className="btn btn-orange" onClick={()=>uploadImage()}>
                Add Links
              </Button>
            </div> */}
            <div className="col-md-6 mt-3 d-flex justify-content-end">
              <Button className="btn btn-orange" onClick={()=>{subject&&message&&handleOpen();uploadImage()}}>
                Preview/Send
              </Button>
            </div>
          </div>
        </div>
      </div>}
      {data?.listAllEventUpdates?.data&&data?.listAllEventUpdates?.data.map((item) =>(
        <div
        className="card preview-card mb-4 my-events-update"
        style={{ marginLeft: "90px", marginRight: "160px" }}
      >
        <div className="card-sub">
          <p className="sub-head">
            {item.subject}
          </p>
          <p className="sub-desc">
            {item.content}
          </p>
          <ul className="sub-icons">
            <li>
              <img src="../assets/images/previewcalender.svg" />
              <span>
                          {
                             moment(item?.createdDate)
                             .utc()
                             .format("MMM D, YYYY")
                          }
                        </span>
            </li>
            <li>
              <img src="../assets/images/previewclock.svg" />
              <span>
              {moment(item?.createdDate).format(
                            "h.mm A"
                          )}
                         
                        </span>
            </li>
            <li>
            {props?.eventDetails?.eventType==="Remote"?<img src="../assets/images/remote2.svg" />:<img src="../assets/images/profilehead.svg" />}
              <span>{item?.eventDtls?.eventType}</span>
            </li>
          </ul>
        </div>
      </div>
      ))}
      {
        data?.listAllEventUpdates?.data.length===0 && <div className="d-flex justify-content-center p-4 align-items-center" style={{background:"white"}}><p>No updates for this event</p></div>
      }
    </div>
  );
}
