import { useParams } from "react-router-dom";
import { useQuery, useMutation, useReactiveVar, useLazyQuery } from "@apollo/client";
import { EVENT_DETAILS, SCHOOL_DETAILS } from "../../Graphql/queries";
import { Loader } from "../Common";
import { CREATE_ORDER } from "../../Graphql/mutations";
import { userDeatils, toggleSnackbar, authVar, registerEvent } from "../../RactiveVariables";
import { useHistory } from "react-router-dom";
import moment from "moment";
export default function CreateOrder(props) {
  const { id, uid } = useParams();
  const user = useReactiveVar(userDeatils);
  const auth = useReactiveVar(authVar);
  const history = useHistory();  
  const [createOrders, { loading }] = useMutation(CREATE_ORDER);
  const [getSchoolDeatils] = useLazyQuery(SCHOOL_DETAILS);
  const { data: eventData } = useQuery(EVENT_DETAILS, {
    variables: { id: id },
    onCompleted: (res) => {
      if(auth.auth){
      getSchoolDeatils({variables:{
        id:user?.data?.schoolId
      }}).then((schDtls)=>{
        let data = {
          orderCreatedFrom : "FrontEnd",
          eventId: id,
          memberPrice: schDtls?.data?.schoolDetails?.schStatus==="Full Member" ? parseFloat(res?.eventDetails?.memberPrice) : parseFloat(res?.eventDetails?.nonMemberPrice),
          totalMemCount: 1,
          totalMemCount: 0,
          nonMemberPrice: parseFloat(res?.eventDetails?.nonMemberPrice),
          orderDate: moment().format("MM/DD/YYYY"),
          schoolId: user?.data?.schoolId,
          orderCreatedFor: user?.data?._id,
          orderType: "Normal",
          paymentMethod: "Card",
          orderUuid:uid,
        };
        createOrders({ variables: { data } }).then((orderRes) => {
          if(!orderRes?.errors){
            toggleSnackbar({
              status: true,
              message: "Order has been saved as draft",
              variant: "success",
            });
            history.push(`/eventregistration/${orderRes?.data?.createOrders?._id}`);
          }
          else if (orderRes?.errors?.message == "This Event is only for NYSAIS Members"){
            toggleSnackbar({
              status: true,
              message: orderRes?.errors?.message,
              variant: "error",
            });
            history.push(`/myevents`);
          }
          else
            history.push(`/eventregistration/${orderRes?.errors?.message}`);
        }).catch((err)=>{
          if(err?.graphQLErrors?.[0]?.message === "This Event is only for NYSAIS Members"){
          toggleSnackbar({
            status: true,
            message: err?.graphQLErrors?.[0]?.message,
            variant: "error",
          });
          history.push(`/myevents`);
        }
        else{
          history.push(`/eventregistration/${err?.graphQLErrors?.[0]?.message}`);
        }
        })
      })
    }
  else{
    registerEvent({
      eventId: id,
      memberPrice: res?.eventDetails?.memberPrice,
      nonMemberPrice: res?.eventDetails?.nonMemberPrice,
    });
    history.push("/schoollogin");
  }
    },
  });
  return (
    <div>
      <Loader open={true} />
    </div>
  );
}
