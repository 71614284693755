import { useState } from "react";
import { useQuery } from "@apollo/client";
import { FINANCE_EVENT_DETAILS,LIST_ALL_SCHOOL } from "../../Graphql/queries";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  FormControl,
  InputLabel,
  Pagination,
  CircularProgress,
  Autocomplete,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import moment from "moment";
import { formatCurrency } from "../Common/GeneralFunctions";
import { useHistory, Link } from "react-router-dom";
export default function EventDetails(props) {
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [dateFrom, setDateFrom] = useState(moment(new Date()).subtract(1, 'month'));
  const [dateTo, setDateTo] = useState(new Date());
  const [page, setPage] = useState(1);
  const [loadingCSV,setLoadingCSV] = useState(false);
  const [csvData,setCsvData] = useState('')
  const [memberType, setMemberType] = useState("");
  const [school,setSchool] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const variables = {
    school: school,
    memberType: memberType,
    eventId: props?.id,
    skip: (page - 1) * rowsPerPage,
    limit: rowsPerPage,
  };
  const { data: schoolList } = useQuery(LIST_ALL_SCHOOL,{variables:{allSch: true}});
  const { data: financeData, loading } = useQuery(FINANCE_EVENT_DETAILS, {
    fetchPolicy: "network-only",
    variables: variables,
  });

  const handleChangePage = (e, page) => {
    setPage(page);
  };
  function handlePageInputChange(event) {
    setRowsPerPage(Number(event.target.value));
    setPage(1);
  }
  function downloadTxtFile () {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      "school":school||"",
      "eventId":props?.id,
    });
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    fetch(`${process.env.REACT_APP_API_URL}/finance/financeEventReport`, requestOptions)
    .then(response => response.text())
      .then(result => {
        const element = document.createElement("a");
        const file = new Blob([result], {type: 'text/csv'});
        element.href = URL.createObjectURL(file);
        element.download = "myFile.csv";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
        setLoadingCSV(false)
      })
      .catch(error => console.log('error', error));

   

  }
  const shortDtls = [
    {
      label: "Total Orders",
      img: "../assets/images/totoalOrder.svg",
      value: "totalOrder",
    },
    {
      label: "Total Registrants",
      img: "../assets/images/totalReg.svg",
      value: "totalRegistrant",
    },
    {
      label: "Total Cancellations",
      img: "../assets/images/totalCancel.svg",
      value: "totalCancel",
    },
    {
      label: "Total Amount",
      img: "../assets/images/totalAmt.svg",
      value: "totalAmt",
      formatCurrency:true
    },
    {
      label: "Total Refund ",
      img: "../assets/images/totalRefund.svg",
      value: "totalRefund",
      formatCurrency:true
    },
  ];
  
  return (
    <>
      <div className="finance-order-outer">
        <div>
          <div className="align-items-center d-flex justify-content-between">
            {shortDtls.map((item) => (
              <div className="text-center">
                <div className="order-short-details">
                  <img src={item.img} className="text-center" />
                  <p className="main">{item.label}</p>
                  <p className="sub">
                    {
                      item.formatCurrency === true ? "$"+formatCurrency(financeData?.financeEventOrders?.singleEventDashBoard?.[
                        item.value
                      ]):
                      financeData?.financeEventOrders?.singleEventDashBoard?.[
                        item.value
                      ]
                    }
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="mt-4">
          <TableContainer component={Paper}>
            <div className="d-flex align-items-center justify-content-between filter-section">
              <h2 className="ordercnt">
                {financeData?.financeEventOrders?.count || "-" + " "} Listings
              </h2>
              <div className="d-flex align-items-center">
              <Box className="ms-2" sx={{ minWidth: 177 }}>
                  <FormControl fullWidth>
                    <InputLabel className="filter-label" id="category">
                      Member Type
                    </InputLabel>
                    <Select className="filter-select" label="Member Type"
                    onChange={(e) => {setPage(1);setMemberType(e.target.value)}}
                    > 
                      <MenuItem value="All">{"All"}</MenuItem>
                      <MenuItem value="Member">{"Member"}</MenuItem>
                      <MenuItem value="Non-Member">{"Non -Member"}</MenuItem>
                      <MenuItem value="Individual">{"Individual"}</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              {/* <Box className="ms-2" sx={{ minWidth: 177 }}>
                  <FormControl fullWidth>
                    <DesktopDatePicker
                      inputFormat="MM/dd/yyyy"
                      value={dateFrom ? dateFrom : null}
                      renderInput={(params) => (
                        <TextField {...params} className="date-field" />
                      )}
                      onChange={(e) => setDateFrom(e)}
                    />
                  </FormControl>
                </Box> */}
                <Box className="ms-2" sx={{ minWidth: 177 }}>

                    {/* <InputLabel className="filter-label" id="category">
                      School
                    </InputLabel>
                    <Select
                      className="filter-select"
                      label="School"
                      onChange={(e) => setSchool(e.target.value)}
                    >
                      <MenuItem value="">{"None"}</MenuItem>
                      {schoolList?.listSchool?.data && schoolList?.listSchool?.data?.map((item) => (
                        <MenuItem value={item.schName}>{item.schName}</MenuItem>
                      ))}
                    </Select> */}
                    <Autocomplete
                        disablePortal
                        className="table-autocomplete-field"
                        // id="combo-box-demo"
                        // variant="outlined"
                        options={schoolList?.listSchool?.data}
                        getOptionLabel={(option) => option.schNameThe}
                        // value= {}
                        onChange={(e,value)=>{setPage(1);setSchool(value?._id)}}
                        // sx={{ width: "100%" }}
                        renderInput={(params) => (
                          <TextField {...params} 
                          placeholder="School"/>
                        )}
                      />

                </Box>
              <a
                href="javascript:;"
                className="btn btn-orange d-flex align-items-center ms-2"
                onClick={()=>{downloadTxtFile();setLoadingCSV(true);}}
              >
                <img className="me-1" src="../assets/images/download.svg" />
                {loadingCSV===true? <CircularProgress size="20px" color="inherit"  /> :  "Download"}
              </a>
              </div>
            </div>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Order Id</TableCell>
                  <TableCell>Registrant</TableCell>
                  <TableCell>School/Membership</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <p> Loading Events Details...</p>
                      </div>
                    </TableCell>
                  </TableRow>
                ) : financeData?.financeEventOrders?.evtRes &&
                  financeData?.financeEventOrders?.evtRes.length > 0 ? (
                  financeData?.financeEventOrders?.evtRes.map((item) => (
                    <TableRow key={item.orderNo}>
                      <TableCell style={{cursor:"pointer"}} onClick={()=>{history.push({pathname:`/orderdetails/${item?._id}`,state: { detail: 'finance' }});}}>#{item.orderNo}</TableCell>
                      <TableCell>{item.memberName}<small className="d-block" style={{fontWeight:"bold"}}>{item.position}</small></TableCell>
                      <TableCell>{item.schoolName}<small className="d-block" style={{fontWeight:"bold"}}>{item.schStatus}</small></TableCell>
                      <TableCell>{item.status==="Refunded"? "-$ "+item?.grandTotal:"$"+item?.grandTotal}</TableCell>
                      <TableCell>{item.status==="Open"?"Draft":item.status}</TableCell>
                    </TableRow>
                  ))
                ) : search ? (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <p>No Events details Found</p>
                      </div>
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <p>No Events details Yet!</p>
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            {!loading && (
              <div className="pagination-outer">
                <div className="count-selector">
                  <Select
                    // variant="outlined"
                    onChange={handlePageInputChange}
                    value={rowsPerPage}
                  >
                    <MenuItem value={10}>{10}</MenuItem>
                    <MenuItem value={25}>{25}</MenuItem>
                    <MenuItem value={50}>{50}</MenuItem>
                    <MenuItem value={100}>{100}</MenuItem>
                  </Select>
                  <img
                    className="count-arrow"
                    src="../../assets/images/arrowupdown.svg"
                  />
                </div>

                <Pagination
                  count={Math.ceil(
                    financeData?.financeEventOrders?.count / rowsPerPage
                  )}
                  page={page}
                  shape="rounded"
                  onChange={handleChangePage}
                />
              </div>
            )}
          </TableContainer>
        </div>
      </div>
    </>
  );
}
