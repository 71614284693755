import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  InputLabel,
  TextField,
  Drawer,
  Button,
  CircularProgress,
  Select,
  MenuItem,
  Avatar,
} from "@mui/material";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { ADD_LOCATION, EDIT_LOCATION } from "../../Graphql/mutations";
import { toggleSnackbar } from "../../RactiveVariables/index";
import { GET_ALLUSERGROUP, IMAGE_UPLOAD } from "../../Graphql/queries";
import axios from "axios";
import { AutoCompleteLocation } from "../Common";
import imageCompression from 'browser-image-compression';

export default function AddLocation(props) {
  const [addLocation, { loading }] = useMutation(ADD_LOCATION);
  const [editLocation, { loading: editLoading }] = useMutation(EDIT_LOCATION);
  //   const { data: usergroups } = useQuery(GET_ALLUSERGROUP);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUploadLoading, setImageUploadLoading] = useState(false);
  const uplaodImageVariables = {
    originalFilename: selectedImage?.fileName,
    path: "/userprofile",
  };
  const [upload, { data: uploadImageData }] = useLazyQuery(IMAGE_UPLOAD, {
    fetchPolicy: "network-only",
    variables: uplaodImageVariables,
  });
  const schema = yup.object().shape({
    locName: yup.string().required("Location name is required"),
    streetAdrs: yup.string().required("Address is required"),
    city: yup.string().required("City is required"),
    state: yup.string().required("State is required"),
    zip: yup.string().required("zip is required"),
  });
  const { register, handleSubmit, formState, reset, control, setValue } = useForm({
    defaultValues: props.selectedItem,
    resolver: yupResolver(schema),
  });
  const { errors } = formState;

  async function uploadImage(params) {
    setImageUploadLoading(true);
    if (selectedImage) {
      let url = uploadImageData?.imageUpload?.signedUrl;
      const response = await axios.put(url, selectedImage.file, {
        headers: {
          "Content-Type": selectedImage?.file?.type,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "X-Requested-With",
        },
      });
    }
    setImageUploadLoading(false);
    onSubmit(params);
  }
  const onSubmit = (params) => {
    if (selectedImage?.imageFile !== null)
      params["locImage"] = uploadImageData?.imageUpload?.publicUrl;
    else params["locImage"] = "";
    params.password = "abcd"; //temporary fix need to remove
    if (Object.keys(props.selectedItem).length > 0) {
      try {
        params._id = props?.selectedItem?._id;
        editLocation({ variables: params })
          .then((res) => {
            toggleSnackbar({
              status: true,
              message: "Location successfully Updated",
              variant: "success",
            });
            props.handlePagination();
            props.setOpen(false);
            props.setSelectedItem(null);
            setSelectedImage(null);
            closeDrawer();
          })
          .catch((error) => {
            const message = error && error.message;
            toggleSnackbar({
              status: true,
              message: message,
              variant: "error",
            });
          });
      } catch (error) {
        const message = error && error.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      }
    } else {
      try {
        addLocation({ variables: params })
          .then((res) => {
            toggleSnackbar({
              status: true,
              message: "Location added successfully",
              variant: "success",
            });
            if (props.event) {
              props.handleAddLocation(res?.data?.addOffLocations);
            } else {
              props.handlePagination();
            }
            props.setOpen(false);
            closeDrawer();
          })
          .catch((error) => {
            const message = error && error.message;
            toggleSnackbar({
              status: true,
              message: message,
              variant: "error",
            });
          });
      } catch (error) {
        const message = error && error.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      }
    }
  };

  function closeDrawer() {
    props.setOpen(false);
    props.setSelectedItem && props.setSelectedItem(null);
    setSelectedImage(null);
  }
  useEffect(() => {
    reset();
  }, [props.selectedItem]);

  function handleUploadClick(event) {
    let obj = {};
    var file = event.target.files[0];
    if (
      !(
        file?.type == "image/jpeg" ||
        file?.type == "image/png" ||
        file?.type == "image/jpg"
      )
    ) {
      toggleSnackbar({
        status: true,
        message: "File does not support. You must use .png or .jpeg",
        variant: "error",
      });
      return false;
    }
    if (file.size > 5242880) {
      toggleSnackbar({
        status: true,
        message: "Please upload a file smaller than 5 MB",
        variant: "error",
      });
      return false;
    }
    obj["fileName"] = file.name;
    var options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 300,
      useWebWorker: true,
    };
    imageCompression(file, options)
    .then((compressedFile) => {
      
    obj["url"] = URL.createObjectURL(compressedFile);
    obj["file"] = compressedFile;
    setSelectedImage(obj);
    upload();
    })
    .catch((err) => {
      //console.log("err: ",err)
    })
  }
  const location_register = register("streetAdrs");
  return (
    <div>
      <input
        id="add-user-location-input"
        type="file"
        hidden
        onChange={handleUploadClick}
        onClick={(event) => {
          event.target.value = null;
        }}
      />
      <Drawer anchor={"right"} open={props.open} onClose={closeDrawer}>
        <div className="sidebar-toggler" role="presentation">
          <div className="title-outer">
            <h1>
              {Object.keys(props.selectedItem).length > 0
                ? "Edit Location"
                : "Add Location"}
            </h1>
            <div onClick={closeDrawer} className="close-icon">
              X
            </div>
          </div>
          <div className="userform-scroller">
            <div className="d-flex justify-content-center user-outer">
              <div className="avatar-outer">
                <Avatar
                  src={
                    selectedImage
                      ? selectedImage.url
                      : props?.selectedItem?.locImage
                      ? props?.selectedItem?.locImage
                      : "/broken-image.jpg"
                  }
                  onClick={(e) =>
                    document.getElementById("add-user-location-input").click()
                  }
                  sx={{ width: 110, height: 110 }}
                />
                <span
                  onClick={() =>
                    document.getElementById("add-user-location-input").click()
                  }
                >
                  +
                </span>
              </div>
            </div>
            <form
              className="adduser-form"
              onSubmit={handleSubmit(uploadImage)}
              noValidate
            >
              <div className="row">
                <div className="col-md-12 mb-3">
                  <InputLabel className="form-label">
                    location Name<sup className="text-danger">*</sup>
                  </InputLabel>
                  <TextField
                    variant="outlined"
                    {...register("locName")}
                    error={errors.locName}
                    autoFocus
                    defaultValue={props?.selectedItem?.locName}
                  />
                  {errors && errors?.["locName"] && (
                    <p className="errorMessage text-danger">
                      {errors?.locName?.message}
                    </p>
                  )}
                </div>
                <div className="col-md-12 auto-complete mb-3">
                  <InputLabel className="form-label">
                    Address<sup className="text-danger">*</sup>
                    </InputLabel>
                  <AutoCompleteLocation
                    className="w-100"
                    location_register={location_register}
                    register={register}
                    setValue={setValue}
                    defaultValue={props?.selectedItem?.streetAdrs}
                    variant="outlined"
                  />
                  {errors && errors?.["streetAdrs"] && (
                    <p className="errorMessage text-danger">
                      {errors?.streetAdrs?.message}
                    </p>
                  )}
                </div>
                <div className="col-md-4">
                  <InputLabel className="form-label">
                    City<sup className="text-danger">*</sup>
                    </InputLabel>
                  <Controller
                    name="city"
                    control={control}
                    defaultValue={props?.selectedItem?.city}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        onChange={(event, value) => {
                          onChange(value);
                        }}
                        variant="outlined"
                        value={value}
                      />
                    )}
                  />
                  {errors && errors?.["city"] && (
                    <p className="errorMessage text-danger">
                      {errors?.city?.message}
                    </p>
                  )}
                </div>
                <div className="col-md-4">
                  <InputLabel className="form-label">
                    State<sup className="text-danger">*</sup>
                    </InputLabel>
                  <Controller
                    name="state"
                    control={control}
                    defaultValue={props?.selectedItem?.state}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        onChange={(event, value) => {
                          onChange(value);
                        }}
                        variant="outlined"
                        value={value}
                      />
                    )}
                  />
                  {errors && errors?.["state"] && (
                    <p className="errorMessage text-danger">
                      {errors?.state?.message}
                    </p>
                  )}
                </div>
                <div className="col-md-4">
                  <InputLabel className="form-label">
                    Zip<sup className="text-danger">*</sup>
                    </InputLabel>
                  <Controller
                    name="zip"
                    control={control}
                    defaultValue={props?.selectedItem?.zip}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        onChange={(event, value) => {
                          onChange(value);
                        }}
                        variant="outlined"
                        value={value}
                      />
                    )}
                  />
                  {errors && errors?.["zip"] && (
                    <p className="errorMessage text-danger">
                      {errors?.zip?.message}
                    </p>
                  )}
                </div>

                <div className="col-md-12 mt-3">
                  <InputLabel className="form-label">Description</InputLabel>
                  <div className="textarea-outer">
                    <TextField
                      {...register("description")}
                      variant="outlined"
                      multiline
                      rows={8}
                      defaultValue={props?.selectedItem?.description}
                    />
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-between align-items-center btn-section">
                <Button className="outline-btn" onClick={() => closeDrawer()}>
                  Cancel
                </Button>
                <div className="d-flex align-items-center">
                  <Button type="submit" className="highlight-btn">
                    {loading || editLoading || imageUploadLoading ? (
                      <CircularProgress size="20px" color="inherit" />
                    ) : Object.keys(props.selectedItem).length > 0 ? (
                      "Edit Location"
                    ) : (
                      "Add Location"
                    )}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Drawer>
    </div>
  );
}
