import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Switch,
  Button,
  Avatar,
  TablePagination,
  Pagination,
  MenuItem,
  Select,
} from "@mui/material";
import { toggleSnackbar } from "../../RactiveVariables";
import { useQuery, useMutation } from "@apollo/client";
import { LIST_FOURMS_MESSAGE } from "../../Graphql/queries";
import { useState } from "react";
import AddConversation from "./AddConversations";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";
import { DELETE_FOURM_MESSAGES } from "../../Graphql/mutations";
import DeleteIcon from "@mui/icons-material/Delete";
import { ConfirmDialog } from "../Common";

export default function Message(props) {
  const [deleteId, setDeleteId]= useState(null);
  const { isManager, postAcs, user } = props;
  const { id } = useParams();
  const history = useHistory();
  const [openAdd, setOpenAdd] = useState(false);
  const [searchVariable, setSearchVariable] = useState(null);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [deleteForumMessage, { loading: deleteLoading }] = useMutation(
    DELETE_FOURM_MESSAGES,
    {
      onCompleted: (res) => {
        refetch();
        toggleSnackbar({
          status: true,
          message: "Conversation deleted successfully",
          variant: "success",
        });
      },
    }
  );

  const { data, refetch, loading } = useQuery(LIST_FOURMS_MESSAGE, {
    fetchPolicy: "network-only",
    variables: {
      skip: (page - 1) * rowsPerPage,
      limit: rowsPerPage,
      search: searchVariable,
      id: id,
      inType: "mainMsgs",
    },
  });

  const handleChangePage = (e, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };
  function handlePageInputChange(event) {
    setRowsPerPage(Number(event.target.value));
    setPage(1);
  }
  function closeConfirm() {
    setDeleteId(null);
  }
  function handleConfirm() {
    deleteForumMessage({ variables: { ids: [deleteId] } })
    closeConfirm();
  }
  return (
    <div className="card border-0">
      {openAdd && <AddConversation
        open={openAdd}
        setOpen={setOpenAdd}
        forumId={id}
        refetch={refetch}
      />}
      <ConfirmDialog
        open={deleteId}
        title={"Delete"}
        body={"Are you sure you want to delete this conversation ? "}
        closeText={"Cancel"}
        confirmText={"Delete"}
        closeConfirm={closeConfirm}
        handleConfirm={handleConfirm}
      />
      <div className="card-body p-0">
        <div className="filter-section d-md-flex justify-content-between">
          <div className="d-flex align-items-center">
          {data?.listAllForumMsgs&&<p  className="me-2 mb-0">{`${data?.listAllForumMsgs?.count} Conversations`}</p>}
            
          </div>
          <div className="d-flex align-items-center justify-content-between justify-content-center-md-center">
          <div>
          <img
              className="search-icon"
              src="../assets/images/search.svg"
              alt="search icon"
            />
            <input
              className="searchbox w-175"
              placeholder="Search by conversation name..."
              type="text"
              value={searchVariable}
              onChange={(e) => {
                setPage(1);
                setSearchVariable(e.target.value);
              }}
            />
            </div>
            {(postAcs || user.data.loginType === 'Admin' ) && (
              <Button
                role="button"
                type="button"
                className="btn add-btn"
                onClick={() => setOpenAdd(true)}
              >
                + Add conversation
              </Button>
            )}
          </div>
        </div>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Conversations</TableCell>
                <TableCell>Created by</TableCell>
                <TableCell>Date</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <p> Loading conversation...</p>
                    </div>
                  </TableCell>
                </TableRow>
              ) : data?.listAllForumMsgs?.data &&
                data?.listAllForumMsgs?.data.length > 0 ? (
                data?.listAllForumMsgs?.data.map((item) => (
                  <TableRow key={item._id}>
                    <TableCell
                      className="cursor-pointer"
                      onClick={() =>
                        history.push(`/conversation/${item._id}&${id}`)
                      }
                    >
                      {item.subject}
                    </TableCell>
                    <TableCell
                      className="cursor-pointer"
                      onClick={() =>
                        history.push(`/conversation/${item._id}&${id}`)
                      }
                    >
                      <div className="d-flex align-items-center">
                        <Avatar
                          src={
                            item?.createdByDtls?.profileImage
                              ? item?.createdByDtls?.profileImage
                              : "/broken-image.jpg"
                          }
                        />
                        <div className="ms-3">
                          {item?.createdByDtls?.firstName +
                            " " +
                            item?.createdByDtls?.lastName}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell
                      className="cursor-pointer"
                      onClick={() =>
                        history.push(`/conversation/${item._id}&${id}`)
                      }
                    >
                      {moment(item.createdDate).format("MMM DD, YYYY")}
                    </TableCell>
                    {(isManager || user.data.loginType === 'Admin') && <TableCell>
                      <DeleteIcon
                        className="cursor-pointer"
                        onClick={() =>
                          setDeleteId(item._id)
                        }
                      />
                    </TableCell>}
                  </TableRow>
                ))
              ) : searchVariable ? (
                <TableRow>
                  <TableCell colSpan={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <p>No Conversation Found</p>
                    </div>
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow>
                  <TableCell colSpan={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <p>No conversation Added Yet!</p>
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {!loading && (
            <div className="pagination-outer">
              <div className="count-selector">
                <Select
                  // variant="outlined"
                  onChange={handlePageInputChange}
                  value={rowsPerPage}
                >
                  <MenuItem value={10}>{10}</MenuItem>
                  <MenuItem value={25}>{25}</MenuItem>
                  <MenuItem value={50}>{50}</MenuItem>
                  <MenuItem value={100}>{100}</MenuItem>
                </Select>
                <img
                  className="count-arrow"
                  src="../../assets/images/arrowupdown.svg"
                />
              </div>

              <Pagination
                count={Math.ceil(data?.listAllForumMsgs?.count / rowsPerPage)}
                page={page}
                shape="rounded"
                onChange={handleChangePage}
              />
            </div>
          )}
        </TableContainer>
      </div>
    </div>
  );
}
