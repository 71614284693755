import React, { useEffect } from "react";
import { useState } from "react";
import { useParams,Link } from "react-router-dom";
import moment from "moment";
import { useQuery, useReactiveVar } from "@apollo/client";
import { schoolDtls } from "../../../RactiveVariables";
import { EVENT_DETAILS } from "../../../Graphql/queries";
import ReactHtmlParser from "react-html-parser";

import EventHeader from "../../Common/EventHeader";
export default function EventPreview(props) {
  const { id } = useParams();
  const schoolDetails = useReactiveVar(schoolDtls);
  const { data: eventData } = useQuery(EVENT_DETAILS, {
    fetchPolicy:"network-only",
    variables: { id: id },
  });
  const eventDetails = eventData?.eventDetails;

  return (
    <div>
      <EventHeader
        title="Events"
        schooleventpreview
        id={id}
        schoolId={schoolDetails?.schoolId ? schoolDetails?.schoolId : eventDetails?.schoolId}
      />
      <div className="preview-outer pt-40">
        <div className="container">
          <div className="row">
            <div className="col-sm-8">
              <div className="card preview-card preview mb-4">
                {
                  eventDetails?.banner.length > 0 ? <img className="preview-banner" src={eventDetails?.banner.map((item) => item?.url)} /> :  <img className="preview-banner no" src="../assets/images/bannernoimage.svg" />
                }
               
                <div className="card-body">
                  <h2 className="main-title">{eventDetails?.title}</h2>
                  <p className="sub-title">
                    Target Audience: {eventDetails?.targetAudience}
                    {/* roles {item.category} */}
                  </p>
                  <ul className="event-specifications mb-0">
                    <li>
                      <img src="../assets/images/previewcalender.svg" />
                      <span>
                        {moment(eventDetails?.startDate)
                          .format("MMM D, YYYY") === moment(eventDetails?.endDate)
                          .format("MMM D, YYYY") ? moment(eventDetails?.startDate)
                          .format("MMM D, YYYY") : moment(eventDetails?.startDate)
                          .format("MMM D, YYYY")+ " to "  +moment(eventDetails?.endDate)
                            .format("MMM D, YYYY")}
                      </span>
                    </li>
                    <li>
                      <img src="../assets/images/previewclock.svg" />
                      <span>
                        {moment(eventDetails?.startTime, "HH.mm").format(
                          "h.mm A"
                        )}{" "}
                        to{" "}
                        {moment(eventDetails?.endTime, "HH.mm").format(
                          "h.mm A"
                        )}
                      </span>
                    </li>
                  </ul>
                  <div className="disc-tile">
                    <h2>DESCRIPTION</h2>
                    <p>{ReactHtmlParser(eventDetails?.description)}</p>
                  </div>
                  {/* <div className="disc-tile">
                      <h2>DISCLAIMER</h2>
                      <p>
                        "Lorem ipsum dolor sit amet, consectetur adipiscing
                        elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua. Ut enim ad minim veniam, quis
                        nostrud exercitation ullamco laboris nisi ut aliquip ex
                        ea commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit
                        anim id est laborum."
                      </p>
                    </div> */}
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="card preview-card border-0 mb-4">
                <div className="card-title">
                  <h4>Event Details</h4>
                  <Link to={`/addschoolevent/${schoolDetails?.schoolId ? schoolDetails?.schoolId : eventDetails?.schoolId}||${id}`}>
                    <img src="../assets/images/previewedit.svg" />
                  </Link>
                </div>
                <div className="card-body py-0">
                  <ul className="list-elements">
                    <li>
                      <p className="list-label">Status</p>
                      <p className="list-value">
                        <span className="badge badge-primary">
                          {eventDetails?.status === "Draft" ? "Unpublished" : eventDetails?.status}
                        </span>
                      </p>
                    </li>
                    <li>
                      <p className="list-label">Starting From</p>
                      <p className="list-value">
                        {moment(eventDetails?.startDate)
                          .format("MMM D, yyyy")}
                      </p>
                    </li>
                    <li>
                      <p className="list-label">Ending On</p>
                      <p className="list-value">
                        {moment(eventDetails?.endDate)
                          .format("MMM D, yyyy")}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              {(eventDetails?.eventType ==="Virtual" || eventDetails?.eventType ==="Hybrid") && <div className="card preview-card border-0 mb-4">
                <div className="card-title mb-0">
                  <h4>Meeting Link</h4>
                  <Link to={`/addschoolevent/${schoolDetails?.schoolId ? schoolDetails?.schoolId : eventDetails?.schoolId}||${id}`}>
                    <img src="../assets/images/previewedit.svg" />
                  </Link>
                </div>
                <div className="card-body">
                  <a className="list-label link" href={eventDetails?.schOnLocDtls}>
                    Meeting Link
                  </a>
                </div>
              </div>}
              {(eventDetails?.eventType ==="In-Person" || eventDetails?.eventType ==="Hybrid") && eventDetails?.schLocDtls&& <div className="card preview-card border-0 mb-4">
                <div className="card-title mb-0">
                  <h4>Location</h4>
                  <Link to={`/addschoolevent/${schoolDetails?.schoolId ? schoolDetails?.schoolId : eventDetails?.schoolId}||${id}`}>
                    <img src="../assets/images/previewedit.svg" />
                  </Link>
                </div>
                <div className="card-body">
                  <p className="list-label">
                  {eventDetails?.schLocDtls}
                  </p>
                </div>
              </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
