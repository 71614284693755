import { useEffect, useState } from "react"
import { useParams,useHistory } from "react-router-dom";
import { authVar, schoolDtls, userDeatils } from "../../RactiveVariables";
import { useLazyQuery, useQuery } from "@apollo/client";
import { GET_CURRENT_USER } from "../../Graphql/queries";

export const MobAppRedirection=()=>{
    const {id,token}= useParams()
    const history = useHistory();
    const [CurrentUserDetails,{ currentUser, loading, }] = useLazyQuery(GET_CURRENT_USER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      userDeatils({
        data: res.currentUserDtls,
      });
      schoolDtls({
        data: res.currentUserDtls?.schoolDetails,
        schoolId: res.currentUserDtls?.schoolId
      })
      authVar({
        loaded: true,
        auth: true,
      });
      history.push(`/eventregistration/${id}`)
    },
    onError: (err) => {
      authVar({
        loaded: true,
        auth: false,
      });
    },
  });
    useEffect(()=>{
      console.log("token",token);
        if(token){
            localStorage.setItem("@authToken", token);
            authVar({
                loaded: true,
                auth: true,
              });
              CurrentUserDetails();
        }
    },[token,CurrentUserDetails])
    return(
        <div className="d-flex justify-content-center align-items-center">
            <div>
                {loading?"Redirecting...":''}
            </div>
        </div>
    )
} 