import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Switch,
  Button,
  Avatar,
  TablePagination,
  Pagination,
  MenuItem,
  Select,
  IconButton,
} from "@mui/material";
import AddLocation from "./AddLocation";
import { useQuery, useMutation } from "@apollo/client";
import { LIST_LOCATIONS } from "../../Graphql/queries";
import { DELETE_LOCATION } from "../../Graphql/mutations";
import { useState } from "react";
import { toggleSnackbar } from "../../RactiveVariables/index";
import { ConfirmDialog } from "../Common";

export default function Location(props) {
  const [openAdd, setOpenAdd] = useState(false);
  const [searchVariable, setSearchVariable] = useState(null);
  const [sortVariable, setSortVariable] = useState("-1");
  const [sortFieldVariable, setSortFieldVariable] = useState("_id");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteLocation, { loading: deleteLoadinng }] = useMutation(DELETE_LOCATION);
  // const [deactivateUser, { loading: deactivateLoadinng }] =
  //   useMutation(DEACTIVATE_USER);
  const getLocationVariables = {
    skip: (page - 1) * rowsPerPage,
    limit: rowsPerPage,
    search: searchVariable,
    sortOrder: sortVariable,
    sortField: sortFieldVariable,
  };
  const {
    data: LocationList,
    fetchMore,
    loading,
  } = useQuery(LIST_LOCATIONS, {
    fetchPolicy: "network-only",
    variables: getLocationVariables,
  });
  const [selectedItem, setSelectedItem] = useState(null);

  const handleChangePage = (e, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  function editButtonClick(item) {
    setSelectedItem(item);
    setOpenAdd(true);
  }
  const handlePagination = () => {
    fetchMore({
      variables: getLocationVariables,
    });
  };
  function handleDelete() {
    deleteLocation({ variables: { userIds: [deleteId] } })
      .then((res) => {
        toggleSnackbar({
          status: true,
          message: "Location deleted successfully",
          variant: "success",
        });
        handlePagination();
      })
      .catch((error) => {
        const message = error && error.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      });
  }
  function closeConfirm() {
    setDeleteId(null);
  }
  function handleConfirm() {
    handleDelete();
    closeConfirm();
    handlePagination();
  }
  function handlePageInputChange(event) {
    setRowsPerPage(Number(event.target.value));
    setPage(1);
  }
  function handleSort(field) {
    if (sortFieldVariable === field) {
      if (sortVariable === "-1") setSortVariable("1");
      else setSortVariable("-1");
    } else {
      setSortFieldVariable(field);
      setSortVariable("1");
    }
  }
  function handleAddress(item){
let addr= item.replace(',','')
let newaddr= addr.replace('USA','')
return newaddr
  }
  return (
    <div className="content-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <ConfirmDialog
        open={deleteId}
        title={"Delete"}
        body={"Are you sure you want to delete this Location ? "}
        closeText={"Cancel"}
        confirmText={"Delete"}
        closeConfirm={closeConfirm}
        handleConfirm={handleConfirm}
      />
      <div className="card border-0">
        <div className="card-body p-0">
          {selectedItem && (
            <AddLocation
              setSelectedItem={setSelectedItem}
              selectedItem={selectedItem}
              open={openAdd}
              setOpen={setOpenAdd}
              handlePagination={handlePagination}
            />
          )}
          <div className="filter-section d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <img
                className="search-icon"
                src="../assets/images/search.svg"
                alt="search icon"
              />
              <input
                className="searchbox"
                placeholder="Search for a location..."
                type="text"
                value={searchVariable}
                onChange={(e) => {setPage(1);setSearchVariable(e.target.value)}}
              />
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <Button
                role="button"
                type="button"
                className="btn add-btn"
                onClick={() => {
                  setOpenAdd(true);
                  setSelectedItem({});
                }}
              >
                + Add Location
              </Button>
            </div>
          </div>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="w-30">
                    Location Name
                    {/* <a
                      className="filter-icon"
                      onClick={() => handleSort("firstName")}
                    >
                      <img
                        src={
                          sortFieldVariable !== "firstName"
                            ? "../assets/images/arrowupdown.svg"
                            : sortVariable === "-1"
                            ? "../assets/images/DownArrow.svg"
                            : "../assets/images/UpArrow.svg"
                        }
                      />
                    </a> */}
                  </TableCell>
                  <TableCell>Street Address</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <p> Loading Locations...</p>
                      </div>
                    </TableCell>
                  </TableRow>
                ) : LocationList?.listOffLocations?.data &&
                LocationList?.listOffLocations?.data ?.length > 0 ? (
                  LocationList?.listOffLocations?.data ?.map((item) => (
                    <TableRow key={item.firstName}>
                      <TableCell>
                        <div className="d-flex align-items-center">
                          <Avatar
                            src={
                              item?.locImage
                                ? item?.locImage
                                : "/broken-image.jpg"
                            }
                          />
                          <div className="ms-3">
                            {item?.locName}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>{handleAddress(item.streetAdrs)}</TableCell>
                      <TableCell>
                        <div className="d-flex justify-content-center">
                          <IconButton
                            className="p-2 "
                            onClick={() => setDeleteId(item._id)}
                          >
                            <img
                              src="../assets/images/trash.svg"
                              alt="delete button image"
                            />
                          </IconButton>
                          <IconButton
                            onClick={() => editButtonClick(item)}
                            className="p-2 "
                          >
                            <img
                              src="../assets/images/pencil.svg"
                              alt="edit button image"
                            />
                          </IconButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
                ) : searchVariable ? (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <p>No Location Found</p>
                      </div>
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <p>No Location Added Yet!</p>
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            {!loading && (
              <div className="pagination-outer">
                <div className="count-selector">
                <Select
                  onChange={handlePageInputChange}
                  value={rowsPerPage}
                >
                  <MenuItem value={10}>{10}</MenuItem>
                  <MenuItem value={25}>{25}</MenuItem>
                  <MenuItem value={50}>{50}</MenuItem>
                  <MenuItem value={100}>{100}</MenuItem>
                </Select>
                <img className="count-arrow" src="../../assets/images/arrowupdown.svg" />
                </div>
               
                <Pagination
                  count={Math.ceil(LocationList?.listOffLocations?.count / rowsPerPage)}
                  page={page}
                  shape="rounded"
                  onChange={handleChangePage}
                />
              </div>
            )}
          </TableContainer>
        </div>
      </div>
          </div>
        </div>
      </div>
      
    </div>
  );
}
