import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL + "/graphql",
  // uri: "https://stag-api.nysais.org/graphql",
});
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("@authToken");
  return {
    headers: {
      ...headers,
      authorization: token ? token : "",
    },
  };
});
// GraphQL Init
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});
export default client;
