import { Modal, InputLabel, Box, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { CREATE_PAYMENT } from "../../Graphql/mutations";
import { useMutation } from "@apollo/client";
import { toggleSnackbar } from "../../RactiveVariables";

export default function CheckDetails(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };
  const [createPayment, { loading }] = useMutation(CREATE_PAYMENT,{refetchQueries:["countOrders"]});
  const schema = yup.object().shape({
    checkNumber: yup.string().required("Check Number is required"),
    description: yup.string().required("Description is required"),
  });
  const { register, handleSubmit, formState, setValue, control, reset } =
    useForm({
      resolver: yupResolver(schema),
    });
  const { errors } = formState;
  function handleClose() {
    props.setOpen(false);
    reset();
  }
  function onSubmit(params) {
    let data = {
      orderId: props?.selectedItem?._id,
      paymentMethod: props?.selectedItem?.paymentMethod,
      amount: props?.selectedItem?.totalPrice,
      checkNumber: params?.checkNumber,
      description: params?.description,
      paymentType: "Check",
      paymentStatus: "Paid",
    };
    try {
      createPayment({ variables: { data: data } })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Payment details updated successfully",
            variant: "success",
          });
          handleClose();
          props.refetch && props.refetch();
        })
        .catch((error) => {
          const message = error && error.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (error) {
      const message = error && error.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  }
  return (
    <Modal className="modal-outer" open={props?.open} onClose={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Box sx={style} className="register-modal">
          <h1 className="modal-title">Payment details</h1>
          <div className="mb-3">
            <InputLabel className="form-label">
              Check Number<sup className="text-danger">*</sup>
            </InputLabel>
            <TextField
              style={{ width: "100%" }}
              variant="outlined"
              error={errors.checkNumber}
              {...register("checkNumber")}
            />
            {errors && errors?.["checkNumber"] && (
              <p className="errorMessage text-danger">
                {errors?.checkNumber?.message}
              </p>
            )}
          </div>
          <div>
            <InputLabel className="form-label">
              Description<sup className="text-danger">*</sup>
            </InputLabel>
            <TextField
              style={{ width: "100%" }}
              variant="outlined"
              multiline
              error={errors.description}
              {...register("description")}
            />
            {errors && errors?.["description"] && (
              <p className="errorMessage text-danger">
                {errors?.description?.message}
              </p>
            )}
          </div>
          <button
            className="btn btn-orange font-weight-bold mt-3 w-100"
            type="submit"
          >
            Confirm
          </button>
        </Box>
      </form>
    </Modal>
  );
}
