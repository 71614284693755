import React, { useState } from "react";
import { CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";

export default function CustomHeader(props) {
  return (
    <header className="event-header">
      <div className="event-btn-outer">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="d-flex justify-content-between align-items-center">
                {props.backBtn && <Link
                  to={props?.backUrl ? props?.backUrl : "/"}
                  style={{ top: "-4px" }}
                  type="submit"
                  className="btn back-btn"
                >
                  <img src="../assets/images/arrow-left.svg" />
                </Link>}
                <h3 className="event-head">{props.label}</h3>

                <div className="d-flex">
                  {props.btn1Label && props.handleBtn1 && (
                    <a
                    //   href="/events"
                      className="btn btn-outline-light"
                      onClick={props.handleBtn1}
                    >
                      {props.btn1Label}
                    </a>
                  )}
                  {props.btn2Label && props.handleBtn2 && (
                    <button
                      type="submit"
                      className="btn btn-light ms-3"
                      onClick={props.handleBtn2}
                    >
                      {props.btn2Loading ? (
                        <CircularProgress size="20px" color="inherit" />
                      ) : (
                        props.btn2Label
                      )}
                    </button>
                  )}
                   {props.editLabel && props.editUrl &&(
                    <Link
                      className="btn btn-outline-light ms-3 d-flex align-items-center"
                      to={props.editUrl}
                    >
                      <img className="me-2" src="../assets/images/edit3.svg" />{props.editLabel}
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
