import { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  Button,
  Menu,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Pagination,
  TextField,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import { toggleSnackbar } from "../../RactiveVariables/index";
import { formatDateandTime } from "../Common/GeneralFunctions";
import { useQuery } from "@apollo/client";
import { LIST_EVENTS_PER_TIC } from "../../Graphql/queries";
import { DELETE_EVENT } from "../../Graphql/mutations";
import moment from "moment";
import { categoryArray } from "../Events/EventOptions";
import { Link } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";
import { schoolDtls ,userDeatils } from "../../RactiveVariables";
import ConfirmDialog from "../Common/ConfirmDialog";

export default function MyOrders(props) {
  const schoolDtl = useReactiveVar(schoolDtls);
  const [category, setcategorytype] = useState(null);
  const [searchVariable, setSearchVariable] = useState(null);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState(null);
  const [eventType, setEventType] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [selectedItem, setSelectedItem] = useState(null);
  const [sortFieldVariable, setSortFieldVariable] = useState("_id");
  const [sortVariable, setSortVariable] = useState("-1");
  const [deleteId, setDeleteId] = useState(false);
  const [deleteEvent, { loading: deleteLoadinng }] = useMutation(DELETE_EVENT);
  const getEventVariables = {
    skip: (page - 1) * rowsPerPage,
    limit: rowsPerPage,
    search: search,
    category: category,
    eventType: eventType,
    startDate: startDate,
    sortOrder: sortVariable,
    sortField: sortFieldVariable,
  };
  const {
    data: eventList,
    fetchMore,
    loading,
  } = useQuery(LIST_EVENTS_PER_TIC, {
    fetchPolicy: "network-only",
    variables: getEventVariables,
  });
  const handleEventType = (event) => {
    setEventType(event.target.value);
  };

  function handleDelete() {
    deleteEvent({ variables: { eventIds: [selectedItem?._id] } })
      .then((res) => {
        toggleSnackbar({
          status: true,
          message: "Event deleted successfully",
          variant: "success",
        });
        handlePagination();
      })
      .catch((error) => {
        const message = error && error.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      });
  }
  const handlePagination = () => {
    fetchMore({
      variables: getEventVariables,
    });
  };
  const handlecategory = (event) => {
    setcategorytype(event.target.value);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, item) => {

    setSelectedItem(item);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChangePage = (e, page) => {
    setPage(page);
  };
  function handlePageInputChange(event) {
    setRowsPerPage(Number(event.target.value));
    setPage(1);
  }
  function convertDate(str) {
    if (str) {
      var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [mnth, day, date.getFullYear()].join("/");
    } else {
      return null;
    }
  }
  function handleSort(field) {
    if (sortFieldVariable === field) {
      if (sortVariable === "-1") setSortVariable("1");
      else setSortVariable("-1");
    } else {
      setSortFieldVariable(field);
      setSortVariable("1");
    }
  }
  function closeConfirm() {
    setDeleteId(false);
  }
  function handleConfirm() {
    handleDelete();
    closeConfirm();
  }
  return (
    <div className="content-wrapper">
      <div className="container">
      <div className="d-block d-md-none">
          <h3 className="m-h3">{schoolDtl?.data?.schNameThe}</h3>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <ConfirmDialog
              open={deleteId}
              title={"Delete"}
              body={"Are you sure you want to delete this Event ? "}
              closeText={"Cancel"}
              confirmText={"Delete"}
              closeConfirm={closeConfirm}
              handleConfirm={handleConfirm}
            />
            <div className="card border-0">
              <div className="card-body p-0">
                {/* <div className="filter-section d-flex justify-content-between">
                  <div className="d-flex align-items-center">
                    <img
                      className="search-icon"
                      src="../assets/images/search.svg"
                      alt="search icon"
                    />
                    <input
                      className="searchbox"
                      placeholder="Search for an Event"
                      value={search}
                      type="text"
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                  <div className="d-flex align-items-center justify-content-center">
                    <Box sx={{ minWidth: 177 }}>
                      <FormControl fullWidth className="filter-date">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            inputFormat="MM/dd/yyyy"
                            value={startDate ? startDate : null}
                            renderInput={(params) => <TextField {...params} />}
                            onChange={(e) => setStartDate(convertDate(e))}
                          />
                        </LocalizationProvider>
                      </FormControl>
                    </Box>
                    <Box className="ms-2" sx={{ minWidth: 177 }}>
                      <FormControl fullWidth>
                        <InputLabel className="filter-label" id="category">
                          Category
                        </InputLabel>
                        <Select
                          className="filter-select"
                          labelId="category"
                          id="demo-category"
                          value={category}
                          label="category"
                          onChange={handlecategory}
                        >
                          <MenuItem value="">{"None"}</MenuItem>
                          {categoryArray.map((item) => (
                            <MenuItem value={item.value}>{item.label}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </div>
                </div> */}
                <TableContainer component={Paper} style={{backgroundColor:'transparent'}}>
                  <Table className="mob-table-layout" aria-label="simple table"  style={{backgroundColor:'#fff'}}>
                    <TableHead>
                      <TableRow>
                        <TableCell className="w-200">Event Name</TableCell>
                        <TableCell className="w-150">Date & Time</TableCell>
                        <TableCell className="w-90">Category</TableCell>
                        <TableCell className="w-90"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {loading ? (
                        <TableRow>
                          <TableCell colSpan={12}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <p> Loading Events...</p>
                            </div>
                          </TableCell>
                        </TableRow>
                      ) : eventList?.listAllEvtsPerTkts?.data &&
                        eventList?.listAllEvtsPerTkts?.data.length > 0 ? (
                        eventList?.listAllEvtsPerTkts?.data?.map((ticket) => {
                          let item = ticket.eventDtls;
                          return (
                            <TableRow>
                              {item&&<TableCell>
                                <Link
                                  className="preview-link"
                                  to={`/eventpreview/${item?._id}`}
                                >
                                  {item?.title}
                                </Link>
                              </TableCell>}
                             {item&& <TableCell>
                                {/* <div>
                                  {" "}
                                  {item.startDate &&
                                    moment(new Date(item.startDate)).format(
                                      "MMM D, YY"
                                    ) +
                                      " " +
                                      moment(item.startTime, "HH.mm").format(
                                        "h:mm A"
                                      )}{" "}
                                  -
                                </div>
                                {item.endDate &&
                                  moment(new Date(item.endDate)).format(
                                    "MMM D, YY"
                                  ) +
                                    " " +
                                    moment(item.endTime, "HH.mm").format(
                                      "h:mm A"
                                    )} */}
                                    {formatDateandTime(item?.startDate) + " - "+formatDateandTime(item?.endDate)}
                              </TableCell>}
                              {item&&<TableCell>{item?.category}</TableCell>}
                              
                              {item&&<TableCell>
                                <div className="d-flex">
                                  {/* <Button
                          // onClick={() => editButtonClick(item)}
                          type="submit"
                          className="p-0 action-btn"
                        >
                          <img
                            src="../assets/images/dots.svg"
                            alt="edit button image"
                          />
                        </Button> */}
                                  <Button
                                    id="basic-button"
                                    aria-controls="basic-menu"
                                    aria-haspopup="true"
                                    aria-expanded={open ? "true" : undefined}
                                    onClick={(e) => handleClick(e, item)}
                                    className="p-0 action-btn"
                                  >
                                    <img
                                      src="../assets/images/dots.svg"
                                      alt="edit button image"
                                    />
                                  </Button>
                                </div>
                              </TableCell>}
                            </TableRow>
                          );
                        })
                      ) : searchVariable ? (
                        <TableRow>
                          <TableCell colSpan={12}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <p>No Events Found</p>
                            </div>
                          </TableCell>
                        </TableRow>
                      ) : (
                        <TableRow>
                          <TableCell colSpan={12}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <p>No Events Added Yet!</p>
                            </div>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                  {!loading && (
                    <div className="pagination-outer">
                      <div className="count-selector">
                        <Select
                          onChange={handlePageInputChange}
                          value={rowsPerPage}
                        >
                          <MenuItem value={10}>{10}</MenuItem>
                          <MenuItem value={25}>{25}</MenuItem>
                          <MenuItem value={50}>{50}</MenuItem>
                          <MenuItem value={100}>{100}</MenuItem>
                        </Select>
                        <img
                          className="count-arrow"
                          src="../../assets/images/arrowupdown.svg"
                        />
                      </div>
                      <Pagination
                        count={Math.ceil(
                          eventList?.listAllEvtsPerTkts?.count / rowsPerPage
                        )}
                        page={page}
                        shape="rounded"
                        onChange={handleChangePage}
                      />
                    </div>
                  )}
                </TableContainer>
              </div>
            </div>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "dropdown-button",
              }}
            >
              <MenuItem onClick={handleClose}>
                <Link
                  className="actionbtn-menu"
                  to={`/eventpreview/${selectedItem?._id}`}
                >
                  Preview
                </Link>
              </MenuItem>
            </Menu>
          </div>
        </div>
      </div>
    </div>
  );
}
