import { useState } from "react";
import { useQuery } from "@apollo/client";
import { FINANCE_DISPATCH } from "../../Graphql/queries";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  Pagination,
  MenuItem,
  Select,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import {formatCurrency} from "../Common/GeneralFunctions"
import { useHistory, Link } from "react-router-dom";
export default function Deposit(props) {
  const history = useHistory();
  const [search, setSearch] = useState("");
  const date =new Date().getFullYear()
  let currentTime =new Date()
  var month = currentTime.getMonth() + 1
  const [dateFrom, setDateFrom] = useState(month<9?`${date-1}-09-01T18:14:16.887Z`:`${date}-09-01T18:14:16.887Z`);
  const [dateTo, setDateTo] = useState(month>=8?`${date+1}-08-30T18:14:16.887Z`:`${date}-08-30T18:14:16.887Z`);
  // const [dateFrom, setDateFrom] = useState(moment(new Date()).subtract(1, 'month'));
  // const [dateTo, setDateTo] = useState(new Date());
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [csvData,setCsvData] = useState('')
  const [loadingCSV,setLoadingCSV] = useState(false);
  const [individualCSV,setIndividualCSV] = useState({})
  const [loadingQB,setLoadingQB] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const variables = {
    search: search,
    dateFrom: dateFrom,
    dateTo: dateTo,
    skip: (page - 1) * rowsPerPage,
    limit: rowsPerPage,
  };

  const { data: financeData, loading } = useQuery(FINANCE_DISPATCH, {
    fetchPolicy: "network-only",
    variables: variables,
  });

  const handleChangePage = (e, page) => {
    setPage(page);
  };
  function handlePageInputChange(event) {
    setRowsPerPage(Number(event.target.value));
    setPage(1);
  }
  function downloadTxtFile () {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      "search":search||"",
      "dateFrom":dateFrom||"",
      "dateTo":dateTo||"",
    });
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    fetch(`${process.env.REACT_APP_API_URL}/finance/financeBatchReport`, requestOptions)
    .then(response => response.text())
      .then(result =>  {
        const element = document.createElement("a");
    const file = new Blob([result], {type: 'text/csv'});
    element.href = URL.createObjectURL(file);
    element.download = "ConsolidatedDepositReport.csv";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    setLoadingCSV(false)
      })
      .catch(error => console.log('error', error));

    

  }
  function downloadQuickBook () {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      "search":search||"",
      "dateFrom":dateFrom||"",
      "dateTo":dateTo||"",
    });
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    fetch(`${process.env.REACT_APP_API_URL}/finance/financeBatchQbo`, requestOptions)
      .then(response => response.text())
      .then(result =>  {
        const element = document.createElement("a");
    const file = new Blob([result], {type: 'text/csv'});
    element.href = URL.createObjectURL(file);
    element.download = "QuickBooks.csv";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    setLoadingQB(false)
      })
      .catch(error => console.log('error', error));

    

  }
  function downloadIndividual (value) {
    const timestamp = parseInt(value);
    const date = new Date(timestamp); // Create a Date object using the timestamp
    const isoString = date.toISOString();
    const options = { month: 'short', day: '2-digit' };
    const nameDate = date.toLocaleDateString('en-US', options).replace(/\s/g, '');
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      "search":search||"",
      "dateFrom":isoString||"",
      "dateTo":isoString||"",
    });
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    fetch(`${process.env.REACT_APP_API_URL}/finance/financeBatchReport`, requestOptions)
    .then(response => response.text())
      .then(result =>  {
        const element = document.createElement("a");
    const file = new Blob([result], {type: 'text/csv'});
    element.href = URL.createObjectURL(file);
    element.download = `DepositReport_${nameDate}.csv`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    setLoadingCSV(false)
      })
      .catch(error => console.log('error', error));

    

  }
  return (
    <>
      <div className="tabnav-filter">
        <div className="container">
          <div className="row">
            <div className="col-sm-3 d-flex">
              <div className="d-flex align-items-center w-100">
                <img src="../assets/images/search.svg" />
                <input
                  className="tabsearch-field"
                  type="text"
                  onChange={(e) =>{setPage(1); setSearch(e.target.value)}}
                  placeholder="Search by Name / Order Id / Event"
                />
              </div>
            </div>
            <div className="col-sm-9 d-flex justify-content-end">
              <div className="d-flex">
              <Box className="ms-2" sx={{ minWidth: 177 }}>
                  <FormControl fullWidth>
                    <DesktopDatePicker
                      inputFormat="MM/dd/yyyy"
                      value={dateFrom ? dateFrom : null}
                      renderInput={(params) => (
                        <TextField {...params} className="date-field" />
                      )}
                      onChange={(e) => {setPage(1);setDateFrom(e)}}
                    />
                  </FormControl>
                </Box>
                <Box className="ms-2" sx={{ minWidth: 177 }}>
                  <FormControl fullWidth>
                    <DesktopDatePicker
                      inputFormat="MM/dd/yyyy"
                      value={dateTo ? dateTo : null}
                      renderInput={(params) => (
                        <TextField {...params} className="date-field" />
                      )}
                      onChange={(e) =>{setPage(1); setDateTo(e)}}
                    />
                  </FormControl>
                </Box>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="finance-order-outer">
        <div>
          <TableContainer component={Paper}>
            <div className="d-flex align-items-center justify-content-between m-3">
              <h2 className="ordercnt">
                {financeData?.batchRes?.count || "-" + " "} Deposits
              </h2>
              <div className="d-flex align-items-center">
              <a
                href="javascript:;"
                className="btn btn-orange d-flex align-items-center me-3"
                onClick={()=>{downloadQuickBook();setLoadingQB(true)}}
              >
                <img className="me-1" src="../assets/images/download.svg" />
                {loadingQB===true? <CircularProgress size="20px" color="inherit"  /> :  "QuickBooks"}
              </a>
              <a
                href="javascript:;"
                className="btn btn-orange d-flex align-items-center"
                onClick={()=>{downloadTxtFile();setLoadingCSV(true);}}
              >
                <img className="me-1" src="../assets/images/download.svg" />
                {loadingCSV===true? <CircularProgress size="20px" color="inherit"  /> :  "Download"}
              </a>
              </div>
              
            </div>

            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Batch</TableCell>
                  <TableCell align="right" className="text-right pe-5">Amount</TableCell>
                </TableRow>
              </TableHead>
            </Table>
            <div className="deposit-accordion-outer">
              <div>
                {loading ? (
                  <div>
                    <div colSpan={12}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <p> Loading Deposit...</p>
                      </div>
                    </div>
                  </div>
                ) : financeData?.batchRes?.data &&
                  financeData?.batchRes?.data.length > 0 ? (
                  financeData?.batchRes?.data.map((item) => 
                    (
                    <Accordion
                      expanded={expanded === item._id}
                      onChange={handleChange(item._id)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <div className="accordion-head align-items-center">
                          <div className="d-flex align-items-center">
                          <p className="date" style={{minWidth:"95px"}}>
                            {moment(parseInt(item._id)).utc().format('ll')}
                          </p>
                          <a
                            href="javascript:;"
                            className="btn btn-orange d-flex align-items-center ms-2"
                            onClick={()=>{downloadIndividual(item._id);setLoadingCSV({ [item._id]: true });}}
                          >
                            <img className="me-1" src="../assets/images/download.svg" />
                            {loadingCSV[item._id]? <CircularProgress size="20px" color="inherit"  /> :  "Download"}
                          </a>
                          </div>
                          <p className="price">{String(item?.totalAmount)[0]==='-'?"-$ "+String(formatCurrency(item?.totalAmount)).slice(1):"$ "+formatCurrency(item?.totalAmount)}</p>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Table className="w-100" aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell>Payment ID</TableCell>
                              <TableCell>Order Id</TableCell>
                              <TableCell>Card</TableCell>
                              <TableCell>Name</TableCell>
                              <TableCell>Event</TableCell>
                              <TableCell>Amount</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {item.dispatchDtls.map((items) => (
                              <TableRow>
                                <TableCell>#{items.batchId}</TableCell>
                                <TableCell  style={{cursor:"pointer"}} onClick={()=>{history.push({pathname:`/orderdetails/${items?.orderId}`,state: { detail: 'finance' }});}} >#{items.orderNo}</TableCell>
                                <TableCell>{items.card_type}</TableCell>
                                <TableCell>{items.memberName}</TableCell>
                                <TableCell>{items.eventName}</TableCell>
                                <TableCell>{String(items.amount)[0]==='-'?"-$ "+String(formatCurrency(items.amount)).slice(1):"$ "+formatCurrency(items.amount)}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </AccordionDetails>
                    </Accordion>
                  ))
                ) : search ? (
                  <div>
                    <div colSpan={12}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <p>No Deposit Found</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div colSpan={12}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <p>No Deposit Yet!</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {!loading && (
                <div className="pagination-outer">
                  <div className="count-selector">
                    <Select
                      // variant="outlined"
                      onChange={handlePageInputChange}
                      value={rowsPerPage}
                    >
                      <MenuItem value={10}>{10}</MenuItem>
                      <MenuItem value={25}>{25}</MenuItem>
                      <MenuItem value={50}>{50}</MenuItem>
                      <MenuItem value={100}>{100}</MenuItem>
                    </Select>
                    <img
                      className="count-arrow"
                      src="../../assets/images/arrowupdown.svg"
                    />
                  </div>

                  <Pagination
                    count={Math.ceil(
                      financeData?.batchRes?.count / rowsPerPage
                    )}
                    page={page}
                    shape="rounded"
                    onChange={handleChangePage}
                  />
                </div>
              )}
            </div>
          </TableContainer>
        </div>
      </div>
    </>
  );
}
