import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  InputLabel,
  TextField,
  Drawer,
  Button,
  CircularProgress,
  Select,
  MenuItem,
  Avatar,
  Input,
  InputAdornment,
} from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { ADD_USER, EDIT_USERS } from "../../Graphql/mutations";
import { toggleSnackbar } from "../../RactiveVariables/index";
import { GET_ALLUSERGROUP, IMAGE_UPLOAD } from "../../Graphql/queries";
import axios from "axios";
import imageCompression from 'browser-image-compression';

export default function AddUser(props) {
  const [addUser, { loading }] = useMutation(ADD_USER);
  const [editUser, { loading: editLoading }] = useMutation(EDIT_USERS);
  const { data: usergroups } = useQuery(GET_ALLUSERGROUP,{fetchPolicy: "network-only"});
  const [phone, setPhone] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUploadLoading, setImageUploadLoading] = useState(false);
  const uplaodImageVariables = {
    originalFilename: selectedImage?.fileName,
    path: "/userprofile",
  };
  const [upload, { data: uploadImageData }] = useLazyQuery(IMAGE_UPLOAD, {
    fetchPolicy: "network-only",
    variables: uplaodImageVariables,
  });
  const schema = yup.object().shape({
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required("Last name is required"),
    title: yup.string().required("Title is required"),
    email: yup.string().required("Email is required").email(),
    phone: yup
      .string()
      .required("Phone Number is required")
      .test("phone", "Must be a number", (value) => {
        if (value && isNaN(value.replace(/[-,),(, ]+/g, ""))) return false;
        else return true;
      })
      .test("phone", "Must be a valid number", (value) => {
        if (value && value.length < 8) return false;
        else return true;
      }),
    userGroupId: yup.string().required("Please select user group"),
  });
  const { register, handleSubmit, formState, reset } = useForm({
    defaultValues: props.defaultValue,
    resolver: yupResolver(schema),
  });
  const phoneNumberFormat = (num) => {
    let newNum = num.replace(/[-,(,), ]+/g, "");
    let x;
    if (newNum.length <= 3) {
      x = newNum;
    } else if (newNum.length > 3 && newNum.length <= 6) {
      x = "(" + newNum.slice(0, 3) + ") " + newNum.slice(3, 6);
    } else {
      x =
        "(" +
        newNum.slice(0, 3) +
        ") " +
        newNum.slice(3, 6) +
        "-" +
        newNum.slice(6, 10);
    }
    return x;
  };
  const { errors } = formState;

  async function uploadImage(params) {
    setImageUploadLoading(true);
    if (selectedImage) {
      let url = uploadImageData?.imageUpload?.signedUrl;
      const response = await axios.put(url, selectedImage.file, {
        headers: {
          "Content-Type": selectedImage?.file?.type,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "X-Requested-With",
        },
      });
    }
    setImageUploadLoading(false);
    onSubmit(params);
  }
  const onSubmit = (params) => {
    if (selectedImage?.imageFile !== null)
      params["profileImage"] = uploadImageData?.imageUpload?.publicUrl;
    else params["profileImage"] = "";
    params.password = "abcd"; //temporary fix need to remove
    if (Object.keys(props.selectedItem).length > 0) {
      try {
        params._id = props?.selectedItem?._id;
        editUser({ variables: params })
          .then((res) => {
            toggleSnackbar({
              status: true,
              message: "User updated successfully",
              variant: "success",
            });
            props.handlePagination();
            props.setOpen(false);
            setPhone("");
            setSelectedImage(null)
          })
          .catch((error) => {
            const message = error && error.message;
            toggleSnackbar({
              status: true,
              message: message,
              variant: "error",
            });
          });
      } catch (error) {
        const message = error && error.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      }
    } else {
      try {
        addUser({ variables: params })
          .then((res) => {
            toggleSnackbar({
              status: true,
              message: "User added successfully",
              variant: "success",
            });
            props.handlePagination();
            props.setOpen(false);
            setPhone("");
            setSelectedImage(null)
          })
          .catch((error) => {
            const message = error && error.message;
            toggleSnackbar({
              status: true,
              message: message,
              variant: "error",
            });
          });
      } catch (error) {
        const message = error && error.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      }
    }

  };

  function closeDrawer() {
    props.setOpen(false);
    props.setSelectedItem(null);
    setSelectedImage(null);
    setPhone(null);
  }
  useEffect(() => {
    reset();
    if (props?.selectedItem?.phone) {
      setPhone(props?.selectedItem?.phone)
    }
  }, [props.selectedItem]);

  function handleUploadClick(event) {
    let obj = {};
    var file = event.target.files[0];
    if (
      !(
        file?.type == "image/jpeg" ||
        file?.type == "image/png" ||
        file?.type == "image/jpg"
      )
    ) {
      toggleSnackbar({
        status: true,
        message: "File does not support. You must use .png or .jpeg",
        variant: "error",
      });
      return false;
    }
    if (file.size > 5242880) {
      toggleSnackbar({
        status: true,
        message: "Please upload a file smaller than 5 MB",
        variant: "error",
      });
      return false;
    }
    obj["fileName"] = file.name;
    var options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 300,
      useWebWorker: true,
    };
    imageCompression(file, options)
    .then((compressedFile) => {
      
    obj["url"] = URL.createObjectURL(compressedFile);
    obj["file"] = compressedFile;
    setSelectedImage(obj);
    upload();
    })
    .catch((err) => {
      //console.log("err: ",err)
    })
  }
  const phoneNumberRegister = register("phone", { required: true });
  return (
    <div>
      <input
        id="add-user-input"
        type="file"
        hidden
        onChange={handleUploadClick}
        onClick={(event) => {
          event.target.value = null;
        }}
      />
      <Drawer anchor={"right"} open={props.open} onClose={closeDrawer}>
        <div className="sidebar-toggler" role="presentation">
          <div className="title-outer">
            <h1>
              {Object.keys(props.selectedItem).length > 0
                ? "Edit User"
                : "Add User"}
            </h1>
            <div onClick={closeDrawer} className="close-icon">
              X
            </div>
          </div>
          <div className="userform-scroller">
            <div className="d-flex justify-content-center user-outer">
              <div className="avatar-outer">
                <Avatar
                  src={
                    selectedImage
                      ? selectedImage.url
                      : props?.selectedItem?.profileImage
                      ? props?.selectedItem?.profileImage
                      : "/broken-image.jpg"
                  }
                  onClick={(e) =>
                    document.getElementById("add-user-input").click()
                  }
                  sx={{ width: 110, height: 110 }}
                />
                <span
                  onClick={() =>
                    document.getElementById("add-user-input").click()
                  }
                >
                  +
                </span>
              </div>
            </div>
            <form
              className="adduser-form"
              onSubmit={handleSubmit(uploadImage)}
              noValidate
            >
              <div className="row">
                <div className="col-md-6">
                  <InputLabel className="form-label">
                    First name<sup className="text-danger">*</sup>
                  </InputLabel>
                  <TextField
                    variant="outlined"
                    {...register("firstName")}
                    error={errors.firstName}
                    autoFocus
                    defaultValue={props?.selectedItem?.firstName}
                  />
                  {errors && errors?.["firstName"] && (
                    <p className="errorMessage text-danger">
                      {errors?.firstName?.message}
                    </p>
                  )}
                </div>
                <div className="col-md-6">
                  <InputLabel className="form-label">
                    Last name<sup className="text-danger">*</sup>
                  </InputLabel>
                  <TextField
                    variant="outlined"
                    {...register("lastName")}
                    error={errors.lastName}
                    defaultValue={props?.selectedItem?.lastName}
                  />
                  {errors && errors?.["lastName"] && (
                    <p className="errorMessage text-danger">
                      {errors?.lastName?.message}
                    </p>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <InputLabel className="form-label">
                    Title<sup className="text-danger">*</sup>
                  </InputLabel>
                  <TextField
                    {...register("title")}
                    variant="outlined"
                    error={errors.title}
                    defaultValue={props?.selectedItem?.title}
                  />
                  {errors && errors?.["title"] && (
                    <p className="errorMessage text-danger">
                      {errors?.title?.message}
                    </p>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <InputLabel className="form-label">
                    User Group<sup className="text-danger">*</sup>
                  </InputLabel>
                  <Select
                    variant="outlined"
                    {...register("userGroupId")}
                    error={errors.userGroupId}
                    defaultValue={props?.selectedItem?.userGroupId}
                  >
                    {usergroups?.listAllUserGps &&
                      usergroups?.listAllUserGps?.map((item) => (
                        <MenuItem value={item._id}>{item.groupName.replace(/(^\w|\s\w)/g, m => m.toUpperCase())}</MenuItem>
                      ))}
                  </Select>
                  {errors && errors?.["userGroupId"] && (
                    <p className="errorMessage text-danger">
                      {errors?.userGroupId?.message}
                    </p>
                  )}
                </div>

                <div className="col-md-6 mt-3">
                  <InputLabel className="form-label">
                    Phone<sup className="text-danger">*</sup>
                  </InputLabel>
                  <TextField
                    {...register("phone")}
                    variant="outlined"
                    error={errors.phone}
                    value={phone}
                    defaultValue={props?.selectedItem?.phone}
                    onChange={(e) => {
                      phoneNumberRegister.onChange(e);
                      setPhone(phoneNumberFormat(e.target.value));
                    }}
                  />
                  {errors && errors?.["phone"] && (
                    <p className="errorMessage text-danger">
                      {errors?.phone?.message}
                    </p>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <InputLabel className="form-label">
                    Email<sup className="text-danger">*</sup>
                  </InputLabel>
                  <TextField
                    {...register("email")}
                    variant="outlined"
                    error={errors.email}
                    defaultValue={props?.selectedItem?.email}
                  />
                  {errors && errors?.["email"] && (
                    <p className="errorMessage text-danger">
                      {errors?.email?.message}
                    </p>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <InputLabel className="form-label">Bio</InputLabel>
                  <div className="textarea-outer">
                    <TextField
                      {...register("bio")}
                      variant="outlined"
                      multiline
                      rows={8}
                      // error={errors.bio}
                      defaultValue={props?.selectedItem?.bio}
                    />
                    {/* {errors && errors?.["bio"] && (
                      <p className="text-danger">{errors?.bio?.message}</p>
                    )} */}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="drawer-subtitle">Website & Social media</div>
                </div>
                <div className="col-sm-6 icon-input-outer mb-3">
                  <InputLabel className="form-label">Twitter</InputLabel>
                  <Input
                    placeholder="https://www.twitter.com/"
                    id="input-with-icon-adornment"
                    {...register("socialMedia.Twitter")}
                    defaultValue={props?.selectedItem?.socialMedia?.Twitter}
                    startAdornment={
                      <InputAdornment position="start">
                        <img src="../../assets/images/Twitter.svg" />
                      </InputAdornment>
                    }
                  />
                </div>
                <div className="col-sm-6 icon-input-outer mb-3">
                  <InputLabel className="form-label">Instagram</InputLabel>
                  <Input
                    id="input-with-icon-adornment"
                    placeholder="https://www.instagram.com/"
                    {...register("socialMedia.Instagram")}
                    defaultValue={props?.selectedItem?.socialMedia?.Instagram}
                    startAdornment={
                      <InputAdornment position="start">
                        <img src="../../assets/images/Instagram.svg" />
                      </InputAdornment>
                    }
                  />
                </div>
                <div className="col-sm-6 icon-input-outer mb-3">
                  <InputLabel className="form-label">Facebook</InputLabel>
                  <Input
                    placeholder="https://www.facebook.com/"
                    id="input-with-icon-adornment"
                    {...register("socialMedia.FaceBook")}
                    defaultValue={props?.selectedItem?.socialMedia?.FaceBook}
                    startAdornment={
                      <InputAdornment position="start">
                        <img src="../../assets/images/Facebook.svg" />
                      </InputAdornment>
                    }
                  />
                </div>
                <div className="col-sm-6 icon-input-outer mb-3">
                  <InputLabel className="form-label">Website</InputLabel>
                  <Input
                    placeholder="https://www.yourwebsite.com/"
                    id="input-with-icon-adornment"
                    {...register("socialMedia.Website")}
                    defaultValue={props?.selectedItem?.socialMedia?.Website}
                    startAdornment={
                      <InputAdornment position="start">
                        <img src="../../assets/images/Url.jpg" />
                      </InputAdornment>
                    }
                  />
                </div>
              </div>

              <div className="d-flex justify-content-between align-items-center btn-section">
                <Button className="outline-btn" onClick={() => closeDrawer()}>
                  Cancel
                </Button>
                <div className="d-flex align-items-center">
                  <Button type="submit" className="highlight-btn">
                    {loading || editLoading || imageUploadLoading ? (
                      <CircularProgress size="20px" color="inherit" />
                    ) : Object.keys(props.selectedItem).length > 0 ? (
                      "Edit User"
                    ) : (
                      "Add User"
                    )}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Drawer>
    </div>
  );
}
