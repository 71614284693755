import { useState, useEffect } from "react";
import { CustomHeader } from "../../Common";
import { Tab, Tabs, Box, Typography } from "@mui/material";
import Overview from "./Overview";
import SchoolEvents from "../SchoolEvents/index";
import SchoolCareer from "../SchoolCareer";
import SchoolOrder from "../SchoolOrder";
import Order from "../../Order/index";
import Users from "../Member/Users";
import { useParams } from "react-router-dom";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { SCHOOL_DETAILS } from "../../../Graphql/queries";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      style={{ backgroundColor: "white", padding: 0 }}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
export default function SchoolPreview(params) {
  const { id } = useParams();
  const schoolId = id.split("||")[0];

  const { data: schoolData } = useQuery(SCHOOL_DETAILS, {
    variables: { id: schoolId },
  });
  const [selectedTab, setSeletcedTab] = useState(params?.location?.selectedTab ? params?.location?.selectedTab: 0);
  function handleBtn1(params) {}
  function handleBtn2(params) {}
  const handleTabChange = (event, newValue) => {
    setSeletcedTab(newValue);
  };  
  function getWebiste(){
    let position = schoolData?.schoolDetails?.socialMediaWeb.findIndex(
      (element) => element.title === "Website"
    );
    if(position>-1){
      return schoolData?.schoolDetails?.socialMediaWeb[position]?.url
    }
  }
  return (
    <div className="school-outer">
      <CustomHeader
        label={schoolData?.schoolDetails?.schNameThe}
        editLabel={"Edit"}
        editUrl={`/addschool/${schoolId}`}
        backBtn
        backUrl="/schools"
      />
      <div className="preview-outer">
        <div className="preview-tab">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <Tabs
                  value={selectedTab}
                  onChange={handleTabChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="Overview" id="tabpanel-1" />
                  <Tab label="Orders" id="tabpanel-2" />
                  <Tab label="School Events" id="tabpanel-3" />
                  <Tab label="Careers" id="tabpanel-4" />
                  <Tab label="Users" id="tabpanel-5" />
                </Tabs>
              </div>
            </div>
          </div>
        </div>
        <TabPanel value={selectedTab} index={0}>
          <Overview schoolId={schoolId} />
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <Order schoolId= {id} />
        </TabPanel>
        <TabPanel value={selectedTab} index={2}>
          <SchoolEvents schoolId= {id} />
        </TabPanel>
        <TabPanel value={selectedTab} index={3}>
          <SchoolCareer schoolId= {id} />
        </TabPanel>
        <TabPanel value={selectedTab} index={4}>
          <Users website={getWebiste()} />
        </TabPanel>
      </div>
    </div>
  );
}
