import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  Button,
  CircularProgress,
  Menu,
  TextField,
  // Controller,
  InputLabel,
  Pagination,
  MenuItem,
  Select,
} from "@mui/material";
import { useQuery, useReactiveVar, useMutation } from "@apollo/client";
import { LIST_FOURMS } from "../../Graphql/queries";
import { DELETE_FORUM } from "../../Graphql/mutations";
import { useState } from "react";
import { toggleSnackbar } from "../../RactiveVariables/index";
import { ConfirmDialog } from "../Common";
import AddGroups from "./AddGroups";
import { Link } from "react-router-dom";
import { userDeatils,schoolDtls } from "../../RactiveVariables/index";
import moment from "moment";
import { useHistory } from "react-router-dom";

export default function Forum(props) {
  const history = useHistory();
  const [openAdd, setOpenAdd] = useState(false);
  const [editFourm, setEditFourm] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchVariable, setSearchVariable] = useState(null);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const user = useReactiveVar(userDeatils);
  const schoolDtl = useReactiveVar(schoolDtls);
  const [deleteId, setDeleteId] = useState(null);
  const getVariables = {
    skip: (page - 1) * rowsPerPage,
    limit: rowsPerPage,
    search: searchVariable,
    listType: user.data.loginType,
  };
  const { data, fetchMore, loading } = useQuery(LIST_FOURMS, {
    fetchPolicy: "network-only",
    variables: getVariables,
  });
  const [deleteForum]= useMutation(DELETE_FORUM, {
    onCompleted:res=>{
      setSelectedItem(null)
    }
  })

  const handleChangePage = (e, page) => {
    setPage(page);
  };
  const handleClose = () => {
    setSelectedItem(null);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handlePagination = () => {
    fetchMore({
      variables: getVariables,
    });
  };
  function handlePageInputChange(event) {
    setRowsPerPage(Number(event.target.value));
    setPage(1);
  }
  const handleClick = (event, item) => {
    setSelectedItem(item);
    setAnchorEl(event.currentTarget);
  };
  function handleDelete() {
    deleteForum({ variables: { forumIds: [deleteId] } })
      .then((res) => {
        toggleSnackbar({
          status: true,
          message: "Group deleted successfully",
          variant: "success",
        });
        handlePagination();
      })
      .catch((error) => {
        const message = error && error.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      });
  }
  function closeConfirm() {
    setDeleteId(null);
  }
  function handleConfirm() {
    handleDelete();
    closeConfirm();
    handlePagination();
    setSelectedItem(null)
  }

  return (
    <div className="content-wrapper">
      {openAdd && (
        <AddGroups
          open={openAdd}
          setOpen={setOpenAdd}
          editFourm={editFourm}
          setEditFourm={setEditFourm}
          handlePagination={handlePagination}
        />
      )}
      <div className="container">
      <div className="d-block d-md-none">
          <h3 className="m-h3">{schoolDtl?.data?.schNameThe}</h3>
        </div>
        <div className="row">
          <div className="col-sm-12">

          <ConfirmDialog
          open={deleteId}
          title={"Delete"}
          body={"Are you sure you want to delete this Group ? "}
          closeText={"Cancel"}
          confirmText={"Delete"}
          closeConfirm={closeConfirm}
          handleConfirm={handleConfirm}
        />

            <div className="card border-0">
              <div className="card-body p-0">
                <div className="filter-section d-flex justify-content-between">
                  <div className="d-flex align-items-center">
                    <img
                      className="search-icon"
                      src="../assets/images/search.svg"
                      alt="search icon"
                    />
                    <input
                      className="searchbox"
                      style={{minWidth:"200px"}}
                      placeholder="Search for a group..."
                      type="text"
                      value={searchVariable}
                      onChange={(e) => setSearchVariable(e.target.value)}
                    />
                  </div>
                  <div className="d-flex align-items-center justify-content-center">
                    {user.data.loginType === 'Admin' &&<Button
                      role="button"
                      type="button"
                      className="btn add-btn"
                      onClick={() => {
                        setOpenAdd(true);
                      }}
                    >
                      + Add Group
                    </Button>}
                  </div>
                </div>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Groups</TableCell>
                        <TableCell  style={{textAlign:'center'}}>Date</TableCell>
                        <TableCell  style={{textAlign:'center'}}>Conversations</TableCell>
                        <TableCell style={{textAlign:'center'}}>Members</TableCell>
                        <TableCell  style={{textAlign:'center'}}>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {loading ? (
                        <TableRow>
                          <TableCell colSpan={12}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <p> Loading Groups...</p>
                            </div>
                          </TableCell>
                        </TableRow>
                      ) : data?.listAllForum?.data.length > 0 ? (
                        data?.listAllForum?.data.map((item) => (
                          <TableRow key={item.firstName}>
                            <TableCell>
                              <div className="d-flex align-items-center">
                                <div className="ms-0 cursor-pointer" onClick={()=>history.push({
                                  pathname: `groups-details/${item?._id}`,
                                  state: { title: item?.forumName }
                                })}>{item?.forumName}</div>
                              </div>
                            </TableCell>
                            <TableCell  style={{textAlign:'center'}}>
                              {moment(item.createdDate).format("MMM DD, YYYY")}
                            </TableCell>
                            <TableCell  style={{textAlign:'center'}}>{item.msgCount}</TableCell>
                            <TableCell  style={{textAlign:'center'}}>{item.memCount}</TableCell>
                            <TableCell style={{textAlign:'center'}}>
                              <div className="d-flex justify-content-center">
                                <Button
                                  id="basic-button"
                                  aria-controls="basic-menu"
                                  aria-haspopup="true"
                                  aria-expanded={
                                    selectedItem ? "true" : undefined
                                  }
                                  onClick={(e) => handleClick(e, item)}
                                  className="p-0 action-btn"
                                >
                                  <img
                                    src="../assets/images/dots.svg"
                                    alt="edit button image"
                                  />
                                </Button>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : searchVariable ? (
                        <TableRow>
                          <TableCell colSpan={12}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <p>No Groups Found</p>
                            </div>
                          </TableCell>
                        </TableRow>
                      ) : (
                        <TableRow>
                          <TableCell colSpan={12}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <p>No Groups Added Yet!</p>
                            </div>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                  {!loading && (
                    <div className="pagination-outer">
                      <div className="count-selector">
                        <Select
                          // variant="outlined"
                          onChange={handlePageInputChange}
                          value={rowsPerPage}
                        >
                          <MenuItem value={10}>{10}</MenuItem>
                          <MenuItem value={25}>{25}</MenuItem>
                          <MenuItem value={50}>{50}</MenuItem>
                          <MenuItem value={100}>{100}</MenuItem>
                        </Select>
                        <img
                          className="count-arrow"
                          src="../../assets/images/arrowupdown.svg"
                        />
                      </div>

                      <Pagination
                        count={Math.ceil(
                          data?.listAllForum?.count / rowsPerPage
                        )}
                        page={page}
                        shape="rounded"
                        onChange={handleChangePage}
                      />
                    </div>
                  )}
                </TableContainer>
              </div>
            </div>
            <Menu
              anchorEl={anchorEl}
              open={selectedItem}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "dropdown-button",
              }}
            >
              {user.data.loginType === 'Admin' && <MenuItem onClick={handleClose}>
                <a
                  onClick={() => {
                    setEditFourm(selectedItem);
                    setOpenAdd(true);
                  }}
                  className="actionbtn-menu"
                  href="javascript:;"
                >
                  Edit
                </a>
              </MenuItem>}
              <MenuItem onClick={()=>{handleClose(); }}>
                <Link
                  className="actionbtn-menu"
                  to={`groups-details/${selectedItem?._id}`}
                >
                  View
                </Link>
              </MenuItem>
              {user.data.loginType === 'Admin' && <MenuItem onClick={handleClose}>
                <a
                  onClick={() => { setDeleteId(selectedItem?._id) }}
                  className="actionbtn-menu"
                  href="javascript:;"
                >
                  Delete
                </a>
              </MenuItem>}
            </Menu>
          </div>
        </div>
      </div>
    </div>
  );
}
