import React, { useEffect } from "react";
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import AddSpeaker from "../../Speaker/AddSpeaker";
import { useState } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  InputLabel,
  TextField,
  Select,
  MenuItem,
  Box,
  Tabs,
  Tab,
  Typography,
  Autocomplete,
  Button,
  Avatar,
  Chip,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import EventHeader from "../../Common/EventHeader";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { CREATE_EVENT, UPDATE_EVENT } from "../../../Graphql/mutations";
import {
  GET_ALLSPEAKERS,
  EVENT_DETAILS,
  GET_ALLLOCATIONS,
} from "../../../Graphql/queries";
import { toggleSnackbar } from "../../../RactiveVariables/index";
import SpeakersView from "./SpeakerView";
import moment from "moment";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import { useHistory, useParams } from "react-router-dom";
import AddLocation from "../../Location/AddLocation";
import AddAdditionalInfo from "./AddAdditionalInfo";
import {
  platformArray,
  categoryArray,
  eventTypeArray,
  statusArray,
  tagArray,
} from "../EventOptions";
import { Loader } from "../../Common";
import TimePicker from "@mui/lab/TimePicker";
import Banner from "./Banner";
import axios from "axios";
import { DateTimePicker } from "@mui/lab";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const discDefaultVal  = "<ul>"+
"<li>Registration closes 1 hour before the start of the event.</li>"+
 "<li>Be sure to enter all attendee email addresses correctly.</li>"+
"<li>The Zoom link for this eSeminar will be sent in the confirmation "+
 "email after you finish the registration process. Please do not "+
 "consider yourself officially registered until you receive the email.</li>"+
"<li>This eSeminar will be recorded and, depending on privacy and "+
 "quality concerns, may be provided to registered attendees after "+
 "the presentation.</li>"+
 "<li>Most registrations may be cancelled by registered users until 10 days before an event starts,"+
 " after which users can reach out to support@nysais.org with any COVID-related requests.</li>"+
"</ul>";

export default function AddEvent(props) {
  const [openAdd, setOpenAdd] = useState(false);
  // const [defaultValue, setDefaultValue] = useState(null);
  const [openAddLocation, setOpenAddLocation] = useState(false);
  const [openAddAdditionalInfo, setOpenAddAdditionalInfo] = useState(false);
  const [value, setValue] = useState(0);
  const [offLocationValue, setOffLocationValue] = useState(null);
  const [speakersFormatedList, setSpeakersFormatedList] = useState([]);
  const [selectedSpeakerValue, setSelectedSpeakerValue] = useState([]);
  const [addInfo, setAddInfo] = useState([]);
  const [eventType, setEventType] = useState(null);
  const [bannerImages, setBannerImages] = useState([]);
  const [textEditorValue, setTextEditorValue] = useState(
    EditorState.createEmpty()
  );
  let { contentBlocks, entityMap } = htmlToDraft(discDefaultVal);
  let contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
  const [distextEditorValue, setDistextEditorValue] = useState(
    EditorState.createWithContent(contentState)
  );
  const [emailtextEditorValue, setEmailtextEditorValue] = useState(
    EditorState.createEmpty()
  );
  const { id } = useParams();
  const history = useHistory();
  const [addEvent, { loading }] = useMutation(CREATE_EVENT);
  const [editEvent, { loading: updateLoading }] = useMutation(UPDATE_EVENT);
  const {
    data: speakersList,
    fetchMore,
    refetch: refetchSpeakers,
  } = useQuery(GET_ALLSPEAKERS, {
    onCompleted: (res) => {
      let array = [];
      res?.listAllSpeakerss.forEach((element) => {
        array.push({
          name: element?.firstName + " " + element?.lastName,
          spkId: element._id,
          profileImage: element?.profileImage,
        });
      });
      setSpeakersFormatedList(array);
    },
  });
  const schema = yup.object().shape({
    coupenCodes: yup
    .array()
    .of(
      yup.object().shape({
        coupenTitle: yup.string().required("Coupon Code is required").nullable(),
        perValue: yup
          .string()
          .required("Discount Percentage is required")
          .test("perValue", "Must be a number", (value) => {
            if (value && isNaN(value)) return false;
            return true;
          })
          .test("perValue", "Maximum Discount Percentage is 100%", (value) => {
            if (value && parseInt(value) > 100) return false;
            return true;
          })
          .nullable(),
      })
    ),
    title: yup.string().required("Event Name is required"),
    category: yup.string().required("please select Category"),
    eventType: yup.string().required("please select Event Type"),
    targetAudience: yup.string().required("Target Audience is required"),
    // closingDate: yup.string().required("Closing date is required"),
    // openingDate: yup.string().required("Opening date is required"),

    startDate: yup.string().required("Start date is required"),
    // startTime: yup.string().required("Start time is required"),
    endDate: yup
      .string()
      .required("End date is required")
      .when("startDate", (startDate) => {
        if (startDate) {
          return yup.date().min(startDate, "End date must be after start date");
        }
      }),
    // endTime: yup.string().required("End time is required"),
    description: yup.string().required("Description is required"),
    seats: yup
      .string()
      .required("Seats available is required")
      .test("seats", "Must be a number", (value) => {
        if (value && isNaN(value)) return false;
        else return true;
      }),
      bulkDiscount: yup
      .string()
      .test("bulkDiscount", "Must be a number", (value) => {
        if (value && isNaN(value)) return false;
        return true;
      })
      .test("bulkDiscount", "Maximum value is 100", (value) => {
        if (value && parseInt(value) > 100) return false;
        return true;
      }),
    nonMemberPrice: yup
      .string().nullable()
      // .required("Non-Member Price is required")
      .test("nonMemberPrice", "Must be a number", (value) => {
        if (value && isNaN(value)) return false;
        else return true;
      }),
    memberPrice: yup
      .string()
      .required("Member Price is required")
      .test("memberPrice", "Must be a number", (value) => {
        if (value && isNaN(value)) return false;
        else return true;
      }),
    status: yup.string().required("Status is required"),
    lastDateToRegister: yup
      .string().nullable()
      .when("endDate", (endDate,schema,val) => {
        if (endDate&&endDate!==""&&val.value&&val.value!=="") {
          return yup.date().max(endDate, "Last date to register must be before end date");
        }
      })
      .required("Last date to register is required"),
      regStartDate: yup
      .string().nullable()
      // .required("Registration start date is required")
      .when("startDate", (startDate,schema,val) => {
        if (startDate&&val.value&&val.value!=="") {
          return yup.date().max(startDate, "Registration start date must be before start date");
        }
      }),
      regStartDate: yup
      .string().nullable()
      // .required("Registration start date is required")
      .when("lastDateToRegister", (lastDateToRegister,schema,val) => {
        if(lastDateToRegister&&val.value&&val.value!==""){
          return yup.date().max(lastDateToRegister, "Registration start date must be before last date to register");
        }
      }),
    // lastDateToCancel: yup.string().required("Last date to cancel is required"),
    // featuredTo: yup.string().when("featuredFrom", (featuredFrom) => {
    //   if (featuredFrom) {
    //     return yup.date().min(featuredFrom, "To date must be after from date");
    //   }
    // }),
    location:
      eventType &&
      eventType !== "In-Person" &&
      yup.object().shape({
        platform: yup.string().required("Platform is required").nullable(),
        link: yup.string().required("Link is required").nullable(),
      }),
    offLocationId:
      eventType &&
      eventType !== "Remote" &&
      yup.string().required("Location is required").nullable(),
  });
  const { data: locationsList, refetch } = useQuery(GET_ALLLOCATIONS, {
    fetchPolicy: "network-only",
  });

  const {
    register,
    handleSubmit,
    formState,
    reset,
    setValue: setFormValue,
    control,
    trigger,
    getValues,
  } = useForm({
    // defaultValues: { coupenCodes: [{ coupenTitle: null, perValue: null }] },
    resolver: yupResolver(schema),
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "coupenCodes",
  });
  const [getEventDetails, { data: eventData, loading: eventDataLoading }] =
    useLazyQuery(EVENT_DETAILS, {
      fetchPolicy: "network-only",
      onCompleted: (res) => {
        let data = res?.eventDetails;
        if (data?.addInfo.length > 0) {
          let addInfo = [];
          data?.addInfo.forEach((element) => {
            addInfo.push({
              title: element.title,
              fileUrl: element.fileUrl,
            });
          });
          setAddInfo(addInfo);
        }
        if (data?.addInfo.length > 0) {
          let coupenCodes = [];
          data?.coupenCodes.forEach((element) => {
            coupenCodes.push({
              coupenTitle: element.coupenTitle,
              perValue: element.perValue,
            });
          });
          setFormValue("coupenCodes", coupenCodes);
        }
        setFormValue(
          "startDate",
          data.startDate
        );
        setFormValue(
          "endDate",
          data.endDate
        );
        setFormValue(
          "lastDateToRegister",
          data.lastDateToRegister
        );
        setFormValue(
          "lastDateToCancel",
          data.lastDateToCancel
        );
        setFormValue(
          "regStartDate",
          data.regStartDate
        );
        setBannerImages(data?.banner);
        // setFormValue(
        //   "openingDate",
        //   moment(data.openingDate).utc().format("MM/DD/YYYY")
        // );
        // setFormValue(
        //   "closingDate",
        //   moment(data.closingDate).utc().format("MM/DD/YYYY")
        // );
        if (data.featuredFrom)
          setFormValue(
            "featuredFrom",
            data.featuredFrom
          );
        if (data.featuredTo)
          setFormValue(
            "featuredTo",
            data.featuredTo
          );
        setFormValue("eventType", data.eventType);
        setEventType(data.eventType);
        setFormValue("status", data.status);
        setFormValue("description", data.description);
        setFormValue("disclaimer", data.disclaimer);
        setFormValue("emailContent", data.emailContent);
        setFormValue("endTime", data.endTime);
        setFormValue("startTime", data.startTime);
        let coupen = [];
        data.coupenCodes.forEach((element) => {
          coupen.push({
            coupenTitle: element.coupenTitle,
            perValue: element.perValue,
          });
        });
        setFormValue("coupenCodes", coupen);
        setOffLocationValue({
          locName: data?.offLocDtls?.locName,
          _id: data?.offLocationId,
        });
        setFormValue(
          "offLocationId",
          data?.offLocationId,
        );
        setSelectedSpeakerValue(data.speakerIDs);
        if (data?.description){
          let blocksFromHtml = htmlToDraft(data?.description);
          let { contentBlocks, entityMap } = blocksFromHtml;
          let contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
          setTextEditorValue(EditorState.createWithContent(contentState));
        }
        if (data?.disclaimer){
          let blocksFromHtml = htmlToDraft(data?.disclaimer);
          let { contentBlocks, entityMap } = blocksFromHtml;
          let contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
          setDistextEditorValue(EditorState.createWithContent(contentState));
        }
        if (data?.emailContent){
          let blocksFromHtml = htmlToDraft(data?.emailContent);
          let { contentBlocks, entityMap } = blocksFromHtml;
          let contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
          setEmailtextEditorValue(EditorState.createWithContent(contentState));
        }
      },
    });
  const eventDetails = eventData?.eventDetails;

  useEffect(() => {
    if (id !== "$") getEventDetails({ variables: { id: id } });
  }, []);

  const { errors } = formState;
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // function convertDate(str,type) {
  //   if(type){
  //     var date = new Date(str),
  //     mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  //     day = ("0" + date.getDate()).slice(-2),
  //     hour = ("0" + date.getHours()).slice(-2),
  //     min = ("0" + date.getMinutes()).slice(-2),
  //     ampm = ("0" + date.get()).slice(-2);
  //   return [mnth, day, date.getFullYear()].join("/")+" "+hour+ ":"+min ;
  //   }
  //   var date = new Date(str),
  //     mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  //     day = ("0" + date.getDate()).slice(-2);
  //   return [mnth, day, date.getFullYear()].join("/");
  // }
  function convertDate(date,addtime,time){
    console.log("data",date,addtime,time);
    if(date){
    if(addtime)
      if(time)
        return new Date(date.setHours(0,0,0,0)).toUTCString();
      else
        return new Date(date.setHours(23,59,59,0)).toUTCString();
    return new Date(date).toUTCString();
  }
  else
  return null
  }

  function onSubmit(params) {
       if (selectedSpeakerValue.length > 0)
      params["speakerIDs"] = selectedSpeakerValue;
    else{
      toggleSnackbar({
        status: true,
        message: "Please select atleast one speaker",
        variant: "error",
      });
      return false;
    }
    let imageArray = [];
    bannerImages.forEach((element) => {
      if (element.file) {
        const response = axios.put(element.signedUrl, element.file, {
          headers: {
            "Content-Type": element?.file?.type,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "X-Requested-With",
          },
        });
        imageArray.push({
          url: element.publicUrl,
          priority: "",
          title: "",
        });
      } else {
        imageArray.push({
          url: element.url,
          priority: element.priority,
          title: element.title,
        });
      }
    });
    params["banner"] = imageArray;
    params["addInfo"] = addInfo;
    // if (typeof params.endDate === "object")
    //   params.endDate = convertDate(params.endDate);
    // if (typeof params.featuredTo === "object")
    //   params.featuredTo = convertDate(params.featuredTo);
    // temp fix needed to change
    if (params.offLocationId === "") delete params.offLocationId;
    if (params.eventType === "Remote") params.offLocationId = null;

    if (params?.eventTags) {
      let sklTagArray = [];
      Object.keys(params?.eventTags).map((key) => {
        if (params?.eventTags[key])
          sklTagArray.push({ title: key, status: params?.eventTags[key] });
      });
      params["eventTags"] = sklTagArray;
    }
    if (props.location.pathname.includes("cloneevent")) {
      try {
        addEvent({ variables: { data: params } })
          .then((res) => {
            toggleSnackbar({
              status: true,
              message: "Event cloned successfully",
              variant: "success",
            });
            history.push("/events");
          })
          .catch((error) => {
            const message = error && error.message;
            toggleSnackbar({
              status: true,
              message: message,
              variant: "error",
            });
          });
      } catch (error) {
        const message = error && error.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      }
    } else {
      if (id === "$") {
        try {
          addEvent({ variables: { data: params } })
            .then((res) => {
              toggleSnackbar({
                status: true,
                message: "Event added successfully",
                variant: "success",
              });
              history.push("/events");
            })
            .catch((error) => {
              const message = error && error.message;
              toggleSnackbar({
                status: true,
                message: message,
                variant: "error",
              });
            });
        } catch (error) {
          const message = error && error.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        }
      } else {
        try {
          editEvent({ variables: { data: params, _id: id } })
            .then((res) => {
              toggleSnackbar({
                status: true,
                message: "Event updated successfully",
                variant: "success",
              });
              history.push("/events");
            })
            .catch((error) => {
              const message = error && error.message;
              toggleSnackbar({
                status: true,
                message: message,
                variant: "error",
              });
            });
        } catch (error) {
          const message = error && error.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        }
      }
    }
  }
  function onChangeEditor(value, type) {
    if (type === 1) {
      // if (value.toString("html") === "<p><br></p>") {
      //   registerDescription.onChange({
      //     target: { value: "", name: "description" },
      //   });
      // } else {
      //   registerDescription.onChange({
      //     target: { value: value.toString("html"), name: "description" },
      //   });
      // }
      // setTextEditorValue(value);
      setTextEditorValue(value)
    registerDescription.onChange({
      target: { value: draftToHtml(convertToRaw(value.getCurrentContent())), name: "description" },
    });
    } else if (type === 2) {
      // if (value.toString("html") === "<p><br></p>") {
      //   registerDisclaimer.onChange({
      //     target: { value: "", name: "disclaimer" },
      //   });
      // } else {
      //   registerDisclaimer.onChange({
      //     target: { value: value.toString("html"), name: "disclaimer" },
      //   });
      // }
      // setDistextEditorValue(value);
      setDistextEditorValue(value)
      registerDisclaimer.onChange({
      target: { value: draftToHtml(convertToRaw(value.getCurrentContent())), name: "disclaimer" },
    });
    } else if (type === 3) {
      // if (value.toString("html") === "<p><br></p>") {
      //   registerEmailContent.onChange({
      //     target: { value: "", name: "emailContent" },
      //   });
      // } else {
      //   registerEmailContent.onChange({
      //     target: { value: value.toString("html"), name: "emailContent" },
      //   });
      // }
      // setEmailtextEditorValue(value);
      setEmailtextEditorValue(value)
      registerEmailContent.onChange({
      target: { value: draftToHtml(convertToRaw(value.getCurrentContent())), name: "emailContent" },
    });
    }
  }
  function addSpeaker(value) {
    let array = [...selectedSpeakerValue];
    array.push(value);
    setSelectedSpeakerValue(array);
    refetchSpeakers();
  }
  function handleAddLocation(value) {
    setOffLocationValue({
      locName: value?.locName,
      _id: value?._id,
    });
    setFormValue("offLocationId", value?._id);
    refetch();
  }
  const registerDescription = register("description");
  const registerDisclaimer = register("disclaimer");
  const registerEmailContent = register("emailContent");
  function handleInfoSubmit(params) {
    setAddInfo([...addInfo, params]);
  }
  const toolbarConfig = {
    display: [
      "INLINE_STYLE_BUTTONS",
      "BLOCK_TYPE_BUTTONS",
      "LINK_BUTTONS",
      "HISTORY_BUTTONS",
      
    ],
    // renderNode: {
    //   ["INLINES.HYPERLINK"]: (node, children) => {
    //     return <a target="_blank" rel="noopener noreferrer" href={node.data.uri}>{children}</a>
    //   }
    // },
    INLINE_STYLE_BUTTONS: [
      { label: "Bold", style: "BOLD", className: "custom-css-class" },
      { label: "Italic", style: "ITALIC" },
      { label: "StrikeThrough", style: "STRIKETHROUGH" },
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: "UL", style: "unordered-list-item" },
      { label: "OL", style: "ordered-list-item" },
    ],
  };
  function handleInfoDelete(index) {
    let array = [...addInfo];
    array.splice(index, 1);
    setAddInfo(array);
  }
  function getTagValue(name) {
    if (eventDetails?.eventTags) {
      let position = eventDetails?.eventTags.findIndex(
        (element) => element.title === name
      );
      if (position > -1) return eventDetails?.eventTags[position]?.status;
    }
  }
  return (
    <div className="fullwidth-body">
      <Loader open={eventDataLoading} />
      {openAdd && (
        <AddSpeaker
          open={openAdd}
          setOpen={setOpenAdd}
          fetchMore={fetchMore}
          addSpeaker={addSpeaker}
          event
        />
      )}
      <AddLocation
        selectedItem={{}}
        open={openAddLocation}
        setOpen={setOpenAddLocation}
        handleAddLocation={handleAddLocation}
        event
      />
      <AddAdditionalInfo
        open={openAddAdditionalInfo}
        setOpen={setOpenAddAdditionalInfo}
        handleInfoSubmit={handleInfoSubmit}
      />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {(id === "$" || eventDetails) && (
          <form
            className="adduser-form"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
          >
            <EventHeader
              title={id === "$" ? "Create Event" : "Edit Event"}
              addevents
              saveBtnLoading={loading || updateLoading}
            />
            <div className="event-outer">
              <div className="container">
                <div className="row">
                  <div className="col-sm-8">
                    <div className="card mb-4">
                      <div className="card-title">
                        <img src="../../assets/images/calendar.svg" /> Event
                        Information
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-6">
                            <InputLabel className="form-label">
                              Event Name<sup className="text-danger">*</sup>
                            </InputLabel>
                            <TextField
                              autoFocus
                              style={{ width: "100%" }}
                              variant="outlined"
                              {...register("title")}
                              error={errors.title}
                              defaultValue={eventDetails?.title}
                            />
                            {errors && errors?.["title"] && (
                              <p className="errorMessage text-danger">
                                {errors?.title?.message}
                              </p>
                            )}
                          </div>
                          <div className="col-md-3">
                            <InputLabel className="form-label">
                              Category<sup className="text-danger">*</sup>
                            </InputLabel>
                            <Select
                              className="w-100"
                              variant="outlined"
                              {...register("category")}
                              error={errors.category}
                              defaultValue={eventDetails?.category}
                              onChange={(e) => {
                                register("category").onChange({
                                  target: {
                                    value: e.target.value,
                                    name: "category",
                                  },
                                });
                                if (
                                  e.target.value === "eSeminar" ||
                                  e.target.value === "E-Conferences" ||
                                  e.target.value === "Residential Conference"
                                ) {
                                  register("eventType").onChange({
                                    target: {
                                      value: "Remote",
                                      name: "eventType",
                                    },
                                  });
                                  setEventType("Remote");
                                } else if (e.target.value === "Workshops") {
                                  register("eventType").onChange({
                                    target: {
                                      value: "In-Person",
                                      name: "eventType",
                                    },
                                  });
                                  setEventType("In-Person");
                                }
                              }}
                            >
                              {categoryArray.map((item) => (
                                <MenuItem style={{textTransform: "initial"}} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {errors && errors?.["category"] && (
                              <p className="errorMessage text-danger">
                                {errors?.category?.message}
                              </p>
                            )}
                          </div>
                          <div className="col-md-3">
                            {/* <Controller
                      control={control}
                      name="category" render={({ field: { onChange, value } }) => (
                        <> */}
                            <InputLabel className="form-label">
                              Event Type<sup className="text-danger">*</sup>
                            </InputLabel>
                            <Select
                              className="w-100"
                              variant="outlined"
                              error={errors.eventType}
                              value={eventType}
                              // {...register("eventType")}
                              // defaultValue={ value === "eSeminar" ? "Remote" : eventDetails?.eventType}
                              onChange={(e) => {
                                setEventType(e.target.value);
                                register("eventType").onChange({
                                  target: {
                                    value: e.target.value,
                                    name: "eventType",
                                  },
                                });
                                setFormValue("location", null);
                                setOffLocationValue(null);
                              }}
                            >
                              {eventTypeArray.map((item) => (
                                <MenuItem value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {errors && errors?.["eventType"] && (
                              <p className="errorMessage text-danger">
                                {errors?.eventType?.message}
                              </p>
                            )}
                            {/* </>
                      )}
                            
                            /> */}
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-md-6">
                            <Controller
                              control={control}
                              name="startDate"
                              render={({ field: { onChange, value } }) => (
                                <>
                                  <InputLabel className="form-label">
                                    Start date
                                    <sup className="text-danger">*</sup>
                                  </InputLabel>
                                  {/* <DesktopDatePicker
                                    inputFormat="MM/dd/yyyy"
                                    value={value ? value : null}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        className="date-field"
                                      />
                                    )}
                                    onChange={(e) => {
                                      onChange(convertDate(e));
                                      if (
                                        !getValues("endDate") ||
                                        moment(
                                          getValues("endDate"),
                                          "MM/DD/YYYY"
                                        ).diff(
                                          moment(convertDate(e), "MM/DD/YYYY")
                                        ) < 0
                                      ) {
                                        setFormValue("endDate", convertDate(e));
                                        trigger("endDate");
                                      }
                                    }}
                                  /> */}
                                  <DateTimePicker
                                    renderInput={(props) => <TextField {...props} className="date-field" />}
                                    value={value ? value : null}
                                    onChange={(e) => {onChange(convertDate(e));
                                      setFormValue("startTime",moment(e).format("HH:mm"))
                                      setFormValue("endDate",convertDate(e))
                                      setFormValue("endTime",moment(e).format("HH:mm"))
                                    }}
                                  />
                                  {errors && errors?.["startDate"] && (
                                    <p className="errorMessage text-danger">
                                      {errors?.startDate?.message}
                                    </p>
                                  )}
                                </>
                              )}
                            />
                          </div>
                          {/* <div className="col-md-3">
                            <InputLabel className="form-label">
                              Start time<sup className="text-danger">*</sup>
                            </InputLabel>
                            <Controller
                              control={control}
                              name="startTime"
                              render={({ field: { onChange, value } }) => {
                                return (
                                  <>
                                    <TimePicker
                                      value={new Date(moment(value, "HH:mm"))}
                                      onChange={(e) => {
                                        onChange(
                                          moment(new Date(e)).format("HH:mm")
                                        );
                                        // setFormValue("endTime",moment(new Date(e)).format("HH:mm"));
                                        // trigger("endTime");
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          className="date-field"
                                        />
                                      )}
                                    />
                                    {errors && errors?.["startTime"] && (
                                      <p className="errorMessage text-danger">
                                        {errors?.startTime?.message}
                                      </p>
                                    )}
                                  </>
                                );
                              }}
                            />
                          </div> */}
                          <div className="col-md-6">
                            <Controller
                              control={control}
                              name="endDate"
                              render={({ field: { onChange, value } }) => (
                                <>
                                  <InputLabel className="form-label">
                                    End date<sup className="text-danger">*</sup>
                                  </InputLabel>
                                  {/* <DesktopDatePicker
                                    inputFormat="MM/dd/yyyy"
                                    value={value ? value : null}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        className="date-field"
                                      />
                                    )}
                                    onChange={(e) => onChange(convertDate(e))}
                                  /> */}
                                  <DateTimePicker
                                    renderInput={(props) => <TextField {...props} className="date-field" />}
                                    value={value ? value : null}
                                    onChange={(e) => {onChange(convertDate(e));
                                      setFormValue("endTime",moment(e).format("HH:mm"))}}
                                  />
                                  {errors && errors?.["endDate"] && (
                                    <p className="errorMessage text-danger">
                                      {errors?.endDate?.message}
                                    </p>
                                  )}
                                </>
                              )}
                            />
                          </div>
                          {/* <div className="col-md-3">
                            <InputLabel className="form-label">
                              End time<sup className="text-danger">*</sup>
                            </InputLabel>
                            <Controller
                              control={control}
                              name="endTime"
                              render={({ field: { onChange, value } }) => {
                                return (
                                  <>
                                    <TimePicker
                                      value={new Date(moment(value, "HH:mm"))}
                                      onChange={(e) =>
                                        onChange(
                                          moment(new Date(e)).format("HH:mm")
                                        )
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          className="date-field"
                                        />
                                      )}
                                    />
                                    {errors && errors?.["endTime"] && (
                                      <p className="errorMessage text-danger">
                                        {errors?.endTime?.message}
                                      </p>
                                    )}
                                  </>
                                );
                              }}
                            />
                          </div> */}
                          <div className="col-md-12 mt-3">
                            <InputLabel className="form-label">
                              Target Audience
                              <sup className="text-danger">*</sup>
                            </InputLabel>
                            <TextField
                              style={{ width: "100%" }}
                              variant="outlined"
                              {...register("targetAudience")}
                              error={errors.targetAudience}
                              defaultValue={eventDetails?.targetAudience}
                            />
                            {errors && errors?.["targetAudience"] && (
                              <p className="errorMessage text-danger">
                                {errors?.targetAudience?.message}
                              </p>
                            )}
                          </div>
                          <div className="col-sm-12 mt-3">
                            <div className="tab-outer">
                              <Box
                                sx={{ borderBottom: 1, borderColor: "divider" }}
                              >
                                <Tabs
                                  value={value}
                                  onChange={handleChange}
                                  aria-label="basic tabs example"
                                >
                                  <Tab
                                    className="mandatory"
                                    label="Description"
                                    {...a11yProps(0)}
                                  />
                                  <Tab label="Disclaimer" {...a11yProps(1)} />
                                  <Tab
                                    label="Override Confirmation Email"
                                    {...a11yProps(2)}
                                  />
                                </Tabs>
                              </Box>
                              <TabPanel value={value} index={0}>
                                {/* <RichTextEditor
                                  value={textEditorValue}
                                  toolbarConfig={toolbarConfig}
                                  {...registerDescription}
                                  onChange={(value) => onChangeEditor(value, 1)}
                                /> */}
                                <Editor
                                  editorState={textEditorValue}
                                  toolbarClassName="toolbarClassName"
                                  wrapperClassName="wrapperClassName"
                                  editorClassName="editorClassName"
                                  onEditorStateChange={(value) => onChangeEditor(value, 1)}
                                />
                                {errors && errors?.["description"] && (
                                  <p className="errorMessage text-danger">
                                    {errors?.description?.message}
                                  </p>
                                )}
                              </TabPanel>
                              <TabPanel value={value} index={1}>
                                {/* <RichTextEditor
                                  value={distextEditorValue}
                                  toolbarConfig={toolbarConfig}
                                  {...registerDisclaimer}
                                  onChange={(value) => onChangeEditor(value, 2)}
                                /> */}
                                <Editor
                                  editorState={distextEditorValue}
                                  toolbarClassName="toolbarClassName"
                                  wrapperClassName="wrapperClassName"
                                  editorClassName="editorClassName"
                                  onEditorStateChange={(value) => onChangeEditor(value, 2)}
                                />
                                {errors && errors?.["disclaimer"] && (
                                  <p className="errorMessage text-danger">
                                    {errors?.disclaimer?.message}
                                  </p>
                                )}
                              </TabPanel>
                              <TabPanel value={value} index={2}>
                                {/* <RichTextEditor
                                  value={emailtextEditorValue}
                                  toolbarConfig={toolbarConfig}
                                  {...registerEmailContent}
                                  onChange={(value) => onChangeEditor(value, 3)}
                                /> */}
                                <Editor
                                  editorState={emailtextEditorValue}
                                  toolbarClassName="toolbarClassName"
                                  wrapperClassName="wrapperClassName"
                                  editorClassName="editorClassName"
                                  onEditorStateChange={(value) => onChangeEditor(value, 3)}
                                />
                                {errors && errors?.["emailContent"] && (
                                  <p className="errorMessage text-danger">
                                    {errors?.emailContent?.message}
                                  </p>
                                )}
                              </TabPanel>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <SpeakersView
                      data={speakersFormatedList}
                      setOpenAdd={setOpenAdd}
                      selectedValue={selectedSpeakerValue}
                      setSelectedValue={setSelectedSpeakerValue}
                    />
                    {(eventType === "Remote" || eventType === "Hybrid") && (
                      <div className="card mb-4">
                        <div className="card-title">
                          <img src="../../assets/images/schoollink.png" />{" "}
                          Remote
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-6 mb-3">
                              <InputLabel className="form-label">
                                Platform
                              </InputLabel>
                              <Controller
                                control={control}
                                name="location.platform"
                                render={({ field: { onChange, value } }) => (
                                  <Select
                                    className="w-100"
                                    variant="outlined"
                                    {...register("location.platform")}
                                    value={value}
                                    defaultValue={
                                      id === "$"
                                        ? "Zoom"
                                        : eventDetails?.location?.platform
                                    }
                                  >
                                    {platformArray.map((item) => (
                                      <MenuItem value={item}>{item}</MenuItem>
                                    ))}
                                  </Select>
                                )}
                              />
                              {errors && errors?.location?.platform && (
                                <p className="errorMessage text-danger">
                                  {errors?.location?.platform?.message}
                                </p>
                              )}
                            </div>
                            <div className="col-md-6 mb-3">
                              <InputLabel className="form-label">
                                Meeting link
                              </InputLabel>
                              <Controller
                                control={control}
                                name="location.link"
                                render={({ field: { onChange, value } }) => (
                                  <>
                                    <TextField
                                      style={{ width: "100%" }}
                                      variant="outlined"
                                      {...register("location.link")}
                                      defaultValue={
                                        eventDetails?.location?.link
                                      }
                                    />

                                    {errors && errors?.location?.link && (
                                      <p className="errorMessage text-danger">
                                        {errors?.location?.link?.message}
                                      </p>
                                    )}
                                  </>
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {(eventType === "In-Person" || eventType === "Hybrid") && (
                      <div className="card mb-4">
                        <div className="card-title justify-content-between">
                          <div className="d-flex">
                            <img src="../../assets/images/Map.svg" /> Location
                          </div>

                          <a
                            className="addspeaker-btn"
                            href="javascript:;"
                            onClick={() => setOpenAddLocation(true)}
                          >
                            <img src="../../assets/images/mapblue.svg" />
                            Add Location
                          </a>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-6 mb-3">
                              <InputLabel className="form-label">
                                Select Location
                              </InputLabel>
                              <Autocomplete
                                className="loc-autocomplete"
                                value={
                                  offLocationValue?._id !== null
                                    ? offLocationValue
                                    : ""
                                }
                                options={
                                  locationsList?.listAllOffLocations || []
                                }
                                getOptionLabel={(option) => option.locName || ""}
                                {...register("offLocationId")}
                                onChange={(e, value) => {
                                  if (value?._id) {
                                    register("offLocationId").onChange({
                                      target: {
                                        value: value?._id,
                                        name: "offLocationId",
                                      },
                                    });
                                    setOffLocationValue({
                                      locName: value?.locName,
                                      _id: value?._id,
                                    });
                                  } else {
                                    register("offLocationId").onChange({
                                      target: {
                                        value: null,
                                        name: "offLocationId",
                                      },
                                    });
                                    setOffLocationValue(null);
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                              />
                              {errors && errors?.["offLocationId"] && (
                                <p className="errorMessage text-danger">
                                  {errors?.offLocationId?.message}
                                </p>
                              )}
                            </div>
                            {/* <div className="col-md-6 mb-3">
                                    <InputLabel className="form-label">
                                      Address
                                    </InputLabel>
                                    <TextField
                                      id="location-address"
                                      style={{ width: "100%" }}
                                      variant="outlined"
                                    />
                                  </div> */}
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="card">
                      <div className="card-title justify-content-between">
                        <div className="d-flex">Additional Information</div>
                        <a
                          className="addspeaker-btn"
                          href="javascript:;"
                          onClick={() => setOpenAddAdditionalInfo(true)}
                        >
                          <img src="../../assets/images/addinfo.svg" />
                          Add Additional Info
                        </a>
                      </div>
                      <div className="card-body">
                        {addInfo.map((item, index) => (
                          <Chip
                            avatar={
                              <Avatar src={item.fileUrl}>
                                {item?.title?.[0].toUpperCase()}
                              </Avatar>
                            }
                            label={item?.title}
                            variant="outlined"
                            onDelete={() => handleInfoDelete(index)}
                          />
                        ))}
                      </div>
                    </div>
                    <div className="mt-3">
                      <Banner
                        setBannerImages={setBannerImages}
                        bannerImages={bannerImages}
                      />
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="card">
                      <div className="card-title">
                        <img src="../../assets/images/settings.svg" /> Public
                        Settings
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-12 mb-3">
                            <InputLabel className="form-label">
                              Status
                            </InputLabel>
                            <Select
                              className="w-100"
                              variant="outlined"
                              {...register("status")}
                              error={errors.status}
                              defaultValue={
                                id === "$" ? "Draft" : eventDetails?.status
                              }
                            >
                              {statusArray.map((item) => (
                                <MenuItem
                                  value={item.value}
                                  disabled={
                                    item.isSpeakerAdded &&
                                    selectedSpeakerValue.length === 0
                                      ? true
                                      : false
                                  }
                                >
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {errors && errors?.["status"] && (
                              <p className="errorMessage text-danger">
                                {errors?.status?.message}
                              </p>
                            )}
                          </div>
                          <div className="col-md-6">
                            <Controller
                              control={control}
                              name="featuredFrom"
                              render={({ field: { onChange, value } }) => (
                                <>
                                  <InputLabel className="form-label">
                                    Featured from
                                  </InputLabel>
                                  <DesktopDatePicker
                                    inputFormat="MM/dd/yyyy"
                                    value={value ? value : null}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        className="date-field"
                                      />
                                    )}
                                    onChange={(e) => onChange(e ? new Date(e.setHours(0,0,0,0)).toISOString() : null)}
                                  />
                                  {errors && errors?.["featuredFrom"] && (
                                    <p className="errorMessage text-danger">
                                      {errors?.featuredFrom?.message}
                                    </p>
                                  )}
                                </>
                              )}
                            />
                          </div>
                          <div className="col-md-6">
                            <Controller
                              control={control}
                              name="featuredTo"
                              render={({ field: { onChange, value } }) => (
                                <>
                                  <InputLabel className="form-label">
                                    Featured to
                                  </InputLabel>
                                  <DesktopDatePicker
                                    inputFormat="MM/dd/yyyy"
                                    value={value ? value : null}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        className="date-field"
                                      />
                                    )}
                                    onChange={(e) => onChange(e ? new Date(e.setHours(23,59,59,999)).toISOString() : null)}
                                  />
                                  {errors && errors?.["featuredTo"] && (
                                    <p className="errorMessage text-danger">
                                      {errors?.featuredTo?.message}
                                    </p>
                                  )}
                                </>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card mt-4">
                      <div className="card-title">
                        <img src="../../assets/images/edit2.svg" /> Order
                        Settings
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-6 mb-3">
                            <InputLabel className="form-label">
                              Seats available
                              <sup className="text-danger">*</sup>
                            </InputLabel>
                            <TextField
                              style={{ width: "100%" }}
                              variant="outlined"
                              {...register("seats")}
                              error={errors.seats}
                              defaultValue={eventDetails?.seats}
                            />
                            {errors && errors?.["seats"] && (
                              <p className="errorMessage text-danger">
                                {errors?.seats?.message}
                              </p>
                            )}
                          </div>
                          <div className="col-md-6 mb-3">
                            <InputLabel className="form-label">
                              Bulk Discount Percentage
                            </InputLabel>
                            <TextField
                              className="persentage-sign"
                              style={{ width: "100%" }}
                              variant="outlined"
                              {...register("bulkDiscount")}
                              error={errors.bulkDiscount}
                              defaultValue={eventDetails?.bulkDiscount}
                            />
                            {errors && errors?.["bulkDiscount"] && (
                              <p className="errorMessage text-danger">
                                {errors?.bulkDiscount?.message}
                              </p>
                            )}
                          </div>
                          <div className="col-md-6 mb-3">
                            <InputLabel className="form-label">
                              Non-Member price
                              {/* <sup className="text-danger">*</sup> */}
                            </InputLabel>
                            <TextField
                              className="doller-sign"
                              style={{ width: "100%" }}
                              variant="outlined"
                              {...register("nonMemberPrice")}
                              error={errors.nonMemberPrice}
                              defaultValue={eventDetails?.nonMemberPrice}
                            />
                            {errors && errors?.["nonMemberPrice"] && (
                              <p className="errorMessage text-danger">
                                {errors?.nonMemberPrice?.message}
                              </p>
                            )}
                          </div>
                          <div className="col-md-6 mb-3">
                            <InputLabel className="form-label">
                              Member price<sup className="text-danger">*</sup>
                            </InputLabel>
                            <TextField
                              className="doller-sign"
                              style={{ width: "100%" }}
                              variant="outlined"
                              {...register("memberPrice")}
                              error={errors.memberPrice}
                              defaultValue={eventDetails?.memberPrice}
                            />
                            {errors && errors?.["memberPrice"] && (
                              <p className="errorMessage text-danger">
                                {errors?.memberPrice?.message}
                              </p>
                            )}
                          </div>
                          {/* <div className="col-md-6">
                            <Controller
                              control={control}
                              name="openingDate"
                              render={({ field: { onChange, value } }) => (
                                <>
                                  <InputLabel className="form-label">
                                    Opening date
                                  </InputLabel>
                                  <DesktopDatePicker
                                    inputFormat="MM/dd/yyyy"
                                    value={value ? value : null}
                                    renderInput={(params) => (
                                      <TextField {...params} />
                                    )}
                                    onChange={(e) => onChange(convertDate(e))}
                                  />
                                  {errors && errors?.["openingDate"] && (
                                    <p className="errorMessage text-danger">
                                      {errors?.openingDate?.message}
                                    </p>
                                  )}
                                </>
                              )}
                            />
                          </div> */}
                          <div className="col-md-12 mb-3">
                            <Controller
                              control={control}
                              name="regStartDate"
                              render={({ field: { onChange, value } }) => (
                                <>
                                  <InputLabel className="form-label">
                                    Registration Start Date
                                    {/* <sup className="text-danger">*</sup> */}
                                  </InputLabel>
                                  {/* <DesktopDatePicker
                                    inputFormat="MM/dd/yyyy"
                                    value={value ? value : null}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        className="date-field"
                                      />
                                    )}
                                    onChange={(e) => onChange(convertDate(e))}
                                  /> */}
                                  <DateTimePicker
                                    renderInput={(props) => <TextField {...props} className="date-field" />}
                                    value={value ? value : null}
                                    onChange={(e) => onChange(convertDate(e))}
                                  />
                                  {errors && errors?.["regStartDate"] && (
                                    <p className="errorMessage text-danger">
                                      {errors?.regStartDate?.message}
                                    </p>
                                  )}
                                </>
                              )}
                            />
                          </div>
                          <div className="col-md-12 mb-3">
                            <Controller
                              control={control}
                              name="lastDateToRegister"
                              render={({ field: { onChange, value } }) => (
                                <>
                                  <InputLabel className="form-label">
                                    Last date to register
                                    <sup className="text-danger">*</sup>
                                  </InputLabel>
                                  {/* <DesktopDatePicker
                                    inputFormat="MM/dd/yyyy"
                                    value={value ? value : null}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        className="date-field"
                                      />
                                    )}
                                    onChange={(e) => onChange(convertDate(e))}
                                  /> */}
                                  <DateTimePicker
                                    renderInput={(props) => <TextField {...props} className="date-field" />}
                                    value={value ? value : null}
                                    onChange={(e) => onChange(convertDate(e))}
                                  />
                                  {errors && errors?.["lastDateToRegister"] && (
                                    <p className="errorMessage text-danger">
                                      {errors?.lastDateToRegister?.message}
                                    </p>
                                  )}
                                </>
                              )}
                            />
                          </div>
                          <div className="col-md-12">
                            <Controller
                              control={control}
                              name="lastDateToCancel"
                              render={({ field: { onChange, value } }) => (
                                <>
                                  <InputLabel className="form-label">
                                    Last date to Cancel
                                  </InputLabel>
                                  <DesktopDatePicker
                                    inputFormat="MM/dd/yyyy"
                                    value={value ? value : null}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        className="date-field"
                                      />
                                    )}
                                    onChange={(e) => onChange(e ? convertDate(e,true) : "")}
                                  />
                                  {errors && errors?.["lastDateToCancel"] && (
                                    <p className="errorMessage text-danger">
                                      {errors?.lastDateToCancel?.message}
                                    </p>
                                  )}
                                </>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card mt-4">
                      <div>
                        <div className="card-title">
                          <img src="../../assets/images/Website.svg" /> Tags
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-12 mb-3 ps-4">
                            {tagArray.map((item) => {
                              return (
                                <div className="chechbox-label">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        defaultChecked={getTagValue(item.name)}
                                        {...register(`eventTags.${item.name}`)}
                                        // icon={<RadioButtonUncheckedIcon />}
                                        // checkedIcon={<CheckCircleIcon />}
                                      />
                                    }
                                    label={item.label}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card mt-4">
                      <div className="card-title">
                        <img src="../../assets/images/edit2.svg" /> Coupon Code
                      </div>
                      <div className="card-body">
                        {fields.map((item, index) => (
                          <div className="coupon-row-outer">
                            <a
                              className="coupon-delete"
                              href="javascript:;"
                              onClick={() => remove(index)}
                            >
                              <img src="../../assets/images/trash.svg"></img>
                            </a>
                            <div className="row">
                              <div className="col-md-6 mb-3">
                                <InputLabel className="form-label">
                                  Coupon Code
                                </InputLabel>
                                <TextField
                                  style={{ width: "100%" }}
                                  variant="outlined"
                                  {...register(
                                    `coupenCodes.${index}.coupenTitle`
                                  )}
                                  error={`errors.coupenCodes.${index}.coupenTitle`}
                                  // defaultValue={eventDetails?.seats}
                                />
                                {errors?.coupenCodes?.[index]?.coupenTitle && (
                                  <p className="errorMessage text-danger">
                                    {
                                      errors?.coupenCodes?.[index]?.coupenTitle
                                        ?.message
                                    }
                                  </p>
                                )}
                              </div>
                              <div className="col-md-6 mb-3">
                                <InputLabel className="form-label">
                                  Discount Percentage
                                </InputLabel>
                                <TextField
                                  className="persentage-sign"
                                  style={{ width: "100%" }}
                                  variant="outlined"
                                  {...register(`coupenCodes.${index}.perValue`)}
                                  error={`errors.coupenCodes.${index}.perValue`}
                                  // defaultValue={eventDetails?.bulkDiscount}
                                />
                                {errors?.coupenCodes?.[index]?.perValue && (
                                  <p className="errorMessage text-danger">
                                    {
                                      errors?.coupenCodes?.[index]?.perValue
                                        ?.message
                                    }
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                        <div className="col-sm-12">
                          <a
                            className="add-code-btn"
                            href="javascript:;"
                            onClick={() =>
                              append({ coupenTitle: null, perValue: null })
                            }
                          >
                            {fields.length > 0
                              ? "Add Code"
                              : "Create coupon code"}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </LocalizationProvider>
    </div>
  );
}
