import { useEffect, useState } from "react";
import {Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  InputLabel,
  TextField,
  Select,
  MenuItem,
  Avatar,
  InputAdornment,
  IconButton,
  OutlinedInput,
  CircularProgress,
  Autocomplete,
  Checkbox,
  Tooltip,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  useMutation,
  useLazyQuery,
  useReactiveVar,
  useQuery,
} from "@apollo/client";
import { toggleSnackbar, schoolDtls, registerEvent, regDomainVar } from "../../RactiveVariables/index";
import { GET_ALL_TOPICS, IMAGE_UPLOAD } from "../../Graphql/queries";
import { ADD_SCHOOL_USER, CREATE_ORDER, LOGIN_SCHOOL } from "../../Graphql/mutations";
import axios from "axios";
import { position } from "../Common/Options";
import { useHistory } from "react-router-dom";
import { CropImage } from "../Common";
import imageCompression from 'browser-image-compression';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export default function AddUser(props) {
  const { website } = props;
  const [schLogin] = useMutation(LOGIN_SCHOOL)
  const schoolDetails = useReactiveVar(schoolDtls);
  const domainVar = useReactiveVar(regDomainVar);
  const history = useHistory();
  const regEvent = useReactiveVar(registerEvent);
  const [phone, setPhone] = useState(null);
  const [positions, setPositions] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUploadLoading, setImageUploadLoading] = useState(false);
  const [groupId, setGroupId] = useState(null);
  const [cropImage, setCropImage] = useState(null);
  const [cropOpen, setCropOpen] = useState(false);

  const [createOrders] = useMutation(CREATE_ORDER);
  const uplaodImageVariables = {
    originalFilename: selectedImage?.fileName,
    path: "/userprofile",
  };
  const [upload, { data: uploadImageData }] = useLazyQuery(IMAGE_UPLOAD, {
    fetchPolicy: "network-only",
    variables: uplaodImageVariables,
  });

  const getUserVariables = {
    skip: 0,
    limit: 500,
    isArchived: false,
  };
  const  { data: topicLists ,loading:topicLoading} = useQuery(GET_ALL_TOPICS, {
    fetchPolicy: "network-only",
    variables: getUserVariables,
  //   onCompleted:res=>{
  //     var arr=[]
  //     props?.selectedItem?.massTopicsIds?.forEach(element1 => {
  //       let position = res?.listTopics?.data?.findIndex((element) => element._id === element1);
  //       if(position > -1) {
  //         arr.push(res?.listTopics?.data?.[position])
  //       }
  //     });
  //     setValue("massTopicsIds",arr);
  // }
  });


  const [addUser, { loading }] = useMutation(ADD_SCHOOL_USER);
  const schema = yup.object().shape({
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required("Last name is required"),
    title: yup.string().required("Title is required"),
    email: yup.string().required("Email is required").email()
    .test("email", "Your email should match with your school domain. Please enter your school email.", (value) => {
      if (value.split("@")[1] === website || !props?.schoolId) return true;
      else return false;
    })
    ,
    phone: yup
      .string()
      .required("Phone Number is required")
      .test("phone", "Must be a number", (value) => {
        if (value && isNaN(value.replace(/[-,(,), ]+/g, ""))) return false;
        else return true;
      })
      .test("phone", "Must be a valid number", (value) => {
        if (value && value.length < 8) return false;
        else return true;
      }),
    pronoun: yup.string().required("Please enter Pronoun"),
    position: !props?.nonMember
      ? yup.string().required("Please select Position")
      : null,
    positionOther: yup.string().when("position", {
      is: (position) => position == "Other",
      then: yup.string().required("Please Enter Other"),
    }),
    // password: yup.string().required("Password is required").matches(
    //   /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/,
    //   "Password must contain atleast 8 characters, including UPPER/lower case, numbers and special characters."
    // ),
    // confirmPassword: yup.string().required("Confirm Password is required").oneOf(
    //   [yup.ref("password"), null],
    //   "Password and Confirm Password do not match"
    // ),
  });
  const { register, handleSubmit, formState, reset, getValue, control } = useForm({
    resolver: yupResolver(schema),
    defaultValues:{email:domainVar?.email ? domainVar?.email : ""}
  });
  const phoneNumberFormat = (num) => {
    let newNum = num.replace(/[-,(,), ]+/g, "");
    let x;
    if (newNum.length <= 3) {
      x = newNum;
    } else if (newNum.length > 3 && newNum.length <= 6) {
      x = "(" + newNum.slice(0, 3) + ") " + newNum.slice(3, 6);
    } else {
      x =
        "(" +
        newNum.slice(0, 3) +
        ") " +
        newNum.slice(3, 6) +
        "-" +
        newNum.slice(6, 10);
    }
    return x;
  };
  const { errors } = formState;

  async function uploadImage(params) {
    setImageUploadLoading(true);
    if (selectedImage) {
      let url = uploadImageData?.imageUpload?.signedUrl;
      const response = await axios.put(url, selectedImage.file, {
        headers: {
          "Content-Type": selectedImage?.file?.type,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "X-Requested-With",
        },
      });
    }
    setImageUploadLoading(false);
    onSubmit(params);
  }
  
  function sortLabels(a, b) {
    var nameA = a.toUpperCase();
    var nameB = b.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  }

  const getTopicId = (array)=>{
    if(array){
      let id = []
      array.forEach(element => {
        id.push(element._id);
      });
      return id
    }
  }

  const onSubmit = (params) => {
    if(params.massTopicsIds){
      params['massTopicsIds']= getTopicId(params.massTopicsIds)
      }
    if(params?.confirmPassword)
      delete params.confirmPassword;
    if (params?.gpModules) {
      let moduleAcs = [];
      Object.keys(params?.gpModules).map((key) => {
        moduleAcs.push({
          title: key,
          status: groupId === "Admin" ? true : params?.gpModules[key],
        });
      });
      params["moduleAcs"] = moduleAcs;
      delete params["gpModules"];
    }
    if (groupId === "Admin") {
      let moduleAcs = [];
      Checkboxlists.forEach((element) => {
        moduleAcs.push({ title: element.name, status: true });
      });
    }
    params["phone"] = params?.phone;
    if (selectedImage?.imageFile !== null)
      params["profileImage"] = uploadImageData?.imageUpload?.publicUrl;
    else params["profileImage"] = "";

    if (props?.schoolId) params["schoolId"] = props?.schoolId;
    else params["schUserType"] = "Non-Member";
    if(regEvent?.eventId){
      params["regEventId"] = regEvent?.eventId
      // params["status"] = false;
    }
    // if(!props?.schoolId)
    //   params.noMailFlg = true;
    try {
      addUser({ variables: { data: params } })
        .then((res) => {
          toggleSnackbar({
            status: true,
            // message: "User added successfully",
            message: "An email has been sent to your email address containing an activation link. Please click on the link to activate your account.",
            variant: "success",
          });
          history.push("/schoollogin")
          // -------------------------login----------------------------------
          
          // try {
          //   schLogin({
          //     variables:{
          //       email:params.email,
          //       password:params.password
          //     }
          //   })
          //     .then((res) => {
          //       if(regEvent?.eventId){
          //         let createEventData = {
          //           "orderCreatedFrom" : "FrontEnd",
          //           "eventId" : regEvent?.eventId,
          //           "memberPrice":parseInt(regEvent?.memberPrice),
          //           "nonMemberPrice":parseInt(regEvent?.nonMemberPrice),
          //           "totalPrice" : regEvent?.schoolId ? 0 : parseInt(regEvent?.nonMemberPrice),
          //           "totalNonMemCount" : regEvent?.schoolId ? 0 : 1,
          //           "paymentMethod" : "Card",
          //           "orderType": "Normal",
          //           "orderCreatedFor":res?.data?.loginSch?.userNew?._id,
          //           "schoolId":regEvent?.schoolId,
          //           "orderAsGuest":regEvent?.schoolId ? false : true 
          //         }
          //         createOrders({ variables: {data:createEventData} })
          //         .then((orderRes) => {
          //           toggleSnackbar({
          //             status: true,
          //             message: "Order has been saved as draft",
          //             variant: "success",
          //           });
          //           history.push(`/eventregistration/${orderRes?.data?.createOrders?._id}`)
          //         })
          //           const responseData = res.data && res.data.loginSch;
          //           const token = responseData.token;
          //           localStorage.setItem("@authToken", token);
          //           userDeatils({ data: res?.data?.loginSch?.userNew });
          //           schoolDtls({
          //             data: res?.data?.loginSch?.userNew?.schoolDetails,
          //             schoolId: res?.data?.loginSch?.userNew?.schoolId,
          //           });
          //           authVar({
          //             loaded: true,
          //             auth: true,
          //           });                  
          //       }
          //       else{
          //         const responseData = res.data && res.data.loginSch;
          //           const token = responseData.token;
          //           localStorage.setItem("@authToken", token);
          //           userDeatils({ data: res?.data?.loginSch?.userNew });
          //           schoolDtls({
          //             data: res?.data?.loginSch?.userNew?.schoolDetails,
          //             schoolId: res?.data?.loginSch?.userNew?.schoolId,
          //           });
          //           authVar({
          //             loaded: true,
          //             auth: true,
          //           });
          //           if(regEvent?.schoolId)
          //             history.push("/schoolprofile")
          //           else
          //           history.push("/orderlisting")
          //       }
          //     })
          //     .catch((err) => {
          //       const message = err && err.message;
          //       toggleSnackbar({
          //         status: true,
          //         message: message,
          //         variant: "error",
          //       });
          //     });
          // } catch (err) {
          //   const message = err && err.message;
          //   toggleSnackbar({
          //     status: true,
          //     message: message,
          //     variant: "error",
          //   });
          // }
          // -------------------------login----------------------------------
        })
        .catch((error) => {
          const message = error && error.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (error) {
      const message = error && error.message;
      toggleSnackbar({
        status: true,
        message: message + "Please check your mail for activation mail",
        variant: "error",
      });
    }
  };

  function handleUploadClick(event) {
    let obj = {};
    var file = event;
    obj["fileName"] = file.name;
    obj["url"] = URL.createObjectURL(file);
    obj["file"] = file;
    setSelectedImage(obj);
    upload();
    
    
  }
  const phoneNumberRegister = register("phone", { required: true });
  const Checkboxlists = [
    { label: "School Profile", name: "SchoolProfile" },
    { label: "Events", name: "Events" },
    { label: "Careers", name: "Careers" },
    { label: "Users", name: "Users" },
  ];
  
  function handelCropDone(result) {
    setCropImage(null);
    handleUploadClick(result?.file, result?.fileUrl);
  }
  function checkImage(file){
    if (
      !(
        file?.type == "image/jpeg" ||
        file?.type == "image/png" ||
        file?.type == "image/jpg"
      )
    ) {
      toggleSnackbar({
        status: true,
        message: "File does not support. You must use .png or .jpeg",
        variant: "error",
      });
      return false;
    }
    else if (file.size > 5242880) {
      toggleSnackbar({
        status: true,
        message: "Please upload a file smaller than 5 MB",
        variant: "error",
      });
      return false;
    }
    else{
      var options = {
        maxSizeMB: 1,
        useWebWorker: true,
      };
      imageCompression(file, options)
      .then((compressedFile) => {
        setCropImage(compressedFile);
        setCropOpen(true);
      })
      .catch((err) => {
        //console.log("err: ",err)
      })
    }
  }
  return (
    <div>
      <CropImage
        open={cropOpen}
        setOpen={setCropOpen}
        image={cropImage}
        handelCropDone={handelCropDone}
        aspectRatio={1}
      />
      <input
        id="add-user-input"
        type="file"
        hidden
        // onChange={handleUploadClick}
        onChange={(e)=>{
          checkImage(e.target.files[0])
        }}
        onClick={(event) => {
          event.target.value = null;
        }}
      />
      <div>
        <div className="sidebar-toggler reg" role="presentation">
          <div className="userform-scroller not">
            {!props.nonMember && (
              <div className="d-flex justify-content-center user-outer">
                <div className="avatar-outer">
                  <Avatar
                    src={
                      selectedImage
                        ? selectedImage.url
                        : props?.selectedItem?.profileImage
                        ? props?.selectedItem?.profileImage
                        : "/broken-image.jpg"
                    }
                    onClick={(e) =>
                      document.getElementById("add-user-input").click()
                    }
                    sx={{ width: 110, height: 110 }}
                  />
                  <span
                    onClick={() =>
                      document.getElementById("add-user-input").click()
                    }
                  >
                    +
                  </span>
                </div>
              </div>
            )}
            <form
              className="adduser-form"
              onSubmit={handleSubmit(uploadImage)}
              noValidate
            >
              <div className="row">
                <div className="col-md-6">
                  <InputLabel className="form-label">
                    First name<sup className="text-danger">*</sup>
                  </InputLabel>
                  <TextField
                    className="m-10"
                    variant="outlined"
                    {...register("firstName")}
                    error={errors.firstName}
                    autoFocus
                    defaultValue={props?.selectedItem?.firstName}
                  />
                  {errors && errors?.["firstName"] && (
                    <p className="errorMessage text-danger">
                      {errors?.firstName?.message}
                    </p>
                  )}
                </div>
                <div className="col-md-6">
                  <InputLabel className="form-label">
                    Last name<sup className="text-danger">*</sup>
                  </InputLabel>
                  <TextField
                    variant="outlined"
                    {...register("lastName")}
                    error={errors.lastName}
                    defaultValue={props?.selectedItem?.lastName}
                  />
                  {errors && errors?.["lastName"] && (
                    <p className="errorMessage text-danger">
                      {errors?.lastName?.message}
                    </p>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <InputLabel className="form-label">
                    Title<sup className="text-danger">*</sup>
                  </InputLabel>
                  <TextField
                    {...register("title")}
                    variant="outlined"
                    error={errors.title}
                    defaultValue={props?.selectedItem?.title}
                  />
                  {/* {errors && errors?.["title"] && (
                    <p className="errorMessage text-danger">
                      {errors?.title?.message}
                    </p>
                  )} */}
                  {/* <Select
                    variant="outlined"
                    {...register("title")}
                    error={errors.title}
                    defaultValue={props?.selectedItem?.title}
                  >
                    {sclUserType?.map((item) => (
                        <MenuItem value={item}>{item}</MenuItem>
                      ))}
                  </Select> */}
                  {errors && errors?.["title"] && (
                    <p className="errorMessage text-danger">
                      {errors?.title?.message}
                    </p>
                  )}
                </div>
                
                {!props.nonMember && (
                  <div
                    className={
                      positions === "Other" ? "col-md-6 mt-3" : "col-md-6 mt-3"
                    }
                  >
                    <InputLabel className="form-label">
                      Position<sup className="text-danger">*</sup>
                    </InputLabel>
                    <Select
                      variant="outlined"
                      {...register("position")}
                      error={errors.position}
                      defaultValue={props?.selectedItem?.position}
                      onChange={(e) => {
                        register("position").onChange({
                          target: { value: e.target.value, name: "position" },
                        });
                        setPositions(e.target.value);
                      }}
                    >
                      {position?.sort(sortLabels).map((item) => (
                        <MenuItem value={item}>{item}</MenuItem>
                      ))}
                    </Select>
                    {errors && errors?.["position"] && (
                      <p className="errorMessage text-danger">
                        {errors?.position?.message}
                      </p>
                    )}
                  </div>
                )}
                {positions === "Other" && (
                  <div className="col-md-6 mt-3">
                    <InputLabel className="form-label">
                      Other<sup className="text-danger">*</sup>
                    </InputLabel>
                    <TextField
                      {...register("positionOther")}
                      variant="outlined"
                      error={errors.positionOther}
                      defaultValue={props?.selectedItem?.positionOther}
                    />
                  </div>
                )}
                {errors && errors?.["positionOther"] && (
                  <p className="errorMessage text-danger">
                    {errors?.positionOther?.message}
                  </p>
                )}

                <div className="col-md-12 mt-3 topics-tags">
                  <InputLabel className="form-label">
                    Topic Notifications
                  </InputLabel>
                  <Controller
                  name="massTopicsIds"
                  control={control}
                  // defaultValue={newArray}
                  render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    multiple
                    id="checkboxes-tags-demo"
                    options={topicLists?.listTopics?.data||[]}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.topic}
                    value={value||[]}
                    onChange={(event, value) => {onChange(value)}}
                    // loading={topicLoading}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.topic}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField {...params}
                      // InputProps={{
                      //   ...params.InputProps,
                      //   endAdornment: (
                      //     <Fragment>
                      //       {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      //       {params.InputProps.endAdornment}
                      //     </Fragment>
                      //   ),
                      // }}
                      />
                    )}
                  />
                  )}
                    />
                  {/* {errors && errors?.["massTopicsIds"] && (
                    <p className="errorMessage text-danger">
                      {errors?.massTopicsIds?.message}
                    </p>)} */}
                </div>
                <div className="col-md-6 mt-3">
                  <InputLabel className="form-label">
                    Phone<sup className="text-danger">*</sup>
                  </InputLabel>
                  <TextField
                    {...register("phone")}
                    variant="outlined"
                    error={errors.phone}
                    value={phone}
                    defaultValue={props?.selectedItem?.phone}
                    onChange={(e) => {
                      phoneNumberRegister.onChange(e);
                      setPhone(phoneNumberFormat(e.target.value));
                    }}
                  />
                  {errors && errors?.["phone"] && (
                    <p className="errorMessage text-danger">
                      {errors?.phone?.message}
                    </p>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <InputLabel className="form-label">
                    Email<sup className="text-danger">*</sup>
                  </InputLabel>
                  <TextField
                    {...register("email")}
                    variant="outlined"
                    error={errors.email}
                    // disabled={domainVar?.email}
                    defaultValue={props?.selectedItem?.email?props?.selectedItem?.email : domainVar?.email ? domainVar?.email :null}
                  />
                  {errors && errors?.["email"] && (
                    <p className="errorMessage text-danger">
                      {errors?.email?.message}
                    </p>
                  )}
                </div>
                {!props.nonMember && (
                  <div className="col-md-12 mt-3">
                    <InputLabel className="form-label">Bio</InputLabel>
                    <div className="textarea-outer">
                      <TextField
                        {...register("bio")}
                        variant="outlined"
                        multiline
                        rows={8}
                        // error={errors.bio}
                        defaultValue={props?.selectedItem?.bio}
                      />
                      {/* {errors && errors?.["bio"] && (
                      <p className="text-danger">{errors?.bio?.message}</p>
                    )} */}
                    </div>
                  </div>
                )}
                <div
                  className={
                    !props.nonMember ? "col-md-12 mt-3" : "col-md-6 mt-3"
                  }
                >
                  <InputLabel className="form-label">
                    Pronoun<sup className="text-danger">*</sup><span><Tooltip title="If this profile is for you, please add your preferred pronouns. Eg/ she/her/hers.
                      If this profile is for someone else, please leave the default text unless you know their preferred pronouns." 
                    placement="right-start">
                      <InfoIcon
                              style={{ width: "17px", color: "#0d6efd" }}
                      />
                    </Tooltip>
                    </span>
                  </InputLabel>
                  {/* <Select
                    variant="outlined"
                    {...register("pronoun")}
                    error={errors.pronoun}
                    defaultValue={props?.selectedItem?.pronoun}
                  >
                    {Pronoun?.map((item) => (
                        <MenuItem value={item}>{item}</MenuItem>
                      ))}
                  </Select>
                  {errors && errors?.["pronoun"] && (
                    <p className="errorMessage text-danger">
                      {errors?.pronoun?.message}
                    </p>
                  )} */}
                  <TextField
                    {...register("pronoun")}
                    variant="outlined"
                    error={errors.pronoun}
                    defaultValue={props?.selectedItem?.pronoun??"To be updated by user"}
                    placeholder="To be updated by user"
                  />
                  {errors && errors?.["pronoun"] && (
                    <p className="errorMessage text-danger">
                      {errors?.pronoun?.message}
                    </p>
                  )}
                  <button id="addmember-submit-btn" className="d-none" type="submit">submit</button>
                </div>
                {/* <div
                  className={"col-md-6 mt-3"}
                >
                  <InputLabel className="form-label">
                    Password<sup className="text-danger">*</sup>
                  </InputLabel>
                  <OutlinedInput
                    type={showPassword ? "text" : "password"}
                    {...register("password")}
                    variant="outlined"
                    error={errors.password}
                    endAdornment={
                      <InputAdornment className="pe-2" position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={()=>setShowPassword(!showPassword)}
                          onMouseDown={(e)=>e.preventDefault()}
                          edge="end"
                        >
                          {showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {errors && errors?.["password"] && (
                    <p className="errorMessage text-danger">
                      {errors?.password?.message}
                    </p>
                  )}
                </div>
                <div
                  className={"col-md-6 mt-3"}
                >
                  <InputLabel className="form-label">
                    Confirm Password<sup className="text-danger">*</sup>
                  </InputLabel>
                  <OutlinedInput
                    type={showCPassword ? "text" : "password"}
                    {...register("confirmPassword")}
                    variant="outlined"
                    error={errors.confirmPassword}
                    endAdornment={
                      <InputAdornment className="pe-2" position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={()=>setShowCPassword(!showCPassword)}
                          onMouseDown={(e)=>e.preventDefault()}
                          edge="end"
                        >
                          {showCPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {errors && errors?.["confirmPassword"] && (
                    <p className="errorMessage text-danger">
                      {errors?.confirmPassword?.message}
                    </p>
                  )}
                </div> */}
                { props?.EventRegistration2 &&
                  <div className="col-sm-12 mt-3 d-flex justify-content-end">
                   {/* <button type="submit" className="btn btn-orange ms-auto" onClick={()=>document.getElementById("addmember-submit-btn").click()}>save</button> */}
                   <button id="addmember-submit-btn" className="btn btn-orange ms-auto" type="submit">
                   {(loading || imageUploadLoading) ? (
                      <CircularProgress size="20px" color="inherit" />
                    ) : "save"}
                     </button>
                </div>}
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
