import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import { useParams } from "react-router-dom";
import { ConfirmDialog, Loader } from "../Common";
import { useEffect, useState } from "react";
import { authVar, toggleSnackbar } from "../../RactiveVariables";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_CURRENT_USER } from "../../Graphql/queries";
import { DELETE_MY_ACCOUNT } from "../../Graphql/mutations";
import { useHistory } from "react-router-dom";

function DeleteAccount(params) {
  const { token } = useParams();
  const history = useHistory();
  const [closeDialog, setCloseDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const [userData, setUserData] = useState("");
  const [CurrentUserDetails, { currentUser }] = useLazyQuery(GET_CURRENT_USER, {
    fetchPolicy: "network-only",
    onCompleted: (res) => {
      setUserData(res.currentUserDtls);

      setLoading(false);
    },
    onError: (err) => {
      setLoading(false);
      setIsTokenExpired(true);
      authVar({
        loaded: true,
        auth: false,
      });
    },
  });
  const [deleteAccount, { loading: accountDeleteLoading }] = useMutation(
    DELETE_MY_ACCOUNT,
    {
      onCompleted: (res) => {
        setCloseDialog(false);
        localStorage.clear();
        history.push("/completeDeletion");
        // setTimeout(() => {
        //   window.location.href = process.env.REACT_APP_WP_URL;
        // }, 1000);
      },
    }
  );
  useEffect(() => {
    setLoading(true);
    if (token) {
      localStorage.setItem("@authToken", token);
      authVar({
        loaded: true,
        auth: true,
      });
      CurrentUserDetails();
    }
  }, [token, CurrentUserDetails]);
  function closeConfirm() {
    setCloseDialog(false);
  }

  function handleConfirm() {
    const data = {
      deleteAccLink: token,
      email: userData?.email,
    };
    try {
      deleteAccount({ variables: data })
        .then((res) => {
          const message = res.data?.deleteMyAccount?.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "success",
          });
        })
        .catch((err) => {
          const message = err?.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (error) {
      const message = error?.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  }
  return (
    <div>
      <Loader open={loading} />
      <ConfirmDialog
        open={closeDialog}
        title={"Delete"}
        body={"Are you sure you want to delete your account ? "}
        closeText={"Cancel"}
        confirmText={"Delete"}
        closeConfirm={closeConfirm}
        handleConfirm={handleConfirm}
        loading={accountDeleteLoading}
      />
      <header
        className="fixed-top header loginheader"
        style={{ backgroundColor: "#fff" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="d-flex justify-content-between">
                <a href={process.env.REACT_APP_WP_URL}>
                  <img
                    className="top-logo"
                    src="../assets/images/logoblue.svg"
                    alt="logo"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div
        className="delete-account-wrap d-flex justify-content-center align-items-center"
        style={{ backgroundColor: "#FAFAFB" }}
      >
        <div className="delete-account-box">
          <h2>Delete Your Account</h2>
          <Avatar
            alt="Remy Sharp"
            src={userData?.profileImage ?? "/static/images/avatar/1.jpg"}
            sx={{ width: 140, height: 140, fontSize: 25 }}
          />
          {userData && (
            <div className="text-center">
              <h3>{`${userData?.firstName} ${userData?.lastName}`}</h3>
              <a>{userData?.email}</a>
            </div>
          )}
          <p className="mb-0">
            {isTokenExpired
              ? "This link has expired. To proceed with deleting your account, please resubmit your request."
              : "Requesting deletion of your account means you will no longer be able to use your NYSAIS account and your account will be permanently deleted."}
          </p>
          <hr />
          <div className="button-group">
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                isTokenExpired
                  ? history.push("/deleteAccount")
                  : (window.location.href = process.env.REACT_APP_WP_URL);
              }}
            >
              {isTokenExpired ? "Resubmit" : "Cancel"}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setCloseDialog(true)}
              disabled={isTokenExpired || accountDeleteLoading}
            >
              Delete Account
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteAccount;
