import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { FormControlLabel, Checkbox, InputLabel } from "@mui/material";
const tagArray = [
  {
    label: "Montessori",
    name: "Montessori",
  },
  {
    label: "Waldorf",
    name: "Waldorf",
  },
  {
    label: "Special Education",
    name: "Special Education",
  },
  {
    label: "Boarding",
    name: "Boarding",
  },
  {
    label: "Day",
    name: "Day",
  },
  {
    label: "International (Students)",
    name: "International (Students)",
  },
  {
    label: "International Baccalaureate (IB)",
    name: "International Baccalaureate (IB)",
  },
];

export default function Tags(props) {
  const { register, errors, defaultValue } = props;

  function getTagValue(name) {
    if (defaultValue?.schoolTags) {
      let position = defaultValue?.schoolTags.findIndex(
        (element) => element.title === name
      );
      if (position > -1) return defaultValue?.schoolTags[position]?.status;
    }
  }
  return (
    <div className="card mt-4">
      <div>
        <div className="card-title">
          <img src="../../assets/images/Website.svg" /> Tags
        </div>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-md-12 mb-3 ps-4">
            {tagArray.map((item) => {
              return (
                <div className="chechbox-label">
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={getTagValue(item.name)}
                        {...register(`schoolTags.${item.name}`)}
                        // icon={<RadioButtonUncheckedIcon />}
                        // checkedIcon={<CheckCircleIcon />}
                      />
                    }
                    label={item.label}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
