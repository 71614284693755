import {
  Button,
  Drawer,
  FormGroup,
  Avatar,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { useState } from "react";
import { userDeatils } from "../../../RactiveVariables";
import { useReactiveVar } from "@apollo/client";

export default function AddMemberPreview(props) {
  const [userId, setUserId] = useState(props?.userId ? props?.userId : []);
  const [searchValue, setSearchValue] = useState("");
  const { membersList } = props;
  const user = useReactiveVar(userDeatils);

  function handleId(id, checked) {
    let array = [...userId];
    if (checked) {
      array.push(id);
      setUserId(array);
    } else {
      let position = array.findIndex((element) => element === id);
      array.splice(position, 1);
      setUserId(array);
    }
  }
  function closeDrawer() {
    props.setOpen(false);
  }
  function openAddMember() {
    closeDrawer();
    props?.setMOpen(true);
  }
  function getpermission() {
    if(user.data?.loginType==="Admin" || user?.data?.userType==="Admin")
      return true;
    else {
      if(user?.data?.moduleAcs){
        let pos = user?.data?.moduleAcs.findIndex(e=>e.title==="Users" && e.status);
        if(pos>-1)
          return true;
      }
    }
  }
  return (
    <Drawer anchor={"right"} open={props.open} onClose={closeDrawer}>
      <div className="sidebar-toggler" role="presentation">
        <div className="title-outer">
          <h1>Select School Members</h1>
          <div onClick={closeDrawer} className="close-icon">
            X
          </div>
        </div>
        <div className="userform-scroller">
          <div className="d-flex justify-content-center user-outer"></div>
          <form
            className="adduser-form"
            // onSubmit={handleSubmit(uploadImage)}
            noValidate
          >
            <div className="row">
              <div className={getpermission() ? "col-md-9 col-8" : "col-md-12 col-12"}>
                <div className="d-flex align-items-center mem-search-outer">
                  <img
                    className="search-icon"
                    src="../assets/images/search.svg"
                    alt="search icon"
                  />
                  <input
                    className="searchbox form-control border-0"
                    placeholder="Search"
                    type="text"
                    value={searchValue}
                    onChange={(e)=>setSearchValue(e.target.value)}
                  />
                </div>
              </div>
              {getpermission() && <div className="col-md-3 col-4 d-flex justify-content-end">
                <Button
                  className="btn btn-orange h-100 text-white"
                  onClick={openAddMember}
                >
                  Add Member
                </Button>
              </div>}
              <div className="col-sm-12">
                <p className="note-text">
                  <b>Please note:</b> Members that have a blue checkmark next to
                  their name have already been registered for this event.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="members-list-outer">
                  {membersList?.listAllSchUsers?.data.filter(usr => (usr?.firstName.toLowerCase()+" "+usr?.lastName.toLowerCase()).includes(searchValue.toLowerCase())).map((item) => {
                    if(!item.status)
                      return false
                    return(
                    <div className="member">
                      <div className="d-flex align-items-center">
                        {
                          item?.profileImage ?  <img
                          className="profile-mem"
                          src={item?.profileImage}
                        ></img> : <Avatar className="profile-mem">{item?.firstName[0].toUpperCase()}</Avatar>
                        }
                       
                        <p className="name-mem">{`${item?.firstName} ${item?.lastName}`}</p>
                      </div>
                      <FormGroup className="module-checkbox">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={userId.includes(item?._id)}
                              onChange={(e, value) =>
                                handleId(item?._id, value)
                              }
                              icon={<RadioButtonUncheckedIcon />}
                              checkedIcon={<CheckCircleIcon />}
                            />
                          }
                          label=""
                        />
                      </FormGroup>
                    </div>
                  )})}
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-between align-items-center btn-section">
              <Button className="outline-btn" onClick={() => closeDrawer()}>
                Cancel
              </Button>
              <div className="d-flex align-items-center">
                <Button
                  className="highlight-btn"
                  onClick={() => props?.handlePreviewSave(userId)}
                >
                  Save
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Drawer>
  );
}
