import RegisterBox from "./FrontRegBox";
import { useState } from "react";
import AddMember from "./AddMember";
import EventHeader from "../Common/EventHeader";
import { useReactiveVar, useQuery } from "@apollo/client";
import { regDomainVar } from "../../RactiveVariables";
import { LIST_ALL_SCHOOL } from "../../Graphql/queries";
export default function Regsiter(props) {
  const domainVar = useReactiveVar(regDomainVar);
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [addUserOpen, setAddUserOpen] = useState(false);
  const { data: schoolList } = useQuery(LIST_ALL_SCHOOL,{
    variables:{
      allSch:true,
    }
  });

  function getWebsite(id) {
    let position = schoolList?.listSchool?.data.findIndex(
      (element) => element._id == id
    );
    if(position>-1){
      let socialArray = schoolList?.listSchool?.data[position]?.socialMediaWeb;
      let wPos = socialArray.findIndex((element)=>element.title==="Website")
      return socialArray[wPos].url
    }
  }

  return (
    <div>
      <EventHeader EventRegistration2 setAddUserOpen={setAddUserOpen} addUserOpen={addUserOpen} title={domainVar?.schName ? `Register with ${domainVar?.schName}`:"Register"} />
      <div className="register-outer">
        {!addUserOpen && (
          <RegisterBox
            schoolList={schoolList}
            selectedSchool={selectedSchool}
            setSelectedSchool={setSelectedSchool}
            setAddUserOpen={setAddUserOpen}
          />
        )}
        {addUserOpen && (
          <AddMember EventRegistration2
            schoolId={selectedSchool}
            setAddUserOpen={setAddUserOpen}
            website={getWebsite(selectedSchool)}
          />
        )}
      </div>
    </div>
  );
}
