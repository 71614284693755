import React, { useState } from "react";
import ENoticeHeader from "./eNoticeHeader";
import { useHistory, useParams,useLocation } from "react-router-dom";
import {
  InputLabel,
  TextField,
  Drawer,
  Button,
  FormControlLabel,
  CircularProgress,
  FormGroup,
  Checkbox,
  Autocomplete,
  Box,
  FormControl,
  Select,
  MenuItem,
  Chip,
  Stack,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import PreviewAndCompose from "./previewAndCompose"
import {useQuery,useMutation} from "@apollo/client";
import {CREATE_ENOTICE,UPDATE_ENOTICE} from "../../Graphql/mutations"
import {LIST_EVENTS,LIST_ENOTICELIST} from "../../Graphql/queries";
import SendMail from "./sendMail";
import Overview from "./overview";
import { toggleSnackbar } from "../../RactiveVariables/index";
import { from } from "@apollo/client";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export default function CreateENotice() {
  const location = useLocation();
  const clone = location?.state?.detail;
  const { id } = useParams();
  const history = useHistory();
  const [openDialog, setOpenDialog] = useState(false);
  const [overviewRes,setOverviewRes] = useState(null);
  const [checkedList, setCheckedList] = useState("Event");
  const [tabValue,setTabValue] = useState('overview');
  const [createRes,setCreateRes] = useState(null);
  const [schedule,setSchedule] = useState("")
  const [dateAndTime,setDateAndTime] = useState(new Date())
  const [create,setCreate] = useState(false)

  const {data:enoticedetais,loading} = useQuery(LIST_ENOTICELIST,{variables:{id:id==="$"?(overviewRes?.createENotice?._id||overviewRes?.updateENotice?._id):id},
onCompleted:(res)=>{
  setCheckedList(res?.eNoticeDetails?.eNoticeType);
}})
  const [updateENotice] = useMutation(UPDATE_ENOTICE,{ refetchQueries:['listAllENotice'],
  onCompleted:res =>{
    setOpenDialog(false)
    history.push("/enotice")
  }})
  const getEventVariables = {
    skip: 0,
    limit: 400,
    search: "",
    category: "",
    eventType: "",
    startDate: "",
    sortOrder: "-1",
    belgsToArg: "Nysais",
    sortField: "createdDate",
    src:'Enotice'
  };

  const date = new Date();
  const {data}= useQuery(LIST_EVENTS,{variables:getEventVariables})
  const handleSendENotice = ()=>{
    let data= {
      massTopicsIds:overviewRes?.updateENotice?.massTopicsIds||enoticedetais?.eNoticeDetails?.massTopicsIds,
      toGpList:overviewRes?.updateENotice?.toGpList||enoticedetais?.eNoticeDetails?.toGpList,
      ccList:overviewRes?.updateENotice?.ccList||enoticedetais?.eNoticeDetails?.ccList,
      fromLabel:overviewRes?.updateENotice?.fromLabel||enoticedetais?.eNoticeDetails?.fromLabel,
      from:overviewRes?.updateENotice?.from||enoticedetais?.eNoticeDetails?.from,
      replyTo:overviewRes?.updateENotice?.replyTo||enoticedetais?.eNoticeDetails?.replyTo,
      eventId:overviewRes?.updateENotice?.eventId||enoticedetais?.eNoticeDetails?.eventId||"",
      eNoticeType:overviewRes?.updateENotice?.eNoticeType||enoticedetais?.eNoticeDetails?.eNoticeType,
      subject:overviewRes?.updateENotice?.subject||enoticedetais?.eNoticeDetails?.subject,
      content:overviewRes?.updateENotice?.content||enoticedetais?.eNoticeDetails?.content,
      sentDate:new Date(date).toUTCString(),
      scheduledDate:new Date(dateAndTime).toUTCString(),
      status:'Schedule',
    }
    try {
      updateENotice({variables:{_id:createRes?.createENotice?._id?createRes?.createENotice?._id:(enoticedetais?.eNoticeDetails?._id||overviewRes?.createENotice?._id||overviewRes?.updateENotice?._id),data:data}})
         .then((res) => {
          const successMessage = schedule? "E-Notice Scheduled successfully":"E-Notice Send successfully"
              toggleSnackbar({
             status: true,
             message: successMessage,
             variant: "success",
           });
         })
         .catch((error) => {
           const message = error && error.message;
           toggleSnackbar({
             status: true,
             message: message,
             variant: "error",
           });
         });
     } catch (error) {
       const message = error && error.message;
       toggleSnackbar({
         status: true,
         message: message,
         variant: "error",
       });
     }
   
  }
  const eventlist =data?.listAllEvents
  return (
    <div className="fullwidth-body">
      <ENoticeHeader tabValue={tabValue}
      handleSendENotice={handleSendENotice}
      setTabValue={setTabValue} 
      backBtn backUrl={"/enotice"}
      enoticedetais={enoticedetais} 
      overviewRes={overviewRes} 
      openDialog ={openDialog}
      createRes={createRes}
      setOpenDialog = {setOpenDialog}/>
      <div className="event-outer pt-0">
        <div className="enotice">
        <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <ul>
              <li className={tabValue==="overview"? "active": ""} style={{cursor:"pointer"}} onClick={()=>setTabValue('overview')}>Overview</li>
              <li className={tabValue==="Preview & Compose"? "active": ""} style={{cursor:"pointer"}} onClick={()=>clone?createRes?.createENotice?._id&&setTabValue('Preview & Compose'):
              (overviewRes?.createENotice?._id||enoticedetais?.eNoticeDetails?._id)&&setTabValue('Preview & Compose')}>Preview & Compose</li>
              <li className={tabValue==="Schedule/Send"? "active": ""} style={{cursor:"pointer"}} onClick={()=>clone?createRes?.createENotice?._id&&setTabValue('Schedule/Send'):
              (overviewRes?.createENotice?._id||enoticedetais?.eNoticeDetails?._id)&&setTabValue('Schedule/Send')}>Schedule/Send</li>
          </ul>
          </div>
        </div>
      </div>
        </div>
        {tabValue==="Preview & Compose"&&<div className="prev-compose">
            <PreviewAndCompose 
            enoticedetais={enoticedetais} 
            overviewRes={overviewRes} 
            eventlist={eventlist}
            setOverviewRes={setOverviewRes}
            setTabValue={setTabValue}
            setCreateRes={setCreateRes}
            tabValue={tabValue}
            />
        </div>}
        {tabValue==="Schedule/Send"&&<div>
            <SendMail 
            enoticedetais={enoticedetais} 
            overviewRes={overviewRes} 
            eventlist={eventlist}
            tabValue={tabValue} 
            setTabValue={setTabValue}
            handleSendENotice={handleSendENotice}
            openDialog ={openDialog}
            setOpenDialog = {setOpenDialog}
            setSchedule={setSchedule}
            schedule={schedule}
            setDateAndTime={setDateAndTime}
            dateAndTime={dateAndTime}
            />
            </div>}
        {tabValue==="overview"&& !loading && <div className="prev-compose">
        <Overview 
        tabValue={tabValue} 
        setTabValue={setTabValue}
        eventlist={eventlist} 
        checkedList={checkedList}
        setCheckedList={setCheckedList}
        setOverviewRes={setOverviewRes}
        enoticedetais={enoticedetais}
        setCreateRes={setCreateRes}
        overviewRes={overviewRes} 
        setCreate={setCreate}
        create = {create}
        />
        </div>}
      </div>
    </div>
  );
}
