import { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useMutation } from "@apollo/client";
import { RESET_PASSWORD, RESET_SCH_PASSWORD } from "../../Graphql/mutations";
import {
  authVar,
  userDeatils,
  toggleSnackbar,
} from "../../RactiveVariables/index";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useParams, useHistory } from "react-router";

const ResetPassword = (props) => {
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);
  const { id } = useParams();
  const schema = yup.object().shape({
    newPassword: yup
      .string()
      .required("New Password is required")
      .matches(
        /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/,
        "Password must be 8 to 20 characters long and should contain at least one uppercase(A-Z), one lowercase(a-z), one number and one special character(!,@,#,$,%,^,&,*)."
      ),
    confirmNewPassword: yup
      .string()
      .required("Confirm Password is required")
      .oneOf(
        [yup.ref("newPassword"), null],
        "New Password and Confirm Password do not match"
      ),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  const [resetPass, { loading }] = useMutation(RESET_PASSWORD);
  const [resetSchPass, { loading : schLoading }] = useMutation(RESET_SCH_PASSWORD);

  const onSubmit = (data) => {
    if (props.location.pathname.includes("/setuserpassword")) {
      try {
        resetSchPass({
          variables: { resetLink: id, newPassword: data.newPassword },
        })
          .then((res) => {
            const message = res?.data?.resetSchPassword?.message;
            toggleSnackbar({
              status: true,
              message: message,
              variant: "success",
            });
            history.push("/schoollogin");
          })
          .catch((err) => {
            const message = err && err.message;
            toggleSnackbar({
              status: true,
              message: message==="jwt expired" ? "Token Expired. Please try again using forgot password" : message,
              variant: "error",
            });
          });
      } catch (err) {
        const message = err && err.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      }
    } else {
      try {
        resetPass({
          variables: { resetLink: id, newPassword: data.newPassword },
        })
          .then((res) => {
            const message = res?.data?.resetPassword?.message;
            toggleSnackbar({
              status: true,
              message: message,
              variant: "success",
            });
            history.push("/login");
          })
          .catch((err) => {
            const message = err && err.message;
            toggleSnackbar({
              status: true,
              message: message,
              variant: "error",
            });
          });
      } catch (err) {
        const message = err && err.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      }
    }
  };

  const handleClickShowPassword = (type) => {
    if (type === "confirm") setShowCPassword(!showCPassword);
    else setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className="login-outer">
      <div className="login" style={{ overflowY: "hidden", height: "auto" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 d-flex align-items-center justify-content-center">
              <div className="logo-section">
                <img
                  src="../assets/images/login-logo.svg"
                  className="img-fluid login-logo"
                  alt="logo"
                />
                <p>
                  NYSAIS is an advocate of the independent schools of New York
                  State, speaking for their independence, well-being, and
                  reputation before the agencies of government, the media, and
                  the public
                </p>
              </div>
            </div>
            <div className="col-lg-6 d-flex justify-content-center">
              <div className="card login-card">
                <div className="card-body">
                  <div className="login-forms">
                    <div className="main-title">
                      {/* {props.location.pathname.includes("/setpassword") ||
                      props.location.pathname.includes("/setuserpassword") ? (
                        <h3>Reset password</h3>
                      ) : (
                        <h3>Reset password</h3>
                      )} */}
                      <h3>Reset password</h3>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)} noValidate>
                      <div className="mt-4">
                        <label
                          className="form-label"
                          for="standard-adornment-password"
                        >
                          Password <sup className="text-danger">*</sup>
                        </label>
                        <OutlinedInput
                          className="form-control"
                          autoFocus
                          type={showPassword ? "text" : "password"}
                          {...register("newPassword")}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        {errors.newPassword && (
                          <span className="text-danger">
                            {errors?.newPassword?.message}
                          </span>
                        )}
                      </div>
                      <div className="mt-4">
                        <label
                          className="form-label"
                          for="standard-adornment-password"
                        >
                          Confirm Password <sup className="text-danger">*</sup>
                        </label>
                        <OutlinedInput
                          className="form-control"
                          type={showCPassword ? "text" : "password"}
                          {...register("confirmNewPassword")}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() =>
                                  handleClickShowPassword("confirm")
                                }
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showCPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        {errors.confirmNewPassword && (
                          <span className="text-danger">
                            {errors?.confirmNewPassword?.message}
                          </span>
                        )}
                      </div>
                      <div className="text-center">
                        <Button
                          className="btn btn-primary mt-4 login-btn"
                          disabled={loading}
                          type="submit"
                          color="primary"
                        >
                          {loading || schLoading ? (
                            <CircularProgress color="secondary" />
                          ) : props.location.pathname.includes(
                              "/setpassword"
                            ) ||
                            props.location.pathname.includes(
                              "/setuserpassword"
                            ) ? (
                            "RESET"
                          ) : (
                            "RESET"
                          )}
                        </Button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
