import { IMAGE_UPLOAD } from "../../../Graphql/queries";
import { useLazyQuery } from "@apollo/client";
import DeleteIcon from "@mui/icons-material/Delete";
import imageCompression from 'browser-image-compression';
export default function LogoUpload(props) {
  const [upload, { data: uploadImageData }] = useLazyQuery(IMAGE_UPLOAD, {
    fetchPolicy: "network-only",
    onCompleted: (res) => {
      let array = [...props.logoImage];
      let position = array.findIndex(
        (element) => element.fileName === res?.imageUpload?.filename
      );
      let obj = array[position];
      obj["publicUrl"] = res?.imageUpload?.publicUrl;
      obj["signedUrl"] = res?.imageUpload?.signedUrl;
      array[position] = obj;
      props?.setLogoImage(array);
    },
  });
  function handleImageUpload(file, src) {
    // let array = [...props.logoImage];
    let array = [];
    var options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 300,
      useWebWorker: true,
    };
    imageCompression(file, options)
    .then((compressedFile) => {
      upload({
        variables: { originalFilename: compressedFile?.name, path: "/school" },
      });
      array.push({
        fileName: compressedFile?.name,
        url: src,
        file: compressedFile,
      });
      props.setLogoImage(array);
    })
    .catch((err) => {
      //console.log("err: ",err)
    })
  }
  function handleRemove(index) {
    let array = [...props.logoImage];
    array.splice(index, 1);
    props.setLogoImage(array);
  }
  return (
    <div className="card mb-4 banner-image-outer">
      <input
        type="file"
        className="d-none"
        multiple
        id="school-banner-logoUpload"
        onChange={(e) => {
          handleImageUpload(
            e.target.files[0],
            window.URL.createObjectURL(e.target.files[0])
          );
        }}
        onClick={(event) => {
          event.target.value = null;
        }}
      />
      <div className="card-title justify-content-between">
        <div className="d-flex">
          <img src="../../assets/images/BannerIcon.svg" /> School Logo
        </div>
        <a
          className="addspeaker-btn"
          onClick={() =>
            document.getElementById("school-banner-logoUpload").click()
          }
          href="javscript:;"
        >
          <img src="../../assets/images/addimage.svg" />
          Add Logo
        </a>
      </div>
      <div className="banner-inner">
        {props.logoImage &&
          props.logoImage.map((item, index) => (
            <div>
              <div className="banner-delete-icon" >
                <DeleteIcon onClick={() => handleRemove(index)} />
              </div>
              <img className={"banner-image"} src={item.url} />
            </div>
          ))}
      </div>
    </div>
  );
}
