import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import {
  LIST_FOURMS_MESSAGE,
  IMAGE_UPLOAD,
  GET_FOURM_USER_DTLS,
} from "../../Graphql/queries";
import {
  useQuery,
  useMutation,
  useLazyQuery,
  useReactiveVar,
} from "@apollo/client";
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  CREATE_FORUM_MESSAGE,
  DELETE_FOURM_MESSAGES,
  getFourmUserDtls,
} from "../../Graphql/mutations";
import { toggleSnackbar,mailGroupTitle } from "../../RactiveVariables";
import { Avatar, Button } from "@mui/material";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";
import logo from "./Attachment.svg";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import { userDeatils,schoolDtls } from "../../RactiveVariables";
import { ConfirmDialog } from "../Common";
import { useHistory } from "react-router-dom";

export default function Conversations(props) {
  const history = useHistory();
  const [deleteId, setDeleteId]= useState(null);
  const user = useReactiveVar(userDeatils);
  const schoolDetails = useReactiveVar(schoolDtls);
  const [selectedFile, setSelectedFile] = useState([]);
  const [page, setPage] = useState(1);
  const [isManager, setIsManager] = useState(false);
  const [firstPost, setFirstPost] = useState(null);
  const [commentAcs, setcommentAcs] = useState(false);
  const [createForumMessage, { loading: createLoading }] =
    useMutation(CREATE_FORUM_MESSAGE);
  const idValue = useParams().id;
  const id = idValue.split("&")[0];
  const forumId = idValue.split("&")[1];
  const [textEditorValue, setTextEditorValue] = useState(
    EditorState.createEmpty()
  );
  const [deleteForumMessage, { loading: deleteLoading }] = useMutation(
    DELETE_FOURM_MESSAGES,
    {
      onCompleted: (res) => {
        refetch();
        toggleSnackbar({
          status: true,
          message: "Comment deleted successfully",
          variant: "success",
        });
      },
    }
  );
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const { data, refetch, loading } = useQuery(LIST_FOURMS_MESSAGE, {
    fetchPolicy: "network-only",
    variables: {
      skip: (page - 1) * rowsPerPage,
      limit: rowsPerPage,
      id: forumId,
      parentId: id,
      inType: "",
    },
    onCompleted: (res) => {
      mailGroupTitle(res.listAllForumMsgs?.dataSub?.subject)
      setFirstPost(res.listAllForumMsgs?.dataSub);
    },
  });

  const [upload] = useLazyQuery(IMAGE_UPLOAD, {
    fetchPolicy: "network-only",
  });
  const toolbarConfig = {
    display: [
      "INLINE_STYLE_BUTTONS",
      "BLOCK_TYPE_BUTTONS",
      "LINK_BUTTONS",
      "HISTORY_BUTTONS",
    ],
    INLINE_STYLE_BUTTONS: [
      { label: "Bold", style: "BOLD", className: "custom-css-class" },
      { label: "Italic", style: "ITALIC" },
      { label: "StrikeThrough", style: "STRIKETHROUGH" },
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: "UL", style: "unordered-list-item" },
      { label: "OL", style: "ordered-list-item" },
    ],
  };
  useEffect(() => {
    if (user?.data?.loginType !== "Admin") {
      getFourmUserDtls();
    } else {
      setIsManager(true);
      setcommentAcs(true);
    }
  }, []);
  const [getFourmUserDtls] = useLazyQuery(GET_FOURM_USER_DTLS, {
    fetchPolicy: "network-only",
    variables: {
      userId: user?.data?._id,
      forumId: forumId,
    },
    onCompleted: (res) => {
      if (res?.forumUserDetails?.commentAccess) setcommentAcs(true);
      if (res?.forumUserDetails?.isManager) setIsManager(true);
    },
  });

  async function handleSave(params) {
    try {
      let imagearr = [];
      for (let index = 0; index < selectedFile.length; index++) {
        let element = selectedFile[index];
        let url = element?.signedUrl;
        const response = await axios.put(url, element.file, {
          headers: {
            "Content-Type": element?.file?.type,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "X-Requested-With",
          },
        });
        imagearr.push(element?.publicUrl);
      }
      let dataObj = {
        content: draftToHtml(convertToRaw(textEditorValue.getCurrentContent())),
        forumParentId: id,
        forumId: forumId,
        firstPost: false,
        files: imagearr,
      };
      if(dataObj.content==="<p></p>\n" && dataObj.files.length===0){
        toggleSnackbar({
          status: true,
          message: "Please add some content",
          variant: "error",
        });
        return false;
      }
      createForumMessage({
        variables: { data: dataObj },
        onCompleted: (res) => {
          setTextEditorValue(EditorState.createEmpty());
          refetch();
          window.scrollTo(0, 0);
          setSelectedFile([]);
          toggleSnackbar({
            status: true,
            message: "Comment posted",
            variant: "success",
          });
        },
      }).catch((error) => {
        const message = error && error.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      });
    } catch (error) {
      const message = error && error.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  }
  function handleUploadClick(event) {
    let obj = {};
    var file = event.target.files[0];
    obj["fileName"] = file.name;
    obj["url"] = URL.createObjectURL(file);
    obj["file"] = file;
    let array = [...selectedFile];

    upload({
      variables: {
        originalFilename: obj["fileName"],
        path: "/conversations",
      },
      onCompleted: (res) => {
        obj["publicUrl"] = res?.imageUpload?.publicUrl;
        obj["signedUrl"] = res?.imageUpload?.signedUrl;
        array.push(obj);
        setSelectedFile(array);
      },
    });
  }
  function closeConfirm() {
    setDeleteId(null);
  }
  function handleConfirm() {
    deleteForumMessage({ variables: { ids: [deleteId] } })
    closeConfirm();
  }

  return (
    <>
      <div className="conversations-outer pb-2">
      <div className="d-block d-md-none">
          <h3 className="m-h3">{schoolDetails?.data?.schNameThe}</h3>
        </div>
        <div className="text-end">
        <Button
          className="btn outline-btn text-right mb-3"
          onClick={()=>history.goBack()}
        >
          Back
        </Button>
        </div>
        <input
          id="conversation-input"
          type="file"
          hidden
          onChange={handleUploadClick}
          onClick={(event) => {
            event.target.value = null;
          }}
        />
        <ConfirmDialog
          open={deleteId}
          title={"Delete"}
          body={"Are you sure you want to delete this comment ? "}
          closeText={"Cancel"}
          confirmText={"Delete"}
          closeConfirm={closeConfirm}
          handleConfirm={handleConfirm}
        />
        {loading && (
          <div className="mb-4 text-center">Loading comments......</div>
        )}
        {data?.listAllForumMsgs?.data &&
          data?.listAllForumMsgs?.data.length === 0 && (
            <div className="mb-4 text-center">No comments added yet......</div>
          )}

        {firstPost && (
          <div className="conv-card" style={{ border: "20px solid #E6E6E8" }}>
            <h2
              style={{
                fontWeight: 500,
                fontSize: "18px",
                color: "#4C5A76",
                paddingLeft: "20px",
              }}
            >
              {firstPost.subject}
            </h2>

            <div className="body me-0">{ReactHtmlParser(firstPost.content)}</div>
            {firstPost.files.length > 0 && (
              <div className="body">
                <p className="head-text">Attachment</p>
                <div className="mt-3">
                  {firstPost.files.map((file) => (
                    <span>
                      <img
                        style={{ width: "100px", height: "100px" }}
                        src={file}
                        className="img-fluid me-2"
                        alt="image"
                      />
                    </span>
                  ))}
                </div>
              </div>
            )}
            <div
              className="head d-flex justify-content-between align-items-center"
              style={{
                border: "none",
                borderTop: "1px solid #F0F0F0",
                paddingBottom: "0",
                paddingTop: "20px",
              }}
            >
              <div className="d-inline-flex align-items-center">
                <Avatar src={firstPost?.createdByDtls?.profileImage}></Avatar>
                <div className="ms-2 ms-md-3">
                  <p className="m-0 fs-14">
                    {firstPost?.createdByDtls?.firstName +
                      " " +
                      firstPost?.createdByDtls?.lastName}
                  </p>
                  <p
                    className="m-0 fs-14"
                    style={{
                      color: "#8C9195",
                      fontSize: "16px",
                      fontWeight: 400,
                    }}
                  >
                    {firstPost?.createdByDtls?.email}
                  </p>
                </div>
              </div>
              <div>
                <div className="fs-14"
                  style={{ color: "#8C9195", fontSize: "16px", fontWeight: 400 }}
                >
                  {moment(firstPost.createdDate).format("MMM DD, yyyy HH:mm A")}
                </div>
              </div>
            </div>
          </div>
        )}

        {data?.listAllForumMsgs?.data.map((item, index) => {
          if (!item.firstPost)
            return (
              <div className="conv-card" style={{ border: "" }}>
                <div className="head d-flex justify-content-between align-items-center">
                  <div className="d-inline-flex align-items-center">
                    <Avatar src={item?.createdByDtls?.profileImage}></Avatar>
                    <div className="ms-3">
                      <p className="m-0">
                        {item?.createdByDtls?.firstName +
                          " " +
                          item?.createdByDtls?.lastName}
                      </p>
                      <p
                        className="m-0"
                        style={{
                          color: "#8C9195",
                          fontSize: "16px",
                          fontWeight: 400,
                        }}
                      >
                        {item?.createdByDtls?.email}
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className="text-end mb-2 cursor-pointer">
                      {isManager && (
                        <DeleteIcon
                          onClick={() =>
                            setDeleteId(item._id)
                          }
                        />
                      )}
                    </div>
                    <div
                      style={{
                        color: "#8C9195",
                        fontSize: "16px",
                        fontWeight: 400,
                      }}
                    >
                      {moment(item.createdDate).format("MMM DD, yyyy HH:mm A")}
                    </div>
                  </div>
                </div>
                <div className="body me-0">{ReactHtmlParser(item.content)}</div>
                {item.files.length > 0 && (
                  <div className="body">
                    <p className="head-text">Attachment</p>
                    <div className="mt-3">
                      {item.files.map((file) => (
                        <span>
                          <img
                            style={{ width: "100px", height: "100px" }}
                            src={file}
                            className="img-fluid me-2"
                            alt="image"
                          />
                        </span>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            );
        })}
        
      </div>

      {(user.data.loginType === "Admin " || commentAcs) && (
          <>
            {/* <RichTextEditor
              value={textEditorValue}
              toolbarConfig={toolbarConfig}
              onChange={(value) => setTextEditorValue(value)}
            /> */}
            <div className="editor-wrapper"
            style={
              {
                padding: "15px 30px",
                boxShadow: "0px 1px 4px rgba(118, 118, 118, 0.19)",
                background: "#FFF",
                position: "fixed",
                bottom: 0,
                width: "calc(100% - 200px)",
                height: "250px",
                overflowX: "scroll"
              }
            }>
              <Editor
                editorState={textEditorValue}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={(value) => setTextEditorValue(value)}
                editorStyle={
                    {height: "130px"}
                }
              />
              {selectedFile.length > 0 && (
                <div className="mt-3">
                  {selectedFile.map((item) => (
                    <span className="me-3">
                      <img src={logo} className="img-fluid me-2" alt="logo" />
                      {item.fileName}
                    </span>
                  ))}
                </div>
              )}
              <div className="mt-2">
                <Button
                  className="btn-orange text-white"
                  onClick={() =>
                    document.getElementById("conversation-input").click()
                  }
                >
                  Upload
                </Button>
                <Button
                  disabled={selectedFile.length === 0 && textEditorValue.toString("html") === "<p><br></p>"}
                  className="btn-orange text-white ms-3"
                  onClick={() => handleSave()}
                >
                  Post
                </Button>
              </div>
            </div>
          </>
      )}
    </>
  );
}
