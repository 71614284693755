import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import {
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TableCell,
  TextField,
  TableRow,
  Table,
  TableContainer,
  Paper,
  TableHead,
  TableBody,
  Pagination,
  Grow,
  Checkbox,
  ListItemText,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import { CheckBox } from "@mui/icons-material";
import { useLazyQuery, useQuery, useMutation } from "@apollo/client";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  GET_MAIL_RESULT_FORUM,
  LIST_ALL_SCHOOL,
  LIST_FOURMS_USERS,
  LIST_ALL_FORUM_USERS,
  GET_FOURM_SCHOOLS,
} from "../../Graphql/queries";
import {
  CREATE_FORUM,
  CREATE_FORUM_USER,
  UPDATE_FORUM_USER,
  UPDATE_FORUM,
  DELETE_FORUM_USER,
  SAVE_FOURM_SCHOOLS,
} from "../../Graphql/mutations";
import { position } from "../Common/Options";
import { useForm } from "react-hook-form";
import { toggleSnackbar } from "../../RactiveVariables/index";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";

export default function AddGroups(props) {
  const { open, setOpen } = props;
  const [index, setIndex] = useState(props.addMember ? 2 : 1);
  const [fourmID, setFourmID] = useState(props.editFourm?._id ?? null);
  const [currentUserList, setCurrentUserList] = useState([]); //user listin in db
  const [schools, setSchools] = useState([]);
  const [count,setCount]= useState('');
  const [forumResponse,setForumResponse] = useState('');
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [loader,setLoader] = useState(false)
  const [positionVal, setPosition] = useState([]);
  const schema = yup.object().shape({
    forumName:
      index === 1 ? yup.string().required("Name is required").nullable() : null,
    description:
      index === 1
        ? yup.string().required("Description is required").nullable()
        : null,
  });
  const { register, handleSubmit, formState, reset, getValues } = useForm({
    defaultValues: {
      forumName: props?.editFourm?.forumName ?? null,
      description: props?.editFourm?.description ?? null,
      commentAccess: props?.editFourm?.commentAccess ?? null,
      postAccess: props?.editFourm?.postAccess ?? null,
    },
    resolver: yupResolver(schema),
  });
  const { errors } = formState;
  const [createForum, { loading: createLoading }] = useMutation(CREATE_FORUM,{refetchQueries:['listAllForum','listAllForumSchools']});
  const [updateForum, { loading: updateLoading }] = useMutation(UPDATE_FORUM,{refetchQueries:['listAllForum','listAllForumSchools'], fetchPolicy:"network-only"});
  const [saveForumSchool, { loading: saveschoolLoading }] =
    useMutation(SAVE_FOURM_SCHOOLS,{
      fetchPolicy:"network-only"
    });
    const handleChangePage = (e, page) => {
      setPage(page);
    };
  const [deleteForum, { loading: deleteLoading }] =
    useMutation(DELETE_FORUM_USER);
  const [getCurrentUserList,{loading:userLoading}] = useLazyQuery(LIST_ALL_FORUM_USERS, {
    fetchPolicy: "network-only",
    variables: { forumId: fourmID, skip: (page - 1) * rowsPerPage,
      limit: rowsPerPage,search:search},
    onCompleted: (res) => {
      setLoader(false)
      setCount(res?.listAllForumUsers?.count)
      let arr = [];
      res?.listAllForumUsers?.data.forEach((element) => {
        arr.push({
          forumId: fourmID,
          userId: element.userId,
          firstName: element?.schMember?.firstName,
          lastName: element?.schMember?.lastName,
          position: element?.schMember?.position,
          postAccess: element.postAccess,
          commentAccess: element.commentAccess,
          isManager: element.isManager,
          _id: element._id,
        });
      });
      setCurrentUserList(arr);
    },
  });
  const [createForumUser, { loading: createUserLoading }] =
    useMutation(CREATE_FORUM_USER);

  const [updateForumUser, { loading: updateUserLoading }] =
    useMutation(UPDATE_FORUM_USER);
  const [getList, { data: listResults, loading }] = useLazyQuery(
    GET_MAIL_RESULT_FORUM,
    {
      fetchPolicy: "network-only",
    }
  );
  const [getFourmSchool, { loading: getFourmSchoolLoading, data: getFourmSchoolData }] = useLazyQuery(
    GET_FOURM_SCHOOLS,
    {
      fetchPolicy: "network-only",
      variables: {
        skip: 0,
        limit: 200,
        id: fourmID,
      },
      onCompleted: (res) => {
        setPosition(res?.listAllForumSchools?.data[0]?.position ?? []);
        let schArr = [];
        res?.listAllForumSchools?.data.forEach((element) => {
          schArr.push(element.schoolId);
        });
        setSchools(schArr);
      },
    }
  );
  const { data: listSchool } = useQuery(LIST_ALL_SCHOOL, {
    variables: { src: "src", allSch: true },
  });
  useEffect(() => {
    if (fourmID) {
      getCurrentUserList();
      getFourmSchool();
    }
  }, []);
  useEffect(() => {
    if (index === 1) {
      getFourmSchool();
    }
  }, [index === 1]);

  const handleClose = (value) => {
    setOpen(false);
    if (!props.addMember) props.setEditFourm(null);
  };
  function handleNext() {
    if (index !== 2) setIndex(index + 1);
  }
  function handleBack() {
    if (index !== 1) setIndex(index - 1);
  }
  function handlePositionsSave(id) {
    if (positionVal.length === 0) {
      toggleSnackbar({
        status: true,
        message: "Please select atleast one position",
        variant: "error",
      });
    } else {
      let arr = [];
      // schools.forEach((element) => {
      //   arr.push({
      //     forumId: fourmID,
      //     schoolId: element,
      //     position: positionVal,
      //   });
      // });
      arr.push({
            forumId: id ?? fourmID,
            position: positionVal,
          });
          let config = {
            operationName: "getListForum",
            variables: {
                  positions: positionVal,
                },
            query:
              "query getListForum($membership: String, $membershipTypes: String, $regions: [String], $schools: [String], $memberFlag: String, $positions: [String], $udid: String) {\n  getListForum(\n    membership: $membership\n    membershipTypes: $membershipTypes\n    regions: $regions\n    schools: $schools\n    memberFlag: $memberFlag\n    positions: $positions\n    udid: $udid\n  ) {\n    data {\n      _id\n      firstName\n      lastName\n      email\n      role\n      position\n      __typename\n    }\n    count\n    __typename\n  }\n}",
          };
          const token = localStorage.getItem("@authToken");
          axios
              .post(process.env.REACT_APP_API_URL+"/graphql", config,{headers:{authorization:token}})
              .then((response)=>{
                let res = response.data.data;
                let createArr = [];
          res.getList?.data.forEach((element) => {
            let pos = currentUserList.findIndex(
              (e) => e.userId === element._id
            );
            if (pos == -1) {
              createArr.push({
                forumId: id ?? fourmID,
                userId: element._id,
                postAccess: getValues("postAccess"),
                commentAccess: getValues("commentAccess"),
                isManager: false,
              });
            }
          });
          let deleteArr = [];
          currentUserList.forEach((element) => {
            let pos = res.getList?.data.findIndex(
              (e) => e._id === element.userId
            );
            if (pos == -1) {
              deleteArr.push(element._id);
            }
          });
          saveForumSchool({
            variables: {
              data: arr,
              forumId: id ?? fourmID,
              createList: createArr,
              deleteList: deleteArr,
            },
          }).then((result) => {
            setLoader(false)
            // getList();
            getCurrentUserList();
            handleNext();
            props.handlePagination();
          });
              })
    }
  }

  function onSumbit(params) {
    if (index === 1&&positionVal.length === 0){
      toggleSnackbar({
        status: true,
        message: "Please select atleast one position",
        variant: "error",
      });
      return false
    }
    setLoader(true)
    params.position = positionVal;
    if (index === 1) {
      if (props.editFourm || fourmID) {
        let newArray = [];
        let delArray = [];
        getFourmSchoolData?.listAllForumSchools?.data[0]?.position.forEach(element => {
          if(!positionVal.includes(element))
            delArray.push(element);
        });

        positionVal.forEach(element => {
          if(!getFourmSchoolData?.listAllForumSchools?.data[0]?.position.includes(element))
            newArray.push(element);
        });
        params._id = fourmID;
        params.positionDelete = delArray;
        params.positionInsert = newArray;
        params.postAccessChange = props.editFourm?.postAccess === params.postAccess ? false : true;
        params.commentAccessChange = props.editFourm?.commentAccess === params.commentAccess ? false : true;

        try {
          updateForum({
            variables: { dataArg: params, _id: fourmID },
            onCompleted: (res) => {
              setForumResponse(res)
              handlePositionsSave()
            },
          }).catch((error) => {
            const message = error && error.message;
            toggleSnackbar({
              status: true,
              message: message,
              variant: "error",
            });
          });
        } catch (error) {
          const message = error && error.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        }
      } else {
        try {
          createForum({
            variables: { dataArr: params },
            onCompleted: (res) => {
              setForumResponse(res)
              setFourmID(res?.createForum?._id);
              handlePositionsSave(res?.createForum?._id);
              toggleSnackbar({
                status: true,
                message: "Group created successfully",
                variant: "success",
              });
            },
          }).catch((error) => {
            const message = error && error.message;
            toggleSnackbar({
              status: true,
              message: message,
              variant: "error",
            });
          });
        } catch (error) {
          const message = error && error.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        }
      }
    // } else if (index === 2) {
      // if (schools.length === 0) {
      //   toggleSnackbar({
      //     status: true,
      //     message: "Please select atleast one school",
      //     variant: "error",
      //   });
      // } else 
      
    } else if (index === 2) {
      props.handlePagination();
      handleClose();
      // try {
      //   let arr = [];
      //   userList.forEach((element) => {
      //     arr.push({
      //       forumId: element.forumId,
      //       userId: element.userId,
      //       postAccess: element.postAccess,
      //       isManager: element.isManager,
      //     });
      //   });
      //   createForumUser({
      //     variables: { data: arr },
      //     onCompleted: (res) => {
      //       props.handlePagination();
      //       handleClose();
      //     },
      //   }).catch((error) => {
      //     const message = error && error.message;
      //     toggleSnackbar({
      //       status: true,
      //       message: message,
      //       variant: "error",
      //     });
      //   });
      // } catch (error) {
      //   const message = error && error.message;
      //   toggleSnackbar({
      //     status: true,
      //     message: message,
      //     variant: "error",
      //   });
      // }
    }
  }
  function handleUpdate(userId, value, type, field) {
    let pos = currentUserList.findIndex((element) => element.userId === userId);
    if (pos >= 0) {
      let userArr = [...currentUserList];
      let userObj = userArr[pos];
      if (field === "postAccess") {
        userObj.postAccess = value;
      } else if (field === "isManager") {
        userObj.isManager = value;
      } else {
        userObj.commentAccess = value;
      }
      try {
        updateForumUser({
          variables: {
            data: {
              isManager: userObj.isManager,
              postAccess: userObj.postAccess,
              commentAccess: userObj.commentAccess,
              userId: userObj.userId,
            },
            _id: userObj._id,
          },
          onCompleted: (res) => {
            userArr[pos] = userObj;
            setCurrentUserList(userArr);
          },
        }).catch((error) => {
          const message = error && error.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
      } catch (error) {
        const message = error && error.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      }
    }
  }

  function handleRemove(userId, id) {
    deleteForum({ variables: { forumUserIds: [id] } }).then((response) => {
      if (response?.data?.deleteForumUsers?.status) {
        let pos = currentUserList.findIndex((element) => element._id === id);
        if (pos >= 0) {
          let userArr = [...currentUserList];
          userArr.splice(pos, 1);
          setCurrentUserList(userArr);
        }
      }
    });
  }

  return (
    <Drawer
      anchor={"right"}
      open={open}
      onClose={handleClose}
      className="add-group-model"
    >
      <div className="outer-div">
        <div className="head d-flex justify-content-between" style={{
          background: "#2B54A3",
        }}>
          <div>
            <p
              className="bold-text"
              style={{ color: "#FFFFFF", fontSize: "18px" }}
            >{props?.editFourm ? "Edit Group" : "Add Group"}</p>
          </div>
          <div onClick={handleClose} style={{ cursor: "pointer" }}>
            <div class="close-icon" style={{ color: "#FFFFFF" }}>X</div>
          </div>
        </div>
        <p
            className="bold-text"
            style={{ color: "#000000", paddingLeft: "25px" }}
          >{`${index} of 2`}</p>
        <div className="sidebar-toggler" role="presentation">
          <form
            style={{marginBottom:"30px"}}
            className="adduser-form"
            noValidate
            onSubmit={handleSubmit(onSumbit)}
          >
            {index === 1 && (
              <Form1
                register={register}
                errors={errors}
                editFourm={props.editFourm}
                listSchool={listSchool}
                schools={schools}
                setSchools={setSchools}
                getList={getList}
                positionVal={positionVal}
                setPosition={setPosition}
                data={props.editFourm}
                forumResponse={forumResponse}
              />
            )}
            {index === 2 && (
              <Form3
                listResults={[...currentUserList]}
                // listResults={listResults}
                handleUpdate={handleUpdate}
                handleRemove={handleRemove}
                handleChangePage={handleChangePage}
                page={page}
                rowsPerPage={ rowsPerPage}
                count={count}
                setSearch={setSearch}
                search={search}
                userLoading={userLoading}
              />
            )}
            <div
              className="d-flex justify-content-end mt-3 drawer-btn"
              style={{
                position: "fixed",
                bottom: "0px",
                right: "0px",
                background: "#fff",
                width: "700px",
                padding: "30px",
              }}
            >
              {index !== 1 && (
                <Button
                  className="btn outline-btn text-right"
                  onClick={handleBack}
                >
                  Back
                </Button>
              )}
              <Button className="btn btn-filled text-right" type="submit">
                {index === 3 ? "Save": loader? <CircularProgress size="20px" color="inherit"  /> : "Next"}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Drawer>
  );
}

function Form1(props) {
  const { register, errors, listSchool, setSchools, schools, positionVal, setPosition,forumResponse } = props;
  // function handleSchoolSelect(e) {
  //   let value = e.target.value;
  //   if (value[value.length - 1] === "All") {
  //     let idArr = [];
  //     listSchool?.listSchool.data.forEach((element) => {
  //       idArr.push(element._id);
  //     });
  //     setSchools(schools.length === idArr.length ? [] : idArr);
  //     return;
  //   }
  //   setSchools(value);
  // }
  function handlePositionSelect(e) {
    let value = e.target.value;
    if (value[value.length - 1] === "All") {
      setPosition(positionVal.length === position.length ? [] : position);
      return;
    }
    setPosition(value);
  }
  function sortLabels(a, b) {
    var nameA = a.toUpperCase();
    var nameB = b.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  }
  return (
    <>
      <div className="bold-text">Enter group info</div>

      <div className="row">
        <div className="col-md-12 mt-3">
          <InputLabel className="form-label mt-10">Group Name</InputLabel>
          <TextField
            {...register("forumName")}
            variant="outlined"
            fullWidth
            autoFocus
          />
          {errors && errors?.["forumName"] && (
            <p className="errorMessage text-danger">
              {errors?.forumName?.message}
            </p>
          )}
        </div>
        <div className="col-md-12 mt-3">
          <InputLabel className="form-label mt-10">Description</InputLabel>
          <TextField
            fullWidth
            multiline
            {...register("description")}
            rows={5}
            // inputProps={{
            //   maxlength: CHARACTER_LIMIT,
            // }}
            // helperText={`${"0"}/${CHARACTER_LIMIT}`}
          />
          {errors && errors?.["description"] && (
            <p className="errorMessage text-danger">
              {errors?.description?.message}
            </p>
          )}
        </div>
        <div className="bold-text mt-5">Access</div>
        <div className="col-md-6 mt-3">
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={props?.editFourm?.postAccess||forumResponse?.createForum?.postAccess||forumResponse?.updateForum?.postAccess}
                {...register("postAccess")}
              />
            }
            label="Post Access"
          />
        </div>
        <div className="col-md-6 mt-3">
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={props?.editFourm?.commentAccess||forumResponse?.createForum?.commentAccess||forumResponse?.updateForum?.commentAccess}
                {...register("commentAccess")}
              />
            }
            label="Comment Access"
          />
        </div>
      </div>
      <div className="row mt-5">
        <div>
          <div className="d-flex justify-content-between">
            <div className="bold-text">Group criteria</div>
            {/* <div className="bold-text">0 members selected</div> */}
          </div>
          <div className="row">
            {/* <div className="col-md-6 mt-3">
              <InputLabel className="form-label">School</InputLabel>
              <Select
                fullWidth
                multiple
                value={schools}
                onChange={(e) => handleSchoolSelect(e)}
                renderValue={(value) => {
                  return value.length > 0
                    ? "( " + value.length + " ) schools selected"
                    : "";
                }}
              >
                <MenuItem key={"selectAll"} value={"All"}>
                  <Checkbox
                    checked={
                      schools.length === listSchool?.listSchool.data.length
                    }
                    indeterminate={
                      schools.length > 0 &&
                      schools.length < listSchool?.listSchool.data.length
                    }
                  />
                  <ListItemText primary={"Select All"} />
                </MenuItem>
                {listSchool?.listSchool.data.map((item) => (
                  <MenuItem key={item._id} value={item._id}>
                    <Checkbox checked={schools.indexOf(item._id) > -1} />
                    <ListItemText primary={item.schNameThe} />
                  </MenuItem>
                ))}
              </Select>
            </div> */}
            <div className="col-md-12 mt-3">
              <InputLabel className="form-label">Position</InputLabel>
              <Select
                fullWidth
                multiple
                value={positionVal}
                onChange={(e) => handlePositionSelect(e)}
                renderValue={(value) => {
                  return value.length > 0
                    ? "( " + value.length + " ) selected"
                    : "";
                }}
              >
                <MenuItem key={"selectAll"} value={"All"}>
                  <Checkbox
                    checked={positionVal.length === position.length}
                    indeterminate={positionVal.length > 0 && positionVal.length < position.length}
                  />
                  <ListItemText primary={"All members / positions"} />
                </MenuItem>
                {position?.sort(sortLabels).map((item) => (
                  <MenuItem key={item} value={item}>
                    <Checkbox checked={positionVal.indexOf(item) > -1} />
                    <ListItemText primary={item} />
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}


function Form3(props) {
  const CHARACTER_LIMIT = 1000;

  const { listResults, handleUpdate, handleRemove,search,setSearch ,userLoading} = props;
  return (
    <>
      <div className="bold-text">Members</div>
      <div className="d-flex align-items-center mt-3 mb-2">
        {/* <img
          className="search-icon"
          src="../assets/images/search.svg"
          alt="search icon"
        /> */}
        <TextField
          fullWidth
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search for a user..."
        />
      </div>
      <TableContainer component={Paper} style={{ overflowY: "scroll", maxHeight: "calc(100vh - 368px)" }}>
        <Table sx={{ minWidth: 650 }} aria-label="group members">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Positon</TableCell>
              <TableCell>Manager</TableCell>
              <TableCell>Post Access</TableCell>
              <TableCell>Comment Access</TableCell>
              {/* <TableCell></TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            
            {userLoading?
            <TableRow key={"test"}>
            <TableCell>
            <div className="d-flex align-items-center">
                        <div className="ms-3">
                          Loading...
                        </div>
                      </div>
            </TableCell>
            </TableRow>:
            listResults &&
              listResults.length > 0 &&
              listResults.filter((element) => {
                  let name = element.firstName + " " + element.lastName;
                  if (
                    search &&
                    !name.toLowerCase().includes(search.toLowerCase())
                  )
                    return false;
                  else return true;
                })
                .map((item) => (
                  <TableRow key={"test"}>
                    <TableCell>
                      <div className="d-flex align-items-center">
                        <div className="ms-3">
                          {item?.firstName + " " + item?.lastName}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell className="lower-case">
                      {item.position}
                    </TableCell>
                    <TableCell>
                      <Switch
                        checked={item.isManager}
                        onChange={(e) => {
                          handleUpdate(
                            item.userId,
                            e.target.checked,
                            item._id,
                            "isManager"
                          );
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Switch
                        checked={item.postAccess}
                        onChange={(e) => {
                          handleUpdate(
                            item.userId,
                            e.target.checked,
                            item._id,
                            "postAccess"
                          );
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Switch
                        checked={item.commentAccess}
                        onChange={(e) => {
                          handleUpdate(
                            item.userId,
                            e.target.checked,
                            item._id,
                            "commentAccess"
                          );
                        }}
                      />
                    </TableCell>
                    {/* <TableCell>
                    <DeleteIcon
                      onClick={() => handleRemove(item.userId, item._id)}
                      style={{ cursor: "pointer" }}
                    />
                  </TableCell> */}
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
          count={Math.ceil(
            props?.count / props?.rowsPerPage
          )}
          page={props?.page}
          shape="rounded"
          onChange={props?.handleChangePage}
          style={{ marginTop: "10px", float: "right" }}
        />
    </>
  );
}
