const schoolList= [
    {
        value: "School 1",
        label: "School 1"
    },
    {
        value: "School 2",
        label: "School 2"
    },
    {
        value: "School 3",
        label: "School 3"
    },
    {
        value: "School 4",
        label: "School 4"
    },
    {
        value: "School 5",
        label: "School 5"
    }
];
const regionList = [
    {
        value: "Region 1",
        label: "Region 1"
    },
    {
        value: "Region 2",
        label: "Region 2"
    },
    {
        value: "Region 3",
        label: "Region 3"
    },
    {
        value: "Region 4",
        label: "Region 4"
    },
    {
        value: "Region 5",
        label: "Region 5"
    },

];
const positionList = [
    {
        value:"position 1",
        label:"position 1"
    },
    {
        value:"position 2",
        label:"position 2"
    },
    {
        value:"position 3",
        label:"position 3"
    },
    {
        value:"position 4",
        label:"position 4"
    },
    {
        value:"position 5",
        label:"position 5"
    },
    {
        value:"position 6",
        label:"position 6"
    },
];
 const membershipList = [
     {
         value: "member 1",
         label: "member 1"
     },
     {
        value: "member 2",
        label: "member 2"
    },
    {
        value: "member 3",
        label: "member 3"
    },
    {
        value: "member 4",
        label: "member 4"
    },
    {
        value: "member 5",
        label: "member 5"
    },
 ];
 const membershipTypeList = [
     {
         value:"type 1",
         label:"type 2"
     },
     {
        value:"type 1",
        label:"type 2"
    },
 ]

export {schoolList,regionList,positionList,membershipList,membershipTypeList};