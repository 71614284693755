import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  CircularProgress,
  Drawer,
  InputLabel,
  TextField,
  IconButton,
} from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useMutation } from "@apollo/client";
import { ADD_TOPICS, UPDATE_TOPICS } from "../../Graphql/mutations";
import { toggleSnackbar } from "../../RactiveVariables";
import { useEffect, useState } from "react";
export const AddTopics = (props) => {
  const schema = yup.object().shape({
    topic: yup.string().required("Topic is Required"),
    description: yup.string().required("Description is Required"),
  });
  const { register, handleSubmit, formState, reset, setValue } = useForm({
    defaultValues: props.defaultValue,
    // defaultValues: formatDefaultValue(props.editItem),
    resolver: yupResolver(schema),
  });
  const { errors } = formState;
  const [addTopic, { loading }] = useMutation(ADD_TOPICS);
  function closeDrawer() {
    props.setOpen(false);
    props.setSelectedItem(null);
  }
  const [editTopic, { loading: editLoading }] = useMutation(UPDATE_TOPICS);
  const SubscriberList = structuredClone(props?.selectedItem?.subScribers?.data);
  const [deleteId,setDeleteId]= useState([])
  const [originalListSubs,setoriginalListSubs] =useState(SubscriberList??[])
  const [listSubs,setListSubs] =useState(SubscriberList??[])
  const onSubmit = (params) => {
    try {
      if (props?.selectedItem?._id) {
        params["_id"] = props.selectedItem?._id;
        params['subScriberIds']= deleteId

        editTopic({ variables: { data: params } })
          .then((res) => {
            const message= res?.data?.editTopics?.message
            const status = res?.data?.editTopics?.status
            const toastVariant = status?"success":"error"
            toggleSnackbar({
              status: true,
              message: message,
              variant: toastVariant,
            });
            props.handlePagination();
            status&&props.setOpen(false);
            status&&props.setSelectedItem(null);
          })
          .catch((error) => {
            const message = error && error?.message;
            toggleSnackbar({
              status: true,
              message: { message },
              variant: "error",
            });
          });
      } else {
        addTopic({ variables: { data: params } })
          .then((res) => {
            props.setSelectedItem(null);
            const message= res?.data?.addTopics?.message
            const status = res?.data?.addTopics?.status
            const toastVariant = status?"success":"error"
            toggleSnackbar({
              status: true,
              message: message,
              variant: toastVariant,
            });
            props.handlePagination();
            props.setOpen(false);
          })
          .catch((error) => {
            const message = error && error?.message;
            toggleSnackbar({
              status: true,
              message: { message },
              variant: "error",
            });
          });
      }
    } catch (error) {
      const message = error && error?.message;
      toggleSnackbar({
        status: true,
        message: { message },
        variant: "error",
      });
    }
  };
  
  function handelDeleteSub(index, id) {
    // Create a copy of the idArr to avoid directly mutating it
    const idArrCopy = [...deleteId];

    // Add the new ID to the copy
    idArrCopy.push(id);
    
    // Update the state with the new array
    setDeleteId(idArrCopy);
    // Update the listSubs state
    const copySub = [...listSubs];
    copySub.splice(index, 1);
    setListSubs(copySub);
  }

  function handleSearch(event) {
    const searchVariable = event.target.value.toLowerCase().trim();; // Convert to lowercase for case-insensitive search
    if(searchVariable===""){
      setListSubs(originalListSubs)
    }else{
    const filteredValue = originalListSubs.filter((item) => {
      const fullName = `${item.firstName} ${item.lastName}`.toLowerCase();
      return fullName.includes(searchVariable);
    });
    setListSubs(filteredValue)
  }
  }

  return (
    <div>
      <Drawer anchor={"right"} open={props.open} onClose={closeDrawer}>
        <div className="sidebar-toggler" role="presentation">
          <div className="title-outer">
            <h1>
              {Object.keys(props.selectedItem).length > 0
                ? "Edit Topic"
                : "Add Topic"}
            </h1>
            <div onClick={closeDrawer} className="close-icon">
              X
            </div>
          </div>
          <form
            className="adduser-form usergroup-form mt-3"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="row">
              <div className="col-md-12 mb-3">
                <InputLabel className="form-label">
                  Topic<sup className="text-danger">*</sup>
                </InputLabel>
                <TextField
                  style={{ width: "100%" }}
                  variant="outlined"
                  {...register("topic")}
                  error={errors.topic}
                  autoFocus
                  defaultValue={props?.selectedItem?.topic}
                />
                {errors && errors?.["topic"] && (
                  <p className="errorMessage text-danger">
                    {errors?.topic?.message}
                  </p>
                )}
              </div>
              <div className="col-md-12 mb-3">
                <InputLabel className="form-label">
                  Description<sup className="text-danger">*</sup>
                </InputLabel>
                <TextField
                  style={{ width: "100%" }}
                  variant="outlined"
                  {...register("description")}
                  error={errors.description}
                  multiline
                  rows={4}
                  defaultValue={props?.selectedItem?.description}
                />
                {errors && errors?.["description"] && (
                  <p className="errorMessage text-danger">
                    {errors?.description?.message}
                  </p>
                )}
              </div>
              {SubscriberList?.length>0&&<div className="col-md-12 mb-3">
                <label className="mb-3" style={{fontWeight:"600",fontSize:"18px"}}>Subscribers Info</label>
                <TextField
                    style={{ width: "100%" }}
                    variant="outlined"
                    placeholder="Search"
                    onChange={(e)=>handleSearch(e)}
                />
              </div>  }   
            </div>

            {SubscriberList?.length>0&&<div className="manage-subscriber-name-outer">
                <div className="h-margin">
                  <h6>Subscriber Name</h6>
                </div>
                <div className="ms-wrap">
                { listSubs.map((item,index)=>(
                  <div className="manage-subscriber-name" key={item?._id}>
                    
                    <label>{`${item?.firstName} ${item?.lastName}`}</label>
                    <IconButton  style={{cursor:"pointer"}} onClick={()=>{ handelDeleteSub(index,item?._id)}} >
                            <img
                              // style={{cursor:"pointer"}}
                              src="../assets/images/manage-delete.svg"
                              alt="delete button"
                            />
                    </IconButton>
                    </div>
                ))
                  }
                  </div>
                </div>}

            <div className="d-flex justify-content-between top-margin align-items-center btn-section">
              <Button className="outline-btn" onClick={() => closeDrawer()}>
                Cancel
              </Button>
              <div className="d-flex align-items-center">
                <Button type="submit" className="highlight-btn">
                  {loading||editLoading ? (
                    <CircularProgress size="20px" color="inherit" />
                  ) : Object.keys(props.selectedItem)?.length > 0 ? (
                    "Update Topic"
                  ) : (
                    "Add Topic"
                  )}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </Drawer>
    </div>
  );
};
