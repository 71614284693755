import { useState } from "react";
import { useQuery } from "@apollo/client";
import { FINANCE_EVENT } from "../../Graphql/queries";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  FormControl,
  InputLabel,
  Pagination,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import EventDetails from "./EventDetails";
import { formatCurrency,formatDateandTime } from "../Common/GeneralFunctions";
import moment from "moment";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";

export default function Event(props) {
  const [search, setSearch] = useState("");
  const { eventId, setEventId, setEventDtls, eventDtls } = props;
  const date =new Date().getFullYear()
  let currentTime =new Date()
  var month = currentTime.getMonth() + 1
  const [dateFrom, setDateFrom] = useState(month<9?`${date-1}-09-01T18:14:16.887Z`:`${date}-09-01T18:14:16.887Z`);
  const [dateTo, setDateTo] = useState(month>=8?`${date+1}-08-30T18:14:16.887Z`:`${date}-08-30T18:14:16.887Z`);
  // const [dateFrom, setDateFrom] = useState(moment(new Date()).subtract(1, 'month'));
  // const [dateTo, setDateTo] = useState(new Date());
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [csvData,setCsvData] = useState('')
  const variables = {
    search: search,
    dateFrom: dateFrom,
    dateTo: dateTo,
    skip: (page - 1) * rowsPerPage,
    limit: rowsPerPage,
  };

  const { data: financeData, loading } = useQuery(FINANCE_EVENT, {
    fetchPolicy: "network-only",
    variables: variables,
  });

  const handleChangePage = (e, page) => {
    setPage(page);
  };
  function handlePageInputChange(event) {
    setRowsPerPage(Number(event.target.value));
    setPage(1);
  }
  function downloadTxtFile () {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      "search": "",
      "school": "",
      "memberType": "",
      "dateFrom": "2022-04-20T19:14:16.887Z",
      "dateTo": "2022-05-28T19:14:16.887Z",
      "skip": 0,
      "limit": 0
    });
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    fetch("https://dev-api.nysais.org/finance/financeEventReport", requestOptions)
      .then(response => response.text())
      .then(result => {
        const element = document.createElement("a");
    const file = new Blob([result], {type: 'text/csv'});
    element.href = URL.createObjectURL(file);
    element.download = "myFile.csv";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
      })
      .catch(error => console.log('error', error));

    

  }
  return (
    <>
      {!eventId ? (
        <>
          <div className="tabnav-filter">
            <div className="container">
              <div className="row">
                <div className="col-sm-3 d-flex">
                  <div className="d-flex align-items-center">
                    <img src="../assets/images/search.svg" />
                    <input
                      className="tabsearch-field"
                      type="text"
                      onChange={(e) =>{setPage(1); setSearch(e.target.value)}}
                      placeholder="Search by Event Name"
                    />
                  </div>
                </div>
                <div className="col-sm-9 d-flex justify-content-end">
                  <div className="d-flex">
                  <Box className="ms-2" sx={{ minWidth: 177 }}>
                  <FormControl fullWidth>
                    <DesktopDatePicker
                      inputFormat="MM/dd/yyyy"
                      value={dateFrom ? dateFrom : null}
                      renderInput={(params) => (
                        <TextField {...params} className="date-field" />
                      )}
                      onChange={(e) => {setPage(1);setDateFrom(e)}}
                    />
                  </FormControl>
                </Box>
                <Box className="ms-2" sx={{ minWidth: 177 }}>
                  <FormControl fullWidth>
                    <DesktopDatePicker
                      inputFormat="MM/dd/yyyy"
                      value={dateTo ? dateTo : null}
                      renderInput={(params) => (
                        <TextField {...params} className="date-field" />
                      )}
                      onChange={(e) =>{setPage(1); setDateTo(e)}}
                    />
                  </FormControl>
                </Box>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="finance-order-outer">
            <div>
              <TableContainer component={Paper}>
                <div className="d-flex align-items-center justify-content-between m-3">
                  <h2 className="ordercnt">
                    {financeData?.financeEvent?.count || "-" + " "} Events
                  </h2>
                  {/* <a
                    href="javascript:;"
                    className="btn btn-orange d-flex align-items-center"
                    onClick={()=>downloadTxtFile()}
                  >
                    <img className="me-1" src="../assets/images/download.svg" />
                    Download
                  </a> */}
                </div>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{width:200}}>{"Date & time"}</TableCell>
                      <TableCell>Event Name</TableCell>
                      <TableCell>Total Orders</TableCell>
                      <TableCell>Total Registrants</TableCell>
                      <TableCell>Cancellations</TableCell>
                      <TableCell>Total Reg Amt</TableCell>
                      <TableCell>Total Refund</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableRow>
                        <TableCell colSpan={12}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <p> Loading Events...</p>
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : financeData?.financeEvent?.evtRes &&
                      financeData?.financeEvent?.evtRes.length > 0 ? (
                      financeData?.financeEvent?.evtRes.map((item) => (
                        <TableRow
                          key={item.orderNo}
                          onClick={() => {
                            setEventId(item._id);
                            setEventDtls({name:item?.title,date:moment(parseInt(item.createdDate)).format("MMM DD")})
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <TableCell>{moment(item?.startDate)
                          .format("MMM D, YYYY") === moment(item?.endDate)
                          .format("MMM D, YYYY") ? moment(item?.startDate)
                          .format("MMM D, YYYY") +" "+ moment(item?.startDate).format("hh:mm a") +" - "+ moment(item?.endDate).format("hh:mm a") :
                          (formatDateandTime(item.startDate) + " - "+formatDateandTime(item.endDate))}</TableCell>
                          <TableCell>{item.title}</TableCell>
                          <TableCell>{item.totalOrder}</TableCell>
                          <TableCell>{item?.totalRegistrant}</TableCell>
                          <TableCell>{item.totalCancel}</TableCell>
                          <TableCell>{"$ "+formatCurrency(item.totalAmt)}</TableCell>
                          <TableCell>{"$ "+formatCurrency(item?.totalRefund)}</TableCell>
                        </TableRow>
                      ))
                    ) : search ? (
                      <TableRow>
                        <TableCell colSpan={12}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <p>No Events Found</p>
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow>
                        <TableCell colSpan={12}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <p>No Events Yet!</p>
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                {!loading && (
                  <div className="pagination-outer">
                    <div className="count-selector">
                      <Select
                        // variant="outlined"
                        onChange={handlePageInputChange}
                        value={rowsPerPage}
                      >
                        <MenuItem value={10}>{10}</MenuItem>
                        <MenuItem value={25}>{25}</MenuItem>
                        <MenuItem value={50}>{50}</MenuItem>
                        <MenuItem value={100}>{100}</MenuItem>
                      </Select>
                      <img
                        className="count-arrow"
                        src="../../assets/images/arrowupdown.svg"
                      />
                    </div>

                    <Pagination
                      count={Math.ceil(
                        financeData?.financeEvent?.count / rowsPerPage
                      )}
                      page={page}
                      shape="rounded"
                      onChange={handleChangePage}
                    />
                  </div>
                )}
              </TableContainer>
            </div>
          </div>
        </>
      ) : (
        <EventDetails id={eventId} />
      )}
    </>
  );
}
