const position = [
    "College Counselor",
    "Health teacher",
    "Facilities Director",
    "Executive Director",
    "Head of School",
    "Executive Assistant",
    "Board of Trustees - Board Chair",
    "Board of Trustees - Board Member",
    "Business Office - Business Manager",
    "Business Office - Controller",
    "Business Office - General Business Office",
    "Administrative Assistant",
    "Associate Head",
    "Assistant Head",
    "Division Head - Early Childhood",
    "Division Head - Lower School",
    "Division Head - Middle School",
    "Division Head - Upper School",
    "Dean of Faculty",
    "Human Resources Director",
    "Administrator",
    "Academic Dean",
    "Admissions Director",
    "Admissions Office",
    "Advancement Director",
    "Advancement Office",
    "Technology Director",
    "Technologist",
    "After School Director",
    "Athletic Director",
    "Athletic Office",
    "Communications Director",
    "Communications Office",
    "Diversity Director",
    "Department Chair",
    "Librarian",
    "Teacher",
    "Alumni/ae Office",
    "Student Support & Guidance",
    "Nurse",
    "Other",
  ];

  export {position};