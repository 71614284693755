import { gql } from "@apollo/client";

export const GET_CURRENT_USER = gql`
  query currentUserDtls {
    currentUserDtls {
      _id
      firstName
      lastName
      email
      bio
      title
      position
      role
      userType
      massTopicsIds
      userGroup
      address
      city
      phone
      userGroupId
      profileImage
      status
      schVisFlg
      positionOther
      pronoun
      state
      zip
      country
      schoolId
      schoolDetails {
        schName
        schNameThe
        schStatus
        schAddress
        schCity
        schState
        schZip
        schRegion
        schSubRegion
        schoolId
        socialMediaWeb {
          title
          url
        }
      }
      loginType
      moduleAcs {
        title
        status
      }
      userGpDtls {
        moduleAcs {
          title
          status
        }
      }
    }
  }
`;
export const LIST_USERS = gql`
  query listUser(
    $skip: Int!
    $limit: Int!
    $search: String
    $sortOrder: String
    $sortField: String
  ) {
    listUser(
      skip: $skip
      limit: $limit
      search: $search
      sortOrder: $sortOrder
      sortField: $sortField
    ) {
      data {
        _id
        firstName
        lastName
        email
        phone
        status
        role
        userType
        userGroup
        address
        city
        phone
        profileImage
        status
        state
        zip
        country
        userGroupId
        title
        bio
        userGpDtls {
          groupName
        }
        socialMedia {
          Twitter
          FaceBook
          Instagram
          Website
          Youtube
          Pinterest
        }
      }
      count
    }
  }
`;

export const LIST_ALLSCHOOL_USERS = gql`
  query listSchUser(
    $skip: Int!
    $limit: Int!
    $search: String
    $sortOrder: String
    $sortField: String
    $schId: String
    $loginType: String
    $listType: String
    $position: String
  ) {
    listSchUser(
      skip: $skip
      limit: $limit
      search: $search
      sortOrder: $sortOrder
      sortField: $sortField
      schId: $schId
      loginType: $loginType
      listType: $listType
      position: $position
    ) {
      data {
        _id
        firstName
        lastName
        email
        phone
        status
        role
        userType
        userGroup
        address
        city
        phone
        profileImage
        status
        state
        position
        schUserType
        positionOther
        massTopicsIds
        bio
        pronoun
        moduleAcs {
          title
          status
        }
        schVisFlg
        emailVisFlg
        schoolId
        schoolDetails {
          schName
          schNameThe
        }
        zip
        country
        userGroupId
        title
        bio
        userGpDtls {
          groupName
        }
      }
      count
    }
  }
`;

export const LIST_USERGROUP = gql`
  query listUserGroup(
    $skip: Int!
    $limit: Int!
    $search: String
    $sortOrder: String
    $sortField: String
  ) {
    listUserGp(
      skip: $skip
      limit: $limit
      search: $search
      sortOrder: $sortOrder
      sortField: $sortField
    ) {
      data {
        _id
        groupName
        noOfUsers {
          userNo
        }
        moduleAcs {
          title
          status
        }
      }
      count
    }
  }
`;
export const GET_ALLUSERGROUP = gql`
  query AllUserGroup {
    listAllUserGps {
      _id
      groupName
    }
  }
`;
export const IMAGE_UPLOAD = gql`
  query imageUpload($originalFilename: String!, $path: String!) {
    imageUpload(originalFilename: $originalFilename, path: $path) {
      filename
      originalFilename
      signedUrl
      publicUrl
    }
  }
`;
export const LIST_EVENTS = gql`
  query listAllEvents(
    $skip: Int
    $limit: Int
    $search: String
    $startDate: Date
    $category: String
    $eventType: String
    $sortOrder: String
    $sortField: String
    $belgsToArg: String
    $schId: String
    $status: String
    $src: String
  ) {
    listAllEvents(
      skip: $skip
      limit: $limit
      search: $search
      startDate: $startDate
      category: $category
      eventType: $eventType
      sortOrder: $sortOrder
      sortField: $sortField
      belgsToArg: $belgsToArg
      schId: $schId
      status: $status
      src: $src
    ) {
      count
      data {
        _id
        title
        startDate
        startTime
        endDate
        endTime
        description
        status
        seats
        tickCount
        shortSummary
        emailContent
        eventType
        location {
          link
          platform
        }
        featuredFrom
        featuredTo
        createdByDtls {
          firstName
          lastName
        }
        category
        spkrDtls {
          _id
          firstName
          title
          lastName
          profileImage
          organization
          bio
        }
      }
    }
  }
`;
export const LIST_SPEAKERS = gql`
  query listSpeaker(
    $skip: Int!
    $limit: Int!
    $search: String
    $sortOrder: String
  ) {
    listSpeakers(
      skip: $skip
      limit: $limit
      search: $search
      sortOrder: $sortOrder
    ) {
      data {
        _id
        firstName
        title
        lastName
        profileImage
        status
        organization
        bio
        pronoun
      }
      count
    }
  }
`;
export const GET_ALLSPEAKERS = gql`
  query listAllSpeakerss {
    listAllSpeakerss {
      _id
      firstName
      lastName
      profileImage
    }
  }
`;
export const EVENT_DETAILS = gql`
  query eventDetails($id: String!) {
    eventDetails(id: $id) {
      _id
      title
      category
      eventType
      startDate
      startTime
      endDate
      endTime
      targetAudience
      status
      description
      shortSummary
      disclaimer
      emailContent
      location {
        link
        platform
      }
      visibilityStatus
      featuredFrom
      featuredTo
      seats
      bulkDiscount
      nonMemberPrice
      memberPrice
      offLocationId
      offLocDtls {
        _id
        locName
        streetAdrs
        description
        latitude
        longitude
        locImage
        status
        state
        city
        zip
      }
      closingDate
      addInfo {
        title
        fileUrl
      }
      coupenCodes {
        coupenTitle
        perValue
      }
      speakerIDs
      schLocDtls
      schoolId
      formLink
      lastDateToRegister
      regStartDate
      lastDateToCancel
      schOnLocDtls
      spkrDtls {
        firstName
        title
        lastName
        profileImage
        bio
        organization
        pronoun
      }
      banner {
        url
        title
        priority
      }
      eventTags {
        title
        status
      }
    }
  }
`;
export const GET_ALLLOCATIONS = gql`
  query listAllOffLocations {
    listAllOffLocations {
      _id
      locName
      streetAdrs
    }
  }
`;
export const LIST_LOCATIONS = gql`
  query listOffLocations(
    $skip: Int!
    $limit: Int!
    $search: String
    $sortOrder: String
  ) {
    listOffLocations(
      skip: $skip
      limit: $limit
      search: $search
      sortOrder: $sortOrder
    ) {
      data {
        _id
        locName
        streetAdrs
        description
        city
        state
        zip
        latitude
        longitude
        locImage
        status
      }

      count
    }
  }
`;
export const LIST_SCHOOL = gql`
  query listAllSchool(
    $region: String
    $subRegion: String
    $city: String
    $schoolType: String
    $gradeLevel: String
    $zip: String
    $status: String
    $gender: String
    $grades: String
    $enrollment: Int
    $search: String
    $src: String
    $specialEducation: Boolean
    $enrollmentRange: String
    $skip: Int
    $limit: Int
  ) {
    listAllSchool(
      skip: $skip
      limit: $limit
      search: $search
      enrollment: $enrollment
      grades: $grades
      gender: $gender
      status: $status
      subRegion: $subRegion
      region: $region
      enrollmentRange: $enrollmentRange
      city: $city
      schoolType: $schoolType
      gradeLevel: $gradeLevel
      zip: $zip
      src: $src
      specialEducation: $specialEducation
    ) {
      data {
        _id
        schName
        schNameThe
        schStatus
        schAddress
        schCity
        schState
        schZip
        schRegion
        schSubRegion
        schPhone
        schFax
        schGender
        schLowestGrade
        schEnrollment
        schMemberSince
        schFeatredOnHome
        status
        schMembers {
          _id
          firstName
          lastName
          profileImage
          title
        }
        schImages {
          url
          priority
          title
        }
        schUdId
        schoolId
        socialMedia {
          Twitter
          FaceBook
          Instagram
          Website
          Youtube
          Pinterest
        }
      }
      count
    }
  }
`;
export const SCHOOL_DETAILS = gql`
  query schoolDetails($id: String!) {
    schoolDetails(id: $id) {
      _id
      schName
      schNameThe
      the
      schStatus
      schAddress
      schCity
      schState
      schZip
      schRegion
      schSubRegion
      schPhone
      schFax
      schGender
      schLowestGrade
      schEnrollment
      schMemberSince
      schFeatredOnHome
      status
      schMembers {
        _id
        firstName
        email
        lastName
        profileImage
        title
        schVisFlg
      }
      schImages {
        url
        priority
        title
      }
      schoolTags {
        title
        status
      }
      careerDtls {
        _id
        title
        createdDate
        careerType
        startDate
        hireDate
        minSal
        maxSal
        salType
        closingDate
        lastDateToApply
        expVal
        expType
      }
      eventDtls {
        _id
        title
        startDate
        endDate
        eventType
        startTime
        endTime
        description
        lastDateToRegister
        banner {
          url
        }
      }
      schUdId
      schoolId
      schHighestGrade
      schDescription
      socialMediaWeb {
        url
        priority
        title
      }
      schEnrollHide
      schLogoUrl
      websiteUrl
    }
  }
`;
export const LIST_SCHOOL_USERS = gql`
  query listSchUser(
    $skip: Int!
    $limit: Int!
    $search: String
    $sortOrder: String
    $sortField: String
    $schId: String!
    $loginType: String
  ) {
    listSchUser(
      skip: $skip
      limit: $limit
      search: $search
      sortOrder: $sortOrder
      sortField: $sortField
      schId: $schId
      loginType: $loginType
    ) {
      data {
        _id
        title
        firstName
        lastName
        email
        role
        userType
        userGroup
        address
        city
        phone
        profileImage
        status
        state
        zip
        massTopicsIds
        country
        userGroupId
        bio
        emailVisFlg
        schVisFlg
        position
        userGpDtls {
          groupName
        }
        moduleAcs {
          title
          status
        }
        pronoun
        position
        positionOther
      }
      count
    }
  }
`;
export const GET_ALL_SCHOOL_USER = gql`
  query listAllSchUsers($schId: String, $idType: String!) {
    listAllSchUsers(schId: $schId, idType: $idType) {
      data {
        _id
        title
        firstName
        lastName
        email
        role
        userType
        userGroup
        address
        city
        phone
        profileImage
        status
        state
        zip
        country
        userGroupId
        bio
        userGpDtls {
          _id
          groupName
        }
      }
    }
  }
`;
export const LIST_CAREER = gql`
  query listAllCareer(
    $skip: Int
    $limit: Int
    $search: String
    $schId: String
    $startDate: Date
    $careerType: String
    $status: String
  ) {
    listAllCareer(
      skip: $skip
      limit: $limit
      search: $search
      schId: $schId
      startDate: $startDate
      careerType: $careerType
      status: $status
    ) {
      data {
        _id
        title
        jobNumber
        description
        minSal
        maxSal
        expVal
        expType
        salType
        careerBelgsTo
        schoolId
        careerType
        status
        startDate
        endDate
        status
        closingDate
        lastDateToApply
        createdDate
        noOfApplicant
      }
      count
    }
  }
`;
export const CAREER_DETAILS = gql`
  query careerDetails($id: String!) {
    careerDetails(id: $id) {
      _id
      title
      jobNumber
      description
      minSal
      maxSal
      expVal
      expType
      salType
      careerBelgsTo
      schoolId
      careerType
      startDate
      endDate
      status
      closingDate
      lastDateToApply
      careerApplyType
      discriminatoryPolicy
      careerUrl
      hireDate
      positionType
      createdDate
      address
      city
      state
      zip
      role
      roleOther
      schoolDetails {
        schAddress
        schCity
        schState
        schZip
      }
    }
  }
`;
export const LIST_ALL_SCHOOL = gql`
  query listSchool($skip: Int, $limit: Int, $src: String, $allSch: Boolean) {
    listSchool(skip: $skip, limit: $limit, src: $src, allSch: $allSch) {
      data {
        _id
        schName
        schNameThe
        socialMediaWeb {
          url
          title
        }
        schStatus
        schMembers {
          _id
          firstName
          lastName
          delStatus
        }
        schUdId
        schoolId
      }
      count
    }
  }
`;
export const ORDER_DETAILS = gql`
  query ordersDetails($id: String!) {
    ordersDetails(id: $id) {
      _id
      orderNo
      schoolId
      eventId
      orderType
      totalMemCount
      totalNonMemCount
      totalMemPrice
      orderAsGuest
      totalNonMemPrice
      discountAmt
      coupenCode
      totalPrice
      bulkDiscount
      bulkDiscountAmt
      discount
      nonMemberPrice
      memberPrice
      status
      orderCreatedFrom
      orderCreatedFor
      grandTotal
      paymentDetails {
        paymentType
        paymentDate
        checkNumber
        description
        transactionCode
        paymentStatus
      }
      paidPaymentDetails {
        paymentRes
        paymentDate
        paymentTime
        paymentStatus
        transactionCode
      }
      paymentMethod
      createdByDtls {
        firstName
      }
      createdForDtls {
        _id
        firstName
        lastName
      }
      eventDtls {
        _id
        title
        lastDateToCancel
        bulkDiscount
        lastDateToRegister
        coupenCodes {
          coupenTitle
          perValue
        }
      }
      schDetails {
        schName
        schNameThe
        socialMediaWeb {
          url
          title
        }
      }
      ticketDetails {
        _id
        orderId
        totalPrice
        createdByDtls {
          firstName
          lastName
          role
          userType
          profileImage
          status
          userGroupId
          schoolId
        }
        schUserList {
          _id
          title
          firstName
          lastName
          role
          userType
          email
          schUserType
          profileImage
          userGpDtls {
            _id
            groupName
          }
          position
        }
      }
    }
  }
`;
export const LIST_ALL_ORDERS = gql`
  query listAllOrders(
    $schId: String
    $search: String
    $orderDate: Date
    $skip: Int!
    $limit: Int!
    $eventId: String
    $orderCreatedFor: String
  ) {
    listAllOrders(
      schId: $schId
      search: $search
      orderDate: $orderDate
      skip: $skip
      limit: $limit
      eventId: $eventId
      orderCreatedFor: $orderCreatedFor
    ) {
      data {
        _id
        orderDate
        orderNo
        createdForDtls {
          firstName
          lastName
        }
        createdByDtls {
          userType
        }
        paymentMethod
        totalPrice
        totalMemCount
        totalNonMemCount
        totalRegist
        orderCreatedFrom
        grandTotal
        eventDtls {
          title
          lastDateToCancel
        }
        schDetails {
          schName
          schNameThe
        }
        status
      }
      count
    }
  }
`;
export const LIST_ORDERS_TICKET = gql`
  query listOrderTicket($orderId: String, $skip: Int!, $limit: Int!) {
    listOrderTicket(orderId: $orderId, skip: $skip, limit: $limit) {
      data {
        _id
        regId
        orderId
        status
        totalTicket
        pricePerTcket
        totalPrice
        schUserList {
          firstName
          lastName
          role
          userType
        }
      }
      count
    }
  }
`;
export const LIST_ALL_TICKET = gql`
  query listAllOrderTicket(
    $id: String
    $reType: String
    $search: String
    $skip: Int
    $limit: Int
    $status: String
    $memberType: String
    $loginType: String
  ) {
    listAllOrderTicket(
      id: $id
      reType: $reType
      search: $search
      skip: $skip
      limit: $limit
      status: $status
      memberType: $memberType
      loginType: $loginType
    ) {
      data {
        _id
        orderId
        schoolUserId
        status
        totalTicket
        pricePerTcket
        totalPrice
        canceledDate
        canceledBy
        orderDtls {
          orderNo
          _id
        }
        schoolId
        eventId
        createdByDtls {
          _id
          title
          firstName
          lastName
          email
          role
        }
        schUserList {
          _id
          title
          firstName
          lastName
          email
          role
          schUserType
        }
        regId
        schoolDtls {
          schName
          schNameThe
        }
      }
      count
    }
  }
`;
export const COUNT_ORDER = gql`
  query countOrders($eventId: String) {
    countOrders(eventId: $eventId) {
      orderCnt
      orderTktCnt
      orderSum
      orderTicktSum
      ticketRefCnt
      orderTicktCanSum
      cancelledCnt
      orderTicktRefSum
    }
  }
`;

export const ORDER_DETAILS_FOR_GUEST = gql`
  query ordersDetails($id: String!) {
    ordersDetails(id: $id) {
      _id
      orderNo
      schoolId
      eventId
      orderType
      totalMemCount
      totalNonMemCount
      totalMemPrice
      totalNonMemPrice
      totalPrice
      bulkDiscount
      coupenCode
      discountAmt
      discount
      bulkDiscountAmt
      nonMemberPrice
      grandTotal
      orderAsGuest
      memberPrice
      status
      orderCreatedFrom
      orderCreatedFor
      paymentDetails {
        paymentType
        checkNumber
        paymentDate
        description
        transactionCode
      }
      paidPaymentDetails {
        paymentRes
        paymentDate
        paymentTime
        paymentStatus
        transactionCode
      }
      paymentMethod
      createdByDtls {
        firstName
      }
      createdForDtls {
        firstName
        lastName
      }
      eventDtls {
        _id
        title
        bulkDiscount
        lastDateToRegister
        coupenCodes {
          coupenTitle
          perValue
        }
      }
      ticketDetails {
        _id
        orderId
        totalPrice
        createdByDtls {
          firstName
          lastName
          role
          userType
          profileImage
          status
          userGroupId
          schoolId
        }
        schUserList {
          _id
          title
          firstName
          lastName
          role
          email
          userType
          schUserType
          profileImage
          userGpDtls {
            _id
            groupName
          }
          position
        }
      }
    }
  }
`;
export const LIST_ADDRESS = gql`
  query listAddress($schoolId: String!, $type: String!) {
    listAddress(schoolId: $schoolId, type: $type) {
      _id
      schZip
      schCity
      schState
      schoolId
      schAddress1
      schAddress2
      defaultAddress
    }
  }
`;
export const CHECK_DOMAIN = gql`
  query checkDomain($domain: String, $emailId: String, $cType: String) {
    checkDomain(domain: $domain, emailId: $emailId, cType: $cType) {
      status
      schoolRes {
        schName
        schNameThe
        _id
      }
      userRes {
        firstName
        lastName
        email
      }
      cType
    }
  }
`;
export const FINANCE_ORDER = gql`
  query financeOrder(
    $search: String
    $memberType: String
    $paymentType: String
    $school: String
    $dateFrom: String!
    $dateTo: String!
    $skip: Int!
    $limit: Int!
  ) {
    financeOrder(
      search: $search
      memberType: $memberType
      paymentType: $paymentType
      school: $school
      dateFrom: $dateFrom
      dateTo: $dateTo
      skip: $skip
      limit: $limit
    ) {
      totalDashbordObj {
        totalOrder
        totalReg
        totalAmt
        totalRefund
        totalCancel
      }
      orderDtls {
        _id
        updatedDate
        schoolName
        orderCreatedFor
        grandTotal
        eventName
        memberName
        refundAmount
        schStatus
        regId
        totalOrderRegistrant
        paymentMethod
        orderNo
        totalRegistrant
      }
      count
    }
  }
`;
export const FINANCE_REFUND = gql`
  query financeRefund(
    $search: String
    $dateFrom: String
    $dateTo: String
    $skip: Int!
    $limit: Int!
  ) {
    financeRefund(
      search: $search
      dateFrom: $dateFrom
      dateTo: $dateTo
      skip: $skip
      limit: $limit
    ) {
      refRes {
        _id
        updatedDate
        refundAmount
        memberName
        orderId
        regId
        cardType
        orderNo
        cancelledReg
      }
      count
    }
  }
`;

export const FINANCE_EVENT = gql`
  query financeEvent(
    $search: String
    $dateFrom: String
    $dateTo: String
    $skip: Int!
    $limit: Int!
  ) {
    financeEvent(
      search: $search
      dateFrom: $dateFrom
      dateTo: $dateTo
      skip: $skip
      limit: $limit
    ) {
      evtRes {
        _id
        createdDate
        title
        totalOrder
        totalRegistrant
        totalCancel
        totalAmt
        totalRefund
        endDate
        startDate
      }
      count
    }
  }
`;

export const GET_MAIL_RESULT = gql`
  query getList(
    $membership: String
    $membershipTypes: String
    $regions: [String]
    $schools: [String]
    $memberFlag: String
    $positions: [String]
    $udid: String
  ) {
    getList(
      membership: $membership
      membershipTypes: $membershipTypes
      regions: $regions
      schools: $schools
      memberFlag: $memberFlag
      positions: $positions
      udid: $udid
    ) {
      status
      message
    }
  }
`;
export const GET_MAIL_RESULT_FORUM = gql`
  query getListForum(
    $membership: String
    $membershipTypes: String
    $regions: [String]
    $schools: [String]
    $memberFlag: String
    $positions: [String]
    $udid: String
  ) {
    getListForum(
      membership: $membership
      membershipTypes: $membershipTypes
      regions: $regions
      schools: $schools
      memberFlag: $memberFlag
      positions: $positions
      udid: $udid
    ) {
      data {
        _id
        firstName
        lastName
        email
        role
        position
      }
      count
    }
  }
`;
export const LIST_ALL_MAIL_GROUPS = gql`
  query listAllMailGroups(
    $search: String
    $startDate: Date
    $category: String
    $skip: Int
    $limit: Int
  ) {
    listAllMailGroups(
      search: $search
      startDate: $startDate
      category: $category
      skip: $skip
      limit: $limit
    ) {
      count
      data {
        _id
        groupName
        category
        mailCount
        updatedDate
        createdByDtls {
          firstName
          lastName
        }
        groupType
        status
        region
        position
        uploadedFile
        schoolId
        description
        status
      }
    }
  }
`;

export const MAIL_GROUP_DETAILS = gql`
  query mailGroupDetails($id: String) {
    mailGroupDetails(id: $id) {
      _id
      groupName
      category
      membership

      region
      position
      groupType
      status
      schoolId
      createdByDtls {
        _id
        title
        firstName
        lastName
        email
        role
        userGroup
      }
      uploadedFile
      createdByDtls {
        _id
        firstName
        lastName
        email
        role
      }
      mailCount
    }
  }
`;

export const LIST_ALL_UPDATES = gql`
  query listAllEventUpdates($eventId: String, $skip: Int!, $limit: Int!) {
    listAllEventUpdates(eventId: $eventId, skip: $skip, limit: $limit) {
      count
      data {
        _id
        eventId
        schoolId
        files
        subject
        content
        createdDate
        eventUpdateType
        eventDtls {
          _id
          eventType
        }
        createdByDtls {
          firstName
          lastName
        }
      }
    }
  }
`;
export const LIST_ALL_POSITIONS = gql`
  query listAllTicketPositions($eventId: String) {
    listAllTicketPositions(eventId: $eventId) {
      position
    }
  }
`;
export const LIST_All_ENOTICES = gql`
  query listAllENotice(
    $search: String
    $skip: Int!
    $limit: Int!
    $eventId: String
  ) {
    listAllENotice(
      search: $search
      skip: $skip
      limit: $limit
      eventId: $eventId
    ) {
      count
      data {
        _id
        subject
        from
        status
        createdDate
        sentDate
        sentCount
        scheduledDate
        eventDtls {
          _id
          title
          eventType
          startDate
          startTime
          endDate
          endTime
          targetAudience
          status
          description
          shortSummary
          emailContent
          featuredFrom
          featuredTo
          spkrDtls {
            _id
            firstName
            title
            lastName
            profileImage
            status
            bio
          }
        }
      }
    }
  }
`;

export const LIST_ENOTICELIST = gql`
  query eNoticeDetails($id: String!) {
    eNoticeDetails(id: $id) {
      _id
      eventId
      toList
      toGpList
      ccList
      fromLabel
      massTopicsIds
      from
      replyTo
      eNoticeType
      subject
      toGpListDtls {
        _id
        groupName
      }
      content
      status
      emailCnt
      eventDtls {
        _id
        title
        eventType
        startDate
        startTime
        endDate
        endTime
        targetAudience
        status
        description
        shortSummary
        emailContent
        featuredFrom
        featuredTo
        offLocationId
        offLocDtls {
          locName
          streetAdrs
        }
        spkrDtls {
          _id
          firstName
          title
          lastName
          profileImage
          status
          bio
        }
      }
    }
  }
`;
export const FINANCE_EVENT_DETAILS = gql`
  query financeEventOrders(
    $eventId: String
    $school: String
    $memberType: String
    $skip: Int!
    $limit: Int!
  ) {
    financeEventOrders(
      eventId: $eventId
      school: $school
      memberType: $memberType
      skip: $skip
      limit: $limit
    ) {
      evtRes {
        _id
        memberName
        schoolName
        totalAmt
        status
        orderNo
        grandTotal
        position
        schStatus
      }
      singleEventDashBoard {
        totalOrder
        totalRegistrant
        totalCancel
        totalAmt
        totalRefund
      }
      count
    }
  }
`;
export const FINANCE_DISPATCH = gql`
  query batchRes(
    $search: String
    $dateFrom: String!
    $dateTo: String!
    $skip: Int!
    $limit: Int!
  ) {
    batchRes(
      search: $search
      dateFrom: $dateFrom
      dateTo: $dateTo
      skip: $skip
      limit: $limit
    ) {
      data {
        _id
        totalAmount
        dispatchDtls {
          batchId
          orderId
          orderNo
          card_type
          schoolUserId
          memberName
          eventId
          eventName
          amount
          settle_date
        }
      }
      count
    }
  }
`;

export const LIST_SCHOOL_MAIL = gql`
  query listSchoolNews(
    $skip: Int
    $limit: Int
    $membership: String
    $regions: [String]
  ) {
    listSchoolNews(
      skip: $skip
      limit: $limit
      membership: $membership
      regions: $regions
    ) {
      data {
        schName
        schNameThe
        _id
      }
    }
  }
`;
export const LIST_EVENTS_PER_TIC = gql`
  query listAllEvtsPerTkts($skip: Int, $limit: Int) {
    listAllEvtsPerTkts(skip: $skip, limit: $limit) {
      data {
        _id
        orderId
        eventDtls {
          _id
          title
          category
          eventType
          startDate
          startTime
          endDate
          endTime
          createdByDtls {
            firstName
            lastName
          }
          tickCount
          seats
          status
        }
      }
      count
    }
  }
`;
export const CHECK_DOMAIN_AND_USER = gql`
  query checkDomainAndUser($domain: String, $emailId: String) {
    checkDomainAndUser(domain: $domain, emailId: $emailId) {
      schStatus
      userStatus
      schoolRes {
        schName
        schNameThe
        _id
      }
      userRes {
        firstName
        lastName
        email
      }
    }
  }
`;
export const EXPORT_ORDERS = gql`
  query exportOrders($orderId: String, $timeZone: String) {
    exportOrders(orderId: $orderId, timeZone: $timeZone) {
      pdfUrl
    }
  }
`;
export const LIST_ALL_MAILUSERS = gql`
  query listAllMailLists(
    $search: String
    $mailGroupId: String
    $skip: Int
    $limit: Int
    $udid: String
  ) {
    listAllMailLists(
      search: $search
      mailGroupId: $mailGroupId
      skip: $skip
      limit: $limit
      udid: $udid
    ) {
      data {
        _id
        firstName
        lastName
        email
        role
        position
        mailGroupId
      }
      gpData {
        groupName
      }
      count
    }
  }
`;

export const USER_TICKET_STATUS = gql`
  query userTicketStatus($schoolUserId: String, $eventId: String) {
    userTicketStatus(schoolUserId: $schoolUserId, eventId: $eventId) {
      message
      status
    }
  }
`;

export const LIST_FOURMS = gql`
  query listAllForum(
    $search: String
    $userId: String
    $skip: Int
    $limit: Int
    $listType: String
  ) {
    listAllForum(
      search: $search
      userId: $userId
      skip: $skip
      limit: $limit
      listType: $listType
    ) {
      data {
        _id
        createdDate
        forumName
        description
        everyOne
        memCount
        msgCount
        postAccess
        commentAccess
      }
      count
    }
  }
`;
export const LIST_FOURMS_USERS = gql`
  query listForumUsers($forumId: String, $skip: Int, $limit: Int) {
    listForumUsers(forumId: $forumId, skip: $skip, limit: $limit) {
      data {
        _id
        forumId
        userId
        status
        postAccess
        commentAccess
        isManager
        lastMailSentDate
        schMember {
          _id
          firstName
          lastName
          position
          email
        }
      }
      count
    }
  }
`;
export const LIST_ALL_FORUM_USERS = gql`
  query listAllForumUsers(
    $forumId: String
    $skip: Int
    $limit: Int
    $search: String
  ) {
    listAllForumUsers(
      forumId: $forumId
      skip: $skip
      limit: $limit
      search: $search
    ) {
      data {
        _id
        forumId
        userId
        status
        postAccess
        commentAccess
        isManager
        lastMailSentDate
        schMember {
          _id
          firstName
          lastName
          position
          email
        }
      }
      count
    }
  }
`;
export const LIST_FOURMS_MESSAGE = gql`
  query listAllForumMsgs(
    $search: String
    $id: String
    $inType: String
    $parentId: String
    $skip: Int!
    $limit: Int!
  ) {
    listAllForumMsgs(
      search: $search
      id: $id
      inType: $inType
      parentId: $parentId
      skip: $skip
      limit: $limit
    ) {
      data {
        _id
        forumId
        files
        subject
        content
        status
        createdDate
        firstPost
        forumParentId
        createdByDtls {
          _id
          firstName
          lastName
          profileImage
          email
        }
      }
      count
      dataSub {
        _id
        forumId
        files
        subject
        content
        status
        createdDate
        firstPost
        forumParentId
        createdByDtls {
          _id
          firstName
          lastName
          profileImage
        }
      }
    }
  }
`;
export const GET_FOURM_SCHOOLS = gql`
  query listAllForumSchools($skip: Int!, $limit: Int!, $id: String!) {
    listAllForumSchools(skip: $skip, limit: $limit, id: $id) {
      data {
        _id
        schoolId
        position
      }
    }
  }
`;
export const GET_FOURM_USER_DTLS = gql`
  query forumUserDetails($userId: String, $forumId: String) {
    forumUserDetails(userId: $userId, forumId: $forumId) {
      postAccess
      isManager
      commentAccess
    }
  }
`;

export const GET_ALL_TOPICS = gql`
  query ListTopics(
    $skip: Int!
    $limit: Int!
    $isArchived: Boolean!
    $search: String
  ) {
    listTopics(
      skip: $skip
      limit: $limit
      isArchived: $isArchived
      search: $search
    ) {
      count
      data {
        _id
        description
        isArchived
        subScribers {
          subScriberCount
          data {
            firstName
            lastName
            _id
          }
        }
        topic
      }
    }
  }
`;
export const ENOTICE_MAIL_LOGS = gql`
  query Data($enoticeId: String!, $skip: Int!, $limit: Int!, $search: String) {
    enoticeMailLogs(
      enoticeId: $enoticeId
      skip: $skip
      limit: $limit
      search: $search
    ) {
      data {
        enoticeId
        firstName
        lastName
        recipient
        status
      }
      count
    }
  }
`;

export const GET_ORDER_STATUS = gql`
query getOrderStatus($orderId: String!){
  getOrderStatus(orderId: $orderId) {
    orderStatus
  }
}
`;

export const USER_REG_CHECK = gql`
  query sameUsersWithSameEvents($eventId: String!, $usersIds: [String]!) {
    sameUsersWithSameEvents(eventId: $eventId, usersIds: $usersIds) {
      message
      status
    }
  }
`;