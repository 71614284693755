import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TablePagination,
  Pagination,
  Select,
  MenuItem,IconButton,
} from "@mui/material";
import AddUserGroup from "./AddUserGroup";
import { useQuery } from "@apollo/client";
import { LIST_USERGROUP } from "../../Graphql/queries";
import { useState } from "react";

export default function Usergroup(props) {
  const [openAdd, setOpenAdd] = useState(false);
  const [searchVariable, setSearchVariable] = useState(null);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [sortVariable, setSortVariable] = useState("-1");
  const [sortFieldVariable, setSortFieldVariable] = useState("_id");
  const getUserGroupVariables = {
    skip: (page - 1) * rowsPerPage,
    limit: rowsPerPage,
    search: searchVariable,
    sortOrder: sortVariable,
    sortField: sortFieldVariable,
  };
  const {
    data: userListGroup,
    fetchMore,
    loading,
  } = useQuery(LIST_USERGROUP, {
    fetchPolicy: "network-only",
    variables: getUserGroupVariables,
  });
  const [searchKeyword, setSearchKeyword] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);

  const handlePagination = () => {
    fetchMore({
      variables: getUserGroupVariables,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const editButtonClick = (item) => {
    setSelectedItem(item);
    setOpenAdd(true);
  };
  const handleChangePage = (e, page) => {
    setPage(page);
  };
  function handlePageInputChange(event) {
    setRowsPerPage(Number(event.target.value));
    setPage(1);
  }
  function handleSort(field) {
    if (sortFieldVariable === field) {
      if (sortVariable === "-1") setSortVariable("1");
      else setSortVariable("-1");
    } else {
      setSortFieldVariable(field);
      setSortVariable("1");
    }
  }
//   function getGpModules(obj) {
//   delete obj.__typename;

//   Object.Keys(obj).map((item)=>(


//   ))

// return "test";
//   }
function getAcsModules(data){
  let array = [];
  data.forEach(element => {
    if(element.status === true)
      array.push(element.title)
  });
  return array.join(", ");
}
  return (
    <div className="content-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="card border-0">
        <div className="card-body p-0">
          {selectedItem && (
            <AddUserGroup
              setSelectedItem={setSelectedItem}
              editItem={selectedItem}
              open={openAdd}
              setOpen={setOpenAdd}
              handlePagination={handlePagination}
            />
          )}
          <div className="filter-section d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <img
                className="search-icon"
                src="../assets/images/search.svg"
                alt="search icon"
              />
              <input
                className="searchbox"
                placeholder="Search for a user group"
                type="text"
                value={searchVariable}
                onChange={(e) => {setPage(1);setSearchVariable(e.target.value)}}
              />
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <Button
                role="button"
                type="button"
                className="btn add-btn"
                onClick={() => {
                  setOpenAdd(true);
                  setSelectedItem({});
                }}
              >
                + Add User Group
              </Button>
              {/* <Button
                role="button"
                type="button"
                className="btn border filter-btn ml-2"
              >
                <img
                  className="img-fluid filter-icon"
                  src="../assets/images/filter.svg"
                  alt="filter button icon"
                />
              </Button> */}
            </div>
          </div>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    User Groups
                    {/* <a
                      className="filter-icon"
                      onClick={() => handleSort("groupName")}
                    >
                      <img
                        src={
                          sortFieldVariable !== "groupName"
                            ? "../assets/images/arrowupdown.svg"
                            : sortVariable === "-1"
                            ? "../assets/images/DownArrow.svg"
                            : "../assets/images/UpArrow.svg"
                        }
                      />
                    </a> */}
                  </TableCell>
                  <TableCell>Module Access</TableCell>
                  <TableCell className="text-center">User</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="user-group">
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <p> Loading users groups...</p>
                      </div>
                    </TableCell>
                  </TableRow>
                ) : userListGroup?.listUserGp?.data &&
                  userListGroup?.listUserGp?.data.length ? (
                  userListGroup?.listUserGp?.data.map((item) => (
                    <TableRow key={item.groupName}>
                      <TableCell>
                        {item?.groupName}
                      </TableCell>
                        <TableCell>
                          {/* {item?.moduleAcs.map((elm)=>(
                          (elm?.status === true) ? 
                            elm?.title+", " : (
                              ""
                            )
                          ))} */}
                          {getAcsModules(item?.moduleAcs)}
                        </TableCell>
                      <TableCell className="text-center">{item?.noOfUsers?.userNo}</TableCell>
                      <TableCell align="center">
                        <IconButton
                          onClick={() => editButtonClick(item)}
                          type="submit"
                          className="p-2"
                        >
                          <img
                            src="../assets/images/pencil.svg"
                            alt="edit button image"
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                ) : searchVariable ? (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <p>No Users Group Found</p>
                      </div>
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <p>No Users Group Added Yet!</p>
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            {!loading && (
              <div className="pagination-outer">
                <div className="count-selector">
                  <Select onChange={handlePageInputChange} value={rowsPerPage}>
                    <MenuItem value={10}>{10}</MenuItem>
                    <MenuItem value={25}>{25}</MenuItem>
                    <MenuItem value={50}>{50}</MenuItem>
                    <MenuItem value={100}>{100}</MenuItem>
                  </Select>
                  <img
                    className="count-arrow"
                    src="../../assets/images/arrowupdown.svg"
                  />
                </div>

                <Pagination
                  count={Math.ceil(
                    userListGroup?.listUserGp?.count / rowsPerPage
                  )}
                  page={page}
                  shape="rounded"
                  onChange={handleChangePage}
                />
              </div>
            )}
          </TableContainer>
        </div>
      </div>
          </div>
        </div>
      </div>
      
    </div>
  );
}
