import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Switch,
  Button,
  Avatar,
  TablePagination,
  Pagination,
  MenuItem,
  Select,
} from "@mui/material";
import { useQuery, useMutation } from "@apollo/client";
import { LIST_FOURMS_USERS ,LIST_ALL_FORUM_USERS} from "../../Graphql/queries";
import { UPDATE_FORUM_USER } from "../../Graphql/mutations";
import { useState } from "react";
import { toggleSnackbar } from "../../RactiveVariables/index";
import AddConversation from "./AddConversations";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";
import AddGroups from "./AddGroups";
import AddMemberPreview from "./memberPreview";

export default function Members(props) {
  const { id } = useParams();
  const history = useHistory();
  const [openAdd, setOpenAdd] = useState(false);
  const [searchVariable, setSearchVariable] = useState(null);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [selectedTab, setSelectedTab] = useState(1);
  const [updateForumUser, { loading: updateUserLoading }] =
    useMutation(UPDATE_FORUM_USER);
  const { data, refetch, loading } = useQuery(LIST_ALL_FORUM_USERS, {
    fetchPolicy: "network-only",
    variables: {
      skip: (page - 1) * rowsPerPage,
      limit: rowsPerPage,
      search: searchVariable,
      forumId: id,
    },
  });

  const handleChangePage = (e, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };
  function handlePageInputChange(event) {
    setRowsPerPage(Number(event.target.value));
    setPage(1);
  }
  const handlePagination = () => {
    refetch();
  };
  function handleRoleChange(value, item, field) {
    let userObj = item;
    if (field === "postAccess") {
      userObj.postAccess = value;
    } else {
      userObj.isManager = value;
    }
    try {
      updateForumUser({
        variables: {
          data: {
            isManager: userObj.isManager,
            postAccess: userObj.postAccess,
            userId: userObj.userId,
          },
          _id: userObj._id,
        },
        onCompleted: (res) => {},
      }).catch((error) => {
        const message = error && error.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      });
    } catch (error) {
      const message = error && error.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  }
  function getId() {
    let arr = [];
    data?.listAllForumUsers?.data.forEach(element => {
        arr.push(element?.schMember._id)
    });
    return arr;
  }
  return (
    <div className="card border-0">
      <AddGroups
          open={openAdd}
          setOpen={setOpenAdd}
          editFourm={{_id:id}}
          addMember={true}
          handlePagination={handlePagination}
        />
        {/* {openAdd && <AddMemberPreview
          open={openAdd}
          setOpen={setOpenAdd}
          forumId = {id}
          userId = {getId()}
        />} */}
      <div className="card-body p-0">
        <div className="filter-section d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <img
              className="search-icon"
              src="../assets/images/search.svg"
              alt="search icon"
            />
            <input
              className="searchbox"
              placeholder="Search name..."
              type="text"
              value={searchVariable}
              onChange={(e) => {
                setPage(1);
                setSearchVariable(e.target.value);
              }}
            />
          </div>
          <div className="d-flex align-items-center justify-content-center">
            {/* <Button
              role="button"
              type="button"
              className="btn add-btn"
              onClick={() => setOpenAdd(true)}
            >
              + Add member
            </Button> */}
          </div>
        </div>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Join Date</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Posting</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <p> Loading Members...</p>
                    </div>
                  </TableCell>
                </TableRow>
              ) : data?.listAllForumUsers?.data &&
                data?.listAllForumUsers?.data.length > 0 ? (
                data?.listAllForumUsers?.data.map((item) => (
                  <TableRow key={item._id}>
                    <TableCell>
                      {item?.schMember?.firstName +
                        " " +
                        item?.schMember?.lastName}
                    </TableCell>
                    <TableCell>{item?.schMember?.email}</TableCell>
                    <TableCell>
                      {moment(item.createdDate).format("MMM DD, YYYY")}
                    </TableCell>
                    <TableCell>
                      <Select
                        style={{ width: "150px" }}
                        label=""
                        value={item.isManager}
                        onChange={(e) =>
                          handleRoleChange(e.target.value, item, "isManager")
                        }
                      >
                        <MenuItem value={true}>Manager</MenuItem>
                        <MenuItem value={false}>Member</MenuItem>
                      </Select>
                    </TableCell>
                    <TableCell>
                    <Select
                        style={{ width: "150px" }}
                        label=""
                        value={item.postAccess??false}
                        onChange={(e) =>
                          handleRoleChange(e.target.value, item, "postAccess")
                        }
                      >
                        <MenuItem value={true}>Allowed</MenuItem>
                        <MenuItem value={false}>Not allowed</MenuItem>
                      </Select>
                        </TableCell>
                  </TableRow>
                ))
              ) : searchVariable ? (
                <TableRow>
                  <TableCell colSpan={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <p>No Members Found</p>
                    </div>
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow>
                  <TableCell colSpan={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <p>No Members Added Yet!</p>
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {!loading && (
            <div className="pagination-outer">
              <div className="count-selector">
                <Select
                  // variant="outlined"
                  onChange={handlePageInputChange}
                  value={rowsPerPage}
                >
                  <MenuItem value={10}>{10}</MenuItem>
                  <MenuItem value={25}>{25}</MenuItem>
                  <MenuItem value={50}>{50}</MenuItem>
                  <MenuItem value={100}>{100}</MenuItem>
                </Select>
                <img
                  className="count-arrow"
                  src="../../assets/images/arrowupdown.svg"
                />
              </div>

              <Pagination
                count={Math.ceil(data?.listAllForumUsers?.count / rowsPerPage)}
                page={page}
                shape="rounded"
                onChange={handleChangePage}
              />
            </div>
          )}
        </TableContainer>
      </div>
    </div>
  );
}
