import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { Button, CircularProgress, InputLabel, TextField } from "@mui/material";
import { useLazyQuery, useMutation } from "@apollo/client";
import { CREATE_FORUM_MESSAGE } from "../../Graphql/mutations";
import { useForm } from "react-hook-form";
import { toggleSnackbar } from "../../RactiveVariables";
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { IMAGE_UPLOAD } from "../../Graphql/queries";
import logo from "./Attachment.svg";
 import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

export default function AddConversation(props) {
  const [errorMessage,setErrorMessage]= React.useState('')
  const [uploading,setuploading]= React.useState(false)
  const [textEditorValue, setTextEditorValue] = React.useState(
    EditorState.createEmpty()
  );
  // const [upload] = useLazyQuery(IMAGE_UPLOAD, {
  //   fetchPolicy: "network-only",
  // });
  const [selectedFile, setSelectedFile] = React.useState([]);
  const [upload, { data: uploadImageData }] = useLazyQuery(IMAGE_UPLOAD, {
    fetchPolicy: "network-only",
    onCompleted: (res) => {
      let array = [...selectedFile];
      let position = array.findIndex(
        (element) => element.fileName === res?.imageUpload?.filename
      );
      let obj = array[position];
      obj["publicUrl"] = res?.imageUpload?.publicUrl;
      obj["signedUrl"] = res?.imageUpload?.signedUrl;
    },
  });
  const schema = yup.object().shape({
    subject: yup.string().required("Title is required"),
  });
  
  const { open, setOpen, forumId } = props;
  const { register, handleSubmit, formState, reset } = useForm({
    resolver: yupResolver(schema),
  });
  const { errors } = formState;
  const [createForumMessage, { loading: createLoading }] =
    useMutation(CREATE_FORUM_MESSAGE);

  const handleClose = () => {
    setOpen(false);
  };
  async function onSumbit(params) {
    try {
      let imagearr = [];
      setuploading(true)
      for (let index = 0; index < selectedFile.length; index++) {
        let element = selectedFile[index];
        let url = element?.signedUrl;
        const response = await axios.put(url, element.file, {
          headers: {
            "Content-Type": element?.file?.type,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "X-Requested-With",
          },
        });
        imagearr.push(element?.publicUrl);
      }
      let dataObj = {
        subject: params.subject,
        content: draftToHtml(convertToRaw(textEditorValue.getCurrentContent())),
        forumId: forumId,
        firstPost: true,
        files: imagearr,
      };
      if(dataObj.content==="<p></p>\n" && dataObj.files.length===0){
        setErrorMessage("Description is required")
        // toggleSnackbar({
        //   status: true,
        //   message: "Please add description",
        //   variant: "error",
        // });
        return false;
      }
      createForumMessage({
        variables: { data: dataObj },
        onCompleted: (res) => {
          setuploading(false)
          props.refetch();
          handleClose();
          toggleSnackbar({
            status: true,
            message: "Conversation created successfully",
            variant: "success",
          });
        },
      }).catch((error) => {
        const message = error && error.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      });
    } catch (error) {
      const message = error && error.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  }

  const toolbarConfig = {
    display: [
      "INLINE_STYLE_BUTTONS",
      "BLOCK_TYPE_BUTTONS",
      "LINK_BUTTONS",
      "HISTORY_BUTTONS",
    ],
    INLINE_STYLE_BUTTONS: [
      { label: "Bold", style: "BOLD", className: "custom-css-class" },
      { label: "Italic", style: "ITALIC" },
      { label: "StrikeThrough", style: "STRIKETHROUGH" },
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: "UL", style: "unordered-list-item" },
      { label: "OL", style: "ordered-list-item" },
    ],
  };

//   function handleUploadClick(event) {
//     let array = [...selectedFile];

//     var file = event.target.files[0];
//     // obj.fileName = ;
//     // obj["url"] = URL.createObjectURL(file);
//     // obj["file"] = file;

// console.log("testttt11",array);
//     upload({
//       variables: {
//         originalFilename: file.name,
//         path: "/conversations",
//       },
//       onCompleted: (res) => {
//         let obj = {};
//         obj["url"] = URL.createObjectURL(file);
//         obj["file"] = file;
//         obj["publicUrl"] = res?.imageUpload?.publicUrl;
//         obj["signedUrl"] = res?.imageUpload?.signedUrl;
//         obj.fileName = res?.imageUpload?.filename;
//         array.push(obj);
//         setSelectedFile(array);
//       },
//     });
//   }
function handleUploadClick(event) {
  let array = [...selectedFile];
  var file = event.target.files[0];
  let fileName = '';
  let fileExtension = '';
  let newFileName = file?.name;
  
  // Rename filename if duplicate found.
  for (let index = 0; index < array.length; index++) {
    
    const element = array[index];
    if (typeof file != "undefined" && element.fileName != 'undefined' && element.fileName === file?.name) {
      fileName = file.name.substring(0, file.name.lastIndexOf('.'));
      fileExtension = file.name.substring(file.name.lastIndexOf('.'));
      newFileName = fileName + '-' + Date.now() + fileExtension;
    }
  }

  // let array = [];      
  upload({
    variables: { originalFilename: newFileName, path: "/school" },
  });
  array.push({
    fileName: newFileName,
    url: URL.createObjectURL(file),
    file: file,
  });
  setSelectedFile(array);
}
  function removeImage(index) {
    let imgArr = [...selectedFile];
    imgArr.splice(index,1);
    setSelectedFile(imgArr);
  }
  return (
    <Dialog onClose={handleClose} open={open}>
      <div className="fourm-add-conversation">
        <input
          id="conversation-input"
          type="file"
          hidden
          onChange={handleUploadClick}
          onClick={(event) => {
            event.target.value = null;
          }}
        />
        <div className="text-end close-btn" onClick={handleClose}>
          <img
                style={{width:'25px'}}
                src="../assets/images/close-line.png"
                alt="close icon"
              />
        </div>
        <div className="text-center head">Add Conversation</div>
        <div className="sidebar-toggler" role="presentation">
          <form
            className="adduser-form"
            noValidate
            onSubmit={handleSubmit(onSumbit)}
          >
            <div className="row">
              <div className="col-md-12 mt-3">
                <InputLabel className="form-label">Title<sup style={{color:"red"}}>*</sup></InputLabel>
                <TextField variant="outlined" {...register("subject")} autoFocus />
                {errors && errors?.["subject"] && (
                  <p className="errorMessage text-danger">
                    {errors?.subject?.message}
                  </p>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <InputLabel className="form-label">Description<sup style={{color:"red"}}>*</sup></InputLabel>
                {/* <TextField multiline rows={5} {...register("content")} /> */}
                <Editor
                  editorState={textEditorValue}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onEditorStateChange={(value) => setTextEditorValue(value)}
                />
                {errorMessage && (
                  <p className="errorMessage text-danger">
                    {errorMessage}
                  </p>
                )}
                <div className="select-attach">
                    <a
                      className="attachment-btn"
                      onClick={() =>
                        document.getElementById("conversation-input").click()
                      }
                    >
                      <img src="../assets/images/plus.svg" className="img-fluid me-2" alt="Add logo"/>Add Attachment
                    </a>
                  {selectedFile.length > 0 && (
                    <div className="selectedFile">
                      {selectedFile.map((item,index) => (
                        <div>
                        <span className="each-file">
                          <img src={logo} className="img-fluid me-2" alt="logo" />
                          {item.fileName} <a onClick={() => removeImage(index)}><img src="../assets/images/red-cross.svg" className="img-fluid file-remove" alt="logo" /></a>
                        </span>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          
            <Button className="btn-orange text-white mt-3" fullWidth type="submit">
              {createLoading||uploading?<CircularProgress size="20px" color="inherit" /> :"Submit"}
            </Button>
          </form>
        </div>
      </div>
    </Dialog>
  );
}
