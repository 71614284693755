import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useMutation, useReactiveVar, useLazyQuery } from "@apollo/client";
import { userDeatils } from "../../RactiveVariables/index";
import {
  TextField,
  InputLabel,
  FormGroup,
  Checkbox,
  FormControlLabel,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
import EventHeader from "../Common/EventHeader";
import {
  ORDER_DETAILS,
  ORDER_DETAILS_FOR_GUEST,
  LIST_ADDRESS,
  GET_ORDER_STATUS
} from "../../Graphql/queries";
import { PURCHASE_ORDER, UPDATE_ORDER } from "../../Graphql/mutations";
import { toggleSnackbar } from "../../RactiveVariables/index";
import { formatCurrency } from "../Common/GeneralFunctions";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AddressPopup from "../Address/AddressPopup";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import md5 from "md5";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { DesktopDatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { uuidv4 } from "../Common/GeneralFunctions";
import Collapse from '@mui/material/Collapse';
export default function Checkout(props) {
  const history = useHistory();
  const { id } = useParams();
  const [promo, setPromo] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [purchaseLoading, setPurchaseLoading] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const user = useReactiveVar(userDeatils);
  const [selectedDate, setSelectedDate] = React.useState();
  const loginType = user?.data?.loginType;
  const [purchaseOrder] = useMutation(PURCHASE_ORDER);
  const [updateOrder] = useMutation(UPDATE_ORDER);
  const [cardTypeSrc,setCardTypeSrc] = useState(null);
  const [getOrderStatus] = useLazyQuery(GET_ORDER_STATUS,{variables: {orderId:id },})
  const {
    data: ordersDetails,
    loading,
    refetch,
  } = useQuery(
    loginType === "Guest" ? ORDER_DETAILS_FOR_GUEST : ORDER_DETAILS,
    {
      fetchPolicy: "network-only",
      variables: { id: id },
      onCompleted: (res) => {
        if (res?.ordersDetails?.coupenCode) {
          let pos = res?.ordersDetails?.eventDtls?.coupenCodes.findIndex(
            (element) => element.coupenTitle === res?.ordersDetails?.coupenCode
          );
          if (pos > -1) {
            setPromo([res?.ordersDetails?.eventDtls?.coupenCodes[pos]]);
          }
        }
      },
    }
  );
  let data = ordersDetails?.ordersDetails;
  const schema = yup.object().shape({
    cardNumber: parseFloat(data?.grandTotal)>0 && yup.string().required("Card Number is required"),
    cardHoldName: parseFloat(data?.grandTotal)>0 && yup.string().required("Name is required"),
    billingAddress: selectedAddress==="" ? yup.string().required("Address is required") : null,
    cvv: parseFloat(data?.grandTotal)>0 && yup
      .string()
      .required("CVV is required")
      .test("cvv", "Must be a number", (value) => {
        if (value && isNaN(value.replace(/[-,),(, ]+/g, ""))) return false;
        else return true;
      })
      .when("cardNumber", (cardNumber) => {
        if (cardNumber && /^3[47][0-9]{5,}$/.test(cardNumber.replace(/[-,),(, ]+/g, ""))) {
          return yup.string().test("cvv", "Must be valid CVV", (value) => {
            if (value && (value.length === 4)) return true;
            else return false;
          });
        }
        else{
          return yup.string().test("cvv", "Must be valid CVV", (value) => {
            if (value && (value.length === 3)) return true;
            else return false;
          });
        }
      }),
    expDate: parseFloat(data?.grandTotal)>0 && yup.string().required("Expiry Date is required"),
  });
  const { register, handleSubmit, formState, control } = useForm({
    resolver: yupResolver(schema),
  });
  const { errors } = formState;
  
  const handleDateChange = (date) => {
    setSelectedDate(date);
    register("expDate").onChange({ target: { value: date, name: "expDate" } });
  };
  const { data: listAddress } = useQuery(LIST_ADDRESS, {
    variables: {
      schoolId: ordersDetails?.ordersDetails?.schoolId || user?.data?._id,
      type: ordersDetails?.ordersDetails?.schoolId ? "school" : "user",
    },
    onCompleted: (res) => {
      res?.listAddress.map((item) =>
        item.defaultAddress === true ? setSelectedAddress(item) : null
      );
    },
  });

  async function handlePayment(params) {
    setPurchaseLoading(true);
    getOrderStatus().then((status) => {
      if(status.data.getOrderStatus.orderStatus==='Ready-For-Payment'||status.data.getOrderStatus.orderStatus==='Open'){
        if(ordersDetails.ordersDetails.grandTotal<=0){
          updateOrder({
            variables: {
              _id: ordersDetails.ordersDetails._id,
              data: {
                status: "Paid",
                actionType: "StatusUpdate",
                orderType:ordersDetails.ordersDetails.orderType,
                grandTotal:ordersDetails.ordersDetails.grandTotal,
                totalMemCount:ordersDetails.ordersDetails.totalMemCount,
                totalNonMemCount:ordersDetails.ordersDetails.totalNonMemCount,
                totalPrice: ordersDetails.ordersDetails.totalPrice,
              },
            },
          }).then((res) => {
            history.push("/orderlisting");
          });
          return false;
        }
        let value = md5(
          process.env.REACT_APP_BLUEPAY_KEY + process.env.REACT_APP_BLUEPAY_ID
        );
        window.BluePay.setCredentials(
          process.env.REACT_APP_BLUEPAY_ID,
          value
        );
        window.BluePay.createToken(
          {
            CARD_ACCOUNT: params.cardNumber.split(" ").join(""),
            CARD_CVV2: params.cvv,
            // CARD_EXPIRE: moment(params.expDate).format("MM-YY"),
            CARD_EXPIRE: params.expDate,
            NAME1: params.cardHoldName,
            NAME2: params.cardHoldName+uuidv4(),
          },
          function (response, error) {
            if (response.STATUS !== "1") {
              toggleSnackbar({
                status: true,
                message:
                  response.MESSAGE === "CARD ACCOUNT NOT VALID"
                    ? "Invalid card number"
                    : response.MESSAGE,
                variant: "error",
              });
              setPurchaseLoading(false);
              return false;
            }
            let cardFullNumber = params.cardNumber.split(" ").join("")
            let lastFourDigit = cardFullNumber % 10000;
            let data = {
              orderId: ordersDetails.ordersDetails._id,
              trnId: response.TRANS_ID,
              grandTotal: ordersDetails.ordersDetails.grandTotal,
              coupenCode: ordersDetails.ordersDetails.coupenCode,
              discountAmt: ordersDetails.ordersDetails.discountAmt,
              bulkDiscount: ordersDetails.ordersDetails.bulkDiscountAmt,
              cardNumber: lastFourDigit.toString() ,
              cardHoldName: params.cardHoldName,
              addressId: selectedAddress._id,
            };
            purchaseOrder({
              variables: data,
              onCompleted: (res) => {
                setPurchaseLoading(false);
                if (res?.purchaseOrder.message)
                  toggleSnackbar({
                    status: true,
                    message: res?.purchaseOrder.message,
                    variant:
                      res?.purchaseOrder.status == true ? "success" : "error",
                  });
                if (res?.purchaseOrder.status == true) {
                  history.push("/orderlisting");
                }
              },
            });
          }
        );
      }
      else{
        toggleSnackbar({
          status: true,
          message: 'The event is already purchased',
          variant: "error",
        });
        setPurchaseLoading(false);
        history.push("/orderlisting");
      }
    })
    
    // if (selectedAddress === "") {
    //   toggleSnackbar({
    //     status: true,
    //     message: "Please select billing address",
    //     variant: "error",
    //   });
    //   setPurchaseLoading(false);
    //   return false;
    // }
 
    // window.BluePay.createToken({CARD_ACCOUNT: '4111111111111111', CARD_CVV2: '113', CARD_EXPIRE: '0828', NAME1: 'Sam', NAME2: 'Doe'},function (response,error){
    
  }


  function applyPromo() {
    if (promoCode === ordersDetails?.ordersDetails?.coupenCode) {
      toggleSnackbar({
        status: true,
        message: "Promo code already applied",
        variant: "warning",
      });
      return;
    }

    if (promoCode) {
      let array = data?.eventDtls?.coupenCodes;
      let position = array.findIndex(
        (element) => element?.coupenTitle === promoCode
      );
      if (position > -1) {
        setPromo([array[position]]);
        let totalVal = parseFloat(ordersDetails?.ordersDetails?.totalPrice);
        if (ordersDetails?.ordersDetails?.bulkDiscount) {
          totalVal =
            totalVal -
            totalVal *
              (parseFloat(ordersDetails?.ordersDetails?.bulkDiscount) / 100);
        }
        let discountedVal =
          parseFloat(totalVal) -
          parseFloat(totalVal) * (parseFloat(array[position].perValue) / 100);
        updateOrder({
          variables: {
            _id: ordersDetails.ordersDetails._id,
            data: {
              coupenCode: array[position].coupenTitle,
              discount: parseFloat(array[position].perValue),
              discountAmt:
                parseFloat(totalVal) *
                (parseFloat(array[position].perValue) / 100),
              grandTotal: discountedVal,
              actionType: "GrandTotalUpdate",
              totalMemCount:ordersDetails.ordersDetails.totalMemCount,
              totalNonMemCount:ordersDetails.ordersDetails.totalNonMemCount,
            },
          },
        }).then((res) => {
          refetch();
          toggleSnackbar({
            status: true,
            message: "Promo code applied",
            variant: "success",
          });
        });
        setPromoCode("");
      } else {
        setPromoCode("");
        toggleSnackbar({
          status: true,
          message: "Invalid Promo code",
          variant: "error",
        });
      }
    }
  }
  function handleSelectedAddress(e, value) {
    setSelectedAddress(value || "");
  }

  function formateNumber(e, oldNumber) {
    let number = e.target.value;
    if (number.length <= 19) {
      number = number
        .replace(/[^\dA-Z]/g, "")
        .replace(/(.{4})/g, "$1 ")
        .trim();
      return number;
    } else return oldNumber;
  }

  function formatExpDate(value) {
    if (value) {
      if (value.length == 3 && value[2] != "-")
        return value.substring(0, 2) + "-" + value.substring(2, 3);
      else return value.substring(0, 5);
    }
  }

  function handleRemovePromocode(params) {
    let discountedVal = parseFloat(ordersDetails?.ordersDetails?.totalPrice);
    if (ordersDetails?.ordersDetails?.bulkDiscount) {
      discountedVal =
        discountedVal -
        discountedVal *
          (parseFloat(ordersDetails?.ordersDetails?.bulkDiscount) / 100);
    }
    updateOrder({
      variables: {
        _id: ordersDetails.ordersDetails._id,
        data: {
          discount: null,
          coupenCode: null,
          discountAmt: 0,
          grandTotal: discountedVal,
          actionType: "GrandTotalUpdate",
          totalMemCount:ordersDetails.ordersDetails.totalMemCount,
          totalNonMemCount:ordersDetails.ordersDetails.totalNonMemCount,
        },
      },
    }).then((res) => {
      setPromo([]);
      refetch();
      toggleSnackbar({
        status: true,
        message: "Promo removed",
        variant: "success",
      });
    });
  }
  function checkForCardType(value) {
    if(value){
      if(/^4[0-9]{6,}$/.test(value.replace(/[-,),(, ]+/g, "")))
        setCardTypeSrc("../assets/images/cc-visa.svg");
      else if(/^3[47][0-9]{5,}$/.test(value.replace(/[-,),(, ]+/g, "")))
        setCardTypeSrc("../assets/images/cc-amex.svg");
      else if(/^6(?:011|5[0-9]{2})[0-9]{3,}$/.test(value.replace(/[-,),(, ]+/g, "")))
        setCardTypeSrc("../assets/images/cc-discover.svg");
      else if(/^5[1-5][0-9]{5,}|222[1-9][0-9]{3,}|22[3-9][0-9]{4,}|2[3-6][0-9]{5,}|27[01][0-9]{4,}|2720[0-9]{3,}$/.test(value.replace(/[-,),(, ]+/g, "")))
        setCardTypeSrc("../assets/images/cc-mastercard.svg");
      else
        setCardTypeSrc(null);
      
    }
    else{
      setCardTypeSrc(null);
    }
  }
  const isDateRegValid = new Date(data?.eventDtls?.lastDateToRegister)> new Date()
  return (
    <div>
      {openAdd&&<AddressPopup open={openAdd} setOpen={setOpenAdd} data={ordersDetails} />}
      <EventHeader title="Checkout" EventRegistration />
      <div className=" preview-outer pt-40">
        <div className="container">
          <div className="d-block d-md-none">
            <h3 className="m-h3">Checkout</h3>
          </div>
          <form onSubmit={handleSubmit(handlePayment)} noValidate>
            <div className="row">
              <div className="col-sm-8">
                <div className="card list-member">
                  <p className="list-head">{data?.eventDtls?.title}</p>
                  <div className="card-body">
                  <div className="d-flex mem-outer justify-content-between align-items-center">
                      <p className="mem-title d-flex align-items-center">
                        <img
                          className="me-1"
                          src="../assets/images/Payment.svg"
                        />{" "}
                        Coupon code
                      </p>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-5">
                        <InputLabel className="form-label">
                          Apply Promo Code
                        </InputLabel>
                        <TextField
                          style={{ width: "100%" }}
                          variant="outlined"
                          value={promoCode}
                          onChange={(e) => setPromoCode(e.target.value)}
                        />
                        {/* {errors && errors?.["title"] && (
                                <p className="errorMessage text-danger">
                                  {errors?.title?.message}
                                </p>
                              )} */}
                      </div>
                      <div className="col-md-3 mb-3">
                        <InputLabel className="form-label text-white">
                          Add
                        </InputLabel>
                        <a
                          className="btn-orange btn w-100 py-2 text-white"
                          onClick={() => applyPromo()}
                        >
                          Apply
                        </a>
                      </div>
                    </div>
                    <Collapse in={data?.grandTotal > 0}>
                    <div className="d-flex mem-outer justify-content-between align-items-center">
                      <p className="mem-title d-flex align-items-center">
                        <img
                          className="me-1"
                          src="../assets/images/Payment.svg"
                        />{" "}
                        Payment Method
                      </p>
                      {/* <p className="mem-title">
                        <a
                          className="add-addr d-flex align-items-center"
                          href="javascript:;"
                        >
                          <img
                            className="me-1"
                            src="../assets/images/Add.svg"
                          />{" "}
                          Add new card
                        </a>
                      </p> */}
                    </div>
                    <div className="row mt-3 row-set">
                      <div className="col-sm-4">
                        <InputLabel className="form-label">
                          Card number<sup className="text-danger">*</sup>
                        </InputLabel>
                        <div className="card-ctrl">
                        <Controller
                          control={control}
                          name="cardNumber"
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              id="time"
                              value={value}
                              onChange={(e) =>{
                                  onChange(formateNumber(e, value))
                                  checkForCardType(formateNumber(e, value))
                                }
                              }
                            />
                            
                          )}
                          
                        />
                         {cardTypeSrc && <img className="ctrl"  src={cardTypeSrc} />}
                       </div>
                        {errors && errors?.["cardNumber"] && (
                          <p className="errorMessage text-danger">
                            {errors?.cardNumber?.message}
                          </p>
                        )}
                      </div>
                      <div className="col-sm-4">
                        <InputLabel className="form-label">
                          Cardholder name<sup className="text-danger">*</sup>
                        </InputLabel>
                        <TextField {...register("cardHoldName")} />
                        {errors && errors?.["cardHoldName"] && (
                          <p className="errorMessage text-danger">
                            {errors?.cardHoldName?.message}
                          </p>
                        )}
                      </div>
                      <div className="col-sm-12">
                        <div className="row">
                          <div className="col-sm-4">
                          <div className="d-flex payment-cards justify-content-end align-items-center">
                        <img className="me-1"  src="../assets/images/cc-amex.svg" />
                        <img className="me-1"  src="../assets/images/cc-discover.svg" />
                        <img className="me-1"  src="../assets/images/cc-mastercard.svg" />
                        <img className="me-1"  src="../assets/images/cc-visa.svg" />
                        </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3 mb-3 row-set">
                      <div className="col-md-4">
                        <InputLabel className="form-label">
                          Expiry<sup className="text-danger">*</sup>
                        </InputLabel>

                        <Controller
                          control={control}
                          name="expDate"
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              style={{ width: "100%" }}
                              variant="outlined"
                              placeholder="MM-YY"
                              onChange={(e) =>
                                onChange({
                                  target: {
                                    value: formatExpDate(e.target.value),
                                    name: "expDate",
                                  },
                                })
                              }
                              value={value || ""}
                            />
                          )}
                        />
                        {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            inputFormat="MM-yy"
                            views={["year", "month"]}
                            value={selectedDate ? selectedDate : null}
                            renderInput={(params) => (
                              <TextField {...params} className="date-field" />
                            )}
                            onChange={handleDateChange}
                          />
                        </LocalizationProvider> */}
                        {errors && errors?.["expDate"] && (
                          <p className="errorMessage text-danger">
                            {errors?.expDate?.message}
                          </p>
                        )}
                        {/* {errors && errors?.["title"] && (
                                <p className="errorMessage text-danger">
                                  {errors?.title?.message}
                                </p>
                              )} */}
                      </div>
                      <div className="col-md-4">
                        <InputLabel className="form-label">
                          CVV<sup className="text-danger">*</sup>
                        </InputLabel>
                        <TextField
                          type={"password"}
                          style={{ width: "100%" }}
                          variant="outlined"
                          {...register("cvv")}
                        />
                        {errors && errors?.["cvv"] && (
                          <p className="errorMessage text-danger">
                            {errors?.cvv?.message}
                          </p>
                        )}
                      </div>
                    </div>
                    {/* <div className="row border-bottom my-2">
                      <div className="col-sm-12">
                        <FormGroup className="module-checkbox">
                          <FormControlLabel
                            control={
                              <Checkbox
                                icon={<RadioButtonUncheckedIcon />}
                                checkedIcon={<CheckCircleIcon />}
                              />
                            }
                            label="Save Card"
                          />
                        </FormGroup>
                      </div>
                    </div> */}
                    
                    {/* <div className="row mb-3">
                      <div className="col-md-5">
                        <InputLabel className="form-label">
                          PO Number
                        </InputLabel>
                        <TextField
                          style={{ width: "100%" }}
                          variant="outlined"
                          value={promoCode}
                          onChange={(e) => setPromoCode(e.target.value)}
                        />
                      </div>
                    </div> */}
                    {/* <div className="row mb-3 mt-3">
                      <div className="col-md-5">
                        <InputLabel className="form-label">
                          Add a Discount or Promo Code
                          <sup className="text-danger">*</sup>
                        </InputLabel>
                        <TextField
                          style={{ width: "100%" }}
                          variant="outlined"
                          //   {...register("title")}
                          //   error={errors.title}
                          //   defaultValue={eventDetails?.title}
                        />
                      </div>
                    </div> */}
                    </Collapse>
                  </div>
                </div>
                <div className="card list-member mt-3 mb-2 mb-md-5">
                  <div className="card-body">
                    <div className="d-flex mt-3 mem-outer justify-content-between align-items-center">
                      <p className="mem-title">Billing Address</p>
                      <p className="mem-title">
                        <a
                          onClick={() => setOpenAdd(true)}
                          className="add-addr d-flex align-items-center"
                          href="javascript:;"
                        >
                          <img
                            className="me-1"
                            src="../assets/images/Add.svg"
                          />{" "}
                          Add new Address
                        </a>
                      </p>
                    </div>
                    <div className="row my-3">
                      <div className="col-md-12">
                        <InputLabel className="form-label">
                          Select Address<sup className="text-danger">*</sup>
                        </InputLabel>
                        <Autocomplete
                          disablePortal
                          className="autocomplete-field"
                          id="combo-box-demo"
                          options={listAddress?.listAddress || []}
                          getOptionLabel={(option) => option.schAddress1}
                          value={selectedAddress ? selectedAddress : null}
                          onChange={handleSelectedAddress}
                          sx={{ width: "100%" }}
                          renderInput={(params) => (
                            <TextField variant="outlined" {...params} />
                          )}
                        />
                        {selectedAddress==="" && errors && errors?.["billingAddress"] && (
                          <p className="errorMessage text-danger">
                            {errors?.billingAddress?.message}
                          </p>
                        )}
                        {/* <TextField
                          style={{ width: "100%" }}
                          variant="outlined"
                          //   {...register("title")}
                          //   error={errors.title}
                          //   defaultValue={eventDetails?.title}
                        /> */}
                        {/* {errors && errors?.["title"] && (
                                <p className="errorMessage text-danger">
                                  {errors?.title?.message}
                                </p>
                              )} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="card order-data mb-3">
                  <div className="card-title">Order Summary</div>

                  <div className="card-table">
                    <div className="head">
                      <div className="row">
                        <div className="col-4">User Type</div>
                        <div className="col-4">Reg/Rate</div>
                        <div className="col-4">Amount</div>
                      </div>
                    </div>
                    {data?.totalMemCount > 0 && (
                      <div className="body">
                        <div className="row">
                          <div className="col-4">Member</div>
                          <div className="col-4">{`${
                            data?.totalMemCount
                          } x $${formatCurrency(data?.memberPrice)}`}</div>
                          <div className="col-4">{`$${formatCurrency(
                            parseFloat(data?.memberPrice) *
                              parseFloat(data?.totalMemCount)
                          )}`}</div>
                        </div>
                      </div>
                    )}
                    {data?.totalNonMemCount > 0 && (
                      <div className="body">
                        <div className="row">
                          <div className="col-4">Guest</div>
                          <div className="col-4">{`${
                            data?.totalNonMemCount
                          } x $${formatCurrency(data?.memberPrice)}`}</div>
                          <div className="col-4">{`$${formatCurrency(
                            parseFloat(data?.memberPrice) *
                              parseFloat(data?.totalNonMemCount)
                          )}`}</div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="card-total">
                    <div className="datas">
                      <div className="row">
                        <div className="col-8">Sub Total</div>

                        <div className="col-4">{`$${formatCurrency(
                          data?.totalPrice
                        )}`}</div>
                      </div>
                    </div>
                    {data?.bulkDiscount ? (
                      <div className="datas">
                        <div className="row">
                          <div className="col-8">Bulk Discount</div>

                          <div className="col-4">
                            {(data?.bulkDiscount || "-") + "%"}
                          </div>
                        </div>
                      </div>
                    ): null}
                    {/* <div className="datas">
                      <div className="row">
                        <div className="col-sm-9">Discount</div>
                        <div className="col-sm-3">$500</div>
                      </div>
                    </div> */}
                    {promo.length > 0 ? (
                      <>
                        <div className="datas pb-0">
                          <div className="row">
                            <div className="col-12 final">Promo Code</div>
                          </div>
                        </div>

                        <div className="datas">
                          <div className="row align-items-center">
                            <div className="col-8">
                              <div className="promo-outer">
                                <span className="promo">
                                  {promo[0]?.coupenTitle}
                                </span>
                                <span
                                  className="close-promo"
                                  style={{cursor:'pointer'}}
                                  onClick={() => handleRemovePromocode()}
                                >
                                  x
                                </span>
                              </div>
                            </div>
                            <div className="col-4">{` ${promo[0]?.perValue}%`}</div>
                          </div>
                        </div>
                        <div className="datas">
                          <div className="row">
                            <div className="col-8 final">Grand Total</div>
                            <div className="col-4">
                              {/* {parseInt(data?.grandTotal) -
                                parseInt(data?.grandTotal) *
                                  (parseInt(promo[0]?.perValue) / 100)} */}
                              {`$${formatCurrency(data?.grandTotal)}`}
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="datas">
                        <div className="row">
                          <div className="col-8">Grand Total</div>

                          <div className="col-4">{`$${formatCurrency(
                            data?.grandTotal
                          )}`}</div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="d-flex justify-content-center">

                    {(isDateRegValid&&data?.status !=='Completed')?<button
                      disabled={purchaseLoading}
                      className="btn-orange btn w-50 py-2 mt-3 text-white"
                      type="submit"
                    >
                      {purchaseLoading ? (
                        <CircularProgress size="20px" color="inherit" />
                      ) : (
                        "Purchase"
                      )}
                    </button>:<p style={{color:'red'}}>last date to register expired</p>}
                  </div>
                </div>
                {data?.paymentDetails.length > 0 && (
                  <div className="card order-data">
                    <div className="card-title">Payment Summary</div>

                    <div className="card-table">
                      <div className="head">
                        <div className="row">
                          <div className="col-5">Date and time</div>
                          <div className="col-4">Transaction Id</div>
                          <div className="col-3">Status</div>
                        </div>
                      </div>
                      {data?.paymentDetails.map((row) => (
                        <div className="body">
                          <div className="row">
                            <div className="col-5">
                              {moment(row.paymentDate).format(
                                "MMM DD, YYYY hh:mm a"
                              )}
                            </div>
                            <div className="col-4">
                              {row.transactionCode}
                            </div>
                            <div className="col-3">{row.paymentStatus}</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
