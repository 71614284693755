import { useState ,useEffect} from "react";
import { useQuery } from "@apollo/client";
import { FINANCE_ORDER } from "../../Graphql/queries";
import {
  Table,
  TableBody,
  TableCell,
  TextField,
  TableHead,
  TableRow,
  Box,
  FormControl,
  InputLabel,
  TablePagination,
  CircularProgress,
  Pagination,
  Autocomplete,
  MenuItem,
  Select,
} from "@mui/material";
import { useHistory, Link } from "react-router-dom";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import moment from "moment";
import { formatCurrency } from "../Common/GeneralFunctions";

export default function Orders(props) {
  const [search, setSearch] = useState("");
  const date =new Date().getFullYear()
  let currentTime =new Date()
  const history = useHistory();
  var month = currentTime.getMonth() + 1
  const [memberType, setMemberType] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [school, setSchool] = useState("");
  const [loadingCSV,setLoadingCSV] = useState(false);
  const [dateFrom, setDateFrom] = useState(month<9?`${date-1}-09-01T18:14:16.887Z`:`${date}-09-01T18:14:16.887Z`);
  const [dateTo, setDateTo] = useState(month>=8?`${date+1}-08-30T18:14:16.887Z`:`${date}-08-30T18:14:16.887Z`);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const variables = {
    search: search,
    memberType: memberType,
    school: school,
    dateFrom: dateFrom,
    paymentType:paymentType,
    dateTo: dateTo,
    skip: (page - 1) * rowsPerPage,
    limit: rowsPerPage,
  };

  const { data: financeData, loading } = useQuery(FINANCE_ORDER, {
    fetchPolicy: "network-only",
    variables: variables,
  });


 

  function downloadTxtFile () {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      "search": search||"",
      "school": school||"",
      "memberType": memberType||"",
      "dateFrom": dateFrom||"",
      "dateTo": dateTo||"",
      "paymentType":paymentType||"",
    });
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    fetch(`${process.env.REACT_APP_API_URL}/finance/financeOrderReport`, requestOptions)
      .then(response => response.text())
      .then(result => {
        const element = document.createElement("a");
    const file = new Blob([result], {type: 'text/csv'});
    element.href = URL.createObjectURL(file);
    element.download = "myFile.csv";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    setLoadingCSV(false)
      })
      .catch(error => console.log('error', error));

  }
  
 
  const shortDtls = [
    {
      label: "Total Orders",
      img: "../assets/images/totoalOrder.svg",
      value: "totalOrder",
    },
    {
      label: "Total Registrations",
      img: "../assets/images/totalReg.svg",
      value: "totalReg",
    },
    {
      label: "Total Cancellations",
      img: "../assets/images/totalCancel.svg",
      value: "totalCancel",
    },
    {
      label: "Total Amount",
      img: "../assets/images/totalAmt.svg",
      value: "totalAmt",
      currency: "currency",
    },
    {
      label: "Total Refund ",
      img: "../assets/images/totalRefund.svg",
      value: "totalRefund",
      currency: "currency",
    },
  ];

  const handleChangePage = (e, page) => {
    setPage(page);
  };
  function handlePageInputChange(event) {
    setRowsPerPage(Number(event.target.value));
    setPage(1);
  }

  return (
    <>
      <div className="tabnav-filter">
        <div className="container">
          <div className="row">
            <div className="col-sm-3 d-flex">
              <div className="d-flex align-items-center w-100">
                <img src="../assets/images/search.svg" />
                <input
                  className="tabsearch-field"
                  type="text"
                  onChange={(e) => {setPage(1);setSearch(e.target.value)}}
                  placeholder="Search by Name / Order Id / Event"
                />
              </div>
            </div>
            <div className="col-sm-9 d-flex justify-content-end">
              <div className="d-flex">
              <Box className="ms-2" sx={{ minWidth: 177 }}>
                  <FormControl fullWidth>
                    <InputLabel className="filter-label" id="category">
                      Payment Type
                    </InputLabel>
                    <Select className="filter-select" label="Member Type"
                    onChange={(e) => {setPage(1);setPaymentType(e.target.value)}}
                    > 
                      <MenuItem value="All">{"All"}</MenuItem>
                      <MenuItem value="Card">{"Card"}</MenuItem>
                      <MenuItem value="Check">{"Check"}</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Box className="ms-2" sx={{ minWidth: 177 }}>
                  <FormControl fullWidth>
                    <InputLabel className="filter-label" id="category">
                      Member Type
                    </InputLabel>
                    <Select className="filter-select" label="Member Type"
                    onChange={(e) => {setPage(1);setMemberType(e.target.value)}}
                    > 
                      <MenuItem value="All">{"All"}</MenuItem>
                      <MenuItem value="Member">{"Member"}</MenuItem>
                      <MenuItem value="Non-Member">{"Non -Member"}</MenuItem>
                      <MenuItem value="Individual">{"Individual"}</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Box className="ms-2" sx={{ minWidth: 177 }}>

                    {/* <InputLabel className="filter-label" id="category">
                      School
                    </InputLabel>
                    <Select
                      className="filter-select"
                      label="School"
                      onChange={(e) => setSchool(e.target.value)}
                    >
                      <MenuItem value="">{"None"}</MenuItem>
                      {props?.schoolList && props?.schoolList.map((item) => (
                        <MenuItem value={item.schName}>{item.schName}</MenuItem>
                      ))}
                    </Select> */}
                    <Autocomplete
                        disablePortal
                        className="autocomplete-field"
                        id="combo-box-demo"
                        variant="outlined"
                        options={props?.schoolList}
                        getOptionLabel={(option) => option.schNameThe}
                        // value= {}
                        onChange={(e,value)=>{setPage(1);setSchool(value?._id)}}
                        // sx={{ width: "100%" }}
                        renderInput={(params) => (
                          <TextField  {...params} 
                          placeholder="School"/>
                        )}
                      />

                </Box>
                <Box className="ms-2" sx={{ minWidth: 177 }}>
                  <FormControl fullWidth>
                    <DesktopDatePicker
                      inputFormat="MM/dd/yyyy"
                      value={dateFrom ? dateFrom : null}
                      renderInput={(params) => (
                        <TextField {...params} className="date-field" />
                      )}
                      onChange={(e) => {setPage(1);setDateFrom(e)}}
                    />
                  </FormControl>
                </Box>
                <Box className="ms-2" sx={{ minWidth: 177 }}>
                  <FormControl fullWidth>
                    <DesktopDatePicker
                      inputFormat="MM/dd/yyyy"
                      value={dateTo ? dateTo : null}
                      renderInput={(params) => (
                        <TextField {...params} className="date-field" />
                      )}
                      onChange={(e) => {setPage(1);setDateTo(e)}}
                    />
                  </FormControl>
                </Box>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="finance-order-outer">
        <div>
          <div className="align-items-center d-flex justify-content-between">
            {shortDtls.map((item) => {
              return(
              <div className="text-center">
                <div className="order-short-details">
                  <img src={item.img} className="text-center" />
                  <p className="main">{item.label}</p>
                  <p className="sub">
                    {item.currency?"$"+formatCurrency(financeData?.financeOrder?.totalDashbordObj?.[item.value]):financeData?.financeOrder?.totalDashbordObj===null?0:financeData?.financeOrder?.totalDashbordObj?.[item.value] }
                  </p>
                </div>
              </div>)
})}
          </div>
        </div>
        <div className=" mt-4">
          <div className="card border-0">
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between mb-2">
                <h2 className="ordercnt">
                  {financeData?.financeOrder?.count || "-" + " "} Orders
                </h2>
                <a
                  href="javascript:;"
                  // download="https://dev-api.nysais.org/finance/financeOrderReport"
                  className="btn btn-orange d-flex align-items-center"
                  onClick={()=>{downloadTxtFile();setLoadingCSV(true);}}
                >
                  <img className="me-1" src="../assets/images/download.svg" />
                  {loadingCSV===true? <CircularProgress size="20px" color="inherit"  /> :  "Download"}
                </a>
              </div>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Order Id</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>School/Membership</TableCell>
                    <TableCell>Event</TableCell>
                    <TableCell>Reg.</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>RF.Amt.</TableCell>
                    <TableCell>Mode</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell colSpan={12}>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <p> Loading Orders...</p>
                        </div>
                      </TableCell>
                    </TableRow>
                  ) : financeData?.financeOrder?.orderDtls &&
                    financeData?.financeOrder?.orderDtls.length > 0 ? (
                    financeData?.financeOrder?.orderDtls.map((item) => (
                      <TableRow key={item.orderNo}>
                        <TableCell style={{cursor:"pointer"}} onClick={()=>{history.push({pathname:`/orderdetails/${item?._id}`,state: { detail: 'finance' }});}}>#{item.orderNo}</TableCell>
                        <TableCell>{moment(parseInt(item.updatedDate)).format('ll')}</TableCell>
                        <TableCell>{item.memberName}</TableCell>
                        <TableCell>{item?.schoolName}<small className="d-block" style={{fontWeight:"bold"}}>{item.schStatus==="-"?"":item.schStatus}</small></TableCell>
                        <TableCell>{item.eventName}</TableCell>
                        <TableCell>{item.totalOrderRegistrant}</TableCell>
                        <TableCell style={{whiteSpace: "nowrap"}}>{"$ "+formatCurrency(item?.grandTotal)}</TableCell>
                        <TableCell style={{whiteSpace: "nowrap"}}>{"$ "+formatCurrency(item.refundAmount)}</TableCell>
                        <TableCell>{item?.paymentMethod}</TableCell>
                      </TableRow>
                    ))
                  ) : search ? (
                    <TableRow>
                      <TableCell colSpan={12}>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <p>No Orders Found</p>
                        </div>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <TableRow>
                      <TableCell colSpan={12}>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <p>No Orders Added Yet!</p>
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </div>
        </div>
        {!loading && (
          <div className="pagination-outer">
            <div className="count-selector">
              <Select
                // variant="outlined"
                onChange={handlePageInputChange}
                value={rowsPerPage}
              >
                <MenuItem value={10}>{10}</MenuItem>
                <MenuItem value={25}>{25}</MenuItem>
                <MenuItem value={50}>{50}</MenuItem>
                <MenuItem value={100}>{100}</MenuItem>
              </Select>
              <img
                className="count-arrow"
                src="../../assets/images/arrowupdown.svg"
              />
            </div>

            <Pagination
              count={Math.ceil(financeData?.financeOrder?.count / rowsPerPage)}
              page={page}
              shape="rounded"
              onChange={handleChangePage}
            />
          </div>
        )}
      </div>
    </>
  );
}
