import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { ApolloProvider } from "@apollo/client";
import client from "./graphqlConfig";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider , createTheme } from '@mui/material/styles';

const THEME = createTheme({
  typography: {
   "fontFamily": `"Lato", sans-serif`,
  //  "fontSize": 16,
   "fontWeightLight": 300,
   "fontWeightRegular": 400,
   "fontWeightMedium": 500
  }
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
    <Router >
  <ThemeProvider  theme={THEME}>
        <App />
  </ThemeProvider >
      </Router>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
