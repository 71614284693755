import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Loader } from "../../Common";
import {
  useQuery,
  useMutation,
  useReactiveVar,
  useLazyQuery,
} from "@apollo/client";
import { Box, Modal, TextField, InputLabel, Avatar, IconButton, Button, InputAdornment,OutlinedInput } from "@mui/material";
import {
  toggleSnackbar,
  schoolDtls,
  userDeatils,
} from "../../../RactiveVariables/index";
import { useForm } from "react-hook-form";
import { Close } from "@mui/icons-material";
import {CHANGE_PASSWORD} from "../../../Graphql/mutations"
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export default function ChangePassword(props){
    const user = useReactiveVar(userDeatils);
    const [showPassword, setShowPassword] = useState(false);
    const [showCPassword, setShowCPassword] = useState(false);
    const [showNPassword,setShowNPassword] = useState(false);
    const schema = yup.object().shape({
      oldpassword: yup.string().required("Old Password is required"),
      newpassword: yup.string().required("New Password is required").matches(
        /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/,
        "Password must contain atleast 8 characters, including UPPER/lower case, numbers and special characters."
      ),
      confirmpassword: yup.string().required("Confirm Password is required").oneOf(
        [yup.ref("newpassword"), null],
        "Password and Confirm Password do not match"
      ),
    })
    const { register, handleSubmit,reset,formState } = useForm({
      resolver: yupResolver(schema),
    });
    const [changePassword] = useMutation(CHANGE_PASSWORD,{
      onCompleted:(res)=>{
        props.setOpenPw(false)
        reset();
    }});
    const { errors } = formState;
    const  onSubmit=(params)=>{
      try {

        changePassword({ variables: {
          oldPassword: params.oldpassword,
          newPassword: params.newpassword

         } })
           .then((res) => {
             
             toggleSnackbar({
               status: true,
               message: "Password Changed successfully",
               variant: "success",
             });
           })
           .catch((error) => {
             const message = error && error.message;
             toggleSnackbar({
               status: true,
               message: message,
               variant: "error",
             });
           });
       } catch (error) {
         const message = error && error.message;
         toggleSnackbar({
           status: true,
           message: message,
           variant: "error",
         });
       }
   }
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
      };
      const handleClickShowPassword = (type) => {
        if (type === "confirm") setShowCPassword(!showCPassword);
        else if( type==="confirmNew") setShowNPassword(!showNPassword)
        else setShowPassword(!showPassword);
      };
      const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };
    return(
        <div>
            <Modal
        className="modal-outer"
        open={props.openPw}
        onClose={() => {props.setOpenPw(false);reset();}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Box sx={style} className="register-modal">
          {/* <IconButton onClick={() => {props.setOpenPw(false);reset();}}>
          <Close />
        </IconButton> */}
          <h1 className="modal-title">{props.title}</h1>
          <div className="mt-4">
          <div>
                <InputLabel className="filter-label" id="type">
                  Old Password
                </InputLabel>
                <OutlinedInput
                  style={{ width: "100%" }}
                  variant="outlined"
                  {...register("oldpassword")}
                  error={errors.oldpassword}
                  type={showCPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={()=>handleClickShowPassword("confirm")}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showCPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {errors && errors?.["oldpassword"] && (
                  <p className="errorMessage text-danger">
                    {errors?.oldpassword?.message}
                  </p>
                )}
              </div>
          </div>
          <div className="mt-4">
          <div>
                <InputLabel className="filter-label" id="type">
                  New Password
                </InputLabel>
                <OutlinedInput
                  style={{ width: "100%" }}
                  variant="outlined"
                  {...register("newpassword")}
                  error={errors.newpassword}
                  type={showNPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={()=>handleClickShowPassword("confirmNew")}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showNPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {errors && errors?.["newpassword"] && (
                  <p className="errorMessage text-danger">
                    {errors?.newpassword?.message}
                  </p>
                )}
              </div>
          </div>
          <div className="mt-4">
          <div>
                <InputLabel className="filter-label" id="type">
                  Confirm Password
                </InputLabel>
                <OutlinedInput
                  style={{ width: "100%" }}
                  variant="outlined"
                  {...register("confirmpassword")}
                  error={errors.confirmpassword}
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {errors && errors?.["confirmpassword"] && (
                  <p className="errorMessage text-danger">
                    {errors?.confirmpassword?.message}
                  </p>
                )}
              </div>
          </div>
          <div className="mt-4 d-flex align-items-center justify-content-end">
          <div>
                <Button className="btn btn-orange" type="submit">
                  Change
                </Button>

          </div>
          </div>
          </Box>

          </form>
      </Modal>

        </div>
    )
}