import { React, useState } from "react";
import {
  Select,
  TextField,
  InputLabel,
  MenuItem,
  FormControl,
  Box,
  Modal,
  FormControlLabel,
  Radio,
  RadioGroup,
  Autocomplete,
  Button,
  CircularProgress,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toggleSnackbar } from "../../RactiveVariables/index";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { LIST_ALL_SCHOOL, SCHOOL_DETAILS } from "../../Graphql/queries";
import { CREATE_ORDER } from "../../Graphql/mutations";
import { useHistory,useParams } from "react-router-dom";
import moment from "moment";

export default function OrderPopup(props) {
  const [radiostatus, setRadioStatus] = useState(false);
  const [schMember, setSchMember] = useState([]);
  const { data: listSchool } = useQuery(LIST_ALL_SCHOOL,{variables:{src:'src',allSch:true},fetchPolicy:'network-only'});
  const [createOrders, { loading }] = useMutation(CREATE_ORDER);
  const [getSchoolDeatils] = useLazyQuery(SCHOOL_DETAILS);
  const [selectMember, setSelectMember] = useState("1");
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };
  const { id } = useParams();
  const history = useHistory();
  const schema = yup.object().shape({
    schoolId: yup.string().required("Organization is required"),
    orderCreatedFor: yup.string().required("Member is required"),
    orderType: yup.string().required("Please select Order Type"),
    paymentMethod: yup.string().required("Please select Payment Method"),
    // totalMemCount: yup
    // .string().when("orderType", {
    //   is: (orderType) => orderType == "Bulk",
    //   then: yup.string().required("Please select Payment Method"),
    // })
    // .test("count", "Must be a number", (value) => {
    //   if (value && isNaN(value.replace(/[-,),(, ]+/g, ""))) return false;
    //   else return true;
    // }),
    memberPrice: yup
      .string()
      .when("orderType", {
        is: (orderType) => orderType == "Bulk",
        then: yup.string().required("Please select price"),
      })
      .test("Price", "Must be a number", (value) => {
        if (value && isNaN(value.replace(/[-,),(, ]+/g, ""))) return false;
        else return true;
      })
      .test("Price", "Please enter a valid amount", (value) => {
        if (value && parseFloat(value) <= 0 ) return false;
        else return true;
      }),
  });
  const { register, handleSubmit, formState, setValue, control, reset } = useForm({
    defaultValues: {},
    resolver: yupResolver(schema),
  });
  const { errors } = formState;
  function onSubmit(params) {
    let pos = listSchool?.listSchool?.data.findIndex(e=> e._id === params.schoolId)
    if(listSchool?.listSchool?.data[pos].schStatus === "Non-Member" && (!props?.eventdetails.nonMemberPrice||props?.eventdetails.nonMemberPrice==="")){
      toggleSnackbar({
        status: true,
        message: "This event is only for Nysais members",
        variant: "error",
      });
      closeOrder();
      return false;
    }
    getSchoolDeatils({variables:{
      id:params.schoolId
    }
    }).then((schDtls)=>{
      params.orderCreatedFrom ="Nysais";
    params.eventId = id;
    params.totalPrice = 0;
    params.memberPrice = schDtls?.data?.schoolDetails?.schStatus==="Non-Member"  && params.orderType !=="Bulk" ? parseFloat(params.nonMemberPrice) : parseFloat(params.memberPrice);
    
    
    if (params?.orderType === "Bulk") {
      // params.totalPrice = parseFloat(params.totalMemCount) * parseFloat(params.memberPrice)
      params.totalPrice = parseFloat(params.memberPrice)
      params.totalMemPrice = parseFloat(params.memberPrice);
      params.grandTotal = parseFloat(params.memberPrice);
      // params.totalMemCount = parseInt(params.totalMemCount);
      // params.bulkDiscount = parseFloat(props?.eventdetails?.bulkDiscount);
      params.totalMemCount = 1;
    } else {
      params.totalMemCount = 0;
      params.totalMemPrice = 0;
      params.memberPrice= schDtls?.data?.schoolDetails?.schStatus==="Full Member" ? parseFloat(params.memberPrice) : parseFloat(params.nonMemberPrice);
      // params.nonMemberPrice= parseFloat(props?.eventdetails?.nonMemberPrice);
    }
    params.orderDate = moment().format("MM/DD/YYYY");
    
    try {

      createOrders({ variables: {data:params} })
        .then((res) => {
          setSelectMember("1");
          setSchMember([]);
          toggleSnackbar({
            status: true,
            message: "Order has been saved as draft",
            variant: "success",
          });
          history.push(`/eventregistration/${res?.data?.createOrders?._id}`);
        })
        .catch((error) => {
          const message = error && error.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (error) {
      const message = error && error.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
    })    
  }

  function closeOrder() {
    props.setRegisterOpen(false);
    setSelectMember("1")
    setSchMember([]);
    reset();
  }

  return (
    <div>
      <Modal
        className="modal-outer"
        open={props?.registeropen}
        onClose={ closeOrder }
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Box sx={style} className="register-modal">
            <h1 className="modal-title">Order Booking - Member School</h1>
            <div className="">
              <InputLabel className="form-label">
                Please Select an Organization<sup className="text-danger">*</sup>
              </InputLabel>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                error={errors.schName}
                options={
                  listSchool?.listSchool?.data
                    ? listSchool?.listSchool?.data
                    : []
                }
                getOptionLabel={(option) => option.schNameThe}
                onChange={(e, value) => {
                  register("schoolId").onChange({
                    target: { value: value?._id, name: "schoolId" },
                  });
                  setSchMember(value?.schMembers ? value?.schMembers : []);
                }}
                renderInput={(params) => <TextField placeholder="Select Organization" {...params} />}
              />
              {errors && errors?.["schoolId"] && (
                <p className="errorMessage text-danger">
                  {errors?.schoolId?.message}
                </p>
              )}
            </div>
            <div className="mt-4">
              <InputLabel className="form-label">
                Please Select Member<sup className="text-danger">*</sup>
              </InputLabel>
              <Select className={selectMember === "1" ? "placeholder-color": ""}
                variant="outlined"
                // {...register("orderCreatedFor")}
                error={errors.orderCreatedFor}
                // renderValue={(selected) => {

                //   if (selected === "1") {
                //     return <em className="placeholder-color">Placeholder</em>;
                //   }
                //   return <em className="text-success">{selected}</em>;
                //   // return selected.join(', ');
                // }}
                value = {selectMember}
                onChange={(e) => {
                  register("orderCreatedFor").onChange({target:{value:e.target.value, name:'orderCreatedFor'}}); 
                  setSelectMember(e.target.value)
                }}
              >
                 <MenuItem value="1" disabled>
                    Select Member
                  </MenuItem>
                {schMember.filter(item => item.delStatus === false).map((item) => (
                  <MenuItem value={item._id}>
                    {item.firstName + " " + item.lastName}
                  </MenuItem>
                ))}
              </Select>
              {errors && errors?.["orderCreatedFor"] && (
                <p className="errorMessage text-danger">
                  {errors?.orderCreatedFor?.message}
                </p>
              )}
            </div>
            <div className="mt-4">
              <InputLabel className="form-label">Select Order Type<sup className="text-danger">*</sup></InputLabel>
              <FormControl>
                <RadioGroup
                  className="d-flex flex-row"
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="orderType"
                  onChange={(e, value) => {register("orderType").onChange({
                    target: { value: value, name: "orderType" },
                  }); setValue("totalMemCount","");
                  setValue("memberPrice","");}}
                  
                  error={errors.orderType}
                >
                  <FormControlLabel
                    onClick={() => setRadioStatus(false)}
                    value="Normal"
                    control={<Radio />}
                    label="Normal"
                  />
                  <FormControlLabel
                    onClick={() => setRadioStatus(true)}
                    value="Bulk"
                    control={<Radio />}
                    label="Bulk"
                  />
                </RadioGroup>
              </FormControl>
              {errors && errors?.["orderType"] && (
                <p className="errorMessage text-danger">
                  {errors?.orderType?.message}
                </p>
              )}
            </div>
            <div className="mt-4">
              <InputLabel className="form-label">Payment Method<sup className="text-danger">*</sup></InputLabel>
              <FormControl>
                <RadioGroup
                  className="d-flex flex-row"
                  aria-labelledby="demo-radio-buttons-group-label1"
                  name="paymentMethod"
                  error={errors.paymentMethod}
                  onChange={(e, value) => {register("paymentMethod").onChange({
                    target: { value: value, name: "paymentMethod" },
                  });}}
                >
                  <FormControlLabel
                    value="Check"
                    control={<Radio />}
                    label="Check"
                  />
                  <FormControlLabel
                    value="Card"
                    control={<Radio />}
                    label="Card"
                  />
                </RadioGroup>
              </FormControl>
              {errors && errors?.["paymentMethod"] && (
                <p className="errorMessage text-danger">
                  {errors?.paymentMethod?.message}
                </p>
              )}
            </div>
            <div className={radiostatus === true ? "d-block": "d-none"}>
            <div className="row mt-4">
                {/* <div className="col-md-6">
                  <InputLabel className="form-label">
                    No Of attendees<sup className="text-danger">*</sup>
                  </InputLabel>
                  <Controller
                    control={control}
                    name="totalMemCount"
                    render={({ field: { onChange, value } }) => {return(
                      <TextField
                    style={{ width: "100%" }}
                    variant="outlined"
                    error={errors.totalMemCount}
                    onChange={(e) => onChange(e.target.value)}
                    value={value}
                  />
                    )}}
                  />
                  
                  {errors && errors?.["totalMemCount"] && (
                    <p className="errorMessage text-danger">
                      {errors?.totalMemCount?.message}
                    </p>
                  )}
                </div> */}
                <div className="col-md-12">
                  <InputLabel className="form-label">
                    Price<sup className="text-danger">*</sup>
                  </InputLabel>
                  <Controller
                    control={control}
                    name="memberPrice"
                    render={({ field: { onChange, value } }) => (
                      <TextField
                      className="doller-sign"
                      style={{ width: "100%" }}
                      variant="outlined"
                      error={errors.memberPrice}
                      onChange={(e) => onChange(e.target.value)}
                      value={value}
                    />
                    )}
                  />
                  {errors && errors?.["memberPrice"] && (
                    <p className="errorMessage text-danger">
                      {errors?.memberPrice?.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
            
            <button
              className="btn btn-orange font-weight-bold mt-3 w-100"
              type="submit"
            >{loading ? (
              <CircularProgress size="20px" color="inherit" />
            ) : "Confirm"}
            </button>
          </Box>
        </form>
      </Modal>
    </div>
  );
}
