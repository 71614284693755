import React ,{useState} from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Avatar,
  Pagination,
  DialogTitle,
  DialogActions,
  DialogContentText,
  DialogContent,
  MenuItem,
  Dialog,
  Select,
  TextField,
  InputLabel
} from "@mui/material";
import ETemplate from "./eTemplate";
import EDetailTemplate from "./eDetailTemplate";
import { toggleSnackbar } from "../../RactiveVariables/index";
import { useQuery,useMutation } from "@apollo/client";
import { useParams,Link,useHistory } from "react-router-dom";
import {LIST_ALL_MAIL_GROUPS} from "../../Graphql/queries"
import {CREATE_ENOTICE,UPDATE_ENOTICE} from "../../Graphql/mutations"
import { DateTimePicker } from "@mui/lab";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
export default function SendMail(props) {

  const {id} = useParams();
  const history = useHistory();
  // const [openDialog, setOpenDialog] = useState(false);
  const handleCloseDialog = () => {
    props?.setOpenDialog(false);
  };


  return (
    <div className="mail-outer-container">
      <div className="container">
         <div>
      <Dialog
        open={props?.openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="send-modal"
      >
        <DialogTitle className="d-flex justify-content-center sendmail-title" id="alert-dialog-title">
         <span >{"Review Your Campaign"}</span> 
          <span className="close-sendicon" style={{cursor:"pointer"}} onClick={()=>{props?.setOpenDialog(false);props?.setSchedule("")}}>X</span>
        </DialogTitle>
        <DialogContent className="text-center pb-0">

          <p className="mb-1">You are about to send a campaign to:</p> 
          <p className="mail-modal-text">The selected mailgroups have {props?.overviewRes?.updateENotice?.emailCnt||props?.enoticedetais?.eNoticeDetails?.emailCnt} contacts</p>
          {props?.schedule==="schedule"&&<div className="text-center pb-0 mb-3">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
          renderInput={(props) => <TextField {...props} className="date-field" />}
          label="Schedule date & time"
          value={props?.dateAndTime}
          onChange={(newValue) => {
            props?.setDateAndTime(newValue);
          }}
        />
        </LocalizationProvider>
        </div>}
        </DialogContent>
        
        <div>
        <div className="d-flex mb-4 justify-content-around top-margin align-items-center btn-section w-100">
        <Button className="btn-cancel px-4" onClick={()=>{props?.setOpenDialog(false);props?.setSchedule("")}} autoFocus>
          Cancel
          </Button>
          <div className="d-flex align-items-center">
          <Button className="btn btn-orange px-3" onClick={()=>props?.handleSendENotice()} autoFocus>
          {props?.schedule==="schedule"?"Schedule Now":"Send Now"}
          </Button>
          </div>
          </div>
        </div>
      </Dialog>
    </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="card border-0">
              <div className="card-body">
                <div className="mail-from-to">
                  <ul>
                    <li>
                      <div className="d-flex align-items-center justify-content-between w-100">
                        <div>
                          <h3>To</h3>
                          {(props?.overviewRes?.updateENotice?.toGpListDtls||props?.enoticedetais?.eNoticeDetails?.toGpListDtls)?.map((item)=>(
                            <p>
                              {item?.groupName}
                          </p>
                          ))
                          }
                         {/* {toArray&&toArray.map((item)=>(
                          <p>
                            {item?.groupName}
                          </p>
                         ))
                         } */}
                          {/* <p>
                            Assistant/Associate Heads | primary :{" "}
                            <small>35 recipients</small>
                          </p> */}
                        </div>
                        <a
                          href="javascript:;"
                          className="edit-notice"
                            onClick={() => props.setTabValue("overview")}
                        >
                          <img
                            src="../assets/images/pencil.svg"
                            alt="edit button image"
                          />
                          Edit
                        </a>
                      </div>
                    </li>
                    <li>
                      <h3>From</h3>
                      <p className="text-lowercase">{(props?.enoticedetais?.eNoticeDetails?.from)||(props?.overviewRes?.updateENotice?.from)||(props?.overviewRes?.createENotice?.from)}</p>
                    </li>
                    <li>
                      <h3>Subject</h3>
                      <p>
                        {(props?.enoticedetais?.eNoticeDetails?.subject)||(props?.overviewRes?.updateENotice?.subject)||(props?.overviewRes?.createENotice?.subject)}
                      </p>
                    </li>
                    <li>
                      <h3>Reply To</h3>
                      <p className="text-lowercase">{(props?.enoticedetais?.eNoticeDetails?.replyTo)||(props?.overviewRes?.updateENotice?.replyTo)||(props?.overviewRes?.createENotice?.replyTo)}</p>
                    </li>
                    <li>
                      <h3>BCC</h3>
                      {((props?.enoticedetais?.eNoticeDetails?.ccList)||(props?.overviewRes?.updateENotice?.ccList)||(props?.overviewRes?.createENotice?.ccList))&&
                      ((props?.enoticedetais?.eNoticeDetails?.ccList)||(props?.overviewRes?.updateENotice?.ccList)||(props?.overviewRes?.createENotice?.ccList)).map((item)=>(
                      <p className="text-lowercase">{item}</p>
                      ))}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mail-content-send">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="content-inner">
                <div className="d-flex head-part justify-content-between w-100">
                  <h3 className="enotice-title">E-Notice Preview</h3>
                  <a href="javascripy:;" 
                  className="edit-notice"
                  onClick={() => props.setTabValue("Preview & Compose")}>
                    <img
                      src="../assets/images/pencil.svg"
                      alt="edit button image"
                    />
                    Edit
                  </a>
                </div>
                <div className="mail-content">
                {(props?.enoticedetais?.eNoticeDetails?.eNoticeType==="Event")||(props?.overviewRes?.updateENotice?.eNoticeType==="Event")||(props?.overviewRes?.createENotice?.eNoticeType==="Event")?<EDetailTemplate eventdetails={props} textValue={props.eNoticeDetails?.content} />:
            <ETemplate eventdetails={props} textValue={props.eNoticeDetails?.content} />
            }
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end">
          <Button style={{ marginRight:"5px"}} className="btn btn-orange mt-3 mb-3 " onClick={()=>{(props?.overviewRes||props?.enoticedetais) && props?.setOpenDialog(true);props?.setSchedule("schedule")}}>Schedule</Button>
        <Button className="btn btn-orange mt-3 mb-3 " onClick={()=>{(props?.overviewRes||props?.enoticedetais) && props?.setOpenDialog(true)}}>Send</Button>
      </div>
        </div>
      </div>
    </div>
  );
}
