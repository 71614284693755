import React from "react";
import { userDeatils } from "../../RactiveVariables";
import { useReactiveVar } from "@apollo/client";
import { Link } from "react-router-dom";

export default function AccessDenied() {
    const user = useReactiveVar(userDeatils);
    return (
        <div className="fournotfour">
            <div className="text-center">
            <h1>Access Denied!</h1>
            <p>Please contact your admin</p>
            <Link to={user?.data?.loginType && user?.data?.loginType.toLowerCase()==="admin" ? "/users" : "/schooluser"} >Back to user list</Link>
            </div>
        </div>
    )
}