import Slider from "react-slick";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import {
  Avatar,
  Button,
} from "@mui/material";
// import rightarrow from "../assets/images/schoolDtl.svg";
import { FacebookProvider, EmbeddedPost } from "react-facebook";
import InstagramEmbed from "react-instagram-embed";
import { format } from "date-fns";
import { useQuery, useReactiveVar } from "@apollo/client";
import { SCHOOL_DETAILS } from "../../../Graphql/queries";
import { schoolDtls } from "../../../RactiveVariables";
import moment from "moment";
import { useState } from "react";
import {
  calculateDate,
  calculateDays,
  formatCurrency,
} from "../../Common/GeneralFunctions";
import EditIcon from '@mui/icons-material/Edit';
import { formatPhone } from "../../Common/GeneralFunctions"
import { useHistory, Link } from "react-router-dom";

export default function Overview(params) {
  const schoolDetails = useReactiveVar(schoolDtls);
  const { data: schoolData } = useQuery(SCHOOL_DETAILS, {
    variables: {
      id: params?.schoolId ? params?.schoolId : schoolDetails?.schoolId,
    },
    fetchPolicy: "network-only",
  });
  const [listEvent, setListEvent] = useState(true);
  const [listCareer, setListCareer] = useState(true);
  const data = schoolData?.schoolDetails;
  const leadershipMembers = schoolData?.schoolDetails?.schMembers.filter((item) => item.schVisFlg == true );
  function getWebiste() {
    let position = data?.socialMediaWeb.findIndex(
      (element) => element.title === "Website"
    );
    if (position > -1) {
      return data?.socialMediaWeb[position]?.url;
    }
  }
  const history = useHistory();
  const schoolDtlArray = [
    {
      label: "Website",
      name: "website",
      image: "schoolwebsite",
      // value: "www." + getWebiste(),
      value: data?.websiteUrl,
    },
    {
      label: "Gender",
      name: "gender",
      image: "gender",
      value: data?.schGender,
    },
    {
      label: "Region",
      name: "region",
      image: "region",
      value: data?.schRegion,
    },
    {
      label: "Sub Region",
      name: "subRegion",
      image: "subregion",
      value: data?.schSubRegion,
    },
    {
      label: "Phone",
      name: "phone",
      image: "phone",
      value: formatPhone(data?.schPhone),
    },
    {
      label: "Fax",
      name: "fax",
      image: "fax",
      value: formatPhone(data?.schFax),
    },
    {
      label: "Address",
      name: "address",
      image: "address",
      value: formatAddress(
        data?.schAddress.replace('USA',''),
        data?.schCity.replace('USA',''),
        data?.schState.replace('USA',''),
        data?.schZip
      ),
    },
  ];

  function formatAddress(addr, city, state, zip) {
    if(addr && city && state && zip)
      return addr.replace(/,/g, "")+"<br>"+city+", "+ state+" "+zip
    }
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const Leadershipsettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: leadershipMembers?.length > 1 ? 2 : 1,
    slidesToScroll: 1,
    nextArrow: <img src="../assets/images/slick-rightarrow.svg" />,
    prevArrow: <img src="../assets/images/slick-rightarrow.svg" />,
  };
  const careersettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow:
      schoolData?.schoolDetails?.careerDtls?.length +
        schoolData?.schoolDetails?.eventDtls?.length >
      1
        ? 2
        : 1,
    slidesToScroll: 1,
    nextArrow: <img src="../assets/images/slick-rightarrow.svg" />,
    prevArrow: <img src="../assets/images/slick-rightarrow.svg" />,
  };
  function filerPastEvents(events) {
    let array = [];
    if(events)
    events.forEach(element => {
      let tDate = new Date().setHours(0,0,0,0);
      let Edate = new Date(element.endDate).setHours(0,0,0,0);
      if(tDate <= Edate)
        array.push(element);
    });
    return array;
  }
  const pageUrl = window.location.pathname;
  return (
    <div className="overview">
      {schoolDetails?.schoolId && <Link className="btn edit-school-btn" to={`/addschool/${schoolDetails?.schoolId}`}>
        <EditIcon/>
        {/* Edit School Profile */}
      </Link>}
        <div className={pageUrl.includes("/schoolpreview") ? "container mt-4" : ""}>
          <div className={pageUrl.includes("/schoolpreview") ? "row" : ""}>
          <div className={pageUrl.includes("/schoolpreview") ? "col-sm-12" : ""}>
            <div className="main-banner">
              <Slider {...settings}>
                {data?.schImages && data?.schImages.length > 0 ? (
                  data?.schImages.map((item) => (
                    <div className="overview-banner-image">
                      {
                        <img
                          src={
                            item?.url
                              ? item?.url
                              : "../assets/images/bannernoimage.svg"
                          }
                        />
                      }
                    </div>
                  ))
                ) : (
                  <div className="overview-banner-image">
                    {<img src="../assets/images/bannernoimage.svg" />}
                  </div>
                )}
              </Slider>
            </div>
          </div>
          </div>
        </div>
      <div className="container">
        <div className="row school-dtl">
          <div className="col-sm-8">
            <div className="school-overview-dtl">
              <p className="school-overview-head">
                <span>About </span>
                <span className="sch-name">{data?.schNameThe}</span>
                
              </p>
              <p className="mb-0">{ReactHtmlParser(data?.schDescription)}</p>
            </div>

           {leadershipMembers?.length > 0 &&<div className="leadership mb-5">
              <p className="school-overview-head">
                <span>Our </span>Leadership
              </p>
              <div>
                <Slider {...Leadershipsettings}>
                  {leadershipMembers?.map((item) => (
                    <div className="leadership-card">
                      <div className="d-flex align-items-center">
                        <img
                          className="leader-image"
                          src={
                            item?.profileImage
                              ? item?.profileImage
                              : "../assets/images/noimage.svg"
                          }
                        />
                        <div className="leadership-disc">
                          <h4
                            className="m-0"
                            // style={{ textTransform: "capitalize" }}
                          >
                            {item?.firstName + " " + item?.lastName}
                          </h4>
                          <span>{item?.title}</span>
                        </div>
                      </div>
                      <a href={`mailto:${item?.email}`}>
                        <img src="../assets/images/leadership3.svg" />
                      </a>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>}
          </div>
          <div className="col-sm-4">
            <div className="school-dtls">
              <div className="head">
                <span>
                  {
                    schoolData?.schoolDetails?.schLogoUrl ? 
                    <img className="school-logo" src={schoolData?.schoolDetails?.schLogoUrl} /> : 
                    null
                    // <Avatar className="school-logo">{schoolData?.schoolDetails?.schName[0]}</Avatar>
                  }
                  
                  {schoolData?.schoolDetails?.schNameThe}
                </span>
              </div>
              <div>
                <ul className="list-style p-0">
                  {schoolDtlArray.map((item) => {
                    if(!item.value || item.value==="N/A")
                      return null;
                    return(
                    <li>
                      <div className="row school-dtls-list m-0">
                        <div className="col-sm-5 p-0">
                          <span>
                            <img src={`../assets/images/${item.image}.svg`} />{" "}
                            {item.label}
                          </span>
                        </div>
                        <div className="col-sm-6">
                          <span className="blue-text wrap-word">
                            {/* {item.value} */}
                            <td dangerouslySetInnerHTML={{__html: item.value}} style={{wordBreak: "break-word"}}/>
                          </span>
                        </div>
                      </div>
                    </li>
                  )})}
                </ul>
              </div>
              <div className="grade-section">
                <ul>
                  {schoolData?.schoolDetails?.schLowestGrade && schoolData?.schoolDetails?.schLowestGrade !=="N/A" && <li className="d-flex align-items-center">
                    <div className="d-flex align-items-center w-75">
                      <img src="../assets/images/lowgrade.svg" />
                      <p className="mb-0">Lowest Grade</p>
                    </div>
                    <h6 className="m-0 w-25">
                      {schoolData?.schoolDetails?.schLowestGrade}
                    </h6>
                  </li>}
                  {schoolData?.schoolDetails?.schHighestGrade && schoolData?.schoolDetails?.schHighestGrade !=="N/A" && <li className="d-flex align-items-center">
                    <div className="d-flex align-items-center w-75">
                      <img src="../assets/images/highgrade.svg" />
                      <p className="mb-0"> Highest Grade</p>
                    </div>
                    <h6 className="m-0 w-25">
                      {schoolData?.schoolDetails?.schHighestGrade}
                    </h6>
                  </li>}
                  <li className="d-flex align-items-center">
                    <div className="d-flex align-items-center w-75">
                      <img src="../assets/images/enrollment.svg" />
                      <p className="mb-0">Student Enrollment</p>
                    </div>
                    <h6 className="m-0 w-25">
                      {schoolData?.schoolDetails?.schEnrollment}
                    </h6>
                  </li>
                </ul>
                {/* <a className="btn apply-btn" href="javascript:;">
                  Contact Us
                </a> */}
              </div>
            </div>
          </div>
          {/* <div className="col-sm-12">
            <div className="career-event-section">
              <p className="heading-career">Careers & Events</p>
              <div>
                <Slider {...careersettings}>
                  {schoolData?.schoolDetails?.careerDtls.map((item) => (
                    <div className="careerevent-card justify-content-between">
                      <div className="d-flex align-items-center">
                        <h3>{item.title}</h3>
                        <div className="d-flex align-items-center">
                          <div className="d-flex align-items-center date-outer">
                            <img src="../assets/images/calendar1.svg" />{" "}
                            <p className="mb-0">
                              {moment(new Date(item.createdDate)).format(
                                "MMM D, YYYY"
                              )}
                            </p>
                          </div>
                          <div className="d-flex align-items-center date-outer">
                            <img src="../assets/images/briefcase.svg" />{" "}
                            <p className="mb-0">{item.careerType}</p>
                          </div>
                        </div>
                      </div>
                      <a className="btn btn-outline-apply" href="javascript:;">
                        Apply
                      </a>
                    </div>
                  ))}
                  {schoolData?.schoolDetails?.eventDtls.map((item) => (
                    <div className="careerevent-card justify-content-between">
                      <div className="d-flex align-items-center">
                        <h3>{item.title}</h3>
                        <div className="d-flex align-items-center">
                          <div className="d-flex align-items-center date-outer">
                            <img src="../assets/images/calendar1.svg" />{" "}
                            <p className="mb-0">
                              {moment(new Date(item.startDate)).format(
                                "MMM D, YYYY"
                              )}
                            </p>
                          </div>
                          <div className="d-flex align-items-center date-outer">
                            <img src="../assets/images/briefcase.svg" />{" "}
                            <p className="mb-0">{item.eventType}</p>
                          </div>
                        </div>
                      </div>
                      <a className="btn btn-outline-apply" href="javascript:;">
                        View All
                      </a>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div> */}
          <div className="col-sm-12">
            <div className="career-event-section">
              { filerPastEvents(schoolData?.schoolDetails?.eventDtls).length > 0 && 
                <div className="section-event">
                <div className="d-flex align-items-center head">
                  <p className="heading-career">Events</p>
                </div>

                {filerPastEvents(schoolData?.schoolDetails?.eventDtls)
                  .slice(
                    0,
                    listEvent === true
                      ? "2"
                      : schoolData?.schoolDetails?.eventDtls.length
                  )
                  .map((item) => (
                    <div className="careerevent-card">
                      <div className="row align-items-center">
                        <div className="col-sm-4">
                          <h3><Link to={"/schooleventpreview/"+item?._id}>{item.title}</Link></h3>
                        </div>
                        <div className="col-sm-5 d-flex justify-content-center">
                          <div>
                            <p className="date-label">
                              {moment(new Date(item.startDate)).format(
                                "MMM D, YYYY"
                              )===moment(new Date(item.endDate)).format(
                                "MMM D, YYYY"
                              ) ? moment(new Date(item.startDate)).format(
                                "MMM D, YYYY"
                              ) :moment(new Date(item.startDate)).format(
                                "MMM D, YYYY"
                              ) + " to " + moment(new Date(item.endDate)).format(
                                "MMM D, YYYY"
                              )}
                            </p>
                            <div className="d-flex align-items-center date-outer justify-content-center">
                              <img src="../assets/images/clock-icon.svg" />
                              <p className="mb-0">
                                <b>
                                {moment(item.startDate).format(
                                    "h.mm A"
                                  )}{" "}
                                  to{" "}
                                  {moment(item.endDate).format(
                                    "h.mm A"
                                  )}
                                </b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-3 d-flex align-items-start justify-content-end">
                          <a
                            className="btn btn-outline-apply"
                            href={"/schooleventpreview/"+item?._id}
                          >
                            View
                          </a>
                        </div>
                      </div>
                    </div>
                  ))}
                {schoolData?.schoolDetails?.eventDtls.length > 2 && (
                  <div className="text-end">
                    <a
                      onClick={() => setListEvent(!listEvent)}
                      href="javscript:;"
                      className="viewall-btn"
                    >
                      {listEvent === true ? "View All" : "View Less"}
                    </a>
                  </div>
                )}
              </div>}
              {
                schoolData?.schoolDetails?.careerDtls.length > 0 && 
                
              <div className="section-career mt-3">
                <div className="d-flex align-items-center head">
                  <p className="heading-career">Careers</p>
                </div>
                {schoolData?.schoolDetails?.careerDtls
                  .slice(
                    0,
                    listCareer === true
                      ? "2"
                      : schoolData?.schoolDetails?.careerDtls.length
                  )
                  .map((item) => (
                    <div className="careerevent-card">
                      <div className="row align-items-center">
                        <div className="col-sm-4">
                        <h3><Link to={"/schoolcareerpreview/"+item?._id}>{item.title}</Link></h3>
                          {/* <Link to={"/schoolcareerpreview/"+item._id}><h3>{item.title}</h3></Link>  */}
                        </div>
                        <div className="col-sm-5 d-flex justify-content-center">
                          <div>
                            <p className="date-label">
                              {/* Next{" "}
                              {calculateDays(
                                moment(new Date()),
                                moment(
                                  calculateDate(
                                    item.createdDate,
                                    item.expVal,
                                    item.expType
                                  ),
                                  "MMM D, YYYY"
                                )
                              )} */}
                              {item?.hireDate}
                            </p>

                            {/* <div className="d-flex align-items-center date-outer">
                              <img src="../assets/images/doller-icon.svg" />
                              <p className="mb-0">
                                <b>
                                  ${formatCurrency(item.minSal)} - $
                                  {formatCurrency(item.maxSal)} /{item.salType}
                                </b>
                              </p>
                            </div> */}
                          </div>
                        </div>
                        <div className="col-sm-3 d-flex align-items-start justify-content-end">
                        <Link 
                        className="btn btn-outline-apply" 
                        to={"/schoolcareerpreview/"+item?._id}>
                          View
                        </Link>
                          {/* <a
                            className="btn btn-outline-apply"
                            href="javascript:;"
                          >
                            Apply
                          </a> */}
                        </div>
                      </div>
                    </div>
                  ))}
                  {
                    schoolData?.schoolDetails?.careerDtls.length > 2 &&
                <div className="text-end">
                  <a
                    onClick={() => setListCareer(!listCareer)}
                    href="javscript:;"
                    className="viewall-btn"
                  >
                    {listCareer === true ? "View All" : "View Less"}
                  </a>
                </div>
                  }

              </div>
              }
            </div>
          </div>
          {/* <div className="col-sm-12">
            <div className="career-event-section">
              {schoolData?.schoolDetails?.careerDtls?.length > 0 && (
                <div className="section-career">
                  <div className="d-flex align-items-center head">
                    <img src="../assets/images/careerimg.svg" />
                    <p className="heading-career">Careers</p>
                  </div>
                  <div className="career-table">
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Position</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Date posted</TableCell>
                            <TableCell>Hiring Period</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {schoolData?.schoolDetails?.careerDtls.map((item) => (
                            <TableRow>
                              <TableCell>{item.title}</TableCell>
                              <TableCell>{item.careerType}</TableCell>
                              <TableCell>
                                {moment(new Date(item.createdDate)).format(
                                  "MMM D, yyyy"
                                )}
                              </TableCell>
                              <TableCell>{item?.hireDate}</TableCell>
                              <TableCell>
                                <a
                                  className="btn btn-outline-apply"
                                  href="javascript:;"
                                >
                                  Apply
                                </a>
                              </TableCell>
                            </TableRow>
                          ))}
                          {schoolData?.schoolDetails?.eventDtls.map((item) => (
                          <TableRow>
                            <TableCell>{item.title}</TableCell>
                            <TableCell>{item.eventType}</TableCell>
                            <TableCell>
                              {moment(new Date(item.startDate)).format(
                                "ddd,MMM,DD"
                              )}
                            </TableCell>
                            <TableCell>-</TableCell>
                            <TableCell>
                              <a
                                className="btn btn-outline-apply"
                                href="javascript:;"
                              >
                                Apply
                              </a>
                            </TableCell>
                          </TableRow>
                        ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              )}
              {schoolData?.schoolDetails?.eventDtls.length > 0 && (
                <div className="section-event">
                  <div className="d-flex align-items-center head">
                    <img src="../assets/images/careerimg.svg" />
                    <p className="heading-career">Upcoming Events</p>
                  </div>
                  <div className="row">
                    {schoolData?.schoolDetails?.eventDtls.map((item) => (
                      <div className="col-sm-4">
                        <div className="card card-events">
                          <div className="top-sec">
                            <span className="badge">{item.eventType}</span>
                            <img
                              className="img-fluid"
                              src={item?.banner.length > 0 ?  item?.banner[0].url : "../assets/images/noimage.svg"}
                            />
                            <div className="down-sec">
                              <div>
                                <h3>Slots left: 12</h3>
                                <p>Closes in 1d I 6h I 33m</p>
                              </div>
                              <a
                                className="btn btn-warning"
                                href="javascript:;"
                              >
                                Register
                              </a>
                            </div>
                          </div>
                          <div className="card-body">
                            <h3 className="event-headtitle">{item.title}</h3>
                            <div className="dateantime">
                              <div className="date">
                                <img src="../assets/images/roundcalender.svg" />
                                <span>
                                  {" "}
                                  {moment(new Date(item.startDate)).format(
                                    "MMM D, yyyy"
                                  )}
                                </span>
                              </div>
                              <div className="time">
                                <img src="../assets/images/roundclock.svg" />
                                <span>
                                  {moment(item.startTime, "HH.mm").format(
                                    "h:mm A"
                                  )}
                                </span>
                              </div>
                            </div>
                            <p className="desc">
                              CLorem ipsum dolor sit amet, consectetur
                              adipiscing elit. Quisque vel sem vitae felis
                              dictum tincidunt. Sed egestas eu nisi id volutpat.
                              Pellentesque massa lorem, ultricies eget semper
                              id, tempus at leo.
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                    <div className="col-sm-4">
                    <div className="card card-events">
                      <div className="top-sec">
                        <span className="badge">eSeminar</span>
                        <img
                          className="img-fluid"
                          src="../assets/images/eventcardimg.svg"
                        />
                        <div className="down-sec">
                          <div>
                            <h3>Slots left: 12</h3>
                            <p>Closes in 1d I 6h I 33m</p>
                          </div>
                          <a className="btn btn-warning" href="javascript:;">
                            Register
                          </a>
                        </div>
                      </div>
                      <div className="card-body">
                        <h3 className="event-headtitle">
                          Enabling Their Best: Counteracting Stereotype Threat
                          in Adolescents - A 2-Part series
                        </h3>
                        <div className="dateantime">
                          <div className="date">
                            <img src="../assets/images/roundcalender.svg" />
                            <span>Wed,Oct 27</span>
                          </div>
                          <div className="time">
                            <img src="../assets/images/roundclock.svg" />
                            <span>Wed,Oct 27</span>
                          </div>
                        </div>
                        <p className="desc">
                          CLorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Quisque vel sem vitae felis dictum tincidunt.
                          Sed egestas eu nisi id volutpat. Pellentesque massa
                          lorem, ultricies eget semper id, tempus at leo.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="card card-events">
                      <div className="top-sec">
                        <span className="badge">eSeminar</span>
                        <img
                          className="img-fluid"
                          src="../assets/images/eventcardimg.svg"
                        />
                        <div className="down-sec">
                          <div>
                            <h3>Slots left: 12</h3>
                            <p>Closes in 1d I 6h I 33m</p>
                          </div>
                          <a className="btn btn-warning" href="javascript:;">
                            Register
                          </a>
                        </div>
                      </div>
                      <div className="card-body">
                        <h3 className="event-headtitle">
                          Enabling Their Best: Counteracting Stereotype Threat
                          in Adolescents - A 2-Part series
                        </h3>
                        <div className="dateantime">
                          <div className="date">
                            <img src="../assets/images/roundcalender.svg" />
                            <span>Wed,Oct 27</span>
                          </div>
                          <div className="time">
                            <img src="../assets/images/roundclock.svg" />
                            <span>Wed,Oct 27</span>
                          </div>
                        </div>
                        <p className="desc">
                          CLorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Quisque vel sem vitae felis dictum tincidunt.
                          Sed egestas eu nisi id volutpat. Pellentesque massa
                          lorem, ultricies eget semper id, tempus at leo.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="card card-events">
                      <div className="top-sec">
                        <span className="badge">eSeminar</span>
                        <img
                          className="img-fluid"
                          src="../assets/images/eventcardimg.svg"
                        />
                        <div className="down-sec">
                          <div>
                            <h3>Slots left: 12</h3>
                            <p>Closes in 1d I 6h I 33m</p>
                          </div>
                          <a className="btn btn-warning" href="javascript:;">
                            Register
                          </a>
                        </div>
                      </div>
                      <div className="card-body">
                        <h3 className="event-headtitle">
                          Enabling Their Best: Counteracting Stereotype Threat
                          in Adolescents - A 2-Part series
                        </h3>
                        <div className="dateantime">
                          <div className="date">
                            <img src="../assets/images/roundcalender.svg" />
                            <span>Wed,Oct 27</span>
                          </div>
                          <div className="time">
                            <img src="../assets/images/roundclock.svg" />
                            <span>Wed,Oct 27</span>
                          </div>
                        </div>
                        <p className="desc">
                          CLorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Quisque vel sem vitae felis dictum tincidunt.
                          Sed egestas eu nisi id volutpat. Pellentesque massa
                          lorem, ultricies eget semper id, tempus at leo.
                        </p>
                      </div>
                    </div>
                  </div>
                  </div>
                </div>
              )}
            </div>
          </div> */}
          {/* <div className="col-sm-6">
            <div className="card border-0 facebook">
              <div className="card-title">
                <img src="../assets/images/facebook1.svg" />
                <a className="btn btn-facebook" href="javascript:;">
                  Follow
                </a>
              </div>
              <div className="card-body">
                <FacebookProvider appId="921201001964201">
                  <EmbeddedPost href="https://www.facebook.com/HubSpire/photos/a.1066715800053655/4538252682899932/" />
                </FacebookProvider>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="card insta">
              <div className="card-title">
                <img src="../assets/images/insta.svg" />
                <div className="d-flex align-items-center">
                  <span>@Collegiateschool</span>
                  <a className="btn btn-insta" href="javascript:;">
                    Follow
                  </a>
                </div>
              </div>
              <div className="card-body">
                <InstagramEmbed
                  url="https://www.instagram.com/p/CX6eR9RlM5k/"
                  clientAccessToken="123|456"
                  maxWidth={320}
                  hideCaption={false}
                  containerTagName="div"
                  protocol=""
                  injectScript
                  onLoading={() => {}}
                  onSuccess={() => {}}
                  onAfterRender={() => {}}
                  onFailure={() => {}}
                />
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
