import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import { useQuery, useReactiveVar } from "@apollo/client";
import { schoolDtls } from "../../../RactiveVariables";
import { CAREER_DETAILS } from "../../../Graphql/queries";
import ReactHtmlParser from "react-html-parser";
import { Box, Typography } from "@mui/material";
import EventHeader from "../../Common/EventHeader";
import { Loader } from "../../Common";
import { formatCurrency }  from "../../Common/GeneralFunctions";

export default function EventPreview(props) {
  const { id } = useParams();
  const schoolDetails = useReactiveVar(schoolDtls);
  const { data: careerData,loading } = useQuery(CAREER_DETAILS, {
    fetchPolicy:"network-only",
    variables: { id: id },
  });
  const careerDetails = careerData?.careerDetails;
  return (
    <div>
      <EventHeader
        // title={careerDetails?.jobNumber ? `JOB #${careerDetails?.jobNumber}` : null}
        title={"Career"}
        schoolCareerpreview
        schoolId = {careerDetails?.schoolId}
        id={id}
      />
      {!loading && 
      <div className="preview-outer pt-40">
        <div className="container">
          <div className="row">
            <div className="col-sm-8">
              <div className="card preview-card border-0 mb-4">
                <div className="card-body">
                  <h2 className="main-title">{careerDetails?.title}</h2>
                  <p>{ReactHtmlParser(careerDetails?.description)}</p>
                  {/* <div className="disc-tile">
                    <h2>DESCRIPTION</h2>
                    
                  </div> */}
                  {/* <div className="disc-tile">
                      <h2>DISCLAIMER</h2>
                      <p>
                        "Lorem ipsum dolor sit amet, consectetur adipiscing
                        elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua. Ut enim ad minim veniam, quis
                        nostrud exercitation ullamco laboris nisi ut aliquip ex
                        ea commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit
                        anim id est laborum."
                      </p>
                    </div> */}
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="card preview-card border-0 mb-4">
                <div className="card-title">
                  <h4>{`Job No #${careerDetails?.jobNumber}`}</h4>
                  <a href={`/addschoolcareer/${schoolDetails?.schoolId ? schoolDetails?.schoolId : careerDetails?.schoolId}||${id}`}>
                    <img src="../assets/images/previewedit.svg" />
                  </a>
                </div>
                <div className="card-body py-0">
                  <ul className="list-elements">
                    {/* <li>
                      <p className="list-label">Position</p>
                      <p className="list-value text-capitalize">
                        <span className="badge badge-primary">
                          {careerDetails?.positionType}
                        </span>
                      </p>
                    </li> */}
                    <li>
                      <p className="list-label">Status</p>
                      <p className="list-value">
                        <span className="badge badge-primary">
                          {careerDetails?.status === true ? "Active" : "Inactive"}
                        </span>
                      </p>
                    </li>
                    <li>
                      <p className="list-label">Hiring Period</p>
                      <p className="list-value">{careerDetails?.hireDate}</p>
                    </li>
                    {careerDetails?.address&&<li className="align-items-start">
                      <p className="list-label">Address</p>
                      <p className="list-value"><div>{careerDetails?.address}</div><div>{careerDetails?.city+", "+ careerDetails?.state+" "+ careerDetails?.zip}</div></p>
                    </li>}
                    {/* <li className="align-items-start">
                      <p className="list-label">Position</p>
                      <p className="list-value">{careerDetails?.positionType}</p>
                    </li> */}
                    {careerDetails?.careerType&&<li className="align-items-start">
                      <p className="list-label">Type</p>
                      <p className="list-value">{careerDetails?.careerType}</p>
                    </li>}
                    <li>
                      <p className="list-label">Posted on</p>
                      <p className="list-value">
                        {moment(careerDetails?.createdDate)
                          .utc()
                          .format("MMM D, yyyy")}
                      </p>
                    </li>
                    {/* <li>
                      <p className="list-label">Salary Range</p>
                      <p className="list-value">
                        {`$${formatCurrency(careerDetails?.minSal)} - $${formatCurrency(careerDetails?.maxSal)} (${careerDetails?.salType})`}
                      </p>
                    </li> */}
                  </ul>
                  {(careerDetails?.careerApplyType==="Link" && careerDetails?.careerUrl) ? 
                  <a href ={careerDetails?.careerUrl} target="_blank" className="apply-btn">Apply Now
                  </a> : null
                  // <Link className="apply-btn" to="#" onClick={(e)=>{window.location= "mailto:" + careerDetails?.careerUrl.toString()}}>
                  //   Apply Now</Link>: 
                  //   careerDetails.status ? 
                  //   <Link to="#" className="apply-btn" onClick={(e)=>{window.location=careerDetails?.careerUrl.toString()}}>Apply Now
                  //   </Link> : null
                    }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>}
    </div>
  );
}
