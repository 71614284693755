import moment from "moment";
import {Link} from "react-router-dom"
import {formatDate,formatTimefromDate} from "../Common/GeneralFunctions"
import draftToHtml from 'draftjs-to-html';
import { convertToRaw } from 'draft-js';
export default function EDetailTemplate(props) {
    return (
      <div className="email-template">
        <table
          role="presentation"
          style={{ width: "600px", fontFamily: "Lato, sans-serif", margin:"auto" }}
        >
          <tr className="email-head">
            <td align="left" style={{ padding: "20px", background: "#2B54A3" }}>
              <img
                style={{ maxHeight: "50px" }}
                src="../assets/images/login-logo.svg"
                className="img-fluid login-logo"
                alt="logo"
              />
            </td>
          </tr>
          <tr className="email-body">
            {/*dangerouslySetInnerHTML={{__html: props?.textValue?._cache.html}} */}
            <td style={{ padding: "20px" }}>
              <h1 className="etem-head">{(props?.eventdetails?.enoticedetais?.eNoticeDetails?.eventDtls?.title)||(props?.eventdetails?.overviewRes?.createENotice?.eventDtls?.title)||(props?.eventdetails?.overviewRes?.updateENotice?.eventDtls?.title)}</h1>
              <h6>Target Audience: {(props?.eventdetails?.enoticedetais?.eNoticeDetails?.eventDtls?.targetAudience)||(props?.eventdetails?.overviewRes?.createENotice?.eventDtls?.targetAudience)||(props?.eventdetails?.overviewRes?.updateENotice?.eventDtls?.targetAudience)}</h6>
              {formatDate((props?.eventdetails?.enoticedetais?.eNoticeDetails?.eventDtls?.startDate)||(props?.eventdetails?.overviewRes?.createENotice?.eventDtls?.startDate)||(props?.eventdetails?.overviewRes?.updateENotice?.eventDtls?.startDate))===
              formatDate((props?.eventdetails?.enoticedetais?.eNoticeDetails?.eventDtls?.endDate)||(props?.eventdetails?.overviewRes?.createENotice?.eventDtls?.endDate)||(props?.eventdetails?.overviewRes?.updateENotice?.eventDtls?.endDate))?
              (<ul className="sub-icons ps-0">
            <li>
              <img src="../assets/images/previewcalender.svg" />
              <span>
              {
                             formatDate((props?.eventdetails?.enoticedetais?.eNoticeDetails?.eventDtls?.startDate)||(props?.eventdetails?.overviewRes?.createENotice?.eventDtls?.startDate)||(props?.eventdetails?.overviewRes?.updateENotice?.eventDtls?.startDate)) === 
                             formatDate((props?.eventdetails?.enoticedetais?.eNoticeDetails?.eventDtls?.endDate)||(props?.eventdetails?.overviewRes?.createENotice?.eventDtls?.endDate)||(props?.eventdetails?.overviewRes?.updateENotice?.eventDtls?.endDate)) ? 
                             formatDate((props?.eventdetails?.enoticedetais?.eNoticeDetails?.eventDtls?.endDate)||(props?.eventdetails?.overviewRes?.createENotice?.eventDtls?.endDate)||(props?.eventdetails?.overviewRes?.updateENotice?.eventDtls?.endDate)) : 
                             formatDate((props?.eventdetails?.enoticedetais?.eNoticeDetails?.eventDtls?.startDate)||(props?.eventdetails?.overviewRes?.createENotice?.eventDtls?.startDate)||(props?.eventdetails?.overviewRes?.updateENotice?.eventDtls?.startDate))
                             + " to " + formatDate((props?.eventdetails?.enoticedetais?.eNoticeDetails?.eventDtls?.endDate)||(props?.eventdetails?.overviewRes?.createENotice?.eventDtls?.endDate)||(props?.eventdetails?.overviewRes?.updateENotice?.eventDtls?.endDate))
                          }
              {/* {
                             moment((props?.eventdetails?.enoticedetais?.eNoticeDetails?.eventDtls?.startDate)||(props?.eventdetails?.overviewRes?.createENotice?.eventDtls?.startDate)||(props?.eventdetails?.overviewRes?.updateENotice?.eventDtls?.startDate))
                             .format("MMM D, YYYY") === moment((props?.eventdetails?.enoticedetais?.eNoticeDetails?.eventDtls?.endDate)||(props?.eventdetails?.overviewRes?.createENotice?.eventDtls?.endDate)||(props?.eventdetails?.overviewRes?.updateENotice?.eventDtls?.endDate))
                             .format("MMM D, YYYY") ? moment((props?.eventdetails?.enoticedetais?.eNoticeDetails?.eventDtls?.endDate)||(props?.eventdetails?.overviewRes?.createENotice?.eventDtls?.endDate)||(props?.eventdetails?.overviewRes?.updateENotice?.eventDtls?.endDate))
                             .format("MMM D, YYYY") : moment((props?.eventdetails?.enoticedetais?.eNoticeDetails?.eventDtls?.startDate)||(props?.eventdetails?.overviewRes?.createENotice?.eventDtls?.startDate)||(props?.eventdetails?.overviewRes?.updateENotice?.eventDtls?.startDate))
                             .format("MMM D, YYYY") + " to " + moment((props?.eventdetails?.enoticedetais?.eNoticeDetails?.eventDtls?.endDate)||(props?.eventdetails?.overviewRes?.createENotice?.eventDtls?.endDate)||(props?.eventdetails?.overviewRes?.updateENotice?.eventDtls?.endDate))
                             .format("MMM D, YYYY")
                          } */}
                        </span>
            </li>
            <li>
              <img src="../assets/images/previewclock.svg" />
              <span>
              {formatTimefromDate((props?.eventdetails?.enoticedetais?.eNoticeDetails?.eventDtls?.startDate)||(props?.eventdetails?.overviewRes?.createENotice?.eventDtls?.startDate)||(props?.eventdetails?.overviewRes?.updateENotice?.eventDtls?.startDate))}{" "}
                          to{" "}
                          {formatTimefromDate((props?.eventdetails?.enoticedetais?.eNoticeDetails?.eventDtls?.endDate)||(props?.eventdetails?.overviewRes?.createENotice?.eventDtls?.endDate)||(props?.eventdetails?.overviewRes?.updateENotice?.eventDtls?.endDate))}
              {/* {moment((props?.eventdetails?.enoticedetais?.eNoticeDetails?.eventDtls?.startTime)||(props?.eventdetails?.overviewRes?.createENotice?.eventDtls?.startTime)||(props?.eventdetails?.overviewRes?.updateENotice?.eventDtls?.startTime), "HH.mm").format(
                            "h.mm A"
                          )}{" "}
                          to{" "}
                          {moment((props?.eventdetails?.enoticedetais?.eNoticeDetails?.eventDtls?.endTime)||(props?.eventdetails?.overviewRes?.createENotice?.eventDtls?.endTime)||(props?.eventdetails?.overviewRes?.updateENotice?.eventDtls?.endTime), "HH.mm").format(
                            "h.mm A"
                          )} */}
                         
                        </span>
            </li>
            <li>
              <img src="../assets/images/profilehead.svg" />
              <span>{(props?.eventdetails?.enoticedetais?.eNoticeDetails?.eventDtls?.eventType)||(props?.eventdetails?.overviewRes?.createENotice?.eventDtls?.eventType)||(props?.eventdetails?.overviewRes?.updateENotice?.eventDtls?.eventType)}</span>
            </li>
          </ul>):(
            <ul className="sub-icons ps-0">
            <li>
              <img src="../assets/images/previewcalender.svg" />
              <span>
              {
                             formatDate((props?.eventdetails?.enoticedetais?.eNoticeDetails?.eventDtls?.startDate)||(props?.eventdetails?.overviewRes?.createENotice?.eventDtls?.startDate)||(props?.eventdetails?.overviewRes?.updateENotice?.eventDtls?.startDate))+
                             " "+
                             formatTimefromDate((props?.eventdetails?.enoticedetais?.eNoticeDetails?.eventDtls?.startDate)||(props?.eventdetails?.overviewRes?.createENotice?.eventDtls?.startDate)||(props?.eventdetails?.overviewRes?.updateENotice?.eventDtls?.startDate))
                             + " to " + formatDate((props?.eventdetails?.enoticedetais?.eNoticeDetails?.eventDtls?.endDate)||(props?.eventdetails?.overviewRes?.createENotice?.eventDtls?.endDate)||(props?.eventdetails?.overviewRes?.updateENotice?.eventDtls?.endDate))+
                             " "+
                             formatTimefromDate((props?.eventdetails?.enoticedetais?.eNoticeDetails?.eventDtls?.endDate)||(props?.eventdetails?.overviewRes?.createENotice?.eventDtls?.endDate)||(props?.eventdetails?.overviewRes?.updateENotice?.eventDtls?.endDate))
                          }
              {/* {
                             moment((props?.eventdetails?.enoticedetais?.eNoticeDetails?.eventDtls?.startDate)||(props?.eventdetails?.overviewRes?.createENotice?.eventDtls?.startDate)||(props?.eventdetails?.overviewRes?.updateENotice?.eventDtls?.startDate))
                             .format("MMM D, YYYY") === moment((props?.eventdetails?.enoticedetais?.eNoticeDetails?.eventDtls?.endDate)||(props?.eventdetails?.overviewRes?.createENotice?.eventDtls?.endDate)||(props?.eventdetails?.overviewRes?.updateENotice?.eventDtls?.endDate))
                             .format("MMM D, YYYY") ? moment((props?.eventdetails?.enoticedetais?.eNoticeDetails?.eventDtls?.endDate)||(props?.eventdetails?.overviewRes?.createENotice?.eventDtls?.endDate)||(props?.eventdetails?.overviewRes?.updateENotice?.eventDtls?.endDate))
                             .format("MMM D, YYYY") : moment((props?.eventdetails?.enoticedetais?.eNoticeDetails?.eventDtls?.startDate)||(props?.eventdetails?.overviewRes?.createENotice?.eventDtls?.startDate)||(props?.eventdetails?.overviewRes?.updateENotice?.eventDtls?.startDate))
                             .format("MMM D, YYYY") + " to " + moment((props?.eventdetails?.enoticedetais?.eNoticeDetails?.eventDtls?.endDate)||(props?.eventdetails?.overviewRes?.createENotice?.eventDtls?.endDate)||(props?.eventdetails?.overviewRes?.updateENotice?.eventDtls?.endDate))
                             .format("MMM D, YYYY")
                          } */}
                        </span>
            </li>
            {/* <li>
              <img src="../assets/images/previewclock.svg" />
              <span>
              {formatTimefromDate((props?.eventdetails?.enoticedetais?.eNoticeDetails?.eventDtls?.startDate)||(props?.eventdetails?.overviewRes?.createENotice?.eventDtls?.startDate)||(props?.eventdetails?.overviewRes?.updateENotice?.eventDtls?.startDate))}{" "}
                          to{" "}
                          {formatTimefromDate((props?.eventdetails?.enoticedetais?.eNoticeDetails?.eventDtls?.endDate)||(props?.eventdetails?.overviewRes?.createENotice?.eventDtls?.endDate)||(props?.eventdetails?.overviewRes?.updateENotice?.eventDtls?.endDate))}
              {moment((props?.eventdetails?.enoticedetais?.eNoticeDetails?.eventDtls?.startTime)||(props?.eventdetails?.overviewRes?.createENotice?.eventDtls?.startTime)||(props?.eventdetails?.overviewRes?.updateENotice?.eventDtls?.startTime), "HH.mm").format(
                            "h.mm A"
                          )}{" "}
                          to{" "}
                          {moment((props?.eventdetails?.enoticedetais?.eNoticeDetails?.eventDtls?.endTime)||(props?.eventdetails?.overviewRes?.createENotice?.eventDtls?.endTime)||(props?.eventdetails?.overviewRes?.updateENotice?.eventDtls?.endTime), "HH.mm").format(
                            "h.mm A"
                          )}
                         
                        </span>
            </li> */}
            <li>
              <img src="../assets/images/profilehead.svg" />
              <span>{(props?.eventdetails?.enoticedetais?.eNoticeDetails?.eventDtls?.eventType)||(props?.eventdetails?.overviewRes?.createENotice?.eventDtls?.eventType)||(props?.eventdetails?.overviewRes?.updateENotice?.eventDtls?.eventType)}</span>
            </li>
          </ul>
          )}
          <p className="etem-para" dangerouslySetInnerHTML={{__html:props.textValue}}>
              {/* {< dangerouslySetInnerHTML={{__html:props?.textValue?._cache.html}} />} */}
              </p>
              {props?.eventdetails?.tabValue==="Schedule/Send"&&<p className="etem-para" dangerouslySetInnerHTML={{__html:(props?.eventdetails?.overviewRes?.updateENotice?.content)?(props?.eventdetails?.overviewRes?.updateENotice?.content):(props?.eventdetails?.enoticedetais?.eNoticeDetails?.content)||(props?.eventdetails?.overviewRes?.createENotice?.content)}}>
              {/* {<td dangerouslySetInnerHTML={{__html:props?.eventdetails?.enoticedetais?.eNoticeDetails?.content||props?.eventdetails?.overviewRes?.updateENotice?.content||props?.eventdetails?.overviewRes?.createENotice?.content}} />} */}
              {/* {(props?.eventdetails?.enoticedetais?.eNoticeDetails?.content)||(props?.eventdetails?.overviewRes?.updateENotice?.content)||(props?.eventdetails?.overviewRes?.createENotice?.content)} */}
              </p>}
              <p className="etem-para" dangerouslySetInnerHTML={{__html:(props?.eventdetails?.enoticedetais?.eNoticeDetails?.eventDtls?.description)||(props?.eventdetails?.overviewRes?.updateENotice?.eventDtls?.description)||(props?.eventdetails?.overviewRes?.createENotice?.eventDtls?.description)}}>
              {/* {<td dangerouslySetInnerHTML={{__html:props?.eventdetails?.enoticedetais?.eNoticeDetails?.eventDtls?.description||props?.eventdetails?.overviewRes?.updateENotice?.eventDtls?.description||props?.eventdetails?.overviewRes?.createENotice?.eventDtls?.description}} />} */}
              {/* {(props?.eventdetails?.enoticedetais?.eNoticeDetails?.eventDtls?.description)||(props?.eventdetails?.overviewRes?.updateENotice?.eventDtls?.description)||(props?.eventdetails?.overviewRes?.createENotice?.eventDtls?.description)} */}
              </p>
            {((props?.eventdetails?.enoticedetais?.eNoticeDetails?.eventDtls?.eventType)||(props?.eventdetails?.overviewRes?.createENotice?.eventDtls?.eventType)||(props?.eventdetails?.overviewRes?.updateENotice?.eventDtls?.eventType)==="In-Person")&&
            <h6>Location: {(props?.eventdetails?.enoticedetais?.eNoticeDetails?.eventDtls?.offLocDtls?.streetAdrs)||(props?.eventdetails?.overviewRes?.createENotice?.eventDtls?.offLocDtls?.streetAdrs)||(props?.eventdetails?.overviewRes?.updateENotice?.eventDtls?.offLocDtls?.streetAdrs)}</h6>}
              <h1 className="etem-head etem-head2">Speakers</h1>
              <div>
                  {((props?.eventdetails?.enoticedetais?.eNoticeDetails?.eventDtls?.spkrDtls)||(props?.eventdetails?.overviewRes?.createENotice?.eventDtls?.spkrDtls)||(props?.eventdetails?.overviewRes?.updateENotice?.eventDtls?.spkrDtls))&&
                  ((props?.eventdetails?.enoticedetais?.eNoticeDetails?.eventDtls?.spkrDtls)||(props?.eventdetails?.overviewRes?.createENotice?.eventDtls?.spkrDtls)||(props?.eventdetails?.overviewRes?.updateENotice?.eventDtls?.spkrDtls)).map((item)=>(
                   <div className="mail-spker-block clearfix">
                        <div style={{ float: "left", paddingRight: "10px" }}>
                          {item?.profileImage?<img className="spk-img" src={item?.profileImage} />:
                          <img className="spk-img" src="../assets/images/login-logo.svg" />}

                          <h4 className="spk-head mt-2 text-center">{item?.firstName+" "+item?.lastName}</h4>
                          {item?.title&&<h6 className="spk-sub text-center">{"("+item?.title+")"}</h6>}

                        </div>

                        <p className="etem-para">{item.bio}</p>
                   </div>
                  ))

                  }

              </div>
            </td>
          </tr>
          <tr
            align="center"
            className="email-footer"
            style={{ background: "#2E3B63" }}
          >
            <td style={{ padding: "20px 0" }}>
              <Link
              to= {{pathname:"https://twitter.com/nysaisnow"}} target="_blank" style={{ padding: "0 13px" }}>
                <img
                  src="../assets/images/twitter2.svg"
                  className="sm-logo"
                  alt="logo"
                />
              </Link>
              <Link to={{pathname:"https://www.facebook.com/nysaisnow/"}} target="_blank" style={{ padding: "0 13px" }}>
                <img
                  src="../assets/images/fb.svg"
                  className="sm-logo"
                  alt="logo"
                />
              </Link>
              <Link to={{pathname:"https://www.youtube.com/user/NYSAIS"}} target="_blank" style={{ padding: "0 13px" }}>
                <img
                  src="../assets/images/yt.svg"
                  className="sm-logo"
                  alt="logo"
                />
              </Link>
            </td>
          </tr>
          <tr align="center">
              <td style={{padding:"30px 20px"}}>The New York State Association of Independent Schools<br/>
              17 Elk Street (1st Floor) | Albany, NY 12207 <br/> Telephone: 518-694-5500 | Fax: 518-694-5501
              </td>
          </tr>
          <tr>
              <td style={{padding:"0 20px 30px 20px", textAlign:"center"}}>The New York State Association of Independent Schools promotes the independence,
                   well-being and public understanding of, and respect for, New York independent
                    schools and serves as an accrediting body chartered by the New York State Board of Regents.
              </td>
          </tr>
        </table>
      </div>
    );
  }
  