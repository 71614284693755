import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Switch,
    Button,
    Avatar,
    TablePagination,
    Pagination,
    MenuItem,
    Menu,
    Select,
  } from "@mui/material";
  import { useQuery, useMutation ,useLazyQuery} from "@apollo/client";
  import { LIST_USERS ,LIST_ALL_MAIL_GROUPS,MAIL_GROUP_DETAILS} from "../../Graphql/queries";
  import { DELETE_MAIL_GROUP } from "../../Graphql/mutations";
  import { useState } from "react";
  import NewsMailList from "../NewsLetter/newsMailList.js"
  import { toggleSnackbar } from "../../RactiveVariables/index";
  import { ConfirmDialog,Loader } from "../Common";
  import { Link } from "react-router-dom"
  import moment from "moment";
  export default function NewsLetter() {
    const [openAdd, setOpenAdd] = useState(false);
    const [editPage,setEditPage] =useState(true)
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [deleteId, setDeleteId] = useState(null);
    const [editId,setEditId] = useState(null);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    const getMailGroupVariables = {
      skip: (page - 1) * rowsPerPage,
      limit: rowsPerPage,
      search: search,
      category: "",
      startDate: "",
    };
    const {data,loading, refetch} = useQuery(LIST_ALL_MAIL_GROUPS,{variables:getMailGroupVariables, fetchPolicy: "network-only"})
    const [deleteMailGroups]= useMutation(DELETE_MAIL_GROUP,{ refetchQueries:['listAllMailGroups'],
      onCompleted:res=>{
        setSelectedItem(null)
      }
    })
    // const [mailGroupDetails,{data:mailGroupDetailList}] = useLazyQuery(MAIL_GROUP_DETAILS,{variables:{id:editId}})

    const handlePagination = () => {
      refetch({
        variables: getMailGroupVariables,
      });
    };
    function handleDelete() {
      deleteMailGroups({ variables: { eventIds: [deleteId] } })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Mail Group deleted successfully",
            variant: "success",
          });
          handlePagination();
        })
        .catch((error) => {
          const message = error && error.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    }
    const handleChangePage = (e, page) => {
        setPage(page);
      };
      function handlePageInputChange(event) {
        setRowsPerPage(Number(event.target.value));
        setPage(1);
      }
      const [anchorEl, setAnchorEl] = useState(null);
      const open = Boolean(anchorEl);
      const handleClick = (event, item) => {
        setSelectedItem(item);
        setAnchorEl(event.currentTarget);
      };
      const handleClose = () => {
        setAnchorEl(null);
        // setSelectedItem(null)
        // setDeleteId()
        // setEditId()
      };
      function closeConfirm() {
        setDeleteId(null);
      }
      function handleConfirm() {
        handleDelete();
        closeConfirm();
        handlePagination();
        setSelectedItem(null)
      }


      return(
        <div className="content-wrapper">
        <div className="container">
          {/* <Loader open={loading}/> */}
          <div className="row">
            <div className="col-sm-12">
              <ConfirmDialog
          open={deleteId}
          title={"Delete"}
          body={"Are you sure you want to delete this Mail Group ? "}
          closeText={"Cancel"}
          confirmText={"Delete"}
          closeConfirm={closeConfirm}
          handleConfirm={handleConfirm}
        />
        <div className="card border-0">
          <div className="card-body p-0">
            {openAdd && (
              <NewsMailList
                setSelectedItem={setSelectedItem}
                selectedItem={selectedItem}
                open={openAdd}
                setOpen={setOpenAdd}
                editPage={editPage}
                setEditPage={setEditPage}
                id={editId}
                refetch={refetch}
                setEditId={setEditId}
                // handlePagination={handlePagination}
              />
            )}
            <div className="filter-section d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <img
                  className="search-icon"
                  src="../assets/images/search.svg"
                  alt="search icon"
                />
                <input
                  className="searchbox"
                  placeholder="Search with Group Name"
                  type="text"
                  value={search}
                  onChange={(e) => {setPage(1);setSearch(e.target.value)}}
                />
              </div>
              <div className="d-flex align-items-center justify-content-center">
                <Button
                  role="button"
                  type="button"
                  className="btn add-btn"
                  style={{width: "150px"}}
                  onClick={() => {
                    setOpenAdd(true);
                    // setSelectedItem({});
                  }}
                >
                  + New Mailing Group
                </Button>
                
              </div>
            </div>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      Group Name
                      
                    </TableCell>
                    {/* <TableCell>
                      Category
                      
                    </TableCell> */}
                    <TableCell>e-Notices Member</TableCell>
                    <TableCell>
                      Last Used On
                      
                    </TableCell>
                    <TableCell>
                      Created by
                      
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody> 
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <p> Loading Mail Groups...</p>
                      </div>
                    </TableCell>
                  </TableRow>
                ) : data?.listAllMailGroups?.data && data?.listAllMailGroups?.data.length > 0 ?(
                      data?.listAllMailGroups?.data?.map(item => (
                        <TableRow >
                        <TableCell>
                          <Link to={`/userlisting/${item._id}`} className="preview-link">
                          <div className="d-flex">
                              {item?.groupName}
                          </div>
                          </Link>
                        </TableCell>
                        {/* <TableCell className="lower-case align-items-center"><div className="ms-3">n/a</div></TableCell> */}
                        {/* <TableCell></TableCell> */}
                        <TableCell><div className="ms-5">{item?.mailCount}</div></TableCell>
                        <TableCell>{moment((item?.updatedDate)).format("lll")}</TableCell>
                        <TableCell> <div className="ms-3 text-capitalize">{item?.createdByDtls?.firstName}</div></TableCell>
                        <TableCell>
                          <div className="d-flex">
                          <Button
                          id="basic-button"
                          aria-controls="basic-menu"
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          onClick={(e) => handleClick(e, item)}
                          className="p-0 action-btn"
                        >
                              <img
                                src="../assets/images/dots.svg"
                                alt="edit button image"
                              />
                            </Button>

                          </div>
                        </TableCell>
                      </TableRow>
                      ))):search ? (
                        <TableRow>
                          <TableCell colSpan={12}>
                            <div
                              style={{ display: "flex", justifyContent: "center" }}
                            >
                              <p>No Mail Groups Found</p>
                            </div>
                          </TableCell>
                        </TableRow>
                      ) : (
                        <TableRow>
                          <TableCell colSpan={12}>
                            <div
                              style={{ display: "flex", justifyContent: "center" }}
                            >
                              <p>No Mail Groups Added Yet!</p>
                            </div>
                          </TableCell>
                        </TableRow>
                      )
                      }
                </TableBody>
              </Table>
              {
            //   !loading && 
              (
                <div className="pagination-outer">
                   <div className="count-selector">
                   <Select
                    // variant="outlined"
                    onChange={handlePageInputChange}
                    value={rowsPerPage}
                  >
                    <MenuItem value={10}>{10}</MenuItem>
                    <MenuItem value={25}>{25}</MenuItem>
                    <MenuItem value={50}>{50}</MenuItem>
                    <MenuItem value={100}>{100}</MenuItem>
                  </Select>
                  <img className="count-arrow" src="../../assets/images/arrowupdown.svg" />
                  </div>
                 
                  <Pagination
                    count={Math.ceil(data?.listAllMailGroups?.count / rowsPerPage)}
                    page={page}
                    shape="rounded"
                    onChange={handleChangePage}
                  />
                </div>
              )}
            </TableContainer>
          </div>
        </div>
        <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "dropdown-button",
        }}
      >
        <MenuItem onClick={handleClose}>
          <Link
            className="actionbtn-menu"
            to='#'
            onClick={()=>{setOpenAdd(true);setEditId(selectedItem._id);setEditPage(false)}}
          >
            Edit
          </Link>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <a
            onClick={() => setDeleteId(selectedItem._id)}
            className="actionbtn-menu"
            href="javascript:;"
          >
            Delete
          </a>
        </MenuItem>
      </Menu>
            </div>
          </div>
        </div>
        
      </div>
      )
  }