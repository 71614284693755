import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  InputLabel,
  TextField,
  Drawer,
  Button,
  FormControlLabel,
  CircularProgress,
  Pagination,
  FormGroup,
  Checkbox,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { useMutation } from "@apollo/client";
import { DELETE_MAIL_LIST } from "../../Graphql/mutations";
import { toggleSnackbar } from "../../RactiveVariables/index";
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ConfirmDialog from "../Common/ConfirmDialog";

const MailResults=(props)=>{

  const [membership, setMembership] = useState('');
  const [membershipType,setMembershipType]= useState('');
  const [schools,setSchools]= useState('');
  const [region,setRegion]= useState('');
  const [position,setPosition]= useState('');
  const [deleteId, setDeleteId]= useState('');
  const [deleteMailList] = useMutation(DELETE_MAIL_LIST,{refetchQueries:['getList'],
onCompleted:()=>{
  props?.getNewMailList()
}})
  useEffect(() => {
    if(props?.listDetails)
    getNewEntry(props?.listDetails)
  },[props?.listDetails])

  function handleDelete() {
    deleteMailList({ variables: { mailListIds: [deleteId] } })
      .then((res) => {
        toggleSnackbar({
          status: true,
          message: "User deleted successfully",
          variant: "success",
        });
      })
      .catch((error) => {
        const message = error && error.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      });
  }
  function getNewEntry(params) {

        let arr = []
        if(params)
        params?.forEach(element => {
            arr.push({
              firstName:element.firstName,
              lastName:element.lastName,
              email:element.email,
              position:element.position
            })
        });
        
      props.setTypedValue(arr)
      }

  function deleteManualEntry() {
    let arr = [...props?.typedValue]
    arr.splice(deleteId-1, 1)

  props?.setTypedValue(arr)
  }
    function closeDrawer() {
      props.setTypedValue([])
        props.setOpen(false);
        // reset();
      }
      function closeConfirm() {
        setDeleteId(null);
      }
      function handleConfirm() {
        handleDelete()
        closeConfirm();
      }
    return(
        <div>
        <ConfirmDialog
        open={deleteId}
        title={"Delete"}
        body={"Are you sure you want to delete this user from the mailing list? "}
        closeText={"Cancel"}
        confirmText={"Delete"}
        closeConfirm={closeConfirm}
        handleConfirm={handleConfirm}
      />
      <Drawer anchor={"right"} open={props.open} onClose={closeDrawer}>
        <div className="sidebar-toggler" role="presentation">
          <div className="title-outer">
            <h1>
                Add New Mailing Group
            </h1>
            <div onClick={closeDrawer} className="close-icon">
              X
            </div>
          </div>
          <form
            className="adduser-form usergroup-form mt-3"
            // onSubmit={handleSubmit(onSubmit)}
          >
            <div className="row">
              <div className="col-md-12 mb-3 border-btm">
                <InputLabel className="form-label">
                Group Name<sup className="text-danger">*</sup>
                </InputLabel>
                <TextField
                  style={{ width: "100%" }}
                  variant="outlined"
                  // helperText={props.errorMessage}
                  onChange={(e)=>{props.setMailTitle(e.target.value);props.setErrorMessage(null)}}
                  value={props.mailTitle|| null}
                //   {...register("Address1")}
                //   error={errors.Address1}
                />
                {props.errorMessage && (
                  <p className="errorMessage text-danger">
                    {props.errorMessage}
                  </p>
                )}
              </div>

              <div className="col-md-6 individual-subhead">{props?.count&&props?.count+" "+"Subscribers"} </div>
              <a style={{cursor:'pointer'}} className="col-md-6 mt-3 d-flex justify-content-end back-link" onClick={() => closeDrawer()} >Back to search</a>

            </div>
            <div className="mail-outer" >
                <ul className="mail-head">
                    <li>
                    <div className="row">
                    <div className="col-md-4">Email</div>
                    <div className="col-md-4">Full Name</div>
                    <div className="col-md-3">Role</div>
                    <div className="col-md-1"></div>
                    </div>

                    </li>
                    
                </ul>
                <ul className="mail-body">
                {props?.loading?"Loading...":props?.listDetails?.length===0? "no results found":props?.listDetails?.map((item,index)=>(<li>
                    
                      <div className="row">
                    <div className="col-md-4">{item?.email}</div>
                    <div className="col-md-4">{item?.firstName+" "+item?.lastName}</div>
                    <div className="col-md-3">{item?.position}</div>
                    <div className="col-md-1" style={{cursor:"pointer"}} onClick={()=>{ setDeleteId(item?._id)}} >
                            <img
                              // style={{cursor:"pointer"}}
                              src="../assets/images/trash.svg"
                              alt="delete button"
                            />
                    </div>

                    </div>
                    
                    </li>))}
                    
                    
                </ul>
                <div className="pagination-outer">
                   {/* <div className="count-selector">
                   <Select
                    // variant="outlined"
                    onChange={handlePageInputChange}
                    value={rowsPerPage}
                  >
                    <MenuItem value={10}>{10}</MenuItem>
                    <MenuItem value={25}>{25}</MenuItem>
                    <MenuItem value={50}>{50}</MenuItem>
                    <MenuItem value={100}>{100}</MenuItem>
                  </Select>
                  <img className="count-arrow" src="../../assets/images/arrowupdown.svg" />
                  </div> */}
                 
                  <Pagination
                    count={Math.ceil(props?.count / props?.rowsPerPage)}
                    page={props?.page}
                    shape="rounded"
                    onChange={props?.handleChangePage}
                  />
                </div>
            </div>

            <div className="d-flex justify-content-between top-margin align-items-center btn-section">
              <Button className="outline-btn" onClick={() => closeDrawer()} >
                Cancel
              </Button>
              <div className="d-flex align-items-center">
                <Button  className="highlight-btn" onClick={() => {props.id?props.updateMailList():props.createMailList();}}  >
                {props?.groupUpdationLoading || props?.groupCreationLoading ? (
                    <CircularProgress size="20px" color="inherit" />
                  ) : props.id ? (
                    "Update Group"
                  ) : (
                    "Create Group"
                  )}
                  {/* {props.id?"Update Group": "Create Group"} */}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </Drawer>
    </div>
    )
}
export default MailResults;