import React, { useEffect } from "react";
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  InputLabel,
  TextField,
  Select,
  MenuItem,
  Box,
  Typography,
} from "@mui/material";
import TimePicker from '@mui/lab/TimePicker';
import EventHeader from "../../Common/EventHeader";
import {
  useMutation,
  useReactiveVar,
  useLazyQuery,
} from "@apollo/client";
import { CREATE_EVENT, UPDATE_EVENT } from "../../../Graphql/mutations";
import {
  EVENT_DETAILS,
} from "../../../Graphql/queries";
import { toggleSnackbar, schoolDtls } from "../../../RactiveVariables/index";
import moment from "moment";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import { useHistory, useParams } from "react-router-dom";
import {
  eventTypeArray,
  statusArray,
} from "../../Events/EventOptions";
import Banner from "../../Events/Addevent/Banner";
import axios from "axios"; 
import { DateTimePicker } from "@mui/lab";

export default function AddEvent(props) {
  const schoolDetails = useReactiveVar(schoolDtls);
  const [addInfo, setAddInfo] = useState([]);
  const [textEditorValue, setTextEditorValue] = useState(
    EditorState.createEmpty()
  );
  const [textDisclaimerValue, setTextDisclaimerValue] = useState(
    EditorState.createEmpty()
  );
  const [bannerImages, setBannerImages] = useState([]);
  const { id } = useParams();
  const eventId = id.split("||")[1];
  const schoolId = id.split("||")[0];
  const history = useHistory();
  const [addEvent, { loading }] = useMutation(CREATE_EVENT);
  const [editEvent, { loading: updateLoading }] = useMutation(UPDATE_EVENT);
  const schema = yup.object().shape({
    title: yup.string().required("Event Name is required"),
    // category: yup.string().required("Category is required"),
    eventType: yup.string().required("Event Type is required"),
    targetAudience: yup.string().required("Target Audience is required"),
    startDate: yup.string().required("Start date is required"),
    // startTime: yup.string().required("Start time is required"),
    endDate: yup
      .string()
      .required("End date is required")
      // .when('startDate', (startDate, schema) => {
      //   return schema.test({
      //     test: endDate => moment(startDate).isBefore(endDate) || startDate===endDate,
      //     message: "End date must be after start date"
      //   })
      // })
      ,
    // endTime: yup.string().required("End time is required"),
    description: yup.string().required("Description is required"),
    // disclaimer: yup.string().required("Disclaimer is required"),
  });

  const {
    register,
    handleSubmit,
    formState,
    reset,
    setValue: setFormValue,
    control,
  } = useForm({
    // defaultValues: { eventType: eventId === "$" ? "Remote": null },
    resolver: yupResolver(schema),
  });
  function convertDate(date){
    return new Date(date).toUTCString();
  }
  const [getEventDetails, { data: eventData }] = useLazyQuery(EVENT_DETAILS, {
    fetchPolicy:'network-only',
    onCompleted: (res) => {
      let data = res?.eventDetails;
      setFormValue("startTime", data?.startTime);
      setFormValue("endTime", data?.endTime);
      setBannerImages(data?.banner);
      if (data.description) setFormValue("description", data.description);
      if (data?.addInfo.length > 0) {
        let addInfo = [];
        data?.addInfo.forEach((element) => {
          addInfo.push({
            title: element.title,
            fileUrl: element.fileUrl,
          });
        });
        setAddInfo(addInfo);
      }
      if (data?.addInfo.length > 0) {
        let coupenCodes = [];
        data?.coupenCodes.forEach((element) => {
          coupenCodes.push({
            coupenTitle: element.coupenTitle,
            perValue: element.perValue,
          });
        });
        setFormValue("coupenCodes", coupenCodes);
      }
      setFormValue(
        "startDate",data.startDate
      );
      setFormValue("endDate", data.endDate);
      //   setFormValue("eventType", data.eventType);
      //   setFormValue("description", data.description);
      //   let speakerArray = [];
      //   data.speakerIDs.forEach((element) => {
      //     speakerArray.push({
      //       name: element.name,
      //       profileImage: element.profileImage,
      //       spkId: element.spkId,
      //     });
      //   });
      //   setOffLocationValue({
      //     locName: data?.offLocDtls?.locName,
      //     _id: data?.offLocationId,
      //   });
      let blocksFromHtml = htmlToDraft(data?.description);
      let { contentBlocks, entityMap } = blocksFromHtml;
      let contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      setTextEditorValue(EditorState.createWithContent(contentState));
      if(data?.disclaimer){
      let blocksFromHtml1 = htmlToDraft(data?.disclaimer);
      let  contentBlocks1 = blocksFromHtml1?.contentBlocks;
      let entityMap1 = blocksFromHtml1.entityMapl
      let contentState1 = ContentState.createFromBlockArray(contentBlocks1, entityMap1);
      setTextDisclaimerValue(EditorState.createWithContent(contentState1));
    }
      setFormValue("eventType",data.eventType)
    },
  });
  const eventDetails = eventData?.eventDetails;

  useEffect(() => {
    if (eventId !== "$") getEventDetails({ variables: { id: eventId } });
  }, []);

  const { errors } = formState;
  // function convertDate(str) {
  //   var date = new Date(str),
  //     mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  //     day = ("0" + date.getDate()).slice(-2);
  //   return [mnth, day, date.getFullYear()].join("/");
  // }

  function onSubmit(params) {
    let imageArray = [];
    bannerImages.forEach((element) => {
      if (element.file) {
        const response = axios.put(element.signedUrl, element.file, {
          headers: {
            "Content-Type": element?.file?.type,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "X-Requested-With",
          },
        });
        imageArray.push({
          url: element.publicUrl,
          priority: "",
          title: "",
        });
      } else {
        imageArray.push({
          url: element.url,
          priority: element.priority,
          title: element.title,
        });
      }
    });
    params["banner"] = imageArray;
    if (eventId === "$") {
      params["eventBelgsTo"] = "School";
      // params["schoolId"] = schoolId;
      params["schoolId"] = schoolDetails?.schoolId
        ? schoolDetails?.schoolId
        : schoolId;
      try {
        addEvent({ variables: { data: params } })
          .then((res) => {
            toggleSnackbar({
              // status: true,
              message: "Event added successfully",
              variant: "success",
            });
            if (schoolDetails?.schoolId) {
              history.push("/schoolevent");
            } else {
              history.push({
                pathname: `/SchoolPreview/${schoolId}`,
                selectedTab: 2,
              });
            }

            // history.push("/schoolcareer");
            // history.push({
            //   pathname: `/SchoolPreview/${schoolId}`,
            //   selectedTab: 2,
            // });
          })
          .catch((error) => {
            const message = error && error.message;
            toggleSnackbar({
              // status: true,
              message: message,
              variant: "error",
            });
          });
      } catch (error) {
        const message = error && error.message;
        toggleSnackbar({
          // status: true,
          message: message,
          variant: "error",
        });
      }
    } else {
      try {
        editEvent({ variables: { data: params, _id: eventId } })
          .then((res) => {
            toggleSnackbar({
              // status: true,
              message: "Event updated successfully",
              variant: "success",
            });
            //   history.push("/schoolevents");
            if (schoolDetails?.schoolId) {
              history.push("/schoolevent");
            } else {
              history.push({
                pathname: `/SchoolPreview/${schoolId}`,
                selectedTab: 2,
              });
            }

            // history.push({
            //   pathname: `/SchoolPreview/${schoolId}`,
            //   selectedTab: 2,
            // });
          })
          .catch((error) => {
            const message = error && error.message;
            toggleSnackbar({
              // status: true,
              message: message,
              variant: "error",
            });
          });
      } catch (error) {
        const message = error && error.message;
        toggleSnackbar({
          // status: true,
          message: message,
          variant: "error",
        });
      }
    }
  }
  function onChangeEditor(value) {
    // if (value.toString("html") === "<p><br></p>") {
    //   registerDescription.onChange({
    //     target: { value: "", name: "description" },
    //   });
    // } else {
    //   registerDescription.onChange({
    //     target: { value: value.toString("html"), name: "description" },
    //   });
    // }
    setTextEditorValue(value);
    registerDescription.onChange({
      target: { value: draftToHtml(convertToRaw(value.getCurrentContent())), name: "description" },
    });
  }
  function onChangeDisclaimer(value) {
    // if (value.toString("html") === "<p><br></p>") {
    //   registerDisclaimer.onChange({
    //     target: { value: "", name: "disclaimer" },
    //   });
    // } else {
    //   registerDisclaimer.onChange({
    //     target: { value: value.toString("html"), name: "disclaimer" },
    //   });
    // }
    setTextDisclaimerValue(value);
    registerDisclaimer.onChange({
      target: { value: draftToHtml(convertToRaw(value.getCurrentContent())), name: "disclaimer" },
    });
  }

  const registerDescription = register("description");
  const registerDisclaimer = register("disclaimer");
  function handleInfoSubmit(params) {
    setAddInfo([...addInfo, params]);
  }
  const toolbarConfig = {
    display: [
      "INLINE_STYLE_BUTTONS",
      "BLOCK_TYPE_BUTTONS",
      "LINK_BUTTONS",
      "HISTORY_BUTTONS",
    ],
    INLINE_STYLE_BUTTONS: [
      { label: "Bold", style: "BOLD", className: "custom-css-class" },
      { label: "Italic", style: "ITALIC" },
      { label: "StrikeThrough", style: "STRIKETHROUGH" },
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: "UL", style: "unordered-list-item" },
      { label: "OL", style: "ordered-list-item" },
    ],
  };
  return (
    <div className="fullwidth-body">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {(eventId === "$" || eventDetails) && (
          <form
            className="adduser-form"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
          >
            <EventHeader
              title={eventId === "$" ? "Create Event" : "Edit Event"}
              addschoolevents
              saveBtnLoading={loading || updateLoading}
              schoolId={schoolId}
              backurl = "/schoolevent"
            />
            <div className="event-outer">
              <div className="container">
                <div className="row">
                  <div className="col-sm-8">
                    <div className="card mb-4">
                      <div className="card-title">
                        <img src="../../assets/images/Summary.svg" />
                        Event Summary
                      </div>
                      <div className="card-body">
                        <div className="row row-set">
                          <div className="col-md-9">
                            <InputLabel className="form-label">
                            Event Name<sup className="text-danger">*</sup>
                            </InputLabel>
                            <TextField
                              style={{ width: "100%" }}
                              variant="outlined"
                              {...register("title")}
                              error={errors.title}
                              defaultValue={eventDetails?.title}
                            />
                            {errors && errors?.["title"] && (
                              <p className="errorMessage text-danger">
                                {errors?.title?.message}
                              </p>
                            )}
                          </div>
                          {/* <div className="col-md-3">
                            <InputLabel className="form-label">
                              Category
                            </InputLabel>
                            <Select
                              className="w-100"
                              variant="outlined"
                              {...register("category")}
                              error={errors.category}
                              defaultValue={eventDetails?.category}
                            >
                              {categoryArray.map((item) => (
                                <MenuItem value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {errors && errors?.["category"] && (
                              <p className="errorMessage text-danger">
                                {errors?.category?.message}
                              </p>
                            )}
                          </div> */}
                          <div className="col-md-3">
                            <InputLabel className="form-label">Event Type<sup className="text-danger">*</sup></InputLabel>
                            <Select
                              className="w-100"
                              variant="outlined"
                              error={errors.eventType}
                              {...register("eventType")}
                              defaultValue={
                                eventDetails?.eventType
                              }
                              onChange={(e) => {
                                register("eventType").onChange(e);
                                setFormValue("location", null);
                              }}
                            >
                              {eventTypeArray.map((item) => (
                                <MenuItem value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {errors && errors?.["eventType"] && (
                              <p className="errorMessage text-danger">
                                {errors?.eventType?.message}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-md-12">
                            <InputLabel className="form-label">
                              Target Audience
                              <sup className="text-danger">*</sup>
                            </InputLabel>
                            <TextField
                              style={{ width: "100%" }}
                              variant="outlined"
                              {...register("targetAudience")}
                              error={errors.targetAudience}
                              defaultValue={eventDetails?.targetAudience}
                            />
                            {errors && errors?.["targetAudience"] && (
                              <p className="errorMessage text-danger">
                                {errors?.targetAudience?.message}
                              </p>
                            )}
                          </div>
                          <div className="col-sm-12 mt-3">
                            <div className="tab-outer">
                              <InputLabel className="form-label">
                                Description
                                <sup className="text-danger">*</sup>
                              </InputLabel>
                              <Editor
                                editorState={textEditorValue}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={(value) => onChangeEditor(value)}
                              />
                              
                              {errors && errors?.["description"] && (
                                <p className="errorMessage text-danger">
                                  {errors?.description?.message}
                                </p>
                              )}
                              {/* </TabPanel> */}
                            </div>
                          </div>
                          <div className="col-sm-12 mt-3">
                            <div className="tab-outer">
                              <InputLabel className="form-label">
                                Disclaimer
                                {/* <sup className="text-danger">*</sup> */}
                              </InputLabel>
                              <Editor
                                editorState={textDisclaimerValue}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={(value) => onChangeDisclaimer(value)}
                              />
                              {/* {errors && errors?.["disclaimer"] && (
                                <p className="errorMessage text-danger">
                                  {errors?.disclaimer?.message}
                                </p>
                              )} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mb-4">
                          <Banner
                            setBannerImages={setBannerImages}
                            bannerImages={bannerImages}
                          />
                        </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="card mb-4">
                      <div className="card-title">
                        <img src="../../assets/images/calendar.svg" /> Event Details
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-12 mb-3">
                            <InputLabel className="form-label">
                              Status
                            </InputLabel>
                            <Select
                              className="w-100"
                              variant="outlined"
                              {...register("status")}
                              error={errors.status}
                              defaultValue={
                                eventId === "$" ? "Draft" : eventDetails?.status
                              }
                            >
                              {statusArray.map((item) => (
                                <MenuItem value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </div>
                          <div className="col-md-12 mb-3">
                            <Controller
                              control={control}
                              name="startDate"
                              render={({ field: { onChange, value } }) => (
                                <>
                                  <InputLabel className="form-label">
                                    Start date
                                    <sup className="text-danger">*</sup>
                                  </InputLabel>
                                  {/* <DesktopDatePicker
                                    inputFormat="MM/dd/yyyy"
                                    value={value ? value : null}
                                    renderInput={(params) => (
                                      <TextField className="date-field" {...params} />
                                    )}
                                    onChange={(e) => onChange(convertDate(e))}
                                  /> */}
                                  <DateTimePicker
                                    renderInput={(props) => <TextField {...props} className="date-field" />}
                                    value={value ? value : null}
                                    onChange={(e) => {onChange(convertDate(e));
                                      setFormValue("startTime",moment(e).format("HH:mm"))
                                      setFormValue("endDate",convertDate(e))
                                      setFormValue("endTime",moment(e).format("HH:mm"))
                                    }}
                                  />
                                  {errors && errors?.["startDate"] && (
                                    <p className="errorMessage text-danger">
                                      {errors?.startDate?.message}
                                    </p>
                                  )}
                                </>
                              )}
                            />
                          </div>
                          {/* <div className="col-md-12 mb-3">
                            <InputLabel className="form-label">
                              Start time<sup className="text-danger">*</sup>
                            </InputLabel>
                            <Controller
                              control={control}
                              name="startTime"
                              render={({ field: { onChange, value } }) => {
                                return(
                                  <TimePicker
                                    value={new Date(moment(value,"HH:mm"))}
                                    onChange={(e)=>onChange(moment(new Date(e)).format("HH:mm"))}
                                    renderInput={(params) => <TextField {...params} className="date-field"/>}
                                  />
                              )}}
                            />
                            {errors && errors?.["startTime"] && (
                              <p className="errorMessage text-danger">
                                {errors?.startTime?.message}
                              </p>
                            )}
                          </div> */}
                          <div className="col-md-12 mb-3">
                            <Controller
                              control={control}
                              name="endDate"
                              render={({ field: { onChange, value } }) => (
                                <>
                                  <InputLabel className="form-label">
                                    End date<sup className="text-danger">*</sup>
                                  </InputLabel>
                                  {/* <DesktopDatePicker
                                    inputFormat="MM/dd/yyyy"
                                    value={value ? value : null}
                                    renderInput={(params) => (
                                      <TextField className="date-field" {...params} />
                                    )}
                                    onChange={(e) => onChange(convertDate(e))}
                                  /> */}
                                  <DateTimePicker
                                    renderInput={(props) => <TextField {...props} className="date-field" />}
                                    value={value ? value : null}
                                    onChange={(e) => {onChange(convertDate(e));
                                      setFormValue("endTime",moment(e).format("HH:mm"))
                                    }}
                                  />
                                  {errors && errors?.["endDate"] && (
                                    <p className="errorMessage text-danger">
                                      {errors?.endDate?.message}
                                    </p>
                                  )}
                                </>
                              )}
                            />
                          </div>
                          {/* <div className="col-md-12 mb-3">
                            <InputLabel className="form-label">
                              End time<sup className="text-danger">*</sup>
                            </InputLabel>
                            <Controller
                              control={control}
                              name="endTime"
                              render={({ field: { onChange, value } }) => {
                                return(
                                  <TimePicker
                                    value={new Date(moment(value,"HH:mm"))}
                                    onChange={(e)=>onChange(moment(new Date(e)).format("HH:mm"))}
                                    renderInput={(params) => <TextField {...params} className="date-field"/>}
                                  />
                              )}}
                            />
                            {errors && errors?.["endTime"] && (
                              <p className="errorMessage text-danger">
                                {errors?.endTime?.message}
                              </p>
                            )}
                          </div> */}
                        </div>
                      </div>
                    </div>
                    <Controller
                      control={control}
                      name="eventType"
                      render={({ field: { onChange, value } }) => (
                        <>
                          {value === "Remote" && (
                            <div className="card mb-4">
                              <div className="card-title">
                                <img src="../../assets/images/schoollink.png" />{" "}
                                Add Meeting Link
                              </div>
                              <div className="card-body">
                                <div className="row">
                                  <div className="col-md-12 mb-3">
                                    <TextField
                                      id="schOnLocDtls"
                                      style={{ width: "100%" }}
                                      variant="outlined"
                                      {...register("schOnLocDtls")}
                                      defaultValue={eventDetails?.schOnLocDtls}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {value === "In-Person" && (
                            <div className="card mb-4">
                              <div className="card-title justify-content-between">
                                <div className="d-flex">
                                  <img src="../../assets/images/Map.svg" />{" "}
                                  Location
                                </div>
                              </div>
                              <div className="card-body">
                                <div className="row">
                                  <div className="col-md-12">
                                    <TextField
                                      id="schLocDtls"
                                      style={{ width: "100%" }}
                                      variant="outlined"
                                      {...register("schLocDtls")}
                                      defaultValue={eventDetails?.schLocDtls}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {value === "Hybrid" && (
                            <div className="card mb-4">
                              <div className="card-title justify-content-between">
                                <div className="d-flex">
                                  <img src="../../assets/images/Map.svg" />{" "}
                                  Hybrid
                                </div>
                              </div>
                              <div className="card-body">
                                <div className="row">
                                  <div className="col-md-12">
                                    <InputLabel className="form-label">
                                      Location
                                    </InputLabel>
                                    <TextField
                                      style={{ width: "100%" }}
                                      variant="outlined"
                                      {...register("schLocDtls")}
                                      defaultValue={eventDetails?.schLocDtls}
                                    />
                                  </div>
                                  <div className="col-md-12 mt-3">
                                    <InputLabel className="form-label">
                                    Add Meeting Link
                                    </InputLabel>
                                    <TextField
                                      style={{ width: "100%" }}
                                      variant="outlined"
                                      {...register("schOnLocDtls")}
                                      defaultValue={eventDetails?.schOnLocDtls}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </LocalizationProvider>
    </div>
  );
}
