import { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useMutation } from "@apollo/client";
import { FORGET_PASSSCHOOL, USER_VERIFICATION } from "../../Graphql/mutations";
import { toggleSnackbar } from "../../RactiveVariables/index";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Loader } from "../Common";
import LoginHeader from "../Common/LoginHeader";
import LoginMobHeader from "../Common/LoginMobHeader";

const UserLogin = (props) => {
  const [forgetPass, setForgetPass] = useState(false);
  const schema = yup.object().shape({
    email: yup
      .string()
      .required("Email is required")
      .email("Email must be valid"),
    password: forgetPass ? null : yup.string().required("Password is required"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  const [loginSch, { loading: schloading }] = useMutation(USER_VERIFICATION);
  const [forgotSchPassword, { loading: forgotSchLoading }] =
    useMutation(FORGET_PASSSCHOOL);

  const onSubmit = (data) => {
    if (!forgetPass) {
      try {
        loginSch({ variables: data })
          .then((res) => {
            const message = res?.data?.userVerification?.message;
            const status = res?.data?.userVerification?.status;
            toggleSnackbar({
              status: status,
              message: message,
              variant: "success",
            });
          })
          .catch((err) => {
            const message = err && err.message;
            toggleSnackbar({
              status: true,
              message: message,
              variant: "error",
            });
          });
      } catch (err) {
        const message = err && err.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      }
    } else {
      try {
        forgotSchPassword({ variables: { email: data.email } })
          .then((res) => {
            const message = res?.data?.forgotSchPassword?.message;
            toggleSnackbar({
              status: true,
              message: message,
              variant: "success",
            });
          })
          .catch((err) => {
            const message = err && err.message;
            toggleSnackbar({
              status: true,
              message: message,
              variant: "error",
            });
          });
      } catch (err) {
        const message = err && err.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      }
    }
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <div className="login-outer">
      <LoginHeader />
      <LoginMobHeader />
      <Loader open={schloading} />
      <div className="login" style={{ overflowY: "hidden", height: "auto" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 d-flex align-items-center justify-content-center">
              <div className="logo-section">
                <a href={process.env.REACT_APP_WP_URL}>
                  {" "}
                  <img
                    src="../assets/images/login-logo.svg"
                    className="img-fluid login-logo"
                    alt="logo"
                  />
                </a>
                <p>
                  NYSAIS is an advocate of the independent schools of New York
                  State, speaking for their independence, well-being, and
                  reputation before the agencies of government, the media, and
                  the public
                </p>
              </div>
            </div>
            <div className="col-lg-6 d-flex justify-content-center">
              <div className="card login-card">
                <div className="card-body">
                  <div className="login-forms">
                    <div className="main-title">
                      {!forgetPass ? (
                        <h3>Request Account Deletion</h3>
                      ) : (
                        <h3>Forgot password</h3>
                      )}
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)} noValidate>
                      <div>
                        <label className="form-label">
                          Email <sup className="text-danger">*</sup>
                        </label>
                        <input
                          className="form-control"
                          type="email"
                          autoFocus
                          error={errors?.email?.message}
                          {...register("email")}
                        />
                        {errors.email && (
                          <span className="text-danger">
                            {errors?.email?.message}
                          </span>
                        )}
                      </div>
                      {!forgetPass && (
                        <div className="mt-3">
                          <label
                            className="form-label"
                            for="standard-adornment-password"
                          >
                            Password <sup className="text-danger">*</sup>
                          </label>
                          <OutlinedInput
                            className="form-control"
                            id="standard-adornment-password"
                            type={showPassword ? "text" : "password"}
                            error={errors?.password?.message}
                            // value={values.password}
                            {...register("password")}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                          {errors.password && (
                            <span className="text-danger">
                              {errors?.password?.message}
                            </span>
                          )}
                          {/* <span className="text-end my-2 d-block text-primary cursor-pointer" onClick={() => setForgetPass(true)}>Forgot Password?</span> */}
                        </div>
                      )}
                      <div className="change-section">
                        {!forgetPass ? (
                          <>
                            <div className="d-flex w-100 justify-content-between align-items-center">
                              <span
                                className="text-end my-2 d-inline-block forgot-link cursor-pointer"
                                onClick={() => setForgetPass(true)}
                              >
                                Forgot Password?
                              </span>
                            </div>
                          </>
                        ) : (
                          <div className="my-2">
                            <span
                              className="text-end my-2 d-none text-primary cursor-pointer"
                              onClick={() => setForgetPass(false)}
                            >
                              back to login
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="text-center">
                        <Button
                          className="btn btn-primary login-btn"
                          disabled={schloading || forgotSchLoading}
                          type="submit"
                          color="primary"
                        >
                          {schloading || forgotSchLoading ? (
                            <CircularProgress color="secondary" />
                          ) : (
                            "REQUEST"
                          )}
                        </Button>
                      </div>
                      <div className="change-section">
                        {forgetPass && (
                          <span
                            className="text-end my-2 d-inline-block forgot-link cursor-pointer"
                            onClick={() => setForgetPass(false)}
                          >
                            back to login
                          </span>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {cookieVariable && <iframe src={'http://nysaiswodpress.local/cookie-page/'+'?token='+cookieVariable}></iframe>} */}
      {/* <iframe src="http://nysaiswodpress.local/cookie-page/?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MWIwZjhjNDJjNzcyNDIxOGQ5YjJhY2EiLCJ1c2VybmFtZSI6ImFudUBodWJzcGlyZS5jb20iLCJlbWFpbCI6ImFudUBodWJzcGlyZS5jb20iLCJuYW1lIjoiS2F0aGVyaW5lMSBQYXRyaWNrIiwiaWF0IjoxNjU1MjA2Njc4LCJleHAiOjE2NTUyNDk4Nzh9.pFTw_CERmKJRmF6M6OrG4JGAWlA42ZIffwmBWnkbNFI"></iframe> */}
    </div>
  );
};

export default UserLogin;
