import { useState } from "react";
import { Box, CircularProgress, Divider, Modal, TextField } from "@mui/material";
import { useMutation } from "@apollo/client";
import { REFUND_TICKET } from "../../Graphql/mutations";
import { toggleSnackbar } from "../../RactiveVariables";

export default function RefundConfirm(props) {
  const [refundText, setRefundText] = useState("");
  const [refundTicket, { loading }] = useMutation(REFUND_TICKET,{refetchQueries:["countOrders"],
    onCompleted:res=>{
      toggleSnackbar({
        status: true,
        message: res?.refundTicket.message,
        variant: res?.refundTicket.status == true ? "success" : "error",
      });
      if(res?.refundTicket.status == true){
        props.refetchTickets();
        handleClose();
      }
    }
  });
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };

  function handleRefund() {
    let data = [{
      orderId: props?.order? props?.item?._id : props?.item?.orderId,
    }];
    if(!props?.order)
      data[0].ticketId = props?.item?._id;
    refundTicket({ variables: { cancelType: props?.order ? "order" : "ticket", data } });
  }

  function handleClose() {
    props?.setOpen(false);
  }

  return (
    <div>
      <Modal
        className="modal-outer"
        open={props?.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="refund-modal">
          <div className="refund-top">
            <h1 className="modal-title">
              Do you really want to cancel this registration?
            </h1>
            <p className="modal-note">
              <span>Note: </span>{`This will lead to the cancellation of the
              ticket and will initiate a refund of $${props?.item?.grandTotal??props?.item?.pricePerTcket}`}
            </p>
          </div>
          <Divider />
          <div className="refund-bottom">
            <p className="refund-note">
              Please type in <span>REFUND</span> and confirm
            </p>

            <TextField
              className="w-100"
              placeholder="REFUND"
              value={refundText}
              onChange={(e) => setRefundText(e.target.value)}
            />
            <div className="btn-section">
              <button
                className="btn  outline-btn font-weight-bold mt-3 w-25"
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                disabled={refundText.toLowerCase() !== "refund"}
                className="btn btn-orange font-weight-bold mt-3 w-25"
                onClick={handleRefund}
              >
                {loading ? (
                  <CircularProgress size="20px" color="inherit" />
                ) : (
                  "Confirm"
                )}                
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
