import { useQuery } from "@apollo/client";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { GET_CURRENT_USER } from "../../Graphql/queries";
import {
  authVar,
  registerEvent,
  schoolDtls,
  userDeatils,
} from "../../RactiveVariables";

function CompleteDeletion(params) {
  const history = useHistory();
  const { data, loading } = useQuery(GET_CURRENT_USER, {
    fetchPolicy: "network-only",

    onError: (err) => {
      authVar({
        loaded: true,
        auth: false,
      });
      userDeatils(null);
      schoolDtls(null);
      registerEvent(null);
    },
  });
  useEffect(() => {
    setTimeout(() => {
      history.push("/schoollogin");
    }, 3000);
  }, []);
  return (
    <div>
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ marginTop: "40vh" }}
      >
        <div>
          <div>
            <img
              className="top-logo"
              src="../assets/images/logoblue.svg"
              alt="logo"
            />
          </div>
          <div className="ms-5 mt-2">Redirecting...</div>
        </div>
      </div>
    </div>
  );
}
export default CompleteDeletion;
