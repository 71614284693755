import React from "react";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { toggleSnackbar } from "../../RactiveVariables/index";
import { useReactiveVar } from "@apollo/client";

const CustomizedSnackbars = () => {
  const toggleSnackbarVar = useReactiveVar(toggleSnackbar);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") return;
    toggleSnackbar({ ...toggleSnackbarVar, status: false });
  };

  return (
    <Snackbar
      open={toggleSnackbarVar.status}
      autoHideDuration={10000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <MuiAlert
        onClose={handleClose}
        severity={toggleSnackbarVar.variant || "success"}
        elevation={6}
        variant="filled"
      >
        {toggleSnackbarVar.message}
      </MuiAlert>
    </Snackbar>
  );
};

export default CustomizedSnackbars;
