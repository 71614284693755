import React from "react";
import { useEffect, useState,useRef } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  InputLabel,
  TextField,
  Drawer,
  Button,
  FormControlLabel,
  CircularProgress,
  FormGroup,
  Checkbox,
  Autocomplete,
} from "@mui/material";
import {
  schoolList,
  regionList,
  positionList,
  membershipList,
  membershipTypeList,
} from "./options";
import {
  regionOptions,
  positionType,
  sclUserType,
  sclUserStatus,
  SchMembership,
} from "../School/SchoolOptions";
import {position}  from "../Common/Options";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import {
  useMutation,
  useQuery,
  useLazyQuery,
  useReactiveVar,
} from "@apollo/client";
import {
  CREATE_MAIL_GROUP,
  UPDATE_MAIL_GROUP,
  DELETE_MAIL_LIST,
} from "../../Graphql/mutations";
import { toggleSnackbar } from "../../RactiveVariables/index";
import {
  LIST_SCHOOL,
  GET_MAIL_RESULT,
  IMAGE_UPLOAD,
  MAIL_GROUP_DETAILS,
  LIST_ALL_SCHOOL,
  LIST_SCHOOL_MAIL,
  LIST_ALL_MAILUSERS,
} from "../../Graphql/queries";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MailResults from "./mailResults";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ConfirmDialog from "../Common/ConfirmDialog";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import axios from "axios";
import imageCompression from 'browser-image-compression';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const NewsMailList = (props) => {
  const [membership, setMembership] = useState("");
  const [schoolMember, setSchoolMember] = useState("");
  const [membershipType, setMembershipType] = useState("");
  const [schools, setSchools] = useState([]);
  const [region, setRegion] = useState([]);
  const [positionNew, setPositionNew] = useState([]);
  const [openAdd, setOpenAdd] = useState(false);
  const [listLoading, setListLoading] = useState(false)
  const [checkIndividual, setCheckIndividual] = useState(true);
  const [checkUploadList, setCheckUploadList] = useState(false);
  const [checkManual, setCheckManual] = useState(false);
  const [checkedList, setCheckedList] = useState(
    props?.selectedItem?.groupType ? props?.selectedItem?.groupType : "Filter"
  );
  const [chooseFile, setChooseFile] = useState("Choose File");
  const [slectedValue, setSelectedValue] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  // const {data:mailGroupDetailList} = useQuery(MAIL_GROUP_DETAILS,{variables:{id:props.id}})
  const [mailTitle, setMailTitle] = useState(
    props?.selectedItem?.groupName || ""
  );
  const [errorMessage, setErrorMessage] = useState(null);
  const [typedValue, setTypedValue] = useState(
    props?.selectedItem&&props?.selectedItem?.usrMailList || []
  );
const[mailList,setMailList] = useState([])
  const [publicUrl, setPublicUrl] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [openDialog,setOpenDialog] = useState(false);
  const [imageUploadLoading, setImageUploadLoading] = useState(false);
  const [page, setPage] = useState(1);
  const rowsPerPage = 10;

  const [checkData,setCheckData] = useState('')
  const schema = yup.object().shape({
    email: yup.string().required("Email is Required"),
    lastName: yup.string().required("Last Name is Required"),
    firstName: yup.string().required("First Name is Required"),
  });
  const { register, handleSubmit, formState, reset } = useForm({
    resolver: yupResolver(schema),
  });
  const { errors } = formState;
  function uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  }
  const [uuId, setUuid] = useState(uuidv4());
  const listInnerRef = useRef();
  // const{data}= useQuery(LIST_SCHOOL,{variables:{region:region[0] }})

  const schoolVariables = {
    skip: 0,
    limit: 210,
    regions: region && region[0] === "All" ? null : region,
    membership: membership,
  };
  const { data: schoolList } = useQuery(LIST_SCHOOL_MAIL, {
    variables: schoolVariables,
  });
  // const {data:schoolList} = useQuery(LIST_ALL_SCHOOL,{variables:{skip:0,limit:210,}})
  const [deleteMailList] = useMutation(DELETE_MAIL_LIST,{refetchQueries:['listAllMailLists']});
  function handleDelete() {
    deleteMailList({ variables: { mailListIds: [deleteId] } })
      .then((res) => {
        toggleSnackbar({
          status: true,
          message: "user details deleted successfully",
          variant: "success",
        });
        handlePagination();
      }, 
      deleteManualEntry(deleteId)
      )
      .catch((error) => {
        const message = error && error.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      });
  }
  const [getMailResults, { data: listResults,loading }] = useLazyQuery(
    GET_MAIL_RESULT,
    {
      variables: {
        membership: membership,
        membershipTypes:membership==="Non member"?"": membershipType,
        regions: region && region[0] === "All" ? null : region,
        schools: schools,
        memberFlag: schoolMember,
        positions: positionNew,
        udid:uuId,
      }, 
      onCompleted: (res) => {
        getNewMailList()
      },
    }
  );

  const getMailGroupVariables = {
    skip: (page - 1) * rowsPerPage,
    limit: rowsPerPage,
    // search: search,
    // mailGroupId:id,
    udid: uuId,
  };
  const [getNewMailList, {data:newMailList,fetchMore}] = useLazyQuery(LIST_ALL_MAILUSERS,{variables:getMailGroupVariables,fetchPolicy:'network-only',
    onCompleted: (res)=>{
      setListLoading(false)
      setOpenAdd(true);
      // let array;
      // if (page > 0) {
      //   array = [...mailList?.listAllMailLists?.data, ...res?.listAllMailLists?.data];
      //   setMailList({ mailList: { data: array, count: res?.listAllMailLists?.count } });
      // }
      // else
      // setMailList(res);
    }
    // onCompleted: res => {
    //       setOpenAdd(true);
    //   let array;
    //   // if(propertyList?.propertyList?.data && propertyList?.propertyList?.data.length > 0){
    //   if (page > 0) {
    //     // array = [...propertyList?.propertyList?.data, ...res?.propertyList?.data];
    //     // setPropertyList({ propertyList: { data: array, count: res?.propertyList?.count } });
    //   }
    //   else
    //     // setPropertyList(res);
    // }
  })
  const handlePagination = () => {
    fetchMore({
      variables: getMailGroupVariables,
    });
  };
  useEffect(() => {
    if(newMailList?.listAllMailLists?.data.length=== 0){
      setPage(1);
    }

  },[newMailList?.listAllMailLists?.data.length=== 0])
  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight > scrollHeight - 400) {
        if (!loading && (page + 1) * rowsPerPage < newMailList?.listAllMailLists?.count)
          setPage(page + 1);
          // if (view === "viewed"&&(!viewLoading && (pageView + 1) * rowsPerPageView < viewedList?.leadActivityList?.count))
          // setPageView(pageView + 1);
      }
    }
  };
  const schoolListData = schoolList?.listSchoolNews?.data ?? [];

  // const schoolListData= data?.listAllSchool?.data??[];
  // const listMailResults = listResults?.getList?.data;
  const listMailResults = newMailList?.listAllMailLists?.data
  const count = newMailList?.listAllMailLists?.count;
  const uplaodImageVariables = {
    originalFilename: selectedImage?.fileName,
    path: "/newsletter",
  };

  // useEffect(() => {
  //   if(listResults){
  //     getNewMailList()
  //   }
  //   // getMailResults();
  // }, [listResults]);
const getnewMailResults = ()=>{
  setListLoading(true)
  let config1 = {
    operationName: "getList",
    variables: {
      membership: membership,
      membershipTypes:membership==="Non member"?"": membershipType,
      regions: region && region[0] === "All" ? null : region,
      schools: schools,
      memberFlag: schoolMember,
      positions: positionNew,
      udid:uuId,
    },
    query: "query getList($membership: String, $membershipTypes: String, $regions: [String], $schools: [String], $memberFlag: String, $positions: [String], $udid: String) {\n  getList(\n    membership: $membership\n    membershipTypes: $membershipTypes\n    regions: $regions\n    schools: $schools\n    memberFlag: $memberFlag\n    positions: $positions\n    udid: $udid\n  ) {\n    status\n    message\n    __typename\n  }\n}",
  };
  axios
    .post(process.env.REACT_APP_API_URL+"/graphql", config1)
    .then((response) => {
      getNewMailList()
    //   let config2 = {
    //     operationName: "listAllMailLists",
    //     variables: {
    //       skip: 0,
    //       limit: 300,
    //       udid:uuId,
    //     },
    //     query:  
    //     "query listAllMailLists($search: String, $mailGroupId: String, $skip: Int, $limit: Int, $udid: String) {\n  listAllMailLists(\n    search: $search\n    mailGroupId: $mailGroupId\n    skip: $skip\n    limit: $limit\n    udid: $udid\n  ) {\n    data {\n      _id\n      firstName\n      lastName\n      email\n      role\n      position\n      mailGroupId\n      __typename\n    }\n    gpData {\n      groupName\n      __typename\n    }\n    count\n    __typename\n  }\n}",
    //   };
    //   axios
    // .post(process.env.REACT_APP_API_URL+"/graphql", config2)
    // .then((response) => {
    //   console.log("testtttttttttt",response);
    // })

    // setTimeout(function(){ getNewMailList(); }, 3000)

    })
}


  const [upload, { data: uploadImageData }] = useLazyQuery(IMAGE_UPLOAD, {
    fetchPolicy: "network-only",
    variables: uplaodImageVariables,
  });
  const [mailGroup, { loading: groupCreationLoading }] = useMutation(
    CREATE_MAIL_GROUP,
    {
      refetchQueries: ["listAllMailGroups"],
      onCompleted: (res) => {
        closeDrawer();
      },
    }
  );
  const [updateMailGroup, { loading: groupUpdationLoading }] = useMutation(
    UPDATE_MAIL_GROUP,
    {
      refetchQueries: ["listAllMailGroups"],
      onCompleted: (res) => {
        closeDrawer();
      },
    }
  );
  function closeDrawer() {
    props.setOpen(false);
    setMembership("");
    setMembershipType("");
    setSchools([]);
    setRegion([]);
    setPositionNew([]);
    setOpenAdd("");
    props.setEditId(null);
    props.setSelectedItem(null);
    props?.setEditPage(true)
  }
  const handleChangePage = (e, page) => {
    setPage(page);
  };
  const handleChangeMember = (event, value) => {
    setMembership(event.target.value);
  };
  const handleSchoolMember = (event, value) => {
    setSchoolMember(value);
  };
  const handleChangeMemberType = (event, value) => {
    setMembershipType(event.target.value);
  };
  const handleChangeSchools = (event, value) => {
    // console.log("test",value);
    let arr = [];
    value.map((item) => arr.push(item._id));
    setSchools(arr);
  };
  const handleChangeRegion = (event, value) => {
    setRegion(value);
  };
  const handleChangePosition = (event, value) => {
    setPositionNew(value);
  };
  const handleChooseFile = (event) => {
    setChooseFile(event.target.value);
  };
  const onSubmit = (params) => {
    let arr = [...typedValue];
    arr.unshift(params);

    setTypedValue(arr);
    reset();
  };

  function deleteManualEntry(item, index) {
    let arr = [...typedValue];
    if(index>=0){
      arr.splice(index, 1);
      setTypedValue(arr);
    }else{
      let position = arr.findIndex(
        (element) =>element._id == item
      );
  if(position >= -1){
    arr.splice(position, 1);

  setTypedValue(arr);
  }
    }
        
  }
  async function uploadImage(params) {
    setImageUploadLoading(true);
    if (selectedImage) {
      setPublicUrl(uploadImageData?.imageUpload?.publicUrl);
      let url = uploadImageData?.imageUpload?.signedUrl;
      const response = await axios
        .put(url, selectedImage?.file, {
          headers: {
            "Content-Type": selectedImage?.file?.type,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "X-Requested-With",
          },
        })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "File uploaded successfully",
            variant: "success",
          });
        });
    } else {
      toggleSnackbar({
        status: true,
        message: "Please choose a file",
        variant: "error",
      });
    }
    setImageUploadLoading(false);
    onSubmit(params);
  }
  // function editTypedValue(item){
  //   if(item.status)delete item.status
  //   if(item.__typename)delete item.__typename
  //   if(item._id)delete item._id
  //   return item
  // }
  // function removeCopy(items){
  //   array1 = array1.filter(val => !array2.includes(val));
  // }
  function getNewEntry(params) {
    let arr = [];
    params?.forEach((element) => {
      if (!element._id) {
        arr.push({
          firstName: element.firstName,
          lastName: element.lastName,
          email: element.email,
        });
      }
    });
    return arr;
  }

  function createMailList() {
    if (mailTitle.length === 0) {
      setErrorMessage("Enter your Group name here");
    } else {
      //   let obj= {
      //   firstName:"",
      //   lastName:"",
      //   email:"",
      // }
      // typedValue.map((item)=>{
      //   obj['firstName']= item.firstName,
      //   obj['lastName']= item.lastName,
      //   obj['email']= item.email,

      // })
      let data = {
        groupName: mailTitle,
        membership: membership,
        membershipType: membershipType,
        region: region,
        position: positionNew,
        groupType:
          checkedList === "Filter"
            ? "filter&add"
            : checkedList === "Upload"
            ? "upload"
            : "individual",
        status: true,
        description: "",
        schoolId: [],
        udid: uuId,
        uploadedFile: publicUrl,
        usrMailList: typedValue===null?[]:typedValue,
      };
      if(checkedList==="Upload"&&publicUrl===""){
        toggleSnackbar({
          status: true,
          message: "please upload a  csv file",
          variant: "success",
        });
      }else{
      try {
        mailGroup({ variables: { dataArr: data } })
          .then((res) => {
            toggleSnackbar({
              status: true,
              message: "Mail Group created successfully",
              variant: "success",
            });
          })
          .catch((error) => {
            const message = error && error.message;
            toggleSnackbar({
              status: true,
              message: message,
              variant: "error",
            });
          });
      } catch (error) {
        const message = error && error.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      }
    }
    }
  }
  function updateMailList() {
    if (mailTitle.length === 0) {
      setErrorMessage("Enter your Group name here");
    } else {
      let data = {
        groupName: mailTitle,
        membership: membership,
        membershipType: membershipType,
        region: region,
        position: positionNew,
        groupType:
          checkedList === "Filter"
            ? "filter&add"
            : checkedList === "Upload"
            ? "upload"
            : "individual",
        status: true,
        description: "",
        schoolId: [],
        uploadedFile: publicUrl,
        usrMailList: publicUrl ? [] : getNewEntry(typedValue),
      };
      try {
        updateMailGroup({ variables: { _id: props.id, dataArg: data } })
          .then((res) => {
            toggleSnackbar({
              status: true,
              message: "Mail Group updated successfully",
              variant: "success",
            });
          })
          .catch((error) => {
            const message = error && error.message;
            toggleSnackbar({
              status: true,
              message: message,
              variant: "error",
            });
          });
      } catch (error) {
        const message = error && error.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      }
    }
  }
  function handleUploadClick(event) {
    let obj = {};
    var file = event.target.files[0];
    if (
      !(
        file?.type === "text/csv"
      )
    ) {
      toggleSnackbar({
        status: true,
        message: "File does not support. You must use .csv extension",
        variant: "error",
      });
      return false;
    }
    // if (file.size > 5242880) {
    //   toggleSnackbar({
    //     status: true,
    //     message: "Please upload a file smaller than 5 MB",
    //     variant: "error",
    //   });
    //   return false;
    // }
    obj["fileName"] = file.name;
    obj["url"] = URL.createObjectURL(file);
    obj["file"] = file;
    setSelectedImage(obj);
    upload();
    
  }
  function closeConfirm() {
    setDeleteId(null);
  }
  function handleConfirm() {
    handleDelete();
    closeConfirm();
    handlePagination();
  }

  return (
    <div>
      {openAdd&&<MailResults
        open={openAdd}
        setOpen={setOpenAdd}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        setMailTitle={setMailTitle}
        listDetails={listMailResults}
        loading={loading}
        mailTitle={mailTitle}
        createMailList={createMailList}
        typedValue={typedValue}
        setTypedValue={setTypedValue}
        updateMailList={updateMailList}
        id={props.id}
        count={count}
        groupCreationLoading={groupCreationLoading}
        groupUpdationLoading={groupUpdationLoading}
        getnewMailResults={getnewMailResults}
        getNewMailList={getNewMailList}
        listInnerRef={listInnerRef}
        onScroll={onScroll}
        handleChangePage={handleChangePage}
        rowsPerPage={rowsPerPage}
        page={page}
      />}
      <ConfirmDialog
        open={deleteId}
        title={"Delete"}
        body={"Are you sure you want to delete this user from the mailing list? "}
        closeText={"Cancel"}
        confirmText={"Delete"}
        closeConfirm={closeConfirm}
        handleConfirm={handleConfirm}
      />
      <Drawer anchor={"right"} open={props.open} onClose={closeDrawer}>
        <div className="sidebar-toggler" role="presentation">
          <div className="title-outer">
            <h1>Add New Mailing Group</h1>
            <div onClick={closeDrawer} className="close-icon">
              X
            </div>
          </div>
          <div className="adduser-form usergroup-form mt-3">
            <div className="row">
              <div className="col-md-12 mb-3">
                <InputLabel className="form-label">
                  Group Name<sup className="text-danger">*</sup>
                </InputLabel>
                <TextField
                  style={{ width: "100%" }}
                  variant="outlined"
                  // helperText={errorMessage}
                  onChange={(e) => {
                    setMailTitle(e.target.value);
                    setErrorMessage(null);
                  }}
                  value={mailTitle}
                  // defaultValue={mailGroupDetailList?.mailGroupDetails?.groupName? mailGroupDetailList?.mailGroupDetails?.groupName:null}
                  //   {...register("Address1")}
                  //   error={errors.Address1}
                />
                {errorMessage && (
                  <p className="errorMessage text-danger">{errorMessage}</p>
                )}
              </div>
              {props?.editPage&&<>
              <div className="col-md-12 border-btm">
                <FormControlLabel
                  // disabled={props?.selectedItem!== null}
                  control={
                    <Checkbox
                      checked={
                        checkedList === "filter&add" || checkedList === "Filter"
                          ? true
                          : false
                      }
                      // onChange={handleChangeIndividual}
                      onChange={() => setCheckedList("Filter")}
                      icon={<RadioButtonUncheckedIcon />}
                      checkedIcon={<CheckCircleIcon />}
                    />
                  }
                  label="Filter & Add "
                />
                <FormControlLabel
                  // disabled={props?.selectedItem!== null}
                  control={
                    <Checkbox
                      checked={
                        checkedList === "upload" || checkedList === "Upload"
                          ? true
                          : false
                      }
                      // onChange={handleChangeUpload}
                      onChange={() => setCheckedList("Upload")}
                      icon={<RadioButtonUncheckedIcon />}
                      checkedIcon={<CheckCircleIcon />}
                    />
                  }
                  label="Upload List"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        checkedList === "individual" || checkedList === "Manual"
                          ? true
                          : false
                      }
                      // onChange={handleChangeManual}
                      onChange={() => setCheckedList("Manual")}
                      icon={<RadioButtonUncheckedIcon />}
                      checkedIcon={<CheckCircleIcon />}
                    />
                  }
                  label="Enter Manually"
                />
              </div>
              {(checkedList === "Filter" || checkedList === "filter&add") && (
                <div className="col-md-12">
                  <div className="row">
                    <div className="individual-subhead">Add Subscribers</div>
                    <div className="col-md-4 mb-3">
                      <Box sx={{ minWidth: 120 }}>
                        <FormControl fullWidth>
                          <label className="form-label">Membership</label>
                          {/* <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={SchMembership}
                            getOptionLabel={(option) => option}
                            // value= {}
                            onChange={handleChangeMember}
                            // sx={{ width: "100%" }}
                            renderInput={(params) => (
                              <TextField variant="outlined" {...params} />
                            )}
                          /> */}
                          <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={membership}
                    onChange={handleChangeMember}
                  >
                    <MenuItem value={"Member"}>Member</MenuItem>
                    <MenuItem value={"Non-Member"}> Non Member</MenuItem>
                  </Select>
                        </FormControl>
                      </Box>
                    </div>
                    {membership === "Member" && (
                      <div className="col-md-4 mb-3">
                        <Box sx={{ minWidth: 120 }}>
                          <FormControl fullWidth>
                            <label className="form-label">
                              Membership Type
                            </label>
                            {/* <Autocomplete
                              disablePortal
                              id="combo-box-demo"
                              options={sclUserStatus}
                              getOptionLabel={(option) => option}
                              // value= {}
                              onChange={handleChangeMemberType}
                              // sx={{ width: "100%" }}
                              renderInput={(params) => (
                                <TextField variant="outlined" {...params} />
                              )}
                            /> */}
                            <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={membershipType}
                    onChange={handleChangeMemberType}
                  >
                    {sclUserStatus?.map((item)=>(<MenuItem value={item}>{item}</MenuItem>))}

                  </Select>
                          </FormControl>
                        </Box>
                      </div>
                    )}
                    {/* <div className="col-md-4 mb-3">
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <label className="form-label">School Member</label>
                  <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={SchMembership}
                      getOptionLabel={option => option}
                      // value= {}
                      onChange={handleSchoolMember}
                      // sx={{ width: "100%" }}
                      renderInput={(params) => <TextField
                        variant="outlined" {...params} />}
                      />
                </FormControl>
              </Box>
              </div> */}
                    <div className="col-md-4 mb-3">
                      <InputLabel className="form-label">Region</InputLabel>
                      <Autocomplete
                        multiple
                        id="checkboxes-tags-demo"
                        options={regionOptions.map((item) => item)}
                        disableCloseOnSelect
                        onChange={handleChangeRegion}
                        getOptionLabel={(option) => {
                          return option;
                        }}
                        // renderOption={(option, { selected }) => {

                        //   return(
                        //   <div>
                        //     <Checkbox
                        //       icon={icon}
                        //       checkedIcon={checkedIcon}
                        //       style={{ marginRight: 8 }}
                        //       checked={selected}
                        //     />
                        //     {option.key}
                        //   </div>
                        //   )
                        // }}
                        // style={{ width: 500 }}
                        renderTags={() =>
                          region[0] === "All"
                            ? "All Regions"
                            : region.length > 0
                            ? region.length + " Region"
                            : ""
                        }
                        renderInput={(params) => {
                          return <TextField {...params} variant="outlined" />;
                        }}
                      />
                    </div>
                    <div className="col-md-4 mb-3">
                      <InputLabel className="form-label">Schools</InputLabel>
                      <Autocomplete
                        multiple
                        id="checkboxes-tags-demo"
                        options={schoolListData}
                        getOptionLabel={(option) => option?.schNameThe}
                        disableCloseOnSelect
                        onChange={handleChangeSchools}
                        // renderOption={(option, { selected }) => {

                        //   return(
                        //   <div>
                        //     <Checkbox
                        //       icon={icon}
                        //       checkedIcon={checkedIcon}
                        //       style={{ marginRight: 8 }}
                        //       checked={selected}
                        //     />
                        //     {option.key}
                        //   </div>
                        //   )
                        // }}
                        // style={{ width: 500 }}
                        renderTags={() =>
                          schools.length > 0 ? schools.length + " Schools" : ""
                        }
                        renderInput={(params) => {
                          return <TextField {...params} variant="outlined" />;
                        }}
                      />
                    </div>

                    <div className="col-md-4 mb-3">
                      <InputLabel className="form-label">Position</InputLabel>
                      <Autocomplete
                        multiple
                        id="checkboxes-tags-demo"
                        options={position.map((item) => item)}
                        disableCloseOnSelect
                        onChange={handleChangePosition}
                        getOptionLabel={(option) => {
                          return option;
                        }}
                        // renderOption={(option, { selected }) => {

                        //   return(
                        //   <div>
                        //     <Checkbox
                        //       icon={icon}
                        //       checkedIcon={checkedIcon}
                        //       style={{ marginRight: 8 }}
                        //       checked={selected}
                        //     />
                        //     {option.key}
                        //   </div>
                        //   )
                        // }}
                        // style={{ width: 500 }}
                        renderTags={() =>
                          positionNew.length > 0
                            ? positionNew.length + " Position"
                            : ""
                        }
                        renderInput={(params) => {
                          return <TextField {...params} variant="outlined" />;
                        }}
                      />
                    </div>

                    <div className="col-md-12 d-flex align-items-center justify-content-left">
                      <Button
                        className="btn btn-orange text-white"
                        onClick={() => {

                          // setSelectedValue(listMailResults);
                          getnewMailResults()
                          // .then((res)=>{
                          //   setOpenAdd(true);
                          // });
                          // setSelectedItem({});
                          // if(!loading){
                          //   setOpenAdd(true);
                          // }
                        }}
                      >
                      {listLoading? <CircularProgress size="20px" color="inherit" />:  'Get Results'}
                      </Button>
                    </div>
                  </div>
                  {props?.selectedItem?.groupType === "filter&add" && (
                    <div className=" mt-3 col-md-12">
                      <div className="mail-outer">
                        <ul className="mail-head">
                          <li>
                            <div className="row">
                              <div className="col-md-4">Email</div>
                              <div className="col-md-4">Full Name</div>
                              <div className="col-md-4"></div>
                            </div>
                          </li>
                        </ul>
                        <ul className="mail-body-manual">
                          {typedValue?.length === 0 ? (
                            "no results found"
                          ) : (
                            typedValue?.map((item, index) => (
                              <li>
                                <div className="row">
                                  <div className="col-md-4">{item?.email}</div>
                                  <div className="col-md-4">
                                    {item?.firstName + " " + item?.lastName}
                                  </div>
                                  <div className="col-md-4">
                                    <img
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        item._id
                                          ? setDeleteId(item._id)
                                          : deleteManualEntry(item, index);
                                      }}
                                      src="../assets/images/trash.svg"
                                      alt="delete button"
                                    />
                                  </div>
                                </div>
                              </li>
                            ))
                          )}
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {(checkedList === "Upload" || checkedList === "upload") && (
                <div className="col-md-12">
                  <div className="row">
                    <ul className="upload-rules">
                      <li>
                        Upload new recipients to this eNotify list. Your upload
                        file must be in a 4 coloumn tab delimited format with
                        the following :
                      </li>
                      <li>Column 1 : Email</li>
                      <li>Column 2 : First Name</li>
                      <li>Column 3 : Last Name</li>
                      <li>
                        Please include a header row with the above titles.{" "}
                        <a href="./sample.csv" download="sample.csv">
                          download a sample CSV file{" "}
                        </a>
                      </li>
                    </ul>

                    <div className="border-tp col-md-12">
                      <div className="row">
                        <div className="col-md-6 ">
                          <input
                            type={"file"}
                            id="fileToUpload"
                            accept=".csv"
                            onChange={handleUploadClick}
                            onClick={(event) => {
                              event.target.value = null;
                            }}
                          />
                        </div>
                        <div className="col-md-6 d-flex align-items-center justify-content-end">
                          <Button
                            className="btn btn-orange text-white "
                            onClick={() => {
                              uploadImage();
                              // setOpenAdd(true);
                              // setSelectedItem({});
                            }}
                          >
                            Upload
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* {props?.selectedItem?.groupType === "upload" && (
                    <div className="row">
                      <div className=" mt-3 col-md-12">
                        <div className="mail-outer">
                          <ul className="mail-head">
                            <li>
                              <div className="row">
                                <div className="col-md-4">Email</div>
                                <div className="col-md-4">Full Name</div>
                                <div className="col-md-4"></div>
                              </div>
                            </li>
                          </ul>
                          <ul className="mail-body-manual">
                            {typedValue?.lenght === 0 ? (
                              <div>no results found</div>
                            ) : (
                              typedValue?.map((item, index) => {console.log("test",item);
                                return(
                                <li>
                                  {item &&<div className="row">
                                    <div className="col-md-4">
                                      {item?.email}
                                    </div>
                                    <div className="col-md-4">
                                      {item?.firstName + " " + item?.lastName}
                                    </div>
                                    <div className="col-md-4">
                                      <img
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          item?._id
                                            ? setDeleteId(item?._id)
                                            : deleteManualEntry(item, index);
                                        }}
                                        src="../assets/images/trash.svg"
                                        alt="delete button"
                                      />
                                    </div>
                                  </div>}
                                </li>
                              )})
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  )} */}
                </div>
              )}

              {(checkedList === "Manual" || checkedList === "individual") && (
                <div className=" col-md-12">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <ul className="upload-rules row">
                      <li className="col-md-6">Enter Your Details</li>
                      <div className="col-md-6 d-flex align-items-center justify-content-end">
                        <Button
                          type="submit"
                          className="btn btn-orange text-white "
                          // onClick={() => {
                          //   setOpenAdd(true);
                          //   setSelectedItem({});
                          // }}
                        >
                          + Add
                        </Button>
                      </div>
                      <li className="col-md-4 mb-3">
                        <div>
                          <InputLabel className="form-label">
                            Email<sup className="text-danger">*</sup>
                          </InputLabel>
                          <TextField
                            style={{ width: "100%" }}
                            variant="outlined"
                            {...register("email")}
                            error={errors.email}
                          />
                          {errors && errors?.["email"] && (
                            <p className="errorMessage text-danger">
                              {errors?.email?.message}
                            </p>
                          )}
                        </div>
                      </li>
                      <li className="col-md-4 mb-3">
                        <div>
                          <InputLabel className="form-label">
                            First Name<sup className="text-danger">*</sup>
                          </InputLabel>
                          <TextField
                            style={{ width: "100%" }}
                            variant="outlined"
                            {...register("firstName")}
                            error={errors.firstName}
                          />
                          {errors && errors?.["firstName"] && (
                            <p className="errorMessage text-danger">
                              {errors?.firstName?.message}
                            </p>
                          )}
                        </div>
                      </li>
                      <li className="col-md-4 mb-3">
                        <div>
                          <InputLabel className="form-label">
                            Last Name<sup className="text-danger">*</sup>
                          </InputLabel>
                          <TextField
                            style={{ width: "100%" }}
                            variant="outlined"
                            {...register("lastName")}
                            error={errors.lastName}
                          />
                          {errors && errors?.["lastName"] && (
                            <p className="errorMessage text-danger">
                              {errors?.lastName?.message}
                            </p>
                          )}
                        </div>
                      </li>
                    </ul>

                    {typedValue?.length!==0&&<div className="border-tp col-md-12">
                      <div className="mail-outer">
                        <ul className="mail-head">
                          <li>
                            <div className="row">
                              <div className="col-md-4">Email</div>
                              <div className="col-md-4">Full Name</div>
                              <div className="col-md-4"></div>
                            </div>
                          </li>
                        </ul>
                        <ul className="mail-body-manual">
                          {(
                            typedValue?.map((item, index) => (
                              <li>
                                <div className="row">
                                  <div className="col-md-4">{item?.email}</div>
                                  <div className="col-md-4">
                                    {item?.firstName + " " + item?.lastName}
                                  </div>
                                  <div className="col-md-4">
                                    <img
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        item._id
                                          ? setDeleteId(item._id)
                                          : deleteManualEntry(item, index);
                                      }}
                                      src="../assets/images/trash.svg"
                                      alt="delete button"
                                    />
                                  </div>
                                </div>
                              </li>
                            ))
                          )}
                        </ul>
                      </div>
                    </div>}
                  </form>
                </div>
              )}
              </>}
            </div>
            
            <div className="d-flex justify-content-between top-margin align-items-center btn-section">
              <Button
                className="outline-btn"
                onClick={() => {
                  closeDrawer();
                  setSelectedImage(null);
                  setErrorMessage(null);
                }}
              >
                Cancel
              </Button>
              <div className="d-flex align-items-center">
                <Button
                  className="highlight-btn"
                  onClick={() => {
                    props?.selectedItem === null
                      ? createMailList()
                      : updateMailList();
                  }}
                >
                  {groupUpdationLoading || groupCreationLoading ? (
                    <CircularProgress size="20px" color="inherit" />
                  ) : props?.selectedItem === null ? (
                    "Create List"
                  ) : (
                    "Update List"
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};
export default NewsMailList;
