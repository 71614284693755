import AddSchoolHeader from "./AddSchoolHeader";
import MemberView from "./MemberView";
import Profile from "./Profile";
import Highlights from "./Highlights";
import Website from "./Website";
import Banner from "./Banner";
import OrganisationAdmin from "./OrganisationAdmin";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation, useLazyQuery, useQuery, useReactiveVar } from "@apollo/client";
import { CREATE_SCHOOL, UPDATE_SCHOOL, CREATE_ORDER, ADD_SCHOOL_USER, LOGIN_SCHOOL } from "../../../Graphql/mutations";
import { useHistory, useParams } from "react-router-dom";
import { useEffect } from "react";
import { SCHOOL_DETAILS, GET_ALL_SCHOOL_USER,LIST_SCHOOL, CHECK_DOMAIN } from "../../../Graphql/queries";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { toggleSnackbar, schoolDtls, registerEvent, userDeatils, authVar } from "../../../RactiveVariables/index";
import { useState } from "react";
import AddMember from "../Member/AddMember";
import axios from "axios";
import { EditorState, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import Tags from "./Tags";
import { lowestgrade } from "../SchoolOptions";
import moment from "moment";
import LogoUpload from "./LogoUplaod";
import { uuidv4 } from "../../Common/GeneralFunctions";

export default function School(props) {
  const [openAddMember, setOpenAddMember] = useState(false);
  const [defaultValue, setDefaultValue] = useState(null);
  const [bannerImages, setBannerImages] = useState([]);
  const [membersFormatedList, setMembersFormatedList] = useState([]);
  const [logoImage, setLogoImage] = useState([]);
  const [regUserImg, setRegUserImg] = useState(null);
  const schoolDetails = useReactiveVar(schoolDtls);
  const userDtls = useReactiveVar(userDeatils);
  const regEvent = useReactiveVar(registerEvent);
  const [textEditorValue, setTextEditorValue] = useState(EditorState.createEmpty());
  const [websiteFlag, setWebsiteFlag] = useState(false);
  const history = useHistory();
  const { id } = useParams();
  const [createOrders] = useMutation(CREATE_ORDER);
  const [createSchoolUsers] = useMutation(ADD_SCHOOL_USER);
  const [loginSchool] = useMutation(LOGIN_SCHOOL);
  const [uuId, setUuid] = useState(uuidv4());

  const schema = yup.object().shape({
    schName: id === "$" ? yup.string().required("School Name is required") : null,
    schAddress: yup.string().required("Address is required"),
    schCity: yup.string().required("City is required"),
    schState: yup.string().required("State is required"),
    schZip: yup
      .string()
      .required("Zip is required")
      .test("Zip", "Must be a number", (value) => {
        if (value && isNaN(value.replace(/[-]+/g, ""))) return false;
        else return true;
      })
      .test("Zip", "Must be a 5 character", (value) => {
        if (value && value.length!=5) return false;
        else return true;
      }),
    // .test("Zip", "Must be a valid number", (value) => {
    //   if (value && value.length < 6) return false;
    //   else return true;
    // }),
    schRegion: yup.string().required("please select Region"),
    schSubRegion: yup.string().required("please select Sub Region"),
    // schPhone: yup
    //   .string()
    //   .required("Phone is required")
    //   .test("phone", "Must be a number", (value) => {
    //     if (value && isNaN(value.replace(/[-,(,), ]+/g, ""))) return false;
    //     else return true;
    //   })
    //   .test("phone", "Must be a valid number", (value) => {
    //     if (value && value.length < 8) return false;
    //     else return true;
    //   }),
    // schFax: yup
    //   .string()
    //   .required("Fax is required")
    //   .test("Fax", "Must be a number", (value) => {
    //     if (value && isNaN(value.replace(/[-,(,), ]+/g, ""))) return false;
    //     else return true;
    //   })
    //   .test("Fax", "Must be a valid number", (value) => {
    //     if (value && value.length < 6) return false;
    //     else return true;
    //   }),
    schDescription: yup.string().required("Description is required"),
    schStatus: (id === "$"&&props?.location?.pathname.includes("addschool")) ? yup.string().required("please select Status") : null,
    schHighestGrade: yup.string().required("please select Highest Grade"),
    schEnrollment: yup.string()
    // .required("Enrollment is required")
    .test("schEnrollment", "Must be a number", (value) => {
      if (value && isNaN(value.replace(/[-,(,), ]+/g, ""))) return false;
      else return true;
    }),
    schLowestGrade: yup.string().required("please select Lowest Grade"),
    // socialMediaWeb: yup.string().required("Website is required"),
    socialMediaWeb: yup
      .object()
      .shape({ Website: yup.string().required("Domain is required")}),
    schMemberSince: yup
      .string()
      .nullable()
      .test("schMemberSince", "Invalid Date", (value) => {
        if (value && !moment(value,"MM/DD/YYYY").isValid()) return false;
        else return true;
      })
      .test("schMemberSince", "Future dates are not allowed ", (value) => {
        if (value && new Date().getTime() < new Date(moment(value,"MM/DD/YYYY")).getTime()) return false;
        else return true;
      }),
      member:props?.location?.pathname.includes("addnewschool") && yup.object().shape({
        firstName:yup.string().required("First Name is required"),
        lastName:yup.string().required("Last Name is required"),
        title:yup.string().required("Title is required"),
        position:yup.string().required("Position is required"),
        pronoun:yup.string().required("Pronoun is required"),
        phone:yup.string().required("Phone is required"),
        email:yup.string().required("Email is required").email(),
        // password:yup.string().required("Password is required").matches(
        //   /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/,
        //   "Password must contain atleast 8 characters, including UPPER/lower case, numbers and special characters."
        // ),
        // confirmpassword:yup.string().required("Confirm Password is required")
        // .oneOf(
        //   [yup.ref("password"), null],
        //   "Password and Confirm Password do not match"
        // ),
      })
  });

  const {
    register,
    handleSubmit,
    formState,
    reset,
    control,
    setValue,
    getValues,
    trigger,
  } = useForm({
    // defaultValues: params.defaultValue,
    resolver: yupResolver(schema),
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "schMembers",
  });
  const { data: membersList, fetchMore } = useQuery(GET_ALL_SCHOOL_USER, {
    variables: {
      schId: id === "$" ? uuId : id,
      idType: id === "$" ? "uuid" : "schoolId",
    },
    onCompleted: (res) => {
      let array = [];
      res?.listAllSchUsers?.data.forEach((element) => {
        if(element.status)
          array.push({
            _id: element._id,
            firstName: element.firstName,
            lastName: element.lastName,
            name: element?.firstName + " " + element.lastName,
            profileImage: element.profileImage,
            title: element.title,
          });
      });
      setMembersFormatedList(array);
    },
  });
  const getMoreUserList = () => {
    fetchMore({
      variables: {
        schId: id === "$" ? uuId : id,
        idType: id === "$" ? "uuid" : "schoolId",
      },
    });
  };
  const [checkDomain,{data:DomainCheckValue}] = useLazyQuery(CHECK_DOMAIN);
  const [getSchoolDetails] = useLazyQuery(SCHOOL_DETAILS, {
    fetchPolicy: "network-only",
    variables: { id: id },
    onCompleted: (res) => {
      setDefaultValue(res?.schoolDetails);
      setValue("schPhone", res?.schoolDetails?.schPhone);
      setValue("schFax", res?.schoolDetails?.schFax);
      setValue("schDescription", res?.schoolDetails?.schDescription);
      setValue("schRegion", res?.schoolDetails?.schRegion);
      setValue("schAddress", res?.schoolDetails?.schAddress);
      setBannerImages(res?.schoolDetails?.schImages);
      if(res?.schoolDetails?.schLogoUrl)
      setLogoImage([{url:res?.schoolDetails?.schLogoUrl}]);
      let webPosition = res?.schoolDetails?.socialMediaWeb.findIndex(
        (element) => element.title === "Website"
      );
      if (webPosition > -1) setValue("socialMediaWeb.Website",res?.schoolDetails?.socialMediaWeb[webPosition]?.url);
      if (res?.schoolDetails?.schMembers) {
        res?.schoolDetails?.schMembers.forEach((element) => {
          if(element.schVisFlg)
            append({
              schUsersId: element.schUsersId,
              name: element.firstName+" "+element.lastName,
              profileImage: element.profileImage,
              title: element.title,
              schUsersId: element._id,
            });
        });
      }
      setValue("schMemberSince", res?.schoolDetails?.schMemberSince);
      if (res?.schoolDetails?.schDescription)
        {
          let blocksFromHtml = htmlToDraft(res?.schoolDetails?.schDescription);
          let { contentBlocks, entityMap } = blocksFromHtml;
          let contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
          setTextEditorValue(EditorState.createWithContent(contentState));
        }
    },
  });
  useEffect(() => {
    if (id !== "$") {
      getSchoolDetails();
    } else setDefaultValue({});
  }, []);
  const [AddSchool, { loading }] = useMutation(CREATE_SCHOOL);
  const [editSchool, { loading: editLoading }] = useMutation(UPDATE_SCHOOL);
  const pageUrl = window.location.pathname;
  const { errors } = formState;

  const getTopicId = (array)=>{
    if(array){
      let id = []
      array.forEach(element => {
        id.push(element._id);
      });
      return id
    }
  }

  async function addMemberAndLogin(res,data) {
    delete data.confirmpassword;
    data["regEventId"] = regEvent?.eventId
    data["status"] = false;
    data["userGroupId"] = "Admin";
    data["userType"] = "Admin"
    if(data.massTopicsIds){
      data['massTopicsIds']= getTopicId(data.massTopicsIds)
      }
    data["moduleAcs"] = [
      {
          "title" : "SchoolProfile",
          "status" : true
      }, 
      {
          "title" : "Events",
          "status" : true
      }, 
      {
          "title" : "Careers",
          "status" : true
      }, 
      {
          "title" : "Users",
          "status" : true
      }, 
      {
          "title" : "Orders",
          "status" : true
      }
  ]
    data.schoolId = res?.data?.createSchool?._id
    if(regUserImg?.publicUrl){
      data.profileImage = regUserImg?.publicUrl;
      let url = regUserImg?.signedUrl;
      const response = await axios.put(url, regUserImg.file, {
        headers: {
          "Content-Type": regUserImg?.file?.type,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "X-Requested-With",
        },
      });
    }
    createSchoolUsers({variables:{data:data}})
    .then(()=>{
      // history.push("/")
      history.push("/schoollogin");
    })
    .catch((error) => {
      const message = error && error.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    });
    // .then((userRes)=>{
    //   loginSchool({variables:{
    //     email:data.email,
    //     password:data.password
    //   }})
    //   .then(loginRes=>{
    //     if(regEvent?.eventId){
    //         let createEventData = {
    //           "orderCreatedFrom" : "FrontEnd",
    //           "eventId" : regEvent?.eventId,
    //           "memberPrice":parseInt(regEvent?.memberPrice),
    //           "nonMemberPrice":parseInt(regEvent?.nonMemberPrice),
    //           "totalPrice" : 0,
    //           "paymentMethod" : "Card",
    //           "orderType": "Normal",
    //           "orderCreatedFor":userRes?.data?.addSchUser?._id,
    //           "schoolId":res?.data?.createSchool?._id,
    //         }
    //       createOrders({ variables: {data:createEventData} })
    //       .then((orderRes) => {
    //         toggleSnackbar({
    //           status: true,
    //           message: "Order has been saved as draft",
    //           variant: "success",
    //         });
    //         history.push(`/eventregistration/${orderRes?.data?.createOrders?._id}`)
    //       })
    //         const responseData = loginRes.data && loginRes.data.loginSch;
    //         const token = responseData.token;
    //         localStorage.setItem("@authToken", token);
    //         userDeatils({ data: loginRes?.data?.loginSch?.userNew });
    //         schoolDtls({
    //           data: loginRes?.data?.loginSch?.userNew?.schoolDetails,
    //           schoolId: loginRes?.data?.loginSch?.userNew?.schoolId,
    //         });
    //         authVar({
    //           loaded: true,
    //           auth: true,
    //         });                  
    //     }
    //     else{
    //       const responseData = loginRes.data && loginRes.data.loginSch;
    //         const token = responseData.token;
    //         localStorage.setItem("@authToken", token);
    //         userDeatils({ data: loginRes?.data?.loginSch?.userNew });
    //         schoolDtls({
    //           data: loginRes?.data?.loginSch?.userNew?.schoolDetails,
    //           schoolId: loginRes?.data?.loginSch?.userNew?.schoolId,
    //         });
    //         authVar({
    //           loaded: true,
    //           auth: true,
    //         });
    //     }
    //   })
    // })
  }

  const onSubmit = async (params) => {
    // if(params.massTopicsIds){
    //   params['massTopicsIds']= getTopicId(params.massTopicsIds)
    //   }
    if(DomainCheckValue?.checkDomain.status)
      return false;

    if(params.schName){
      params["schNameThe"]=params.the+" "+params.schName
      // let newName= params.schName.split(" ")
      // params["schNameThe"]=params.schName
      // if(newName[0]==="The")params["the"] = newName[0]
      // if(newName[0]==="The"){
      //   params["schName"] = newName.slice(1).join(' ')
      // }else {params["schName"] =params.schName}
    }
    let memberData = params?.member;
    if(params?.schEnrollment)
      params["schEnrollment"] = parseInt(params.schEnrollment)
    else
      params["schEnrollment"] = 0;
    delete params.member;
    if(props?.location?.pathname && props?.location?.pathname.includes("addnewschool"))
      params["schStatus"] = "Non-Member";
    if(params?.schLowestGrade && params?.schHighestGrade){
      let lowIndex = lowestgrade.findIndex(
        (element) => element === params?.schLowestGrade
      );
      let highIndex = lowestgrade.findIndex(
        (element) => element === params?.schHighestGrade
      );
      let array = [];
      if(lowIndex>highIndex){
        toggleSnackbar({
          status: true,
          message: "Invalid Grade Selection",
          variant: "error",
        });
        return false;
      }
      else if(lowIndex<highIndex)
        for(let index = lowIndex; index<=highIndex; ++index){
          array.push(lowestgrade[index])
        }
      else{
        array.push(lowestgrade[lowIndex])
      }
      params["gradeRange"] = array;
    }
    if (params?.schMembers.length === 0 && props?.location?.pathname.includes("addschool") && userDtls.data.loginType!=="Admin") {
      toggleSnackbar({
        status: true,
        message: "Add atleast one member",
        variant: "error",
      });
    } else {
      if (params?.schoolTags) {
        let sklTagArray = [];
        Object.keys(params?.schoolTags).map((key) => {
          if (params?.schoolTags[key])
            sklTagArray.push({ title: key, status: params?.schoolTags[key] });
        });
        params["schoolTags"] = sklTagArray;
      }
      if (params?.socialMediaWeb) {
        let socialMediaArray = [];
        Object.keys(params?.socialMediaWeb).map((key) => {
          if (params?.socialMediaWeb[key])
            socialMediaArray.push({
              title: key,
              url: params?.socialMediaWeb[key],
              priority: "",
            });
        });
        params["socialMediaWeb"] = socialMediaArray;
      }
      let imageArray = [];
      bannerImages.forEach((element) => {
        if (element.file) {
          const response = axios.put(element.signedUrl, element.file, {
            headers: {
              "Content-Type": element?.file?.type,
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Headers": "X-Requested-With",
            },
          });
          imageArray.push({
            url: element.publicUrl,
            priority: "",
            title: "",
          });
        } else {
          imageArray.push({
            url: element.url,
            priority: element.priority,
            title: element.title,
          });
        }
      });
      params["schImages"] = imageArray;
      // -------------school logo upload-----------------------------
      params["schLogoUrl"] = null;
      logoImage.forEach((element) => {
        if (element.file) {
          const response = axios.put(element.signedUrl, element.file, {
            headers: {
              "Content-Type": element?.file?.type,
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Headers": "X-Requested-With",
            },
          });
        }
        params["schLogoUrl"] = element.publicUrl;
      });
      // -------------school logo upload-----------------------------
      if (id === "$") {
        try {
          params["schUdId"] = uuId;
          AddSchool({ variables: { data: params } })
            .then((res) => {
              if(props?.location?.pathname.includes("addnewschool"))
                addMemberAndLogin(res,memberData);
              else
                history.push( {pathname:"/schools",state:{detail:"src"}});
              toggleSnackbar({
                status: true,
                message: params?.schMembers.length === 0?"School created successfully":"An email has been sent to the member's email address containing an activation link",
                variant: "success",
              });
               
            })
            .catch((error) => {
              const message = error && error.message;
              toggleSnackbar({
                status: true,
                message: message,
                variant: "error",
              });
            });
        } catch (error) {
          const message = error && error.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        }
      } else {
        try {
          editSchool({ variables: { data: params, _id: id } })
            .then((res) => {
              toggleSnackbar({
                status: true,
                message: "School updated successfully",
                variant: "success",
              });
              if(schoolDetails?.schoolId)
                history.goBack();
              else
              history.push( {pathname:"/schools",state:{detail:"src"}});
            })
            .catch((error) => {
              const message = error && error.message;
              toggleSnackbar({
                status: true,
                message: message,
                variant: "error",
              });
            });
        } catch (error) {
          const message = error && error.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        }
      }
    }
  };
  function handleMemberAdd(values) {
    append({
      schUsersId: values.id,
      name: values.firstName + " " + values.lastName,
      profileImage: values.profileImage,
      title: values.title,
    });
    setOpenAddMember(false);
  }
  function getWebsite() {
    if(defaultValue?.socialMediaWeb)
      {
        let position = defaultValue?.socialMediaWeb.findIndex(
        (element) => element.title === "Website"
      );
      if (position > -1) {
        return defaultValue?.socialMediaWeb[position]?.url;
      }
    }
  }
  
  function checkDomainValue() {
    let website = getWebsite();
    if(DomainCheckValue?.checkDomain.status===true && !(getValues("socialMediaWeb.Website") === website))
      return true;
    else
      return false;
  }
  const registerDescription = register("schDescription");
  return (
    <div className="fullwidth-body">
      {openAddMember && (
        <AddMember
          uuid={uuId}
          open={openAddMember}
          setOpen={setOpenAddMember}
          website={getValues("socialMediaWeb.Website")}
          handleAdd={handleMemberAdd}
          getMoreUserList={getMoreUserList}
        />
      )}

      {defaultValue && (
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <AddSchoolHeader loading={ editLoading} />
          <div className="event-outer">
            <div className="container">
              <div className="row">
                <div className="col-sm-8">
                  <Profile
                    errors={errors}
                    register={register}
                    defaultValue={defaultValue}
                    registerDescription={registerDescription}
                    textEditorValue={textEditorValue}
                    setTextEditorValue={setTextEditorValue}
                    control={control}
                    id={id}
                    pathname={props?.location?.pathname}
                    websiteFlag={websiteFlag}
                    setValue={setValue}
                    trigger={trigger}
                    checkDomain={checkDomain}
                    checkDomainValue={checkDomainValue}
                  />
                  {props?.location?.pathname.includes("addschool") && <Controller
                    control={control}
                    name="socialMediaWeb.Website"
                    render={({ field: { onChange, value } }) => (
                      <MemberView
                        data={membersFormatedList}
                        setOpenAddMember={setOpenAddMember}
                        openAddMember={openAddMember}
                        fields={fields}
                        append={append}
                        remove={remove}
                        website={defaultValue?.socialMediaWeb ? getWebsite() : value}
                        setWebsiteFlag={setWebsiteFlag}
                        websiteFlag={websiteFlag}
                      />
                    )}
                  />}

                  <Banner
                    setBannerImages={setBannerImages}
                    bannerImages={bannerImages}
                  />
                  <LogoUpload
                    logoImage={logoImage}
                    setLogoImage={setLogoImage}
                  />
                  {props?.location?.pathname.includes("addnewschool") && <OrganisationAdmin
                    errors={errors}
                    register={register}
                    defaultValue={defaultValue}
                    control={control}
                    setValue={setValue}
                    id={id}
                    Controller={Controller}
                    setRegUserImg={setRegUserImg}
                    regUserImg={regUserImg}
                    websiteFlag={websiteFlag} />}
                </div>
                <div className="col-sm-4">
                  <Highlights
                    errors={errors}
                    register={register}
                    defaultValue={defaultValue}
                    control={control}
                  />
                  {!pageUrl.includes("/addnewschool") && 
                  <Website 
                    errors={errors}
                    register={register}
                    defaultValue={defaultValue}
                  />
                }
                  <Tags
                    errors={errors}
                    register={register}
                    defaultValue={defaultValue}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </div>
  );
}
