import { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  Button,
  Menu,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Pagination,
  TextField,
  Typography,
  Modal,
} from "@mui/material";
import { useMutation, useReactiveVar } from "@apollo/client";
import { toggleSnackbar, schoolDtls } from "../../../RactiveVariables/index";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { useQuery } from "@apollo/client";
import { LIST_EVENTS } from "../../../Graphql/queries";
import { DELETE_EVENT } from "../../../Graphql/mutations";
import moment from "moment";
import { categoryArray, eventTypeArray } from "../../Events/EventOptions";
import { useParams, Link } from "react-router-dom";
import ConfirmDialog from "../../Common/ConfirmDialog";
import { formatDateandTime } from "../../Common/GeneralFunctions";

export default function SchoolEvents(props) {
  const [category, setcategorytype] = useState(null);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState(null);
  const [eventType, setEventType] = useState(null);
  const [startDate, setStartDate] = useState(null);
  //   const [school, setSchoool] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [selectedItem, setSelectedItem] = useState(null);
  const [sortFieldVariable, setSortFieldVariable] = useState("_id");
  const [sortVariable, setSortVariable] = useState("-1");
  const [deleteId, setDeleteId] = useState(null);
  const [deleteEvent, { loading: deleteLoadinng }] = useMutation(DELETE_EVENT);
  const schoolDetails = useReactiveVar(schoolDtls);

  const { id } = useParams();
  const getEventVariables = {
    skip: (page - 1) * rowsPerPage,
    limit: rowsPerPage,
    search: search,
    category: category,
    eventType: eventType,
    startDate: startDate,
    sortOrder: sortVariable,
    sortField: sortFieldVariable,
    belgsToArg: "School",
    // schId: id,
    schId: schoolDetails?.schoolId ? schoolDetails?.schoolId : id,
  };

  const {
    data: eventList,
    fetchMore,
    loading,
  } = useQuery(LIST_EVENTS, {
    fetchPolicy: "network-only",
    variables: getEventVariables,
  });
  const handleEventType = (event) => {
    setPage(1);
    setEventType(event.target.value);
  };

  function closeConfirm() {
    setDeleteId(null);
  }
  function handleConfirm() {
    handleDelete();
    closeConfirm();
    handlePagination();
  }

  function handleDelete() {
    deleteEvent({ variables: { eventIds: [deleteId] } })
      .then((res) => {
        toggleSnackbar({
          status: true,
          message: "Event deleted successfully",
          variant: "success",
        });
        handlePagination();
      })
      .catch((error) => {
        const message = error && error.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      });
  }
  const handlePagination = () => {
    fetchMore({
      variables: getEventVariables,
    });
  };
  const handlecategory = (event) => {
    setcategorytype(event.target.value);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, item) => {
    setSelectedItem(item);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChangePage = (e, page) => {
    setPage(page);
  };
  function handlePageInputChange(event) {
    setRowsPerPage(Number(event.target.value));
    setPage(1);
  }
  function convertDate(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    let formatedDate = [mnth, day, date.getFullYear()].join("/");
    return formatedDate == "aN/aN/NaN" || formatedDate == "01/01/1970"
      ? null
      : formatedDate;
  }
  function handleSort(field) {
    if (sortFieldVariable === field) {
      if (sortVariable === "-1") setSortVariable("1");
      else setSortVariable("-1");
    } else {
      setSortFieldVariable(field);
      setSortVariable("1");
    }
  }
  return (
    <div className="content-wrapper">
      <ConfirmDialog
        open={deleteId}
        title={"Delete"}
        body={"Are you sure you want to delete this Event ? "}
        closeText={"Cancel"}
        confirmText={"Delete"}
        closeConfirm={closeConfirm}
        handleConfirm={handleConfirm}
      />
      <div className="container">
      <div className="d-block d-md-none">
          <h3 className="m-h3">{schoolDetails?.data?.schNameThe}</h3>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="card border-0">
              <div className="card-body p-0">
                <div className="filter-section d-md-flex justify-content-between">
                  <div className="d-flex align-items-center m-10">
                    <img
                      className="search-icon"
                      src="../assets/images/search.svg"
                      alt="search icon"
                    />
                    <input
                      className="searchbox"
                      placeholder="Search by Event Name"
                      value={search}
                      type="text"
                      onChange={(e) => {setPage(1);setSearch(e.target.value)}}
                    />
                  </div>
                  <div className="school-event-filter d-flex align-items-center justify-content-center">
                    <Box sx={{ minWidth: 177 }}>
                      <FormControl fullWidth className="filter-date">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            inputFormat="MM/dd/yyyy"
                            value={startDate ? startDate : null}
                            renderInput={(params) => <TextField {...params} />}
                            onChange={(e) => {setPage(1);setStartDate(e ? convertDate(e) : null)}}
                          />
                        </LocalizationProvider>
                      </FormControl>
                    </Box>
                    {/* <Box className="ms-2" sx={{ minWidth: 177 }}>
                      <FormControl fullWidth>
                        <InputLabel className="filter-label" id="category">
                          Category
                        </InputLabel>
                        <Select
                          className="filter-select"
                          labelId="category"
                          id="demo-category"
                          value={category}
                          label="category"
                          onChange={handlecategory}
                        >
                          <MenuItem value="">{"None"}</MenuItem>
                          {categoryArray.map((item) => (
                            <MenuItem value={item.value}>{item.label}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box> */}
                    <Box className="ms-2" sx={{ minWidth: 177 }}>
                      <FormControl fullWidth>
                        <InputLabel className="filter-label" id="type">
                          Type
                        </InputLabel>
                        <Select
                          className="filter-select"
                          labelId="type"
                          id="demo-type"
                          value={eventType}
                          label="type"
                          onChange={handleEventType}
                        >
                          <MenuItem value="All">{"All"}</MenuItem>
                          {eventTypeArray.map((item) => (
                            <MenuItem value={item.value}>{item.label}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                    <Link
                      role="button"
                      type="button"
                      className="btn add-btn ms-2"
                      to={`/addschoolevent/${
                        schoolDetails?.schoolId
                          ? schoolDetails?.schoolId
                          : props?.schoolId
                      }||$`}
                      //   href="javascript:;"
                      //   onClick={handleModalOpen}
                    >
                      + Add Event
                    </Link>
                    {/* <Modal
                      modalopen={modalopen}
                      onClose={handleModalClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx ={style}>
                        <Typography
                          id="modal-modal-title"
                          variant="h6"
                          component="h2"
                        >
                          Text in a modal
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                          Duis mollis, est non commodo luctus, nisi erat
                          porttitor ligula.
                        </Typography>
                      </Box>
                    </Modal> */}
                  </div>
                </div>
                <TableContainer component={Paper}>
                  <Table className="mob-table-layout" sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell className="w-175">
                        Event Name
                          {/* <a
                            className="filter-icon"
                            onClick={() => handleSort("title")}
                          >
                            <img
                              src={
                                sortFieldVariable !== "title"
                                  ? "../assets/images/arrowupdown.svg"
                                  : sortVariable === "-1"
                                  ? "../assets/images/DownArrow.svg"
                                  : "../assets/images/UpArrow.svg"
                              }
                            />
                          </a> */}
                        </TableCell>
                        <TableCell className="w-150">Start Date & Time</TableCell>
                        <TableCell className="w-150">End Date & Time</TableCell>
                        {/* <TableCell>
                          Category
                          <a
                            className="filter-icon"
                            onClick={() => handleSort("category")}
                          >
                            <img
                              src={
                                sortFieldVariable !== "category"
                                  ? "../assets/images/arrowupdown.svg"
                                  : sortVariable === "-1"
                                  ? "../assets/images/DownArrow.svg"
                                  : "../assets/images/UpArrow.svg"
                              }
                            />
                          </a>
                        </TableCell> */}
                        <TableCell align="center" className="w-125">Event Type</TableCell>
                        <TableCell className="w-90"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {loading ? (
                        <TableRow>
                          <TableCell colSpan={6}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <p> Loading Events...</p>
                            </div>
                          </TableCell>
                        </TableRow>
                      ) : eventList?.listAllEvents?.data &&
                        eventList?.listAllEvents?.data.length > 0 ? (
                        eventList?.listAllEvents?.data?.map((item) => (
                          <TableRow>
                            <TableCell><Link className="preview-link" to={`/schooleventpreview/${item._id}`}>{item.title}</Link></TableCell>
                            <TableCell>
                              {/* {item.startDate &&
                                moment(new Date(item.startDate)).utc().format(
                                  "MMM D, YY" 
                                ) +
                                  " " +
                                  moment(item.startTime, "HH.mm").format(
                                    "h:mm A"
                                  )} */}
                                  {formatDateandTime(item.startDate)}
                            </TableCell>
                            <TableCell>
                              {/* {item.endDate &&
                                moment(new Date(item.endDate)).utc().format(
                                  "MMM D, YY"
                                ) +
                                  " " +
                                  moment(item.endTime, "HH.mm").format(
                                    "h:mm A"
                                  )} */}
                                  {formatDateandTime(item.endDate)}
                            </TableCell>
                            {/* <TableCell>{item.category}</TableCell> */}
                            <TableCell align="center">{item?.eventType}</TableCell>
                            <TableCell>
                              <Button
                                id="basic-button"
                                aria-controls="basic-menu"
                                aria-haspopup="true"
                                aria-expanded={open ? "true" : undefined}
                                onClick={(e) => handleClick(e, item)}
                                className="p-0 action-btn"
                              >
                                <img
                                  src="../assets/images/dots.svg"
                                  alt="edit button image"
                                />
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : search ? (
                        <TableRow>
                          <TableCell colSpan={6}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <p>No Events Found</p>
                            </div>
                          </TableCell>
                        </TableRow>
                      ) : (
                        <TableRow>
                          <TableCell colSpan={6}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <p>No Events Added Yet!</p>
                            </div>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                  {!loading && (
                    <div className="pagination-outer">
                      <div className="count-selector">
                        <Select
                          onChange={handlePageInputChange}
                          value={rowsPerPage}
                        >
                          <MenuItem value={10}>{10}</MenuItem>
                          <MenuItem value={25}>{25}</MenuItem>
                          <MenuItem value={50}>{50}</MenuItem>
                          <MenuItem value={100}>{100}</MenuItem>
                        </Select>
                        <img
                          className="count-arrow"
                          src="../../assets/images/arrowupdown.svg"
                        />
                      </div>
                      <Pagination
                        count={Math.ceil(
                          eventList?.listAllEvents?.count / rowsPerPage
                        )}
                        page={page}
                        shape="rounded"
                        onChange={handleChangePage}
                      />
                    </div>
                  )}
                </TableContainer>
              </div>
            </div>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "dropdown-button",
              }}
            >
              <MenuItem onClick={handleClose}>
                <Link
                  className="actionbtn-menu"
                  to={`/addschoolevent/${
                    schoolDetails?.schoolId
                      ? schoolDetails?.schoolId
                      : props?.schoolId
                  }||${selectedItem?._id}`}
                >
                  Edit
                </Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <a
                  // onClick={() => handleDelete()}
                  className="actionbtn-menu"
                  onClick={() => setDeleteId(selectedItem?._id)}
                  href="javascript:;"
                >
                  Delete
                </a>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Link
                  className="actionbtn-menu"
                  to={`/schooleventpreview/${selectedItem?._id}`}
                >
                  Preview
                </Link>
              </MenuItem>
            </Menu>
          </div>
        </div>
      </div>
    </div>
  );
}
