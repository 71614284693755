import { useEffect, useState } from "react";
import Message from "./Messages";
import Member from "./Member";
import { userDeatils,schoolDtls } from "../../RactiveVariables";
import { useLazyQuery, useReactiveVar } from "@apollo/client";
import { GET_FOURM_USER_DTLS } from "../../Graphql/queries";
import { useParams } from "react-router-dom";
import { useHistory ,useLocation} from "react-router-dom";
import { toggleSnackbar,mailGroupTitle } from "../../RactiveVariables";
 
import {
  Tabs,
  Tab,
  Button,
} from "@mui/material";
export default function GroupDetails(props) {
  const [selectedTab, setSelectedTab] = useState(1);
  const { id } = useParams();
  const schoolDetails = useReactiveVar(schoolDtls);
  const user = useReactiveVar(userDeatils);
  const [isManager, setIsManager] = useState(false);
  const [value, setValue] = useState("Conversation");
  const history = useHistory();
  const location = useLocation();

  const [postAcs, setPostAcs] = useState(false);
  useEffect(() => {
    if(user?.data?.loginType!=="Admin"){
      getFourmUserDtls();
    }
    else{
      setIsManager(true);
      setPostAcs(true);
    }
  },[])
  useEffect(()=>{
    if(location?.state?.title){
      mailGroupTitle(location?.state?.title)
    }
  },[location?.state?.title])
  const [getFourmUserDtls] = useLazyQuery(GET_FOURM_USER_DTLS, {
    fetchPolicy:"network-only",
    variables: {
      userId: user?.data?._id,
      forumId: id,
    },
    onCompleted:res=>{
      if(res?.forumUserDetails?.postAccess)
        setPostAcs(true);
      if(res?.forumUserDetails?.isManager)
        setIsManager(true);
}  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className="content-wrapper">
      <div className="container">
      <div className="d-block d-md-none">
          <h3 className="m-h3">{schoolDetails?.data?.schNameThe}</h3>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="mb-3">
              <div className="d-flex align-items-center justify-content-between" style={{backgroundColor:'#fff'}}>
                <div>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    style={{background:"#fff"}}
                    aria-label="basic tabs example"
                  >
                    <Tab 
                      label="Conversations" 
                      value="Conversation"
                    />
                    {isManager && <Tab
                      label="Members" 
                      value="Members"
                    />}
                  </Tabs>
                </div>
                <div className="" style={{background:"white", textAlign:"end"}}>
                  <Button
                    className="btn outline-btn text-right me-2 me-md-3"
                    
                    onClick={()=>history.goBack()}
                  >
                    Back
                  </Button>
                </div>
              </div>
            
    
            </div>
            {value === "Conversation" ? <Message isManager={isManager} postAcs={postAcs} user= {user} /> : <Member />}
          </div>
        </div>
      </div>
    </div>
  );
}
