import { IconButton } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import { Link } from "react-router-dom";
import List from "@mui/material/List";
import { useReactiveVar } from "@apollo/client";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useHistory } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import { schoolDtls ,userDeatils } from "../../RactiveVariables";
import { useState } from "react";

const sideMenuItems = [
  { label: "Dashboard", img: "Dashboard" },
  {
    collapse: true,
    label: "Events",
    url: "/events",
    img: "Events",
    subMenu: [
      { label: "Events", url: "/events", img: "Events" },
      { label: "Locations", url: "/location", img: "Location" },
      { label: "Speakers", url: "/speaker", img: "Speaker" },
    ],
    accessLabel: "Events",
  },
  { label: "Schools", url: "/schools", img: "Schools", accessLabel : "Member Schools" },
  { label: "Users", url: "/users", img: "Users" },
  { label: "School Users", url: "/schoolusers", img: "Users" },
  { label: "Groups", img: "Forums", accessLabel : "Forums", url: "/groups",otherUrl:"/groups-details",otherUrl1:"/conversation"  },
  { label: "User Groups", url: "/usergroup", img: "User Groups", accessLabel : "Users Group" },
  { label: "Finance", url: "/finance", img: "finance-icon-mob", accessLabel : "Finance" },
  { label: "Mailing Groups",url: "/newsletter", img: "Newsletter", otherUrl:"/userlisting"},
  { label:"E-Notices", url:"/enotice", img: "E notice",otherUrl:"/userlists"},
  // { label:"Groups", url:"/groups", img: "E notice"},
];
const schoolSideMenuItems = [
    { label: "Orders", url: "/orderlisting", img: "Dashboard"},
    { label: "School Events", url: "/schoolevent", img: "Events", accessLabel : "events" },
    { label: "Careers", url: "/schoolcareer", img: "briefcase1", accessLabel : "careers" },
    // { label: "Profile", img: "Schools", url: "/schoolprofile", accessLabel : "schoolprofile" },
    { label: "Users", url: "/schooluser", img: "Users", accessLabel : "Users"},
    { label: "Groups", img: "Forums", url: "/groups",otherUrl:"/groups-details",otherUrl1:"/conversation" },
    // { label: "Browse Events", url: "/events", img: "Events" },
    { label: "My Events", url: "/myevents", img: "Events"},
  ];
  const guestSideMenuItems = [
    { label: "Orders", url: "/orderlisting", img: "Dashboard" },
    { label: "My Events", url: "/myevents", img: "Events"},
  ];
export default function MobileSideMenu(props) {
    const user = useReactiveVar(userDeatils);
    const userType = user?.data?.loginType;
    const [subMenuVisible,setSubMenuVisible]= useState(false)
    const [menuItems, setMenuItems] = useState(
      userType=== "Admin"  ? sideMenuItems : userType==="Guest" ? guestSideMenuItems : schoolSideMenuItems
      );
  const history = useHistory();
  const currentUrl = window.location.href;
  console.log("subMenuVisible",subMenuVisible);
  return (
    <div>
      <Drawer
        className="m-menu-drawer"
        anchor={"right"}
        open={props.openSideMenu}
        onClose={() => {
          props.setOpenSideMenu(false);
        }}
      >
        <div>
       <div className="d-flex justify-content-end">
          <IconButton
            className="m-menu-close"
            onClick={() => props.setOpenSideMenu(false)}
          >
            <CloseIcon/>
          </IconButton>
          </div>
          <nav className="sideMenu mob-side-menu">
          {menuItems.map((item) => (
            <List disablePadding key={item.label}>
              <ListItem disablePadding>
                <ListItemButton
                  className={currentUrl.includes(item.url || item.otherUrl || item.otherUrl1) ? "active sideMenuItem" : "sideMenuItem"}
                  selected={item.label}
                  onClick={() => {
                    if (item.subMenu) {
                      // Toggle the submenu visibility when clicking on "Events"
                      setSubMenuVisible(!subMenuVisible);
                    } else {
                      history.push(item.url);
                      props.setOpenSideMenu(false);
                      setSubMenuVisible(!subMenuVisible);
                    }
                  }}
                >
                  <img src={`../assets/images/${item.img}.svg`} alt="menu icon" />
                  <ListItemText className="sideMenuItemText" primary={item.label} />
                </ListItemButton>
              </ListItem>
              {subMenuVisible && item.subMenu && (
                <List className="subMenu">
                  {item.subMenu.map((subItem) => (
                    <ListItem
                      key={subItem.label}
                      button
                    >
                      <ListItemButton 
                        style={currentUrl.includes(subItem.url) ?{color:'#2b54a3',fontWeight:'900 !important'}:{color:'black'}}
                        className={currentUrl.includes(subItem.url) ? "active subMenuItem" : "subMenuItem"}
                        selected={subItem.label}                  
                        onClick={() => {
                        history.push(subItem.url);
                        props.setOpenSideMenu(false);
                      }}>
                      <img src={`../assets/images/${subItem.img}.svg`} alt="submenu icon" />
                      <ListItemText primary={subItem.label} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              )}
            </List>
          ))}

          </nav>
        </div>
      </Drawer>
    </div>
  );
}
