import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  InputLabel,
  TextField,
  Drawer,
  Button,
  FormControlLabel,
  CircularProgress,
  FormGroup,
  Checkbox,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { useMutation } from "@apollo/client";
import { ADD_USERGROUP, EDIT_USERGROUP } from "../../Graphql/mutations";
import { toggleSnackbar } from "../../RactiveVariables/index";

export default function AddUserGroup(props) {
  const [AddUserGroup, { loading }] = useMutation(ADD_USERGROUP);
  const [editUserGroup, { loading: editLoading }] = useMutation(EDIT_USERGROUP);
  const schema = yup.object().shape({
    groupName: yup.string().required("User Group Name is Required"),
  });
  const { register, handleSubmit, formState, reset } = useForm({
    defaultValues: props.defaultValue,
    // defaultValues: formatDefaultValue(props.editItem),
    resolver: yupResolver(schema),
  });
  const { errors } = formState;
  function getAccessDefaultvalue(name) {
    if (Object.keys(props.editItem).length > 0 ) { 
       let position = props.editItem?.moduleAcs.findIndex(
      (element) => element.title === name
    ); 
    if (position > -1) {
      return props.editItem?.moduleAcs[position]?.status
    }
  }   
  }

  const onSubmit = (params) => {
    let flag = false;
    Object.keys(params.gpModules).forEach(key => {
      if(params.gpModules[key]===true){
        flag = true;
      }
    });
    if (!flag) {
      toggleSnackbar({
        status: true,
        message: "Please add atleast one module permission",
        variant: "error",
      });
      return false;
    }
    let moduleArray = [];
    Object.keys(params.gpModules).map((key) => {
      moduleArray.push({
        title: key,
        status: params.gpModules[key],
      });
    });
    params.moduleAcs = moduleArray;
    delete params.gpModules;

    if (Object.keys(props.editItem).length > 0) {
      try {
        params._id = props?.editItem?._id;
        editUserGroup({ variables: params })
          .then((res) => {
            toggleSnackbar({
              status: true,
              message: "User Group Updated successfully",
              variant: "success",
            });
            props.handlePagination();
            props.setOpen(false);
          })
          .catch((error) => {
            const message = error && error.message;
            toggleSnackbar({
              status: true,
              message: message,
              variant: "error",
            });
          });
      } catch (error) {
        const message = error && error.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      }
    } else {
      try {
        AddUserGroup({ variables: params })
          .then((res) => {
            toggleSnackbar({
              status: true,
              message: "User Group created successfully",
              variant: "success",
            });
            props.handlePagination();
            props.setOpen(false);
          })
          .catch((error) => {
            const message = error && error.message;
            toggleSnackbar({
              status: true,
              message: message,
              variant: "error",
            });
          });

      } catch (error) {
        const message = error && error.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      }
    }
  };
  function closeDrawer() {
    props.setOpen(false);
    props.setSelectedItem(null);
  }
  const Checkboxlists = [
    { label: "Events", name: "Events" },
    { label: "Forums", name: "Forums" },
    { label: "Finance", name: "Finance" },
    // { label: "Careers", name: "Careers" },
    { label: "Users Access", name: "Users Access" },
    { label: "Users Group", name: "Users Group" },
    { label: "Member Schools", name: "Member Schools" },
  ];
  useEffect(() => {
    reset();
  }, [props.editItem]);

   
  return (
    <div>
      <Drawer anchor={"right"} open={props.open} onClose={closeDrawer}>
        <div className="sidebar-toggler" role="presentation">
          <div className="title-outer">
            <h1>
              {Object.keys(props.editItem).length > 0
                ? "Edit User Group"
                : "Add User Group"}
            </h1>
            <div onClick={closeDrawer} className="close-icon">
              X
            </div>
          </div>
          <form
            className="adduser-form usergroup-form mt-3"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="row">
              <div className="col-md-12 mb-3">
                <InputLabel className="form-label">
                User Group Name<sup className="text-danger">*</sup>
                </InputLabel>
                <TextField
                  style={{ width: "100%" }}
                  variant="outlined"
                  {...register("groupName")}
                  error={errors.groupName}
                  autoFocus
                  defaultValue={props?.editItem?.groupName}
                />
                {errors && errors?.["groupName"] && (
                  <p className="errorMessage text-danger">
                    {errors?.groupName?.message}
                  </p>
                )}
              </div>
              <div className="col-md-12">
                <h3 className="module-title">Module Permission</h3>
                <FormGroup className="module-checkbox row">
                  {Checkboxlists.map((item) => {
                    return (
                      <FormControlLabel
                        className="col-sm-3"
                        control={
                          <Checkbox
                            defaultChecked={
                              getAccessDefaultvalue(item.name)
                            }
                            {...register(`gpModules.${item.name}`)}
                            icon={<RadioButtonUncheckedIcon />}
                            checkedIcon={<CheckCircleIcon />}
                          />
                        }
                        label={item.label}
                      />
                    );
                  })}
                </FormGroup>
              </div>
            </div>

            <div className="d-flex justify-content-between top-margin align-items-center btn-section">
              <Button className="outline-btn" onClick={() => closeDrawer()}>
                Cancel
              </Button>
              <div className="d-flex align-items-center">
                <Button type="submit" className="highlight-btn">
                  {loading ? (
                    <CircularProgress size="20px" color="inherit" />
                  ) : props.editItem &&
                    Object.keys(props.editItem).length > 0 ? (
                    "Edit User Group"
                  ) : (
                    "Add User Group"
                  )}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </Drawer>
    </div>
  );
}
