import {
  Autocomplete,
  TextField,
  InputLabel,
  Chip,
  Avatar,
} from "@mui/material";
import { toggleSnackbar } from "../../../RactiveVariables";

export default function MemberView(props) {
  const { fields,append, website } = props;
  function handleOnChange(value) {
    append({schUsersId:value[0]?._id,
      name:value[0]?.name,
      profileImage:value[0]?.profileImage,
      title:value[0]?.title});
  }  
  function getMemberIdArray() {
    let array = [];
    fields.forEach(element => {
      array.push(element.name) //temporary fix replace name with id
    });
    return array;
  }
  const filterOptions = (options) => {

    let array = []
    let id = getMemberIdArray();
    options.forEach(element => {
      if(!id.includes(element?.name)){ //temporary fix replace name with id
        array.push(element);
      }
    });
    return array;
  };
    return (
    <div className="card mb-4">
      <div className="card-title justify-content-between">
        <div className="d-flex">
          <img src="../../assets/images/Group.svg" /> Members
        </div>
        <a
          onClick={() => {
            if(!website || website==="" ){
              toggleSnackbar({
                status: true,
                message: "Enter a website",
                variant: "error",
              });
            }
            else{
              props.setWebsiteFlag(true);
              props.setOpenAddMember(true);
            }
          }}
          className="addspeaker-btn"
          // href="javscript:;"
        >
          <img src="../../assets/images/adduser.svg" />
          Add School Member
        </a>
      </div>
      <div className="card-body speaker-select-outer">
        <div className="row">
        <div className="col-md-12 mb-3">
          <InputLabel className="form-label">Select members for School Public Profile</InputLabel>
          <Autocomplete
            multiple
            // filterOptions = {filterOptions}
            options={filterOptions(props?.data)}
            value={[]}
            getOptionLabel={(options) => options.name}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                //   label="type"
                placeholder="Type"
              />
            )}
            onChange={(e, value) => {
              handleOnChange(value);
            }}
          />
        </div>
          <div className="col-sm-12">
            <div className="d-flex flex-wrap">
          <p className="speakers-head">Members</p>
            {fields &&
              fields.map((item, index) => (
                <div>
                  <div className="selected-speakers">
                    <Chip
                      avatar={
                        <Avatar src={item.profileImage}>
                          {item?.name?.[0].toUpperCase()}
                        </Avatar>
                      }
                      label={item?.name}
                      variant="outlined"
                      onDelete={() => props.remove(index)}
                    />
                  </div>
                </div>
              ))}
              </div>
          </div>
        </div>
      </div>
    </div>
  );
}
