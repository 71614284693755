import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Switch,
  Menu,
  Button,
  Avatar,
  TablePagination,
  Pagination,
  MenuItem,
  Select,
  FormControl,
  Box,
  IconButton,
  InputLabel,
 
} from "@mui/material";
import moment from "moment";
import { useQuery, useMutation } from "@apollo/client";
import { LIST_USERS,LIST_All_ENOTICES } from "../../Graphql/queries";
import { Link ,useHistory} from "react-router-dom";
import { DELETE_USER, DEACTIVATE_USER,DELETE_ENOTICE } from "../../Graphql/mutations";
import { useState } from "react";
import NewsMailList from "../NewsLetter/newsMailList.js";
import { toggleSnackbar } from "../../RactiveVariables/index";
import { ConfirmDialog } from "../Common";
import FileCopyIcon from '@mui/icons-material/FileCopy';
export default function ENotice() {
  const [openAdd, setOpenAdd] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState(null);
  const [editOption,setEditOption] =useState(null)
  const [clone,setClone] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const history = useHistory();
  const open = Boolean(anchorEl);
  const eNoticeVariables={
    skip: (page - 1) * rowsPerPage,
    limit: rowsPerPage,
    eventId:"",
    search: search,
  }
  const {data,loading,fetchMore} = useQuery(LIST_All_ENOTICES,{variables:eNoticeVariables, fetchPolicy: "network-only"});
  const [deleteENotice] = useMutation(DELETE_ENOTICE)

  const handleChangePage = (e, page) => {
    setPage(page);
  };
  const handlePagination = () => {
    fetchMore({
      variables: eNoticeVariables,
    });
  };
  function closeConfirm() {
    setDeleteId(null);
    setClone(null)
  }
  function handleDelete() {
    deleteENotice({ variables: { eventIds: [deleteId] } })
      .then((res) => {
        toggleSnackbar({
          status: true,
          message: "E-Notice deleted successfully",
          variant: "success",
        });
        handlePagination();
      })
      .catch((error) => {
        const message = error && error.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      });
  }
  function handleConfirm() {
    handleDelete();
    closeConfirm();
    handlePagination();
  }
  function handleClone(){
    history.push({pathname:`/createenotice/${clone}`,state: { detail: 'clone' }});
  };
  function handlePageInputChange(event) {
    setRowsPerPage(Number(event.target.value));
    setPage(1);
  }
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChangeEdit = (e)=>{
    setEditOption(e.target.value)
  }
  return (
    <div className="content-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            {(deleteId||clone)&&<ConfirmDialog
          open={deleteId||clone}
          title={clone?"Clone":"Delete"}
          body={clone?"Are you sure you want to clone this E-Notice ? ":"Are you sure you want to delete this E-Notice ? "}
          closeText={"Cancel"}
          confirmText={clone? "Yes": "Delete"}
          closeConfirm={closeConfirm}
          handleConfirm={clone?handleClone:handleConfirm}
        />}
            <div className="card border-0">
              <div className="card-body p-0">
                {/* {selectedItem && ( */}
                <NewsMailList
                  // setSelectedItem={setSelectedItem}
                  // selectedItem={selectedItem}
                  open={openAdd}
                  setOpen={setOpenAdd}
                  // handlePagination={handlePagination}
                />
                {/* )} */}
                <div className="filter-section d-flex justify-content-between">
                  <div className="d-flex align-items-center">
                    <img
                      className="search-icon"
                      src="../assets/images/search.svg"
                      alt="search icon"
                    />
                    <input
                      className="searchbox"
                      placeholder="Search for an E-Notice..."
                      type="text"
                      value={search}
                      onChange={(e) => {setPage(1);setSearch(e.target.value)}}
                    />
                  </div>
                  <div className="d-flex align-items-center justify-content-center">
                  <Link
                role="button"
                type="button"
                className="btn add-btn ms-2"
                to="/createenotice/$"
              >
                Create E-Notice
              </Link>
                
              </div>
                </div>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell className="w-25" >Subject</TableCell>
                        <TableCell className="">Count</TableCell>
                        <TableCell className="w-25">From</TableCell>
                        <TableCell className="w-25">Sch. date</TableCell>
                        <TableCell className="w-25">Date Sent</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {loading?(
                  <TableRow>
                    <TableCell colSpan={12}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <p> Loading E-notice Lists...</p>
                      </div>
                    </TableCell>
                  </TableRow>
                ):data?.listAllENotice?.data&&data?.listAllENotice?.data.length > 0 ?(
                  data?.listAllENotice?.data.map((item)=>(
                        <TableRow key={item?._id}>
                        <TableCell>
                          <div className="d-flex">
                            {item?.subject}
                          </div>
                        </TableCell>
                        <TableCell className="lower-case align-items-center ">
                          <div>{item?.sentCount}</div>
                        </TableCell>
                        <TableCell className="lower-case align-items-center ">
                          <div>{item?.from}</div>
                        </TableCell>
                        <TableCell><div>{item?.scheduledDate&&moment(item?.scheduledDate).format('lll')}</div></TableCell>
                        <TableCell><div>{item?.status==="Sent"?item?.sentDate&&moment(item?.sentDate).format('lll'):"-"}</div></TableCell>
                        <TableCell>
                          <div>
                            <span className={item?.status==="Draft"? "badge Cancelled":"badge open"}>{item?.status==="Schedule"?"Scheduled":item?.status}</span>
                          </div>
                        </TableCell>
                        <TableCell>
                        {/* <Box className="ms-2" sx={{ minWidth: 177 }}> */}
                {/* <FormControl fullWidth> */}
                  {/* <Button style={{border:"1px solid #CFD7E3",color:"#00396D" }} variant="outlined" onClick={(e)=>{setSelectedItem(item._id);setAnchorEl(e.currentTarget);}}>Clone/Edit</Button> */}
                  {/* <InputLabel className="filter-label" id="edit/clone">
                  edit/clone
                  </InputLabel>
                  <Select
                    className="filter-select"
                    labelId="edit/clone"
                    id="demo-category"
                    value={editOption}
                    label="edit/clone"
                    onChange={handleChangeEdit}
                  >
                    <MenuItem value="clone">{"Clone"}</MenuItem>
                    <MenuItem value="edit">{"Edit"}</MenuItem>
                  </Select> */}
                {/* </FormControl> */}
              {/* </Box> */}
                        </TableCell>
                        <TableCell>
                          <div className="d-flex">
                          {/* <Link
                            className="actionbtn-menu"
                            to={`/createenotice/${item._id}`}

                          > */}
                          
                            <IconButton type="button" className="p-2" data-bs-toggle="tooltip" title="Clone"
                                onClick={() => setClone(item._id)}
                            >
                              <FileCopyIcon style={{color:"#666666"}}/>
                              {/* <img
                                src="../assets/images/trash.svg"
                                alt="delete button image"
                              /> */}
                            </IconButton>
                          {/* </Link> */}
                            
                            <IconButton
                              className="p-2"
                                onClick={() => setDeleteId(item._id)}
                            >
                              <img
                                src="../assets/images/trash.svg"
                                alt="delete button image"
                              />
                            </IconButton>
                            {item?.status==="Draft"&&<IconButton
                              className="p-2"
                                // onClick={() => setDeleteId(item._id)}
                            >
                            <Link
                                className="actionbtn-menu"
                                to={`/createenotice/${item._id}`}

                            >
                             <img
                                src="../assets/images/pencil.svg"
                                alt="delete button image"
                              />
                            </Link>
                              
                            </IconButton>}
                            {(item?.status==="Send"||item?.status==="Sent")&&<IconButton
                              className="p-2"
                                // onClick={() => setDeleteId(item._id)}
                            >
                            <Link
                                className="actionbtn-menu"
                                to={`/userlists/${item._id}`}

                            >
                             <img
                                src="../assets/images/eye-icon.svg"
                                alt="delete button image"
                              />
                            </Link>
                              
                            </IconButton>}
                          </div>
                        </TableCell>
                      </TableRow>
                      ))):search ? (
                        <TableRow>
                          <TableCell colSpan={12}>
                            <div
                              style={{ display: "flex", justifyContent: "center" }}
                            >
                              <p>No E-Notice Found</p>
                            </div>
                          </TableCell>
                        </TableRow>
                      ) : (
                        <TableRow>
                          <TableCell colSpan={12}>
                            <div
                              style={{ display: "flex", justifyContent: "center" }}
                            >
                              <p>No E-Notice Added Yet!</p>
                            </div>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                  {
                    //   !loading &&
                    <div className="pagination-outer">
                      <div className="count-selector">
                        <Select
                          // variant="outlined"
                          onChange={handlePageInputChange}
                          value={rowsPerPage}
                        >
                          <MenuItem value={10}>{10}</MenuItem>
                          <MenuItem value={25}>{25}</MenuItem>
                          <MenuItem value={50}>{50}</MenuItem>
                          <MenuItem value={100}>{100}</MenuItem>
                        </Select>
                        <img
                          className="count-arrow"
                          src="../../assets/images/arrowupdown.svg"
                        />
                      </div>

                      <Pagination
                        count={Math.ceil(data?.listAllENotice?.count/ rowsPerPage)}
                        page={page}
                        shape="rounded"
                        onChange={handleChangePage}
                      />
                    </div>
                  }
                </TableContainer>
              </div>
            </div>
          </div>
          <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "dropdown-button",
        }}
      >
        <MenuItem onClick={handleClose}>
          <Link
            className="actionbtn-menu"
            to={`/createenotice/${selectedItem}`}

          >
            Edit
          </Link>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Link
          // to={`/createenotice/${selectedItem}`}
          to={{ pathname: `/createenotice/${selectedItem}`, state: { foo: 'clone'} }}
            className="actionbtn-menu"
          >
            Clone
          </Link>
        </MenuItem>
      </Menu>
        </div>
      </div>
    </div>
  );
}
