import { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  Button,
  Menu,
  MenuItem,
  FormControl,
  Box,
  Pagination,
  CircularProgress,
  InputLabel,
} from "@mui/material";
import { useMutation, useReactiveVar } from "@apollo/client";
import {
  toggleSnackbar,
  schoolDtls,
  userDeatils,
} from "../../RactiveVariables/index";
import { useQuery } from "@apollo/client";
import { LIST_ALL_TICKET, COUNT_ORDER } from "../../Graphql/queries";
import { DELETE_TICKET, UPDATE_ORDER_TICKET } from "../../Graphql/mutations";
import moment from "moment";
import { useParams, Link } from "react-router-dom";
import { formatCurrency } from "../Common/GeneralFunctions";
import { ConfirmDialog, RefundConfirm } from "../Common";

export default function Ticket(props) {
  const [category, setcategorytype] = useState(null);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [eventType, setEventType] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [selectedItem, setSelectedItem] = useState(null);
  const [sortFieldVariable, setSortFieldVariable] = useState("_id");
  const [sortVariable, setSortVariable] = useState("-1");
  const [status, setStatus] = useState(null);
  const [loadingCSV,setLoadingCSV] = useState(false);
  const [memberType, setMemberType] = useState(null);
  const [cancelId, setCancelId] = useState(null);
  const [refundOpen, setRefundOpen] = useState(false);
  const [updateTicket, { loading: updateLoadinng }] = useMutation(UPDATE_ORDER_TICKET);
  //   const [checkOpen, setCheckOpen] = useState(false);
    const [deleteTicket] = useMutation(DELETE_TICKET);
  const schoolDetails = useReactiveVar(schoolDtls);
  const user = useReactiveVar(userDeatils);

  const pageUrl = window.location.pathname;

  const { id } = useParams();
  const getEventVariables = {
    // schId: schoolDetails?.schoolId ? schoolDetails?.schoolId : pageUrl.includes("/schoolpreview") ? props.schoolId : "",
    // schoolId: 1,
    // search: search,
    // skip: (page - 1) * rowsPerPage,
    // limit: rowsPerPage,
    // sortOrder: sortVariable,
    // eventId: pageUrl.includes("/eventpreview") ? id : "",

    id: id,
    reType: pageUrl.includes("/eventpreview") ? "event" : "",
    search: search,
    skip: (page - 1) * rowsPerPage,
    limit: rowsPerPage,
    status: user?.data?.loginType !== "Admin" ? "Paid" : status,
    memberType:memberType,
    loginType: user?.data?.loginType
  };

  const {
    data: ticketList,
    fetchMore,
    loading,
    refetch: refetchTickets,
  } = useQuery(LIST_ALL_TICKET, {
    fetchPolicy: "network-only",
    variables: getEventVariables,
  });

  const {
    data: countOrders
  } = useQuery(COUNT_ORDER, {
    fetchPolicy: "network-only",
    variables: {
       eventId: id
    },
  });
  const handleEventType = (event) => {
    setEventType(event.target.value);
  };


    function handleDelete() {
      deleteTicket({ variables: { eventIds: [cancelId] } })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Order deleted successfully",
            variant: "success",
          });
          handlePagination();
          setCancelId(null)
        })
        .catch((error) => {
          const message = error && error.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    }
  const handlePagination = () => {
    fetchMore({
      variables: getEventVariables,
    });
  };
  const handlecategory = (event) => {
    setcategorytype(event.target.value);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, item) => {
    setSelectedItem(item);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChangePage = (e, page) => {
    setPage(page);
  };
  function handlePageInputChange(event) {
    setRowsPerPage(Number(event.target.value));
    setPage(1);
  }
  function downloadTxtFile () {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      "id": id,
      "memberType": memberType,
      "reType": "event",
      "search": search,
      "status":status,
    });
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    fetch(`${process.env.REACT_APP_API_URL}/events/listAllOrderTkts`, requestOptions)
      .then(response => response.text())
      .then(result => {
        const element = document.createElement("a");
    const file = new Blob([result], {type: 'text/csv'});
    element.href = URL.createObjectURL(file);
    element.download = "RegTckt.csv";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    setLoadingCSV(false);
      })
      .catch(error => console.log('error', error));

  }
  function handleSort(field) {
    if (sortFieldVariable === field) {
      if (sortVariable === "-1") setSortVariable("1");
      else setSortVariable("-1");
    } else {
      setSortFieldVariable(field);
      setSortVariable("1");
    }
  }
  function closeConfirm() {
    setCancelId(null);
  }
  function cancelTicket() {
    updateTicket({
      variables: {
        _id:cancelId,
        data: {
          status: "Cancelled",
        },
      },
      onCompleted:res=>{
        closeConfirm();
        refetchTickets();
      }
    });
  }
  return (
    <div
      className={props?.eventorder ? "content-wrapper pt-0" : "content-wrapper"}
    >
      <ConfirmDialog
        open={cancelId}
        title={"Delete"}
        body={"Are you sure you want to delete this Ticket ? "}
        closeText={"Cancel"}
        confirmText={"Confirm"}
        closeConfirm={closeConfirm}
        handleConfirm={handleDelete}
      />
      <RefundConfirm
        setOpen={setRefundOpen}
        open={refundOpen}
        item={selectedItem}
        refetchTickets={refetchTickets}
      />
      {/* <CheckDetails open={checkOpen} setOpen={setCheckOpen} selectedItem={selectedItem} refetch={refetch} /> */}
      <div className="container">
        <div className="row">
          <div className="col-sm-3">
            <div className="card ticket-top">
              <div className="card-body">
                <img src="../assets/images/ticket1.svg" />
                <h3>Sold Registrations</h3>
                <p>{countOrders?.countOrders?.orderTktCnt ?? "0"}</p>
              </div>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="card ticket-top">
              <div className="card-body">
                <img src="../assets/images/ticket2.svg" />
                <h3>Cancelled Registrations</h3>
                <p>{(countOrders?.countOrders?.cancelledCnt+countOrders?.countOrders?.ticketRefCnt)||"0"}</p>
              </div>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="card ticket-top">
              <div className="card-body">
                <img src="../assets/images/ticket3.svg" />
                <h3>Total Amount</h3>
                <p>${formatCurrency(countOrders?.countOrders?.orderTicktSum)}</p>
              </div>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="card ticket-top">
              <div className="card-body">
                <img src="../assets/images/ticket4.svg" />
                <h3>Total Refund Amount</h3>
                <p>${formatCurrency(countOrders?.countOrders?.orderTicktRefSum)}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="card border-0">
              <div className="card-body p-0">
                <div className="filter-section d-flex justify-content-between">
                  <div className="d-flex align-items-center">
                    <img
                      className="search-icon"
                      src="../assets/images/search.svg"
                      alt="search icon"
                    />
                    <input
                      className="searchbox"
                      placeholder="Search for a Ticket"
                      value={search}
                      type="text"
                      // onChange={(e) => setSearch(e.target.value)}
                      onChange={(e) => {setPage(1);setSearch(e.target.value.split("")[0]==="#" ? e.target.value.substring(1) : e.target.value )}}
                    />
                  </div>
                  <div className="d-flex align-items-center justify-content-center">
                    {user?.data?.loginType === "Admin" && <Box className="ms-2" sx={{ minWidth: 140 }}>
                      <FormControl fullWidth>
                        <InputLabel className="filter-label" id="type">
                          Status
                        </InputLabel>
                        <Select
                          className="filter-select"
                          labelId="Status"
                          id="demo-type"
                          // value={schoolSubRegion}
                          label="Status"
                          onChange={(e)=>{setPage(1);setStatus(e.target.value)}}
                        >
                          <MenuItem value="">All</MenuItem>
                          <MenuItem value="open">Draft</MenuItem>
                          <MenuItem value="Paid">Paid</MenuItem>
                          <MenuItem value="Ready-For-Payment">Ready-For-Payment</MenuItem>
                          <MenuItem value="Refunded">Refunded</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>}
                    <Box className="ms-2" sx={{ minWidth: 140 }}>
                      <FormControl fullWidth>
                        <InputLabel className="filter-label" id="type">
                          Member Type
                        </InputLabel>
                        <Select
                          className="filter-select"
                          labelId="type"
                          id="demo-type"
                          // value={schoolStatus}
                          // label="type"
                          onChange={(e)=>{setPage(1);setMemberType(e.target.value)}}
                        >
                          <MenuItem value="">All</MenuItem>
                          <MenuItem value="Member">Member</MenuItem>
                          <MenuItem value="Non-Member">Guest</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                   {user?.data?.loginType==="Admin"&&  <a
                  href="javascript:;"
                  // download="https://dev-api.nysais.org/finance/financeOrderReport"
                  className="btn btn-orange d-flex align-items-center ms-2"
                  onClick={()=>{downloadTxtFile();setLoadingCSV(true)}}
                >
                  <img className="me-1" src="../assets/images/download.svg" />
                  {loadingCSV===true? <CircularProgress size="20px" color="inherit"  /> :  "Download"}
                </a>}
                  </div>
                </div>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Reg ID</TableCell>
                        <TableCell>Order ID</TableCell>
                        <TableCell>Participant Name</TableCell>
                        {/* <TableCell>Role</TableCell> */}
                        <TableCell>School</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Price</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {loading ? (
                        <TableRow>
                          <TableCell colSpan={12}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <p> Loading Tickets...</p>
                            </div>
                          </TableCell>
                        </TableRow>
                      ) : ticketList?.listAllOrderTicket?.data &&
                        ticketList?.listAllOrderTicket?.data.length > 0 ? (
                        ticketList?.listAllOrderTicket?.data?.map((item) => (
                          <TableRow>
                            <TableCell>
                            <Link
                                className="preview-link"
                                to={item.status === "Open" ? 
                                  `/eventregistration/${item?.orderId}` : 
                                  // item.status === "Ready-For-Payment" ? 
                                  // `/checkout/${item?._id}` : 
                                  `/orderdetails/${item?.orderId}` 
                                }
                              >
                                #{item?.regId}
                              </Link>
                                
                            </TableCell>
                            {/* <TableCell>#{item?.orderDtls?.orderNo}</TableCell> */}
                            <TableCell><Link
                                className="preview-link"
                                to={{pathname:`/orderdetails/${item?.orderDtls?._id}`,selectedTab: "Reg"}}
                              >
                                #{item?.orderDtls?.orderNo}
                              </Link></TableCell>
                            <TableCell>
                              {item?.schUserList?.firstName +
                                " " +
                                item?.schUserList?.lastName}
                            </TableCell>
                            {/* <TableCell>{item?.schUserList?.role}</TableCell> */}
                            <TableCell>{item?.schoolDtls?.schNameThe}</TableCell>
                            <TableCell>
                              {item?.schUserList?.schUserType==="Non-Member" ? "Guest" : item?.schUserList?.schUserType}
                            </TableCell>
                            <TableCell>${formatCurrency(item?.pricePerTcket)}</TableCell>
                            <TableCell>
                              <span className={item?.status ==="Open" ? "Open badge w-100" : "badge w-100 "+item?.status}>
                                {item?.status ==="Open" ? "Draft": item?.status}
                              </span>
                            </TableCell>
                            <TableCell>
                              <Button
                                id="basic-button"
                                aria-controls="basic-menu"
                                aria-haspopup="true"
                                aria-expanded={open ? "true" : undefined}
                                onClick={(e) => handleClick(e, item)}
                                disabled={item?.status ==="Cancelled"}
                                className="p-0 action-btn"
                              >
                                <img
                                  src="../assets/images/dots.svg"
                                  alt="edit button image"
                                />
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : search ? (
                        <TableRow>
                          <TableCell colSpan={12}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <p>No Tickets Found</p>
                            </div>
                          </TableCell>
                        </TableRow>
                      ) : (
                        <TableRow>
                          <TableCell colSpan={12}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <p>No Tickets Added Yet!</p>
                            </div>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                  {!loading && (
                    <div className="pagination-outer">
                      <div className="count-selector">
                        <Select
                          onChange={handlePageInputChange}
                          value={rowsPerPage}
                        >
                          <MenuItem value={10}>{10}</MenuItem>
                          <MenuItem value={25}>{25}</MenuItem>
                          <MenuItem value={50}>{50}</MenuItem>
                          <MenuItem value={100}>{100}</MenuItem>
                        </Select>
                        <img
                          className="count-arrow"
                          src="../../assets/images/arrowupdown.svg"
                        />
                      </div>
                      <Pagination
                        count={Math.ceil(
                          ticketList?.listAllOrderTicket?.count / rowsPerPage
                        )}
                        page={page}
                        shape="rounded"
                        onChange={handleChangePage}
                      />
                    </div>
                  )}
                </TableContainer>
              </div>
            </div>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "dropdown-button",
              }}
            >
              {(selectedItem?.status==="Ready-For-Payment" || selectedItem?.status==="Open") && <MenuItem onClick={handleClose}>
                  <a
                    className="actionbtn-menu"
                    onClick={() => setCancelId(selectedItem?._id)}
                    href="javascript:;"
                  >
                    Delete
                  </a>
                </MenuItem>
                }
                <MenuItem>
                  <a
                    className="actionbtn-menu"
                    href={`/orderdetails/${selectedItem?.orderId}`}
                  >
                    View
                  </a>
                </MenuItem>
                {selectedItem?.status==="Paid" && selectedItem?.eventDtls?.lastDateToCancel && 
                <MenuItem onClick={handleClose}>
                  <a
                    className="actionbtn-menu"
                    onClick={() => setRefundOpen(true)}
                    href="javascript:;"
                  >
                    Cancel for Refund
                  </a>
                </MenuItem>}
            </Menu>
          </div>
        </div>
      </div>
    </div>
  );
}
