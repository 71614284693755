import { InputLabel, TextField, Select, MenuItem, Checkbox, FormControlLabel } from "@mui/material";
import { gender, lowestgrade, highestgrade } from "../SchoolOptions";
import { Controller } from "react-hook-form";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { RadioButtonUnchecked } from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export default function Highlights(props) {
  const { register, errors, defaultValue, control } = props;
  const pageUrl = window.location.pathname;
  
  function convertDate(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("/");
  }

  return (
    <div className="card">
      <div className="card-title">
        <img src="../../assets/images/Highlight.svg" /> Highlights
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-md-12 mb-3">
            <InputLabel className="form-label">Gender</InputLabel>
            {/* <TextField
              style={{ width: "100%" }}
              variant="outlined"
              {...register("gender")}
              // error={errors?.gender}
              defaultValue={defaultValue?.schGender}
            /> */}
            <Select
              className="w-100"
              variant="outlined"
              {...register("schGender")}
              defaultValue={defaultValue?.schGender}
            >
              {gender.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
          </div>
          <div className="col-md-12 mb-3">
            <InputLabel className="form-label">Lowest Grade<sup className="text-danger">*</sup></InputLabel>
            <Select
              className="w-100"
              variant="outlined"
              {...register("schLowestGrade")}
              defaultValue={defaultValue.schLowestGrade}
            >
               {lowestgrade.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
            {errors && errors?.["schLowestGrade"] && (
              <p className="errorMessage text-danger">
                {errors?.schLowestGrade?.message}
              </p>
            )}
          </div>
          <div className="col-md-12 mb-3">
            <InputLabel className="form-label">Highest Grade<sup className="text-danger">*</sup></InputLabel>
            <Select
              className="w-100"
              variant="outlined"
              {...register("schHighestGrade")}
              defaultValue={defaultValue.schHighestGrade}
            >
              {highestgrade.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
            {errors && errors?.["schHighestGrade"] && (
              <p className="errorMessage text-danger">
                {errors?.schHighestGrade?.message}
              </p>
            )}
          </div>
          <div className="col-md-12 mb-3">
            <InputLabel className="form-label">Student Enrollment</InputLabel>
            <TextField
              style={{ width: "100%" }}
              variant="outlined"
              {...register("schEnrollment")}
              // error={errors?.schEnrollment}
              defaultValue={defaultValue?.schEnrollment}
            />
            {errors && errors?.["schEnrollment"] && (
              <p className="errorMessage text-danger">
                {errors?.schEnrollment?.message}
              </p>
            )}
          </div>
          <div className={pageUrl.includes("/addnewschool") ? "d-none" : "col-md-12 mb-3 chechbox-label"}>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={defaultValue?.schEnrollHide}
                {...register(`schEnrollHide`)}
                icon={<RadioButtonUnchecked />}
                checkedIcon={<CheckCircleIcon />}
              />
            }
            label={"Hide Enrollment In Public View"}
          />
          </div>
          <div className={pageUrl.includes("/addnewschool") ? "d-none" :"col-md-12 mb-3"}>
            <InputLabel className="form-label">
              NYSAIS Accredited since
            </InputLabel>
            <Controller
              control={control}
              name="schMemberSince"
              render={({ field: { onChange, value } }) => (
                <>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    inputFormat="MM/dd/yyyy"
                    value={value ? value : null}
                    // maxDate={new Date()}
                    renderInput={(params) => (
                      <TextField {...params} className="date-field"/>
                    )}
                    onChange={(e) => onChange(convertDate(e))}
                  />
                  </LocalizationProvider>
                  {errors && errors?.["schMemberSince"] && (
                    <p className="errorMessage text-danger">
                      {errors?.schMemberSince?.message}
                    </p>
                  )}
                </>
              )}
            />
            {/* <TextField
              style={{ width: "100%" }}
              variant="outlined"
              {...register("schMemberSince")}
              // error={errors?.schMemberSince}
              defaultValue={defaultValue?.schMemberSince}
            /> */}
          </div>
          {/* <div className="col-md-12 mb-3 d-flex align-items-center">
            <div className="switch-outer">
              <Switch
                defaultChecked={defaultValue?.schFeatredOnHome}
                {...register("schFeatredOnHome")}
              />
            </div>
            <InputLabel className="form-switch-label">
              Feature on Homepage
            </InputLabel>
          </div> */}
        </div>
      </div>
    </div>
  );
}
