import React, { useState } from "react";
import { CircularProgress, IconButton } from "@mui/material";
import { useReactiveVar } from "@apollo/client";
import { authVar, userDeatils } from "../../RactiveVariables/index";
import { useMutation } from "@apollo/client";
import { toggleSnackbar } from "../../RactiveVariables/index";
import { DELETE_EVENT } from "../../Graphql/mutations";
import { useHistory, Link, useLocation } from "react-router-dom";
import { ConfirmDialog } from "../Common";

export default function EventHeader(props) {
  const { addevents, eventpreview, home, addschoolevents, setDeleteDraft } = props;
  const auth = useReactiveVar(authVar);
  const location = useLocation();
  const path = location?.state?.detail;
  const user = useReactiveVar(userDeatils);
  const currentUrl = window.location.href;
  const [deleteEvent, { loading: deleteLoadinng }] = useMutation(DELETE_EVENT);
  const history = useHistory();
  const [deleteId, setDeleteId] = useState(null);

  function handleDelete() {
    deleteEvent({ variables: { eventIds: [deleteId] } })
      .then((res) => {
        toggleSnackbar({
          status: true,
          message: "Event deleted successfully",
          variant: "success",
        });
        // handlePagination();
        history.push("/events");
      })
      .catch((error) => {
        const message = error && error.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      });
  }

  function closeConfirm() {
    setDeleteId(null);
  }
  function handleConfirm() {
    handleDelete();
    closeConfirm();
  }
  function handleRegister() {
    if(!auth.auth){
      props?.setEventDetails();
      history.push("/schoollogin");
    }
    else
      props.setRegisterOpen(true)
  }
  return (
    <header className="event-header">
      <ConfirmDialog
        open={deleteId}
        title={"Delete"}
        body={"Are you sure you want to delete this Event ? "}
        closeText={"Cancel"}
        confirmText={"Delete"}
        closeConfirm={closeConfirm}
        handleConfirm={handleConfirm}
      />
      <div className="event-btn-outer">
        <div className={(currentUrl.includes("/addschoolevent")||currentUrl.includes("/addschoolcareer"))?"mob-margin container-margin":"container-margin"}>
          <div className="row">
            <div className="col-sm-12">
              <div className={currentUrl.includes("/eventregistration")?"d-md-flex justify-content-between align-items-center":"d-flex justify-content-between align-items-center"}>
                <h3 className={currentUrl.includes("/eventregistration")?"event-head d-md-block d-none":"event-head"}>{props.title}</h3>
                {addevents && (
                  <div className="d-flex">
                    <Link to="/events" className="btn btn-outline-light">
                      Cancel
                    </Link>
                    <button type="submit" className="btn btn-light ms-3">
                      {props.saveBtnLoading ? (
                        <CircularProgress size="20px" color="inherit" />
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                )}
                {eventpreview && (
                  <div className="d-flex">
                    {auth?.auth ? (
                      <>
                        <Link
                          to={user.data?.loginType==="Admin" ? "/events":"/myevents"}
                          type="submit"
                          className="btn back-btn"
                        >
                          <img src="../assets/images/arrow-left.svg" />
                        </Link>
                        {props?.register && user.data?.loginType==="Admin" &&  <a
                          href="javascript:;"
                          className="btn btn-outline-light ms-3"
                          onClick={()=>handleRegister()}
                        >
                          Register
                        </a>}
                        {/* <a
                          href="javascript:;"
                          onClick={() => setDeleteId(props?.id)}
                          className="btn btn-outline-light ms-3"
                        >
                          Cancel
                        </a> */}
                        {user.data?.loginType==="Admin" &&<Link
                          to={`/addevent/${props.id}`}
                          className="ms-3 btn btn-outline-light"
                        >
                          Edit
                        </Link>}
                        {user.data?.loginType==="Admin" &&<Link
                          to={`/cloneevent/${props.id}`}
                          className="ms-3 btn btn-outline-light"
                        >
                          Clone
                        </Link>}
                        {user.data?.loginType==="Admin" &&<a
                          href="javascript:;"
                          onClick={()=> setDeleteDraft(true)}
                          className="ms-3 btn btn-outline-light"
                        >
                          Delete draft orders
                        </a>}
                        {/* <button
                          className="d-flex align-items-center btn btn-outline-light ms-3"
                          type="submit"
                        >
                          <img
                            className="me-2"
                            src="../assets/images/previewcalendar.svg"
                          />
                          Calendar
                        </button> */}
                      </>
                    ) : (
                      <>
                      <Link
                        to="/"
                        style={{ top: "-11px" }}
                        type="submit"
                        className="btn back-btn"
                      >
                        <img src="../assets/images/arrow-left.svg" />
                      </Link>
                      {props?.register && <a
                          href="javascript:;"
                          className="btn btn-outline-light ms-3"
                          onClick={()=>handleRegister()}
                        >
                          Register
                        </a>}
                      </>
                    )}
                  </div>
                )}
                {home && (
                  <div className="d-flex align-items-center justify-content-betweeen header-home">
                    <div className="d-flex align-items-center menu-items">
                      <a href="javascript:;">Home</a>
                      <Link to="/">Events</Link>
                    </div>
                    <Link to={`/login`} className="ms-3 btn btn-outline-light">
                      Login
                    </Link>
                  </div>
                )}
                {(addschoolevents || props?.addschoolCareer) && (
                  <div className="d-flex">
                    {props.schoolDetails?.schoolId && !props.schoolId ? (
                      <Link
                        className="btn btn-outline-light"
                        to={{
                          pathname: `/schoolpreview/${props?.schoolId}`,
                          selectedTab: addschoolevents ? 2 : 3,
                        }}
                      >
                        Cancel
                      </Link>
                    ) : (
                      <a href="javascript:;"
                        className="btn btn-outline-light"
                        onClick={() => {
                          if (user.data?.loginType!=="Admin") {
                            history.replace(props?.backurl ?? "/schoolcareer");
                          } else {
                            history.replace({
                              pathname: `/SchoolPreview/${props?.schoolId}`,
                              selectedTab: addschoolevents ? 2 :3,
                            });
                          }
                        }}
                      >
                        Cancel
                      </a>
                    )}
                    <button type="submit" className="btn btn-light ms-3">
                      {props.saveBtnLoading ? (
                        <CircularProgress size="20px" color="inherit" />
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                )}
                {props?.schooleventpreview && (
                  <div className="d-flex">
                    {
                      props?.backUrl ? 
                      <Link to = {props?.backUrl} className="btn back-btn">
                        <img src="../assets/images/arrow-left.svg" />
                      </Link>
                    :
                    <a href="javascript:;" onClick={()=>{
                      if (user?.data?.loginType!=="Admin") {
                        history.replace("/schoolevent");
                      } else {
                        history.replace({
                          pathname: `/SchoolPreview/${props?.schoolId}`,
                          selectedTab: 2,
                        });
                      }
                    }} type="submit" className="btn back-btn">
                      <img src="../assets/images/arrow-left.svg" />
                    </a>
                    }
                    <Link
                      to={`/addschoolevent/${props?.schoolId}||${props?.id}`}
                      className="ms-3 btn btn-outline-light"
                    >
                      Edit
                    </Link>
                  </div>
                )}
                {props?.EventRegistration && (
                  <div className="d-flex align-items-center justify-content-end">
                    {!path&&<a href="javascript:;" onClick={()=> user?.data?.loginType === "Admin" ? history.goBack() : history.push("/orderlisting")} type="submit" className="btn back-btn">
                      <img src="../assets/images/arrow-left.svg" />
                    </a>}
                    { props?.saveBtn==="Open" && (
                      // <Link to={"/orderlisting"}>
                      <button onClick={()=> user?.data?.loginType === "Admin" ? history.goBack() : history.push("/orderlisting")} className="btn btn-outline-light ms-3" >
                      I'll do this later
                    </button>
                    // </Link>
                    )}
                    {path==="finance"&&<IconButton onClick={()=>user?.data?.loginType === "Admin" && history.goBack()}>
                      <img style = {{width:"28px" }} src="../assets/images/close.svg" />
                    </IconButton>}
                  </div>
                )}
                {props?.EventRegistration2 && (
                  <div className="d-flex align-items-center">
                    <a href="javascript:;"
                     onClick={props?.addUserOpen ? ()=> props?.setAddUserOpen(false) : ()=>history.goBack()}
                      type="submit" className="btn back-btn">
                      <img src="../assets/images/arrow-left.svg" />
                    </a>
                    {/* {
                      props?.addUserOpen && 
                    <button type="submit" className="btn btn-light" onClick={()=>document.getElementById("addmember-submit-btn").click()}>Next</button>
                    } */}
                  </div>
                )}
                {props?.schoolCareerpreview && (
                  <div className="d-flex">
                    <a href="javascript:;" onClick={()=>history.goBack()} type="submit" className="btn back-btn">
                      <img src="../assets/images/arrow-left.svg" />
                    </a>
                    <Link
                      to={`/addschoolcareer/${props?.schoolId}||${props?.id}`}
                      className="ms-3 btn btn-outline-light"
                    >
                      Edit
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
