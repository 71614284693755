import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  CircularProgress,
  FormGroup,
  FormControlLabel,
  Checkbox,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useMutation, useReactiveVar, useLazyQuery } from "@apollo/client";
import {
  LOGIN_ADMIN,
  LOGIN_SCHOOL,
  FORGET_PASS,
  FORGET_PASSSCHOOL,
  CREATE_ORDER,
  USER_ACTIVATION,
} from "../../Graphql/mutations";
import { SCHOOL_DETAILS, EVENT_DETAILS } from "../../Graphql/queries";
import {
  authVar,
  userDeatils,
  schoolDtls,
  toggleSnackbar,
  registerEvent,
} from "../../RactiveVariables/index";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Link, useHistory, useParams } from "react-router-dom";
import { Loader } from "../Common";
import moment from "moment";
import LoginHeader from "../Common/LoginHeader";
import LoginMobHeader from "../Common/LoginMobHeader";

const Login = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const regEvent = useReactiveVar(registerEvent);
  const [createOrders] = useMutation(CREATE_ORDER);
  const [getSchoolDeatils] = useLazyQuery(SCHOOL_DETAILS);
  const [getEventDeatils,{data:eventData}] = useLazyQuery(EVENT_DETAILS);
  const [forgetPass, setForgetPass] = useState(false);
  const [cookieVariable, setCookieVariable] = useState(null);
  const [activateUser, { data, loading: activateLoading }] = useMutation(USER_ACTIVATION,{
    onCompleted:res=>{
      registerEvent({eventId:res?.schUserActivation?.regEventId})
    }
  });
  useEffect(()=>{
    if(id){
      activateUser({variables:{resetLink:id}})
    }
    if(regEvent?.eventId){
      getEventDeatils({variables:{id:regEvent?.eventId}})
    }
  },[])
  const schema = yup.object().shape({
    email: yup
      .string()
      .required("Email is required")
      .email("Email must be valid"),
    password: forgetPass ? null : yup.string().required("Password is required"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  const [login, { loading }] = useMutation(LOGIN_ADMIN);
  const [loginSch, { loading: schloading }] = useMutation(LOGIN_SCHOOL);
  const [forgetPassword, { loading: forgetLoading }] = useMutation(FORGET_PASS);
  const [forgotSchPassword, { loading: forgotSchLoading }] =
    useMutation(FORGET_PASSSCHOOL);
  const Pathname = props.location.pathname;

  const onSubmit = (data) => {
    if (Pathname === "/login") {
      if (!forgetPass) {
        try {
          login({ variables: data })
            .then((res) => {
              const responseData = res.data && res.data.login;
              const token = responseData.token;
              localStorage.setItem("@authToken", token);
              userDeatils({ data: res?.data?.login?.userNew });
              schoolDtls({
                data: res?.data?.login?.userNew?.schoolDetails,
                schoolId: res?.data?.login?.userNew?.schoolId,
              });
              authVar({
                loaded: true,
                auth: true,
              });
            })
            .catch((err) => {
              const message = err && err.message;
              toggleSnackbar({
                status: true,
                message: message,
                variant: "error",
              });
            });
        } catch (err) {
          const message = err && err.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        }
      } else {
        try {
          forgetPassword({ variables: { email: data.email } })
            .then((res) => {
              const message = res?.data?.forgotPassword?.message;
              toggleSnackbar({
                status: true,
                message: message,
                variant: "success",
              });
            })
            .catch((err) => {
              const message = err && err.message;
              toggleSnackbar({
                status: true,
                message: message,
                variant: "error",
              });
            });
        } catch (err) {
          const message = err && err.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        }
      }
    } else {
      if (!forgetPass) {
        try {
          loginSch({ variables: data })
            .then((res) => {
              const token = res.data.loginSch.token;
              // wp cookie vaiable
              setCookieVariable(token);
              // wp cookie vaiable
              localStorage.setItem("@authToken", token);
              if(regEvent?.eventId || (res?.data?.loginSch?.userNew?.regEventId && res?.data?.loginSch?.userNew?.orderCount===0)){
                if(res?.data?.loginSch?.userNew?.schoolId){
                  getSchoolDeatils({variables:{id:id}}).then((schDtl)=>{
                    let createEventData = {
                      "orderCreatedFrom" : "FrontEnd",
                      "eventId" : regEvent?.eventId || res?.data?.loginSch?.userNew?.regEventId,
                      "memberPrice":schDtl?.data?.schoolDetails?.schStatus!=="Non-Member" ? parseInt(eventData?.data?.memberPrice) : parseInt(eventData?.data?.nonMemberPrice),
                      "totalPrice" : 0,
                      "paymentMethod" : "Card",
                      "orderType": "Normal",
                      "orderCreatedFor":res?.data?.loginSch?.userNew?._id,
                      "schoolId": res?.data?.loginSch?.userNew?.schoolId,
                      "orderDate": moment().format("MM/DD/YYYY"),
                      "orderAsGuest": res?.data?.loginSch?.userNew?.schoolId ? false : true,
                    }
                    const responseData = res.data && res.data.loginSch;
                    const token = responseData.token;
                    localStorage.setItem("@authToken", token);
                    // wp cookie vaiable
                    setCookieVariable(token);
                    // wp cookie vaiable
                    createOrders({ variables: {data:createEventData} })
                    .then((orderRes) => {
                      toggleSnackbar({
                        status: true,
                        message: "Order has been saved as draft",
                        variant: "success",
                      });
                    userDeatils({ data: res?.data?.loginSch?.userNew });
                    schoolDtls({
                      data: res?.data?.loginSch?.userNew?.schoolDetails,
                      schoolId: res?.data?.loginSch?.userNew?.schoolId,
                    });
                    authVar({
                      loaded: true,
                      auth: true,
                    });
                      history.push(`/eventregistration/${orderRes?.data?.createOrders?._id}`)
                    })
                    .catch((err) => {
                      if(err?.graphQLErrors?.[0]?.message === "This Event is only for NYSAIS Members"){
                        
                        localStorage.setItem("@authToken", token);
                        userDeatils({ data: res?.data?.loginSch?.userNew });
                        schoolDtls({
                          data: res?.data?.loginSch?.userNew?.schoolDetails,
                          schoolId: res?.data?.loginSch?.userNew?.schoolId,
                        });
                        authVar({
                          loaded: true,
                          auth: true,
                        });

                        toggleSnackbar({
                          status: true,
                          message: err?.graphQLErrors?.[0]?.message,
                          variant: "error",
                        });
                        history.push(`/myevents`);
                      }
                    })
                  })
                }
                else{
                  let createEventData = {
                    "orderCreatedFrom" : "FrontEnd",
                    "eventId" : regEvent?.eventId || res?.data?.loginSch?.userNew?.regEventId,
                    "memberPrice":parseInt(regEvent?.nonMemberPrice),
                    "totalPrice" : 0,
                    "paymentMethod" : "Card",
                    "orderType": "Normal",
                    "orderCreatedFor":res?.data?.loginSch?.userNew?._id,
                    "schoolId": res?.data?.loginSch?.userNew?.schoolId,
                    "orderDate": moment().format("MM/DD/YYYY"),
                    "orderAsGuest": res?.data?.loginSch?.userNew?.schoolId ? false : true,
                  }
                  createOrders({ variables: {data:createEventData} })
                  .then((orderRes) => {
                    toggleSnackbar({
                      status: true,
                      message: "Order has been saved as draft",
                      variant: "success",
                    });
                    const responseData = res.data && res.data.loginSch;
                    const token = responseData.token;
                    // wp cookie vaiable
                    setCookieVariable(token);
                    // wp cookie vaiable
                    localStorage.setItem("@authToken", token);
                    userDeatils({ data: res?.data?.loginSch?.userNew });
                    schoolDtls({
                      data: res?.data?.loginSch?.userNew?.schoolDetails,
                      schoolId: res?.data?.loginSch?.userNew?.schoolId,
                    });
                    authVar({
                      loaded: true,
                      auth: true,
                    });
                    history.push(`/eventregistration/${orderRes?.data?.createOrders?._id}`)
                  })
                  .catch((err) => {
                    if(err?.graphQLErrors?.[0]?.message === "This Event is only for NYSAIS Members"){
                      localStorage.setItem("@authToken", token);
                      userDeatils({ data: res?.data?.loginSch?.userNew });
                      schoolDtls({
                        data: res?.data?.loginSch?.userNew?.schoolDetails,
                        schoolId: res?.data?.loginSch?.userNew?.schoolId,
                      });
                      authVar({
                        loaded: true,
                        auth: true,
                      });
                      toggleSnackbar({
                        status: true,
                        message: err?.graphQLErrors?.[0]?.message,
                        variant: "error",
                      });
                      history.push(`/myevents`);
                    }
                  })
                }
              }
              else{
                const responseData = res.data && res.data.loginSch;
                  const token = responseData.token;
                  // wp cookie vaiable
                  setCookieVariable(token);
                  // wp cookie vaiable
                  localStorage.setItem("@authToken", token);
                  userDeatils({ data: res?.data?.loginSch?.userNew });
                  schoolDtls({
                    data: res?.data?.loginSch?.userNew?.schoolDetails,
                    schoolId: res?.data?.loginSch?.userNew?.schoolId,
                  });
                  authVar({
                    loaded: true,
                    auth: true,
                  });
                  history.push("/orderlisting")
              }
            })
            .catch((err) => {
              const message = err && err.message;
              toggleSnackbar({
                status: true,
                message: message,
                variant: "error",
              });
            });
        } catch (err) {
          const message = err && err.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        }
      } else {
        try {
          forgotSchPassword({ variables: { email: data.email } })
            .then((res) => {
              const message = res?.data?.forgotSchPassword?.message;
              toggleSnackbar({
                status: true,
                message: message,
                variant: "success",
              });
            })
            .catch((err) => {
              const message = err && err.message;
              toggleSnackbar({
                status: true,
                message: message,
                variant: "error",
              });
            });
        } catch (err) {
          const message = err && err.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        }
      }
    }
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };  
  return (
    <div className="login-outer">
      <LoginHeader />
      <LoginMobHeader/>
      <Loader open={activateLoading} />
      <div className="login" style={{ overflowY: "hidden", height: "auto" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 d-flex align-items-center justify-content-center">
              <div className="logo-section">
                <a href={process.env.REACT_APP_WP_URL}> <img
                  src="../assets/images/login-logo.svg"
                  className="img-fluid login-logo"
                  alt="logo"
                /></a>
                <p>
                  NYSAIS is an advocate of the independent schools of New York
                  State, speaking for their independence, well-being, and
                  reputation before the agencies of government, the media, and
                  the public
                </p>
              </div>
            </div>
            <div className="col-lg-6 d-flex justify-content-center">
              <div className="card login-card">
                <div className="card-body">
                  <div className="login-forms">
                    <div className="main-title">
                      {!forgetPass ? (
                        <h3 onClick={() => history.push("/schoollogin")}>
                          {Pathname === "/login"
                            ? "Login"
                            : "School User Login"}{" "}
                        </h3>
                      ) : (
                        <h3>Forgot password</h3>
                      )}
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)} noValidate>
                      <div>
                        <label className="form-label">
                          Email <sup className="text-danger">*</sup>
                        </label>
                        <input
                          className="form-control"
                          type="email"
                          autoFocus
                          error={errors?.email?.message}
                          {...register("email")}
                        />
                        {errors.email && (
                          <span className="text-danger">
                            {errors?.email?.message}
                          </span>
                        )}
                      </div>
                      {!forgetPass && (
                        <div className="mt-3">
                          <label
                            className="form-label"
                            for="standard-adornment-password"
                          >
                            Password <sup className="text-danger">*</sup>
                          </label>
                          <OutlinedInput
                            className="form-control"
                            id="standard-adornment-password"
                            type={showPassword ? "text" : "password"}
                            error={errors?.password?.message}
                            // value={values.password}
                            {...register("password")}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                          {errors.password && (
                            <span className="text-danger">
                              {errors?.password?.message}
                            </span>
                          )}
                          {/* <span className="text-end my-2 d-block text-primary cursor-pointer" onClick={() => setForgetPass(true)}>Forgot Password?</span> */}
                        </div>
                      )}
                      <div className="change-section">
                        {!forgetPass ? (
                          <>
                            <div className="d-flex w-100 justify-content-between align-items-center">
                              <FormGroup>
                                <FormControlLabel
                                  className="text-primary"
                                  control={<Checkbox {...register("rememberMe")} />}
                                  label="Remember me"
                                />
                              </FormGroup>
                              <span
                                className="text-end my-2 d-inline-block forgot-link cursor-pointer"
                                onClick={() => setForgetPass(true)}
                              >
                                Forgot Password?
                              </span>
                            </div>
                          </>
                        ) : (
                          <div className="my-2">
                            <span
                              className="text-end my-2 d-none text-primary cursor-pointer"
                              onClick={() => setForgetPass(false)}
                            >
                              back to login
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="text-center">
                        <Button
                          className="btn btn-primary login-btn"
                          disabled={
                            loading ||
                            schloading ||
                            forgetLoading ||
                            forgotSchLoading
                          }
                          type="submit"
                          color="primary"
                        >
                          {loading ||
                          schloading ||
                          forgetLoading ||
                          forgotSchLoading ? (
                            <CircularProgress color="secondary" />
                          ) : forgetPass ? (
                            "REQUEST"
                          ) : (
                            "Login"
                          )}
                        </Button>
                        <div className="or-text">Or</div>
                        <Link
                          className="btn btn-outline-orange"
                          to={"/register"}
                        >
                          Register as a New User
                        </Link>
                      </div>
                      <div className="change-section">
                        {forgetPass && (
                          <span
                            className="text-end my-2 d-inline-block forgot-link cursor-pointer"
                            onClick={() => setForgetPass(false)}
                          >
                            back to login
                          </span>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {cookieVariable && <iframe src={'http://nysaiswodpress.local/cookie-page/'+'?token='+cookieVariable}></iframe>} */}
      {/* <iframe src="http://nysaiswodpress.local/cookie-page/?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MWIwZjhjNDJjNzcyNDIxOGQ5YjJhY2EiLCJ1c2VybmFtZSI6ImFudUBodWJzcGlyZS5jb20iLCJlbWFpbCI6ImFudUBodWJzcGlyZS5jb20iLCJuYW1lIjoiS2F0aGVyaW5lMSBQYXRyaWNrIiwiaWF0IjoxNjU1MjA2Njc4LCJleHAiOjE2NTUyNDk4Nzh9.pFTw_CERmKJRmF6M6OrG4JGAWlA42ZIffwmBWnkbNFI"></iframe> */}
    </div>
  );
};

export default Login;
