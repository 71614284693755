import { Button, MenuItem, Select, TextField, InputLabel, Autocomplete } from "@mui/material";
import { useLazyQuery, useQuery, useReactiveVar } from "@apollo/client";
import { CHECK_DOMAIN, CHECK_DOMAIN_AND_USER } from "../../Graphql/queries";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  regDomainVar,
  registerEvent,
  toggleSnackbar,
} from "../../RactiveVariables";
import DomainCheck from "./DomainCheck";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { ConfirmDialog } from "../Common";
import { useHistory } from "react-router-dom";
import axios from "axios";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function RegisterBox(props) {
  const { selectedSchool, setSelectedSchool, setAddUserOpen, schoolList } =
    props;
  var listData = props.schoolList?.listSchool?.data ? [...props.schoolList.listSchool.data] : [];
  const [expanded, setExpanded] = useState("panel1");
  const regEvent = useReactiveVar(registerEvent);
  const [emailValue, setEmailValue] = useState("");
  const [schNameValue, setSchNameValue] = useState("");
  const [schWebsiteValue, setSchWebsiteValue] = useState("");
  const [conformMessage, setConformMessage] = useState(null);
  const [chkDoamin] = useLazyQuery(CHECK_DOMAIN, {
    fetchPolicy: "network-only",
  });
  const history = useHistory();
  const domainVar = useReactiveVar(regDomainVar);

  useEffect(() => {
    regDomainVar(null);
  }, []);

  const handleChange = (panel) => (event, newExpanded) => {
    setEmailValue("");
    if (panel === "panel3") {
      setSelectedSchool(null);
    }
    setExpanded(newExpanded ? panel : false);
  };

  function formatWebsiteValue(website) {
    if (website && website.length > 3) {
      if (
        website[0].toLowerCase() === "w" &&
        website[1].toLowerCase() === "w" &&
        website[2].toLowerCase() === "w" &&
        website[3] === "."
      )
        return website.replace(/www./g, "");
      else return website;
    }
  }
  function handleMemberClick() {
    if (emailValue === "") {
      toggleSnackbar({
        status: true,
        message: "Please enter an email",
        variant: "error",
      });
    } else if (
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        emailValue
      )
    ) {
      toggleSnackbar({
        status: true,
        message: "Please enter a valid email",
        variant: "error",
      });
    } else {
      chkDoamin({
        variables: {
          domain: null,
          emailId: emailValue,
          cType: "User",
        },
        onCompleted: (res) => {
          if (res.checkDomain.status) {
            setConformMessage({
              message:
                "Name: " +
                res.checkDomain.userRes.firstName +
                " " +
                res.checkDomain.userRes.lastName,
              positiveBtn: "Login",
            });
          } else {
            // let obj = domainVar || {};
            // obj["email"] = emailValue;
            regDomainVar({ email: emailValue });
            setAddUserOpen(true);
          }
        },
      });
    }
  }
  function closeConfirm() {
    setConformMessage(null);
    setSelectedSchool(null);
  }

  function handleConfirm() {
    if (conformMessage.setEmail) {
      let domVar = { ...domainVar };
      domVar["email"] = emailValue;
      domVar["schName"] = conformMessage.schName;
      regDomainVar(domVar);
    }
    if (conformMessage.addUser) setAddUserOpen(true);
    else history.push("/schoollogin");
  }
  function handleSchoolClick(guest) {
    if (emailValue === "") {
      toggleSnackbar({
        status: true,
        message: "Please enter an email",
        variant: "error",
      });
    } else if (
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        emailValue
      )
    ) {
      toggleSnackbar({
        status: true,
        message: "Please enter a valid email",
        variant: "error",
      });
    } else if (schNameValue === "" && !guest) {
      toggleSnackbar({
        status: true,
        message: "Please enter a school name",
        variant: "error",
      });
    } else if (schWebsiteValue === "" && !guest) {
      toggleSnackbar({
        status: true,
        message: "Please enter a school website",
        variant: "error",
      });
    } else if (emailValue.split("@")[1] !== schWebsiteValue && !guest) {
      toggleSnackbar({
        status: true,
        message: "Email must match with school domain",
        variant: "error",
      });
    } else {
      let config = {
        operationName: "checkDomainAndUser",
        variables: {
          domain: guest ? emailValue.split("@")[1] : schWebsiteValue,
          emailId: emailValue,
        },
        query:
          "query checkDomainAndUser($domain: String, $emailId: String) {\n  checkDomainAndUser(\n    domain:$domain \n  emailId:$emailId \n ) {\n    schStatus \n    userStatus\n  schoolRes {\n        schName\n        _id\n        __typename\n      }\n  userRes {\n        firstName\n        lastName\n  email\n        __typename\n      }\n    __typename\n  }\n}",
      };
      axios
        .post(process.env.REACT_APP_API_URL+"/graphql", config)
        .then((response) => {
          let res = response?.data;
          if (res?.data?.checkDomainAndUser?.userStatus === true) {
            // setConformMessage({
            //   title: "User already exist",
            //   message:
            //     "Name: " +
            //     res?.data?.checkDomainAndUser.userRes.firstName +
            //     " " +
            //     res?.data?.checkDomainAndUser.userRes.lastName,
            //   positiveBtn: "Login",
            // });
            toggleSnackbar({
              status: true,
              message: "User already exists",
              variant: "error",
            });
          } else if (res?.data?.checkDomainAndUser?.schStatus === true) {
            setSelectedSchool(res.data?.checkDomainAndUser.schoolRes._id);
            setConformMessage({
              title: "School already exist",
              message:"Based on your email, your school already exist in our system, please click on continue to register yourself as a user under that school.",
                // "Name: " + res.data?.checkDomainAndUser.schoolRes.schName,
              positiveBtn: "Continue",
              addUser: true,
              setEmail: true,
              schName: res.data?.checkDomainAndUser.schoolRes.schName,
            });
          } else {
            if (guest) {
              regDomainVar({ email: emailValue });
              setSelectedSchool(null);
              setAddUserOpen(true);
            } else {
              regDomainVar({
                schName: schNameValue,
                domain: schWebsiteValue,
                email: emailValue,
              });
              history.push("/addnewschool/$");
            }
          }
        });
    }
  }
  function sortLabels(a, b) {
    var nameA = a.schName.toUpperCase();
    var nameB = b.schName.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  }
  const listSchool= listData.sort(sortLabels).filter(function(e) {
    return e.schStatus !== "Non-Member"; })

  const filteredSchoolList = listSchool.map(({ _id, schNameThe }) => ({ _id, schNameThe }));
  console.log("listSchool",selectedSchool);

  return (
    <div className="register">
      <ConfirmDialog
        open={conformMessage}
        title={conformMessage?.title}
        body={conformMessage?.message}
        closeText={"Cancel"}
        confirmText={conformMessage?.positiveBtn}
        closeConfirm={closeConfirm}
        handleConfirm={handleConfirm}
      />
      <p className="reg-title">Register</p>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          IconButtonProps={{ edge: "start" }}
        >
          <p className="reg-label m-0">My School is a NYSAIS Member</p>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <p>Please select your School from the list below.</p>
            {/* <Select
              className="form-select ps-0"
              value={selectedSchool||"1"}
              onChange={(e) => setSelectedSchool(e.target.value)}
            >
              <MenuItem value="1" disabled>
                Select a school
              </MenuItem>
              {listData.sort(sortLabels).filter(function(e) {
                return e.schStatus !== "Non-Member"; }).map((item) => (
                <MenuItem value={item?._id}>{item.schNameThe}</MenuItem>
              ))}
            </Select> */}
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={filteredSchoolList??[]}
              getOptionLabel={(options=>options?.schNameThe)}
              sx={{ width: '100%' }}
              onChange={(event, selectedOption) => {
                // Access the _id value from the selected option
                const selectedId = selectedOption?._id;
                // Update the state or perform other actions
                setSelectedSchool(selectedId);
              }}
              renderInput={(params) => <TextField {...params} label="Select a School" />}
              renderOption={(props, option) => (
                <li {...props} key={option?._id}>
                  {option?.schNameThe}
                </li>
              )}
            />
          </div>

          <div className="my-20">
            <Button
              className="btn btn-orange w-100 text-white"
              disabled={selectedSchool == "1"}
              onClick={() => {
                let data = { ...domainVar };
                let pos = schoolList?.listSchool?.data.findIndex(
                  (element) => element._id === selectedSchool
                );
                if (pos > -1)
                  data["schName"] = schoolList?.listSchool?.data[pos]?.schName;
                data["schoolId"] = selectedSchool;
                regDomainVar(data);
                setAddUserOpen(true);
              }}
            >
              Confirm
            </Button>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <p className="reg-label m-0">Create a Non Member Account</p>
        </AccordionSummary>
        <AccordionDetails>
          <form>
            <InputLabel className="form-label">
              School Name<sup className="text-danger">*</sup>
            </InputLabel>
            <TextField
              className="w-100 mb-3 text-field"
              // variant="outlined"
              value={schNameValue}
              placeholder="School Name"
              onChange={(e) => setSchNameValue(e.target.value)}
            />
            <InputLabel className="form-label">
              School Website Address<sup className="text-danger">*</sup>
            </InputLabel>
            <TextField
              className="w-100 mb-3 text-field www-sign"
              // variant="outlined"
              placeholder="nysais.org"
              value={schWebsiteValue}
              onChange={(e) =>
                setSchWebsiteValue(formatWebsiteValue(e.target.value))
              }
            />
            <InputLabel className="form-label">
              Official School Email Address<sup className="text-danger">*</sup>
            </InputLabel>
            <TextField
              className="w-100 mb-3 text-field"
              // variant="outlined"
              value={emailValue}
              placeholder="Email"
              onChange={(e) => setEmailValue(e.target.value.trim())}
            />
          </form>

          <Button
            className="btn btn-orange w-100 text-white"
            onClick={()=>handleSchoolClick(false)}
          >
            Create new
          </Button>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <p className="reg-label m-0">Create an Individual Account</p>
        </AccordionSummary>
        <AccordionDetails>
          <InputLabel className="form-label">
            Email Address<sup className="text-danger">*</sup>
          </InputLabel>
          <TextField
            className="w-100 mb-3 text-field"
            // variant="outlined"
            value={emailValue}
            placeholder="Email"
            onChange={(e) => setEmailValue(e.target.value.trim())}
          />
          <Button
            className="btn btn-orange w-100 text-white"
            onClick={() => handleSchoolClick(true)}
          >
            Register with us
          </Button>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
