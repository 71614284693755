import { useState } from "react";
import { IconButton, Menu, MenuItem, Avatar,Button } from "@mui/material";
import AddUser from "../School/Member/AddMember"
function LoginHeader(props) {

  return (
    <div>
      <header className="fixed-top header loginheader d-md-flex d-none">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
                <div className="d-flex justify-content-between">
                    <a href={process.env.REACT_APP_WP_URL}><img className="top-logo" src="../assets/images/logoblue.svg" /></a>
                    <ul id="menu-header-top-menu" class="menu-items">
                      <li class="menu-item"><a title="About" href={process.env.REACT_APP_WP_URL+"/about/"}>About</a></li>
                      <li class="menu-item"><a title="Schools" href={process.env.REACT_APP_WP_URL+"/schools/"}>Schools</a></li>
                      <li class="menu-item"><a title="Events" href={process.env.REACT_APP_WP_URL+"/events/"}>Events</a></li>
                      <li class="menu-item"><a title="Professional Learning" href={process.env.REACT_APP_WP_URL+"/professional_learning/"}>Professional Learning</a></li>
                      <li class="menu-item"><a title="Careers" href={process.env.REACT_APP_WP_URL+"/careers/"}>Careers</a></li>
                      <li class="menu-item"><a title="Accreditation" href={process.env.REACT_APP_WP_URL+"/accreditation/"}>Accreditation</a></li>
                      <li class="menu-item"><a title="Athletics" href={process.env.REACT_APP_WP_URL+"/athletics/"}>Athletics</a></li>
                      <li class="menu-item"><a title="Contact" href={process.env.REACT_APP_WP_URL+"/contact/"}>Contact</a></li>
                    </ul>
                </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default LoginHeader;
