import {TextField,InputLabel,Button} from "@mui/material";
import { useState } from "react";
import {useMutation} from "@apollo/client";
import { useForm } from "react-hook-form";
import {UNSUBSCRIBE} from "../../Graphql/mutations"
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams } from "react-router-dom";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {toggleSnackbar} from "../../RactiveVariables/index";
import * as yup from "yup";
export default function Unsubscribe() {
    const {id} =useParams();
    const [buttonUse,setButtonUse] = useState(false)
    const [unsubscribe] =useMutation(UNSUBSCRIBE,{onCompleted:(res)=>{
        console.log("test",res);
        setButtonUse(res.unsubscribe.status)
    }})
    const schema = yup.object().shape({
        email: yup
          .string()
          .required("Email is required")
          .email("Email must be valid"),
      });
    const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm({ resolver: yupResolver(schema) });
    const onSubmit=(data)=>{
        console.log("test",data);
        try {
            unsubscribe({variables:{mailId:data.email}})
               .then((res) => {
                    toggleSnackbar({
                   status: true,
                   message: "Unsubscribed successfully",
                   variant: "success",
                 });
               })
               .catch((error) => {
                 const message = error && error.message;
                 toggleSnackbar({
                   status: true,
                   message: message,
                   variant: "error",
                 });
               });
           } catch (error) {
             const message = error && error.message;
             toggleSnackbar({
               status: true,
               message: message,
               variant: "error",
             });
           }
        
    }
  return (
    <div className="login-outer">
        <div className="login">
        <div className="container">
      <div className="row">
        <div className="col-lg-6 d-flex align-items-center justify-content-center">
          <div className="logo-section">
            <a href={process.env.REACT_APP_WP_URL}>
              {" "}
              <img
                src="../assets/images/login-logo.svg"
                className="img-fluid login-logo"
                alt="logo"
              />
            </a>
            <p>
              NYSAIS is an advocate of the independent schools of New York
              State, speaking for their independence, well-being, and reputation
              before the agencies of government, the media, and the public
            </p>
          </div>
        </div>
        <div className="col-lg-6 d-flex align-items-center justify-content-center unsubscribe ">
        <form onSubmit={handleSubmit(onSubmit)} noValidate>

            <div>
                {buttonUse===false?<h2>Unsubscribe</h2>:<h2>Unsubscribed</h2>}
                <p>
                    We're sorry to see you go, but hey, no hard feelings, hopefully we will see you back one day. Please confirm your
                    email address in order to unsubscribe from the list
                </p>
                {buttonUse===false&&<div className="mb-3">
                        <label className="form-label-unsubscribe">
                          Email <sup className="text-danger">*</sup>
                        </label>
                        <input
                          className="form-control"
                          type="email"
                          autoFocus
                          defaultValue={id}
                          placeholder="Email"
                          error={errors?.email?.message}
                          {...register("email")}
                        />
                        {errors.email && (
                          <span className="text-danger">
                            {errors?.email?.message}
                          </span>
                        )}
                      </div>}
                {buttonUse===false?<div>
                <Button  
                className="btn btn-primary login-btn"
                type="submit"
                color="primary">
                    Unsubscribe
                </Button>
                </div>:<div className="d-flex justify-content-center">
                    <CheckCircleIcon color="inherit" fontSize="large"/>
                    </div>}
 
            </div>

        </form>
        </div>
      </div>
    </div>
        </div>
    </div>
    
  );
}
