import { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  Button,
  Menu,
  MenuItem,
  FormControl,
  Box,
  Pagination,
  TextField,
  CircularProgress
} from "@mui/material";
import { useMutation, useReactiveVar } from "@apollo/client";
import {
  toggleSnackbar,
  schoolDtls,
  userDeatils,
} from "../../RactiveVariables/index";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { useQuery } from "@apollo/client";
import { LIST_ALL_ORDERS, COUNT_ORDER } from "../../Graphql/queries";
import { DELETE_ORDER } from "../../Graphql/mutations";
import moment from "moment";
import { useParams, Link,useLocation } from "react-router-dom";
import ConfirmDialog from "../Common/ConfirmDialog";
import CheckDetails from "./checkDetails";
import { formatCurrency } from "../Common/GeneralFunctions";
import { RefundConfirm } from "../Common";

export default function Order(props) {
  const [category, setcategorytype] = useState(null);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [eventType, setEventType] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [selectedItem, setSelectedItem] = useState(null);
  const [sortFieldVariable, setSortFieldVariable] = useState("_id");
  const [sortVariable, setSortVariable] = useState("-1");
  const [loadingCSV,setLoadingCSV] =useState(false)
  const [deleteId, setDeleteId] = useState(null);
  const [checkOpen, setCheckOpen] = useState(false);
  const [refundOpen, setRefundOpen] = useState(false);
  const [deleteOrder, { loading: deleteLoadinng }] = useMutation(DELETE_ORDER);
  const schoolDetails = useReactiveVar(schoolDtls);
  const user = useReactiveVar(userDeatils);

  const pageUrl = window.location.pathname;

  const { id } = useParams();

  const getEventVariables = {
    // belgsToArg: "School",
    // schId: schoolDetails?.schoolId ? schoolDetails?.schoolId : id,
    schId: schoolDetails?.schoolId
      ? schoolDetails?.schoolId
      : pageUrl.includes("/schoolpreview")
      ? props.schoolId
      : "",
    search: search,
    orderDate: startDate
      ? moment(new Date(startDate)).format("MM/DD/YYYY")
      : null,
    // startDate: startDate,
    // category: category,
    // eventType: eventType,
    skip: (page - 1) * rowsPerPage,
    limit: rowsPerPage,
    sortOrder: sortVariable,
    eventId: pageUrl.includes("/eventpreview") ? id : "",
    orderCreatedFor: (user.data.loginType === "Guest" || (user.data.loginType === "SchoolUser"&& !getschooluserpermission("Orders"))) ? user?.data?._id : null,
    // sortField: sortFieldVariable,
  };
  function getschooluserpermission(title){
    let position = user?.data?.moduleAcs.findIndex((element) => element?.title === title);
    if(position>-1 && user?.data?.moduleAcs[position].status===true){
      return true;
    }
    else return false;
  }
  const {
    data: orderList,
    fetchMore,
    loading,
    refetch,
  } = useQuery(LIST_ALL_ORDERS, {
    fetchPolicy: "network-only",
    variables: getEventVariables,
  });

  const {
    data: countOrders,
    refetch : refetchCount
  } = useQuery(COUNT_ORDER, {
    fetchPolicy: "network-only",
    variables: {
       eventId: id
    },
   
  });

  const handleEventType = (event) => {
    setEventType(event.target.value);
  };

  function closeConfirm() {
    setDeleteId(null);
  }
  function handleConfirm() {
    handleDelete();
    closeConfirm();
    handlePagination();
  }

  function handleDelete() {
    deleteOrder({ variables: { eventIds: [deleteId] } })
      .then((res) => {
        toggleSnackbar({
          status: true,
          message: "Order deleted successfully",
          variant: "success",
        });
        refetchCount();
        handlePagination();
      })
      .catch((error) => {
        const message = error && error.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      });
  }

  function downloadTxtFile () {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      "search":search,
      "eventId": id,
      "orderCreatedFor": (user.data.loginType === "Guest" || (user.data.loginType === "SchoolUser"&& !getschooluserpermission("Orders"))) ? user?.data?._id : "",
      "orderDate": startDate,
    });
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    fetch(`${process.env.REACT_APP_API_URL}/events/listAllOrders`, requestOptions)
      .then(response => response.text())
      .then(result => {
        const element = document.createElement("a");
    const file = new Blob([result], {type: 'text/csv'});
    element.href = URL.createObjectURL(file);
    element.download = "Orders.csv";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    setLoadingCSV(false);
      })
      .catch(error => console.log('error', error));

  }
  const handlePagination = () => {
    fetchMore({
      variables: getEventVariables,
    });
  };
  const handlecategory = (event) => {
    setcategorytype(event.target.value);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, item) => {
    setSelectedItem(item);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChangePage = (e, page) => {
    setPage(page);
  };
  function handlePageInputChange(event) {
    setRowsPerPage(Number(event.target.value));
    setPage(1);
  }
  // function convertDate(str) {
  //   var date = new Date(str),
  //     mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  //     day = ("0" + date.getDate()).slice(-2);
  //   let formatedDate = [mnth, day, date.getFullYear()].join("/");
  //   return formatedDate == "aN/aN/NaN" || formatedDate == "01/01/1970"
  //     ? null
  //     : formatedDate;
  // }
  function convertDate(str) {
    if (str) {
      var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [mnth, day, date.getFullYear()].join("/");
    } else {
      return null;
    }
  }
  function handleSort(field) {
    if (sortFieldVariable === field) {
      if (sortVariable === "-1") setSortVariable("1");
      else setSortVariable("-1");
    } else {
      setSortFieldVariable(field);
      setSortVariable("1");
    }
  }
  return (
    <div
      className={props?.eventorder ? "content-wrapper pt-0" : "content-wrapper"}
    >
      <ConfirmDialog
        open={deleteId}
        title={"Delete"}
        body={"Are you sure you want to delete this Order ? "}
        closeText={"Cancel"}
        confirmText={"Delete"}
        closeConfirm={closeConfirm}
        handleConfirm={handleConfirm}
      />
      <CheckDetails
        open={checkOpen}
        setOpen={setCheckOpen}
        selectedItem={selectedItem}
        refetch={refetch}
      />
      <RefundConfirm
        setOpen={setRefundOpen}
        open={refundOpen}
        item={selectedItem}
        refetchTickets={refetch}
        order={true}
      />
      <div className="container ">
        <div className="d-block d-md-none">
          <h3 className="m-h3">{schoolDetails?.data?.schNameThe}</h3>
        </div>
        {
          pageUrl.includes("/eventpreview") &&
          <div className="row">
          <div className="col-sm-4">
            <div className="card order-count">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col-sm-8">
                    <div className="d-flex align-items-center border-right">
                      <img src="../assets/images/order1.svg" />
                      <h2>Total Orders</h2>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <p>{countOrders?.countOrders?.orderCnt === 0 || countOrders?.countOrders?.orderCnt === null ? "0" : countOrders?.countOrders?.orderCnt}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="card order-count">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col-sm-8">
                    <div className="d-flex align-items-center border-right">
                      <img src="../assets/images/order2.svg" />
                      <h2>Registrations</h2>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <p>{countOrders?.countOrders?.orderTktCnt === 0 || countOrders?.countOrders?.orderTktCnt === null ? "0" : countOrders?.countOrders?.orderTktCnt}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="card order-count">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col-sm-7">
                    <div className="d-flex align-items-center border-right">
                      <img src="../assets/images/order3.svg" />
                      <h2>Total Amount</h2>
                    </div>
                  </div>
                  <div className="col-sm-5 ps-0">
                    <p>
                      $
                      {countOrders?.countOrders?.orderTicktSum === 0 ||
                      countOrders?.countOrders?.orderTicktSum === null
                        ? "0"
                        : formatCurrency(countOrders?.countOrders?.orderSum)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        }
        
        <div className="row">
          <div className="col-sm-12">
            <div className="card border-0">
              <div className="card-body p-0">
                <div className="filter-section d-flex justify-content-between">
                  <div className="d-flex align-items-center">
                    <img
                      className="search-icon"
                      src="../assets/images/search.svg"
                      alt="search icon"
                    />
                    <input
                      className="searchbox"
                      placeholder="Search by Order ID"
                      value={search}
                      type="text"
                      // onChange={(e) => setSearch(e.target.value)}
                      onChange={(e) => {setPage(1);setSearch(e.target.value.split("")[0]==="#" ? e.target.value.substring(1) : e.target.value)}}
                    />
                  </div>
                  <div className="d-flex align-items-center justify-content-center">
                    <Box className="ol-date" sx={{ minWidth: 177 }}>
                      <FormControl fullWidth className="filter-date">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            inputFormat="MM/dd/yyyy"
                            value={startDate ? startDate : null}
                            renderInput={(params) => <TextField {...params} />}
                            onChange={(e) =>{setPage(1); setStartDate(convertDate(e))}}
                          />
                        </LocalizationProvider>
                      </FormControl>
                    </Box>
                   {user?.data?.loginType==="Admin"&&pageUrl.includes("/eventpreview")&& <a
                  href="javascript:;"
                  // download="https://dev-api.nysais.org/finance/financeOrderReport"
                  className="btn btn-orange d-flex align-items-center ms-2"
                  onClick={()=>{downloadTxtFile();setLoadingCSV(true);}}
                >
                  <img className="me-1" src="../assets/images/download.svg" />
                  {loadingCSV===true? <CircularProgress size="20px" color="inherit"  /> :  "Download"}
                </a>}
                  </div>
                </div>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} className="mob-table-layout" aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell className="w-90">Order ID</TableCell>
                        <TableCell className="w100" style={{ width: 130 }}>Date</TableCell>
                        <TableCell className="w100">Name</TableCell>
                        <TableCell className="w-200">{pageUrl.includes("/schoolpreview") ||pageUrl.includes("/orderlisting") ? "Event" : "School"}</TableCell>
                        <TableCell align="center" className="w100">Registrations</TableCell>
                        <TableCell align="center" className="w100">Amount</TableCell>
                        <TableCell align="center" className="w100">Payment Type</TableCell>
                        <TableCell align="center" className="w-125">Status</TableCell>
                        <TableCell className="w100"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {loading ? (
                        <TableRow>
                          <TableCell colSpan={12}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <p> Loading Orders...</p>
                            </div>
                          </TableCell>
                        </TableRow>
                      ) : orderList?.listAllOrders?.data &&
                        orderList?.listAllOrders?.data.length > 0 ? (
                        orderList?.listAllOrders?.data?.map((item) => (
                          <TableRow>
                            <TableCell>
                              <Link
                                className="preview-link"
                                to={{pathname:item.status === "Open" ? 
                                  `/eventregistration/${item?._id}` : 
                                  // item.status === "Ready-For-Payment" ? 
                                  // `/checkout/${item?._id}` : 
                                  `/orderdetails/${item?._id}` , state: { foo: item.status}
                                }}
                              >
                                #{item?.orderNo}
                              </Link>
                            </TableCell>
                            <TableCell>
                              {item.orderDate &&
                                moment(new Date(item.orderDate)).utc().format(
                                  "MMM DD, YYYY"
                                )}
                            </TableCell>
                            <TableCell>
                              {item?.createdForDtls?.firstName +
                                " " +
                                item?.createdForDtls?.lastName}
                            </TableCell>
                            <TableCell>{pageUrl.includes("/schoolpreview") ||pageUrl.includes("/orderlisting") ? item?.eventDtls?.title : item?.schDetails?.schNameThe}</TableCell>
                            <TableCell align="center">
                              {item?.totalMemCount+item?.totalNonMemCount}
                            </TableCell>
                            <TableCell align="center">
                              ${formatCurrency(item?.grandTotal)}
                            </TableCell>
                            <TableCell align="center">{item?.paymentMethod}</TableCell>
                            <TableCell align="center">
                              <span className={item?.status + " badge w-100"}>
                                {item?.status === "Open"
                                  ? "Draft"
                                  : item?.status}
                              </span>
                            </TableCell>
                            <TableCell>
                              <Button
                                id="basic-button"
                                aria-controls="basic-menu"
                                aria-haspopup="true"
                                aria-expanded={open ? "true" : undefined}
                                onClick={(e) => handleClick(e, item)}
                                className="p-0 action-btn"
                              >
                                <img
                                  src="../assets/images/dots.svg"
                                  alt="edit button image"
                                />
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : search ? (
                        <TableRow>
                          <TableCell colSpan={12}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <p>No Orders Found</p>
                            </div>
                          </TableCell>
                        </TableRow>
                      ) : (
                        <TableRow>
                          <TableCell colSpan={12}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <p>No Orders Added Yet!</p>
                            </div>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                  {!loading && (
                    <div className="pagination-outer">
                      <div className="count-selector">
                        <Select
                          onChange={handlePageInputChange}
                          value={rowsPerPage}
                        >
                          <MenuItem value={10}>{10}</MenuItem>
                          <MenuItem value={25}>{25}</MenuItem>
                          <MenuItem value={50}>{50}</MenuItem>
                          <MenuItem value={100}>{100}</MenuItem>
                        </Select>
                        <img
                          className="count-arrow"
                          src="../../assets/images/arrowupdown.svg"
                        />
                      </div>
                      <Pagination
                        count={Math.ceil(
                          orderList?.listAllOrders?.count / rowsPerPage
                        )}
                        page={page}
                        shape="rounded"
                        onChange={handleChangePage}
                      />
                    </div>
                  )}
                </TableContainer>
              </div>
            </div>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "dropdown-button",
              }}
            >
              
                  {selectedItem && selectedItem.status === "Open" && <MenuItem onClick={handleClose}>
                    <Link
                      className="actionbtn-menu"
                      to={`/eventregistration/${selectedItem?._id}`}
                    >
                      Edit
                    </Link>
                  </MenuItem>}
                  {(selectedItem && (selectedItem.status === "Open" ||  selectedItem.status === "Ready-For-Payment")) &&
                   ((selectedItem.orderCreatedFrom==="Nysais"&& user?.data?.loginType==="Admin")||selectedItem.orderCreatedFrom!=="Nysais")&&
                  <MenuItem onClick={handleClose}>
                    <a
                      className="actionbtn-menu"
                      onClick={() => setDeleteId(selectedItem?._id)}
                      href="javascript:;"
                    >
                      Delete
                    </a>
                  </MenuItem>}
              {selectedItem && selectedItem.status !== "Open" && <MenuItem onClick={handleClose}>
                <Link
                  className="actionbtn-menu"
                  to={{ pathname: `/orderdetails/${selectedItem?._id}`,state: { foo: selectedItem.status}}}
                >
                  View
                </Link>
              </MenuItem>}
              {selectedItem &&
                selectedItem.status === "Ready-For-Payment" &&
                selectedItem.paymentMethod === "Check" &&
                user?.data?.loginType === "Admin" && (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      setCheckOpen(true);
                    }}
                  >
                    <a
                      className="actionbtn-menu"
                      // onClick={() => setDeleteId(selectedItem?._id)}
                      href="javascript:;"
                    >
                      Check
                    </a>
                  </MenuItem>
                )}
              {selectedItem &&
                selectedItem.status === "Ready-For-Payment" &&
                selectedItem.paymentMethod === "Card" &&
                // user?.data?.loginType !== "Admin" && 
                (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    <Link
                      className="actionbtn-menu"
                      to={`/checkout/${selectedItem?._id}`}
                    >
                      Payment
                    </Link>
                  </MenuItem>
                )}
                {selectedItem?.status==="Paid" && selectedItem.eventDtls.lastDateToCancel && 
                <MenuItem onClick={handleClose}>
                  <a
                    className="actionbtn-menu"
                    onClick={() => setRefundOpen(true)}
                    href="javascript:;"
                  >
                    Cancel for Refund
                  </a>
                </MenuItem>}
            </Menu>
          </div>
        </div>
      </div>
    </div>
  );
}
