import React, { useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  Button,
  InputLabel,
  MenuItem,
  FormControl,
  Box,
  Pagination,
  Menu,
  CircularProgress,
} from "@mui/material";
import { useState} from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import { useQuery, useMutation, useReactiveVar, useLazyQuery } from "@apollo/client";
import { toggleSnackbar, schoolDtls, userDeatils } from "../../RactiveVariables/index";
import { ORDER_DETAILS, LIST_ALL_TICKET, ORDER_DETAILS_FOR_GUEST,EXPORT_ORDERS } from "../../Graphql/queries";
import { formatCurrency } from "../Common/GeneralFunctions";
import { DELETE_TICKET } from "../../Graphql/mutations";
import { ConfirmDialog, RefundConfirm } from "../Common";
import moment from "moment";

import EventHeader from "../Common/EventHeader";
export default function OrderDetails(props) {
  const { id } = useParams();
  const user = useReactiveVar(userDeatils);
  const timeZone= Intl.DateTimeFormat().resolvedOptions().timeZone
  const loginType = user?.data?.loginType;
  const location = useLocation();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [selectedItem, setSelectedItem] = useState(null);
  const [status, setStatus] = useState(null);
  const [orderDetails, setOrderDetails] = useState(null);
  const [memberType, setMemberType] = useState(null);
  const [cancelId, setCancelId] = useState(null);
  const [userType,setUserType] = useState(null)
  const [refundOpen, setRefundOpen] = useState(false);
  const schoolDetails = useReactiveVar(schoolDtls);
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteTicket] = useMutation(DELETE_TICKET);
  const [pdfDownload,{loading: pdfloading}] = useLazyQuery(EXPORT_ORDERS,{fetchpolicy:"network-only" ,variables:{orderId:id,timeZone:timeZone}})

  
  const [getorderDetailsforGuest] = useLazyQuery(ORDER_DETAILS_FOR_GUEST,{
    fetchPolicy: "network-only",
    variables: { id: id },
    onCompleted:res=>{setOrderDetails(res)},
  });

  useEffect(()=>{window.scrollTo(0, 0);},[])

  const {
    data: orderDetailsData,
    loading,
    refetch,
  } = useQuery(ORDER_DETAILS, {
    fetchPolicy: "network-only",
    variables: { id: id },
    onCompleted:res=>{setOrderDetails(res)},
    onError:error=>{getorderDetailsforGuest()}
  });
  let data = orderDetails?.ordersDetails;

  function getPermission (type){
    let flag = false;
    if(user?.data?.moduleAcs && type)
      user?.data?.moduleAcs.forEach(element => {
        if(element?.title===type)
          flag = element?.status;
      });
    return flag;
  }
  const {
    data: ticketDetails,
    loading: ticketLoading,
    refetch: refetchTickets,
  } = useQuery(LIST_ALL_TICKET, {
    fetchPolicy: "network-only",
    variables: {
      id: props?.location?.pathname.includes("orderdetails") ? id : null,
      reType: props?.location?.pathname.includes("orderdetails") ? "order" : null,
      skip: (page - 1) * rowsPerPage,
      limit: rowsPerPage,
      status:status==="All"?"":status,
      memberType:memberType==="All"?"":memberType,
      search:search,
      // loginType : !getPermission("Orders") && user?.data?.loginType==="SchoolUser" ? "SchoolUser" : null,
    },
  });

  function handleDeleteTicket() {
    deleteTicket(
      { variables: { eventIds: [cancelId], userType: userType },
      onCompleted:res=>{
        toggleSnackbar({
          status: true,
          message: "Ticket deleted successfully",
          variant: "success",
        });
        closeConfirm();
        refetchTickets();
      }
    });
  }

  function closeConfirm() {
    setCancelId(null);
    setUserType(null)
  }


  const open = Boolean(anchorEl);
  const handleClick = (event, item) => {
    setSelectedItem(item);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChangePage = (e, page) => {
    setPage(page);
  };
  function handlePageInputChange(event) {
    setRowsPerPage(Number(event.target.value));
    setPage(1);
  }
console.log("si",selectedItem);
  if(props?.location?.pathname.includes("orderdetails"))
  return (
    <div className="content-wrapper">
      <RefundConfirm
        setOpen={setRefundOpen}
        open={refundOpen}
        item={selectedItem}
        refetchTickets={refetchTickets}
      />
      <EventHeader
        title={"Order #"+ orderDetails?.ordersDetails?.orderNo+", "+orderDetails?.ordersDetails?.eventDtls?.title}
        EventRegistration 
        id={id}
        schoolId={schoolDetails?.schoolId}
        backUrl = {{pathname:`/eventpreview/${orderDetails?.ordersDetails?.eventId}`,state:{selected:1}}}
        loaction= {location?.state?.foo}
      />
      <ConfirmDialog
        open={cancelId}
        title={"Cancel"}
        body={"Are you sure you want to delete this Ticket ? "}
        closeText={"Cancel"}
        confirmText={"Confirm"}
        closeConfirm={closeConfirm}
        handleConfirm={handleDeleteTicket}
      />
      <div className="preview-outer">
        <div className="container">
          <div className="row">
            <div className="col-sm-8">
              <div className="card border-0">
                <div className="card-body">
                  <div className="filter-section filter-section-order-preview d-md-flex justify-content-between">
                    <div className="d-flex align-items-center m-10">
                      <img
                        className="search-icon"
                        src="../assets/images/search.svg"
                        alt="search icon"
                      />
                      <input
                        className="searchbox"
                        placeholder="Search by Reg ID"
                        value={search}
                        type="text"
                          onChange={(e) => setSearch(e.target.value.split("")[0]==="#" ? e.target.value.substring(1) : e.target.value)}
                      />
                    </div>
                    <div className="filter-select d-flex align-items-center justify-content-center">
                      <Box className="ms-md-2 pt-0" sx={{ minWidth: 177 }}>
                        <FormControl fullWidth>
                          <InputLabel className="filter-label" id="type">
                            Status
                          </InputLabel>
                          <Select
                            className="filter-select"
                            labelId="type"
                            id="demo-type"
                            // value={eventType}
                            label="type"
                            onChange={(e)=>setStatus(e.target.value)}
                          >
                            <MenuItem value="All">All</MenuItem>
                            <MenuItem value="open">Draft</MenuItem>
                            <MenuItem value="Paid">paid</MenuItem>
                            <MenuItem value="Refunded">Refunded</MenuItem>
                            <MenuItem value="Ready-For-Payment">Ready-For-Payment</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                      <Box className="ms-md-2 pt-0" sx={{ minWidth: 177 }}>
                        <FormControl fullWidth>
                          <InputLabel className="filter-label" id="type">
                            Member Type
                          </InputLabel>
                          <Select
                            className="filter-select"
                            labelId="type"
                            id="demo-type"
                            // value={eventType}
                            label="type"
                            onChange={(e)=>setMemberType(e.target.value)}
                          >
                            <MenuItem value="All">All</MenuItem>
                            <MenuItem value="Member">Member</MenuItem>
                            <MenuItem value="Non-Member">Guest</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                      {location?.state?.foo==="Paid"&&<a
                        href="javascript:;"
                        className="btn btn-orange d-flex align-items-center ms-2"
                        onClick={()=>{pdfDownload().then((res)=>{window.open(res?.data?.exportOrders?.pdfUrl, '_blank');})}}
                      > { pdfloading?<CircularProgress size="20px" color="inherit" />:
                      <><img className="me-1" src="../assets/images/download.svg" />Invoice</>
                        }
                      </a>}
                    </div>
                  </div>
                  <TableContainer component={Paper} className="table-mobile">
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Reg ID</TableCell>
                          <TableCell>Participant Name</TableCell>
                          {/* <TableCell>Role</TableCell> */}
                          <TableCell>Type</TableCell>
                          <TableCell>Price</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {ticketLoading ? (
                          <TableRow>
                            <TableCell colSpan={6}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <p> Loading Tickets...</p>
                              </div>
                            </TableCell>
                          </TableRow>
                        ) : ticketDetails?.listAllOrderTicket?.data &&
                          ticketDetails?.listAllOrderTicket?.data.length > 0 ? (
                          ticketDetails?.listAllOrderTicket?.data?.map((item) => (
                            <TableRow>
                              <TableCell>#{item?.regId}</TableCell>
                              <TableCell>{`${item?.schUserList?.firstName} ${item?.schUserList?.lastName}`}</TableCell>
                              {/* <TableCell>{item?.role}</TableCell> */}
                              <TableCell>{item?.schUserList?.schUserType === "Non-Member" ? "Guest" : item?.schUserList?.schUserType}</TableCell>
                              <TableCell>${formatCurrency(item?.pricePerTcket)}</TableCell>
                              <TableCell><span className={item?.status + " badge w-100"}>{item?.status.toLowerCase() === "open" ? "Draft" : item?.status}</span></TableCell>
                              <TableCell>
                                <Button
                                  id="basic-button"
                                  aria-controls="basic-menu"
                                  aria-haspopup="true"
                                  disabled={item?.status==="Cancelled" || item?.status==="Refunded"}
                                  aria-expanded={open ? "true" : undefined}
                                  onClick={(e) => handleClick(e, item)}
                                  className="p-0 action-btn"
                                >
                                  <img
                                    src="../assets/images/dots.svg"
                                    alt="edit button image"
                                  />
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))
                        ) : search ? (
                          <TableRow>
                            <TableCell colSpan={6}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <p>No Tickets Found</p>
                              </div>
                            </TableCell>
                          </TableRow>
                        ) : (
                          <TableRow>
                            <TableCell colSpan={6}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <p>No Tickets Added Yet!</p>
                              </div>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                   
                  </TableContainer>
                </div>
                {!ticketLoading && (
                      <div className="pagination-outer">
                        <div className="count-selector">
                          <Select
                            onChange={handlePageInputChange}
                            value={rowsPerPage}
                          >
                            <MenuItem value={10}>{10}</MenuItem>
                            <MenuItem value={25}>{25}</MenuItem>
                            <MenuItem value={50}>{50}</MenuItem>
                            <MenuItem value={100}>{100}</MenuItem>
                          </Select>
                          <img
                            className="count-arrow"
                            src="../../assets/images/arrowupdown.svg"
                          />
                        </div>
                        <Pagination
                          count={Math.ceil(
                            ticketDetails?.listAllOrderTicket?.count / rowsPerPage
                          )}
                          page={page}
                          shape="rounded"
                          onChange={handleChangePage}
                        />
                      </div>
                    )}
              </div>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "dropdown-button",
                }}
              >
                {(selectedItem?.status==="Ready-For-Payment" || selectedItem?.status==="Open") ? <MenuItem onClick={handleClose}>
                  <a
                    className="actionbtn-menu"
                    onClick={() => {setCancelId(selectedItem?._id);setUserType(selectedItem?.schUserList?.schUserType)}}
                    href="javascript:;"
                  >
                    Delete
                  </a>
                </MenuItem>
                :
                (selectedItem?.status==="Paid" && (selectedItem?.eventDtls?.lastDateToCancel || data?.eventDtls?.lastDateToCancel)) &&  <MenuItem onClick={handleClose}>
                  <a
                    className="actionbtn-menu"
                    onClick={() => setRefundOpen(true)}
                    href="javascript:;"
                  >
                    Cancel for Refund
                  </a>
                </MenuItem>}
              </Menu>
            </div>
            <div className="col-sm-4">
              <div className="card order-data">
                <div className="card-title mb-0">Order Summary</div>
                <div className="card-table">
                  <div className="head">
                    <div className="row">
                      <div className="col-4">Package</div>
                      <div className="col-4">Reg/Rate</div>
                      <div className="col-4">Amount</div>
                    </div>
                  </div>
                  {data?.totalMemCount && data?.totalMemCount > 0 ? <div className="body">
                    <div className="row">
                      <div className="col-4">Members</div>
                      <div className="col-4">{`${data?.totalMemCount} x $${formatCurrency(data?.memberPrice)}`}</div>
                      <div className="col-4">${formatCurrency(data?.totalMemCount * data?.memberPrice)}</div>
                    </div>
                  </div>:null}
                  {
                    (data?.totalNonMemCount !== 0 && data?.totalNonMemCount !== null) ?
                  <div className="body">
                    <div className="row">
                      <div className="col-4">Guest</div>
                      <div className="col-4">{data?.totalNonMemCount&&`${data?.totalNonMemCount} x $${formatCurrency(data?.memberPrice)}`}</div>
                      <div className="col-4">{`$${formatCurrency(data?.totalNonMemPrice)}`}</div>
                    </div>
                  </div>
                  :null
                  }
                </div>
                <div className="card-total">
                  {
                    data?.bulkDiscount !== null && data?.bulkDiscount!="0" &&  
                <div className="datas">
                    <div className="row">
                      <div className="col-8">Bulk Discount Percentage</div>
                      <div className="col-4">{data?.bulkDiscount}%</div>
                    </div>
                  </div>
                  }
                  {
                    data?.coupenCode !== null && data?.coupenCode.length > 0 &&  
                    <>
                      <div className="datas">
                        <div className="row">
                          <div className="col-8">Coupon Code</div>
                          <div className="col-4">{data?.coupenCode}</div>
                        </div>
                      </div>
                      <div className="datas">
                        <div className="row">
                          <div className="col-8">Coupon Code Discount</div>
                          <div className="col-4">{data?.discount+"%"}</div>
                        </div>
                      </div>
                    </>
                  }
                  <div className="datas">
                    <div className="row">
                      <div className="col-8">Total Amount</div>
                      <div className="col-4">{`$${formatCurrency(data?.grandTotal)}`}</div>
                    </div>
                  </div>
                  {/* <div className="datas">
                    <div className="row">
                      <div className="col-sm-8">Discount</div>
                      <div className="col-sm-4">$1,240</div>
                    </div>
                  </div>
                  <div className="datas">
                    <div className="row">
                      <div className="col-sm-8 final">Total Amount</div>
                      <div className="col-sm-4">$1,240</div>
                    </div>
                  </div> */}
                </div>
              </div>
              {data?.paymentDetails.length>0 && <div className="card order-data mt-3">
                <div className="card-title mb-0">Payment Summary</div>
                <div className="card-table table-responsive">
                  {data?.paymentMethod==="Check" ? 
                    <>
                      <div className="body">
                        <div className="row">
                          <div className="col-6">Check Number</div>
                          <div className="col-6">{data?.paymentDetails?.[0]?.checkNumber}</div>
                        </div>
                      </div>
                      <div className="body">
                        <div className="row">
                          <div className="col-6">Check Description</div>
                          <div className="col-6">{data?.paymentDetails?.[0]?.description}</div>
                        </div>
                      </div>
                    </>
                    :
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Date and time</TableCell>
                          <TableCell>Transaction Id</TableCell>
                          <TableCell>Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data?.paymentDetails.map((row) => (
                          <TableRow
                            key={row.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell style={{whiteSpace: 'nowrap'}}>
                              {moment(row.paymentDate).format("MMM DD, YYYY hh:mm a")}
                            </TableCell>
                            <TableCell>{row.transactionCode}</TableCell>
                            <TableCell>{row.paymentStatus}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  }
                  
                </div>
              </div>}
              {/* <div className="card order-data">
                <div className="card-title">Refund Summary</div>
                <div className="card-table">
                  <div className="body">
                    <div className="row">
                      <div className="col-sm-4">Standard</div>
                      <div className="col-sm-4">10 x $150</div>
                      <div className="col-sm-4">$150</div>
                    </div>
                  </div>
                  <div className="body">
                    <div className="row">
                      <div className="col-sm-4">Standard</div>
                      <div className="col-sm-4">10 x $150</div>
                      <div className="col-sm-4">$150</div>
                    </div>
                  </div>
                </div>
                <div className="card-total">
                  <div className="datas">
                    <div className="row">
                      <div className="col-sm-8 final">Total Amount</div>
                      <div className="col-sm-4">$2,480</div>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className="card order-data">
                <div className="card-title">Payment Summary</div>
                <div className="card-table">
                  <div className="body">
                    <div className="row">
                      <div className="col-sm-4">Standard</div>
                      <div className="col-sm-4">10 x $150</div>
                      <div className="col-sm-4">$150</div>
                    </div>
                  </div>
                  <div className="body">
                    <div className="row">
                      <div className="col-sm-4">Standard</div>
                      <div className="col-sm-4">10 x $150</div>
                      <div className="col-sm-4">$150</div>
                    </div>
                  </div>
                </div>
                <div className="card-total">
                  <div className="datas">
                    <div className="row">
                      <div className="col-sm-8 final">Total Amount</div>
                      <div className="col-sm-4">$2,480</div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  else
    return(
      <div
      className="content-wrapper"
    >
      <div className="container">        
        <div className="row">
          <div className="col-sm-12">
            <div className="card border-0">
              <div className="card-body p-0">
                <div className="filter-section d-flex justify-content-between">
                  <div className="d-flex align-items-center">
                    <img
                      className="search-icon"
                      src="../assets/images/search.svg"
                      alt="search icon"
                    />
                    <input
                      className="searchbox"
                      placeholder="Search Ticket"
                      // value={search}
                      type="text"
                      // onChange={(e) => setSearch(e.target.value)}
                      // onChange={(e) => setSearch(e.target.value.split("")[0]==="#" ? e.target.value.substring(1) : e.target.value)}
                    />
                  </div>
                  <div className="d-flex align-items-center justify-content-center">
                    {/* <Box sx={{ minWidth: 177 }}>
                      <FormControl fullWidth className="filter-date">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            inputFormat="MM/dd/yyyy"
                            value={startDate ? startDate : null}
                            renderInput={(params) => <TextField {...params} />}
                            onChange={(e) => setStartDate(convertDate(e))}
                          />
                        </LocalizationProvider>
                      </FormControl>
                    </Box> */}
                  </div>
                </div>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Reg ID</TableCell>
                          <TableCell>Participant Name</TableCell>
                          {/* <TableCell>Role</TableCell> */}
                          <TableCell>Type</TableCell>
                          <TableCell>Price</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {ticketLoading ? (
                          <TableRow>
                            <TableCell colSpan={6}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <p> Loading Tickets...</p>
                              </div>
                            </TableCell>
                          </TableRow>
                        ) : ticketDetails?.listAllOrderTicket?.data &&
                          ticketDetails?.listAllOrderTicket?.data.length > 0 ? (
                          ticketDetails?.listAllOrderTicket?.data?.map((item) => (
                            <TableRow>
                              <TableCell>#{item?.regId}</TableCell>
                              <TableCell>{`${item?.schUserList?.firstName} ${item?.schUserList?.lastName}`}</TableCell>
                              {/* <TableCell>{item?.role}</TableCell> */}
                              <TableCell>{item?.schUserList?.schUserType === "Non-Member" ? "Guest" : item?.schUserList?.schUserType}</TableCell>
                              <TableCell>${formatCurrency(item?.pricePerTcket)}</TableCell>
                              <TableCell><span className={item?.status + " badge w-100"}>{item?.status.toLowerCase() === "open" ? "Draft" : item?.status}</span></TableCell>
                              <TableCell>
                                <Button
                                  id="basic-button"
                                  aria-controls="basic-menu"
                                  aria-haspopup="true"
                                  disabled={item?.status==="Cancelled"}
                                  aria-expanded={open ? "true" : undefined}
                                  onClick={(e) => handleClick(e, item)}
                                  className="p-0 action-btn"
                                >
                                  <img
                                    src="../assets/images/dots.svg"
                                    alt="edit button image"
                                  />
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))
                        ) : search ? (
                          <TableRow>
                            <TableCell colSpan={6}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <p>No Tickets Found</p>
                              </div>
                            </TableCell>
                          </TableRow>
                        ) : (
                          <TableRow>
                            <TableCell colSpan={6}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <p>No Tickets Added Yet!</p>
                              </div>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                   
                  </TableContainer>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
    )
}
