import React,{ useState} from "react";
import ETemplate from "./eTemplate"
import EDetailTemplate from "./eDetailTemplate"
import { useForm, Controller } from "react-hook-form";
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Avatar,
    Pagination,
    MenuItem,
    Select,
  } from "@mui/material";
  import { useQuery,useMutation } from "@apollo/client";
  import {EVENT_DETAILS} from "../../Graphql/queries";
  import { useParams,Link,useLocation } from "react-router-dom";
  import { toggleSnackbar } from "../../RactiveVariables/index";
  import {CREATE_ENOTICE,UPDATE_ENOTICE} from "../../Graphql/mutations"
export default function PreviewAndCompose(props){
  const location = useLocation();
  const clone = location?.state?.detail;
 
  const {id} = useParams();
    const {
        register,
        handleSubmit,
        formState,
        reset,
        setValue: setFormValue,
        control,
        trigger,
      } = useForm({});
      let { contentBlocks, entityMap } = htmlToDraft(
        props?.overviewRes?.createENotice?.content||props?.overviewRes?.updateENotice?.content||props?.enoticedetais?.eNoticeDetails?.content?
        (props?.overviewRes?.createENotice?.content)||
        (props?.overviewRes?.updateENotice?.content)||
        (props?.enoticedetais?.eNoticeDetails?.content):
        "");
      let contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const [textEditorValue, setTextEditorValue] = useState(EditorState.createWithContent(contentState));
      const { data: eventData } = useQuery(EVENT_DETAILS,{variables:{id:id}});
      const toolbarConfig = {
        display: [
          "INLINE_STYLE_BUTTONS",
          "BLOCK_TYPE_BUTTONS",
          "LINK_BUTTONS",
          "HISTORY_BUTTONS",
        ],
        INLINE_STYLE_BUTTONS: [
          { label: "Bold", style: "BOLD", className: "custom-css-class" },
          { label: "Italic", style: "ITALIC" },
          { label: "StrikeThrough", style: "STRIKETHROUGH" },
        ],
        BLOCK_TYPE_BUTTONS: [
          { label: "UL", style: "unordered-list-item" },
          { label: "OL", style: "ordered-list-item" },
        ],
      };
      const registerDescription = register("description");
    function onChangeEditor(value) {
        if (value.toString("html") === "<p><br></p>") {
          registerDescription.onChange({
            target: { value: "", name: "description" },
          });
        } else {
          registerDescription.onChange({
            target: { value: value.toString("html"), name: "description" },
          });
        }
        setTextEditorValue(value);
      }
      const [updateENotice] = useMutation(UPDATE_ENOTICE,{ refetchQueries:['listAllENotice'],
      onCompleted:res=>{
        props.setOverviewRes(res)

        // setSelectEvent(res)
        props.setTabValue("Schedule/Send");
    }})
    const handleSaveENotice = ()=>{
      let data= {
        massTopicsIds:props?.overviewRes?.createENotice?.massTopicsIds||props?.overviewRes?.updateENotice?.massTopicsIds||props?.enoticedetais?.eNoticeDetails?.massTopicsIds,
        toGpList:props?.overviewRes?.createENotice?.toGpList||props?.overviewRes?.updateENotice?.toGpList||props?.enoticedetais?.eNoticeDetails?.toGpList,
        ccList:props?.overviewRes?.createENotice?.ccList||props?.overviewRes?.updateENotice?.ccList||props?.enoticedetais?.eNoticeDetails?.ccList,
        fromLabel:props?.overviewRes?.createENotice?.fromLabel||props?.overviewRes?.updateENotice?.fromLabel||props?.enoticedetais?.eNoticeDetails?.fromLabel,
        from:props?.overviewRes?.createENotice?.from||props?.overviewRes?.updateENotice?.from||props?.enoticedetais?.eNoticeDetails?.from,
        replyTo:props?.overviewRes?.createENotice?.replyTo||props?.overviewRes?.updateENotice?.replyTo||props?.enoticedetais?.eNoticeDetails?.replyTo,
        eventId:props?.overviewRes?.createENotice?.eventId||props?.overviewRes?.updateENotice?.eventId||props?.enoticedetais?.eNoticeDetails?.eventId||"",
        eNoticeType:props?.overviewRes?.createENotice?.eNoticeType||props?.overviewRes?.updateENotice?.eNoticeType||props?.enoticedetais?.eNoticeDetails?.eNoticeType,
        subject:props?.overviewRes?.createENotice?.subject||props?.overviewRes?.updateENotice?.subject||props?.enoticedetais?.eNoticeDetails?.subject,
        content:draftToHtml(convertToRaw(textEditorValue.getCurrentContent())),
        status:'Draft',
      }
        try {
          updateENotice({variables:{_id:clone?props?.overviewRes?.createENotice?._id?props?.overviewRes?.createENotice?._id:props?.overviewRes?.updateENotice?._id:id!=="$"?id:(props?.overviewRes?.createENotice?._id||props?.overviewRes?.updateENotice?._id),data:data}})
             .then((res) => {
                  toggleSnackbar({
                 status: true,
                 message: "E-Notice Saved successfully",
                 variant: "success",
               });
             })
             .catch((error) => {
               const message = error && error.message;
               toggleSnackbar({
                 status: true,
                 message: message,
                 variant: "error",
               });
             });
         } catch (error) {
           const message = error && error.message;
           toggleSnackbar({
             status: true,
             message: message,
             variant: "error",
           });
         }
      }
      
return(
  <div className="container">
    <div className="pre-comp row">
        <div className="col-6">
            {(props?.enoticedetais?.eNoticeDetails?.eNoticeType==="Event")||(props?.overviewRes?.updateENotice?.eNoticeType==="Event")||(props?.overviewRes?.createENotice?.eNoticeType==="Event")?
            <EDetailTemplate eventdetails={props} textValue={draftToHtml(convertToRaw(textEditorValue.getCurrentContent()))}/>:
            <ETemplate eventdetails={props} textValue={draftToHtml(convertToRaw(textEditorValue.getCurrentContent()))}/>
            }
        </div>
        <div className="col-6">
        <Editor
          editorState={textEditorValue}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          onEditorStateChange={(value) => onChangeEditor(value)}
        />
        <div className="d-flex justify-content-between top-margin align-items-center temp-save">
              <Button className="prev-btn" onClick={() =>props.setTabValue("Schedule/Send")} >
                Preview
              </Button>
              <div className="d-flex align-items-center">
                <Button className="btn btn-orange" onClick={()=>handleSaveENotice()}>
                   Save
                </Button>
              </div>
        </div>
        </div>
        
    </div>
    </div>
)
}