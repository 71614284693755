import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  IconButton,
  Typography,
  CircularProgress,
} from "@mui/material";
import { Close } from "@mui/icons-material";

const ConfirmDialog = (props) => {
  return (
    <Dialog open={props?.open} className="max-width400" fullWidth>
      <DialogTitle>{props?.title}</DialogTitle>
      <Box position="absolute" top={0} right={0}>
        <IconButton onClick={() => props.closeConfirm()}>
          <Close />
        </IconButton>
      </Box>
      <DialogContent>
        <Typography>{props?.body}</Typography>
      </DialogContent>
      {props?.content && (
        <div className="ps-4 pe-4" style={{ fontSize: "12px" }}>
          <span className="me-1" style={{ color: "red" }}>
            Note:
          </span>
          {props?.content}
        </div>
      )}
      <DialogActions>
        <Button
          className="btn  btn-cancel"
          variant="contained"
          onClick={() => props?.closeConfirm()}
        >
          {props.closeText ? props.closeText : "Close"}
        </Button>
        <Button
          className=" btn-orange"
          variant="contained"
          onClick={() => props?.handleConfirm()}
          disabled={props.loading}
        >
          {props?.loading ? (
            <CircularProgress size="20px" color="inherit" />
          ) : props.confirmText ? (
            props.confirmText
          ) : (
            "Confirm"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
