import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  InputLabel,
  TextField,
  Drawer,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  CircularProgress,
  Select,
  MenuItem,
  textFieldClasses,
  Avatar,
} from "@mui/material";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { ADD_SPEAKER, EDIT_SPEAKER } from "../../Graphql/mutations";
import { toggleSnackbar } from "../../RactiveVariables/index";
import { IMAGE_UPLOAD } from "../../Graphql/queries";
import axios from "axios";
import { CropImage } from "../Common";
import imageCompression from 'browser-image-compression';

export default function AddSpeaker(props) {
  const [cropImage, setCropImage] = useState(null);
  const [cropOpen, setCropOpen] = useState(false);
  const [addSpeaker, { loading }] = useMutation(ADD_SPEAKER);
  const [editSpeaker, { loading: editLoading }] = useMutation(EDIT_SPEAKER);
  const schema = yup.object().shape({
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
    // title: yup.string().required("Title is required"),
    // organization: yup.string().required("Organization is required"),
    pronoun: yup.string().required("Pronoun is required"),
  });
  const { register, handleSubmit, formState, reset } = useForm({
    defaultValues: props.defaultValue,
    resolver: yupResolver(schema),
  });
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUploadLoading, setImageUploadLoading] = useState(false);
  const uplaodImageVariables = {
    originalFilename: selectedImage?.fileName,
    path: "/userprofile", //change folder name
  };
  const [upload, { data: uploadImageData }] = useLazyQuery(IMAGE_UPLOAD, {
    variables: uplaodImageVariables,
  });

  const { errors } = formState;
  async function uploadImage(params) {
    setImageUploadLoading(true);
    if (selectedImage) {
      let url = uploadImageData?.imageUpload?.signedUrl;
      const response = await axios.put(url, selectedImage.file, {
        headers: {
          "Content-Type": selectedImage?.file?.type,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "X-Requested-With",
        },
      });
    }
    setImageUploadLoading(false);
    onSubmit(params);
  }
  
  const onSubmit = (params) => {
    if (selectedImage?.imageFile !== null)
      params["profileImage"] = uploadImageData?.imageUpload?.publicUrl;
    else params["profileImage"] = "";
    if ( props.selectedItem && Object.keys(props.selectedItem).length > 0) {
      try {
        params._id = props?.selectedItem?._id;
        editSpeaker({ variables: params })
          .then((res) => {
            toggleSnackbar({
              status: true,
              message: "Speaker successfully Updated",
              variant: "success",
            });
            props.handlePagination();
            props.setOpen(false);
            props.setSelectedItem(null);
            setSelectedImage(null);
          })
          .catch((error) => {
            const message = error && error.message;
            toggleSnackbar({
              status: true,
              message: message,
              variant: "error",
            });
          });
      } catch (error) {
        const message = error && error.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      }
    } else {
      try {
        addSpeaker({ variables: params })
          .then((res) => {
            toggleSnackbar({
              status: true,
              message: "Speaker added successfully",
              variant: "success",
            });
            if (props.event) {
              props?.addSpeaker(res?.data?.addSpeakers?._id);
            } else if (props.handlePagination) {
              props.handlePagination();
            }
            props.setOpen(false);
          })
          .catch((error) => {
            const message = error && error.message;
            toggleSnackbar({
              status: true,
              message: message,
              variant: "error",
            });
          });
      } catch (error) {
        const message = error && error.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      }
    }
  };

  function closeDrawer() {
    props.setOpen(false);
    props.setSelectedItem && props.setSelectedItem(null);
    setSelectedImage(null);
    reset();
  }
  useEffect(() => {
    reset();
  }, [props.selectedItem]);
  function handleUploadClick(file) {
    let obj = {};
    // var file = event.target.files[0];
    obj["fileName"] = file.name;
    obj["url"] = URL.createObjectURL(file);
    obj["file"] = file;
    setSelectedImage(obj);
    upload();    
  }
  
  function handelCropDone(result,fileUrl) {
    setCropImage(null);
    handleUploadClick(result, fileUrl);
  }
  function openCrop(event) {
    
    var options = {
      maxSizeMB: 1,
      // maxWidthOrHeight: 300,
      useWebWorker: true,
    };
    imageCompression(event.target.files[0], options)
    .then((compressedFile) => {
      setCropImage(compressedFile)
      setCropOpen(true);      
    })
    .catch((err) => {
      //console.log("err: ",err)
    })
  }

  return (
    <div>
      <input
        id="add-user-input"
        type="file"
        hidden
        onChange={openCrop}
        onClick={(event) => {
          event.target.value = null;
        }}
      />
      <CropImage
        open={cropOpen}
        setOpen={setCropOpen}
        image={cropImage}
        handelCropDone={handelCropDone}
        aspectRatio={1/1}
      />
      <Drawer anchor={"right"} open={props.open} onClose={closeDrawer}>
        <div className="sidebar-toggler" role="presentation">
          <div className="title-outer">
            <h1>Add Speaker</h1>
            <div onClick={closeDrawer} className="close-icon">
              X
            </div>
          </div>
          <div className="userform-scroller">
            <div className="d-flex justify-content-center user-outer">
              <div className="avatar-outer">
                <Avatar
                  src={
                    selectedImage
                      ? selectedImage.url
                      : props?.selectedItem?.profileImage
                      ? props?.selectedItem?.profileImage
                      : "/broken-image.jpg"
                  }
                  onClick={(e)=>document.getElementById("add-user-input").click()}
                  sx={{ width: 110, height: 110 }}
                />
                <span
                  onClick={() =>
                    document.getElementById("add-user-input").click()
                  }
                >
                  +
                </span>
              </div>
            </div>
            <form
              className="adduser-form"
              onSubmit={handleSubmit(uploadImage)}
              noValidate
            >
              <div className="row">
                <div className="col-md-6 mb-3">
                  <InputLabel className="form-label">First name<sup className="text-danger">*</sup></InputLabel>
                  <TextField
                    style={{ width: "100%" }}
                    variant="outlined"
                    {...register("firstName")}
                    error={errors.firstName}
                    defaultValue={props?.selectedItem?.firstName}
                  />
                  {errors && errors?.["firstName"] && (
                    <p className="errorMessage text-danger">
                      {errors?.firstName?.message}
                    </p>
                  )}
                </div>
                <div className="col-md-6 mb-3">
                  <InputLabel className="form-label">Last Name<sup className="text-danger">*</sup></InputLabel>
                  <TextField {...register("lastName")}
                    defaultValue={props?.selectedItem?.lastName}
                    error={errors.lastName}
                     variant="outlined" />
                  {errors && errors?.["lastName"] && (
                    <p className="errorMessage text-danger">
                      {errors?.lastName?.message}
                    </p>
                  )}
                </div>
                <div className="col-md-6 mb-3">
                  <InputLabel className="form-label">Title</InputLabel>
                  <TextField
                    {...register("title")}
                    variant="outlined"
                    error={errors.title}
                    defaultValue={props?.selectedItem?.title}
                  />
                  {errors && errors?.["title"] && (
                    <p className="errorMessage text-danger">
                      {errors?.title?.message}
                    </p>
                  )}
                </div>
                <div className="col-md-6 mb-3">
                  <InputLabel className="form-label">Organization</InputLabel>
                  <TextField
                    {...register("organization")}
                    variant="outlined"
                    error={errors.organization}
                    defaultValue={props?.selectedItem?.organization}
                  />
                  {errors && errors?.["organization"] && (
                    <p className="errorMessage text-danger">
                      {errors?.organization?.message}
                    </p>
                  )}
                </div>
                <div className="col-md-12 pb-3 multi-line">
                  <InputLabel className="form-label">Bio</InputLabel>
                  <TextField
                    {...register("bio")}
                    multiline
                    rows={8}
                    variant="outlined"
                    defaultValue={props?.selectedItem?.bio}
                  />
                 
                </div>
                <div className="col-md-12 pt-1 mb-3">
                <InputLabel className="form-label">
                    Pronoun<sup className="text-danger">*</sup>
                  </InputLabel>
                  {/* <Select
                    variant="outlined"
                    {...register("pronoun")}
                    error={errors.pronoun}
                    defaultValue={props?.selectedItem?.pronoun}
                  >
                    {Pronoun?.map((item) => (
                        <MenuItem value={item}>{item}</MenuItem>
                      ))}
                  </Select>
                  {errors && errors?.["pronoun"] && (
                    <p className="errorMessage text-danger">
                      {errors?.pronoun?.message}
                    </p>
                  )} */}
                 <TextField
                    {...register("pronoun")}
                    variant="outlined"
                    error={errors.pronoun}
                    defaultValue={props?.selectedItem?.pronoun}
                  />
                  {errors && errors?.["pronoun"] && (
                    <p className="errorMessage text-danger">
                      {errors?.pronoun?.message}
                    </p>
                  )}
                </div>
              </div>

              <div className="d-flex justify-content-between align-items-center btn-section">
                <Button className="outline-btn" onClick={() => closeDrawer()}>
                  Cancel
                </Button>
                <div className="d-flex align-items-center">
                  {/* <Button
                    disabled={loading}
                    type="submit"
                    className="highlight-btn"
                  >
                    {loading ? (
                      <CircularProgress size="20px" color="inherit" />
                    ) : (
                      "Save"
                    )}
                  </Button> */}

                  <Button type="submit" className="highlight-btn">
                    {loading || editLoading || imageUploadLoading ? (
                      <CircularProgress size="20px" color="inherit" />
                    ) : props.selectedItem && Object.keys(props.selectedItem).length > 0 ? (
                      "Save"
                    ) : (
                      "Add Speaker"
                    )}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Drawer>
    </div>
  );
}
