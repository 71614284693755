import { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { Box, FormControl, Select, MenuItem, InputLabel } from "@mui/material";
import Orders from "./order";
import Refund from "./Refund";
import Event from "./Event";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Deposit from "./Deposit";
import { LIST_ALL_SCHOOL } from "../../Graphql/queries";
import { useQuery } from "@apollo/client";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Finance(props) {
  const [tabValue, setTabValue] = useState(0);
  const [eventId, setEventId] = useState(null);
  const [eventDtls, setEventDtls] = useState(null);
  const { data: schoolList } = useQuery(LIST_ALL_SCHOOL,{variables:{allSch: true}});

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div className="content-wrapper">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {!eventId ? (
          <div className="tabnav-normalhead">
            <Tabs
              value={tabValue}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Orders" {...a11yProps(0)} />
              <Tab label="Refund" {...a11yProps(1)} />
              <Tab label="Events" {...a11yProps(2)} />
              <Tab label="Deposit" {...a11yProps(3)} />
            </Tabs>
          </div>
        ) : (
          <div className="finance-e-dtl-header">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="d-flex align-item-center justify-content-between ">
                    <h3>{eventDtls.date+", "+eventDtls.name}</h3>
                    <a href="javascript:;" onClick={() => setEventId(null)}>
                      Back to Events
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div
          className="tabnav-content"
          style={eventId && { paddingTop: "70px" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <TabPanel className="tabpanel" value={tabValue} index={0}>
                  <Orders schoolList={schoolList?.listSchool?.data} />
                </TabPanel>
                <TabPanel className="tabpanel" value={tabValue} index={1}>
                  <Refund />
                </TabPanel>
                <TabPanel className="tabpanel" value={tabValue} index={2}>
                  <Event
                    setEventId={setEventId}
                    eventId={eventId}
                    eventDtls={eventDtls}
                    setEventDtls={setEventDtls}
                  />
                </TabPanel>
                <TabPanel className="tabpanel" value={tabValue} index={3}>
                  <Deposit />
                </TabPanel>
              </div>
            </div>
          </div>
        </div>
      </LocalizationProvider>
    </div>
  );
}
