import {Link} from "react-router-dom"
import draftToHtml from 'draftjs-to-html';
import { convertToRaw } from 'draft-js';
export default function ETemplate(props) {

  return (
    <div className="email-template">
      <table
        role="presentation"
        style={{ width: "600px", fontFamily: "Lato, sans-serif", margin:"auto" }}
      >
        <tr className="email-head">
          <td align="left" style={{ padding: "20px", background: "#2B54A3" }}>
            <img
              style={{ maxHeight: "50px" }}
              src="../assets/images/login-logo.svg"
              className="img-fluid login-logo"
              alt="logo"
            />
          </td>
        </tr>
        <tr className="email-body">
        <td style={{ padding: "20px" }}>
        <h1>{(props?.eventdetails?.enoticedetais?.eNoticeDetails?.subject)||(props?.eventdetails?.overviewRes?.updateENotice?.subject)||(props?.eventdetails?.overviewRes?.createENotice?.subject)}</h1>
          
          {/* <p className="etem-para m-0" dangerouslySetInnerHTML={{__html:props?.textValue?._cache.html}}> */}
          <p className="etem-para" dangerouslySetInnerHTML={{__html:props.textValue}}>
              {/* {< dangerouslySetInnerHTML={{__html:props?.textValue?._cache.html}} />} */}
              </p>
              {props?.eventdetails?.tabValue==="Schedule/Send"&&<p className="etem-para" dangerouslySetInnerHTML={{__html:(props?.eventdetails?.overviewRes?.updateENotice?.content)?
              (props?.eventdetails?.overviewRes?.updateENotice?.content):
              (props?.eventdetails?.enoticedetais?.eNoticeDetails?.content)||
                (props?.eventdetails?.overviewRes?.createENotice?.content)}}></p>}
          {/* <p className="etem-para m-0">{(props?.eventdetails?.enoticedetais?.eNoticeDetails?.content)||(props?.eventdetails?.overviewRes?.updateENotice?.content)||(props?.eventdetails?.overviewRes?.createENotice?.content)}</p> */}
            {/* <h1>Assistant Heads and Division Heads Roundtable Discussion</h1>
            <h2>
              Thursday, April 21, 4:00-5:00 PM
              <br />
              Free of Charge - Open to NYSAIS Assistant Heads and Division
              Heads, Deans of Faculty, After School Directors
            </h2>
            <h2>
              <a href="javascript:;">Registration is required.</a>
            </h2>
            <h3>
              Submit questions and topics for discussion when you{" "}
              <a href="javascript::">register here.</a>{" "}
            </h3>
            <p>
              This Interactive forum will allow participants to hear the
              perspectives and ask
              <br />
              questions of poors.
              <br />
              This month's session will be facilitated by:
              <br />
              Nitya York, Head of Middle School, Brooklyn Friends School
            </p>
            <p>
              This online session will be conducted utilizing the Zoom video
              collaboration program, which is simple to use and requires only an
              internet connection.. webcam, and microphone to participate. After
              registering for the session, participants will receive log-in
              information and instructions. Please note: this event will not be
              recorded.
            </p>
            <h3>Register for our Roundtable TODAY at <a href="javascript:;">this link.</a></h3> */}
          </td>
        </tr>
        <tr
          align="center"
          className="email-footer"
          style={{ background: "#2E3B63" }}
        >
          <td style={{ padding: "20px 0" }}>
          <Link
              to= {{pathname:"https://twitter.com/nysaisnow"}} target="_blank" style={{ padding: "0 13px" }}>
                <img
                  src="../assets/images/twitter2.svg"
                  className="sm-logo"
                  alt="logo"
                />
              </Link>
              <Link to={{pathname:"https://www.facebook.com/nysaisnow/"}} target="_blank" style={{ padding: "0 13px" }}>
                <img
                  src="../assets/images/fb.svg"
                  className="sm-logo"
                  alt="logo"
                />
              </Link>
              <Link to={{pathname:"https://www.youtube.com/user/NYSAIS"}} target="_blank" style={{ padding: "0 13px" }}>
                <img
                  src="../assets/images/yt.svg"
                  className="sm-logo"
                  alt="logo"
                />
              </Link>
          </td>
        </tr>
        <tr align="center">
            <td style={{padding:"30px 20px"}}>The New York State Association of Independent Schools<br/>
            17 Elk Street (1st Floor) | Albany, NY 12207 <br/> Telephone: 518-694-5500 | Fax: 518-694-5501
            </td>
        </tr>
        <tr>
            <td style={{padding:"0 20px 30px 20px", textAlign:"center"}}>The New York State Association of Independent Schools promotes the independence,
                 well-being and public understanding of, and respect for, New York independent
                  schools and serves as an accrediting body chartered by the New York State Board of Regents.
            </td>
        </tr>
      </table>
    </div>
    // <div className="template-logo">
    //     <header className="template-header">
    //     <img
    //           src="../assets/images/login-logo.svg"
    //           className="img-fluid login-logo"
    //           alt="logo"
    //         />
    //     </header>
    //     <div className="template-body">
    //         <h2>Assistant Heads and Division Heads Roundtable Discussion</h2>
    //         <td dangerouslySetInnerHTML={{__html: props?.textValue?._cache.html}} />
    //         {/* <p>{props.textValue._cache.html}</p> */}
    //     </div>
    //     <footer className="tem-footer">
    //         <div className="d-flex justify-content-center align-item-center">
    //         <img
    //           src="../assets/images/twitter2.svg"
    //           className="sm-logo"
    //           alt="logo"
    //         />
    //         <img
    //           src="../assets/images/fb.svg"
    //           className="sm-logo"
    //           alt="logo"
    //         />
    //         <img
    //           src="../assets/images/yt.svg"
    //           className="sm-logo"
    //           alt="logo"
    //         />
    //         </div>
    //     </footer>
    //     <div className="foot-details">
    //         <p className="foo-address text-center">The New York State Association of Independent Schools
    //             17 Elk Street (1st Floor) | Albany, NY 12207 | Telephone: 518-694-5500 | Fax: 518-694-5501</p>
    //         <p className="text-center">The New York State Association of Independent Schools promotes the independence, well-being and public
    //             understanding of, and respect for, New York independent schools and serves as an accrediting body chartered
    //             by the New York State Board of Regents.</p>
    //     </div>
    // </div>
  );
}
