import { makeVar } from "@apollo/client";
export const authVar = makeVar({
  loaded: false,
  auth: false,
  userType: null,
});
export const toggleSnackbar = makeVar({
  status: false,
  message: "",
  variant: "",
});
export const userDeatils = makeVar({
  data: null,
});
export const schoolDtls = makeVar({
  data: null,
});
export const mailGroupTitle = makeVar({
  title: '',
});
export const registerEvent = makeVar(null);
export const regDomainVar = makeVar(null);
