import React, { useState } from "react";
import { CircularProgress } from "@mui/material";
import { useReactiveVar } from "@apollo/client";
import { useHistory, useParams, Link } from "react-router-dom";

export default function AddSchoolHeader(props) {
  const {id} = useParams();
  const history = useHistory();
  return (
    <header className="event-header">
      <div className="event-btn-outer">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="d-flex justify-content-between align-items-center">
                <h3 className="event-head">{id==="$" ? "School Profile" : "Edit School Profile"}</h3>

                <div className="d-flex">
                  {/* <Link to="/schools" className="btn btn-outline-light">
                    Cancel 
                  </Link> */}
                  <a className="btn btn-outline-light" href="javascript:;"  onClick={() => history.goBack()}>Cancel</a>
                  <button type="submit" className="btn btn-light ms-3">
                    {props.loading ? (
                      <CircularProgress size="20px" color="inherit" />
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
