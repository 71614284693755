import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Pagination,
    MenuItem,
    Select,
  } from "@mui/material";
  import { useState } from "react";
  import { Link ,useParams,useHistory} from "react-router-dom"
  import {useQuery} from '@apollo/client'
  import { ENOTICE_MAIL_LOGS } from "../../Graphql/queries"; 
  export default function EnoticeUserList() {
    const history = useHistory();
    const {id}= useParams();
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState(null);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const getUserLogVariables = {
      skip: (page - 1) * rowsPerPage,
      limit: rowsPerPage,
      search: search,
      enoticeId:id
    };
    const {data,loading} = useQuery(ENOTICE_MAIL_LOGS,{variables:getUserLogVariables})

    const handleChangePage = (e, page) => {
        setPage(page);
      };
      function handlePageInputChange(event) {
        setRowsPerPage(Number(event.target.value));
        setPage(1);
      }


      return(
         <div className="content-wrapper">
        <div className="container">
          {/* <Loader open={loading}/> */}
          <div className="row">
            <div className="col-sm-12">
              
        <div className="card border-0">
          <div className="card-body p-0">
            <div className="filter-section d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <img
                  className="search-icon"
                  src="../assets/images/search.svg"
                  alt="search icon"
                />
                <input
                  className="searchbox"
                  placeholder="Search with Name / Email"
                  type="text"
                  value={search}
                  onChange={(e) => {setPage(1);setSearch(e.target.value)}}
                />
              </div>
              <div className="d-flex align-items-center justify-content-center"><a style={{color: 'black'}} href="javascript:;" onClick={()=> history.goBack()}>Back to E-Notice Listing</a></div>
            </div>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      First Name
                      
                    </TableCell>
                    
                    <TableCell>Last Name</TableCell>
                    <TableCell>
                      Email
                      
                    </TableCell>
                    <TableCell>
                      Read/Unread
                      
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody> 
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <p> Loading subscribers ...</p>
                      </div>
                    </TableCell>
                  </TableRow>
                ) : data?.enoticeMailLogs?.data && data?.enoticeMailLogs?.data?.length > 0 ?(
                      data?.enoticeMailLogs?.data?.map(item => (
                        <TableRow >
                        <TableCell>
                          <div className="d-flex">
                              {item?.firstName}
                          </div>
                        </TableCell>
                        
                        <TableCell><div className="ms-5">{item?.lastName}</div></TableCell>
                        <TableCell>{item?.recipient}</TableCell>
                        <TableCell>{(item?.status==="delivered"||item?.status==="accepted")?"Unread":(item?.status==="failed")?"Failed":"Read"}</TableCell>
                        
                      </TableRow>
                      ))):search ? (
                        <TableRow>
                          <TableCell colSpan={12}>
                            <div
                              style={{ display: "flex", justifyContent: "center" }}
                            >
                              <p>No subscribers Found</p>
                            </div>
                          </TableCell>
                        </TableRow>
                      ) : (
                        <TableRow>
                          <TableCell colSpan={12}>
                            <div
                              style={{ display: "flex", justifyContent: "center" }}
                            >
                              <p>No subscribers added yet!</p>
                            </div>
                          </TableCell>
                        </TableRow>
                      )
                      }
                </TableBody>
              </Table>
              {
            //   !loading && 
              (
                <div className="pagination-outer">
                   <div className="count-selector">
                   <Select
                    // variant="outlined"
                    onChange={handlePageInputChange}
                    value={rowsPerPage}
                  >
                    <MenuItem value={10}>{10}</MenuItem>
                    <MenuItem value={25}>{25}</MenuItem>
                    <MenuItem value={50}>{50}</MenuItem>
                    <MenuItem value={100}>{100}</MenuItem>
                  </Select>
                  <img className="count-arrow" src="../../assets/images/arrowupdown.svg" />
                  </div>
                 
                  <Pagination
                    count={Math.ceil(data?.enoticeMailLogs?.count / rowsPerPage)}
                    page={page}
                    shape="rounded"
                    onChange={handleChangePage}
                  />
                </div>
              )}
            </TableContainer>
          </div>
        </div>
        
            </div>
          </div>
        </div>
        
      </div>
      )
  }