import {
  TextField,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Avatar,
  Autocomplete,
  Checkbox,
  Tooltip
} from "@mui/material";
import { useEffect, useState } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  sclUserStatus,
} from "../SchoolOptions";
import { position } from "../../Common/Options";
import InfoIcon from "@mui/icons-material/Info";
import { useLazyQuery, useQuery, useReactiveVar } from "@apollo/client";
import { toggleSnackbar } from "../../../RactiveVariables";
import { GET_ALL_TOPICS, IMAGE_UPLOAD } from "../../../Graphql/queries";
import { regDomainVar } from "../../../RactiveVariables";
import { CropImage } from "../../Common";
import imageCompression from 'browser-image-compression';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export default function OrganisationAdmin(props) {
  const domainVar = useReactiveVar(regDomainVar);
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);
  const [cropImage, setCropImage] = useState(null);
  const [cropOpen, setCropOpen] = useState(false);
  const {
    register,
    errors,
    control,
    defaultValue,
    Controller,
    setValue
  } = props;
  const [upload, { data: uploadImageData }] = useLazyQuery(IMAGE_UPLOAD, {
    fetchPolicy: "network-only",
    onCompleted: res=>{
      let obj = {...props?.regUserImg};
      obj["publicUrl"] = res?.imageUpload?.publicUrl
      obj["signedUrl"] = res?.imageUpload?.signedUrl
      props?.setRegUserImg(obj);
    }
  });
  const getUserVariables = {
    skip: 0,
    limit: 500,
    isArchived: false,
  };

  const  { data: topicLists ,loading:topicLoading} = useQuery(GET_ALL_TOPICS, {
    fetchPolicy: "network-only",
    variables: getUserVariables,
    onCompleted:res=>{
      var arr=[]
      props?.selectedItem?.massTopicsIds?.forEach(element1 => {
        let position = res?.listTopics?.data?.findIndex((element) => element._id === element1);
        if(position > -1) {
          arr.push(res?.listTopics?.data?.[position])
        }
      });
      setValue("member.massTopicsIds",arr);
  }
  });

  useEffect(()=>{
    setValue('member.pronoun','To be updated by user')
  },[])

  const phoneNumberFormat = (num) => {
    let newNum = num.replace(/[-,(,), ]+/g, "");
    let x;
    if (newNum.length <= 3) {
      x = newNum;
    } else if (newNum.length > 3 && newNum.length <= 6) {
      x = "(" + newNum.slice(0, 3) + ") " + newNum.slice(3, 6);
    } else {
      x =
        "(" +
        newNum.slice(0, 3) +
        ") " +
        newNum.slice(3, 6) +
        "-" +
        newNum.slice(6, 10);
    }
    return x;
  };
  const [phoneNumber, setPhoneNumber] = useState(
    defaultValue?.schPhone ? phoneNumberFormat(defaultValue?.schPhone) : ""
  );

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  function handleUploadClick(imagefile) {
    let obj = {};
    obj["fileName"] = imagefile.name;
    obj["url"] = URL.createObjectURL(imagefile);
    obj["file"] = imagefile;
    props?.setRegUserImg(obj);
    upload({variables:{
      originalFilename: imagefile.name,
      path: "/userprofile",
    }})
  }
  function checkImage(e) {
    let file = e.target.files[0];
    if (
      !(
        file?.type == "image/jpeg" ||
        file?.type == "image/png" ||
        file?.type == "image/jpg"
      )
    ) {
      toggleSnackbar({
        status: true,
        message: "File does not support. You must use .png or .jpeg",
        variant: "error",
      });
      return false;
    }
    else if (file.size > 5242880) {
      toggleSnackbar({
        status: true,
        message: "Please upload a file smaller than 5 MB",
        variant: "error",
      });
      return false;
    }
    else{
      var options = {
        maxSizeMB: 1,
        // maxWidthOrHeight: 300,
        useWebWorker: true,
      };
      imageCompression(file, options)
      .then((compressedFile) => {
        setCropImage(compressedFile);
        setCropOpen(true);      
      })
      .catch((err) => {
        //console.log("err: ",err)
      })
    }
  }
  function handelCropDone(result) {
    setCropImage(null);
    handleUploadClick(result?.file, result?.fileUrl);
  }
  function sortLabels(a, b) {
    var nameA = a.toUpperCase();
    var nameB = b.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  }
  return (
    <div className="card mb-4">
      <input
        id="add-user-register-input"
        type="file"
        hidden
        // onChange={handleUploadClick}
        onChange={checkImage}
        onClick={(event) => {
          event.target.value = null;
        }}
      />
      <CropImage
        open={cropOpen}
        setOpen={setCropOpen}
        image={cropImage}
        handelCropDone={handelCropDone}
        aspectRatio={1}
      />
      <div className="card-title">
        <img src="../../assets/images/orgmember.svg" />
        Organization Admin
      </div>
      <div className="card-body">

        <div className="row">
            <div className="col-sm-12">
                <p className="form-text">Please provide your account information below. You will be the organization admin and can add additional members to your organization.</p>
            </div>
          <div className="col-sm-3" onClick={()=>document.getElementById("add-user-register-input").click()}>
            <Avatar className="img-fluid org-admin-img" src={props?.regUserImg ? props?.regUserImg?.url : "/broken-image.jpg"} variant="square" sx={{ width: "100%", height: "100%" }} />
          </div>
          <div className="col-sm-9">
            <div className="row row-set">
              <div className="col-sm-6">
                <InputLabel className="form-label">
                  first Name<sup className="text-danger">*</sup>
                </InputLabel>
                <TextField
                  className="mb-0"
                  style={{ width: "100%" }}
                  variant="outlined"
                    {...register("member.firstName")}
                    error={errors?.member?.firstName}
                  //   defaultValue={defaultValue?.schName}
                />
                {errors && errors?.member?.firstName && (
                  <p className="errorMessage text-danger">
                    {errors?.member?.firstName?.message}
                  </p>
                )}
              </div>
              <div className="col-sm-6">
                <InputLabel className="form-label">
                  last Name<sup className="text-danger">*</sup>
                </InputLabel>
                <TextField
                  className="mb-0"
                  style={{ width: "100%" }}
                  variant="outlined"
                  {...register("member.lastName")}
                    error={errors?.member?.lastName}
                  //   defaultValue={defaultValue?.schName}
                />
                {errors && errors?.member?.lastName && (
                  <p className="errorMessage text-danger">
                    {errors?.member?.lastName?.message}
                  </p>
                )}
              </div>
            </div>
            <div className="row mt-3 row-set">
              <div className="col-md-4">
                <InputLabel className="form-label">
                  title<sup className="text-danger">*</sup>
                </InputLabel>
                <TextField
                  style={{ width: "100%" }}
                  variant="outlined"
                    {...register("member.title")}
                  //   error={errors?.schAddress}
                  //   defaultValue={defaultValue?.schAddress}
                />
                {errors && errors?.member?.title && (
                  <p className="errorMessage text-danger">
                    {errors?.member?.title?.message}
                  </p>
                )}
              </div>
              <div className="col-md-4">
                <InputLabel className="form-label">
                  position<sup className="text-danger">*</sup>
                </InputLabel>
                <Select
                  className="w-100 m-10"
                  variant="outlined"
                    {...register("member.position")}
                  //   error={errors.schStatus}
                  //   defaultValue={defaultValue?.schStatus}
                >
                  {position?.sort(sortLabels).map((item) => (
                    <MenuItem value={item}>{item}</MenuItem>
                  ))}
                </Select>
                {errors && errors?.member?.position && (
                  <p className="errorMessage text-danger">
                    {errors?.member?.position?.message}
                  </p>
                )}
              </div>
              <div className="col-md-4">
                <InputLabel className="form-label">
                  Pronoun<sup className="text-danger">*</sup><span><Tooltip title="If this profile is for you, please add your preferred pronouns. Eg/ she/her/hers.
                  If this profile is for someone else, please leave the default text unless you know their preferred pronouns." 
                  placement="right-start">
                    <InfoIcon
                    style={{ width: "17px", color: "#0d6efd" }}
                    />
                  </Tooltip>
                  </span>
                </InputLabel>
                <TextField
                  style={{ width: "100%" }}
                  variant="outlined"
                    {...register("member.pronoun")}
                  //   error={errors?.schAddress}
                    defaultValue={"To be updated by user"}
                  placeholder="To be updated by user"
                />
                {errors && errors?.member?.pronoun && (
                  <p className="errorMessage text-danger">
                    {errors?.member?.pronoun?.message}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3 row-set">
          <div className="col-md-6">
            <InputLabel className="form-label">
              Phone<sup className="text-danger">*</sup>
            </InputLabel>
            <TextField
              style={{ width: "100%" }}
              variant="outlined"
              value={phoneNumber}
              //   error={errors?.schPhone}
                onChange={(e) => {
                  register("member.phone").onChange({
                    target: { value: e.target.value, name: "member.phone" },
                  });
                  setPhoneNumber(phoneNumberFormat(e.target.value));
                }}
            />
            {errors && errors?.member?.phone && (
                  <p className="errorMessage text-danger">
                    {errors?.member?.phone?.message}
                  </p>
                )}
          </div>
          <div className="col-md-6">
            <InputLabel className="form-label">
              email<sup className="text-danger">*</sup>
            </InputLabel>
            <TextField
              style={{ width: "100%" }}
              variant="outlined"
              defaultValue={domainVar?.email}
              {...register("member.email")}
              //   error={errors?.schFax}
              //   defaultValue={defaultValue?.schFax}
            />
            {errors && errors?.member?.email && (
                  <p className="errorMessage text-danger">
                    {errors?.member?.email?.message}
                  </p>
                )}
          </div>
          <div className="col-md-12 mt-3 topic-select topics-tags" >
              <InputLabel className="form-label">
                Topic Notifications
              </InputLabel>
              <Controller
              name="member.massTopicsIds"
              control={control}
              // defaultValue={newArray}
              render={({ field: { onChange, value } }) => (
              <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                options={topicLists?.listTopics?.data||[]}
                disableCloseOnSelect
                getOptionLabel={(option) => option.topic}
                value={value||[]}
                onChange={(event, value) => {onChange(value)}}
                // loading={topicLoading}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.topic}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params}
                  // InputProps={{
                  //   ...params.InputProps,
                  //   endAdornment: (
                  //     <Fragment>
                  //       {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  //       {params.InputProps.endAdornment}
                  //     </Fragment>
                  //   ),
                  // }}
                  />
                )}
              />
              )}
                />
              {/* {errors && errors?.["massTopicsIds"] && (
                <p className="errorMessage text-danger">
                  {errors?.massTopicsIds?.message}
                </p>)} */}
            </div>
        </div>
        {/* <div className="row mt-3">
          <div className="col-md-6">
            <InputLabel className="form-label">
              password<sup className="text-danger">*</sup>
            </InputLabel>
            <OutlinedInput
              className="form-control password-field"
              id="standard-adornment-password"
              type={showPassword ? "text" : "password"}
              error={errors?.member?.password}
              // value={values.password}
              {...register("member.password")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {errors && errors?.member?.password && (
                  <p className="errorMessage text-danger">
                    {errors?.member?.password?.message}
                  </p>
                )}
          </div>
          <div className="col-md-6">
            <InputLabel className="form-label">
              Confirm password<sup className="text-danger">*</sup>
            </InputLabel>
            <OutlinedInput
              className="form-control password-field"
              id="standard-adornment-password1"
              type={showCPassword ? "text" : "password"}
              error={errors?.member?.password}
              // value={values.password}
              {...register("member.confirmpassword")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={()=>setShowCPassword(!showCPassword)}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showCPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {errors && errors?.member?.confirmpassword && (
                  <p className="errorMessage text-danger">
                    {errors?.member?.confirmpassword?.message}
                  </p>
                )}
          </div>
        </div> */}
      </div>
    </div>
  );
}
