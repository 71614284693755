import {
  List,
  ListItem,
  Collapse,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";
import { schoolDtls ,userDeatils } from "../../RactiveVariables";
import { useState } from "react";
const sideMenuItems = [
  { label: "Dashboard", img: "Dashboard" },
  {
    collapse: true,
    label: "Events",
    url: "/events",
    img: "Events",
    subMenu: [
      { label: "Locations", url: "/location", img: "Location" },
      { label: "Speakers", url: "/speaker", img: "Speaker" },
    ],
    accessLabel: "Events",
  },
  { label: "Schools", url: "/schools", img: "Schools", accessLabel : "Member Schools" },
  { label: "Users", url: "/users", img: "Users" },
  { label: "School Users", url: "/schoolusers", img: "Users" },
  { label: "Groups", img: "Forums", accessLabel : "Forums", url: "/groups",otherUrl:"/groups-details",otherUrl1:"/conversation"  },
  { label: "User Groups", url: "/usergroup", img: "User Groups", accessLabel : "Users Group" },
  { label: "Finance", url: "/finance", img: "Finance", accessLabel : "Finance" },
  { label: "Mailing Groups",url: "/newsletter", img: "Newsletter", otherUrl:"/userlisting"},
  { label:"E-Notices", url:"/enotice", img: "E notice", otherUrl:"/userlists"},
  { label:"Manage Topics", url:"/manage-topics", img: "settings-icon"},
];
const schoolSideMenuItems = [
  { label: "Orders", url: "/orderlisting", img: "Dashboard"},
  { label: "School Events", url: "/schoolevent", img: "Events", accessLabel : "events" },
  { label: "Careers", url: "/schoolcareer", img: "briefcase1", accessLabel : "careers" },
  { label: "Profile", img: "Schools", url: "/schoolprofile", accessLabel : "schoolprofile" },
  { label: "Users", url: "/schooluser", img: "Users", accessLabel : "Users"},
  { label: "Groups", img: "Forums", url: "/groups",otherUrl:"/groups-details",otherUrl1:"/conversation" },
  // { label: "Browse Events", url: "/events", img: "Events" },
  { label: "My Events", url: "/myevents", img: "Events"},
];

const guestSideMenuItems = [
  { label: "Orders", url: "/orderlisting", img: "Dashboard" },
  { label: "My Events", url: "/myevents", img: "Events"},
];
export default function SideMenu(props) {
  const schoolDtl = useReactiveVar(schoolDtls);
  const user = useReactiveVar(userDeatils);
  const userType = user?.data?.loginType;
  const [menuItems, setMenuItems] = useState(
    userType=== "Admin"  ? sideMenuItems : userType==="Guest" ? guestSideMenuItems : schoolSideMenuItems
  );
  return (
    <div className="custom-sidebar d-none d-md-block">
      <List component="nav" className="p-0 menu" style={{ cursor: "pointer" }}>
        {menuItems.map((item) => {
          if(user.data.loginType==="SchoolUser"){
            if(user?.data?.userType!=="Admin" && item.accessLabel &&  !(user?.data?.moduleAcs.find(element => element.title.toLowerCase() === item.accessLabel.toLowerCase() && element.status===true)))
              return null;
          }
          else if (user.data.loginType==="Admin"){
            if(item.accessLabel &&  !(user?.data?.userGpDtls?.moduleAcs.find(element => element.title.toLowerCase() === item.accessLabel.toLowerCase() && element.status===true)))
              return null;
          }
            
          if (item.collapse)
            return (
              <>
                <Link to={item.url} style={{ textDecoration: "none" }}>
                  <ListItem
                    className="menu-item"
                    key={item.label}
                    button
                    // selected={
                    //   item.url == props?.pathname ||
                    //   "/speaker" == props?.pathname ||
                    //   "/location" == props?.pathname
                    //     ? true
                    //     : false
                    // }
                  >
                    <img
                      src={`../assets/images/${item.img}.svg`}
                      className="img-fluid"
                      alt="logo"
                    />
                    <label style={{ cursor: "pointer" }}>{item.label}</label>
                  </ListItem>
                </Link>
                <Collapse
                  in={
                    item.url == props?.pathname ||
                    "/speaker" == props?.pathname ||
                    "/location" == props?.pathname
                      ? true
                      : false
                  }
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding className="ms-3">
                    <Link to={item.url} style={{ textDecoration: "none" }}>
                      <ListItem
                        className="menu-item"
                        key={item.label}
                        button
                        selected={item.url === props?.pathname ? true : false}
                      >
                        <img
                          src={`../assets/images/${item.img}.svg`}
                          className="img-fluid"
                          alt="logo"
                        />
                        <label style={{ cursor: "pointer" }}>
                          {item.label}
                        </label>
                      </ListItem>
                    </Link>
                    {item.subMenu.map((subMenu) => (
                      <Link to={subMenu.url} style={{ textDecoration: "none" }}>
                        <ListItem
                          className="menu-item"
                          key={subMenu.label}
                          button
                          selected={
                            subMenu.url === props?.pathname ? true : false
                          }
                        >
                          <img
                            src={`../assets/images/${subMenu.img}.svg`}
                            className="img-fluid"
                            alt="logo"
                          />
                          <label style={{ cursor: "pointer" }}>
                            {subMenu.label}
                          </label>
                        </ListItem>
                      </Link>
                    ))}
                  </List>
                </Collapse>
              </>
            );
          else if (item.url){
            if(item.label==="Groups" && user.data && user.data.schoolDetails && user.data.schoolDetails?.schStatus==="Non-Member")
              return null;
            return (
              <Link to={item.url} style={{ textDecoration: "none" }}>
                <ListItem
                  className="menu-item"
                  key={item.label}
                  button
                  selected={(item.url == props?.pathname || (item.otherUrl && props?.pathname.includes(item.otherUrl)) ||(item.otherUrl1 && props?.pathname.includes(item.otherUrl1))) ? true : false}
                >
                  <img
                    src={`../assets/images/${item.img}.svg`}
                    className="img-fluid"
                    alt="logo"
                  />
                  <label style={{ cursor: "pointer" }}>{item.label}</label>
                </ListItem>
              </Link>
            );
          }
          else
            return (
              <ListItem className="menu-item" key={item.label} button>
                <img
                  src={`../assets/images/${item.img}.svg`}
                  className="img-fluid"
                  alt="logo"
                />
                <label style={{ cursor: "pointer" }}>{item.label}</label>
              </ListItem>
            );
        })}
      </List>
    </div>
  );
}
