import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  InputLabel,
  TextField,
  Drawer,
  Button,
  CircularProgress,
  Chip,
} from "@mui/material";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { CREATE_EVENT } from "../../../Graphql/mutations";
import { toggleSnackbar } from "../../../RactiveVariables/index";
import { IMAGE_UPLOAD } from "../../../Graphql/queries";
import axios from "axios";

export default function AddAdditionalInfo(props) {
  const [addAdditionalInfo, { loading }] = useMutation(CREATE_EVENT);
  const schema = yup.object().shape({
    title: yup.string().required("Title is required"),
  });
  const { register, handleSubmit, formState, reset } = useForm({
    defaultValues: props.defaultValue,
    resolver: yupResolver(schema),
  });
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUploadLoading, setImageUploadLoading] = useState(false);
  const uplaodImageVariables = {
    originalFilename: selectedImage?.fileName,
    path: "/userprofile", //change folder name
  };
  const [upload, { data: uploadImageData }] = useLazyQuery(IMAGE_UPLOAD, {
    variables: uplaodImageVariables,
  });

  const { errors } = formState;
  async function uploadImage(params) {
    setImageUploadLoading(true);
    if (selectedImage) {
      let url = uploadImageData?.imageUpload?.signedUrl;
      const response = await axios.put(url, selectedImage.file, {
        headers: {
          "Content-Type": selectedImage?.file?.type,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "X-Requested-With",
        },
      });
    }
    setImageUploadLoading(false);
    onSubmit(params);
  }

  const onSubmit = (params) => {
    if (selectedImage && selectedImage?.imageFile !== null){
      params["fileUrl"] = uploadImageData?.imageUpload?.publicUrl;    
      props.handleInfoSubmit(params)
      closeDrawer();
      props.setOpen(false);
    }
    else 
    toggleSnackbar({
      status: true,
      message: "Please select a file",
      variant: "error",
    });
  };

  function closeDrawer() {
    props.setOpen(false);
    // props.setSelectedItem(null);
    setSelectedImage(null);
    reset();
  }
  useEffect(() => {
    reset();
  }, [props.selectedItem]);
  function handleUploadClick(event) {
    if(event.target.files[0].type!=="application/pdf"){
      toggleSnackbar({
        status: true,
        message: "Please select a PDF",
        variant: "error",
      });
      return false;
    }
    let obj = {};
    var file = event.target.files[0];
    obj["fileName"] = file.name;
    obj["url"] = URL.createObjectURL(file);
    obj["file"] = file;
    setSelectedImage(obj);
    upload();
    
  }

  return (
    <div>
      <input
        id="additional-info-input"
        type="file"
        hidden
        onChange={handleUploadClick}
        onClick={(event) => {
          event.target.value = null;
        }}
      />
      <Drawer anchor={"right"} open={props.open} onClose={closeDrawer}>
        <div className="sidebar-toggler" role="presentation">
          <div className="title-outer">
            <h1>Add Additional Information</h1>
            <div onClick={closeDrawer} className="close-icon">
              X
            </div>
          </div>
          <div className="userform-scroller">
            {/* <div className="d-flex justify-content-center user-outer">
              <div className="avatar-outer">
                <Avatar
                  src={
                    selectedImage
                      ? selectedImage.url
                      : props?.selectedItem?.profileImage
                      ? props?.selectedItem?.profileImage
                      : "/broken-image.jpg"
                  }
                  sx={{ width: 110, height: 110 }}
                />
                <span
                  onClick={() =>
                    document.getElementById("additional-info-input").click()
                  }
                >
                  +
                </span>
              </div>
            </div> */}
            <form
              className="adduser-form"
              onSubmit={handleSubmit(uploadImage)}
              noValidate
            >
              <div className="row">
                <div className="col-md-12 mt-3 mb-3">
                  <InputLabel className="form-label">Title</InputLabel>
                  <TextField
                    {...register("title")}
                    variant="outlined"
                    error={errors.title}
                    // defaultValue={props?.selectedItem?.title}
                  />
                  {errors && errors?.["title"] && (
                    <p className="errorMessage text-danger">
                      {errors?.title?.message}
                    </p>
                  )}
                </div>
                <div className="col-sm-12">
                  <a className="btn btn-orange" href="javascript:;"  onClick={() =>
                    document.getElementById("additional-info-input").click()}>Add File</a>
                    {selectedImage && <Chip
                    className="ms-2"
                    label={selectedImage?.fileName}
                    variant="outlined"
                    onDelete={()=>setSelectedImage(null)}
                  />}
                </div>
              </div>

              <div className="d-flex justify-content-between align-items-center btn-section">
                <Button className="outline-btn" onClick={() => closeDrawer()}>
                  Cancel
                </Button>
                <div className="d-flex align-items-center">
                  <Button type="submit" className="highlight-btn">
                    {loading || imageUploadLoading ? (
                      <CircularProgress size="20px" color="inherit" />
                    ) : (
                      "Add Info"
                    )}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Drawer>
    </div>
  );
}
