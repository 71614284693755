import { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  Button,
  TextField,
  Menu,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Pagination,
  Drawer,
  IconButton
} from "@mui/material";
import { useMutation, useReactiveVar } from "@apollo/client";
import { toggleSnackbar, schoolDtls,  } from "../../../RactiveVariables/index";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { useQuery } from "@apollo/client";
import { LIST_CAREER } from "../../../Graphql/queries";
import { DELETE_CAREER } from "../../../Graphql/mutations";
import moment from "moment";
import { categoryArray, eventTypeArray,careerType } from "../../Events/EventOptions";
import { useParams, Link } from "react-router-dom";
import ConfirmDialog from "../../Common/ConfirmDialog";
import { calculateDate } from "../../Common/GeneralFunctions";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import CloseIcon from '@mui/icons-material/Close';

export default function SchoolCareer(props) {
  // const [category, setcategorytype] = useState(null);
  const [search, setSearch] = useState(null);
  const [page, setPage] = useState(1);
  const [deleteId, setDeleteId] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [selectedItem, setSelectedItem] = useState(null);
  const schoolDetails = useReactiveVar(schoolDtls);
  const [startDate, setStartDate] = useState(null);
  const [careerTypeValue, setCareerTypeValue] = useState(null);
  const [status, setStatus] = useState(null);
  const [openFilter,setOpenFilter]= useState(false)
  const [deleteCareer, { loading: deleteLoadinng }] =
    useMutation(DELETE_CAREER);

  const { id } = useParams();
  const getCareerVariables = {
    skip: (page - 1) * rowsPerPage,
    limit: rowsPerPage,
    search: search,
    schId: schoolDetails?.schoolId ? schoolDetails?.schoolId : id,
    startDate: startDate,
    careerType:careerTypeValue,
    status: status
  };
  const {
    data: careerList,
    fetchMore,
    loading,
  } = useQuery(LIST_CAREER, {
    fetchPolicy:"network-only",
    variables: getCareerVariables,
  });

  function closeConfirm() {
    setDeleteId(null);
  }
  function handleConfirm() {
    handleDelete();
    closeConfirm();
    handlePagination();
  }

  function handleDelete() {
    deleteCareer({ variables: { careerIds: [deleteId] } })
      .then((res) => {
        toggleSnackbar({
          status: true,
          message: "Career deleted successfully",
          variant: "success",
        });
        handlePagination();
      })
      .catch((error) => {
        const message = error && error.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      });
  }
  const handlePagination = () => {
    fetchMore({
      variables: getCareerVariables,
    });
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, item) => {
    setSelectedItem(item);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChangePage = (e, page) => {
    setPage(page);
  };
  function handlePageInputChange(event) {
    setRowsPerPage(Number(event.target.value));
    setPage(1);
  }
  function convertDate(str) {
    if(str){
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    let formatedDate = [mnth, day, date.getFullYear()].join("/");
    return formatedDate == "aN/aN/NaN" || formatedDate == "01/01/1970"
      ? null
      : formatedDate;
    }
  }
  const handleCareerType = (event) => {
    setPage(1);
    setCareerTypeValue(event.target.value);
  };

  return (
    <div className="content-wrapper">
      <ConfirmDialog
        open={deleteId}
        title={"Delete"}
        body={"Are you sure you want to delete this Career ? "}
        closeText={"Cancel"}
        confirmText={"Delete"}
        closeConfirm={closeConfirm}
        handleConfirm={handleConfirm}
      />
      <div className="container">
      <div className="d-block d-md-none">
          <h3 className="m-h3">{schoolDetails?.data?.schNameThe}</h3>
        </div>
      <Drawer
        className="m-menu-drawer w-100"
        anchor={"right"}
        open={openFilter}
        onClose={() => {
          setOpenFilter(false);
        }}
      >
        <div className="container" role="presentation">
            <div onClick={()=>setOpenFilter(false)} className="d-flex justify-content-end mt-3 mb-3">
              <IconButton>
                <CloseIcon/>
              </IconButton>
            </div>
            <Box className="mb-4" sx={{ minWidth: '100%' }}>
              <FormControl fullWidth className="filter-date-drawer">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    className="w-100"
                    inputFormat="MM/dd/yyyy"
                    value={startDate ? startDate : null}
                    renderInput={(params) => <TextField {...params} />}
                    onChange={(e) => {setPage(1);setStartDate(convertDate(e))}}
                  />
                </LocalizationProvider>
              </FormControl>
            </Box>
            <Box className="mb-4" sx={{ minWidth: '100%' }}>
              <FormControl fullWidth>
                <InputLabel className="" id="type">
                  Type
                </InputLabel>
                <Select
                  className="filter-select"
                  labelId="type"
                  id="demo-type"
                  value={careerTypeValue}
                  label="type"
                  onChange={handleCareerType}
                >
                  {/* <MenuItem value="All">{"All"}</MenuItem> */}
                  {careerType.map((item) => (
                    <MenuItem value={item.value}>{item.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box className="mb-5" sx={{ minWidth: '100%' }}>
              <FormControl fullWidth>
                <InputLabel className="filter-label" id="type">
                  Status
                </InputLabel>
                <Select
                  className="filter-select"
                  labelId="type"
                  id="demo-type"
                  value={status}
                  label="Status"
                  onChange={(e)=>{setPage(1);setStatus(e.target.value)}}
                >
                  <MenuItem value="Active">{"Active"}</MenuItem>
                  <MenuItem value="Inactive">{"Inactive"}</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Button color="primary" variant="contained"  onClick={()=>setOpenFilter(false)} className="w-100">Apply Filter</Button>
        </div>
      </Drawer>
        <div className="row">
          <div className="col-sm-12">
            <div className="card border-0">
              <div className="card-body p-0">
                <div className="filter-section d-flex justify-content-between">
                  <div className="d-flex align-items-center">
                    <img
                      className="search-icon"
                      src="../assets/images/search.svg"
                      alt="search icon"
                    />
                    <input
                      className="searchbox"
                      placeholder="Search by job title"
                      value={search}
                      type="text"
                      onChange={(e) => {setPage(1);setSearch(e.target.value)}}
                    />
                  </div>
                  <div className="d-flex align-items-center justify-content-center">
                    <div onClick={()=>setOpenFilter(true)} className="d-md-none">
                    <img
                      className=""
                      src="../assets/images/filter-icon-new.svg"
                      alt="filter icon"
                      style={{width:'35px'}}
                    />
                    </div>
                    <Box sx={{ minWidth: 177 }} className="d-none d-md-block">
                      <FormControl fullWidth className="filter-date">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            inputFormat="MM/dd/yyyy"
                            value={startDate ? startDate : null}
                            renderInput={(params) => <TextField {...params} />}
                            onChange={(e) => {setPage(1);setStartDate(convertDate(e))}}
                          />
                        </LocalizationProvider>
                      </FormControl>
                    </Box>
                    <Box className="ms-2 d-none d-md-block" sx={{ minWidth: 177 }} >
                      <FormControl fullWidth>
                        <InputLabel className="filter-label" id="type">
                          Type
                        </InputLabel>
                        <Select
                          className="filter-select"
                          labelId="type"
                          id="demo-type"
                          value={careerTypeValue}
                          label="type"
                          onChange={handleCareerType}
                        >
                          {/* <MenuItem value="All">{"All"}</MenuItem> */}
                          {careerType.map((item) => (
                            <MenuItem value={item.value}>{item.label}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                    <Box className="ms-2 d-none d-md-block" sx={{ minWidth: 177 }} >
                      <FormControl fullWidth>
                        <InputLabel className="filter-label" id="type">
                          Status
                        </InputLabel>
                        <Select
                          className="filter-select"
                          labelId="type"
                          id="demo-type"
                          value={status}
                          label="Status"
                          onChange={(e)=>{setPage(1);setStatus(e.target.value)}}
                        >
                          <MenuItem value="Active">{"Active"}</MenuItem>
                          <MenuItem value="Inactive">{"Inactive"}</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
               
                    <Link
                      role="button"
                      type="button"
                      className="btn add-btn ms-2"
                      to={`/addschoolcareer/${props?.schoolId}||$`}
                    >
                      + Add Career
                    </Link>
                  </div>
                </div>
                <TableContainer component={Paper}>
                  <Table aria-label="simple table" className="mob-table-layout">
                    <TableHead>
                      <TableRow>
                        {/* <TableCell>Job Number</TableCell> */}
                        <TableCell className="w-175">Job Title</TableCell>
                        <TableCell className="w100">Date Posted</TableCell>
                        <TableCell className="w100">Start Date</TableCell>
                        <TableCell  className="w-125" align="center"># of Applicants</TableCell>
                        <TableCell align="center" className="w-90">Type</TableCell>
                        <TableCell align="center" className="w-90" >Status</TableCell>
                        <TableCell align="center" className="w-90"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {loading ? (
                        <TableRow>
                          <TableCell colSpan={6}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <p> Loading Career...</p>
                            </div>
                          </TableCell>
                        </TableRow>
                      ) : careerList?.listAllCareer?.data &&
                        careerList?.listAllCareer?.data.length > 0 ? (
                        careerList?.listAllCareer?.data?.map((item) => (
                          <TableRow>
                            {/* <TableCell><Link className="preview-link" to={`/schoolcareerpreview/${item._id}`}>{`#${item.jobNumber}`}</Link></TableCell> */}
                            <TableCell><Link className="preview-link" to={`/schoolcareerpreview/${item._id}`}>{item.title}</Link></TableCell>
                            <TableCell>
                              {moment(new Date(item.createdDate)).utc().format(
                                "MMM D, YYYY"
                              )}
                            </TableCell>
                            <TableCell>
                              {/* {moment(new Date(item.createdDate)).format(
                                "MMM DD, YYYY"
                              )} */}
                              {/* {calculateDate(
                                item.createdDate,
                                item.expVal,
                                item.expType
                              )} */}
                              {moment(new Date(item.startDate)).utc().format(
                                "MMM D, YYYY"
                              )}
                            </TableCell>
                            <TableCell align="center">{item.noOfApplicant}</TableCell>
                            <TableCell align="center">{item.careerType}</TableCell>
                            <TableCell align="center">{item.status===true ? "Active":"Inactive"}</TableCell>
                            <TableCell align="center">
                              <Button
                                id="basic-button"
                                aria-controls="basic-menu"
                                aria-haspopup="true"
                                aria-expanded={open ? "true" : undefined}
                                onClick={(e) => handleClick(e, item)}
                                className="p-0 action-btn"
                              >
                                <img
                                  src="../assets/images/dots.svg"
                                  alt="edit button image"
                                />
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : search ? (
                        <TableRow>
                          <TableCell colSpan={6}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <p>No Careers Found</p>
                            </div>
                          </TableCell>
                        </TableRow>
                      ) : (
                        <TableRow>
                          <TableCell colSpan={6}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <p>No Career Added Yet!</p>
                            </div>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                  {!loading && (
                    <div className="pagination-outer">
                      <div className="count-selector">
                        <Select
                          onChange={handlePageInputChange}
                          value={rowsPerPage}
                        >
                          <MenuItem value={10}>{10}</MenuItem>
                          <MenuItem value={25}>{25}</MenuItem>
                          <MenuItem value={50}>{50}</MenuItem>
                          <MenuItem value={100}>{100}</MenuItem>
                        </Select>
                        <img
                          className="count-arrow"
                          src="../../assets/images/arrowupdown.svg"
                        />
                      </div>
                      <Pagination
                        count={Math.ceil(
                          careerList?.listAllCareer?.count / rowsPerPage
                        )}
                        page={page}
                        shape="rounded"
                        onChange={handleChangePage}
                      />
                    </div>
                  )}
                </TableContainer>
              </div>
            </div>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "dropdown-button",
              }}
            >
              <MenuItem onClick={handleClose}>
                <Link
                  className="actionbtn-menu"
                  to={`/addschoolcareer/${props?.schoolId}||${selectedItem?._id}`}
                >
                  Edit
                </Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <a
                  className="actionbtn-menu"
                  onClick={() => setDeleteId(selectedItem?._id)}
                  href="javascript:;"
                >
                  Delete
                </a>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Link
                  className="actionbtn-menu"
                  to={`/schoolcareerpreview/${selectedItem?._id}`}
                >
                  Preview
                </Link>
              </MenuItem>
            </Menu>
          </div>
        </div>
      </div>
    </div>
  );
}
