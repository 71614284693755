import { Button } from "@mui/material";
import { IMAGE_UPLOAD } from "../../../Graphql/queries";
import { useLazyQuery } from "@apollo/client";
import { CropImage } from "../../Common";
import imageCompression from "browser-image-compression";
import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import CropIcon from "@mui/icons-material/Crop";
export default function Banner(props) {
  const [cropImage, setCropImage] = useState(null);
  const [cropOpen, setCropOpen] = useState(false);
  const [upload, { data: uploadImageData }] = useLazyQuery(IMAGE_UPLOAD, {
    fetchPolicy: "network-only",
    onCompleted: (res) => {
      let array = [...props.bannerImages];
      let position = array.findIndex(
        (element) => element.fileName === res?.imageUpload?.filename
      );
      let obj = array[position];
      obj["publicUrl"] = res?.imageUpload?.publicUrl;
      obj["signedUrl"] = res?.imageUpload?.signedUrl;
    },
  });
  function compressImage(file) {
    var options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 300,
      useWebWorker: true,
    };

    imageCompression(file[0], options)
      .then((compressedFile) => {
        setCropImage(compressedFile);
        setCropOpen(true);
      })
      .catch((err) => {

      });
  }
  function handleImageUpload(file, src) {
    let array = [...props.bannerImages];
    let fileName = '';
    let fileExtension = '';
    let newFileName = file?.name.toLowerCase();
    // newFileName = newFileName.split('.')[0]+'.'+newFileName.split('.')[1].toLowerCase()

    // Rename filename if duplicate found.
    for (let index = 0; index < array.length; index++) {
      
      const element = array[index];
      if (typeof file != "undefined" && element.fileName != 'undefined' && element.fileName === newFileName) {
        fileName = file.name.substring(0, file.name.lastIndexOf('.'));
        fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
        newFileName = fileName + '-' + Date.now() + fileExtension.toLowerCase();
      }
    }

    // let array = [];
    upload({
      variables: { originalFilename: newFileName, path: "/school" },
    });
    array.push({
      fileName: newFileName,
      url: src,
      file: file,
    });
    props.setBannerImages(array);
  }
  function handelCropDone(result,fileUrl) {
    setCropImage(null);
    handleImageUpload(result, fileUrl);
  }
  function handleRemove(index) {
    let array = [...props.bannerImages];
    array.splice(index, 1);
    props.setBannerImages(array);
  }
  return (
    <div className="card mb-4 banner-image-outer">
      <CropImage
        open={cropOpen}
        setOpen={setCropOpen}
        image={cropImage}
        handelCropDone={handelCropDone}
        // aspectRatio="16/9"
      />
      <input
        type="file"
        className="d-none"
        multiple
        id="school-banner-imageUpload"
        accept='.jpg, .png, .jpeg'
        onChange={(e) => {
          
          var options = {
            maxSizeMB: 1,
            useWebWorker: true,
          };
          imageCompression(e.target.files[0], options)
          .then((compressedFile) => {
            setCropImage(compressedFile);
            setCropOpen(true);
            // compressImage(e.target.files);
          })
          .catch((err) => {
            //console.log("err: ",err)
          })
        }}
        onClick={(event)=> {
          event.target.value = null
        }}
      />
      <div className="card-title justify-content-between">
        <div className="d-flex">
          <img src="../../assets/images/BannerIcon.svg" /> Banner
        </div>
        <a
          className="addspeaker-btn"
          onClick={() =>
            document.getElementById("school-banner-imageUpload").click()
          }
          href="javscript:;"
        >
          <img src="../../assets/images/addimage.svg" />
          Add Image
        </a>
      </div>
      <div>
        {props.bannerImages.map((item, index) => (
          <div className="banner-inner">
            <div 
            className="banner-delete-icon"
            >
              <DeleteIcon onClick={() => handleRemove(index)} />
            </div>
            <img className={"banner-image"} src={item.url} />
          </div>
        ))}
        {/* <div>
          <div
            className="banner-image-upload"
            onClick={() =>
              document.getElementById("school-banner-imageUpload").click()
            }
          >
            <img src={"../../assets/images/addPhoto.svg"} />
          </div>
        </div> */}
      </div>
      {/* <div className="school-btn-div">
        <Button className="btn  btn-cancel" variant="contained">
          Cancel
        </Button>
        <Button className="btn add-btn btn-orange" variant="contained">
          Save
        </Button>
      </div> */}
    </div>
  );
}
