import { useState } from "react";
import { IconButton } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import CloseIcon from '@mui/icons-material/Close';

export default function LoginMobHeader(props) {
  const [openHeaderMenu, setOpenHeaderMenu] = useState(false)
  return (
    <div>
      <header className="fixed-top header loginheader d-flex d-md-none">
        <div className="container">
          <Drawer
            className="m-menu-drawer"
            anchor={"right"}
            open={openHeaderMenu}
            onClose={() => {
              setOpenHeaderMenu(false);
            }}
          >
            <div>
              <div className="d-flex justify-content-end">
                <IconButton
                  className="m-menu-close"
                  onClick={() => setOpenHeaderMenu(false)}
                >
                  <CloseIcon />
                </IconButton>
              </div>
              <nav className="login-side-menu">
                  <ul id="menu-header-top-menu" class="menu-items">
                    <li class="menu-item"><a title="About" href={process.env.REACT_APP_WP_URL + "/about/"}>About</a></li>
                    <li class="menu-item"><a title="Schools" href={process.env.REACT_APP_WP_URL + "/schools/"}>Schools</a></li>
                    <li class="menu-item"><a title="Events" href={process.env.REACT_APP_WP_URL + "/events/"}>Events</a></li>
                    <li class="menu-item"><a title="Professional Learning" href={process.env.REACT_APP_WP_URL + "/professional_learning/"}>Professional Learning</a></li>
                    <li class="menu-item"><a title="Careers" href={process.env.REACT_APP_WP_URL + "/careers/"}>Careers</a></li>
                    <li class="menu-item"><a title="Accreditation" href={process.env.REACT_APP_WP_URL + "/accreditation/"}>Accreditation</a></li>
                    <li class="menu-item"><a title="Athletics" href={process.env.REACT_APP_WP_URL + "/athletics/"}>Athletics</a></li>
                    <li class="menu-item"><a title="Contact" href={process.env.REACT_APP_WP_URL + "/contact/"}>Contact</a></li>
                  </ul>
                
              </nav>
            </div>
          </Drawer>
          <div className="d-flex align-items-center justify-content-between">
            <a href={process.env.REACT_APP_WP_URL}><img className="top-logo" src="../assets/images/logo.svg" /></a>

            <IconButton onClick={() => setOpenHeaderMenu(true)} className="d-block d-md-none p-0" >
              <img
                src="../assets/images/hamburger-menu.svg"
                className="img-fluid hamburger-icon hamburger-icon-mob m-0"
                alt="Menu Icon"
              />
            </IconButton>
          </div>
        </div>
      </header>
    </div>
  );
}
