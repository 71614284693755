import { useState } from "react";
import { useQuery } from "@apollo/client";
import { FINANCE_REFUND } from "../../Graphql/queries";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  FormControl,
  InputLabel,
  CircularProgress,
  Pagination,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import moment from "moment";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import { useHistory, Link } from "react-router-dom";
import { formatCurrency } from "../Common/GeneralFunctions";
export default function Refund(props) {
  const [search, setSearch] = useState("");
  const date =new Date().getFullYear()
  const history = useHistory();
  let currentTime =new Date()
  var month = currentTime.getMonth() + 1
  const [dateFrom, setDateFrom] = useState(month<9?`${date-1}-09-01T18:14:16.887Z`:`${date}-09-01T18:14:16.887Z`);
  const [dateTo, setDateTo] = useState(month>=8?`${date+1}-08-30T18:14:16.887Z`:`${date}-08-30T18:14:16.887Z`);
  // const [dateFrom, setDateFrom] = useState(moment(new Date()).subtract(1, 'month'));
  // const [dateTo, setDateTo] = useState(new Date());
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [loadingCSV,setLoadingCSV] = useState(false);
  const [csvData,setCsvData] = useState('')
  const variables = {
    search: search,
    dateFrom: dateFrom,
    dateTo: dateTo,
    skip: (page - 1) * rowsPerPage,
    limit: rowsPerPage,
  };

  const { data: financeData, loading } = useQuery(FINANCE_REFUND, {
    fetchPolicy: "network-only",
    variables: variables,
  });

  const handleChangePage = (e, page) => {
    setPage(page);
  };
  function handlePageInputChange(event) {
    setRowsPerPage(Number(event.target.value));
    setPage(1);
  }
  function downloadTxtFile () {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      "search":search||"",
      "dateFrom":dateFrom||"",
      "dateTo":dateTo||"",
    });
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    fetch(`${process.env.REACT_APP_API_URL}/finance/financeRefundReport`, requestOptions)
      .then(response => response.text())
      .then(result => {
        const element = document.createElement("a");
        const file = new Blob([result], {type: 'text/csv'});
        element.href = URL.createObjectURL(file);
        element.download = "myFile.csv";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
        setLoadingCSV(false)
      })
      .catch(error => console.log('error', error));

   

  }

  return (
    <>
      <div className="tabnav-filter">
        <div className="container">
          <div className="row">
            <div className="col-sm-3 d-flex">
              <div className="d-flex align-items-center w-100">
                <img src="../assets/images/search.svg" />
                <input
                  className="tabsearch-field"
                  type="text"
                  onChange={(e) => {setPage(1);setSearch(e.target.value)}}
                  placeholder="Search by Name / Order Id / Reg. Id"
                />
              </div>
            </div>
            <div className="col-sm-9 d-flex justify-content-end">
              <div className="d-flex">
              <Box className="ms-2" sx={{ minWidth: 177 }}>
                  <FormControl fullWidth>
                    <DesktopDatePicker
                      inputFormat="MM/dd/yyyy"
                      value={dateFrom ? dateFrom : null}
                      renderInput={(params) => (
                        <TextField {...params} className="date-field" />
                      )}
                      onChange={(e) =>{setPage(1); setDateFrom(e)}}
                    />
                  </FormControl>
                </Box>
                <Box className="ms-2" sx={{ minWidth: 177 }}>
                  <FormControl fullWidth>
                    <DesktopDatePicker
                      inputFormat="MM/dd/yyyy"
                      value={dateTo ? dateTo : null}
                      renderInput={(params) => (
                        <TextField {...params} className="date-field" />
                      )}
                      onChange={(e) => {setPage(1);setDateTo(e)}}
                    />
                  </FormControl>
                </Box>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="finance-order-outer">
        <div>
          <TableContainer component={Paper}>
            <div className="d-flex align-items-center justify-content-between m-3">
              <h2 className="ordercnt">
                {financeData?.financeRefund?.count || "-" + " "} Refunds
              </h2>
              <a
                href="javascript:;"
                className="btn btn-orange d-flex align-items-center"
                onClick={()=>{downloadTxtFile();setLoadingCSV(true);}}
              >
                <img className="me-1" src="../assets/images/download.svg" />
                {loadingCSV===true? <CircularProgress size="20px" color="inherit"  /> :  "Download"}
              </a>
            </div>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Reg. Id</TableCell>
                  <TableCell>Order Id</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Cancelled Reg.</TableCell>
                  <TableCell>Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <p> Loading Refunds...</p>
                      </div>
                    </TableCell>
                  </TableRow>
                ) : financeData?.financeRefund?.refRes &&
                  financeData?.financeRefund?.refRes.length > 0 ? (
                  financeData?.financeRefund?.refRes.map((item) => (
                    <TableRow key={item.orderNo}>
                      <TableCell>{moment(parseInt(item.updatedDate)).format('ll')}</TableCell>
                      <TableCell>#{item.regId}</TableCell>
                      <TableCell  style={{cursor:"pointer"}} onClick={()=>{history.push({pathname:`/orderdetails/${item?.orderId}`,state: { detail: 'finance' }});}}>#{item.orderNo}</TableCell>
                      <TableCell>{item?.memberName}</TableCell>
                      <TableCell>{item?.cardType}</TableCell>
                      <TableCell>{item.cancelledReg}</TableCell>
                      <TableCell>{"$ "+formatCurrency(item?.refundAmount)}</TableCell>
                    </TableRow>
                  ))
                ) : search ? (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <p>No Refund Found</p>
                      </div>
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <p>No Refund Yet!</p>
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            {!loading && (
              <div className="pagination-outer">
                <div className="count-selector">
                  <Select
                    // variant="outlined"
                    onChange={handlePageInputChange}
                    value={rowsPerPage}
                  >
                    <MenuItem value={10}>{10}</MenuItem>
                    <MenuItem value={25}>{25}</MenuItem>
                    <MenuItem value={50}>{50}</MenuItem>
                    <MenuItem value={100}>{100}</MenuItem>
                  </Select>
                  <img
                    className="count-arrow"
                    src="../../assets/images/arrowupdown.svg"
                  />
                </div>

                <Pagination
                  count={Math.ceil(
                    financeData?.financeRefund?.count / rowsPerPage
                  )}
                  page={page}
                  shape="rounded"
                  onChange={handleChangePage}
                />
              </div>
            )}
          </TableContainer>
        </div>
      </div>
    </>
  );
}
