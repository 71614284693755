import { TextField, InputLabel, Select, MenuItem } from "@mui/material";
import { useState, useEffect } from "react";
import RichTextEditor from "react-rte";
import { sclUserStatus, regionOptions, subRegionOptions } from "../SchoolOptions";
import { sortLabels } from "../../Common/GeneralFunctions";
import { Controller } from "react-hook-form";
import AutoComplete from "../../Common/AutoComplete";
import { regDomainVar, userDeatils } from "../../../RactiveVariables";
import { useReactiveVar } from "@apollo/client";
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
export default function Profile(props) {
  console.log("test", props);
  const {
    register,
    errors,
    defaultValue,
    registerDescription,
    textEditorValue,
    setTextEditorValue,
    control,
    id,
    setValue,
    trigger,
    checkDomain,
    checkDomainValue,
  } = props;
  useEffect(() => {
    if (domainVar?.domain)
      setValue("socialMediaWeb.Website", domainVar.domain)
    if (domainVar?.schName)
      setValue("schName", domainVar.schName)
  }, [])
  const domainVar = useReactiveVar(regDomainVar);
  const user = useReactiveVar(userDeatils);
  const phoneNumberFormat = (num) => {
    let newNum = num.replace(/[-,(,), ]+/g, "");
    let x;
    if (newNum.length <= 3) {
      x = newNum;
    } else if (newNum.length > 3 && newNum.length <= 6) {
      x = "(" + newNum.slice(0, 3) + ") " + newNum.slice(3, 6);
    } else {
      x =
        "(" + newNum.slice(0, 3) +
        ") " +
        newNum.slice(3, 6) +
        "-" +
        newNum.slice(6, 10);
    }
    return x;
  };
  const [phoneNumber, setPhoneNumber] = useState(defaultValue?.schPhone ? phoneNumberFormat(defaultValue?.schPhone) : "");
  const [faxNumber, setFaxNumber] = useState(defaultValue?.schFax ? phoneNumberFormat(defaultValue?.schFax) : "");
  // function onChangeEditor(value) {
  //   if (value.toString("html") === "<p><br></p>") {
  //     registerDescription.onChange({
  //       target: { value: "", name: "schDescription" },
  //     });
  //   } else {
  //     registerDescription.onChange({
  //       target: { value: value.toString("html"), name: "schDescription" },
  //     });
  //   }
  //   setTextEditorValue(value);
  // }
  const toolbarConfig = {

    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'HISTORY_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
      { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
      { label: 'Italic', style: 'ITALIC' },
      { label: 'StrikeThrough', style: 'STRIKETHROUGH', }
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: 'UL', style: 'unordered-list-item' },
      { label: 'OL', style: 'ordered-list-item' }
    ]
  };
  function handlePlaceSelect(value) {
    let strAddr = "";
    for (let i = 0; i < value?.terms.length - 3; ++i) {
      if (strAddr === "")
        strAddr = value.terms[i].value;
      else
        strAddr = strAddr + ", " + value.terms[i].value;
    }
    setValue("schAddress", strAddr);
    setValue("latitude", value?.location?.lat + "");
    setValue("longitude", value?.location?.lng + "");
    if (value) {
      setValue("schCity", value?.terms?.[value?.terms.length - 3]?.value);
      setValue("schState", value?.terms?.[value?.terms.length - 2]?.value);
      setValue("schZip", value?.zip);
    }
    trigger("schAddress");
  }
  const onEditorStateChange = (editorState) => {
    setTextEditorValue(editorState)
    registerDescription.onChange({
      target: { value: draftToHtml(convertToRaw(textEditorValue.getCurrentContent())), name: "schDescription" },
    });
  }
  return (
    <div className="card mb-4">
      <div className="card-title">
        <img src="../../assets/images/Profile.svg" />School Profile
      </div>
      <div className="card-body">
        <div className="row row-set">
          <div className="col-md-2">
            <InputLabel className="form-label">
              The
            </InputLabel>
            <TextField
              autoFocus
              disabled={id !== "$" && user?.data?.loginType !== "Admin" ? true : false}
              style={{ width: "100%" }}
              variant="outlined"
              {...register("the")}
              // error={errors?.schName}
              defaultValue={defaultValue?.the}
            />
            {/* {errors && errors?.["the"] && (
              <p className="errorMessage text-danger">
                {errors?.schName?.message}
              </p>
            )} */}
          </div>
          <div className="col-md-8">
            <InputLabel className="form-label">
              School Name<sup className="text-danger">*</sup>
            </InputLabel>
            <TextField
              autoFocus
              disabled={id !== "$" && user?.data?.loginType !== "Admin" ? true : false}
              style={{ width: "100%" }}
              variant="outlined"
              {...register("schName")}
              error={errors?.schName}
              defaultValue={defaultValue?.schName ? defaultValue?.schName : domainVar?.schName}
            />
            {errors && errors?.["schName"] && (
              <p className="errorMessage text-danger">
                {errors?.schName?.message}
              </p>
            )}
          </div>
        </div>
        <div className="row mt-3">
          <div className={props?.pathname.includes("addschool") ? "col-md-6" : "col-md-12"}>
            <InputLabel className="form-label">Domain<sup className="text-danger">*</sup></InputLabel>
            <Controller
              control={control}
              name="socialMediaWeb.Website"
              render={({ field: { onChange, value } }) => (
                <TextField
                  disabled={(id !== "$" || props.websiteFlag || domainVar?.domain) && user?.data?.loginType !== "Admin"}
                  // className="www-sign"
                  style={{ width: "100%" }}
                  variant="outlined"
                  error={errors?.socialMediaWeb?.Website}
                  onChange={(e) => {
                    onChange(e.target.value.split(".")[0] === "www" ? e.target.value.substring(4) : e.target.value);
                    checkDomain({
                      variables: {
                        domain: e.target.value,
                        emailId: null,
                        cType: "School",
                      }
                    })
                  }}
                  value={value}
                />
              )}
            />
            {(errors?.socialMediaWeb?.Website || checkDomainValue()) && (
              <p className="errorMessage text-danger">
                {errors?.socialMediaWeb?.Website ? errors?.socialMediaWeb?.Website?.message : "Website already exist"}
              </p>
            )}
          </div>
          {props?.pathname.includes("addschool") && <div className="col-md-6">
            <InputLabel className="form-label">
              Status<sup className="text-danger">*</sup>
            </InputLabel>
            <Select
              disabled={id !== "$" && user?.data?.loginType !== "Admin" ? true : false}
              className="w-100"
              variant="outlined"
              {...register("schStatus")}
              error={errors.schStatus}
              defaultValue={defaultValue?.schStatus}
            >
              {sclUserStatus.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}

            </Select>
            {errors && errors?.["schStatus"] && (
              <p className="errorMessage text-danger">
                {errors?.schStatus?.message}
              </p>
            )}
          </div>}
        </div>
        <div className="row mt-3 row-set">
          <div className="col-md-4">
            <InputLabel className="form-label">
              Address<sup className="text-danger">*</sup>
            </InputLabel>
            {/* <TextField
              style={{ width: "100%" }}
              variant="outlined"
              {...register("schAddress")}
              error={errors?.schAddress}
              defaultValue={defaultValue?.schAddress}
            /> */}
            <AutoComplete
              handlePlaceSelect={handlePlaceSelect}
              defaultValue={defaultValue?.schAddress}
            />
            {errors && errors?.["schAddress"] && (
              <p className="errorMessage text-danger">
                {errors?.schAddress?.message}
              </p>
            )}
          </div>
          <div className="col-md-3">
            <InputLabel className="form-label">
              City<sup className="text-danger">*</sup>
            </InputLabel>
            <TextField
              style={{ width: "100%" }}
              variant="outlined"
              {...register("schCity")}
              error={errors?.schCity}
              defaultValue={defaultValue?.schCity}
            />
            {errors && errors?.["schCity"] && (
              <p className="errorMessage text-danger">
                {errors?.schCity?.message}
              </p>
            )}
          </div>
          <div className="col-md-3">
            <InputLabel className="form-label">
              State<sup className="text-danger">*</sup>
            </InputLabel>
            <TextField
              style={{ width: "100%" }}
              variant="outlined"
              {...register("schState")}
              error={errors?.schState}
              defaultValue={defaultValue?.schState}
            />
            {errors && errors?.["schState"] && (
              <p className="errorMessage text-danger">
                {errors?.schState?.message}
              </p>
            )}
          </div>
          <div className="col-md-2">
            <InputLabel className="form-label">
              Zip<sup className="text-danger">*</sup>
            </InputLabel>
            <TextField
              style={{ width: "100%" }}
              variant="outlined"
              {...register("schZip")}
              error={errors?.schZip}
              defaultValue={defaultValue?.schZip}
            />
            {errors && errors?.["schZip"] && (
              <p className="errorMessage text-danger">
                {errors?.schZip?.message}
              </p>
            )}
          </div>
        </div>
        <div className="row mt-3 row-set">
          <div className="col-md-6">
            <InputLabel className="form-label">
              Region<sup className="text-danger">*</sup>
            </InputLabel>
            {/* <TextField
              style={{ width: "100%" }}
              variant="outlined"
              {...register("schRegion")}
              error={errors?.schRegion}
              defaultValue={defaultValue?.schRegion}
            /> */}
            <Select
              className="w-100 m-10"
              variant="outlined"

              error={errors.schRegion}
              defaultValue={defaultValue?.schRegion}
              name="schRegion"
              onChange={(e) => {
                register("schRegion").onChange(e);
                setValue("schSubRegion", "");
              }}
            >
              {regionOptions?.sort(sortLabels).map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}

            </Select>
            {errors && errors?.["schRegion"] && (
              <p className="errorMessage text-danger">
                {errors?.schRegion?.message}
              </p>
            )}
          </div>
          <div className="col-md-6">
            <Controller
              control={control}
              name="schRegion"
              render={({ field: { onChange, value } }) => (
                <>
                  <InputLabel className="form-label">
                    Sub Region<sup className="text-danger">*</sup>
                  </InputLabel>
                  <Select
                    className="w-100"
                    variant="outlined"
                    {...register("schSubRegion")}
                    error={errors.schSubRegion}
                    defaultValue={defaultValue?.schSubRegion}
                  >
                    {value && subRegionOptions[value].sort(sortLabels).map((item) => (
                      <MenuItem value={item}>{item}</MenuItem>
                    ))}
                  </Select>
                  {errors && errors?.["startDate"] && (
                    <p className="errorMessage text-danger">
                      {errors?.startDate?.message}
                    </p>
                  )}
                </>
              )}
            />
            {/* <TextField
              style={{ width: "100%" }}
              variant="outlined"
              {...register("schSubRegion")}
              error={errors?.schSubRegion}
              defaultValue={defaultValue?.schSubRegion}
            /> */}
            {errors && errors?.["schSubRegion"] && (
              <p className="errorMessage text-danger">
                {errors?.schSubRegion?.message}
              </p>
            )}
          </div>
        </div>
        <div className="row mt-3 row-set">
          <div className="col-md-6">
            <InputLabel className="form-label">
              Phone
            </InputLabel>
            <TextField
              style={{ width: "100%" }}
              variant="outlined"
              value={phoneNumber}
              error={errors?.schPhone}
              onChange={(e) => {
                register("schPhone").onChange({
                  target: { value: e.target.value, name: "schPhone" },
                });
                setPhoneNumber(phoneNumberFormat(e.target.value))
              }}
            />
            {errors && errors?.["schPhone"] && (
              <p className="errorMessage text-danger">
                {errors?.schPhone?.message}
              </p>
            )}
          </div>
          <div className="col-md-6">
            <InputLabel className="form-label">
              Fax
            </InputLabel>
            <TextField
              style={{ width: "100%" }}
              variant="outlined"
              value={faxNumber}
              // {...register("schFax")}
              onChange={(e) => {
                register("schFax").onChange({
                  target: { value: e.target.value, name: "schFax" },
                });
                setFaxNumber(phoneNumberFormat(e.target.value))
              }}
              // error={errors?.schFax}
              defaultValue={defaultValue?.schFax}
            />
            {/* {errors && errors?.["schFax"] && (
              <p className="errorMessage text-danger">
                {errors?.schFax?.message}
              </p>
            )} */}
          </div>
          <div className="col-md-12 mt-3">
            <InputLabel className="form-label">
              Description<sup className="text-danger">*</sup>
            </InputLabel>
            <div className="tab-outer">
              {/* <RichTextEditor
              className="text-editor-max-height"
              value={textEditorValue}
              {...registerDescription}
              toolbarConfig={toolbarConfig}
              onChange={(value) => onChangeEditor(value)}
            /> */}
              <div>
                {/* <Editor
                // editorState={textEditorValue}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                onEditorStateChange={(e)=>setTextEditorValue(e)}
              /> */}
                {/* <textarea
                disabled
                value={textEditorValue ? draftToHtml(convertToRaw(textEditorValue.getCurrentContent())) : ""}
              /> */}
                <Editor
                  editorState={textEditorValue}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onEditorStateChange={onEditorStateChange}
                />
              </div>
              {errors && errors?.["schDescription"] && (
                <p className="errorMessage text-danger">
                  {errors?.schDescription?.message}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
