import React, { useState } from "react";
import { CircularProgress } from "@mui/material";
import { useReactiveVar } from "@apollo/client";
import { useHistory, useParams, Link,useLocation } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Avatar,
  Pagination,
  DialogTitle,
  DialogActions,
  DialogContentText,
  DialogContent,
  MenuItem,
  Dialog,
  Select,
} from "@mui/material";
export default function ENoticeHeader(props) {
  const {id} = useParams();
  const history = useHistory();
  const location = useLocation();
  const clone = location?.state?.detail;
  console.log('clone',clone);
  const handleCloseDialog = () => {
    props?.setOpenDialog(false);
  };
  return (
    <header className="event-header">
      <div className="event-btn-outer">
      <Dialog
        open={props?.openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="send-modal"
      >
        <DialogTitle className="d-flex justify-content-center sendmail-title" id="alert-dialog-title">
         <span >{"Review Your Campaign"}</span> 
          <span className="close-sendicon" style={{cursor:"pointer"}} onClick={()=>props?.setOpenDialog(false)}>X</span>
        </DialogTitle>
        <DialogContent className="text-center pb-0">

          <p className="mb-1">You are about to send a campaign to:</p> 
          <p className="mail-modal-text">The selected mailgroups have {props?.overviewRes?.updateENotice?.emailCnt||props?.enoticedetais?.eNoticeDetails?.emailCnt} contacts</p>

        </DialogContent>
        <div>
        <div className="d-flex mb-4 justify-content-around top-margin align-items-center btn-section w-100">
        <Button className="btn-cancel px-4" onClick={()=>props?.setOpenDialog(false)} autoFocus>
          Cancel
          </Button>
          <div className="d-flex align-items-center">
          <Button className="btn btn-orange px-3" onClick={()=>{props?.handleSendENotice()}} autoFocus>
          Send Now
          </Button>
          </div>
          </div>
        </div>
      </Dialog>
        <div className="container-margin">
          <div className="row">
            <div className="col-sm-12">
              <div className="d-flex justify-content-between align-items-center">
              {props.backBtn && <Link
                  to={props?.backUrl ? props?.backUrl : "/"}
                  style={{ top: "-4px" }}
                  type="submit"
                  className="btn back-btn"
                >
                  <img src="../assets/images/arrow-left.svg" />
                </Link>}
                <h3 className="event-head">{id==="$" ? "Create E-Notice" : "Edit E-Notice"}</h3>

                <div className="d-flex">
                  {/* <Link to="/schools" className="btn btn-outline-light">
                    Cancel 
                  </Link> */}
                  <a className="btn btn-outline-light" href="javascript:;"  onClick={() => history.goBack()}>Cancel</a>
                  <button type="submit" className="btn btn-outline-light ms-3" onClick={() =>{props.tabValue==="overview"&&clone!=='clone'? 
                  (props?.overviewRes?.createENotice?._id||props?.enoticedetais?.eNoticeDetails?._id)&&props.setTabValue("Preview & Compose"):props?.tabValue==="Schedule/Send"?(props?.overviewRes||props?.enoticedetais)&& props?.setOpenDialog(true):clone!=='clone'&&props.setTabValue("Schedule/Send");}}>
                    {props.loading ? (
                      <CircularProgress size="20px" color="inherit" />
                    ) : props.tabValue==="overview"?(
                      "Preview & Compose"
                    ):("Send")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
