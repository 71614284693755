const platformArray = [
  "Zoom",
  "Google Meet",
  "Skype",
  "Cisco Webex",
  "Uber Conference",
  "Go ToMeeting",
];
const categoryArray = [
  { label: "eSeminar", value: "eSeminar" },
  { label: "Residential Conference", value: "Residential Conference" },
  { label: "Workshops", value: "Workshops" },
  { label: "E-Conferences", value: "E-Conferences" },
  { label: "Institutes", value: "Institutes" },
  { label: "Community Conversations", value: "Community Conversations" },
  { label: "Symposia", value: "Symposia" },
  { label: "Job Fair", value: "Job Fair" },
  { label: "Regional Meeting", value: "Regional Meeting" },
];
const eventTypeArray = [
  { label: "In-Person", value: "In-Person" },
  { label: "Remote", value: "Remote" },
  { label: "Hybrid", value: "Hybrid" },
];
const careerType = [
  { label: "Full-time", value: "Full-time"},
  { label: "Part-time", value: "Part-time"},
  { label: "Temporary", value: "Temporary"}
];
const statusArray = [
  { label: "Published", value: "Published", isSpeakerAdded: true },
  // { label: "Draft", value: "Draft" },
  { label: "Unpublished", value: "Draft" },
  { label: "Completed", value: "Completed" },
];
const Pronoun = [
  "They/Them/Theirs",
  "She/Her/Hers",
  "He/Him/His",
  "My pronoun isn't listed",
  "Prefer not to say",
];

const tagArray = [
  {
    label: "Technology",
    name: "Technology",
  },
  {
    label: "Pedagogy",
    name: "Pedagogy",
  },
  {
    label: "Governance",
    name: "Governance",
  },
  {
    label: "Admissions",
    name: "Admissions",
  },
  {
    label: "Advancement",
    name: "Advancement",
  },
  {
    label: "Division",
    name: "Division",
  },
  {
    label: "DEIJ",
    name: "DEIJ",
  },
  {
    label: "Leadership",
    name: "Leadership",
  },
  {
    label: "Curriculum",
    name: "Curriculum",
  },
  {
    label: "SEL",
    name: "SEL",
  },
];

export {
  platformArray,
  categoryArray,
  eventTypeArray,
  statusArray,
  Pronoun,
  tagArray,
  careerType
};
