import React, { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toggleSnackbar } from "../../../RactiveVariables";
import {formatCurrency, formatDate, formatTime, formatTimefromDate, formatDateandTime} from "../../Common/GeneralFunctions"
import { Link, useHistory, useParams } from "react-router-dom";
import moment from "moment";
import {
  useMutation,
  useQuery,
  useLazyQuery,
  useReactiveVar,
} from "@apollo/client";
import { GET_ALLSPEAKERS, EVENT_DETAILS, USER_TICKET_STATUS } from "../../../Graphql/queries";
import { DELETE_DRAFT_ORDER } from "../../../Graphql/mutations";
import OrderPopup from "../../Order/OrderPopup";
import Order from "../../Order/index";
import Ticket from "../../Ticket/index";
import EventUpdates from "./EventUpdates";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import {
  InputLabel,
  TextField,
  Select,
  MenuItem,
  Box,
  Tabs,
  Tab,
  Typography,
  Modal,
  Autocomplete,
} from "@mui/material";
import EventHeader from "../../Common/EventHeader";
import { authVar, registerEvent, userDeatils, schoolDtls } from "../../../RactiveVariables";
import { ConfirmDialog } from "../../Common";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

// document.getElementsByClassName("disc-tile ").attr('target', '_blank');

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function EventPreview(props) {
  const [registeropen, setRegisterOpen] = useState(false);
  const [deleteDraft, setDeleteDraft] = useState(false);
  const [selectedSpeakerValue, setSelectedSpeakerValue] = useState([]);
  const auth = useReactiveVar(authVar);
  const user = useReactiveVar(userDeatils);
  const { id } = useParams();
  const [deleteDraftOrder] = useMutation(DELETE_DRAFT_ORDER,{ refetchQueries:['listAllOrders']});
  const {
    register,
    handleSubmit,
    formState,
    reset,
    setValue: setFormValue,
    control,
  } = useForm({
    defaultValues: {},
    // resolver: yupResolver(schema),
  });
  const [getEventDetails, { data: eventData }] = useLazyQuery(EVENT_DETAILS, {
    fetchPolicy: "network-only",
  });
  const [userTicketStatus, { data: ticketStatusData }] = useLazyQuery(USER_TICKET_STATUS, {
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    if (id !== "$") getEventDetails({ variables: { id: id } });
    if(user.data.loginType==="SchoolUser"){
      userTicketStatus({variables:{
        schoolUserId:user.data._id,eventId:id
      }})
    }
  }, []);
  const [value, setValue] = useState(
    props?.history?.location?.state?.selected
      ? props?.history?.location?.state?.selected
      : 0
  );
  



  const handleChange = (event, newValue) => {
    setValue(newValue);
  };





  const eventDetails = eventData?.eventDetails;
  function setEventDetails() {
    registerEvent({
      eventId: id,
      memberPrice: eventDetails?.memberPrice,
      nonMemberPrice: eventDetails?.nonMemberPrice,
    });
  }
// const eventPreviewadd= [
//   {
//     label: "Address",
//     name: "address",
//     image: "address",
//     value: formatAddress(
//       eventDetails?.offLocDtls?.streetAdrs.replace('USA',''),
//       eventDetails?.offLocDtls?.city.replace('USA',''),
//       eventDetails?.offLocDtls?.state.replace('USA',''),
//       eventDetails&&eventDetails?.offLocDtls?.zip
//     ),
//   },
// ]
// function formatAddress(locName,addr, city, state, zip) {
//   if(addr && city && state && zip)
//     return addr.replace(/,/g, "")+"<br>"+city+", "+ state+", "+zip
//   }
function handleDeleteDraft() {
  try {
    setDeleteDraft(false);
    deleteDraftOrder({ variables: { eventId: id} })
      .then((res) => {
        toggleSnackbar({
          status: true,
          message: "Draft Orders deleted successfully",
          variant: "success",
        });
      })
      .catch((error) => {
        const message = error && error.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      });
    }catch (error) {
      const message = error && error.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
}
  let newAddre=eventDetails&&eventDetails?.offLocDtls?.streetAdrs?.replace(',','')
  // const speakers =  eventDetails?.speakerIDs[0].name
  const defaultDisclaimer = "<ul>\n<li>Registration closes 1 hour before the start of the event.</li>\n<li>Be sure to enter all attendee email addresses correctly.</li>\n<li>The Zoom link for this eSeminar will be sent in the confirmation email after you finish the registration process. Please do not consider yourself officially registered until you receive the email.</li>\n<li>This eSeminar will be recorded and, depending on privacy and quality concerns, may be provided to registered attendees after the presentation.</li>\n<li>Most registrations may be cancelled by registered users until 10 days before an event starts, after which users can reach out to support@nysais.org with any COVID-related requests.</li>\n</ul>"
  return (
    <div className="">
      <ConfirmDialog
        open={deleteDraft}
        title={"Delete"}
        body={"Are you sure you want to delete all draft orders ? "}
        closeText={"Cancel"}
        confirmText={"Delete"}
        closeConfirm={()=>setDeleteDraft(false)}
        handleConfirm={handleDeleteDraft}
      />
      <EventHeader 
        setRegisterOpen={setRegisterOpen} 
        title="Event" 
        eventpreview 
        id={id} 
        register={eventDetails?.status==="Published"&&moment(new Date(eventDetails?.lastDateToRegister)).isAfter()} 
        setEventDetails={setEventDetails} 
        handleDeleteDraft={handleDeleteDraft}
        setDeleteDraft={setDeleteDraft}
      />
      <div className="preview-outer">
        <div className="preview-tab">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="Overview" {...a11yProps(0)} />
                  {auth.auth&& 
                    (user?.data?.loginType!=="Admin" 
                      && ticketStatusData 
                      && ticketStatusData.userTicketStatus?.status 
                      || user?.data?.loginType==="Admin") && <Tab label="updates" {...a11yProps(1)} />}
                  {auth.auth && user?.data?.loginType==="Admin"&&  <Tab label="orders" {...a11yProps(2)} />}
                  {auth.auth && user?.data?.loginType==="Admin"&&  <Tab label="Registrations" {...a11yProps(3)} />}
                </Tabs>
              </div>
            </div>
          </div>
        </div>
        <TabPanel value={value} index={0}>
          <div className="container">
            <div className="row">
              <div className={user?.data?.loginType==="Admin" ? "col-sm-8" : "col-sm-12"}>
                <div className="card preview-card mb-4">
                {
                  eventDetails?.banner.length > 0 ? <img className="preview-banner" src={eventDetails?.banner.map((item) => item?.url)} /> :  <img className="preview-banner no" src="../assets/images/bannernoimage.svg" />
                }
                  <div className="card-body">
                    <h2 className="main-title">{eventDetails?.title}</h2>
                    <p className="sub-title">
                      Target Audience: {eventDetails?.targetAudience}
                      {/* roles {item.category} */}
                    </p>
                    <ul className="event-specifications">
                      <li>
                        <img src="../assets/images/previewcalender.svg" />
                        <span>
                          {
                             formatDate(eventDetails?.startDate) === formatDate(eventDetails?.endDate) ? 
                             formatDate(eventDetails?.endDate) : formatDateandTime(eventDetails?.startDate)
                             + " to " + formatDateandTime(eventDetails?.endDate)
                          }
                        </span>
                      </li>
                      {formatDate(eventDetails?.startDate) === formatDate(eventDetails?.endDate) && <li>
                        <img src="../assets/images/previewclock.svg" />
                        <span>
                          {formatTimefromDate(eventDetails?.startDate)}{" "}
                          to{" "}
                          {formatTimefromDate(eventDetails?.endDate)}
                        </span>
                      </li>}
                    </ul>
                    <div className="disc-tile">
                      <h2>Description</h2>
                      <p>{ReactHtmlParser(eventDetails?.description)}</p>
                    </div>
                    {/* {eventDetails?.disclaimer && ( */}
                      <div className="disc-tile">
                        <h2>Disclaimer</h2>
                        <p>{ReactHtmlParser(eventDetails?.disclaimer??defaultDisclaimer)}</p>
                      </div>
                    {/* )} */}
                    {eventDetails?.emailContent && (
                      <div className="disc-tile">
                        <h2>Override Confirmation Email</h2>
                        <p>{ReactHtmlParser(eventDetails?.emailContent)}</p>
                      </div>
                    )}
                    {/* <div className="disc-tile">
                      <h2>DISCLAIMER</h2>
                      <p>
                        "Lorem ipsum dolor sit amet, consectetur adipiscing
                        elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua. Ut enim ad minim veniam, quis
                        nostrud exercitation ullamco laboris nisi ut aliquip ex
                        ea commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit
                        anim id est laborum."
                      </p>
                    </div> */}
                  </div>
                </div>
                {eventDetails?.speakerIDs &&
                eventDetails?.speakerIDs.length > 0 ? (
                  <div className="card preview-card mb-4">
                    <div className="card-title">
                      <h4>Speaker/Presentor</h4>
                      {user?.data?.loginType==="Admin"&& <Link to={`/addevent/${id}`}>
                        <img src="../assets/images/previewedit.svg" />
                      </Link>}
                    </div>
                    {eventDetails?.spkrDtls?.map((item) => (
                      <div className="card-body">
                        <div className="d-md-flex">
                          <div className="speaker-img-outer">
                            {item?.profileImage ? (
                              <img src={item?.profileImage} />
                            ) : (
                              <img src="../assets/images/noimage.svg" />
                            )}
                          </div>
                          <div className="speaker-disc-outer">
                            <h3 className="speaker-name">
                              {item.firstName + " " + item.lastName}{" "}
                              {item?.title&&<span>{`(${item?.title})`}</span>}
                            </h3>
                            <p className="speaker-details">{item?.bio}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <span></span>
                )}

                {(eventDetails?.eventType==="Remote" ||eventDetails?.eventType==="Hybrid") && 
                ticketStatusData && ticketStatusData.userTicketStatus?.status && (
                  <div className="card preview-card mb-4">
                    <div className="card-title">
                      <h4>Remote</h4>
                      {user?.data?.loginType==="Admin"&&  <Link to={`/addevent/${id}`}>
                        <img src="../assets/images/previewedit.svg" />
                      </Link>}
                    </div>
                    <div className="card-body">
                      <div className="d-md-flex">
                        <div >
                          <img
                            src={ "../assets/images/schoollink.png"}
                          />
                        </div>
                        <div className="speaker-disc-outer">
                          <h3 className="speaker-name">
                            <div className="mb-2">
                              {eventDetails?.location?.platform}
                            </div>
                            <a href={eventDetails?.location?.link} target="_blank" className="normal">
                              Link:{eventDetails.location?.link}
                            </a>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {(eventDetails?.eventType==="In-Person" || eventDetails?.eventType==="Hybrid")&&eventDetails?.offLocDtls?._id && (
                  <div className="card preview-card mb-4">
                    <div className="card-title">
                      <h4>Location</h4>
                      {user?.data?.loginType==="Admin"&&  <Link to={`/addevent/${id}`}>
                        <img src="../assets/images/previewedit.svg" />
                      </Link>}
                    </div>
                    <div className="card-body">
                      <div className="d-md-flex">
                        <div className="speaker-img-outer">
                          <img
                            src={
                              eventDetails?.offLocDtls?.locImage
                                ? eventDetails?.offLocDtls?.locImage
                                : "../assets/images/noimage.svg"
                            }
                          />
                        </div>
                        <div className="speaker-disc-outer">
                          <h3 className="speaker-name">
                            <div className="mb-2">
                              {eventDetails?.offLocDtls?.locName}
                            </div>
                            <div className="normal">
                              {newAddre.replace('USA', '')}
                              
                            </div>
                            <div className="normal">
                              {eventDetails&&eventDetails?.offLocDtls?.city+", "+eventDetails?.offLocDtls?.state+" "+eventDetails?.offLocDtls?.zip}
                            </div>
                          </h3>
                          <p className="speaker-details">
                            {/* <span> {eventDetails?.offLocDtls?.locName}</span> */}
                            {eventDetails?.offLocDtls?.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-sm-4" style={{display:user?.data?.loginType==="Admin" ? null : "none"}}>
                <div className="card preview-card mb-4">
                  <div className="card-title">
                    <h4>Publish Settings</h4>
                    {user?.data?.loginType==="Admin"&&  <Link to={`/addevent/${id}`}>
                      <img src="../assets/images/previewedit.svg" />
                    </Link>}
                  </div>
                  <div className="card-body py-0">
                    <ul className="list-elements">
                      <li>
                        <p className="list-label">Status</p>
                        <p className="list-value">
                          <span className="badge badge-primary">
                            {/* {eventDetails?.status === "Draft" ? "Unpublished" : eventDetails?.status === "Published" && moment(new Date(eventDetails?.endDate)).isBefore() ? "Completed" : eventDetails?.status } */}
                            {eventDetails?.status === "Draft" ? "Unpublished" :  eventDetails?.status }
                          </span>
                        </p>
                      </li>
                      {eventDetails?.featuredFrom && <li>
                        <p className="list-label">Featured From</p>
                        <p className="list-value">
                          {eventDetails?.featuredFrom ? formatDate(eventDetails?.featuredFrom) : "-"}
                        </p>
                      </li>}
                      {eventDetails?.featuredTo && <li>
                        <p className="list-label">Feaured To</p>
                        <p className="list-value">
                          {eventDetails?.featuredTo ? formatDate(eventDetails?.featuredTo) : "-"}
                        </p>
                      </li>}
                    </ul>
                  </div>
                </div>
                <div className="card preview-card mb-4">
                  <div className="card-title">
                    <h4>Type & Category</h4>
                    {user?.data?.loginType==="Admin"&&  <Link to={`/addevent/${id}`}>
                      <img src="../assets/images/previewedit.svg" />
                    </Link>}
                  </div>
                  <div className="card-body py-0">
                    <ul className="list-elements">
                      <li>
                        <p className="list-label">Type</p>
                        <p className="list-value">{eventDetails?.eventType}</p>
                      </li>
                      <li>
                        <p className="list-label">Category</p>
                        <p className="list-value">{eventDetails?.category}</p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="card preview-card mb-4">
                  <div className="card-title">
                    <h4>Registration</h4>
                    {user?.data?.loginType==="Admin"&& <Link to={`/addevent/${id}`}>
                      <img src="../assets/images/previewedit.svg" />
                    </Link>}
                  </div>
                  <div className="card-body py-0">
                    <ul className="list-elements">
                      <li>
                        <p className="list-label">Maximum capacity</p>
                        <p className="list-value">{eventDetails?.seats}</p>
                      </li>
                      <li>
                        <p className="list-label">Member price</p>
                        <p className="list-value">
                          ${formatCurrency(eventDetails?.memberPrice)}
                        </p>
                      </li>
                      <li>
                        <p className="list-label">Non-Member price</p>
                        <p className="list-value">
                          ${formatCurrency(eventDetails?.nonMemberPrice)}
                        </p>
                      </li>
                      {eventDetails?.bulkDiscount&&<li>
                        <p className="list-label">Bulk discount Percentage</p>
                        <p className="list-value">
                          {eventDetails?.bulkDiscount}%
                        </p>
                      </li>}
                      {eventDetails?.regStartDate&&<li>
                        <p className="list-label">Registration Start Date</p>
                        <p className="list-value">
                          {formatDate(eventDetails?.regStartDate)}
                          <span>
                          {" "+moment(eventDetails?.regStartDate).format(
                            "h.mm A"
                          )}
                         
                        </span>
                        </p>

                      </li>}
                      <li>
                        <p className="list-label">Last Date to register</p>
                        <p className="list-value">
                          {formatDate(eventDetails?.lastDateToRegister)}
                          <span>
                          {" "+moment(eventDetails?.lastDateToRegister).format(
                            "h.mm A"
                          )}
                         
                        </span>
                        </p>

                      </li>
                      {eventDetails?.lastDateToCancel&&<li>
                        <p className="list-label">Last Date to cancel</p>
                        <p className="list-value">
                          {formatDate(eventDetails?.lastDateToCancel)}
                        </p>
                      </li>}
                    </ul>
                  </div>
                </div>
                {eventDetails?.coupenCodes.length > 0 && <div className="card preview-card mb-4">
                  <div className="card-title">
                    <h4>Coupon Codes</h4>
                    {user?.data?.loginType==="Admin"&&  <Link to={`/addevent/${id}`}>
                      <img src="../assets/images/previewedit.svg" />
                    </Link>}
                  </div>
                  <div className="card-body py-0">
                    <ul className="list-elements">
                      {eventDetails?.coupenCodes.map((item) => (
                        <li>
                          <p className="list-label">{item.coupenTitle}</p>
                          <p className="list-value">{item.perValue + "%"}</p>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>}
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <EventUpdates eventDetails={eventDetails} eventorder schoolId={id}/>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Order eventorder schoolId={id} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Ticket eventorder schoolId={id} />
        </TabPanel>
      </div>

      <OrderPopup
        eventdetails={eventDetails}
        registeropen={registeropen}
        setRegisterOpen={setRegisterOpen}
      />
    </div>
  );
}
