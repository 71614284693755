import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Switch,
  Button,
  Menu,
  Pagination,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Box,
} from "@mui/material";
import { useQuery, useMutation } from "@apollo/client";
import { LIST_SCHOOL } from "../../Graphql/queries";
import { useState } from "react";
import { toggleSnackbar } from "../../RactiveVariables/index";
import { ConfirmDialog } from "../Common";
import { useHistory } from "react-router-dom";
import { DELETE_SCHOOL, FEATURED_SCHOOL } from "../../Graphql/mutations";
import {
  gender,
  lowestgrade,
  highestgrade,
  sclUserGrp,
  sclUserType,
  sclUserStatus,
  regionOptions,
  subRegionOptions,
} from "./SchoolOptions";
import { Link } from "react-router-dom"

export default function School(props) {
  // const [openAdd, setOpenAdd] = useState(false);
  const [searchVariable, setSearchVariable] = useState(null);
  // const [sortVariable, setSortVariable] = useState("-1");
  // const [sortFieldVariable, setSortFieldVariable] = useState("_id");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [deleteId, setDeleteId] = useState(null);
  const [schoolGender, setSchoolGender] = useState("");
  const [schoolRegion, setSchoolRegion] = useState("");
  const [schoolSubRegion, setSchoolSubRegion] = useState("");
  const [schoolStatus, setSchoolStatus] = useState("");
  const [schoolGrade, setSchoolGrade] = useState("");

  const [deleteSchool] = useMutation(DELETE_SCHOOL);
  const [featuredUser] = useMutation(FEATURED_SCHOOL);
  const history = useHistory();
  const getSchoolVariables = {
    skip: (page - 1) * rowsPerPage,
    limit: rowsPerPage,
    gender: schoolGender,
    grades: schoolGrade,
    // src: props?.location?.state?.detail? "src": "FE",
    src: null,
    search: searchVariable,
    region: schoolRegion,
    subRegion: schoolSubRegion,
    status: schoolStatus,
    // sortOrder: sortVariable,
    // sortField: sortFieldVariable,
  };
  const {
    data: schoolList,
    fetchMore,
    loading,
  } = useQuery(LIST_SCHOOL, {
    fetchPolicy: "network-only",
    variables: getSchoolVariables,
  });
  const [selectedItem, setSelectedItem] = useState(null);

  const handleChangePage = (e, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handlePagination = () => {
    fetchMore({
      variables: getSchoolVariables,
    });
  };
  function handleDelete() {
    deleteSchool({ variables: { schoolIds: [deleteId] } })
      .then((res) => {
        toggleSnackbar({
          status: true,
          message: "School deleted successfully",
          variant: "success",
        });
        handlePagination();
      })
      .catch((error) => {
        const message = error && error.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      });
  }
  function closeConfirm() {
    setDeleteId(null);
  }
  function handleConfirm() {
    handleDelete();
    closeConfirm();
    handlePagination();
  }
  function handleFeatured(item) {
    featuredUser({
      variables: {
        id: item._id,
        status: item.schFeatredOnHome === true ? true : false,
      },
    })
      .then((res) => {
        const message = res?.data?.changeFeatured?.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "success",
        });
        handlePagination();
      })
      .catch((error) => {
        const message = error && error.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      });
  }
  function handlePageInputChange(event) {
    setRowsPerPage(Number(event.target.value));
    setPage(1);
  }
  function handleSort(field) {
    // if (sortFieldVariable === field) {
    //   if (sortVariable === "-1") setSortVariable("1");
    //   else setSortVariable("-1");
    // } else {
    //   setSortFieldVariable(field);
    //   setSortVariable("1");
    // }
  }
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, item) => {
    setSelectedItem(item);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="content-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
             <ConfirmDialog
        open={deleteId}
        title={"Delete"}
        body={"Are you sure you want to delete this school ? "}
        closeText={"Cancel"}
        confirmText={"Delete"}
        closeConfirm={closeConfirm}
        handleConfirm={handleConfirm}
      />
      <div className="card border-0">
        <div className="card-body p-0">
          <div className="filter-section d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <img
                className="search-icon"
                src="../assets/images/search.svg"
                alt="search icon"
              />
              <input
                className="searchbox"
                placeholder="Search for a school..."
                type="text"
                value={searchVariable}
                onChange={(e) => {setPage(1);setSearchVariable(e.target.value)}}
              />
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <Box className="ms-0" sx={{ minWidth: 127 }}>
                <FormControl fullWidth>
                  <InputLabel className="filter-label" id="type">
                    Region
                  </InputLabel>
                  <Select
                    className="filter-select"
                    labelId="type"
                    id="demo-type"
                    value={schoolRegion}
                    label="type"
                    onChange={(e) => {
                      setPage(1);
                      setSchoolSubRegion("");
                      setSchoolRegion(e.target.value);
                    }}
                  >
                    <MenuItem value="">{"Region"}</MenuItem>
                    {regionOptions.map((item) => (
                      <MenuItem value={item}>{item}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box className="ms-2" sx={{ minWidth: 127 }}>
                <FormControl fullWidth>
                  <InputLabel className="filter-label" id="type">
                    Sub Region
                  </InputLabel>
                  <Select
                    className="filter-select"
                    labelId="type"
                    id="demo-type"
                    value={schoolSubRegion}
                    label="type"
                    onChange={(e) => {setPage(1);setSchoolSubRegion(e.target.value)}}
                  >
                    <MenuItem value="">{"Sub Region"}</MenuItem>
                    {subRegionOptions[schoolRegion] &&
                      subRegionOptions[schoolRegion].map((item) => (
                        <MenuItem value={item}>{item}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
              <Box className="ms-2" sx={{ minWidth: 127 }}>
                <FormControl fullWidth>
                  <InputLabel className="filter-label" id="type">
                    Status
                  </InputLabel>
                  <Select
                    className="filter-select"
                    labelId="type"
                    id="demo-type"
                    value={schoolStatus}
                    label="type"
                    onChange={(e) => {setPage(1);setSchoolStatus(e.target.value)}}
                  >
                    <MenuItem value="">{"Status"}</MenuItem>
                    <MenuItem value="Members">{"Member"}</MenuItem>
                    <MenuItem value="Non-Member">{"Non-Member"}</MenuItem>
                    {/* {sclUserStatus.map((item) => (
                        <MenuItem value={item}>{item}</MenuItem>
                      ))} */}
                  </Select>
                </FormControl>
              </Box>
              <Box className="ms-2" sx={{ minWidth: 127 }}>
                <FormControl fullWidth>
                  <InputLabel className="filter-label" id="type">
                    Grades
                  </InputLabel>
                  <Select
                    className="filter-select"
                    labelId="type"
                    id="demo-type"
                    value={schoolGrade}
                    label="type"
                    onChange={(e) => {setPage(1);setSchoolGrade(e.target.value)}}
                  >
                    <MenuItem value="">{"Grade"}</MenuItem>
                    {lowestgrade.map((item) => (
                      <MenuItem value={item}>{item}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box className="ms-2 me-2" sx={{ minWidth: 127 }}>
                <FormControl fullWidth>
                  <InputLabel className="filter-label" id="type">
                    Gender
                  </InputLabel>
                  <Select
                    className="filter-select"
                    labelId="type"
                    id="demo-type"
                    value={schoolGender}
                    label="type"
                    onChange={(e) => {setPage(1);setSchoolGender(e.target.value)}}
                  >
                    <MenuItem value="">{"Gender"}</MenuItem>
                    {gender.map((item) => (
                      <MenuItem value={item}>{item}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Link
                to="/addschool/$"
                role="button"
                type="button"
                className="btn add-btn"
              >
                + Add School
              </Link>
              {/* <Button
                  role="button"
                  type="button"
                  className="btn border filter-btn ml-2"
                  onClick={() => {
                    if (sortVariable === "1") setSortVariable("-1");
                    else setSortVariable("1");
                  }}
                >
                  <img
                    className="img-fluid filter-icon"
                    src="../assets/images/filter.svg"
                    alt="filter button icon"
                  />
                </Button> */}
            </div>
          </div>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>School Name</TableCell>
                  <TableCell>Region</TableCell>
                  <TableCell>Sub-Region</TableCell>
                  <TableCell>Gender</TableCell>
                  <TableCell>Grades</TableCell>
                  <TableCell>Enrollment</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Featured</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <p> Loading Schools...</p>
                      </div>
                    </TableCell>
                  </TableRow>
                ) : schoolList?.listAllSchool?.data &&
                  schoolList?.listAllSchool?.data.length > 0 ? (
                  schoolList?.listAllSchool?.data.map((item) => {
                    console.log("test",item);
                    return(
                    <TableRow key={item.schName}>
                      <TableCell>
                          <Link className="preview-link" to={`/schoolpreview/${item?._id}`}>
                          {item?.schNameThe&&item?.schNameThe +
                          item?.schNameThe&&item?.schNameThe} 
                            
                            </Link>
                      </TableCell>
                      <TableCell>{item.schRegion}</TableCell>
                      <TableCell>{item.schSubRegion}</TableCell>
                      <TableCell>{item.schGender}</TableCell>
                      <TableCell>{item.schLowestGrade}</TableCell>
                      <TableCell>{item.schEnrollment}</TableCell>
                      <TableCell>
                        <span className="badge badge-success w-100">
                          {item?.schStatus}
                        </span>
                      </TableCell>
                      <TableCell>
                        <Switch
                          checked={item.schFeatredOnHome}
                          onClick={() => handleFeatured(item)}
                        />
                      </TableCell>
                      <TableCell>
                        <Button
                          id="basic-button"
                          aria-controls="basic-menu"
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          onClick={(e) => handleClick(e, item)}
                          className="p-0 action-btn"
                        >
                          <img
                            src="../assets/images/dots.svg"
                            alt="edit button image"
                          />
                        </Button>
                      </TableCell>
                    </TableRow>
                  )})
                ) : searchVariable ? (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <p>No Schools Found</p>
                      </div>
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <p>No Schools Added Yet!</p>
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            {!loading && (
              <div className="pagination-outer">
                <div className="count-selector">
                  <Select onChange={handlePageInputChange} value={rowsPerPage}>
                    <MenuItem value={10}>{10}</MenuItem>
                    <MenuItem value={25}>{25}</MenuItem>
                    <MenuItem value={50}>{50}</MenuItem>
                    <MenuItem value={100}>{100}</MenuItem>
                  </Select>
                  <img
                    className="count-arrow"
                    src="../../assets/images/arrowupdown.svg"
                  />
                </div>

                <Pagination
                  count={Math.ceil(
                    schoolList?.listAllSchool?.count / rowsPerPage
                  )}
                  page={page}
                  shape="rounded"
                  onChange={handleChangePage}
                />
              </div>
            )}
          </TableContainer>
        </div>
      </div>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "dropdown-button",
        }}
      >
        <MenuItem onClick={handleClose}>
          <Link
            className="actionbtn-menu"
            to={`/addschool/${selectedItem?._id}`}
          >
            Edit
          </Link>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <a
            onClick={() => setDeleteId(selectedItem._id)}
            className="actionbtn-menu"
            href="javascript:;"
          >
            Delete
          </a>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Link
            className="actionbtn-menu"
            to={`/schoolpreview/${selectedItem?._id}`}
          >
            Preview
          </Link>
        </MenuItem>
      </Menu>
          </div>
        </div>
      </div>
     
    </div>
  );
}
