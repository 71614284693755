import React from "react";

export default function fournotfour() {
    return (
        <div className="fournotfour">
            <div className="text-center">
            <h1>404</h1>
            <p>Page not found !</p>
            </div>
        </div>
    )
}