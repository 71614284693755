import { gql } from "@apollo/client";

export const LOGIN_ADMIN = gql`
  mutation login($email: String!, $password: String!, $rememberMe: Boolean) {
    login(email: $email, password: $password, rememberMe: $rememberMe) {
      userNew {
        _id
        firstName
        lastName
        email
        role
        userType
        profileImage
        status
        loginType
        userGpDtls {
          moduleAcs {
            title
            status
          }
        }
      }
      token
    }
  }
`;
export const LOGIN_SCHOOL = gql`
  mutation loginSch($email: String!, $password: String!, $rememberMe: Boolean) {
    loginSch(email: $email, password: $password, rememberMe: $rememberMe) {
      userNew {
        _id
        firstName
        lastName
        email
        role
        bio
        position
        title
        phone
        pronoun
        massTopicsIds
        userType
        positionOther
        profileImage
        status
        schoolId
        regEventId
        orderCount
        schoolDetails {
          schName
          schNameThe
          schStatus
          schAddress
          schCity
          schState
          schZip
          schRegion
          schSubRegion
          schoolId
        }
        moduleAcs {
          title
          status
        }
        loginType
      }
      token
    }
  }
`;
export const FORGET_PASS = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email) {
      status
      message
    }
  }
`;
export const FORGET_PASSSCHOOL = gql`
  mutation forgotSchPassword($email: String!) {
    forgotSchPassword(email: $email) {
      status
      message
    }
  }
`;
export const ADD_USER = gql`
  mutation addUser(
    $firstName: String
    $lastName: String
    $userGroupId: String!
    $email: String
    $role: String
    $userType: String
    $userGroup: String
    $address: String
    $city: String
    $phone: String
    $profileImage: String
    $status: Boolean
    $state: String
    $zip: String
    $country: String
    $password: String
    $bio: String
    $title: String
    $socialMedia: socialMediaIn
  ) {
    addUser(
      firstName: $firstName
      lastName: $lastName
      userGroupId: $userGroupId
      email: $email
      role: $role
      userType: $userType
      userGroup: $userGroup
      address: $address
      city: $city
      phone: $phone
      profileImage: $profileImage
      status: $status
      state: $state
      zip: $zip
      country: $country
      password: $password
      bio: $bio
      title: $title
      socialMedia: $socialMedia
    ) {
      firstName
      email
    }
  }
`;
export const EDIT_USERS = gql`
  mutation editUser(
    $_id: String!
    $firstName: String
    $lastName: String
    $userGroupId: String!
    $email: String
    $role: String
    $userType: String
    $userGroup: String
    $address: String
    $city: String
    $phone: String
    $profileImage: String
    $status: Boolean
    $state: String
    $zip: String
    $country: String
    $bio: String
    $title: String
    $socialMedia: socialMediaIn
  ) {
    editProfile(
      _id: $_id
      firstName: $firstName
      lastName: $lastName
      userGroupId: $userGroupId
      email: $email
      role: $role
      userType: $userType
      userGroup: $userGroup
      address: $address
      city: $city
      phone: $phone
      profileImage: $profileImage
      status: $status
      state: $state
      zip: $zip
      country: $country
      bio: $bio
      title: $title
      socialMedia: $socialMedia
    ) {
      firstName
      email
    }
  }
`;
export const DELETE_USER = gql`
  mutation deleteUser($userIds: [String]) {
    deleteUser(userIds: $userIds) {
      status
      message
    }
  }
`;
export const ADD_USERGROUP = gql`
  mutation addUserGroup($groupName: String, $moduleAcs: [moduleAcsIn]) {
    addUserGp(groupName: $groupName, moduleAcs: $moduleAcs) {
      _id
    }
  }
`;
export const EDIT_USERGROUP = gql`
  mutation editUserGp(
    $_id: String!
    $groupName: String
    $moduleAcs: [moduleAcsIn]
  ) {
    editUserGp(_id: $_id, groupName: $groupName, moduleAcs: $moduleAcs) {
      _id
    }
  }
`;
export const RESET_PASSWORD = gql`
  mutation resetPassword($resetLink: String!, $newPassword: String!) {
    resetPassword(resetLink: $resetLink, newPassword: $newPassword) {
      status
      message
    }
  }
`;
export const RESET_SCH_PASSWORD = gql`
  mutation resetSchPassword($resetLink: String!, $newPassword: String!) {
    resetSchPassword(resetLink: $resetLink, newPassword: $newPassword) {
      status
      message
    }
  }
`;
export const ADD_SPEAKER = gql`
  mutation addSpeaker(
    $firstName: String
    $lastName: String
    $title: String
    $profileImage: String
    $organization: String
    $bio: String
    $pronoun: String
  ) {
    addSpeakers(
      firstName: $firstName
      lastName: $lastName
      title: $title
      profileImage: $profileImage
      organization: $organization
      bio: $bio
      pronoun: $pronoun
    ) {
      firstName
      lastName
      profileImage
      _id
    }
  }
`;
export const EDIT_SPEAKER = gql`
  mutation editSpeaker(
    $_id: String!
    $firstName: String
    $title: String
    $lastName: String
    $profileImage: String
    $organization: String
    $bio: String
    $pronoun: String
  ) {
    editSpeakers(
      _id: $_id
      firstName: $firstName
      title: $title
      lastName: $lastName
      profileImage: $profileImage
      organization: $organization
      bio: $bio
      pronoun: $pronoun
    ) {
      _id
    }
  }
`;
export const DELETE_SPEAKER = gql`
  mutation deleteSpeaker($userIds: String!) {
    deleteSpeakers(userIds: $userIds) {
      status
      message
    }
  }
`;
export const DEACTIVATE_USER = gql`
  mutation deactivateUser($userId: String!, $status: Boolean!) {
    deactivateUser(userId: $userId, status: $status) {
      status
      message
    }
  }
`;
export const CREATE_EVENT = gql`
  mutation createEvent($data: createEventInput!) {
    createEvent(data: $data) {
      title
      category
    }
  }
`;
export const DELETE_EVENT = gql`
  mutation deleteEvent($eventIds: [String]) {
    deleteEvents(eventIds: $eventIds) {
      status
      message
    }
  }
`;
export const ADD_LOCATION = gql`
  mutation addLocation(
    $locName: String
    $streetAdrs: String
    $description: String
    $latitude: String
    $longitude: String
    $locImage: String
    $city: String
    $state: String
    $zip: String
  ) {
    addOffLocations(
      locName: $locName
      streetAdrs: $streetAdrs
      description: $description
      latitude: $latitude
      longitude: $longitude
      locImage: $locImage
      city: $city
      state: $state
      zip: $zip
    ) {
      _id
      locName
      streetAdrs
    }
  }
`;
export const EDIT_LOCATION = gql`
  mutation editLocation(
    $_id: String!
    $locName: String
    $streetAdrs: String
    $description: String
    $latitude: String
    $longitude: String
    $locImage: String
    $city: String
    $state: String
    $zip: String
  ) {
    editOffLocations(
      _id: $_id
      locName: $locName
      streetAdrs: $streetAdrs
      description: $description
      latitude: $latitude
      longitude: $longitude
      locImage: $locImage
      city: $city
      state: $state
      zip: $zip
    ) {
      locName
      streetAdrs
    }
  }
`;
export const UPDATE_EVENT = gql`
  mutation updateEvent($_id: ID!, $data: updateEventInput!) {
    updateEvent(_id: $_id, data: $data) {
      title
      category
    }
  }
`;
export const DELETE_LOCATION = gql`
  mutation deleteLocation($userIds: [String]) {
    deleteOffLocations(userIds: $userIds) {
      status
      message
    }
  }
`;
export const CREATE_SCHOOL = gql`
  mutation createSchool($data: createSchoolInput!) {
    createSchool(data: $data) {
      _id
      schName
      schNameThe
      schStatus
    }
  }
`;
export const UPDATE_SCHOOL = gql`
  mutation updateSchool($_id: ID!, $data: updateSchoolInput!) {
    updateSchool(_id: $_id, data: $data) {
      schName
      schNameThe
      schStatus
    }
  }
`;
export const ADD_SCHOOL_USER = gql`
  mutation addSchUser($data: addSchUserInput!) {
    addSchUser(data: $data) {
      _id
    }
  }
`;
export const EDIT_SCHOOL_USER = gql`
  mutation editSchProfile($_id: ID!, $data: editSchUserInput!) {
    editSchProfile(_id: $_id, data: $data) {
      _id
    }
  }
`;
export const DELETE_SCHOOL = gql`
  mutation deleteSchool($schoolIds: [String]) {
    deleteSchool(schoolIds: $schoolIds) {
      status
      message
    }
  }
`;
export const DELETE_SCHOOL_USER = gql`
  mutation deleteSchUser($userIds: [String]) {
    deleteSchUser(userIds: $userIds) {
      status
      message
    }
  }
`;
export const DEACTIVATE_SCHOOL_USER = gql`
  mutation deactivateSchUser($userId: String!, $status: Boolean!) {
    deactivateSchUser(userId: $userId, status: $status) {
      status
      message
    }
  }
`;
export const FEATURED_SCHOOL = gql`
  mutation changeFeatured($id: String!, $status: Boolean!) {
    changeFeatured(id: $id, status: $status) {
      status
      message
    }
  }
`;
export const DELETE_CAREER = gql`
  mutation deleteCareer($careerIds: [String]) {
    deleteCareer(careerIds: $careerIds) {
      status
      message
    }
  }
`;
export const CREATE_CAREER = gql`
  mutation createCareer($data: createCareerInput!) {
    createCareer(data: $data) {
      _id
      title
    }
  }
`;
export const UPDATE_CAREER = gql`
  mutation updateCareer($_id: ID!, $data: updateCareerInput!) {
    updateCareer(_id: $_id, data: $data) {
      _id
      title
    }
  }
`;
export const CREATE_ORDER = gql`
  mutation createOrders($data: createOrdersInput!) {
    createOrders(data: $data) {
      _id
      schoolId
      eventId
    }
  }
`;
export const DELETE_ORDER = gql`
  mutation deleteOrders($eventIds: [String]) {
    deleteOrders(eventIds: $eventIds) {
      status
      message
    }
  }
`;
export const UPDATE_ORDER = gql`
  mutation updateOrders($_id: ID!, $data: updateOrdersInput!) {
    updateOrders(_id: $_id, data: $data) {
      _id
      status
      paymentMethod
    }
  }
`;
export const CREATE_PAYMENT = gql`
  mutation createPayment($data: createPaymentInput!) {
    createPayment(data: $data) {
      _id
      paymentStatus
    }
  }
`;
export const USER_ACTIVATION = gql`
  mutation schUserActivation($resetLink: String!) {
    schUserActivation(resetLink: $resetLink) {
      regEventId
    }
  }
`;
export const UPDATE_ORDER_TICKET = gql`
  mutation updateOrderTicket($_id: ID!, $data: updateOrderTicketInput!) {
    updateOrderTicket(_id: $_id, data: $data) {
      _id
      orderId
    }
  }
`;

export const ADD_ADDRESS = gql`
  mutation addAddress(
    $schAddress1: String
    $schAddress2: String
    $schCity: String
    $schState: String
    $schZip: String
    $defaultAddress: Boolean
    $schoolId: String
    $schoolUserId: String
  ) {
    addAddress(
      schAddress1: $schAddress1
      schAddress2: $schAddress2
      schCity: $schCity
      schState: $schState
      schZip: $schZip
      defaultAddress: $defaultAddress
      schoolId: $schoolId
      schoolUserId: $schoolUserId
    ) {
      schAddress1
      schAddress2
      schCity
      schState
      schZip
      defaultAddress
      schoolId
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changeSchPassword($oldPassword: String!, $newPassword: String!) {
    changeSchPassword(oldPassword: $oldPassword, newPassword: $newPassword) {
      status
      message
    }
  }
`;

export const PURCHASE_ORDER = gql`
  mutation purchaseOrder(
    $orderId: String!
    $trnId: String!
    $grandTotal: Float!
    $coupenCode: String
    $discountAmt: Float
    $cardNumber: String!
    $cardHoldName: String!
    $addressId: String!
    $bulkDiscount: Float
  ) {
    purchaseOrder(
      orderId: $orderId
      trnId: $trnId
      grandTotal: $grandTotal
      coupenCode: $coupenCode
      discountAmt: $discountAmt
      cardNumber: $cardNumber
      cardHoldName: $cardHoldName
      addressId: $addressId
      bulkDiscount: $bulkDiscount
    ) {
      status
      message
    }
  }
`;

export const REFUND_TICKET = gql`
  mutation refundTicket($cancelType: String!, $data: [refundData!]) {
    refundTicket(cancelType: $cancelType, data: $data) {
      status
      message
    }
  }
`;
export const CREATE_MAIL_GROUP = gql`
  mutation createMailGroup($dataArr: createMailGroupInput!) {
    createMailGroup(dataArr: $dataArr) {
      _id
      groupName
      groupType
      uploadedFile
      createdByDtls {
        _id
        firstName
        lastName
        email
        role
      }
      usrMailList {
        _id
        mailGroupId
        firstName
        lastName
        position
        email
        status
        lastMailSentDate
        createdByDtls {
          _id
          firstName
          lastName
          email
          role
        }
      }
    }
  }
`;
export const DELETE_MAIL_GROUP = gql`
  mutation deleteMailGroups($eventIds: [String]) {
    deleteMailGroups(eventIds: $eventIds) {
      status
      message
    }
  }
`;

export const DELETE_MAIL_LIST = gql`
  mutation deleteMailLists($mailListIds: [String]) {
    deleteMailLists(mailListIds: $mailListIds) {
      status
      message
    }
  }
`;

export const UPDATE_MAIL_GROUP = gql`
  mutation updateMailGroup($_id: ID!, $dataArg: updateMailGroupInput!) {
    updateMailGroup(_id: $_id, dataArg: $dataArg) {
      _id
      groupName
      membership
      membershipType
      region
      position
      groupType
      status
      description
      schoolId
      createdByDtls {
        _id
        title
        firstName
        lastName
        email
        role
        userType
      }
      uploadedFile
      usrMailList {
        _id
        mailGroupId
        firstName
        lastName
        email
        status
        lastMailSentDate
        createdByDtls {
          firstName
          lastName
          email
          role
        }
      }
      mailCount
    }
  }
`;

export const CREATE_EVENT_UPDATES = gql`
  mutation createEventUpdates($data: createEventUpdatesInput!) {
    createEventUpdates(data: $data) {
      _id
      createdDate
      content
      subject
      subscribers
      createdByDtls {
        _id
        firstName
        lastName
      }
    }
  }
`;

export const CREATE_ENOTICE = gql`
  mutation createENotice($data: createENoticeInput!) {
    createENotice(data: $data) {
      _id
      eventId
      toList
      toGpList
      ccList
      fromLabel
      from
      replyTo
      eNoticeType
      toGpListDtls {
        _id
        groupName
      }
      subject
      content
      massTopicsIds
      status
      emailCnt
      eventDtls {
        _id
        title
        eventType
        startDate
        startTime
        endDate
        endTime
        targetAudience
        status
        description
        shortSummary
        emailContent
        featuredFrom
        featuredTo
        offLocationId
        offLocDtls {
          locName
          streetAdrs
        }
        spkrDtls {
          _id
          firstName
          title
          lastName
          profileImage
          status
          bio
        }
      }
    }
  }
`;
export const DELETE_ENOTICE = gql`
  mutation deleteENotice($eventIds: [String]) {
    deleteENotice(eventIds: $eventIds) {
      status
      message
    }
  }
`;
export const UPDATE_ENOTICE = gql`
  mutation updateENotice($_id: ID!, $data: updateENoticeInput!) {
    updateENotice(_id: $_id, data: $data) {
      _id
      eventId
      toList
      toGpList
      ccList
      fromLabel
      from
      massTopicsIds
      replyTo
      eNoticeType
      subject
      sentDate
      scheduledDate
      toGpListDtls {
        _id
        groupName
      }
      content
      status
      emailCnt
      eventDtls {
        _id
        title
        eventType
        startDate
        startTime
        endDate
        endTime
        targetAudience
        status
        description
        shortSummary
        emailContent
        featuredFrom
        featuredTo
        offLocationId
        offLocDtls {
          locName
          streetAdrs
        }
        spkrDtls {
          _id
          firstName
          title
          lastName
          profileImage
          status
          bio
        }
      }
    }
  }
`;
export const DELETE_TICKET = gql`
  mutation DeleteOrderTicket($userType: String, $eventIds: [String]) {
    deleteOrderTicket(userType: $userType, eventIds: $eventIds) {
      message
      status
    }
  }
`;

export const DELETE_SCH_USER = gql`
  mutation deleteSchUser($userIds: [String]) {
    deleteSchUser(userIds: $userIds) {
      status
      message
    }
  }
`;

export const UNSUBSCRIBE = gql`
  mutation unsubscribe($mailId: String) {
    unsubscribe(mailId: $mailId) {
      status
      message
    }
  }
`;

export const CREATE_FORUM = gql`
  mutation createForum($dataArr: createForumInput!) {
    createForum(dataArr: $dataArr) {
      commentAccess
      postAccess
      _id
    }
  }
`;
export const UPDATE_FORUM = gql`
  mutation updateForum($dataArg: updateForumInput!, $_id: ID!) {
    updateForum(dataArg: $dataArg, _id: $_id) {
      commentAccess
      postAccess
      _id
    }
  }
`;
export const DELETE_FORUM = gql`
  mutation deleteForum($forumIds: [String]) {
    deleteForum(forumIds: $forumIds) {
      status
      message
    }
  }
`;
export const CREATE_FORUM_USER = gql`
  mutation createForumUsers($data: [createForumUsersInput]) {
    createForumUsers(data: $data) {
      message
      status
    }
  }
`;
export const UPDATE_FORUM_USER = gql`
  mutation updateForumUsers($_id: ID!, $data: updateForumUsersInput!) {
    updateForumUsers(_id: $_id, data: $data) {
      message
      status
    }
  }
`;
export const DELETE_FORUM_USER = gql`
  mutation deleteForumUsers($forumUserIds: [String]) {
    deleteForumUsers(forumUserIds: $forumUserIds) {
      message
      status
    }
  }
`;
export const CREATE_FORUM_MESSAGE = gql`
  mutation createForumMsgs($data: createForumMsgsInput!) {
    createForumMsgs(data: $data) {
      _id
    }
  }
`;
export const SAVE_FOURM_SCHOOLS = gql`
  mutation createForumSchools(
    $data: [createForumSchoolsInput]
    $forumId: String
    $createList: [createForumUsersInput]
    $deleteList: [String]
  ) {
    createForumSchools(
      data: $data
      forumId: $forumId
      createList: $createList
      deleteList: $deleteList
    ) {
      status
    }
  }
`;
export const DELETE_FOURM_MESSAGES = gql`
  mutation deleteForumMsgs($ids: [String]) {
    deleteForumMsgs(ids: $ids) {
      status
    }
  }
`;
export const DELETE_DRAFT_ORDER = gql`
  mutation deleteDraftOrders($eventId: String) {
    deleteDraftOrders(eventId: $eventId) {
      status
      message
    }
  }
`;

export const ADD_TOPICS = gql`
  mutation AddTopics($data: topicInput!) {
    addTopics(data: $data) {
      message
      status
    }
  }
`;
export const DELETE_TOPICS = gql`
  mutation DeleteTopics($topicIds: [String!]) {
    deleteTopics(topicIds: $topicIds) {
      message
      status
    }
  }
`;
export const UPDATE_TOPICS = gql`
  mutation EditTopics($data: editInput!) {
    editTopics(data: $data) {
      message
      status
    }
  }
`;

export const USER_VERIFICATION = gql`
  mutation UserVerification($email: String!, $password: String!) {
    userVerification(email: $email, password: $password) {
      message
      status
    }
  }
`;
export const DELETE_MY_ACCOUNT = gql`
  mutation DeleteMyAccount($deleteAccLink: String, $email: String) {
    deleteMyAccount(deleteAccLink: $deleteAccLink, email: $email) {
      message
      status
      redirectUrl
    }
  }
`;
