import { InputLabel, TextField } from "@mui/material";

export default function Websites(props) {
  const { register, errors, defaultValue } = props;
  function getSocialMediaValue(name) {
    if (defaultValue?.socialMediaWeb) {
      let position = defaultValue?.socialMediaWeb.findIndex(
        (element) => element.title === name
      );
      if (position > -1) return defaultValue?.socialMediaWeb[position]?.url;
    }
  }
  return (
    <div className="card mt-4">
      <div className="card-title">
        <img src="../../assets/images/Website.svg" /> Website & Social Media
      </div>
      <div className="card-body">
        <div className="row">
        <div className="col-md-12 mb-3">
            <InputLabel className="form-label">Website</InputLabel>
            <TextField
              style={{ width: "100%" }}
              variant="outlined"
              placeholder="https://www.webiste.com/"
              {...register("websiteUrl")}
              // error={errors?.socialMediaWeb.FaceBook}
              defaultValue={defaultValue.websiteUrl}
            />
          </div>
          {/* <div className="col-md-12 mb-3">
            <InputLabel className="form-label">Instagram</InputLabel>
            <TextField
              style={{ width: "100%" }}
              variant="outlined"
              {...register("socialMediaWeb.Instagram")}
              placeholder="https://www.instagram.com/"
              // error={errors?.socialMediaWeb.Instagram}
              defaultValue={getSocialMediaValue("Instagram")}
            />
          </div> */}
          <div className="col-md-12 mb-3">
            <InputLabel className="form-label">Facebook</InputLabel>
            <TextField
              style={{ width: "100%" }}
              variant="outlined"
              placeholder="https://www.facebook.com/"
              {...register("socialMediaWeb.FaceBook")}
              // error={errors?.socialMediaWeb.FaceBook}
              defaultValue={getSocialMediaValue("FaceBook")}
            />
          </div>
          <div className="col-md-12 mb-3">
            <InputLabel className="form-label">Twitter</InputLabel>
            <TextField
              style={{ width: "100%" }}
              variant="outlined"
              {...register("socialMediaWeb.Twitter")}
              placeholder="https://www.twitter.com/"
              // error={errors?.socialMediaWeb.Instagram}
              defaultValue={getSocialMediaValue("Twitter")}
            />
          </div>
          <div className="col-md-12 mb-3">
            <InputLabel className="form-label">School Admissions URL</InputLabel>
            <TextField
              style={{ width: "100%" }}
              variant="outlined"
              placeholder="https://www.yourwebsite.com/"
              {...register("socialMediaWeb.SchoolAdmissionsURL")}
              // error={errors?.socialMediaWeb.SchoolAdmissionsURL}
              defaultValue={getSocialMediaValue("SchoolAdmissionsURL")}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
