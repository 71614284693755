const RouteNames = [
  {
    url: "/users",
    label: "Users",
  },
  {
    url: "/usergroup",
    label: "User Groups",
  },
  { url: "/events", label: "Events" },
  { url: "/location", label: "Locations" },
  { url: "/speaker", label: "Speakers" },
  { url: "/schools", label: "Schools" },
  { url: "/orderlisting", label: "Orders" },
  { url: "/newsletter", label: "Mailing Groups" },
  { url: "/enotice", label: "E-Notices" },
  { url: "/manage-topics", label: "Manage Topics" },
  { url: "/finance", label: "Finance" },
  { url: "/groups", label: "Groups" },
  { url: "/schoolusers", label: "School Users" },
];
export default RouteNames;
