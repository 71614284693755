import {
  Autocomplete,
  TextField,
  InputLabel,
  Chip,
  Avatar,
} from "@mui/material";

export default function SpeakersView(props) {
  function getSpeakerDtls(id) {
    if (id) {
      let position = props?.data.findIndex((element) => element.spkId === id);
      if (position > -1) {
        return props?.data[position];
      }
    }
  }
  function handleOnChange(value) {
    let array = [...props.selectedValue];
    array.push(value[0]?.spkId);
    props.setSelectedValue(array);
  }
  function handleDelete(chipToDelete) {
    props.setSelectedValue((chips) =>
      chips.filter((chip) => chip !== chipToDelete)
    );
  }
  function getSpeakerIdArray() {
    let array = [];
    props.selectedValue.forEach((element) => {
      array.push(element.spkId);
    });
    return array;
  }
  const filterOptions = (options, { inputValue }) => {
    let array = [];
    let id = getSpeakerIdArray();
    options.forEach((element) => {
      if (inputValue) {
        if (
          element.name.toLowerCase().includes(inputValue.toLowerCase()) &&
          !props.selectedValue.includes(element?.spkId)
        ) {
          array.push(element);
        }
      } else if (!props.selectedValue.includes(element?.spkId)) {
        array.push(element);
      }
    });
    return array;
  };
  console.log(props?.data, "data");
  return (
    <div className="card mb-4">
      <div className="card-title justify-content-between">
        <div className="d-flex">
          <img src="../../assets/images/Group.svg" /> Speaker
        </div>
        <a
          onClick={() => {
            props.setOpenAdd(true);
          }}
          className="addspeaker-btn"
          // href="javscript:;"
        >
          <img src="../../assets/images/adduser.svg" />
          Add Speaker
        </a>
      </div>
      <div className="card-body speaker-select-outer">
        <div className="row">
          <div className="col-md-12 mb-3">
            <InputLabel className="form-label">Select speaker</InputLabel>
            <Autocomplete
              multiple
              filterOptions={filterOptions}
              options={props?.data ? props?.data : []}
              value={[]}
              getOptionLabel={(options) => options.name}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  //   label="type"
                  placeholder="Type"
                />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option?.spkId}>
                  {option?.name}
                </li>
              )}
              onChange={(e, value) => {
                handleOnChange(value);
              }}
            />
          </div>
          <div className="col-sm-12">
            <div className="d-flex">
              <p className="speakers-head">Speakers:</p>
              <div className="selected-speakers">
                {props.selectedValue.map((item) => (
                  <Chip
                    avatar={
                      <Avatar src={getSpeakerDtls(item)?.profileImage}>
                        {getSpeakerDtls(item)?.name?.[0].toUpperCase()}
                      </Avatar>
                    }
                    label={getSpeakerDtls(item)?.name}
                    variant="outlined"
                    onDelete={() => handleDelete(item)}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
