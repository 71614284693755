import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Box,
  InputLabel,
  FormControl,
  Pagination,
  MenuItem,
  Select,
  IconButton,
  Button,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useQuery, useMutation } from "@apollo/client";
import { GET_ALL_TOPICS, LIST_ALLSCHOOL_USERS } from "../../Graphql/queries";
import {
  DEACTIVATE_USER,
  DELETE_SCH_USER,
  DELETE_TOPICS,
} from "../../Graphql/mutations";
import { useState } from "react";
import { toggleSnackbar } from "../../RactiveVariables/index";
import { ConfirmDialog } from "../Common";
// import EditUser from "./editUser.js";
import { position } from "../Common/Options";
import { AddTopics } from "./AddTopics";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#2b54a3",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export default function ManageTopics(props) {
  const [openAdd, setOpenAdd] = useState(false);
  const [searchVariable, setSearchVariable] = useState(null);
  const [sortVariable, setSortVariable] = useState("-1");
  const [sortFieldVariable, setSortFieldVariable] = useState("_id");
  const [checked, setChecked] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteTopic, { loading: deleteLoadinng }] = useMutation(DELETE_TOPICS);

  const getUserVariables = {
    skip: (page - 1) * rowsPerPage,
    limit: rowsPerPage,
    search: searchVariable,
    isArchived: checked,
  };

  const {
    data: topicList,
    fetchMore,
    loading,
  } = useQuery(GET_ALL_TOPICS, {
    fetchPolicy: "network-only",
    variables: getUserVariables,
  });

  
  const handleChangePage = (e, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  function editButtonClick(item) {
    setSelectedItem(item);
    setOpenAdd(true);
  }
  const handlePagination = () => {
    fetchMore({
      variables: getUserVariables,
    });
  };
  function handleDelete() {
    deleteTopic({ variables: { topicIds: [deleteId] } })
      .then((res) => {
        toggleSnackbar({
          status: true,
          message: "Topic deleted successfully",
          variant: "success",
        });
        handlePagination();
      })
      .catch((error) => {
        const message = error && error.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      });
  }
  function closeConfirm() {
    setDeleteId(null);
  }
  function handleConfirm() {
    handleDelete();
    closeConfirm();
    handlePagination();
  }

  function handlePageInputChange(event) {
    setRowsPerPage(Number(event.target.value));
    setPage(1);
  }
  function handleSort(field) {
    if (sortFieldVariable === field) {
      if (sortVariable === "-1") setSortVariable("1");
      else setSortVariable("-1");
    } else {
      setSortFieldVariable(field);
      setSortVariable("1");
    }
  }
  function sortLabels(a, b) {
    var nameA = a.toUpperCase();
    var nameB = b.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  }
  return (
    <div className="content-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <ConfirmDialog
              open={deleteId}
              title={"Delete"}
              body={"Are you sure you want to delete this topic ?"}
              closeText={"Cancel"}
              confirmText={"Delete"}
              closeConfirm={closeConfirm}
              handleConfirm={handleConfirm}
              content={"All the topics which are being deleted will be Archived and will no longer be available for the subscribers."}
            />
            <div className="card border-0">
              <div className="card-body p-0">
                {selectedItem && (
                  <AddTopics
                    setSelectedItem={setSelectedItem}
                    selectedItem={selectedItem}
                    open={openAdd}
                    setOpen={setOpenAdd}
                    handlePagination={handlePagination}
                  />
                )}

                <div className="filter-section d-flex justify-content-between">
                  <div className="d-flex align-items-center">
                    <img
                      className="search-icon"
                      src="../assets/images/search.svg"
                      alt="search icon"
                    />
                    <input
                      className="searchbox"
                      placeholder="Search by Topic"
                      type="text"
                      value={searchVariable}
                      onChange={(e) => {
                        setPage(1);
                        setSearchVariable(e.target.value);
                      }}
                    />
                  </div>
                  <div className="d-flex align-items-center justify-content-center">
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          sx={{ m: 1 }}
                          checked={checked}
                          onClick={() => setChecked(!checked)}
                        />
                      }
                      label="Archive"
                    />
                    <Box sx={{ minWidth: 127 }}>
                      <Button
                        role="button"
                        type="button"
                        style={{width:"110px"}}
                        className="btn add-btn ms-2"
                        onClick={() => {
                          setOpenAdd(true);
                          setSelectedItem({});
                        }}
                      >
                        + Add Topic
                      </Button>
                    </Box>
                  </div>
                </div>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell className="w-50">Topic</TableCell>
                        <TableCell className="w-50" >Description</TableCell>
                        <TableCell align="center" className="pe-5">Subscribers</TableCell>
                        <TableCell align="center">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {loading ? (
                        <TableRow>
                          <TableCell colSpan={12}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <p> Loading topics...</p>
                            </div>
                          </TableCell>
                        </TableRow>
                      ) : topicList?.listTopics?.data &&
                        topicList?.listTopics?.data.length > 0 ? (
                        topicList?.listTopics?.data.map((item) => (
                          <TableRow key={item._id}>
                            <TableCell>
                              <div className="d-flex">
                                <div>{item?.topic}</div>
                              </div>
                            </TableCell>
                            <TableCell>
                              {item?.description}
                            </TableCell>
                            <TableCell align="center"  className="pe-5">
                              {item?.subScribers?.subScriberCount}
                            </TableCell>
                            <TableCell>
                              <div className="d-flex justify-content-center">
                                <IconButton
                                  
                                  onClick={() => setDeleteId(item._id)}
                                >
                                  <img
                                    src="../assets/images/trash.svg"
                                    alt="delete button"
                                  />
                                </IconButton>
                                <IconButton
                                  onClick={() => editButtonClick(item)}
                                  className="p-2"
                                >
                                  <img
                                    src="../assets/images/pencil.svg"
                                    alt="edit button"
                                  />
                                </IconButton>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : searchVariable ? (
                        <TableRow>
                          <TableCell colSpan={12}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <p>No Topics Found</p>
                            </div>
                          </TableCell>
                        </TableRow>
                      ) : (
                        <TableRow>
                          <TableCell colSpan={12}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <p>No Topics Added Yet!</p>
                            </div>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                  {!loading && (
                    <div className="pagination-outer">
                      <div className="count-selector">
                        <Select
                          // variant="outlined"
                          onChange={handlePageInputChange}
                          value={rowsPerPage}
                        >
                          <MenuItem value={10}>{10}</MenuItem>
                          <MenuItem value={25}>{25}</MenuItem>
                          <MenuItem value={50}>{50}</MenuItem>
                          <MenuItem value={100}>{100}</MenuItem>
                        </Select>
                        <img
                          className="count-arrow"
                          src="../../assets/images/arrowupdown.svg"
                          alt="arrow"
                        />
                      </div>

                      <Pagination
                        count={Math.ceil(
                          topicList?.listTopics?.count / rowsPerPage
                        )}
                        page={page}
                        shape="rounded"
                        onChange={handleChangePage}
                      />
                    </div>
                  )}
                </TableContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
