import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Switch,
  Button,
  Avatar,
  Pagination,
  MenuItem,
  Select,
  Menu,
} from "@mui/material";
import AddUser from "./AddMember";
import { useQuery, useMutation, useReactiveVar } from "@apollo/client";
import { LIST_SCHOOL_USERS } from "../../../Graphql/queries";
import {
  DELETE_SCHOOL_USER,
  DEACTIVATE_SCHOOL_USER,
} from "../../../Graphql/mutations";
import { useState } from "react";
import { toggleSnackbar, schoolDtls, userDeatils } from "../../../RactiveVariables/index";
import { ConfirmDialog } from "../../Common";
import { useParams } from "react-router-dom";
import { formatPhone } from "../../Common/GeneralFunctions";

export default function Users(props) {
  const schoolDetails = useReactiveVar(schoolDtls);
  const userDtls = useReactiveVar(userDeatils);
  const [openAdd, setOpenAdd] = useState(false);
  const { id } = useParams();
  const [searchVariable, setSearchVariable] = useState(null);
  const [sortVariable, setSortVariable] = useState("-1");
  const [sortFieldVariable, setSortFieldVariable] = useState("_id");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteUser] = useMutation(DELETE_SCHOOL_USER);
  const [deactivateUser] = useMutation(DEACTIVATE_SCHOOL_USER);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const getUserVariables = {
    skip: (page - 1) * rowsPerPage,
    limit: rowsPerPage,
    search: searchVariable,
    sortOrder: sortVariable,
    sortField: sortFieldVariable,
    schId: schoolDetails?.schoolId ? schoolDetails?.schoolId : id,
    loginType: userDtls?.data?.loginType
  };
  const {
    data: userList,
    fetchMore,
    loading,
  } = useQuery(LIST_SCHOOL_USERS, {
    fetchPolicy: "network-only",
    variables: getUserVariables,
  });
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);

  const handleChangePage = (e, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  function editButtonClick(item) {
    setSelectedItem(item);
    setOpenAdd(true);
  }
  const handlePagination = () => {
    fetchMore({
      variables: getUserVariables,
    });
  };
  function handleDelete() {
    deleteUser({ variables: { userIds: [deleteId] } })
      .then((res) => {
        toggleSnackbar({
          status: true,
          message: "User deleted successfully",
          variant: "success",
        });
        handlePagination();
      })
      .catch((error) => {
        const message = error && error.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      });
  }
  function closeConfirm() {
    setDeleteId(null);
  }
  function handleConfirm() {
    handleDelete();
    closeConfirm();
    handlePagination();
  }
  function handleDeactivate(item) {
    deactivateUser({
      variables: {
        userId: item._id,
        status: item.status,
      },
    })
      .then((res) => {
        const message = res?.data?.deactivateSchUser?.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "success",
        });
        handlePagination();
      })
      .catch((error) => {
        const message = error && error.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      });
  }
  function handlePageInputChange(event) {
    setRowsPerPage(Number(event.target.value));
    setPage(1);
  }
  function handleSort(field) {
    if (sortFieldVariable === field) {
      if (sortVariable === "-1") setSortVariable("1");
      else setSortVariable("-1");
    } else {
      setSortFieldVariable(field);
      setSortVariable("1");
    }
  }

  const handleClick = (event, item) => {
    setSelectedUser(item);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className="content-wrapper">
      <div className="container">
      <div className="d-block d-md-none">
          <h3 className="m-h3">{schoolDetails?.data?.schNameThe}</h3>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <ConfirmDialog
              open={deleteId}
              title={"Delete"}
              body={"Are you sure you want to delete this user ? "}
              closeText={"Cancel"}
              confirmText={"Delete"}
              closeConfirm={closeConfirm}
              handleConfirm={handleConfirm}
            />
            <div className="card border-0">
              <div className="card-body p-0">
                {selectedItem && (
                  <AddUser
                    setSelectedItem={setSelectedItem}
                    selectedItem={selectedItem}
                    open={openAdd}
                    setOpen={setOpenAdd}
                    handlePagination={handlePagination}
                    website={props?.website}
                  />
                )}
                <div className="filter-section d-flex justify-content-between">
                  <div className="d-flex align-items-center">
                    <img
                      className="search-icon"
                      src="../assets/images/search.svg"
                      alt="search icon"
                    />
                    <input
                      className="searchbox w-150"
                      placeholder="Search by member name"
                      type="text"
                      value={searchVariable}
                      onChange={(e) => {setPage(1);setSearchVariable(e.target.value)}}
                    />
                  </div>
                  <div className="d-flex align-items-center justify-content-center">
                    <Button
                      role="button"
                      type="button"
                      className="btn add-btn"
                      onClick={() => {
                        setOpenAdd(true);
                        setSelectedItem({});
                      }}
                    >
                      + Add member
                    </Button>
                  </div>
                </div>
                <TableContainer component={Paper}>
                  <Table className="mob-table-layout" sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell className="w-150">
                          Member Name
                          {/* <a
                      className="filter-icon"
                      onClick={() => handleSort("firstName")}
                    >
                      <img
                        src={
                          sortFieldVariable !== "firstName"
                            ? "../assets/images/arrowupdown.svg"
                            : sortVariable === "-1"
                            ? "../assets/images/DownArrow.svg"
                            : "../assets/images/UpArrow.svg"
                        }
                      />
                    </a> */}
                        </TableCell>
                        <TableCell className="w-90">Title</TableCell>
                        <TableCell className="w100">Phone</TableCell>
                        <TableCell className="w-200">
                          Email
                          {/* <a
                      className="filter-icon"
                      onClick={() => handleSort("email")}
                    >
                      <img
                        src={
                          sortFieldVariable !== "email"
                            ? "../assets/images/arrowupdown.svg"
                            : sortVariable === "-1"
                            ? "../assets/images/DownArrow.svg"
                            : "../assets/images/UpArrow.svg"
                        }
                      />
                    </a> */}
                        </TableCell>
                        <TableCell className="w-150">position</TableCell>
                        <TableCell className="w100">Status</TableCell>
                        <TableCell className="w100">User Group</TableCell>
                        <TableCell className="w-90"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {loading ? (
                        <TableRow>
                          <TableCell colSpan={6}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <p> Loading users...</p>
                            </div>
                          </TableCell>
                        </TableRow>
                      ) : userList?.listSchUser?.data &&
                        userList?.listSchUser?.data.length > 0 ? (
                        userList?.listSchUser?.data.map((item) => (
                          <TableRow key={item.firstName}>
                            <TableCell>
                              <div className="d-flex align-items-center">
                                <Avatar
                                  src={
                                    item?.profileImage
                                      ? item?.profileImage
                                      : "/broken-image.jpg"
                                  }
                                />
                                <div className="ms-1 ms-md-3">
                                  {item?.firstName +
                                    " " +
                                    item?.lastName}
                                </div>
                              </div>
                            </TableCell>
                            <TableCell>{item.title}</TableCell>
                            <TableCell>{formatPhone(item.phone)}</TableCell>
                            <TableCell className="lower-case">
                              {item.email}
                            </TableCell>
                            <TableCell>{item.position=== "Other" ? item.positionOther : item.position}</TableCell>
                            <TableCell>
                              <Switch
                                checked={item.status}
                                onClick={() => handleDeactivate(item)}
                              />
                            </TableCell>
                            <TableCell>{item?.userType??item?.userGroupId}</TableCell>
                            <TableCell>
                              <Button
                                id="basic-button"
                                aria-controls="basic-menu"
                                aria-haspopup="true"
                                aria-expanded={open ? "true" : undefined}
                                onClick={(e) => handleClick(e, item)}
                                className="p-0 action-btn"
                              >
                                <img
                                  src="../assets/images/dots.svg"
                                  alt="edit button image"
                                />
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : searchVariable ? (
                        <TableRow>
                          <TableCell colSpan={6}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <p>No Users Found</p>
                            </div>
                          </TableCell>
                        </TableRow>
                      ) : (
                        <TableRow>
                          <TableCell colSpan={6}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <p>No Users Added Yet!</p>
                            </div>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                  {!loading && (
                    <div className="pagination-outer">
                      <div className="count-selector">
                        <Select
                          // variant="outlined"
                          onChange={handlePageInputChange}
                          value={rowsPerPage}
                        >
                          <MenuItem value={10}>{10}</MenuItem>
                          <MenuItem value={25}>{25}</MenuItem>
                          <MenuItem value={50}>{50}</MenuItem>
                          <MenuItem value={100}>{100}</MenuItem>
                        </Select>
                        <img
                          className="count-arrow"
                          src="../../assets/images/arrowupdown.svg"
                        />
                      </div>

                      <Pagination
                        count={Math.ceil(
                          userList?.listSchUser?.count / rowsPerPage
                        )}
                        page={page}
                        shape="rounded"
                        onChange={handleChangePage}
                      />
                    </div>
                  )}
                </TableContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "dropdown-button",
        }}
      >
        <MenuItem onClick={handleClose}>
          <a
            onClick={() => editButtonClick(selectedUser)}
            className="actionbtn-menu"
          >
            {/* <img src="../assets/images/pencil.svg" alt="edit button image" />{" "} */}
            Edit
          </a>
        </MenuItem>
        {userDtls?.data?._id !== selectedUser?._id && <MenuItem onClick={handleClose}>
          <a
            className="actionbtn-menu"
            onClick={() => setDeleteId(selectedUser._id)}
          >
            {/* <img src="../assets/images/trash.svg" alt="delete button image" />{" "} */}
            Delete
          </a>
        </MenuItem>}
      </Menu>
    </div>
  );
}
