import { Switch, withRouter ,Redirect} from "react-router-dom";
import Home from "./Modules/Home";
import Events from "./Modules/Events";
import Speaker from "./Modules/Speaker";
import School from "./Modules/School/index";
import AddSchool from "./Modules/School/AddSchool/index";
import AddSchoolEvent from "./Modules/School/SchoolEvents/AddSchoolEvents";
import SchoolPreview from "./Modules/School/SchoolPreview";
import AddEvents from "./Modules/Events/Addevent";
import EventPreview from "./Modules/Events/Addevent/EventPreview";
import User from "./Modules/User";
import SchoolUsers from "./Modules/SchoolUsers"
import NewsLetter from "./Modules/NewsLetter"
import UserList from "./Modules/NewsLetter/userListing";
import Usergroup from "./Modules/Usergroup";
import Location from "./Modules/Location";
import ENotice from "./Modules/ENotices";
import { CustomizedSnackbars } from "./Modules/Common";
import { useQuery, useReactiveVar } from "@apollo/client";
import { authVar, userDeatils, schoolDtls } from "./RactiveVariables/index";
import { GET_CURRENT_USER } from ".//Graphql/queries";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./styles/style.css";
import { Login, ResetPassword } from "./Modules/Login";
import Route from "./Route";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Loader } from "./Modules/Common";
import AddSchoolCareer from "./Modules/School/SchoolCareer/AddSchoolCareer";
import SchoolUser from "./Modules/School/Member/Users";
import SchoolCareer from "./Modules/School/SchoolCareer";
import SchoolEvent from "./Modules/School/SchoolEvents";
import SchoolOverview from "./Modules/School/SchoolPreview/Overview";
import SchoolEventPreview from "./Modules/School/SchoolEvents/SchoolEventPreview";
import SchoolCareerPreview from "./Modules/School/SchoolCareer/SchoolCareerPreview";
import OrderDetails from "./Modules/Order/OrderPreview";
import EventRegistration from "./Modules/Events/Addevent/EventRegistration";
import Checkout from "./Modules/Order/Checkout";
import Fournorfour from "./Modules/Common/404";
import Register from "./Modules/Login/register";
import OrderListing from "./Modules/Order";
import { AccessDenied } from "./Modules/Common";
import CreateENotice from "./Modules/ENotices/createENotice"
import Finance from "./Modules/Finance";
import Forum from "./Modules/Forum";
import CreateOrder from "./Modules/Order/CreateOrder";
import MyOrders from "./Modules/MyOrders";
import Unsubscribe from "./Modules/Login/unsubscribe";
import GroupDetails from "./Modules/Forum/GroupDetails";
import Conversations from "./Modules/Forum/Conversations";  
import ManageTopics from "./Modules/ManageTopics";
import EnoticeUserList from "./Modules/ENotices/eNoticeUsersLists";
import { MobAppRedirection } from "./Modules/MobAppRedirection/MobAppRedirection";
import UserLogin from "./Modules/DeleteUserAccount/userLogin";
import DeleteAccount from "./Modules/DeleteUserAccount/deleteAccount";
import CompleteDeletion from "./Modules/DeleteUserAccount/completeDeletion";
const App = (props) => {
  // -----------------------redirect to front-end--------------------------------------
  if(window.location.pathname==="/"){
    window.location.href=process.env.REACT_APP_WP_URL;
  }
  // -----------------------redirect to front-end--------------------------------------
  const auth = useReactiveVar(authVar);
  const user = useReactiveVar(userDeatils);
  const loginType = user?.data?.loginType;
  const { currentUser, loading,fetchMore } = useQuery(GET_CURRENT_USER, { fetchPolicy: "network-only", refetchQueries:['listSchUser'],
    onCompleted: (res) => {
      userDeatils({
        data: res.currentUserDtls,
      });
      schoolDtls({
        data: res.currentUserDtls?.schoolDetails,
        schoolId: res.currentUserDtls?.schoolId
      })
      authVar({
        loaded: true,
        auth: true,
      });
    },
    onError: (err) => {
      authVar({
        loaded: true,
        auth: false,
      });
    },
  });
  // function getPermission (type){
  //   let flag = false;
  //   if(user?.data?.moduleAcs && type)
  //     user?.data?.moduleAcs.forEach(element => {
  //       if(element?.title===type)
  //         flag = element?.status;
  //     });
  //   return flag;
  // }
  const userAccess = ["maintenance"];
  if (auth.loaded === false) return <Loader open={loading}/>;
  return (
    <>
      <CustomizedSnackbars />

      <Switch>
        {/* admin module */}
   
        <Route path="/events" component={Events} userAccess={["Admin", "SchoolUser"]} isPrivate header sidemenu />
        <Route path="/addevent/:id" component={AddEvents} userAccess={["Admin"]} isPrivate fullbody />
        <Route path="/schools" component={School} userAccess={["Admin"]} isPrivate  header sidemenu />
        <Route path="/addschool/:id" component={AddSchool} userAccess={["Admin", "SchoolUser"]} isPrivate fullbody />
        <Route path="/addschoolevent/:id" component={AddSchoolEvent} userAccess={["Admin", "SchoolUser"]} isPrivate fullbody />
        <Route path="/addschoolcareer/:id" component={AddSchoolCareer} userAccess={["Admin", "SchoolUser"]} isPrivate fullbody />
        <Route path="/schoolpreview/:id" component={SchoolPreview} userAccess={["Admin"]} isPrivate fullbody />
        <Route path="/cloneevent/:id" component={AddEvents} userAccess={["Admin"]} isPrivate fullbody />
        <Route path="/eventpreview/:id" component={EventPreview} fullbody />
        <Route path="/users" component={User} userAccess={["Admin"]} isPrivate header sidemenu />
        <Route path="/schoolusers" component={SchoolUsers} userAccess={["Admin"]} isPrivate header sidemenu />
        <Route path="/usergroup" component={Usergroup} userAccess={["Admin"]} isPrivate header sidemenu />
        <Route path="/newsletter" component={NewsLetter} userAccess={["Admin"]} isPrivate header sidemenu />
        <Route path="/manage-topics" component={ManageTopics} userAccess={["Admin"]} isPrivate header sidemenu />
        <Route path="/userlisting/:id" component={UserList} userAccess={["Admin"]} isPrivate header sidemenu />
        <Route path="/enotice" component={ENotice} userAccess={["Admin"]} isPrivate header sidemenu />
        <Route path="/createenotice/:id" component={CreateENotice} userAccess={["Admin"]} isPrivate fullbody  />
        <Route path="/userlists/:id" component={EnoticeUserList} userAccess={["Admin"]} isPrivate header sidemenu />
        <Route path="/location" component={Location} userAccess={["Admin"]} isPrivate header sidemenu />
        <Route path="/speaker" component={Speaker} userAccess={["Admin"]} isPrivate header sidemenu />
        <Route path="/login" component={Login} isAuthPage fullbody />
        <Route path="/register" component={Register} isAuthPage fullbody />
        <Route path="/addnewschool/:id" component={AddSchool} fullbody />
        <Route path="/404" component={Fournorfour} fullbody />
        <Route path="/unsubscribe/:id" component={Unsubscribe} fullbody />
        <Route path="/accessdenied" component={AccessDenied} fullbody />
        <Route path="/finance" component={Finance} userAccess={["Admin"]} isPrivate header sidemenu />
        <Route path="/groups" component={Forum} userAccess={["Admin", "SchoolUser"]} isPrivate header sidemenu />
        <Route path="/groups-details/:id" component={GroupDetails} userAccess={["Admin", "SchoolUser"]} isPrivate header sidemenu />
        <Route path="/conversation/:id" component={Conversations} userAccess={["Admin", "SchoolUser"]} isPrivate header sidemenu />
        {/* school user module */}
        <Route path="/loginApp/:id/:token" component={MobAppRedirection} fullbody />
        <Route path="/schoollogin/:id" component={Login} isAuthPage fullbody />
        <Route path="/schoollogin" component={Login} isAuthPage fullbody />
        <Route path="/deleteAccount" component={UserLogin} isAuthPage fullbody isPublic />
        <Route path="/confirmDeletion/:token" component={DeleteAccount} isAuthPage fullbody isPublic />
        <Route path="/completeDeletion" component={CompleteDeletion} isAuthPage fullbody isPublic />
        <Route path="/schooluser" component={SchoolUser} userAccess={["Admin", "SchoolUser"]} isPrivate header sidemenu />
        <Route path="/schoolcareer" component={SchoolCareer} userAccess={["Admin", "SchoolUser"]} isPrivate header sidemenu />
        <Route path="/schoolevent" component={SchoolEvent} userAccess={["Admin", "SchoolUser"]} isPrivate header sidemenu />
        <Route path="/resetpassword/:id" component={ResetPassword} isAuthPage fullbody />
        <Route path="/setpassword/:id" component={ResetPassword} isAuthPage fullbody />
        <Route path="/setuserpassword/:id" component={ResetPassword} isAuthPage fullbody />
        <Route path="/schoolprofile" userAccess={["Admin", "SchoolUser"]} component={()=><div className="school-outer"><div className="preview-outer mt-0"><div className="card border-0"><div className="card-body p-0"><SchoolOverview /></div></div></div></div>} isPrivate header sidemenu />
        <Route path="/schooleventpreview/:id" component={SchoolEventPreview} userAccess={["Admin", "SchoolUser"]} fullbody />
        <Route path="/schoolcareerpreview/:id" component={SchoolCareerPreview} userAccess={["Admin", "SchoolUser"]} fullbody />
        <Route path="/myevents" component={MyOrders} userAccess={["SchoolUser","Guest"]} isPrivate header sidemenu />
        <Route path="/orderdetails/:id" component={OrderDetails} userAccess={["Admin", "SchoolUser", "Guest"]} fullbody />
        <Route path="/orderlisting" component={OrderListing} userAccess={["Admin", "SchoolUser", "Guest"]} header sidemenu />
        <Route path="/checkout/:id" component={Checkout} userAccess={["Admin", "SchoolUser", "Guest"]} fullbody />
        <Route path="/eventregistration/:id" component={EventRegistration} fullbody />
        <Route path="/createorder/:id/:uid" component={CreateOrder} fullbody />
       
        {/* <Route component={Login} isAuthPage fullbody /> */}
        <Route component={Home} fullbody/>
      </Switch>
    </>
  );
};

export default withRouter(App);
