import React, { useEffect } from "react";
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  InputLabel,
  TextField,
  Select,
  MenuItem,
  Radio,
  FormControlLabel,
  RadioGroup,
} from "@mui/material";
import {
  calculateDate,
} from "../../Common/GeneralFunctions";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import EventHeader from "../../Common/EventHeader";
import {
  useMutation,
  useQuery,
  useLazyQuery,
  useReactiveVar,
} from "@apollo/client";
import { CREATE_CAREER, UPDATE_CAREER } from "../../../Graphql/mutations";
import { CAREER_DETAILS,SCHOOL_DETAILS } from "../../../Graphql/queries";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { toggleSnackbar, schoolDtls } from "../../../RactiveVariables/index";
import moment from "moment";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { useHistory, useParams } from "react-router-dom";
import { eventTypeArray } from "../../Events/EventOptions";
import { positionType, careerTypeOption, roleArray } from "../SchoolOptions";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AddEvent(props) {
  const schoolDetails = useReactiveVar(schoolDtls);
  const [value, setValue] = useState(0);
  const [addInfo, setAddInfo] = useState([]);
  const [textEditorValue, setTextEditorValue] = useState(
    EditorState.createEmpty()
  );
  const [nonDisctextEditorValue, setNonDiscTextEditorValue] = useState(
    EditorState.createEmpty()
  );
  const { id } = useParams();
  const careerId = id.split("||")[1];
  const schoolId = id.split("||")[0];
  const { data: schoolData } = useQuery(SCHOOL_DETAILS, {
    variables: { id: schoolId },
  });
  console.log("test2",schoolData);
  const history = useHistory();
  const [roleValue, setRoleValue] = useState(null);
  const [addCareer, { loading }] = useMutation(CREATE_CAREER);
  const [editCareer, { loading: updateLoading }] = useMutation(UPDATE_CAREER);
  const schema = yup.object().shape({
    title: yup.string().required("Job Title is required"),
    address: yup.string().required("Address is required"),
    city: yup.string().required("City is required"),
    state: yup.string().required("State is required"),
    zip: yup.string().required("Zip is required"),
    description: yup.string().required("Description is required"),
    startDate: yup.string().required("StartDate is required").nullable(),
    // discriminatoryPolicy: yup.string().required("Non Discriminatory Policy is required"),
    hireDate: yup.string().required("Hiring Period is required"),
    // careerType: yup.string().required("Type is required"),
    // jobNumber: yup
    //   .string()
    //   .required("Job Number is required"),
    status: yup.string().required("Status is required"),
    // positionType: yup.string().required("Position is required"),
    expVal: yup
      .number()
      .required("Expiration Period is required").nullable()
      .moreThan(0, 'Expiration period should not be zero or less than zero')
      .transform((value) => (isNaN(value) ? undefined : value))
      // .test("expVal", "Must be a number", (value) => {
      //   if (value && isNaN(value)) return false;
      //   else return true;
      // })
      ,
      roleOther: yup
      .string()
      .when("role", {
        is: (role) => role == "Other",
        then: yup.string().required("Please Enter Other Value"),
      }),
      role: yup.string().required("Job role is required"),
    // minSal: yup
    //   .string()
    //   .required("Salary Range  is required")
    //   .test("Salary Range", "Must be a number", (value) => {
    //     if (value && isNaN(value)) return false;
    //     else return true;
    //   }),
    // maxSal: yup
    //   .string()
    //   .required("Salary Range is required")
    //   .test("Salary Range", "Must be a number", (value) => {
    //     if (value && isNaN(value)) return false;
    //     else return true;
    //   })
    //   .when('minSal', (minSal, schema) => {
    //     return schema.test({
    //       test: maxSal => Number(maxSal) > Number(minSal),
    //       message: "Max should be greater than min"
    //     })
    //   }),
    careerUrl:yup.string()
    .when("careerApplyType", {
      is: (careerApplyType) => careerApplyType === "Send Resume",
      then: yup.string().email("Must Be A Valid Email").nullable(),
    })
    .when("careerApplyType", {
      is: (careerApplyType) => careerApplyType === "Link",
      then: yup.string().url()
    //   matches(
    //     /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm,
    //     'Enter correct url! http://...'
    // )
    // .required('Please enter a link'),
    })
  });

  const {
    register,
    handleSubmit,
    formState,
    reset,
    setValue: setFormValue,
    control,
    trigger,
  } = useForm({
    defaultValues: {careerApplyType:"Link"},
    resolver: yupResolver(schema),
  });
  const [getCareerDetails, { data: careerData }] = useLazyQuery(
    CAREER_DETAILS,
    {
      fetchPolicy:'network-only',
      onCompleted: (res) => {
        let data = res?.careerDetails;
        if (data.description) setFormValue("description", data.description);
        if (data.discriminatoryPolicy) setFormValue("discriminatoryPolicy",data.discriminatoryPolicy)
        if (data.status) setFormValue("status", data.status);
        setFormValue("careerApplyType",data.careerApplyType);        
        setFormValue(
          "startDate",
          data.startDate
        );
        setFormValue('role',data.role)
        let blocksFromHtml = htmlToDraft(data?.description);
        let { contentBlocks, entityMap } = blocksFromHtml;
        let contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        setTextEditorValue(EditorState.createWithContent(contentState));

        let blocksFromHtml1 = htmlToDraft(data?.discriminatoryPolicy===null?"":data?.discriminatoryPolicy);
        let contentBlocks1 = blocksFromHtml1.contentBlocks;
        let entityMap1  = blocksFromHtml1.entityMap;
        let contentState1 = ContentState.createFromBlockArray(contentBlocks1, entityMap1);
        setNonDiscTextEditorValue(EditorState.createWithContent(contentState1));
      },
    }
  );
  const careerDetails = careerData?.careerDetails;
console.log("careerDetails",careerDetails?.createdDate);
  useEffect(() => {
    if (careerId !== "$") getCareerDetails({ variables: { id: careerId } });
  }, []);

  const { errors } = formState;
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // function convertDate(str) {
  //   var date = new Date(str),
  //     mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  //     day = ("0" + date.getDate()).slice(-2);
  //   return [mnth, day, date.getFullYear()].join("/");
  // }
  
  function convertDate(date){
    return new Date(date).toUTCString();
  }

  function onSubmit(params) {
    if(params?.expVal)params.expVal=params.expVal.toString()
    if(params?.minSal) params.minSal = params.minSal.replace(/,/g, '');
    if(params?.maxSal) params.maxSal = params.maxSal.replace(/,/g, '');
    if (params?.status === "true") params.status = true;
    else params.status = false;
    // params.endDate = calculateDate(
    //   // new Date(params.startDate),
    //   new Date(),
    //   params.expVal,
    //   params.expType
    // );
    params['schRegion'] = schoolData?.schoolDetails?.schRegion
  if(schoolData?.schoolDetails?.schStatus==="Non-Member"){
     params["memStatus"]=false
    }else{params["memStatus"]=true};
    if (careerId === "$") {
      params.endDate = calculateDate(
        // new Date(params.startDate),
        new Date(),
        params.expVal,
        params.expType
      );
      params["careerBelgsTo"] = "School";
      params["schoolId"] = schoolDetails?.schoolId
        ? schoolDetails?.schoolId
        : schoolId;
      try {
        addCareer({ variables: { data: params } })
          .then((res) => {
            toggleSnackbar({
              // status: true,
              message: "Career added successfully",
              variant: "success",
            });
            if (schoolDetails.schoolId) {
              history.push("/schoolcareer");
            } else {
              history.push({
                pathname: `/SchoolPreview/${schoolId}`,
                selectedTab: 3,
              });
            }
          })
          .catch((error) => {
            const message = error && error.message;
            toggleSnackbar({
              // status: true,
              message: message,
              variant: "error",
            });
          });
      } catch (error) {
        const message = error && error.message;
        toggleSnackbar({
          // status: true,
          message: message,
          variant: "error",
        });
      }
    } else {
      params.endDate = calculateDate(
        // new Date(params.startDate),
        new Date(careerDetails?.createdDate),
        params.expVal,
        params.expType
      );
      try {
        editCareer({ variables: { data: params, _id: careerId } })
          .then((res) => {
            toggleSnackbar({
              // status: true,
              message: "Career updated successfully",
              variant: "success",
            });
            if (schoolDetails.schoolId) {
              history.push("/schoolcareer");
            } else {
              history.push({
                pathname: `/SchoolPreview/${schoolId}`,
                selectedTab: 3,
              });
            }
          })
          .catch((error) => {
            const message = error && error.message;
            toggleSnackbar({
              // status: true,
              message: message,
              variant: "error",
            });
          });
      } catch (error) {
        const message = error && error.message;
        toggleSnackbar({
          // status: true,
          message: message,
          variant: "error",
        });
      }
    }
  }
  function onChangeEditor(value) {
    // if (value.toString("html") === "<p><br></p>") {
    //   registerDescription.onChange({
    //     target: { value: "", name: "description" },
    //   });
    // } else {
    //   registerDescription.onChange({
    //     target: { value: value.toString("html"), name: "description" },
    //   });
    // }
    registerDescription.onChange({
      target: { value: draftToHtml(convertToRaw(value.getCurrentContent())), name: "description" },
    });
    setTextEditorValue(value);
  }
  function onChangePolicyEditor(value) {
    // if (value.toString("html") === "<p><br></p>") {
    //   registerPolicy.onChange({
    //     target: { value: "", name: "discriminatoryPolicy" },
    //   });
    // } else {
    //   registerPolicy.onChange({
    //     target: { value: value.toString("html"), name: "discriminatoryPolicy" },
    //   });
    // }
    registerPolicy.onChange({
      target: { value: draftToHtml(convertToRaw(value.getCurrentContent())), name: "discriminatoryPolicy" },
    });
    setNonDiscTextEditorValue(value);
  }

  const registerDescription = register("description");
  const registerPolicy = register("discriminatoryPolicy");
  const toolbarConfig = {
    display: [
      "INLINE_STYLE_BUTTONS",
      "BLOCK_TYPE_BUTTONS",
      "LINK_BUTTONS",
      "HISTORY_BUTTONS",
    ],
    INLINE_STYLE_BUTTONS: [
      { label: "Bold", style: "BOLD", className: "custom-css-class" },
      { label: "Italic", style: "ITALIC" },
      { label: "StrikeThrough", style: "STRIKETHROUGH" },
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: "UL", style: "unordered-list-item" },
      { label: "OL", style: "ordered-list-item" },
    ],
  };
  const FormatCurrency = (num) => {
    if (num) {
      let newNum = num.replace(/,/g, '');
      let x;
      if (newNum.length <= 3) {
        x = newNum;
      } else if (newNum.length > 3 && newNum.length <= 6) {
        x = newNum.slice(0, newNum.length-3) + "," + newNum.slice(newNum.length-3, 6);
      } else {
        x =
          newNum.slice(0, newNum.length-6) +
          "," +
          newNum.slice(newNum.length-6, newNum.length-3) +
          "," +
          newNum.slice(newNum.length-3, 10);
      }
      return x;
  }
  };
  function sortLabels(a, b) {
    var nameA = a.toUpperCase();
    var nameB = b.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  }
  return (
    <div className="fullwidth-body">
      {(careerId === "$" || careerDetails) && (
        <form
          className="adduser-form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <EventHeader
              title={careerId === "$" ? "Add A Job" : "Edit Job"}
              addschoolCareer
              saveBtnLoading={loading || updateLoading}
              schoolId={schoolId}
              schoolDetails={schoolDetails}
            />
            <div className="event-outer">
              <div className="container">
                <div className="row">
                  <div className="col-sm-8 stretch-card">
                    <div className="card mb-4">
                      <div className="card-title">
                        <img src="../../assets/images/Summary.svg" />
                        Job Summary
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-12">
                            <InputLabel className="form-label">
                            Job Title<sup className="text-danger">*</sup>
                            </InputLabel>
                            <TextField
                              autoFocus
                              style={{ width: "100%" }}
                              variant="outlined"
                              {...register("title")}
                              error={errors.title}
                              defaultValue={careerDetails?.title}
                            />
                            {errors && errors?.["title"] && (
                              <p className="errorMessage text-danger">
                                {errors?.title?.message}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="row mt-3">
                        <div className="col-md-3 mb-3">
                            <InputLabel className="form-label">
                              Address<sup className="text-danger">*</sup>
                            </InputLabel>
                            <TextField
                              id="address"
                              {...register("address")}
                              defaultValue={careerDetails?.address??schoolData?.schoolDetails?.schAddress??schoolDetails?.data?.schAddress}
                            />
                            {errors && errors?.["address"] && (
                              <p className="errorMessage text-danger">
                                {errors?.address?.message}
                              </p>
                            )}
                          </div>
                          <div className="col-md-3 mb-3">
                            <InputLabel className="form-label">
                              City<sup className="text-danger">*</sup>
                            </InputLabel>
                            <TextField
                              id="city"
                              {...register("city")}
                              defaultValue={careerDetails?.city??schoolData?.schoolDetails?.schCity??schoolDetails?.data?.schCity}
                            />
                            {errors && errors?.["city"] && (
                              <p className="errorMessage text-danger">
                                {errors?.city?.message}
                              </p>
                            )}
                          </div>
                          <div className="col-md-3 mb-3">
                            <InputLabel className="form-label">
                              State<sup className="text-danger">*</sup>
                            </InputLabel>
                            <TextField
                              id="state"
                              {...register("state")}
                              defaultValue={careerDetails?.state??schoolData?.schoolDetails?.schState??schoolDetails?.data?.schState}
                            />
                            {errors && errors?.["state"] && (
                              <p className="errorMessage text-danger">
                                {errors?.state?.message}
                              </p>
                            )}
                          </div>
                          <div className="col-md-3 mb-3">
                            <InputLabel className="form-label">
                              Zip<sup className="text-danger">*</sup>
                            </InputLabel>
                            <TextField
                              id="zip"
                              {...register("zip")}
                              defaultValue={careerDetails?.zip??schoolData?.schoolDetails?.schZip??schoolDetails?.data?.schZip}
                            />
                            {errors && errors?.["zip"] && (
                              <p className="errorMessage text-danger">
                                {errors?.zip?.message}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="tab-outer">
                              <InputLabel className="form-label">
                                Description<sup className="text-danger">*</sup>
                              </InputLabel>
                              {/* <RichTextEditor className="large-box text-editor-max-height"
                                toolbarConfig={toolbarConfig}
                                value={textEditorValue}
                                {...registerDescription}
                                onChange={(value) => onChangeEditor(value)}
                              /> */}
                              <Editor
                                editorState={textEditorValue}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={(value) => onChangeEditor(value)}
                              />
                              {errors && errors?.["description"] && (
                                <p className="errorMessage text-danger">
                                  {errors?.description?.message}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-sm-12 mt-3">
                            <div className="tab-outer">
                              <InputLabel className="form-label">
                              Non Discriminatory Policy
                              </InputLabel>
                              {/* <RichTextEditor className="large-box text-editor-max-height"
                                toolbarConfig={toolbarConfig}
                                value={nonDisctextEditorValue}
                                {...registerPolicy}
                                onChange={(value) => onChangePolicyEditor(value)}
                              /> */}
                              <Editor
                                editorState={nonDisctextEditorValue}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={(value) => onChangePolicyEditor(value)}
                              />
                              {errors && errors?.["discriminatoryPolicy"] && (
                                <p className="errorMessage text-danger">
                                  {errors?.discriminatoryPolicy?.message}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="card mb-4">
                      <div className="card-title">
                        <img src="../../assets/images/calendar.svg" /> Job Details
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-12 mb-3">
                            <InputLabel className="form-label">
                              Status<sup className="text-danger">*</sup>
                            </InputLabel>
                            <Select
                              style={{ width: "100%" }}
                              variant="outlined"
                              {...register("status")}
                              error={errors.status}
                              defaultValue={careerDetails?.status}
                            >
                              <MenuItem value={true}>Active</MenuItem>
                              <MenuItem value={false}>Inactive</MenuItem>
                            </Select>
                            {errors && errors?.["status"] && (
                              <p className="errorMessage text-danger">
                                {errors?.status?.message}
                              </p>
                            )}
                          </div>
                          {/* {careerId!=="$" && <div className="col-md-6 mb-3">
                            <InputLabel className="form-label">
                              Job number<sup className="text-danger">*</sup>
                            </InputLabel>
                            <TextField
                              id="time"
                              disabled
                              {...register("jobNumber")}
                              defaultValue={careerDetails?.jobNumber}
                            />
                            {errors && errors?.["jobNumber"] && (
                              <p className="errorMessage text-danger">
                                {errors?.jobNumber?.message}
                              </p>
                            )}
                          </div>} */}
                          <div className={ "col-md-12 mb-3"}>
                            <InputLabel className="form-label">
                            Hiring Period
                              <sup className="text-danger">*</sup>
                            </InputLabel>
                            <Select
                              style={{ width: "100%" }}
                              variant="outlined"
                              {...register("hireDate")}
                              error={errors.hireDate}
                              defaultValue={careerDetails?.hireDate}
                            >
                              <MenuItem value={"Effective Immediately"}>
                                Effective Immediately
                              </MenuItem>
                              <MenuItem value={"Next 2-3 Weeks"}>
                                Next 2-3 Weeks
                              </MenuItem>
                              <MenuItem value={"Next 1-2 Months"}>
                                Next 1-2 Months
                              </MenuItem>
                              <MenuItem value={"Next 3+ Months"}>
                                Next 3+ Months
                              </MenuItem>
                            </Select>
                            {errors && errors?.["hireDate"] && (
                              <p className="errorMessage text-danger">
                                {errors?.hireDate?.message}
                              </p>
                            )}
                          </div>
                          <div className={roleValue==="Other" ? "col-md-6 mb-3" : "col-md-12 mb-3"}>
                            <InputLabel className="form-label">
                              Job Role
                              <sup className="text-danger">*</sup>
                            </InputLabel>
                            <Select
                              style={{ width: "100%" }}
                              variant="outlined"
                              error={errors.role}
                              defaultValue={careerDetails?.role}
                              onChange={(e)=>{
                                register("role").onChange({target:{value:e.target.value,name:"role"}})
                                setRoleValue(e.target.value);
                              }}
                            >
                              {roleArray?.sort(sortLabels).map((item) => (
                                <MenuItem value={item}>{item}</MenuItem>
                              ))}
                            </Select>
                            {errors && errors?.["role"] && (
                              <p className="errorMessage text-danger">
                                {errors?.role?.message}
                              </p>
                            )}
                          </div>
                          {roleValue==="Other" && 
                          <div className="col-md-6 mb-3">
                          <InputLabel className="form-label">
                            Role Other
                          </InputLabel>
                          <TextField
                            id="roleOther"
                            {...register("roleOther")}
                            defaultValue={careerDetails?.roleOther}
                          />
                          {errors && errors?.["roleOther"] && (
                            <p className="errorMessage text-danger">
                              {errors?.roleOther?.message}
                            </p>
                          )}
                        </div>
                        }
                          {/* <div className="col-md-6 mb-3">
                            <InputLabel className="form-label">
                              Position<sup className="text-danger">*</sup>
                            </InputLabel>
                            <TextField
                              {...register("positionType")}
                              defaultValue={careerDetails?.positionType}
                            />
                            <Select
                              style={{ width: "100%" }}
                              variant="outlined"
                              {...register("positionType")}
                              error={errors.positionType}
                              defaultValue={careerDetails?.positionType}
                            >
                              {positionType?.sort(sortLabels).map((item) => (
                                <MenuItem value={item}>{item}</MenuItem>
                              ))}
                            </Select>
                            {errors && errors?.["positionType"] && (
                              <p className="errorMessage text-danger">
                                {errors?.positionType?.message}
                              </p>
                            )}
                          </div> */}
                          <div className="col-md-12 mb-3">
                            <InputLabel className="form-label">
                              Type
                            </InputLabel>
                            <Select
                              style={{ width: "100%" }}
                              variant="outlined"
                              {...register("careerType")}
                              error={errors.careerType}
                              defaultValue={careerDetails?.careerType}
                            >
                              {careerTypeOption.map((item) => (
                                <MenuItem value={item}>
                                  {item}
                                </MenuItem>
                              ))}
                            </Select>
                            {errors && errors?.["careerType"] && (
                              <p className="errorMessage text-danger">
                                {errors?.careerType?.message}
                              </p>
                            )}
                          </div>
                          <div className="col-md-12 mb-3">
                            <InputLabel className="form-label">
                              Start Date<sup className="text-danger">*</sup>
                            </InputLabel>
                            <Controller
                                control={control}
                                name="startDate"
                                render={({ field: { onChange, value } }) => (
                                  <>
                                    <DesktopDatePicker
                                      inputFormat="MM/dd/yyyy"
                                      value={value ? value : null}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          className="date-field"
                                        />
                                      )}
                                      onChange={(e) => onChange(e ? convertDate(e) : null)}
                                    />
                                    {errors && errors?.["startDate"] && (
                                      <p className="errorMessage text-danger">
                                        {errors?.startDate?.message}
                                      </p>
                                    )}
                                  </>
                                )}
                              />
                          </div>
                          <div className="col-md-12 mb-3">
                            <InputLabel className="form-label" >
                              Expiration period <sup className="text-danger">*</sup> <span className="text-danger">(Number Only)</span>
                            </InputLabel>
                            <div className="d-flex">
                            <Controller
                                control={control}
                                defaultValue={careerDetails?.expVal}
                                name="expVal"
                                render={({ field: { onChange, value } }) => (
                                  <TextField className="me-2"
                                  onChange={(event) => {
                                    if(event.target.value>0 && !isNaN(event.target.value.replace(/,/g, '').trim()))
                                      onChange(
                                        {target:{value:event.target.value.replace(/,/g, '').trim(), name: "expVal"}})
                                    else if(!event.target.value || isNaN(event.target.value.replace(/,/g, '')))
                                      onChange({target:{value:" ", name: "expVal"}})
                                    }
                                  }
                                  value={value}
                                />
                                )}
                              />

                              {/* <TextField
                                className="me-2"
                                {...register("expVal")}
                                defaultValue={careerDetails?.expVal}
                              /> */}

                              <Select
                                className="ms-2"
                                variant="outlined"
                                style={{ width: "100%" }}
                                {...register("expType")}
                                error={errors.expType}
                                defaultValue={careerDetails?.expType ? careerDetails?.expType : "Days"}
                              >
                                <MenuItem value={"Days"}>Days</MenuItem>
                                <MenuItem value={"Week"}>Weeks</MenuItem>
                                <MenuItem value={"Month"}>Months</MenuItem>
                              </Select>
                              {errors && errors?.["expType"] && (
                                <p className="errorMessage text-danger">
                                  {errors?.expType?.message}
                                </p>
                              )}
                            </div>                          
                            {errors && errors?.["expVal"] && (
                                <p className="errorMessage text-danger">
                                  {errors?.expVal?.message}
                                </p>
                              )}
                          </div>
                          <div className="col-md-12 mb-3">
                            <InputLabel className="form-label">Salary Range</InputLabel>
                            <div className="d-md-flex">
                              <div className="d-flex me-md-2 min-max-outer">
                              <Controller
                                control={control}
                                defaultValue={FormatCurrency(careerDetails?.minSal)}
                                name="minSal"
                                render={({ field: { onChange, value } }) => (
                                  <TextField
                                  onChange={(event) => {
                                    if(event.target.value && !isNaN(event.target.value.replace(/,/g, '').trim()))
                                      onChange(
                                        {target:{value:event.target.value.replace(/,/g, '').trim(), name: "minSal"}})
                                    else if(!event.target.value || isNaN(event.target.value.replace(/,/g, '')))
                                      onChange({target:{value:" ", name: "minSal"}})
                                    }
                                  }
                                  className="min doller-sign"
                                  value={FormatCurrency(value)}
                                />
                                )}
                              />
                                
                                <Controller
                                control={control}
                                defaultValue={FormatCurrency(careerDetails?.maxSal)}
                                name="maxSal"
                                render={({ field: { onChange, value } }) => (
                                  <TextField
                                  onChange={(event) => {
                                    if(event.target.value && !isNaN(event.target.value.replace(/,/g, '').trim()))
                                      onChange(
                                        {target:{value:event.target.value.replace(/,/g, '').trim(), name: "maxSal"}})
                                        else if(!event.target.value || isNaN(event.target.value.replace(/,/g, '')))
                                      onChange({target:{value:" ", name: "maxSal"}})
                                    }
                                  }
                                  className="max doller-sign"
                                  value={FormatCurrency(value)}
                                />
                                )}
                              />
                                {/* //     <TextField
                                //   className="max doller-sign"
                                //   {...register("maxSal")}
                                //   defaultValue={careerDetails?.maxSal}
                                // /> */}
                              </div>
                              
                              <Select
                                className="ms-md-2"
                                style={{ width: "100%" }}
                                variant="outlined"
                                {...register("salType")}
                                error={errors.salType}
                                defaultValue={careerDetails?.salType ? careerDetails?.salType : "Hourly"}
                              >
                                <MenuItem value={"Hourly"}>Hourly</MenuItem>
                                <MenuItem value={"Daily"}>Daily</MenuItem>
                                <MenuItem value={"Weekly"}>Weekly</MenuItem>
                                <MenuItem value={"Monthly"}>Monthly</MenuItem>
                                <MenuItem value={"Annually"}>Annually</MenuItem>
                              </Select>
                              {errors && errors?.["salType"] && (
                                <p className="errorMessage text-danger">
                                  {errors?.salType?.message}
                                </p>
                              )}
                            </div>
                            {errors &&
                                (errors?.["minSal"] || errors?.["maxSal"]) && (
                                  <p className="errorMessage text-danger">
                                    {errors?.minSal?.message
                                      ? errors?.minSal?.message
                                      : errors?.maxSal?.message}
                                  </p>
                                )}
                          </div>
                          <div className="col-md-6 mb-3">
                            {/* <TextField
                                {...register("stassrtTime")}
                                defaultValue={careerDetails?.startTime}
                              /> */}
                            {errors && errors?.["startTime"] && (
                              <p className="errorMessage text-danger">
                                {errors?.startTime?.message}
                              </p>
                            )}
                          </div>
                          <div className="col-md-12 mb-2">
                            <InputLabel className="module-title">
                              Apply via
                            </InputLabel>
                            <RadioGroup
                              className="module-checkbox"
                              row
                              defaultValue={careerId==="$" ? "Link" : careerDetails?.careerApplyType}
                              onChange={(e)=>{
                                register("careerApplyType")
                                  .onChange({target:{value:e.target.value,name:"careerApplyType"}});
                                trigger("careerUrl");
                              }}
                            >
                              <FormControlLabel
                                value="Link"
                                control={<Radio />}
                                label="Link"
                              />
                              <FormControlLabel
                                value="Send Resume"
                                control={<Radio />}
                                label="Send Resume"
                              />
                            </RadioGroup>
                          </div>
                          <Controller
                                control={control}
                                name="careerApplyType"
                                render={({ field: { onChange, value } }) => {
                                  if(value==="Link" || value==="Send Resume")
                                  return(
                                  <div className="col-md-12 mb-3">
                                    <InputLabel className="form-label">
                                      {value==="Send Resume" ? "Provide Email" : "Provide Apply Link"}
                                    </InputLabel>
                                    <TextField
                                      id="time"
                                      {...register("careerUrl")}
                                      defaultValue={careerDetails?.careerUrl}
                                    />
                                    {errors && errors?.["careerUrl"] && (
                                      <p className="errorMessage text-danger">
                                        {errors?.careerUrl?.message}
                                      </p>
                                    )}
                                  </div>
                                )
                              return null}}
                              />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </LocalizationProvider>
        </form>
      )}
    </div>
  );
}
