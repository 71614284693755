import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";
import axios from "axios";

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };
const autocompleteapi = process.env.REACT_APP_AUTOCOMPLETE;

export default function AutoCompleteLocation(props) {
  const [value, setValue] = React.useState(
    props.defaultValue ? props.defaultValue : ""
  );
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        "https://maps.googleapis.com/maps/api/js?key=" +
          autocompleteapi +
          "&libraries=places",
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);
  const { register } = props;

  return (
    <Autocomplete
      {...props.location_register}
      // {...register("streetAdrs")}
      id="google-map-demo"
      sx={{ width: 300 }}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        if (newValue?.terms) {
          let strAddr = "";
          for (let i = 0; i < newValue.terms.length - 3; ++i) {
            if (strAddr === "") strAddr = newValue.terms[i].value;
            else strAddr = strAddr + ", " + newValue.terms[i].value;
          }
          var config = {
            method: "get",
            url: `https://maps.googleapis.com/maps/api/geocode/json?place_id=${newValue?.place_id}&key=${autocompleteapi}`,
            headers: {},
          };
          axios(config)
            .then(function (response) {
              let arr = response?.data?.results?.[0]?.address_components;
              let position = arr.findIndex(
                (element) => element.types && element.types[0] == "postal_code"
              );
              if (position !== -1)
                props?.setValue(
                  "zip",
                  response?.data?.results?.[0]?.address_components?.[
                    position
                  ]?.["long_name"]
                );
            })
            .catch(function (error) {});
          props.location_register.onChange({
            target: { value: strAddr, name: "streetAdrs" },
          });
          props?.setValue(
            "state",
            newValue.terms?.[newValue.terms.length - 2]?.value
          );
          props?.setValue(
            "city",
            newValue.terms?.[newValue.terms.length - 3]?.value
          );
          setValue(strAddr);
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label="Add a location" fullWidth />
      )}
      renderOption={(props, option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length])
        );

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item>
                <Box
                  component={LocationOnIcon}
                  sx={{ color: "text.secondary", mr: 2 }}
                />
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))}

                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}
