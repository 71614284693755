import { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  Button,
  Menu,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Pagination,
  TextField,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import { toggleSnackbar } from "../../RactiveVariables/index";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { useQuery } from "@apollo/client";
import { LIST_EVENTS } from "../../Graphql/queries";
import { DELETE_EVENT } from "../../Graphql/mutations";
import { categoryArray, eventTypeArray } from "./EventOptions";
import { Link } from "react-router-dom";
import ConfirmDialog from "../Common/ConfirmDialog";
import { formatDateandTime } from "../Common/GeneralFunctions";
import moment from "moment";
import { useReactiveVar } from "@apollo/client";
import { userDeatils } from "../../RactiveVariables/index";

export default function Events(props) {
  const  user = useReactiveVar(userDeatils);
  const [category, setcategorytype] = useState(null);
  const [searchVariable, setSearchVariable] = useState(null);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState(null);
  const [eventType, setEventType] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [selectedItem, setSelectedItem] = useState(null);
  const [sortFieldVariable, setSortFieldVariable] = useState("_id");
  const [sortVariable, setSortVariable] = useState("-1");
  const [deleteId, setDeleteId] = useState(false);
  const [deleteEvent, { loading: deleteLoadinng }] = useMutation(DELETE_EVENT);
  const getEventVariables = {
    skip: (page - 1) * rowsPerPage,
    limit: rowsPerPage,
    search: search,
    category: category,
    eventType: eventType,
    startDate: startDate,
    sortOrder: sortVariable,
    sortField: sortFieldVariable,
    status :  user?.data?.loginType==="Admin" ? null : "Published",
  };
  const {
    data: eventList,
    fetchMore,
    loading,
  } = useQuery(LIST_EVENTS, {
    fetchPolicy: "network-only",
    variables: getEventVariables,
  });
  const handleEventType = (event) => {
    setEventType(event.target.value);
  };

  function handleDelete() {
    deleteEvent({ variables: { eventIds: [selectedItem?._id] } })
      .then((res) => {
        toggleSnackbar({
          status: true,
          message: "Event deleted successfully",
          variant: "success",
        });
        handlePagination();
      })
      .catch((error) => {
        const message = error && error.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      });
  }
  const handlePagination = () => {
    fetchMore({
      variables: getEventVariables,
    });
  };
  const handlecategory = (event) => {
    setPage(1);
    setcategorytype(event.target.value);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, item) => {

    setSelectedItem(item);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChangePage = (e, page) => {
    setPage(page);
  };
  function handlePageInputChange(event) {
    setRowsPerPage(Number(event.target.value));
    setPage(1);
  }
  function convertDate(str) {
    if(str){
      var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [mnth, day, date.getFullYear()].join("/");
    }
    else{
      return null;
    }
  }
  function handleSort(field) {
    if (sortFieldVariable === field) {
      if (sortVariable === "-1") setSortVariable("1");
      else setSortVariable("-1");
    } else {
      setSortFieldVariable(field);
      setSortVariable("1");
    }
}
function closeConfirm() {
  setDeleteId(false);
}
function handleConfirm() {
  handleDelete();
  closeConfirm();
}
  return (
    <div className="content-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
          <ConfirmDialog
        open={deleteId}
        title={"Delete"}
        body={"Are you sure you want to delete this Event ? "}
        closeText={"Cancel"}
        confirmText={"Delete"}
        closeConfirm={closeConfirm}
        handleConfirm={handleConfirm}
      />
      <div className="card border-0">
        <div className="card-body p-0">
          <div className="filter-section d-flex justify-content-between fw">
            <div className="d-flex align-items-center res-event-search-box">
              <img
                className="search-icon"
                src="../assets/images/search.svg"
                alt="search icon"
              />
              <input
                className="searchbox"
                placeholder="Search for an Event"
                value={search}
                type="text"
                onChange={(e) => {setPage(1);setSearch(e.target.value)}}
              />
            </div>
            <div className="d-flex align-items-center justify-content-center fw">
              <Box className="res-box" sx={{ minWidth: 177 }}>
                <FormControl fullWidth className="filter-date">
                  {/* <InputLabel className="filter-label" id="startdate">
                    Start date
                  </InputLabel> */}
                  {/* <Select
                    className="filter-select"
                    labelId="startdate"
                    id="demo-startdate"
                    value={startdate}
                    label="startdate"
                    // onChange={handlestartdate}
                  >
                    <MenuItem>N/A</MenuItem>
                  </Select> */}
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      inputFormat="MM/dd/yyyy"
                      value={startDate ? startDate : null}
                      renderInput={(params) => <TextField {...params} />}
                      onChange={(e) => {setPage(1);setStartDate(convertDate(e))}}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Box>
              <Box  className="res-box ms-2" sx={{ minWidth: 177 }}>
                <FormControl fullWidth>
                  <InputLabel className="filter-label" id="category">
                    Category
                  </InputLabel>
                  <Select
                    className="filter-select"
                    labelId="category"
                    id="demo-category"
                    value={category}
                    label="category"
                    onChange={handlecategory}
                  >
                    <MenuItem value="">{"None"}</MenuItem>
                    {categoryArray.map((item) => (
                      <MenuItem value={item.value}>{item.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              {/* <Box className="ms-2" sx={{ minWidth: 177 }}>
                <FormControl fullWidth>
                  <InputLabel className="filter-label" id="type">
                    Type
                  </InputLabel>
                  <Select
                    className="filter-select"
                    labelId="type"
                    id="demo-type"
                    value={eventType}
                    label="type"
                    onChange={handleEventType}
                  >
                    <MenuItem value="">{"All"}</MenuItem>
                    {eventTypeArray.map((item) => (
                      <MenuItem value={item.value}>{item.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box> */}
              {user.data?.loginType === "Admin" && <Link
                role="button"
                type="button"
                className="btn add-btn ms-2"
                to="/addevent/$"
              >
                + Add Event
              </Link>}
            </div>
          </div>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={{width:450}}>
                    Event Name
                    {/* <a
                      className="filter-icon"
                      onClick={() => handleSort("title")}
                    >
                      <img
                        src={
                          sortFieldVariable !== "title"
                            ? "../assets/images/arrowupdown.svg"
                            : sortVariable === "-1"
                            ? "../assets/images/DownArrow.svg"
                            : "../assets/images/UpArrow.svg"
                        }
                      />
                    </a> */}
                  </TableCell>
                  <TableCell>
                    Date & Time
                  </TableCell>
                  <TableCell>
                    Category
                    {/* <a
                      className="filter-icon"
                      onClick={() => handleSort("category")}
                    >
                      <img
                        src={
                          sortFieldVariable !== "category"
                            ? "../assets/images/arrowupdown.svg"
                            : sortVariable === "-1"
                            ? "../assets/images/DownArrow.svg"
                            : "../assets/images/UpArrow.svg"
                        }
                      />
                    </a> */}
                  </TableCell>
                  {/* <TableCell>
                    Type
                    <a
                      className="filter-icon"
                      onClick={() => handleSort("eventType")}
                    >
                      <img
                        src={
                          sortFieldVariable !== "eventType"
                            ? "../assets/images/arrowupdown.svg"
                            : sortVariable === "-1"
                            ? "../assets/images/DownArrow.svg"
                            : "../assets/images/UpArrow.svg"
                        }
                      />
                    </a>
                  </TableCell> */}
                  <TableCell>Created By</TableCell>
                  <TableCell>Vacancy</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                   <TableRow>
                   <TableCell colSpan={12}>
                     <div
                       style={{ display: "flex", justifyContent: "center" }}
                     >
                       <p> Loading Events...</p>
                     </div>
                   </TableCell>
                 </TableRow>
                ) : eventList?.listAllEvents?.data && 
                    eventList?.listAllEvents?.data.length > 0 ? (
                    eventList?.listAllEvents?.data?.map((item) => (
                  <TableRow>
                    <TableCell className="text-normal"><Link className="preview-link" to={`/eventpreview/${item?._id}`} target={user?.data?.loginType==="Admin" ? null : "_blank"}>{item?.title}</Link></TableCell>
                    <TableCell>
                     {/* <div> {item.startDate &&
                        formatDate(item.startDate) +
                          " " +
                          formatTime(item.startTime)} -</div>
                      {item.endDate &&
                        formatDate(item.endDate) +
                          " " +
                          formatTime(item.endTime)} */}
                          {/* {formatDateandTime(item.startDate) + " - "+formatDateandTime(item.endDate)} */}
                          {moment(item?.startDate)
                          .format("MMM D, YYYY") === moment(item?.endDate)
                          .format("MMM D, YYYY") ? moment(item?.startDate)
                          .format("MMM D, YYYY") +" "+ moment(item?.startDate).format("hh:mm a") +" - "+ moment(item?.endDate).format("hh:mm a") :
                          (formatDateandTime(item.startDate) + " - "+formatDateandTime(item.endDate))}
                    </TableCell>
                    <TableCell style={item?.category==="eSeminar"?{textTransform:"initial"}:null} >{item?.category}</TableCell>
                    <TableCell>{item?.createdByDtls?.firstName+" "+item?.createdByDtls?.lastName}</TableCell>
                    <TableCell>{parseInt(item?.seats) - parseInt(item?.tickCount)}</TableCell>
                    <TableCell><span className={item?.status === "Public" ? "Published w-100  badge" : item?.status+" w-100 badge"}>{item?.status === "Public" || item?.status === "Published" ? "Published" : item?.status === "Draft" ? "Unpublished" : item?.status}</span></TableCell>
                    <TableCell>
                      <div className="d-flex">
                        {/* <Button
                          // onClick={() => editButtonClick(item)}
                          type="submit"
                          className="p-0 action-btn"
                        >
                          <img
                            src="../assets/images/dots.svg"
                            alt="edit button image"
                          />
                        </Button> */}
                        <Button
                          id="basic-button"
                          aria-controls="basic-menu"
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          onClick={(e) => handleClick(e, item)}
                          className="p-0 action-btn"
                        >
                          <img
                            src="../assets/images/dots.svg"
                            alt="edit button image"
                          />
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
                ): searchVariable ? (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <p>No Events Found</p>
                      </div>
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <p>No Events Added Yet!</p>
                      </div>
                    </TableCell>
                  </TableRow>
                )
                }
              </TableBody>
            </Table>
            {!loading && (
              <div className="pagination-outer">
                <div className="count-selector">
                <Select onChange={handlePageInputChange} value={rowsPerPage}>
                  <MenuItem value={10}>{10}</MenuItem>
                  <MenuItem value={25}>{25}</MenuItem>
                  <MenuItem value={50}>{50}</MenuItem>
                  <MenuItem value={100}>{100}</MenuItem>
                </Select>
                <img className="count-arrow" src="../../assets/images/arrowupdown.svg" />
                </div>
                <Pagination
                  count={Math.ceil(
                    eventList?.listAllEvents?.count / rowsPerPage
                  )}
                  page={page}
                  shape="rounded"
                  onChange={handleChangePage}
                />
              </div>
            )}
            
          </TableContainer>
        </div>
      </div>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "dropdown-button",
        }}
      >
        {( user?.data?.loginType==="Admin")&&<MenuItem onClick={handleClose}>
          <Link className="actionbtn-menu" to={`/addevent/${selectedItem?._id}`}>
            Edit
          </Link>
        </MenuItem>}
        { user?.data?.loginType==="Admin" && <MenuItem onClick={handleClose}>
          <a onClick={() => setDeleteId(true)} className="actionbtn-menu" href="javascript:;">
            Delete
          </a>
        </MenuItem>}
        <MenuItem onClick={handleClose}>
          <Link
            className="actionbtn-menu"
            to={`/eventpreview/${selectedItem?._id}`}
            target={user?.data?.loginType==="Admin" ? null : "_blank"}
          >
            Preview
          </Link>
        </MenuItem>
      </Menu>
          </div>
        </div>
      </div>
    </div>
  );
}
