import { useState } from "react";
import { IconButton, Menu, MenuItem, Avatar,Button } from "@mui/material";
import {
  authVar,
  userDeatils,
  schoolDtls,
  registerEvent,
} from "../../RactiveVariables/index";
import { useReactiveVar } from "@apollo/client";
import ConfirmDialog from "./ConfirmDialog";
import ChangePassword from "../School/Member/ChangePassword";
import { useHistory, Link } from "react-router-dom";
import AddUser from "../School/Member/AddMember"
import MobileSideMenu from "./MobileSidemenu";
function Header(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const user = useReactiveVar(userDeatils);
  const auth = useReactiveVar(authVar);
  const schoolDetails = useReactiveVar(schoolDtls);
  const open = Boolean(anchorEl);
  const history = useHistory();
  // const userRole = user?.data?.Role;
  const [logoutAlert, setLogoutAlert] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [editProfile,setEditProfile] = useState(false);
  const [openSideMenu, setOpenSideMenu] = useState(false);
  const handleMenuItems = (type) => {
    switch (type) {
      case "TOGGLE_LOGOUT_ALERT":
        setLogoutAlert(!logoutAlert);
        setAnchorEl(null);
        break;
      case "CONFIRM_LOGOUT":
        handleLogout();
        setLogoutAlert(!logoutAlert);
        // window.location.href = 'https://dev-wordpress.nysais.org/';
        break;
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    authVar({
      loaded: true,
      auth: false,
    });
    userDeatils(null);
    schoolDtls(null);
    registerEvent(null);
    if(user?.data?.loginType==="Admin")
      history.push("/login");
    else
      history.push("/schoollogin");
  };
  function handleUserUpdate(data){
    let userObj = {...user};
    let obj = {...userObj.data};
    Object.keys(data).map((key)=>{
      // if(data[key] && userObj.data[key]){
        obj[key]=data[key];
      // }
    })
    userObj.data = obj;
    userDeatils(userObj);
  }
  return (
    <div>
      <header className="fixed-top header">
        <MobileSideMenu 
          openSideMenu={openSideMenu}
          setOpenSideMenu={setOpenSideMenu}
        />
        
        <div className="header-logo d-none d-md-block">
          <Link to="/">
            <img
              src="../assets/images/logo.svg"
              className="img-fluid"
              alt="logo"
            />
          </Link>
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="right-div">
              <Link to="/" className="d-block d-md-none">
                <img
                  src="../assets/images/logo.svg"
                  className="img-fluid logo-mob"
                  alt="logo"
                />
              </Link>
                <h3 className="nav-title d-none d-md-block">{props?.title}</h3>
                <div className="d-flex align-items-center">
                  <div className="d-flex align-items-center">
                    {/* {schoolDetails?.schoolId &&<Button onClick={() =>history.push(`/addschool/${schoolDetails?.schoolId}`)} className="btn btn-orange mx-4">Edit School</Button>} */}
                    <p className="user-name">
                      {user?.data?.firstName +
                        " " +
                        user?.data?.lastName}
                    </p>
                    {auth.auth ? (
                      <IconButton
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={(event) => setAnchorEl(event.currentTarget)}
                        color="inherit"
                      >
                        <Avatar src={user?.data?.profileImage}>
                          {user?.data?.FirstName?.charAt(0).toUpperCase()}
                        </Avatar>
                      </IconButton>
                    ) : (
                      <a
                        href="/login"
                        className="highlight-btn"
                        id="frontend-header-login-btn"
                        color="primary"
                        variant="contained"
                      >
                        Login
                      </a>
                    )}
                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={() => setAnchorEl(null)}
                    >
                      <MenuItem
                        onClick={() => handleMenuItems("TOGGLE_LOGOUT_ALERT")}
                      >
                        Logout
                      </MenuItem>
                      {schoolDetails?.schoolId && (
                        <MenuItem
                          onClick={() =>{setEditProfile(true);setAnchorEl(null)}}
  >
                          Edit Profile
                        </MenuItem>
                      )}
                      {schoolDetails?.schoolId && (<MenuItem onClick={()=>{setEditOpen(true);setAnchorEl(null)}}>
                        Change Password
                        </MenuItem>)}
                    </Menu>
                    <ConfirmDialog
                      open={logoutAlert}
                      title={"Logout ?"}
                      body={"Are you sure you want to logout?"}
                      closeText={"No"}
                      confirmText={"Yes"}
                      closeConfirm={() => handleMenuItems("TOGGLE_LOGOUT_ALERT")}
                      handleConfirm={() => handleMenuItems("CONFIRM_LOGOUT")}
                    />
                    <ChangePassword
                    openPw={editOpen}
                    setOpenPw={setEditOpen}
                    title={"Change Password"} />
                    <AddUser 
                    open={editProfile}
                    setOpen={setEditProfile}
                    selectedItem={user.data}
                    handleUserUpdate= {handleUserUpdate}/>
                  </div>
                  <IconButton  onClick={() => setOpenSideMenu(true)} className="d-block d-md-none p-0" >
                    <img
                      src="../assets/images/hamburger-menu.svg"
                      className="img-fluid hamburger-icon hamburger-icon-mob"
                      alt="Menu Icon"
                    />
                  </IconButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Header;
