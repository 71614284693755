import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  InputLabel,
  TextField,
  Drawer,
  Button,
  FormControlLabel,
  CircularProgress,
  FormGroup,
  Checkbox,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { useMutation,useReactiveVar } from "@apollo/client";
import { ADD_ADDRESS } from "../../Graphql/mutations";
import { toggleSnackbar, userDeatils } from "../../RactiveVariables/index";

const AddAddress = (props)=>{
  const user = useReactiveVar(userDeatils);
const [checked, setChecked ] = useState(true);
    const schema = yup.object().shape({
        Address1: yup.string().required("Address is Required"),
        City: yup.string().required("City is Required"),
        State : yup.string().required("State is Required"),
        Zip: yup.string().required("Zip is Required"),
        // SchoolID: yup.string().required("School ID is Required"),
      });
    const { register, handleSubmit, formState, reset } = useForm({
        resolver: yupResolver(schema),
      });
      const { errors } = formState;

      const [addAddress,{loading:addAddressLoading}] = useMutation(ADD_ADDRESS,{refetchQueries:["listAddress"],onCompleted:(res)=>{
        closeDrawer()
      }})

     const  onSubmit=(params)=>{
         try {

            addAddress({ variables: {
                schAddress1: params.Address1,
                schAddress2: params.Address2,
                schCity: params.City,
                schState: params.State,
                schZip: params.Zip,
                defaultAddress: checked,
                schoolId: props?.data?.ordersDetails?.schoolId,
                schoolUserId: user?.data?._id,
            } })
              .then((res) => {
                
                toggleSnackbar({
                  status: true,
                  message: "Address Added successfully",
                  variant: "success",
                });
              })
              .catch((error) => {
                const message = error && error.message;
                toggleSnackbar({
                  status: true,
                  message: message,
                  variant: "error",
                });
              });
          } catch (error) {
            const message = error && error.message;
            toggleSnackbar({
              status: true,
              message: message,
              variant: "error",
            });
          }
      }

    function closeDrawer() {
        props.setOpen(false);
        reset();
      }
      const handleChange = (event) => {
        setChecked(event.target.checked);
      };
    return(
        <div>
      <Drawer anchor={"right"} open={props.open} onClose={closeDrawer}>
        <div className="sidebar-toggler" role="presentation">
          <div className="title-outer">
            <h1>
                Add New Address
            </h1>
            <div onClick={closeDrawer} className="close-icon">
              X
            </div>
          </div>
          <form
            className="adduser-form usergroup-form mt-3"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="row">
              <div className="col-md-12 mb-3">
                <InputLabel className="form-label">
                Address Line 1<sup className="text-danger">*</sup>
                </InputLabel>
                <TextField
                  style={{ width: "100%" }}
                  variant="outlined"
                  {...register("Address1")}
                  error={errors.Address1}
                />
                {errors && errors?.["Address1"] && (
                  <p className="errorMessage text-danger">
                    {errors?.Address1?.message}
                  </p>
                )}
              </div>
              <div className="col-md-12 mb-3">
                <InputLabel className="form-label">
                Address Line 2 
                </InputLabel>
                <TextField
                  style={{ width: "100%" }}
                  variant="outlined"
                  {...register("Address2")}
                //   error={errors.groupName}
                //   autoFocus
                //   defaultValue={props?.editItem?.groupName}
                />
                {/* {errors && errors?.["groupName"] && (
                  <p className="errorMessage text-danger">
                    {errors?.groupName?.message}
                  </p>
                )} */}
              </div>
              <div className="col-md-6 mb-3">
                <InputLabel className="form-label">
                City<sup className="text-danger">*</sup>
                </InputLabel>
                <TextField
                  style={{ width: "100%" }}
                  variant="outlined"
                  {...register("City")}
                  error={errors.City}
                />
                {errors && errors?.["City"] && (
                  <p className="errorMessage text-danger">
                    {errors?.City?.message}
                  </p>
                )}
              </div>
              <div className="col-md-6 mb-3">
                <InputLabel className="form-label">
                State<sup className="text-danger">*</sup>
                </InputLabel>
                <TextField
                  style={{ width: "100%" }}
                  variant="outlined"
                  {...register("State")}
                  error={errors.State}
                />
                {errors && errors?.["State"] && (
                  <p className="errorMessage text-danger">
                    {errors?.State?.message}
                  </p>
                )}
              </div>
              <div className="col-md-6 mb-3">
                <InputLabel className="form-label">
                Zip<sup className="text-danger">*</sup>
                </InputLabel>
                <TextField
                  style={{ width: "100%" }}
                  variant="outlined"
                  {...register("Zip")}
                  error={errors.Zip}
                />
                {errors && errors?.["Zip"] && (
                  <p className="errorMessage text-danger">
                    {errors?.Zip?.message}
                  </p>
                )}
              </div>
        
              <div className="col-md-12">

                <FormGroup className="module-checkbox row">
                <FormControlLabel control={<Checkbox checked={checked}
                    onChange={handleChange} />} label="Make this as my default address" />
                </FormGroup>
              </div>
            </div>

            <div className="d-flex justify-content-between top-margin align-items-center btn-section">
              <Button className="outline-btn" onClick={() => closeDrawer()}>
                Cancel
              </Button>
              <div className="d-flex align-items-center">
                <Button type="submit" className="highlight-btn">
                  {addAddressLoading? <CircularProgress size="20px" color="inherit"  /> :  "Add"}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </Drawer>
    </div>
    )
}
export default AddAddress;