import moment from "moment";

function calculateDate(from, number, type) {
  if (from && number && type) {
    if (type.toLowerCase() === "days") {
      return moment(new Date(from)).add(number, "days").format("MMM D, YYYY");
    } else if (type.toLowerCase() === "week") {
      return moment(new Date(from)).add(number, "weeks").format("MMM D, YYYY");
    } else if (type.toLowerCase() === "month") {
      return moment(new Date(from)).add(number, "months").format("MMM D, YYYY");
    }
  }
}
function calculateDays(today, lastday) {
  if (today && lastday) {
    var today = moment(today);
    var lastday = moment(lastday);

    if (lastday.diff(today, "years") >= 1) {
      return parseInt(lastday.diff(today, "years")) + " Years";
    } else if (lastday.diff(today, "months") >= 1) {
      return parseInt(lastday.diff(today, "months")) + " Months";
    } else if (lastday.diff(today, "days") >= 1) {
      return parseInt(lastday.diff(today, "days")) + " Days";
    }
  }
}
function formatCurrency(num) {
  if (num) {
    num += "";
    return parseFloat(num)
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  } else return 0;
}
function sortLabels(a, b) {
  var nameA = a.toUpperCase();
  var nameB = b.toUpperCase();
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  // names must be equal
  return 0;
}
function formatPhone(num) {
  if (num) {
    let newNum = num.replace(/[-,(,), ]+/g, "");
    let x;
    if (newNum.length <= 3) {
      x = newNum;
    } else if (newNum.length > 3 && newNum.length <= 6) {
      x = "(" + newNum.slice(0, 3) + ") " + newNum.slice(3, 6);
    } else {
      x =
        "(" +
        newNum.slice(0, 3) +
        ") " +
        newNum.slice(3, 6) +
        "-" +
        newNum.slice(6, 10);
    }
    return x;
  }
}
function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}
function formatDate(date) {
  return moment(new Date(date)).format("MMM D, YYYY");
}

function formatTime(time) {
  return moment(time, "HH.mm").format("h:mm A");
}

function formatDateandTime(date) {
  return moment(new Date(date)).format("MMM D, yyyy h:mm A");
}
function formatTimefromDate(date) {
  return moment(new Date(date)).format("h:mm A");
}

export {
  calculateDate,
  calculateDays,
  formatCurrency,
  sortLabels,
  formatPhone,
  uuidv4,
  formatDate,
  formatTime,
  formatDateandTime,
  formatTimefromDate,
};
