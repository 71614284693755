import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Loader } from "../../Common";
import {
  useQuery,
  useMutation,
  useReactiveVar,
  useLazyQuery,
} from "@apollo/client";
import {
  Box,
  Modal,
  TextField,
  InputLabel,
  Avatar,
  Button,
} from "@mui/material";
import {
  toggleSnackbar,
  schoolDtls,
  userDeatils,
} from "../../../RactiveVariables/index";
import {
  ORDER_DETAILS,
  GET_ALL_SCHOOL_USER,
  ORDER_DETAILS_FOR_GUEST,
  SCHOOL_DETAILS,
  USER_REG_CHECK,
  EVNET_TICKET_COUNT,
} from "../../../Graphql/queries";
import AddMember from "../../School/Member/AddMember";
import AddMemberPreview from "./AddMemberPreview";
import { UPDATE_ORDER } from "../../../Graphql/mutations";
import { useHistory } from "react-router-dom";
import EventHeader from "../../Common/EventHeader";
import { useForm } from "react-hook-form";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { formatCurrency } from "../../Common/GeneralFunctions";
import InfoIcon from "@mui/icons-material/Info";
import axios from "axios";

export default function EventRegistration(props) {
  const [mOpen, setMOpen] = useState(false);
  const [nMOpen, setNMOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [orderDetails, setOrderDetails] = useState(null);
  const [noSchData, setNoSchData] = useState(false);
  const { id } = useParams();
  const history = useHistory();
  const schoolDetails = useReactiveVar(schoolDtls);
  const user = useReactiveVar(userDeatils);
  const loginType = user?.data?.loginType;
  const [updateOrder, { loading: updateLoadinng }] = useMutation(UPDATE_ORDER);
  const [regCheck ] = useLazyQuery(USER_REG_CHECK);
  const { register, handleSubmit, reset } = useForm();
  
  const getEventVariables = {
    id: id,
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
  };
  const [getMemberList, { data: membersList }] =
    useLazyQuery(GET_ALL_SCHOOL_USER);
  const [getorderDetailsforGuest] = useLazyQuery(ORDER_DETAILS_FOR_GUEST, {
    fetchPolicy: "network-only",
    variables: getEventVariables,
    onCompleted: (res) => {
      setOrderDetails(res);
      getSchoolDetails();
      getMemberList({
        variables: {
          schId: res?.ordersDetails?.schoolId,
          idType: "schoolId",
        },
      });
    },
  });
  const {
    data: orderDetailsData,
    loading,
    refetch,
  } = useQuery(ORDER_DETAILS, {
    fetchPolicy: "network-only",
    variables: getEventVariables,
    onCompleted: (res) => {
      setOrderDetails(res);
      getSchoolDetails();
      getMemberList({
        variables: {
          schId: res?.ordersDetails?.schoolId,
          idType: "schoolId",
        },
      });
    },
    onError: (error) => {
      getorderDetailsforGuest();
      setNoSchData(true);
    },
  });
  const [getSchoolDetails, { data: schoolData }] = useLazyQuery(
    SCHOOL_DETAILS,
    {
      variables: { id: orderDetails?.ordersDetails?.schoolId },
    }
  );
  function getWebsite(array) {
    let position = array.findIndex((element) => element.title === "Website");
    if (position > -1) return array[position].url;
  }
  function getMember(array, type, id) {
    if (array && type) {
      let arr = [];
      array.forEach((element) => {
        if (element?.schUserList?.schUserType === type)
          arr.push(id ? element?.schUserList?._id : element);
      });
      return arr;
    }
  }
  function handleMemberAdd(nonMember) {
    if (!nonMember) {
      getMemberList({
        variables: {
          schId: orderDetails?.ordersDetails?.schoolId,
          idType: "schoolId",
        },
      });
      setOpen(true);
    }
  }
  async function handlePreviewSave(userId) {
    let nonMember = getMember(
      orderDetails?.ordersDetails?.ticketDetails,
      "Non-Member"
    );
    let nonMemberArray = [];
    nonMember.forEach((element) => {
      nonMemberArray.push(element?.schUserList?._id);
    });
    let allUser = [];
    allUser = allUser.concat(userId, nonMemberArray);
    // if (allUser.length > 5 && !orderDetails?.ordersDetails?.bulkDiscount)
    //   handleBulkDiscount("add");
    // else if (allUser.length <= 5 && orderDetails?.ordersDetails?.bulkDiscount)
    //   handleBulkDiscount("remove");

    let data = {
      totalMemCount: userId.length,
      totalNonMemCount: nonMemberArray.length,
      totalMemPrice:
        parseFloat(userId.length) *
        parseFloat(parseFloat(orderDetails?.ordersDetails?.memberPrice)),
      totalNonMemPrice:
        parseFloat(nonMemberArray.length) *
        parseFloat(orderDetails?.ordersDetails?.memberPrice),
      totalPrice:
        parseFloat(userId.length) *
          parseFloat(parseFloat(orderDetails?.ordersDetails?.memberPrice)) +
        parseFloat(nonMemberArray.length) *
          parseFloat(orderDetails?.ordersDetails?.memberPrice),
      usersIds: allUser,
      schoolId: orderDetails?.ordersDetails?.schoolId,
      orderNo: orderDetails?.ordersDetails?.orderNo,
      eventId: orderDetails?.ordersDetails?.eventId,
      actionType: "Insert",
      grandTotal:
        parseFloat(userId.length) *
          parseFloat(parseFloat(orderDetails?.ordersDetails?.memberPrice)) +
        parseFloat(nonMemberArray.length) *
          parseFloat(orderDetails?.ordersDetails?.memberPrice),
      // parseFloat(userId.length) *
      //   parseFloat(orderDetails?.ordersDetails?.memberPrice) +
      // parseFloat(nonMemberArray.length) *
      //   parseFloat(orderDetails?.ordersDetails?.memberPrice) -
      // (orderDetails?.ordersDetails?.bulkDiscount
      //   ? parseFloat(orderDetails?.ordersDetails?.bulkDiscount)
      //   : 0),
    };
    // ----------------------------------------------------------------------------------------------
    if (
      allUser.length > 5 &&
      orderDetails?.ordersDetails?.eventDtls?.bulkDiscount
    ) {
      data.grandTotal =
        parseFloat(
          allUser.length * parseFloat(orderDetails?.ordersDetails?.memberPrice)
        ) -
        parseFloat(
          (allUser.length *
            parseFloat(orderDetails?.ordersDetails?.memberPrice) *
            orderDetails?.ordersDetails?.eventDtls?.bulkDiscount) /
            100
        );
      data.bulkDiscount = parseFloat(
        orderDetails?.ordersDetails?.eventDtls?.bulkDiscount
      );
      data.bulkDiscountAmt =
        parseFloat(
          allUser.length * parseFloat(orderDetails?.ordersDetails?.memberPrice)
        ) *
        (parseFloat(orderDetails?.ordersDetails?.eventDtls?.bulkDiscount) /
          100);
    } else if (allUser.length <= 5) {
      data.grandTotal =
        allUser.length * parseFloat(orderDetails?.ordersDetails?.memberPrice);
      data.bulkDiscount = null;
      data.bulkDiscountAmt = null;
    }
    // ----------------------------------------------------------------------------------------------
    callMutation(data);
  }

  function getMemberId(array) {
    if (array) {
      let arr = [];
      array.forEach((element) => {
        if (element?.schUserList?._id)
          arr.push(element?.schUserList?._id);
      });
      return arr;
    }
  }
  function handleBook() {
    if (
      parseInt(orderDetails?.ordersDetails?.totalMemCount) > 0 ||
      parseInt(orderDetails?.ordersDetails?.totalNonMemCount) > 0
    ) {
      if (user.data?.loginType === "Admin") {
        let data = {
          actionType: "StatusUpdate",
          status: "Ready-For-Payment",
          totalMemCount: orderDetails?.ordersDetails?.totalMemCount,
          totalNonMemCount: orderDetails?.ordersDetails?.totalNonMemCount,
          totalPrice: orderDetails?.ordersDetails?.totalPrice,
          schoolId: orderDetails?.ordersDetails?.schoolId,
          grandTotal: orderDetails?.ordersDetails?.grandTotal,
        };
        callMutation(data);
      } else {
        let selectedId= getMemberId(orderDetails?.ordersDetails?.ticketDetails)
        try {
          regCheck({ variables: { eventId: orderDetails?.ordersDetails?.eventId, usersIds: selectedId } })
            .then((res) => {
              console.log(res?.data,res?.errors,res?.error,'res');
              if(res?.data!==undefined){
              let config = {
                operationName: "eventTktCount",
                variables: {
                  id: orderDetails?.ordersDetails?.eventId,
                },
                query:
                  "query eventTktCount($id: String) {\n  eventTktCount(eventId: $id) {\n    seats\n    ticktCnt\n    remainingSeats\n    __typename\n  }\n}",
              };
              axios
                .post(process.env.REACT_APP_API_URL + "/graphql", config)
                .then((response) => {
                  let length = orderDetails?.ordersDetails?.ticketDetails.length;
                  if (response.data.data.eventTktCount.remainingSeats >= length) {
                    history.push(`/checkout/${id}`);
                  } else {
                    toggleSnackbar({
                      status: true,
                      message:
                        response.data.data.eventTktCount.remainingSeats === 0
                          ? "All seats for this event are booked"
                          : `Only ${response.data.data.eventTktCount.remainingSeats} seats available for this event`,
                      variant: "error",
                    });
                  }
                });
              }
              else{
                const message = res?.error && res?.error.message;
                console.log(res?.error,'res2');
                toggleSnackbar({
                  status: true,
                  message: message,
                  variant: "error",
                });
              }
            })
            .catch((error) => {
              const message = error && error.message;
              toggleSnackbar({
                status: true,
                message: message,
                variant: "error",
              });
            });
        } catch (error) {
          console.log(error);
        }
        // let config = {
        //   operationName: "eventTktCount",
        //   variables: {
        //     id: orderDetails?.ordersDetails?.eventId,
        //   },
        //   query:
        //     "query eventTktCount($id: String) {\n  eventTktCount(eventId: $id) {\n    seats\n    ticktCnt\n    remainingSeats\n    __typename\n  }\n}",
        // };
        // axios
        //   .post(process.env.REACT_APP_API_URL + "/graphql", config)
        //   .then((response) => {
        //     let length = orderDetails?.ordersDetails?.ticketDetails.length;
        //     if (response.data.data.eventTktCount.remainingSeats >= length) {
        //       history.push(`/checkout/${id}`);
        //     } else {
        //       toggleSnackbar({
        //         status: true,
        //         message:
        //           response.data.data.eventTktCount.remainingSeats === 0
        //             ? "All seats for this event are booked"
        //             : `Only ${response.data.data.eventTktCount.remainingSeats} seats available for this event`,
        //         variant: "error",
        //       });
        //     }
        //   });
      }
    } else {
      toggleSnackbar({
        status: true,
        message: "No Members added",
        variant: "error",
      });
    }
  }

  function handleNonMemberAdd(nonMemberId) {
    let member = getMember(
      orderDetails?.ordersDetails?.ticketDetails,
      "Member",
      true
    );
    let nonMember = getMember(
      orderDetails?.ordersDetails?.ticketDetails,
      "Non-Member",
      true
    );
    nonMember.push(nonMemberId);
    let allUser = [];
    allUser = allUser.concat(member, nonMember);
    // if (allUser.length > 5 && !orderDetails?.ordersDetails?.bulkDiscount)
    //   handleBulkDiscount("add");
    // else if (allUser.length <= 5 && orderDetails?.ordersDetails?.bulkDiscount)
    //   handleBulkDiscount("remove");
    let data = {
      totalMemCount: member.length,
      totalNonMemCount: nonMember.length,
      totalMemPrice:
        parseFloat(member.length) *
        parseFloat(parseFloat(orderDetails?.ordersDetails?.memberPrice)),
      totalNonMemPrice:
        parseFloat(nonMember.length) *
        parseFloat(orderDetails?.ordersDetails?.memberPrice),
      totalPrice:
        parseFloat(member.length) *
          parseFloat(parseFloat(orderDetails?.ordersDetails?.memberPrice)) +
        parseFloat(nonMember.length) *
          parseFloat(orderDetails?.ordersDetails?.memberPrice),
      usersIds: allUser,
      actionType: "Insert",
      schoolId: orderDetails?.ordersDetails?.schoolId,
      orderNo: orderDetails?.ordersDetails?.orderNo,
      eventId: orderDetails?.ordersDetails?.eventId,
      grandTotal:
        parseFloat(member.length) *
          parseFloat(parseFloat(orderDetails?.ordersDetails?.memberPrice)) +
        parseFloat(nonMember.length) *
          parseFloat(orderDetails?.ordersDetails?.memberPrice),
      // parseFloat(member.length) *
      //   parseFloat(orderDetails?.ordersDetails?.memberPrice) +
      // parseFloat(nonMember.length) *
      //   parseFloat(orderDetails?.ordersDetails?.memberPrice) -
      // (orderDetails?.ordersDetails?.bulkDiscount
      //   ? ((parseFloat(member.length) *
      //       parseFloat(orderDetails?.ordersDetails?.memberPrice) +
      //       parseFloat(nonMember.length) *
      //         parseFloat(orderDetails?.ordersDetails?.memberPrice)) *
      //       orderDetails?.ordersDetails?.bulkDiscount) /
      //     100
      //   : 0),
    };
    if (
      allUser.length > 5 &&
      orderDetails?.ordersDetails?.eventDtls?.bulkDiscount
    ) {
      data.grandTotal =
        parseFloat(
          allUser.length * parseFloat(orderDetails?.ordersDetails?.memberPrice)
        ) -
        parseFloat(
          (allUser.length *
            parseFloat(orderDetails?.ordersDetails?.memberPrice) *
            orderDetails?.ordersDetails?.eventDtls?.bulkDiscount) /
            100
        );
      data.bulkDiscount = parseFloat(
        orderDetails?.ordersDetails?.eventDtls?.bulkDiscount
      );
      data.bulkDiscountAmt =
        parseFloat(
          allUser.length * parseFloat(orderDetails?.ordersDetails?.memberPrice)
        ) *
        (parseFloat(orderDetails?.ordersDetails?.eventDtls?.bulkDiscount) /
          100);
    } else if (allUser.length <= 5) {
      data.grandTotal =
        allUser.length * parseFloat(orderDetails?.ordersDetails?.memberPrice);
      data.bulkDiscount = null;
      data.bulkDiscountAmt = null;
    }
    callMutation(data);
  }

  function callMutation(data) {
    let config = {
      operationName: "eventTktCount",
      variables: {
        id: orderDetails?.ordersDetails?.eventId,
      },
      query:
        "query eventTktCount($id: String) {\n  eventTktCount(eventId: $id) {\n    seats\n    ticktCnt\n    remainingSeats\n    __typename\n  }\n}",
    };
    axios
      .post(process.env.REACT_APP_API_URL + "/graphql", config)
      .then((response) => {
        let length =
          data?.usersIds?.length ??
          orderDetails?.ordersDetails?.ticketDetails.length;
        if (response.data.data.eventTktCount.remainingSeats >= length) {
          if (data.paidPaymentDetails) delete data.paidPaymentDetails;
          try {
            updateOrder({ variables: { _id: id, data: data } })
              .then((res) => {
                toggleSnackbar({
                  status: true,
                  message: "Order updated successfully",
                  variant: "success",
                });
                if (!noSchData) refetch();
                else getorderDetailsforGuest();
                setOpen(false);
                if (editOpen === true) setEditOpen(false);
                if (
                  res?.data?.updateOrders?.paymentMethod === "Card" &&
                  res?.data?.updateOrders?.status === "Ready-For-Payment"
                ) {
                  if (user?.data?.loginType === "Admin")
                    history.replace({
                      pathname: `/eventpreview/${orderDetails?.ordersDetails?.eventId}`,
                      state: { selected: 2 },
                    });
                  else history.push(`/checkout/${id}`);
                } else if (
                  res?.data?.updateOrders?.paymentMethod === "Check" &&
                  res?.data?.updateOrders?.status === "Ready-For-Payment"
                )
                  history.replace({
                    pathname: `/eventpreview/${orderDetails?.ordersDetails?.eventId}`,
                    state: { selected: 3 },
                  });
              })
              .catch((error) => {
                const message = error && error.message;
                toggleSnackbar({
                  status: true,
                  message: message,
                  variant: "error",
                });
              });
          } catch (error) {
            const message = error && error.message;
            toggleSnackbar({
              status: true,
              message: message,
              variant: "error",
            });
          }
        } else {
          toggleSnackbar({
            status: true,
            message:
              response.data.data.eventTktCount.remainingSeats === 0
                ? "All seats for this event are booked"
                : `Only ${response.data.data.eventTktCount.remainingSeats} seats available for this event`,
            variant: "error",
          });
        }
      });
  }
  async function handleDeleteMember(userId, type, guestId) {
    let memCount, nonMemCount;

    if (type === "Member") {
      memCount =
        getMember(orderDetails?.ordersDetails?.ticketDetails, "Member").length -
        1;
      nonMemCount = getMember(
        orderDetails?.ordersDetails?.ticketDetails,
        "Non-Member"
      ).length;
    } else if (type === "Non-Member") {
      memCount = getMember(
        orderDetails?.ordersDetails?.ticketDetails,
        "Member"
      ).length;
      nonMemCount =
        getMember(orderDetails?.ordersDetails?.ticketDetails, "Non-Member")
          .length - 1;
    }
    let data = {
      totalMemCount: memCount,
      totalNonMemCount: nonMemCount,
      totalMemPrice:
        memCount * parseFloat(orderDetails?.ordersDetails?.memberPrice),
      totalNonMemPrice: nonMemCount * orderDetails?.ordersDetails?.memberPrice,
      totalPrice:
        memCount * parseFloat(orderDetails?.ordersDetails?.memberPrice) +
        nonMemCount * orderDetails?.ordersDetails?.memberPrice,
      grandTotal:
        memCount * parseFloat(orderDetails?.ordersDetails?.memberPrice) +
        nonMemCount * orderDetails?.ordersDetails?.memberPrice,
      totalCount: memCount + nonMemCount,
      actionType: "Delete",
      usersIds: [userId],//ticket id
      userType:type,
      guestId: guestId??null,
      schoolId: orderDetails?.ordersDetails?.schoolId,
      // grandTotal:
      //   parseInt(memCount) *
      //     parseFloat(orderDetails?.ordersDetails?.memberPrice) +
      //   parseInt(nonMemCount) *
      //     parseFloat(orderDetails?.ordersDetails?.memberPrice) -
      //   (orderDetails?.ordersDetails?.bulkDiscount
      //     ? parseFloat(
      //         (orderDetails?.ordersDetails?.bulkDiscount *
      //           (parseInt(memCount) *
      //             parseFloat(orderDetails?.ordersDetails?.memberPrice) +
      //             parseInt(nonMemCount) *
      //               parseFloat(orderDetails?.ordersDetails?.memberPrice))) /
      //           100
      //       )
      //     : 0),
    };
    if (
      memCount + nonMemCount > 5 &&
      orderDetails?.ordersDetails?.eventDtls?.bulkDiscount
    ) {
      data.grandTotal =
        parseFloat(
          (memCount + nonMemCount) *
            parseFloat(orderDetails?.ordersDetails?.memberPrice)
        ) -
        parseFloat(
          ((memCount + nonMemCount) *
            parseFloat(orderDetails?.ordersDetails?.memberPrice) *
            orderDetails?.ordersDetails?.eventDtls?.bulkDiscount) /
            100
        );
      data.bulkDiscount = parseFloat(
        orderDetails?.ordersDetails?.eventDtls?.bulkDiscount
      );
      data.bulkDiscountAmt =
        parseFloat(
          (memCount + nonMemCount) *
            parseFloat(orderDetails?.ordersDetails?.memberPrice)
        ) *
        (parseFloat(orderDetails?.ordersDetails?.eventDtls?.bulkDiscount) /
          100);
    } else if (memCount + nonMemCount <= 5) {
      // data.grandTotal = memCount * parseFloat(orderDetails?.ordersDetails?.memberPrice)
      data.grandTotal =
        memCount * parseFloat(orderDetails?.ordersDetails?.memberPrice) +
        nonMemCount * orderDetails?.ordersDetails?.memberPrice;
      data.bulkDiscount = null;
      data.bulkDiscountAmt = null;
    }
    callMutation(data);
  }
  function handleBulkDiscount(type) {
    let ticketCount = parseInt(
      orderDetails?.ordersDetails?.ticketDetails.length
    );
    let data = {
      // totalMemCount: orderDetails?.ordersDetails?.totalMemCount,
      // totalNonMemCount: orderDetails?.ordersDetails?.totalNonMemCount,
      // totalMemPrice: orderDetails?.ordersDetails?.totalMemPrice,
      // totalNonMemPrice: orderDetails?.ordersDetails?.totalNonMemPrice,
      // totalPrice: orderDetails?.ordersDetails?.totalPrice,
      actionType: "Update",
      schoolId: orderDetails?.ordersDetails?.schoolId,
      grandTotal:
        type === "add"
          ? parseFloat(
              ticketCount * parseFloat(orderDetails?.ordersDetails?.memberPrice)
            ) -
            parseFloat(
              (ticketCount *
                parseFloat(orderDetails?.ordersDetails?.memberPrice) *
                orderDetails?.ordersDetails?.eventDtls?.bulkDiscount) /
                100
            )
          : ticketCount * parseFloat(orderDetails?.ordersDetails?.memberPrice),
      // type === "add"
      //   ? parseFloat(orderDetails?.ordersDetails?.totalPrice) -
      //     parseFloat(
      //       (orderDetails?.ordersDetails?.totalPrice *
      //         orderDetails?.ordersDetails?.eventDtls?.bulkDiscount) /
      //         100
      //     )
      //   : orderDetails?.ordersDetails?.totalPrice,
      bulkDiscount:
        type === "add"
          ? parseFloat(orderDetails?.ordersDetails?.eventDtls?.bulkDiscount)
          : null,
      bulkDiscountAmt:
        type === "add"
          ? parseFloat(
              ticketCount * parseFloat(orderDetails?.ordersDetails?.memberPrice)
            ) *
            (parseFloat(orderDetails?.ordersDetails?.eventDtls?.bulkDiscount) /
              100)
          : null,
      // type === "add"
      //   ? parseFloat(orderDetails?.ordersDetails?.totalPrice) *
      //       (parseFloat(
      //         orderDetails?.ordersDetails?.eventDtls?.bulkDiscount
      //       ) /
      //         100)
      //   : null,
    };
    callMutation(data);
  }
  function onSubmit(params) {
    if (parseFloat(params.memberPrice) <= 0) {
      toggleSnackbar({
        status: true,
        message: "Please enter a valid amount",
        variant: "error",
      });
      return false;
    }
    let data = { ...orderDetails?.ordersDetails };
    delete data.__typename;
    delete data.createdByDtls;
    delete data.createdForDtls;
    delete data.eventDtls;
    delete data.ticketDetails;
    delete data.schDetails;
    delete data._id;
    delete data.paymentDetails;
    // data["totalMemCount"] = parseFloat(params?.totalMemCount);
    data["memberPrice"] = parseFloat(params?.memberPrice);
    data["totalMemPrice"] = parseFloat(params?.memberPrice);
    data["totalMemCount"] = 1;
    // data["totalPrice"] =
    //   parseInt(params?.totalMemCount) * parseFloat(params?.memberPrice);
    data["totalPrice"] = parseFloat(params?.memberPrice);
    data["grandTotal"] =
      parseFloat(params?.memberPrice) -
      (orderDetails?.ordersDetails?.bulkDiscount
        ? parseFloat(params?.memberPrice) *
          (parseFloat(orderDetails?.ordersDetails?.bulkDiscount) / 100)
        : 0);
    data["actionType"] = "bulkUpdate";
    data["usersIds"] = orderDetails?.ordersDetails?.usersIds;
    data["schoolId"] = orderDetails?.ordersDetails?.schoolId;
    data["eventId"] = orderDetails?.ordersDetails?.eventId;
    data["orderNo"] = orderDetails?.ordersDetails?.orderNo;
    callMutation(data);
  }
  function getBulkdiscountPrice(total) {
    if (
      orderDetails?.ordersDetails?.bulkDiscount &&
      orderDetails?.ordersDetails?.bulkDiscount >= 0
    )
      return formatCurrency(
        total - total * 0.01 * orderDetails?.ordersDetails?.bulkDiscount
      );
    else return formatCurrency(total);
  }

  const isDateRegValid = new Date(orderDetails?.ordersDetails?.eventDtls?.lastDateToRegister)> new Date()
  return (
    <div>
      <Loader open={loading || updateLoadinng} />
      <EventHeader
        userType={loginType}
        title={`Book Event for
          ${orderDetails?.ordersDetails?.createdForDtls?.firstName + " "}
          ${orderDetails?.ordersDetails?.createdForDtls?.lastName}
          ${
            orderDetails?.ordersDetails?.schDetails?.schNameThe
              ? ", " + orderDetails?.ordersDetails?.schDetails?.schNameThe
              : ""
          }`}
        EventRegistration
        saveBtn={orderDetails?.ordersDetails?.status}
      />
      {(mOpen || nMOpen) && (
        <AddMember
          open={mOpen ? mOpen : nMOpen}
          setOpen={mOpen ? setMOpen : setNMOpen}
          nonMember={nMOpen ? true : false}
          handleMemberAdd={handleMemberAdd}
          handleNonMemberAdd={handleNonMemberAdd}
          schoolId={orderDetails?.ordersDetails?.schoolId}
          website={
            orderDetails?.ordersDetails?.schDetails?.socialMediaWeb
              ? getWebsite(
                  orderDetails?.ordersDetails?.schDetails?.socialMediaWeb
                )
              : null
          }
        />
      )}
      {open && (
        <AddMemberPreview
          open={open}
          setOpen={setOpen}
          setMOpen={setMOpen}
          membersList={membersList}
          handlePreviewSave={handlePreviewSave}
          userId={getMember(
            orderDetails?.ordersDetails?.ticketDetails,
            "Member",
            true
          )}
        />
      )}
      <Modal
        className="modal-outer"
        open={editOpen}
        onClose={() => setEditOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {editOpen && (
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Box sx={style} className="register-modal">
              <h1 className="modal-title">Edit Price Details</h1>

              <div className="mt-4 d-flex align-items-center">
                <div>
                  <InputLabel className="filter-label" id="type">
                    Member
                  </InputLabel>
                  {/* <TextField
                  style={{ width: "100%" }}
                  variant="outlined"
                  {...register("totalMemCount")}
                  defaultValue={orderDetails?.ordersDetails?.totalMemCount}
                /> */}
                  <TextField
                    style={{ width: "100%" }}
                    variant="outlined"
                    // {...register("totalMemCount")}
                    // defaultValue={orderDetails?.ordersDetails?.totalMemCount}
                    defaultValue="1"
                    disabled
                  />
                </div>
                <div className="mb-0 mt-3 mx-2">x</div>
                <div>
                  <InputLabel className="filter-label" id="type">
                    Rate
                  </InputLabel>
                  <TextField
                    style={{ width: "100%" }}
                    variant="outlined"
                    {...register("memberPrice")}
                    defaultValue={orderDetails?.ordersDetails?.memberPrice}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-end mt-3">
                <button
                  onClick={() => {
                    setEditOpen(false);
                    reset();
                  }}
                  className="btn btn-cancel font-weight-bold"
                >
                  Cancel
                </button>
                <button
                  className="btn btn-orange ms-3 font-weight-bold"
                  type="submit"
                >
                  Confirm
                </button>
              </div>
            </Box>
          </form>
        )}
      </Modal>
      <div className="preview-outer pt-40">
        <div className="container">
          {orderDetails?.ordersDetails && (
            <div className="row">
              <div className="col-sm-8">
                <div className="card list-member member">
                  <p className="list-head">
                    {orderDetails?.ordersDetails?.eventDtls?.title}
                  </p>
                  <div className="card-body">
                    {/* {user?.data?.loginType === "Admin" && */}
                    {getMember(
                      orderDetails?.ordersDetails?.ticketDetails,
                      "Member"
                    ).length > 0 && (
                      <div className="d-flex mem-outer justify-content-between align-items-center">
                        <p className="mem-title">
                          {orderDetails?.ordersDetails?.orderType !== "Bulk"
                            ? "School Members"
                            : "School Member"}
                        </p>
                        <p className="mem-title">
                          Price $
                          {formatCurrency(
                            parseFloat(
                              schoolData?.schoolDetails?.schStatus ===
                                "Non-Member"
                                ? orderDetails?.ordersDetails?.memberPrice
                                : orderDetails?.ordersDetails?.memberPrice
                            )
                          )}{" "}
                          {orderDetails?.ordersDetails?.orderType !== "Bulk" &&
                            orderDetails?.ordersDetails?.memberPrice !==
                              orderDetails?.ordersDetails?.memberPrice && (
                              <span className="strike ms-2">
                                $
                                {formatCurrency(
                                  orderDetails?.ordersDetails?.memberPrice
                                )}
                              </span>
                            )}
                        </p>
                      </div>
                    )}
                    {/* } */}
                    {getMember(
                      orderDetails?.ordersDetails?.ticketDetails,
                      "Member"
                    ).length > 0 && (
                      <div>
                        {orderDetails?.ordersDetails?.ticketDetails &&
                          getMember(
                            orderDetails?.ordersDetails?.ticketDetails,
                            "Member"
                          ).map((item) => {
                            return (
                              <div className="member-outer">
                                <div className="members">
                                  <div className="d-flex align-items-center">
                                    {item?.schUserList?.profileImage ? (
                                      <img
                                        className="mem-profile"
                                        src={item?.schUserList?.profileImage}
                                      />
                                    ) : (
                                      <Avatar className="mem-profile">
                                        {item?.schUserList?.firstName[0].toUpperCase()}
                                      </Avatar>
                                    )}

                                    <p className="mem-name">
                                      {item?.schUserList?.firstName +
                                        " " +
                                        item?.schUserList?.lastName}
                                      ,{" "}
                                      <small>{item?.schUserList?.title}</small>
                                    </p>
                                  </div>
                                  {orderDetails?.ordersDetails?.orderType !==
                                    "Bulk" && (
                                    <button
                                      className="delete-member"
                                      onClick={() =>
                                        handleDeleteMember(item._id, "Member")
                                      }
                                      disabled={
                                        orderDetails?.ordersDetails?.status ===
                                        "Paid"
                                      }
                                    >
                                      Remove
                                    </button>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    )}
                  </div>
                </div>
                {orderDetails?.ordersDetails?.orderType === "Normal" && (
                  <>
                    {/* {!orderDetails?.ordersDetails?.orderAsGuest && (
                      <button
                        className="btn btn-orange my-3 w-100"
                        onClick={() => setOpen(true)}
                        disabled={
                          orderDetails?.ordersDetails?.status === "Paid"
                        }
                      >
                        Add Member
                      </button>
                    )} */}
                    <div className="card list-member guest">
                      <div className="card-body">
                        <div className="d-flex mem-outer mt-3 justify-content-between align-items-center">
                          <p className="mem-title">
                            {/* Guest{" "} */}
                            {/* <span
                              type="button"
                              className="p-0 action-btn"
                              data-bs-toggle="tooltip"
                              title="Guests are people who are affiliated with your school but who do not actually work in your school."
                            >
                              <InfoIcon
                                style={{ width: "17px", color: "#0d6efd" }}
                              />
                            </span> */}
                          </p>
                          <p className="mem-title">
                            Price{" "}
                            {"$" +
                              formatCurrency(
                                orderDetails?.ordersDetails?.memberPrice
                              )}
                          </p>
                        </div>
                        {orderDetails?.ordersDetails?.ticketDetails &&
                          getMember(
                            orderDetails?.ordersDetails?.ticketDetails,
                            "Non-Member"
                          ).map((item) => {
                            return (
                              <div className="member-outer">
                                <div className="members">
                                  <div className="d-flex align-items-center">
                                    {item?.schUserList?.profileImage ? (
                                      <img
                                        className="mem-profile"
                                        src={item?.schUserList?.profileImage}
                                      />
                                    ) : (
                                      <Avatar className="mem-profile">
                                        {item?.schUserList?.firstName[0].toUpperCase()}
                                      </Avatar>
                                    )}
                                    <p className="mem-name">
                                      {item?.schUserList?.firstName +
                                        " " +
                                        item?.schUserList?.lastName}
                                      <span className="text-lowercase">
                                        {" "}
                                        ({item?.schUserList?.email})
                                      </span>
                                      ,{" "}
                                      <small>{item?.schUserList?.title}</small>
                                    </p>
                                  </div>
                                  {/* {orderDetails?.ordersDetails?.createdForDtls
                                  ?._id !== item?.schUserList?._id &&  */}
                                  {!orderDetails?.ordersDetails
                                    ?.orderAsGuest && (
                                    <button
                                      className="delete-member"
                                      onClick={() =>
                                        handleDeleteMember(
                                          item._id,
                                          "Non-Member",
                                          item?.schUserList?._id

                                        )
                                      }
                                      disabled={
                                        orderDetails?.ordersDetails?.status ===
                                        "Paid"
                                      }
                                    >
                                      Remove
                                    </button>
                                  )}
                                  {/* } */}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                    {/* {!orderDetails?.ordersDetails?.orderAsGuest && (
                      <button
                        className="btn btn-orange my-3 w-100"
                        onClick={() => setNMOpen(true)}
                        disabled={
                          orderDetails?.ordersDetails?.status === "Paid"
                        }
                      >
                        Add Guest
                      </button>
                    )} */}
                  </>
                )}
                {orderDetails?.ordersDetails?.orderType === "Normal" && (
                  <div className="row mt-2">
                    {!orderDetails?.ordersDetails?.orderAsGuest && (
                      <div className="col-12">
                        <button
                          className="btn btn-orange my-2 my-md-3 w-100"
                          onClick={() => setOpen(true)}
                          disabled={
                            orderDetails?.ordersDetails?.status === "Paid"
                          }
                        >
                          Add Member
                        </button>
                      </div>
                    )}
                    {/* {!orderDetails?.ordersDetails?.orderAsGuest && (
                      <div className="col-6">
                        <button
                          className="btn btn-orange my-2 my-md-3 w-100"
                          onClick={() => setNMOpen(true)}
                          disabled={
                            orderDetails?.ordersDetails?.status === "Paid"
                          }
                        >
                          Add Guest{" "}
                          <span
                            type="button"
                            className="p-0 action-btn"
                            data-bs-toggle="tooltip"
                            title="Guests are people who are affiliated with your school but who do not actually work in your school."
                          >
                            <InfoIcon style={{ width: "18px" }} />
                          </span>
                        </button>
                      </div>
                    )} */}
                  </div>
                )}
              </div>
              <div className="col-sm-4">
                <div className="card order-data">
                  {orderDetails?.ordersDetails?.orderType !== "Bulk" ? (
                    <div className="card-title">Order Summary</div>
                  ) : (
                    <div className="card-title d-flex align-items-center justify-content-between">
                      <span>Order Summary</span>
                      {orderDetails?.ordersDetails?.status === "Open" && (
                        <a
                          onClick={() => setEditOpen(true)}
                          href="javascript:;"
                        >
                          <img src="../assets/images/edit3.svg" />
                        </a>
                      )}
                      {/* <a href="javascript:;" onClick={handleOpen}>Open modal</a> */}
                    </div>
                  )}

                  <div className="card-table">
                    <div className="head">
                      <div className="row">
                        <div className="col-5">User Type</div>
                        <div className="col-4">Reg/Rate</div>
                        <div className="col-3 ps-md-0">Amount</div>
                      </div>
                    </div>
                    {getMember(
                      orderDetails?.ordersDetails?.ticketDetails,
                      "Member"
                    ).length > 0 && (
                      <div className="body">
                        <div className="row">
                          <div className="col-5">Member</div>
                          {orderDetails?.ordersDetails?.orderType !== "Bulk" ? (
                            <>
                              <div className="col-4">
                                {`${
                                  getMember(
                                    orderDetails?.ordersDetails?.ticketDetails,
                                    "Member"
                                  ).length
                                } x $${formatCurrency(
                                  parseFloat(
                                    schoolData?.schoolDetails?.schStatus ===
                                      "Non-Member"
                                      ? orderDetails?.ordersDetails?.memberPrice
                                      : orderDetails?.ordersDetails?.memberPrice
                                  )
                                )}`}
                              </div>
                              <div className="col-3 ps-md-0">
                                $
                                {
                                  schoolData?.schoolDetails?.schStatus ===
                                  "Non-Member"
                                    ? formatCurrency(
                                        getMember(
                                          orderDetails?.ordersDetails
                                            ?.ticketDetails,
                                          "Member"
                                        ).length *
                                          orderDetails?.ordersDetails
                                            ?.memberPrice
                                      )
                                    : formatCurrency(
                                        getMember(
                                          orderDetails?.ordersDetails
                                            ?.ticketDetails,
                                          "Member"
                                        ).length *
                                          orderDetails?.ordersDetails
                                            ?.memberPrice
                                      )
                                  // orderDetails?.ordersDetails?.totalMemPrice
                                }
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="col-sm-4">
                                {`${
                                  orderDetails?.ordersDetails?.totalMemCount
                                } x $${formatCurrency(
                                  orderDetails?.ordersDetails?.memberPrice
                                )}`}
                              </div>
                              <div className="col-sm-3 ps-0">
                                $
                                {formatCurrency(
                                  orderDetails?.ordersDetails?.totalMemCount *
                                    orderDetails?.ordersDetails?.memberPrice
                                )}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    )}
                    {orderDetails?.ordersDetails?.orderType !== "Bulk" &&
                      getMember(
                        orderDetails?.ordersDetails?.ticketDetails,
                        "Non-Member"
                      ).length !== 0 && (
                        <div className="body">
                          <div className="row">
                            <div className="col-5">Guest</div>
                            <div className="col-4">
                              {`${
                                getMember(
                                  orderDetails?.ordersDetails?.ticketDetails,
                                  "Non-Member"
                                ).length
                              } x $${formatCurrency(
                                orderDetails?.ordersDetails?.memberPrice
                              )}`}
                            </div>
                            <div className="col-3 ps-md-0">
                              {"$" +
                                formatCurrency(
                                  getMember(
                                    orderDetails?.ordersDetails?.ticketDetails,
                                    "Non-Member"
                                  ).length *
                                    orderDetails?.ordersDetails?.memberPrice
                                )}
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                  <div className="card-total">
                    {orderDetails?.ordersDetails?.orderType !== "Bulk" && (
                      <div className="datas">
                        <div className="row">
                          <div className="col-9">Sub Total</div>

                          <div className="col-3 ps-md-0">
                            $
                            {schoolData?.schoolDetails?.schStatus ===
                            "Non-Member"
                              ? formatCurrency(
                                  parseFloat(
                                    getMember(
                                      orderDetails?.ordersDetails
                                        ?.ticketDetails,
                                      "Member"
                                    ).length *
                                      orderDetails?.ordersDetails?.memberPrice
                                  ) +
                                    parseFloat(
                                      getMember(
                                        orderDetails?.ordersDetails
                                          ?.ticketDetails,
                                        "Non-Member"
                                      ).length *
                                        orderDetails?.ordersDetails?.memberPrice
                                    )
                                )
                              : formatCurrency(
                                  parseFloat(
                                    getMember(
                                      orderDetails?.ordersDetails
                                        ?.ticketDetails,
                                      "Member"
                                    ).length *
                                      orderDetails?.ordersDetails?.memberPrice
                                  ) +
                                    parseFloat(
                                      getMember(
                                        orderDetails?.ordersDetails
                                          ?.ticketDetails,
                                        "Non-Member"
                                      ).length *
                                        orderDetails?.ordersDetails?.memberPrice
                                    )
                                )}
                          </div>
                        </div>
                      </div>
                    )}
                    {orderDetails?.ordersDetails?.eventDtls?.bulkDiscount &&
                      orderDetails?.ordersDetails?.eventDtls?.bulkDiscount !==
                        "" && (
                        <>
                          {loginType === "Admin" && (
                            <div className="datas">
                              <div className="row">
                                <div className="col-9">
                                  Bulk Discount Percentage
                                </div>
                                <div className="col-3 ps-md-0 d-flex align-items-center">
                                  {orderDetails?.ordersDetails?.bulkDiscount &&
                                    orderDetails?.ordersDetails?.bulkDiscount +
                                      "%"}
                                  <button
                                    className="delete-bulk-discount ms-1"
                                    onClick={() =>
                                      handleBulkDiscount(
                                        orderDetails?.ordersDetails
                                          ?.bulkDiscount &&
                                          orderDetails?.ordersDetails
                                            ?.bulkDiscount >= 0
                                          ? "remove"
                                          : "add"
                                      )
                                    }
                                  >
                                    {orderDetails?.ordersDetails
                                      ?.bulkDiscount &&
                                    orderDetails?.ordersDetails?.bulkDiscount >=
                                      0 ? (
                                      <DeleteIcon />
                                    ) : (
                                      <AddIcon className="add" />
                                    )}
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                          {orderDetails?.ordersDetails?.bulkDiscount !== 0 &&
                            orderDetails?.ordersDetails?.bulkDiscount >= 0 && (
                              <div className="datas">
                                <div className="row">
                                  <div className="col-9">Discount Amount</div>
                                  <div className="col-3 ps-md-0">
                                    {"$" +
                                      formatCurrency(
                                        parseFloat(
                                          orderDetails?.ordersDetails
                                            ?.totalPrice
                                        ) *
                                          (parseFloat(
                                            orderDetails?.ordersDetails
                                              ?.bulkDiscount
                                          ) /
                                            100)
                                      )}
                                  </div>
                                </div>
                              </div>
                            )}
                        </>
                      )}

                    <div className="datas">
                      <div className="row">
                        <div className="col-9 final">Total</div>
                        <div className="col-3 ps-md-0">
                          {orderDetails?.ordersDetails?.bulkDiscount &&
                          orderDetails?.ordersDetails?.bulkDiscount >= 0
                            ? "$" +
                              formatCurrency(
                                parseFloat(
                                  orderDetails?.ordersDetails?.totalPrice
                                ) -
                                  parseFloat(
                                    orderDetails?.ordersDetails?.totalPrice
                                  ) *
                                    (parseFloat(
                                      orderDetails?.ordersDetails?.bulkDiscount
                                    ) /
                                      100)
                              )
                            : "$" +
                              formatCurrency(
                                parseFloat(
                                  orderDetails?.ordersDetails?.totalPrice
                                )
                              )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="booking-fortext">
                    Register for{" "}
                    <b>
                      {orderDetails?.ordersDetails?.createdForDtls?.firstName +
                        " " +
                        orderDetails?.ordersDetails?.createdForDtls?.lastName}
                    </b>
                    <br />
                    {orderDetails?.ordersDetails?.schDetails?.schNameThe &&
                      "on behalf of "}
                    <b>{orderDetails?.ordersDetails?.schDetails?.schNameThe}</b>
                    {(!isDateRegValid||orderDetails?.ordersDetails?.status==='Completed')&&<p style={{color:'red'}}>Last date to register expired</p>}
                  </p>
                  <div className="row">
                    <div className={"col-sm-12"}>
                      {(isDateRegValid&&orderDetails?.ordersDetails?.status!=='Completed')&&<div className="highlight-footer">
                        <a
                          href="javascript:;"
                          onClick={() =>
                            orderDetails?.ordersDetails?.status === "Paid"
                              ? null
                              : handleBook()
                          }
                        >
                          {orderDetails?.ordersDetails?.status === "Paid" ? (
                            <h2>Paid</h2>
                          ) : orderDetails?.ordersDetails?.orderType !==
                            "Bulk" ? (
                            <h2>
                              {user?.data?.loginType === "Admin"
                                ? "Register"
                                : "Pay now"}{" "}
                              {/* @ */}
                              {/* {" $" +
                                getBulkdiscountPrice(
                                  // parseInt(
                                  //   getMember(
                                  //     orderDetails?.ordersDetails?.ticketDetails,
                                  //     "Member"
                                  //   ).length *
                                  //     orderDetails?.ordersDetails?.memberPrice
                                  // ) +
                                  //   parseInt(
                                  //     getMember(
                                  //       orderDetails?.ordersDetails?.ticketDetails,
                                  //       "Non-Member"
                                  //     ).length *
                                  //       orderDetails?.ordersDetails?.nonMemberPrice
                                  //   )
                                  orderDetails?.ordersDetails?.totalPrice > 0
                                    ? parseFloat(
                                        orderDetails?.ordersDetails?.totalPrice
                                      )
                                    : schoolData?.schoolDetails?.schStatus ===
                                      "Non-Member"
                                    ? formatCurrency(
                                        parseInt(
                                          getMember(
                                            orderDetails?.ordersDetails
                                              ?.ticketDetails,
                                            "Member"
                                          ).length *
                                            orderDetails?.ordersDetails
                                              ?.memberPrice
                                        ) +
                                          parseInt(
                                            getMember(
                                              orderDetails?.ordersDetails
                                                ?.ticketDetails,
                                              "Non-Member"
                                            ).length *
                                              orderDetails?.ordersDetails
                                                ?.memberPrice
                                          )
                                      )
                                    : formatCurrency(
                                        parseInt(
                                          getMember(
                                            orderDetails?.ordersDetails
                                              ?.ticketDetails,
                                            "Member"
                                          ).length *
                                            orderDetails?.ordersDetails
                                              ?.memberPrice
                                        ) +
                                          parseInt(
                                            getMember(
                                              orderDetails?.ordersDetails
                                                ?.ticketDetails,
                                              "Non-Member"
                                            ).length *
                                              orderDetails?.ordersDetails
                                                ?.memberPrice
                                          )
                                      )
                                )} */}
                            </h2>
                          ) : (
                            <h2>
                              {user?.data?.loginType === "Admin"
                                ? "Register"
                                : "Pay now"}{" "}
                              {/* @ $
                              {getBulkdiscountPrice(
                                parseFloat(
                                  getMember(
                                    orderDetails?.ordersDetails?.ticketDetails,
                                    "Member"
                                  ).length *
                                    orderDetails?.ordersDetails?.memberPrice
                                )
                              )} */}
                            </h2>
                          )}
                        </a>
                      </div>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
