import { IMAGE_UPLOAD } from "../../../Graphql/queries";
import { useLazyQuery } from "@apollo/client";
import { CropImage } from "../../Common";
import imageCompression from "browser-image-compression";
import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
export default function Banner(props) {
  const [cropImage, setCropImage] = useState(null);
  const [cropOpen, setCropOpen] = useState(false);
  const [upload, { data: uploadImageData }] = useLazyQuery(IMAGE_UPLOAD, {
    fetchPolicy: "network-only",
    onCompleted: (res) => {
      let array = [...props.bannerImages];
      let position = array.findIndex(
        (element) => element.fileName === res?.imageUpload?.filename
      );
      let obj = array[position];
      obj["publicUrl"] = res?.imageUpload?.publicUrl;
      obj["signedUrl"] = res?.imageUpload?.signedUrl;
    },
  });
  function compressImage(file) {
    var options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 300,
      useWebWorker: true,
    };

    imageCompression(file[0], options)
      .then((compressedFile) => {
        setCropImage(compressedFile);
        setCropOpen(true);
      })
      .catch((err) => {

      });
  }
  function handleImageUpload(file, src) {
    // let array = [...props.bannerImages];
    let array = [];
    upload({
      variables: { originalFilename: file?.name, path: "/school" },
    });
    array.push({
      fileName: file?.name,
      url: src,
      file: file,
    });
    props.setBannerImages(array);    
  }
  function handelCropDone(result,fileUrl) {
    setCropImage(null);
    handleImageUpload(result, fileUrl);
  }
  function handleRemove(index) {
    let array = [...props.bannerImages];
    array.splice(index, 1);
    props.setBannerImages(array);
  }
  return (
    <div className="card mb-4 banner-image-outer">
      <CropImage
        open={cropOpen}
        setOpen={setCropOpen}
        image={cropImage}
        handelCropDone={handelCropDone}
        // aspectRatio="16/9"
      />
      <input
        type="file"
        className="d-none"
        id="school-banner-imageUpload"
        accept='.jpg, .png, .jpeg'
        onChange={(e) => {          
          var options = {
            maxSizeMB: 1,
            // maxWidthOrHeight: 700,
            useWebWorker: true,
          };
          
          imageCompression(e.target.files[0], options)
          .then((compressedFile) => {            
          setCropImage(compressedFile);
          setCropOpen(true);
          })
          .catch((err) => {
            //console.log("err: ",err)
          })
          // compressImage(e.target.files);
        }}
        onClick={(event)=> {
          event.target.value = null
        }}
      />
      <div className="card-title justify-content-between">
        <div className="d-flex">
          <img src="../../assets/images/BannerIcon.svg" /> Banner
        </div>
        <a
          className="addspeaker-btn"
          onClick={() =>
            document.getElementById("school-banner-imageUpload").click()
          }
          href="javscript:;"
        >
          <img src="../../assets/images/addimage.svg" />
          Add Image
        </a>
      </div>
      <div className="banner-inner">
        {props?.bannerImages &&
          props?.bannerImages?.map((item, index) => (
            <div>
              <div className="banner-delete-icon">
                <DeleteIcon onClick={() => handleRemove(index)} />
              </div>
              <img className={"banner-image"} src={item.url} />
            </div>
          ))}
      </div>
    </div>
  );
}
